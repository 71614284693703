import { Button, Card, Grid, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import Form, { FormActions } from "../../../components/Form";
import { OpenIcon, PrintIcon } from "../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";

export default function ReportToMail() {

  const { CanDoDataMaintenance, CanEvaluate } = useRoot();
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const [reportToMailData,] = useState([]);
  const [infoMessage,] = useState(
    "There is 1 report for 1 contact. first PRINT the Label and then PROCESS the report"
  );

  const tblOptions = {
    columns: GetColumns(viewClick),
    dataSource: (reportToMailData || []).map((x) => ({
      ...x,
      keyProp:
        x.Username +
        "" +
        (x.FullName || "") +
        "" +
        (x.Added || "") +
        "" +
        (x.Event || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: { height: 700 },
  };
  //Export excel
  function viewClick() { }
  return (
    <>
      <Grid container>
        <Grid item xs={12} sx>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(infoMessage, CanDoDataMaintenance, CanEvaluate)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {reportToMailData && reportToMailData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(viewClick) {
  return [
    {
      headerName: "View",
      field: "View",
      sortable: false,
      width: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={viewClick} label="View" />
          </>
        );
      },
    },
    {
      headerName: "Username",
      field: "Username",
      flex: 1,
    },
    {
      headerName: "Contact",
      field: "Contact",
      flex: 1,
    },
    {
      headerName: "Sample",
      field: "Sample",
      flex: 1,
    },
    {
      headerName: "Added",
      field: "Added",
      flex: 1,
    },
    {
      headerName: "Product",
      field: "Product",
      flex: 1,
    },
    {
      headerName: "Customer",
      field: "Customer",
      flex: 1,
    },
    {
      headerName: "Site",
      field: "Site",
      flex: 1,
    },
    {
      headerName: "Unit",
      field: "Unit",
      flex: 1,
    },
    {
      headerName: "Component",
      field: "Component",
      flex: 1,
    },
    {
      headerName: "Address",
      field: "Address",
      flex: 1,
    },
  ];
}
function GetFormSections(infoMessage, CanDoDataMaintenance, CanEvaluate) {
  return [
    {
      fields: [
        {
          xsCol: 1.4,
          component: function () {
            return (
              <Button
                variant="contained"
                //  onClick={}
                sx={{ mt: "12px" }}
                startIcon={<PrintIcon />}
              >
                Print Labels (F12)
              </Button>
            );
          },
        },
        {
          name: "LabelOffset",
          label: "Label Offset",
          type: "number",
          xsCol: 1.3,
          min: 1,
          max: 5,
        },
        {
          component: function () {
            return (
              <Button
                variant="outlined"
                //   onClick={}
                sx={{ mt: "12px" }}
                startIcon={<OpenIcon />}
              >
                Open All Reports (F11)
              </Button>
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return <Typography sx={{ mt: 2 }}>{infoMessage}</Typography>;
          },
        },
        (CanDoDataMaintenance || CanEvaluate) && {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return <Button
              variant="outlined"
              sx={{ mt: "12px" }}
              startIcon={<OpenIcon />}
            >
              Delete All Reports
            </Button>
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
const SearchFilterDefaults = {};
