import { useTheme } from "@emotion/react";
import {
  Badge,
  Button,
  Grid,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonGroup,
  ClickAwayListener,
  Typography,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { useRef, useState } from "react";
import { Fragment } from "react";

export default function FormFooter({ buttons, footerTextSx, footerText, gmt = -0.5 }) {
  const theme = useTheme();
  return (
    <>
      <Grid container >
        <Grid item xs={footerText && footerText ? 6 : 0} sx={{ marginTop: "10px" }}>
          <Typography sx={footerTextSx || {}}>{footerText}</Typography>
        </Grid>

        <Grid item xs={footerText && footerText ? 6 : 12}>
          <Grid
            container
            spacing={1}
            style={{ marginTop: theme.spacing(gmt) }}
            justifyContent="flex-end"
          >
            {buttons.map((x, i) => {
              if (x !== false)
                return (
                  <Fragment key={i} >
                    <ButtonComp {...x} />
                  </Fragment>
                )
            }
            )}
          </Grid >

        </Grid>
      </Grid >

    </>
  );
}

function ButtonComp({ type, hidden, ...props }) {
  if (type === "dropdown") {
    return (
      <Grid item hidden={hidden}>
        <DropdownButton {...props} />
      </Grid>
    );
  }
  return (
    <Grid item hidden={hidden}>
      <BadgeButton {...props} />
    </Grid>
  );
}

export function BadgeButton({ badge, ...button }) {
  if (badge && badge.badgeContent) {
    return (
      <Badge color={badge?.badgeColor || "primary"} {...badge}
        sx={badge?.sx || {}}
      >
        <Button {...button}>{button.label}</Button>
      </Badge >
    );
  }

  return <Button {...button}>{button.label}</Button>;
}

export function DropdownButton({ label, options, onClick, disabled }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef} variant="contained" disabled={disabled}>
        <Button onClick={onClick}>{label}</Button>
        <Button size="small" onClick={handleToggle}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map(({ label, ...option }, index) => (
                    <MenuItem key={index} {...option}>
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
