import { Card, Grid, } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";

import useFetch from "../../../hooks/useFetch";
import SearchFilter from "./SearchFilter";
import OutstandingTable from "./OutstandingTable";
import { DateFormat } from "../../../utils";
import ActionedTable from "./ActionedTable";
import AddCriticalLogs from "./add-critical-logs/AddCriticalLogs";
import AppDialog from "../../../components/AppDialog";
import { CRITICAL_LOGS_APIS, MASTER_DATA_APIS } from "../../../common/apis";

export default function CriticalLogs() {
  const { get, getFile } = useFetch();
  const [responseData, setResponseData] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [radioOptions, setRadioOption] = useState();
  const [rowAddClickData, setRowAddClickData] = useState();
  const [addCriticalDialogueOpen, setAddCriticalDialogueOpen] = useState(false);
  const [infoMessage, setInfoMessage] = useState();

  async function onViewHandler(filter) {
    //Get Grid data
    setRadioOption(filter.radioOption);
    setResponseData([]);
    if (filter.radioOption === 0) {
      const data = await get(
        `${CRITICAL_LOGS_APIS.CRITICAL_LOG_OUTSTANDING}?dateFrom=${moment(filter.DateFrom).format(DateFormat)}&dateTo=${moment(filter.DateTo).format(DateFormat)}&UserID=${filter.UserId}`
      );
      if (data?.length > 0) {
        setResponseData(data || []);
        setInfoMessage(`There are ${data?.length} outstanding samples`);
      } else {
        setResponseData();
        setInfoMessage(`There are no outstanding samples`);
      }

      return;
    }
    const data = await get(
      `${CRITICAL_LOGS_APIS.CRITICAL_LOGS}?dateFrom=${moment(
        filter.DateFrom
      ).format(DateFormat)}&dateTo=${moment(filter.DateTo).format(
        DateFormat
      )}&UserID=${filter.UserId}`
    );
    if (data?.length > 0) {
      setResponseData(data || []);
      setInfoMessage(`There are ${data?.length} critical log entries`);
    } else {
      setResponseData();
      setInfoMessage(`There are no critical log entries`);
    }
  }

  //Get User types
  useEffect(function () {
    document.title = "Critical Log";
    (async function () {
      const data = await get(
        `${MASTER_DATA_APIS.GET_BY_TYPES}?types=Users`,
        {}
      );
      if (data.Users) {
        data.Users.unshift({ UserId: 0, FullName: "All Users" });
        setUserTypes(data.Users);
      }
    })();
  }, []);

  function handelEditClick(row) {
    setRowAddClickData(row);
    setAddCriticalDialogueOpen(true);
  }

  function handelViewClick(row) {
    window.open("/trays/evaluation?sampleNo=" + row.SampleNumber + "&labId=" + row.LaboratoryId + "&trayDate=" + row.TrayDate + "&tray=" + row.Tray, "_blank");
  }

  function handelAddClick(row) {
    setRowAddClickData(row);
    setAddCriticalDialogueOpen(true);
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <SearchFilter
              onView={onViewHandler}
              userTypes={userTypes}
              infoMessage={infoMessage}
              responseData={responseData}
              key={userTypes.UserId}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          {radioOptions === 0 ? (
            <OutstandingTable
              data={responseData}
              addClick={handelAddClick}
              viewClick={handelViewClick}
            />
          ) : (
            <ActionedTable
              data={responseData}
              editClick={handelEditClick}
              viewClick={handelViewClick}
            />
          )}
        </Grid>
      </Grid>

      <AppDialog
        open={addCriticalDialogueOpen}
        handleClose={() => setAddCriticalDialogueOpen(false)}
        title={`Critical Log for Sample Number ${rowAddClickData && rowAddClickData.SampleNumber}`}
        maxWidth="100vw"
      >
        <AddCriticalLogs dataObj={rowAddClickData} />
      </AppDialog>
    </>
  );
}
