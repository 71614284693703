import DataTable from "../../../components/DataTable";
import { MissedSamplesColumns } from "./MissedSamplesColumns";

export default function MissedSampleTable(props) {
  const options = {
    columns: MissedSamplesColumns,
    dataSource: props.data,
    rowIdProperty: "SampleNumber",
    sx: { height: 640 },
    isDisableColumnFilter: true,
  };
  return <DataTable {...options} />;
}
