import { Grid, Box, Typography, colors } from "@mui/material";
import { useId } from "react";
import DateTime, { Date } from "./DateTime";
import DropDown from "./DropDown";
import SwitchInput from "./SwitchInput";
import RadioOption from "./RadioOption";
import TextBox from "./TextBox";

export default function Form({
  sections,
  spacing = 1,
  data,
  dispatch,
  setData,
  readOnly,
  errors,
  masterData,
  onChange,
  sx,
  actions = {},
}) {
  const id = useId();
  function setValue(name, value) {
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name, value },
    });
  }

  function onDateTimeChange(val, name) {
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name, value: val },
    });
  }

  function onChange(e, field) {
    const val =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const num = Number(e.target.value);
    if (e.target.type === "number") {
      if (e.target.value === "") {
        e.target.value = "";
      } else if (num <= field.min || num <= 0) {
        e.target.value = field.min || 1;
      } else if (num > field.max) {
        e.target.value = field.max;
      }
    }

    if (dispatch) {
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: e.target.name, value: val },
      });
      actions.onChange && actions.onChange(e.target.name, val);
      return;
    }
    setData && setData({ ...data, [e.target.name]: val });
  }

  return (
    <Grid container spacing={spacing}>
      {sections?.map((x, i) => {
        //if (!x.type || x.type === "text")
        return (
          <Grid
            item
            xs={x.xsCol || 12}
            key={i}
            container
            spacing={1}
            alignContent="flex-start"
            sx={sx || {}}
          >
            {x?.fields?.map((field, index) => {
              if (field !== false)
                return renderField({
                  field,
                  index,
                  id,
                  data,
                  onChange,
                  setValue,
                  onDateTimeChange,
                  readOnly,
                  errors,
                  masterData,
                  dispatch,
                  actions,
                  sx,
                });
            })}
          </Grid>
        );
      })}
    </Grid>
  );
}

function renderField({
  field,
  index: j,
  id,
  data,
  onChange,
  setValue,
  onDateTimeChange,
  readOnly,
  errors,
  masterData,
  dispatch,
  actions,
  sx,
}) {
  if (field?.component) {
    return (
      <GridFormItem {...field} key={j}>
        {field.component({
          id,
          value: (data || {})[field.name],
          setValue: (value, name = "") => setValue(name || field.name, value),
          errorMsg: (errors || {})[field.errorId || field.name],
          onChange: onChange,
          data,
          dispatch,
          masterData,
          actions,
        })}
      </GridFormItem>
    );
  }
  if (field?.group) {
    return (
      <GridFormItem
        {...field}
        key={j}
        container
        spacing={2}
        alignContent="flex-start"
      >
        {/* <Grid >
        </Grid> */}
        {field?.group?.map((f, k) => {
          if (f !== false)
            return renderField({
              field: f,
              index: k,
              id,
              data,
              onChange,
              setValue: setValue,
              onDateTimeChange,
              readOnly,
              errors,
              masterData,
              dispatch,
              actions,
              sx,
            });
        })}
      </GridFormItem>
    );
  }
  if (
    !field.type ||
    field.type === "text" ||
    field.type === "textarea" ||
    field.type === "number"
  ) {
    const options =
      field.type === "textarea"
        ? { multiline: true, rows: field.rows || 3 }
        : field.type === "number"
          ? { type: "number", inputProps: { min: field.min, max: field.max } }
          : field.type === "text"
            ? { inputProps: { maxLength: field?.maxLength } }
            : {};

    return (
      <GridFormItem {...field} key={j} hidden={getHiddenValue(data, field)}>
        <TextBox
          sx={field.sx}
          id={`${id}${field.name}`}
          name={field.name}
          label={field.label}
          labelColor={field.labelColor || ""}
          label2={field.label2 || ""}
          label2Color={field.label2Color || ""}
          label2bgColor={field.label2bgColor || ""}
          defaultValue={getFieldValue(data, field)}
          errorMsg={(errors || {})[field.name] || ""}
          readOnly={readOnly || field.readOnly}
          disabled={getDisabledValue(data, field)}
          onChangeAsync={(e) => onChange(e, field)}
          {...{ ...options }}
        />
      </GridFormItem>
    );
  }
  if (field.type === "switch") {
    return (
      <GridFormItem
        {...field}
        key={j}
        alignSelf="end"
        hidden={getHiddenValue(data, field)}
      >
        <SwitchInput
          id={`${id}${field.name}`}
          name={field.name}
          className={field.className}
          label={field.label}
          value={(data || {})[field.name] || false}
          errorMsg={(errors || {})[field.name] || ""}
          onChange={onChange}
          disabled={getDisabledValue(data, field)}
          readOnly={getReadOnlyValue(data, field)}
        />
      </GridFormItem>
    );
  }
  if (field.type === "radio") {
    return (
      <GridFormItem {...field} key={j} hidden={getHiddenValue(data, field)}>
        <RadioOption
          id={`${id}${field.name}`}
          name={field.name}
          label={field.label}
          value={(data || {})[field.name] || field.defaultVal || ""}
          errorMsg={(errors || {})[field.name] || ""}
          onChange={onChange}
        />
      </GridFormItem>
    );
  }
  if (field.type === "dropdown") {
    let selectOptions = [];
    if (field?.selectOptions) {
      selectOptions = field?.selectOptions;
    } else if (field?.optionConfigs) {
      selectOptions = (masterData[field?.optionConfigs?.name] || [])?.map(
        (x) => ({
          value: x[field?.optionConfigs?.valProp || "value"],
          text: x[field?.optionConfigs?.textProp || "text"],
        })
      );
    } else if (field.optionsArray) {
      selectOptions = field.optionsArray?.map((x, i) => ({
        value: i,
        text: x,
      }));
    } else if (field.optionsObject) {
      selectOptions = Object.keys(field.optionsObject)?.map((key) => ({
        value: key,
        text: field.optionsObject[key],
      }));
    }

    return (
      <GridFormItem
        {...field}
        //alignSelf={!field.label ? "flex-end" : undefined}
        key={j}
        hidden={getHiddenValue(data, field)}
      >
        <DropDown
          // sx={{ minWidth: "230px" }} disturbing when moves to smaller screen
          id={`${id}${field.name}`}
          name={field.name}
          label={field.label}
          value={(data || {})[field.name] || 0}
          errorMsg={(errors || {})[field.name] || ""}
          onChange={field.onChange || onChange}
          selectOptions={selectOptions}
          required={field.required}
          disabled={getDisabledValue(data, field)}
          readOnly={getReadOnlyValue(data, field)}
        />
      </GridFormItem>
    );
  }
  if (field.type === "datetime") {
    return (
      <GridFormItem {...field} key={j} hidden={getHiddenValue(data, field)}>
        <DateTime
          id={`${id}${field.name}`}
          name={field.name}
          label={field.label}
          value={(data || {})[field.name] || ""}
          errorMsg={(errors || {})[field.name] || ""}
          onChange={onDateTimeChange}
          disabled={getDisabledValue(data, field)}
          disablePast={field.disablePast}
          disableFuture={field.disableFuture}
          readOnly={readOnly || field.readOnly}
        />
      </GridFormItem>
    );
  }
  if (field.type === "date") {
    return (
      <GridFormItem
        sx={{ maxWidth: "200px" }}
        {...field}
        key={j}
        hidden={getHiddenValue(data, field)}
      >
        <Date
          id={`${id}${field.name}`}
          name={field.name}
          label={field.label}
          value={(data || {})[field.name] || ""}
          errorMsg={(errors || {})[field.name] || ""}
          onChange={onDateTimeChange}
          disabled={getDisabledValue(data, field)}
          readOnly={readOnly || field.readOnly}
          disablePast={field.disablePast}
          disableFuture={field.disableFuture}
          maxDate={field?.maxDate}
          minDate={field?.minDate}
        />
      </GridFormItem>
    );
  }
  // if (field.type === "label") {
  //   return (
  //     <GridFormItem {...field}  key={j}>
  //       <FormHelperTextLabel label={field.label} />
  //     </Grid>
  //   );
  // }
}

function GridFormItem({
  xsCol,
  children,
  container,
  direction,
  spacing,
  alignContent,
  alignItems,
  alignSelf,
  flex,
  sx,
  hidden,
}) {
  const options = {
    container: container,
    spacing: spacing,
    alignSelf: alignSelf,
    alignContent: alignContent,
    alignItems: alignItems,
    direction: direction,
    flex: flex,
    sx: sx,
    hidden,
  };
  if (xsCol) {
    options.xs = xsCol;
  }
  return (
    <Grid item {...options}>
      {children}
    </Grid>
  );
}

function getFieldValue(data, field) {
  if (typeof field.getValue === "function") {
    return field.getValue(data);
  }
  const val = (data || {})[field.name];
  return val || val === 0 ? val : "";
}

function getDisabledValue(data, field) {
  return (
    field.disabled &&
    (typeof field.disabled === "function"
      ? field.disabled(data)
      : field.disabled)
  );
}
function getReadOnlyValue(data, field) {
  return (
    field.readOnly &&
    (typeof field.readOnly === "function"
      ? field.readOnly(data)
      : field.readOnly)
  );
}

function getHiddenValue(data, field) {
  return (
    field.hidden &&
    (typeof field.hidden === "function" ? field.hidden(data) : field.hidden)
  );
}

export function SectionTitle({ title }) {
  return (
    <Box
      sx={{ backgroundColor: "primary.main", color: colors.common.white }}
      p={1}
    >
      <Typography>{title}</Typography>
    </Box>
  );
}

export const FormActions = {
  LOAD_DATA: "load-data",
  INPUT_CHANGED: "input-changed",
  RESET_FORM: "reset-form",
};
