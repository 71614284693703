import { Box, Button } from "@mui/material";
import { Check, Person } from "@mui/icons-material";
import { useCallback, useEffect, useReducer, useState } from "react";
import Form, { FormActions } from "../components/Form";
import FormFooter from "../components/FormFooter";
import ComponentReducer, { ComponentActions, } from "./reducers/ComponentReducer";
import useFetch from "../hooks/useFetch";
import useMasterData from "../hooks/useMasterData";
import { ValidateComponent } from "../validation/schemas/ComponentSchema";
import { DETAILS_APIS } from "../common/apis";
import AppDialog from "../components/AppDialog";
import StandardNames from "../features/master-data/machine-templates/StandardNames";
import ViewUnitModels from "../features/master-data/unit-models/ViewUnitModels";
import NewFluid from "../features/master-data/fluids/NewFluid";
import { NewIcon } from "../icons/ButtonIcons";
import { useRoot, useToast } from "../rootContext";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ComponentAction from "../redux/actions/ComponentActions";
import appResources from "../app-resources/en/Resources.json";
import DropDownAdvance from "../components/DropDownAdvance";

function EditComponent({
  siteId,
  productId,
  customerId,
  cancel,
  unitId,
  compId,
  fluidTypeId,
  registrationDone,
  ComponentAction,
  startLetter,
  setExpandTreeData,
  handleSearchTree,
}) {

  const [data, dispatch] = useReducer(ComponentReducer, {
    DefaultFluidTypeId: "1",
    ComponentTypeId: "1",
    LocationId: "1",
  });
  const { CanDoDataMaintenance } = useRoot();
  const { get, post } = useFetch();
  const { getMasterData } = useMasterData();
  const { showToast, showErrorToast } = useToast();

  const [formErrors, setFormErrors] = useState({});
  const [dropdownData, setDropdownData] = useState([]);
  const [standardNameDialogueOpen, setStandardNameDialogueOpen] = useState(false);
  const [unitModelDialogueOpen, setUnitModelDialogueOpen] = useState(false);
  const [newFluidOpen, setNewFluidOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (!compId && !unitId && !fluidTypeId) {
        const masterData = await getMasterData(
          "ComponentFluidTypes,ComponentTypes,ComponentModels,ComponentLocations,TestSetsForUnitFluidTypes,Fluids",
          `fluidTypeId=1`
        );
        return setDropdownData(masterData || {});
      }

      if (unitId) {
        const masterData = await getMasterData(
          "ComponentFluidTypes,ComponentTypes,ComponentModels,ComponentLocations,TestSetsForUnitFluidTypes,Fluids",
          `&unitId=${unitId}&fluidTypeId=${fluidTypeId || 1}`
        );
        setDropdownData(masterData || {});
      }
      if (compId) {
        (async () => {
          const data = await get(
            `/details/component?compId=${compId !== undefined ? compId : ""
            }&FluidTypeId=${fluidTypeId || 1}`, { skipBlocker: true }
          );
          setDropdownData(data || {});
          dispatch({
            type: ComponentActions.LOAD_DATA,
            payload: data && data.Component,
          });
        })();
      }
    })();
  }, []);

  const actions = {
    save: async function () {
      if (compId) {
        setFormErrors({});
        ValidateComponent(data).then(async function ({ data: req, errors }) {
          if (req) {
            let result = await post(DETAILS_APIS.UPDATE_COMPONENT, {
              // ...data,
              ApplyToAllSamples: data?.SetToALLSamples || false,
              CIN: data?.CIN,
              Capacity: data?.Capacity,
              ComponentTypeId: data?.ComponentTypeId,
              ComponentMakeId: data?.ComponentMakeId,
              ComponentId: data?.ComponentId,
              Component: data?.Component,
              ComponentFluidTypeId: data?.DefaultFluidTypeId,
              Serial: data?.Serial,
              LocationId: data?.LocationId,
              Notes: data?.ComponentNotes,
              DefaultFluidId: data?.DefaultFluidId,
              DefaultTestSetId: data?.DefaultTestSetId,
              IsNonFluid: data?.IsNonFluid,
              IsArchived: data?.ComponentArchived,
              ApplyToAllTestSet: data?.ApplyToAllTestSet,
              UnitId: data?.UnitId,
              UserId: data?.UserId,
              Screen: "Edit Component",
              // IsArchived: !!data.ComponentArchived,
              // ComponentFluidTypeId: data.DefaultFluidTypeId,
              // Notes: data.ComponentNotes,
              // ApplyToAllSamples: data.SetToALLSamples ? data.SetToALLSamples : false,
              // IsNonFluid: !!data.IsNonFluid,
            });

            if (result > 0 || result?.length > 0) {
              showToast(appResources.COMPONENT_SAVED_SUCCESSFULLY_MSG);
              ComponentAction.updateComponent(data);
              return cancel();
            }

            if (result !== null && (result < 1 || result?.length < 1)) {
              return showErrorToast(appResources.COMPONENT_NOT_SAVED_MSG);
            }
            registrationDone && registrationDone(data.SampleNumber);
            return;
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      } else {
        setFormErrors({});
        ValidateComponent(data).then(async function ({ data: req, errors }) {
          if (req) {
            let result = await post(DETAILS_APIS.ADD_COMPONENT, {
              ...data,
              UnitId: unitId,
              IsArchived: !!data.ComponentArchived,
              ComponentFluidTypeId: data.DefaultFluidTypeId,
              Notes: data.ComponentNotes,
              ApplyToAllSamples: !!data.SetToALLSamples,
              IsNonFluid: !!data.IsNonFluid,
            });
            registrationDone && registrationDone(data.SampleNumber);
            if (result > 0 || result?.length > 0) {
              if (result > 0) {
                const treeData = { ProductId: productId, StartLetter: startLetter, CustomerId: customerId, SiteId: siteId, UnitId: unitId, ComponentId: result }
                setExpandTreeData && setExpandTreeData(treeData)
                handleSearchTree && handleSearchTree(treeData)
              }
              return cancel();
            }
            return;
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      }
    },
    cancel: cancel,
  };

  function standardNameClick() {
    setStandardNameDialogueOpen(true);
  }
  function newModelClick() {
    setUnitModelDialogueOpen(true);
  }
  function newFluidClick() {
    setNewFluidOpen(true);
  }

  function recordSelected(row) {
    if (row.ComponentName) {
      dispatch({
        payload: {
          ...row,
          Component: row.ComponentName,
        },
        type: ComponentActions.LOAD_DATA,
      });
    }
  }

  const handleDDChange = (e, val, name) => {
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name: name, value: val }
    });
  }
  // const handleKeyDown = useCallback(
  //   (event) => {
  //     if (event.key === "F11") {
  //       event.preventDefault();
  //       setStandardNameDialogueOpen(true);
  //     } else if (event.key === "F12") {
  //       event.preventDefault();
  //       actions.save();
  //     }
  //   },
  //   [data]
  // );
  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [handleKeyDown]);
  return (
    <>
      <Form
        sections={FormSections(
          dropdownData,
          standardNameClick,
          newModelClick,
          newFluidClick,
          handleDDChange,
          data, CanDoDataMaintenance
        )}
        data={data}
        spacing={2}
        dispatch={dispatch}
        errors={formErrors}
        key={data._ts_key}
      />
      <FormFooter
        buttons={GetFormButtons(data, actions, CanDoDataMaintenance)}
        footerText={`ProductId: ${productId || data?.ProductId || ""
          }, CustomerId: ${customerId || data?.CustomerId || ""}, SiteId: ${siteId || data?.SiteId || ""
          }, UnidId: ${unitId || data?.UnitId || ""},  ComponentId: ${data.ComponentId || ""
          } `}
      />
      <AppDialog
        open={standardNameDialogueOpen}
        handleClose={() => setStandardNameDialogueOpen(false)}
        title="Standard Component Names"
        maxWidth="md"
      >
        <StandardNames
          recordSelected={recordSelected}
          setStandardNameDialogueOpen={setStandardNameDialogueOpen}
        />
      </AppDialog>
      <AppDialog
        open={unitModelDialogueOpen}
        handleClose={() => setUnitModelDialogueOpen(false)}
        title="Component Make and Model from Edit Components"
        maxWidth="xl"
      >
        <ViewUnitModels
          handleClose={setUnitModelDialogueOpen}
        // recordSelected={recordSelected}
        // setStandardNameDialogueOpen={setStandardNameDialogueOpen}
        />
      </AppDialog>
      <AppDialog
        open={newFluidOpen}
        handleClose={() => setNewFluidOpen(false)}
        title="New Fluid from Edit Component"
        maxWidth="md"
      >
        <NewFluid
          handleClose={setNewFluidOpen}
          setNewFluidOpen={setNewFluidOpen}
        // recordSelected={recordSelected}
        // setStandardNameDialogueOpen={setStandardNameDialogueOpen}
        />
      </AppDialog>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return { ComponentAction: bindActionCreators(ComponentAction, dispatch) };
};

export default connect(null, mapDispatchToProps)(EditComponent);

const GetFormButtons = (data, { cancel, save }, CanDoDataMaintenance) => [
  {
    hidden: !CanDoDataMaintenance,
    variant: "contained",
    label: "Save",
    onClick: save,
    startIcon: <Check />,
  },
];

function ComponentsDropDown({ id, dataList, handleChange, defaultValue }) {

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" sx={{ minWidth: "280px", maxWidth: "280px" }} {...props} key={option.ComponentMakeId}>
        {option.ComponentMakeFormatted || "Select"}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options
        .filter((x) =>
          x.ComponentMakeFormatted.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.ComponentMakeFormatted || "Select";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label="Model"
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, val) => handleChange(e, val?.ComponentMakeId || null, "ComponentMakeId")}
      options={dataList || []}
      defaultValue={dataList.find((x) => x.ComponentMakeId === defaultValue) || "Select"}
    />
  );
}

function FluidsDropDown({ id, dataList, handleChange, defaultValue }) {

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" sx={{ minWidth: "280px", maxWidth: "280px" }} {...props} key={option.FluidId}>
        {option.FluidFormatted || "Select"}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options
        .filter((x) =>
          x.FluidFormatted.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.FluidFormatted || "Select";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label="Fluid"
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, val) => handleChange(e, val?.FluidId || null, "DefaultFluidId")}
      options={dataList || []}
      defaultValue={dataList.find((x) => x.FluidId === defaultValue) || "Select"}
    />
  );
}

function FormSections(
  dropdownData,
  standardNameClick,
  newModelClick,
  newFluidClick,
  handleDDChange,
  data, CanDoDataMaintenance
) {
  return [
    {
      fields: [
        { name: "Component", label: "Component", xsCol: 3 },
        {
          xsCol: 3,
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={standardNameClick}
                sx={{ mt: "14px" }}
                startIcon={<Person />}
              >
                Standard Names (F11)
              </Button>
            );
          },
        },
        {
          name: "ComponentArchived",
          label: "Archived - no website",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "IsNonFluid",
          label: "Non-Fluid Component",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "ComponentTypeId",
          label: "Comp. Type",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (dropdownData.ComponentTypes || []).map((x) => ({
            value: x.ComponentTypeId,
            text: x.ComponentType,
          })),
          sx: {
            [`.MuiSelect-select`]: {
              backgroundColor: data?.ComponentTypeId === '1' ? "red" : "",
              color: data?.ComponentTypeId === '1' ? "white" : "",
              [`.MuiSvgIcon-root-MuiSelect-icon`]: {
                color: data?.ComponentTypeId === '1' ? "" : "white",
              },
            },
            [`.MuiSelect-icon`]: {
              color: data?.ComponentTypeId === '1' ? "white" : "",
            },
          },
        },
        {
          name: "DefaultFluidTypeId",
          label: "Fluid Type",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (
            dropdownData.FluidTypes ||
            dropdownData.ComponentFluidTypes ||
            []
          ).map((x) => ({
            value: x.FluidTypeId,
            text: x.FluidType,
          })),
        },
        {
          name: "LocationId",
          label: "Location",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (dropdownData.ComponentLocations || []).map((x) => ({
            value: x.LocationId,
            text: x.Location,
          })),
        },
        { name: "Serial", label: "Serial", xsCol: 3 },
        {
          xsCol: 3,
          component: function () {
            return (
              <ComponentsDropDown
                id={"ComponentMakeId"}
                dataList={dropdownData.ComponentModels || []}
                handleChange={(e, val, text) => handleDDChange(e, val, text)}
                defaultValue={data?.ComponentMakeId}
              />
            );
          },
        },
        {
          xsCol: 3,
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={newModelClick}
                sx={{ mt: "14px" }}
                startIcon={<NewIcon />}
              >
                New Model
              </Button>
            );
          },
        },
        { name: "Capacity", label: "Capacity  (in litres)", xsCol: 3 },
        { name: "CIN", label: "CIN", xsCol: 3 },
      ],
    },
    {
      fields: [
        {
          name: "DefaultTestSetId",
          label: "Test Set",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (
            dropdownData.TestSetsForUnitFluidTypes ||
            dropdownData.TestSets ||
            []
          ).map((x) => ({
            value: x.TestSetId,
            text: x.TestSet,
          })),
        },
        {
          xsCol: 3,
          component: function () {
            return (
              <FluidsDropDown
                id={"DefaultFluidId"}
                dataList={dropdownData.Fluids || []}
                handleChange={(e, val, text) => handleDDChange(e, val, text)}
                defaultValue={data?.FluidId}
              />
            );
          },
        },
        CanDoDataMaintenance && {
          xsCol: 2,
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={newFluidClick}
                sx={{ mt: "15px" }}
                startIcon={<NewIcon />}
              >
                New Fluid
              </Button>
            );
          },
        },
        CanDoDataMaintenance && {
          name: "SetToALLSamples",
          label: "Apply Test Set to ALL Samples",
          type: "switch",
          xsCol: 2,
        },
        CanDoDataMaintenance && {
          name: "FluidToALLSamples",
          label: "Apply Fluid to ALL Samples",
          type: "switch",
          xsCol: 2,
        },
        {
          name: "ComponentNotes",
          label: "Comments",
          type: "textarea",
          xsCol: 12,
        },
      ],
    },
  ];
}
