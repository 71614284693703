import { Button, Typography } from "@mui/material";
import moment from "moment";
import { useReducer, } from "react";
import Form from "../../../components/Form";
import { FormActions } from "../../../components/Form";
import { ExportIcon, SearchIcon } from "../../../icons/ButtonIcons";
import useFetch from "../../../hooks/useFetch";
import { CRITICAL_LOGS_APIS } from "../../../common/apis";

export default function SearchFilter({
  onView,
  userTypes,
  infoMessage,
  responseData,
}) {
  const { getFile } = useFetch();
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const actions = {
    view: () => onView(data),
    exportClick: function () {
      data?.radioOption === 0
        ? getFile(
          `${CRITICAL_LOGS_APIS.EXPORT_CRITICAL_LOG_OUTSTANDING}?UserID=${data.UserId ? data.UserId : 0
          }&dateFrom=${data.DateFrom}&dateTo=${data.DateTo}`,
          "Outstanding Critical Samples"
        )
        : getFile(
          `${CRITICAL_LOGS_APIS.EXPORT_CRITICAL_LOGS}?UserID=${data.UserId ? data.UserId : 0
          }&dateFrom=${data.DateFrom}&dateTo=${data.DateTo}`,
          "Actioned Critical Samples"
        );
    },
  };
  return (
    <>
      <Form
        sections={GetFormSections(
          data,
          actions,
          userTypes,
          infoMessage,
          responseData
        )}
        data={data}
        spacing={2}
        dispatch={dispatch}
      />
    </>
  );
}

function GetFormSections(
  data,
  { view, exportClick },
  userTypes,
  infoMessage,
  responseData
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "DateFrom",
          label: "From",
          type: "date",
          alignSelf: "end",
        },
        {
          name: "DateTo",
          label: "To",
          type: "date",
          alignSelf: "end",
        },
        {
          name: "UserId",
          label: "Users",
          type: "dropdown",
          alignSelf: "end",
          defaultValue: 0,
          selectOptions: (userTypes || []).map((x) => ({
            value: x.UserId,
            text: x.FullName,
          })),
        },
        {
          name: "radioOption",
          type: "dropdown",
          alignSelf: "end",
          required: true,
          selectOptions: getRadioOptions(),
        },
        {
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={view}
                sx={{ mt: "23px" }}
                startIcon={<SearchIcon />}
              >
                View
              </Button>
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return (
              <Typography sx={{ mt: 3, fontSize: 11 }}>
                {infoMessage}
              </Typography>
            );
          },
        },
        {
          component: function () {
            return (
              responseData?.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={exportClick}
                  sx={{ mt: "23px" }}
                  startIcon={<ExportIcon />}
                >
                  Export
                </Button>
              )
            );
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

const SearchFilterDefaults = {
  DateFrom: moment().toISOString(),
  DateTo: moment().toISOString(),
  radioOption: 0,
  UserId: 0,
};

function getRadioOptions() {
  return [
    { value: 0, text: "Outstanding" },
    { value: 1, text: "Actioned" },
  ];
}
