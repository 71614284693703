import { useTheme } from "@emotion/react";
import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useReducer } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import { FormActions } from "../../components/Form";
import useMasterData from "../../hooks/useMasterData";
import { CONTACTS_APIS } from "../../common/apis";
import useFetch from "../../hooks/useFetch";

export default function ParentSearch({ ContactId, setDialog, getData }) {
  const [data, dispatch] = useReducer(SearchFilterReducer);
  const curTheme = useTheme();
  const { post } = useFetch();
  //get api for loading data
  const { masterData } = useMasterData("ParentsAll");

  //use memo
  const filteredData = useMemo(
    () =>
      data?.ShowArchived
        ? masterData.ParentsAll
        : (masterData.ParentsAll || []).filter((x) => {
          return !x.IsArchived;
        }),
    [data, masterData.ParentsAll]
  );
  const tblOptions = {
    columns: GetColumns(selectClick),
    dataSource: filteredData || [],
    rowIdProperty: "ParentId",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    sx: { height: 500, minWidth: 500 },
  };

  async function selectClick(rowData) {
    await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, {
      ContactId: ContactId,
      ParentId: rowData.ParentId,
      Screen: "Contact Access Permissions",
    });
    setDialog((prevState) => ({ ...prevState, open: false }));
    getData();
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(selectClick) {
  return [
    {
      headerName: "Select",
      field: "Select",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Parent",
      field: "Parent",
      minWidth: 200,
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 160,
      flex: 1,
    },
  ];
}

const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
