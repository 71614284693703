import * as React from "react";
import { Card, Grid, Typography } from "@mui/material";
import TextBox from "../../../components/TextBox";
import UnitModelsTable from "./UnitModelsTable";
import useFetch from "../../../hooks/useFetch";
import ViewUnitModels from "./ViewUnitModels";
import { FLEET_APIS } from "../../../common/apis";

export default function UnitModels() {
  const [unitsData, setUnitsData] = React.useState([]);
  const [selectedUnit, setSelectedUnit] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [infoMessage, setInfoMessage] = React.useState();

  const { get } = useFetch();

  async function fetchUnitModels() {
    const temp = await get(FLEET_APIS.UNIT_MODELS_ALL);
    setUnitsData(temp || []);
    setSelectedUnit(temp[0]);
  }
  React.useEffect(function () {
    document.title = "Unit Models";
    fetchUnitModels();
  }, []);

  function handelFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }
  function unitRowSelectedHandler(row) {
    setSelectedUnit(row);
  }
  function getShowMessage(msg) {
    setInfoMessage(msg);
  }

  const filteredData = React.useMemo(
    function () {
      return unitsData.filter(
        (x) =>
          !filter.startsWith ||
          x.UnitModelFormatted?.toUpperCase().startsWith(
            filter.startsWith.toUpperCase()
          )
      );
    },
    [unitsData, filter]
  );
  const [newFluidOpen, setnewFluidOpen] = React.useState(false);
  function handleNewFluidClick() {
    setnewFluidOpen(true);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2} alignItems="flex-end">
          <Grid item>
            <TextBox
              name="startsWith"
              label="Starts with"
              //value={filter.startsWith}
              onChange={handelFilterChange}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ fontSize: 11, color: "red" }}>
              {infoMessage}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            Total Records: <b>{filteredData?.length}</b>
          </Typography>
          <UnitModelsTable
            data={filteredData}
            rowSelected={unitRowSelectedHandler}
            fetchUnitModels={fetchUnitModels}
            selectedRow={selectedUnit?.UnitMakeId + selectedUnit?.TemplateId}
          />
        </Grid>
        <Grid item xs={6} mt={"20px"}>
          <Card sx={{ p: 1 }}>
            <ViewUnitModels
              unitModel={selectedUnit}
              key={selectedUnit.UnitMakeId}
              fetchUnitModels={fetchUnitModels}
              showMessage={getShowMessage}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
