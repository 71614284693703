import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import moment from "moment";
import useFetch from "../../hooks/useFetch";
import Form, { FormActions } from "../../components/Form";
import DataTable, { DataGridAction } from "../../components/DataTable";
import { toLocalDateTime } from "../../utils";
import { MANAGEMENT_APIS } from "../../common/apis";
import { NewIcon, SaveIcon } from "../../icons/ButtonIcons";
import AppDialog from "../../components/AppDialog";
import AlertDialog from "../../components/AlertDialog";
import { useRoot, useToast } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";
import renderCellExpand from "../../components/DatatableCellExpand";

export default function Messages() {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const { get, post } = useFetch();
  const useRootObject = useRoot();
  const [messagesData, setMessagesData] = useState([]);
  const [usersDropdownData, setUsersDropdownData] = useState([]);
  const [labDropdownData, setLabDropdownData] = useState([]);
  const [dialog, setDialog] = useState({ open: false, message: "" });
  const [rowData, setRowData] = useState();
  const { showToast } = useToast();

  //Call api for user dropdown data
  useEffect(() => {
    document.title = "Messages";
    loadData();
  }, [useRootObject?.allLaboratories]);

  async function loadData() {
    let responseLabData = useRootObject?.allLaboratories;
    if (responseLabData) {
      setLabDropdownData(responseLabData);
    }
    refreshData();
  }
  async function refreshData() {
    let responseMessageData = await get(MANAGEMENT_APIS.MESSAGES_ALL);
    if (responseMessageData) {
      responseMessageData.Users.unshift({
        UserId: 0,
        FullName: "All Users",
      });
      setUsersDropdownData(responseMessageData.Users);
      if (responseMessageData.MessagesAll.length > 0) {
        setMessagesData(responseMessageData.MessagesAll);
      } else {
        setMessagesData([]);
      }
    }
  }

  async function saveClick() {
    await post(MANAGEMENT_APIS.EDIT_MESSAGE, {
      ...data,
      DisplayFrom: data.NoEndDate ? null : data.DisplayFrom,
      DisplayTo: data.NoEndDate ? null : data.DisplayTo,
      AddedBy: "User d1",
      LaboratoryId: data.LaboratoryId === 4 ? 0 : data.LaboratoryId,
      UserId: data.UserId ? data.UserId : 0,
    });

    await refreshData();
  }
  const tblOptions = {
    columns: GetColumns(deleteClick),
    dataSource: messagesData || [],
    rowIdProperty: "MessageId",
    isDisableColumnFilter: true,
    sx: { height: 600 },
  };
  function deleteClick(row) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    setRowData(row);
  }
  async function deleteClickAlert() {
    await get(
      `${MANAGEMENT_APIS.DELETE_MESSAGE}?MessageId=${rowData.MessageId}`
    );
    await refreshData();
    setDialog((prevState) => ({ ...prevState, open: false }));
    showToast(appResources.MESSAGE_DELETED_SUCCESSFULLY_MSG);
  }

  function newCLick() {
    dispatch({ type: FormActions.RESET_FORM });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                usersDropdownData,
                labDropdownData,
                newCLick,
                saveClick,
              )}
              data={data}
              dispatch={dispatch}
              key={data._ts_key}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {messagesData?.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Message"
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={deleteClickAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(deleteClick) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      width: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },
    {
      headerName: "From",
      field: "DisplayFrom",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      width: 170,
    },
    {
      headerName: "To",
      field: "DisplayTo",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      width: 170,
    },
    {
      headerName: "User",
      field: "FullName",
      valueFormatter: ({ value }) => (value === null ? "All" : value),
      width: 170,
    },
    {
      headerName: "Laboratory",
      field: "Laboratory",
      width: 170,
    },
    {
      headerName: "Created",
      field: "Created",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      width: 200,
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 170,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Message",
      field: "Message",
      minWidth: 300,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}

function GetFormSections(
  usersDropdownData,
  labDropdownData,
  newCLick,
  saveClick,
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "DisplayFrom",
          label: "Display From",
          type: "date",
        },
        {
          name: "DisplayTo",
          label: "Until",
          type: "date",
          disablePast: true,
        },

        {
          name: "NoEndDate",
          label: "No End Date",
          xsCol: 2,
          type: "switch",
        },
        {
          name: "UserId",
          label: "Show To User",
          type: "dropdown",
          xsCol: 2,
          required: true,
          selectOptions: (usersDropdownData || []).map((x) => ({
            value: x.UserId,
            text: x.FullName,
          })),
        },
        {
          name: "LaboratoryId",
          label: "Laboratory",
          type: "dropdown",
          xsCol: 2,
          required: true,
          selectOptions: (labDropdownData || []).map((x) => ({
            value: x.LaboratoryId,
            text: x.Laboratory,
          })),
        },
        {
          name: "Message",
          label: "Message",
          type: "textarea",
          xsCol: 6,
        },
        {
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={newCLick}
                sx={{ mt: "23px" }}
                startIcon={<NewIcon />}
              >
                New
              </Button>
            );
          },
        }, {
          xsCol: 2,
          component: function ({ data }) {
            return (
              <Button
                variant="contained"
                onClick={saveClick}
                sx={{ mt: "23px" }}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            );
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchFilterDefaults, _ts_key: Date.now() };
  }
  return state;
}
const SearchFilterDefaults = {
  DisplayFrom: moment().toISOString(),
  DisplayTo: moment().toISOString(),
  LaboratoryId: 4,
};
