import { Check, Delete } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import AppDialog from "../../../components/AppDialog";
import AlertDialog from "../../../components/AlertDialog";
import appResources from "../../../app-resources/en/Resources.json";
import { useRoot } from "../../../rootContext";

export default function ViewSiteSets({
  cancel,
  parentsData,
  refreshParentsData,
  handleNewParentClick,
}) {
  const { products, CanDoDataMaintenance } = useRoot();
  const [data, dispatch] = useReducer(FluidReducer, parentsData);
  const [masterData, setMasterData] = useState();
  const { get, post } = useFetch();
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const actions = {
    cancel: cancel,

    deleteParent: function () {
      setOpenDialog(true);
      setAlertTitle(`Site Set Management ${data.SiteSet}`);
      setAlertMessage(
        `${appResources.DELETE_SITESETS_CONFIRMATION_MSG}\nSite Set: ${data.SiteSet}\nProduct: ${data.Product}\nSiteSetId: ${data.SiteSetId}`
      );
    },

    deleteParentAlert: async function () {
      await get(
        `${FLEET_APIS.DELETE_SITE_SET}?SiteSetId=${data.SiteSetId}&Screen=Site Sets`
      );
      setOpenDialog(false);
      handleNewParentClick();
      refreshParentsData();
    },

    save: async function (e) {
      dispatch({ type: FormActions.RESET_FORM });
      const response = await post(FLEET_APIS.SAVE_SITE_SET, {
        ...data,
        SiteSetId: data.SiteSetId ? data.SiteSetId : null,
        SiteSet: data.SiteSet ? data.SiteSet : null,
        ProductId: data.ProductId ? data.ProductId : null,
        IsArchived: data.IsArchived ? true : false,
        Screen: "Site Sets",
      });
      refreshParentsData();
    },
  };
  async function fetchProductType() {
    const temp = products;
    setMasterData(temp);
    return temp;
  }
  useEffect(
    function () {
      (async function () {
        await fetchProductType();
      })();
    },
    [products]
  );

  function FormSections(masterData) {
    return [
      {
        fields: [
          {
            xsCol: 12,
            group: [
              {
                xsCol: 6,
                name: "SiteSet",
                label: "Site Set",
              },
              {
                xsCol: 6,
                name: "IsArchived",
                label: "Archived",
                type: "switch",
                disabled: (data) => data.IsAutoUpdated,
              },
            ],
          },
          {
            xsCol: 12,
            name: "IsAutoUpdated",
            label: "Auto Updated",
            type: "switch",
            disabled: (data) =>
              data.IsAutoUpdated || !data.SiteSetId || !data.IsAutoUpdated,
          },
          {
            xsCol: 12,
            group: [
              {
                xsCol: 6,
                name: "ProductId",
                label: "Product",
                disabled: (data) => !!data.SiteSetId,
                type: "dropdown",
                selectOptions: (masterData || []).map((x) => ({
                  value: x.ProductId,
                  text: x.Product,
                })),
                required: true,
              },
            ],
          },
        ],
      },
    ];
  }
  return (
    <>
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Form
            sections={FormSections(masterData)}
            data={data}
            dispatch={dispatch}
            masterData={masterData}
            key={data && data._key}
          />
        </Grid>
        {CanDoDataMaintenance && <Grid item sx={{ display: "flex" }} xs={12}>
          <FormFooter
            buttons={GetFormButtons(parentsData, actions)}
            hideDivider
            gmt={0}
          />
        </Grid>}
      </Grid>
      <AppDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={alertTitle}
        maxWidth={"md"}
      >
        <AlertDialog
          handleClose={() => {
            setOpenDialog(false);
          }}
          alertAction={actions.deleteParentAlert}
          message={alertMessage}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, { cancel, save, deleteParent }) => [
  {
    variant: "outlined",
    maxWidth: 1,
    label: "Delete",
    onClick: deleteParent,
    startIcon: <Delete />,
    disabled: data.IsAutoUpdated || !data.SiteSetId,
  },
  {
    variant: "contained",
    maxWidth: 1,
    label: "Save",
    type: "submit",
    onClick: save,
    disabled: data.IsAutoUpdated || !data,
    startIcon: <Check />,
  },
];

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { _key: Date.now() };
  }
  return state;
}
