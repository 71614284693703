import React, { useRef } from "react";
import { Chart } from "chart.js";
import Grid from "@mui/material/Grid";
import { Scatter } from "react-chartjs-2";
import { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import SampleEventsTable from "./SampleEventsTable";
import AppDialog from "../../components/AppDialog";
import EditEvent from "../events/EditEvent";
import { useLocation } from "react-router-dom";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
} from "@mui/material";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ChartAnnotations from "chartjs-plugin-annotation";
import "chartjs-adapter-moment";
import AddEvent from "../events/AddEvent";
import { TRAY_APIS } from "../../common/apis";
//updating chart
Chart.register(ChartDataLabels);
Chart.register(ChartAnnotations);
let annotationDataArray = [];
let sampleNumber = 0;
const TabEventChart = ({ sampleId }) => {
  const [isShowFluidEvents, setIsShowFluidEvents] = useState(true);
  const chartReference = useRef();
  var timeFormat = "MM/dd/yyyy";

  const anno = [
    {
      type: "line",
      xMin: 3000,
      xMax: 3000,
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
    },
  ];

  const [options, setOptions] = useState({
    responsive: true,
    elements: {
      pointStyle: "star",
    },

    plugins: {
      datalabels: {
        display: false,
        color: "black",
        align: "top",
        formatter: function (value, context) {
          return value.y;
        },
      },
      annotation: {
        annotations: [],
      },
      legend: {
        position: "right",
        align: "center",
        labels: {
          boxWidth: 10,
          pointStyle: "cross",
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.raw.elementHover;
          },
          title: function (context) {
            return "";
          },
        },
      },
    },
  });

  const [chartData, setChartData] = useState(null);
  const [eqpMachineId, seteqpMachineId] = useState(null);
  const [chartDataByDate, setChartDataByDate] = useState(null);
  const [existingChartData, setExistingChartData] = useState(null);
  const [eventsData, setEventsData] = useState(null);
  const [eventsRowData, setEventsRowData] = useState();

  // Sample realted data vars
  const [productId, setProductId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [unitId, setUnitId] = useState(null);

  /////
  const [openEditEvent, setOpenEditEvent] = useState(false);
  const [addEditEvent, setAddEditEvent] = useState(false);
  const [hideEventsTable, setHideEventsTable] = useState(true);
  const [eventFilter, setEventFilter] = useState({});
  const { get } = useFetch();

  function eventCb(event) {
    if (event.storageArea != localStorage) return;
    if (event.key === "selectedSampleNo") {
      sampleNumber = localStorage.getItem("selectedSampleNo");
      const eqpmid = localStorage.getItem("EqpMachineId");
      seteqpMachineId(eqpmid);
      localStorage.removeItem("selectedSampleNo");
      localStorage.removeItem("EqpMachineId");
      fetchData(sampleNumber);
      document.getElementById("insightsframe")
        ?.contentWindow?.location.reload(true);
    }
  }

  useEffect(function () {

    window.addEventListener("storage", eventCb);
    return function () {
      window.removeEventListener("storage", eventCb);
    };
  });

  async function fetchData(sampleNumbers) {
    sampleNumber = sampleNumbers;
    let filterEventsData = [];
    if (
      sampleNumber &&
      sampleNumber !== "undefined" &&
      sampleNumber !== "null"
    ) {
      const sampleData = await get(
        `${TRAY_APIS.SAMPLE_CHART}?SampleNumber=${sampleNumber}&IncludeLaterSamples=true`
      );

      //To set the Repairs,Change component and change meter data
      filterEventsData = sampleData?.EventLogsBySampleNumber?.filter(
        (item) =>
          item.EventTypeId === 1 ||
          item.EventTypeId === 2 ||
          item.EventTypeId === 7
      );
      annotationDataArray = filterEventsData?.map((item) => ({
        type: "line",
        xMin: item?.UnitHours,
        xMax: item?.UnitHours,
        borderColor: "rgba(178, 178, 178, 0.8)",
        borderWidth: 1,
        margin: 5,
        label: {
          backgroundColor: "transparent",
          content: item?.EventType,

          display: true,
          position: "end",
          color: "rgba(178, 178, 178, 0.8)",
        },
      }));
      //set the option annotations
      setOptions({
        responsive: true,
        elements: {
          pointStyle: "star",
        },

        plugins: {
          datalabels: {
            display: false,
            color: "black",
            align: "top",
            formatter: function (value, context) {
              return value.y;
            },
          },
          annotation: {
            annotations: annotationDataArray,
          },
          legend: {
            position: "right",
            align: "center",
            labels: {
              boxWidth: 10,
              pointStyle: "cross",
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return context.raw.elementHover;
              },
              title: function (context) {
                return "";
              },
            },
          },
        },
      });
      setChartData(sampleData && sampleData.chartData);
      setExistingChartData(sampleData && sampleData.chartData);
      setChartDataByDate(sampleData && sampleData.chartDataByDate);
      setEventsData(sampleData && sampleData.EventLogsBySampleNumber);
      setCustomerId(sampleData && sampleData.CustomerId);
      setProductId(sampleData && sampleData.ProductId);
      setSiteId(sampleData && sampleData.SiteId);
      setUnitId(sampleData && sampleData.UnitId);
      setEventFilter({
        siteId: sampleData?.SiteId,
        unitId: sampleData?.UnitId,
        customerId: sampleData?.CustomerId,
        productId: sampleData?.ProductId,
        sampleNo: sampleData?.SampleNumber,
        componentId: sampleData?.ComponentId,
      });
    }
  }

  useEffect(function () {
    fetchData(sampleId);
    const query = new URLSearchParams(window.location.search);
    const data = query.get("EqpMachineId");
    seteqpMachineId(data);
  }, []);

  function sampleRowSelectedHandler(sample) {
    setEventsRowData(sample);
    setOpenEditEvent(true);
    setEventFilter({
      siteId: siteId,
      unitId: unitId,
      customerId: customerId,
      productId: productId,
      eventLogId: sample.EventLogId,
      sampleNo: sample.SampleNumber,
      componentId: sample.ComponentId,
    });
  }
  function handleHideEventsClick() {
    if (hideEventsTable) {
      setHideEventsTable(false);
    } else {
      setHideEventsTable(true);
    }
  }

  function setChartDateView(isShow) {
    if (isShow) {
      setOptions({
        ...options,
        parsing: {
          xAxisKey: "x1",
        },
        scales: {
          x: {
            type: "time",
            position: "bottom",
            timeFormat: "MM/DD/YYYY",
            time: {
              parser: "MM/DD/YYYY",
              unit: "day",
              unitStepSize: 1,
              displayFormats: {
                day: "MM/DD/YYYY",
              },
            },
          },
        },
      });
    } else {
      setOptions({
        ...options,
        parsing: {
          xAxisKey: "x",
        },
      });
    }
  }
  const handleSampleDateChkChange = (event, val) => {
    setChartDateView(val);
  };

  function setChartOptions(isShow) {
    setOptions({
      elements: {
        pointStyle: "cross",
      },
      plugins: {
        datalabels: {
          display: isShow,
          color: "black",
          align: "top",
          formatter: function (value, context) {
            return value.y;
          },
        },
        annotation: {
          annotations: annotationDataArray,
        },
        legend: {
          position: "right",
          align: "center",
          labels: {
            boxWidth: 10,
            pointStyle: "cross",
          },
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return context.raw.elementHover;
            },
            title: function (context) {
              return "";
            },
          },
        },
      },
    });
  }

  const handleValueChkChange = (event, val) => {
    setChartOptions(val);
  };
  const handelFluidChangedCheck = (event, val) => {
    setIsShowFluidEvents(val);
  };
  const handleAddEventClick = () => {
    setAddEditEvent(true);
  };
  function handleRefresh() {
    window.location.reload(true);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isCloseWindow = localStorage.getItem("isCloseWindow");
      if (isCloseWindow === "true") {
        window.close();
      }
    }, 2000); // Interval set to 1 seconds (1000 milliseconds)

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        {eqpMachineId !== "null" &&
          eqpMachineId !== undefined &&
          eqpMachineId !== "undefined" &&
          eqpMachineId !== null ? (
          <Grid xs={3}>
            <iframe
              id="insightsframe"
              title="insights frame"
              src={
                "https://insiteuat.komatsu.com.au/profile/" + // For UAT
                //"https://insite.komatsu.com.au/profile/" + // For PROD
                eqpMachineId +
                "/overview"
              }
              height={"100%"}
              width={"100%"}
            />
          </Grid>
        ) : (
          <></>
        )}

        <Grid xs={eqpMachineId != "null" ? 9 : 12}>
          <Stack direction="row" spacing={2}>
            <FormGroup>
              <FormControlLabel
                onChange={handleValueChkChange}
                control={<Switch />}
                label="Values"
                sx={{ mt: -0.5, ml: 6 }}
              />
            </FormGroup>
            {/* <FormGroup>
              <Button onClick={handleRefresh}>Refresh</Button>
            </FormGroup> */}
            <FormGroup>
              <Button onClick={handleHideEventsClick}>Show/ Hide Events</Button>
            </FormGroup>
            {!hideEventsTable && (
              <FormGroup>
                <FormControlLabel
                  onChange={handelFluidChangedCheck}
                  control={<Switch />}
                  checked={isShowFluidEvents}
                  label="Hide Fluid Changed Events"
                  sx={{ mt: -0.5, ml: 2 }}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Button onClick={handleAddEventClick}>Add Event</Button>
            </FormGroup>
          </Stack>
          <Grid item xs={12} sx={{ pl: 2 }} hidden={hideEventsTable}>
            {chartData && (
              <SampleEventsTable
                rowSelected={sampleRowSelectedHandler}
                data={
                  isShowFluidEvents
                    ? eventsData
                    : eventsData?.filter((item) => item?.EventTypeId !== 3)
                }
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              minHeight: hideEventsTable == true ? 800 : 580,
              maxHeight: hideEventsTable == true ? 1000 : 550,

              pl: 2,
            }}
          >
            {chartData && (
              <Scatter
                ref={chartReference}
                // style={{ width: "60%", height: "60%" }}

                type="Scatter"
                data={chartData}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <AppDialog
        open={addEditEvent}
        title="Edit Event"
        maxWidth="lg"
        handleClose={() => setAddEditEvent(false)}
      >
        <AddEvent
          cancel={() => setAddEditEvent(false)}
          eventFilter={eventFilter}
          eventsRowData={eventsRowData}
          setAddEditEvent={setAddEditEvent}
          fetchData={fetchData}
          sampleNumber={sampleNumber}
        />
      </AppDialog>
      <AppDialog
        open={openEditEvent}
        title="Edit Event"
        maxWidth="lg"
        handleClose={() => setOpenEditEvent(false)}
      >
        <EditEvent
          cancel={() => setOpenEditEvent(false)}
          eventFilter={eventFilter}
          eventsRowData={eventsRowData}
          setOpenEditEvent={setOpenEditEvent}
          fetchData={fetchData}
        />
      </AppDialog>
    </>
  );
};

export default TabEventChart;
