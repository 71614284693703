import NewDataTable from "../../../components/NewDataTable";

export default function HistoryTableNew({
  data = [],
  columns1,
}) {
  const tblOptions1 = {
    sx: { height: 320 },
    columns: columns1 || [],
    dataSource: data || [],
    enableColumnActions: false,
    enableSorting: false,
    enablePinning: true,
    enableEditing: false,
    initialState: { columnPinning: { left: ['SampleNumber'] } },
    muiTableBodyCellProps: ({ row }) => {
      return ({
        sx: {
          py: 0.25, px: 0.25, color: row.original.AlertRank === null ? "" : 'white',
          backgroundColor:
            row.original.AlertRank === 1
              ? "#22925C !important"
              : row.original.AlertRank === 2
                ? "DodgerBlue !important"
                : row.original.AlertRank === 3
                  ? "orange !important"
                  : row.original.AlertRank === 4
                    ? "DeepPink !important"
                    : row.original.AlertRank === 5
                      ? "red !important"
                      : "",
        },
      });
    },
    muiTableHeadCellProps: ({ row }) => {
      return ({
        align: "left",
        sx: { borderRight: "1px solid #E0E0E0", py: 0.5, px: 0.25, }
      });
    },
  };
  return <NewDataTable {...tblOptions1} />;
}
