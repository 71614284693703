import { Box, ClickAwayListener, Typography } from "@mui/material";
import TreeItem from "../../../components/tree/TreeItem";
import getNodeId, { parseNodeId } from "./nodeId";
import TreeMenu from "../TreeMenu";
import { useState } from "react";
import GetStepNames from "../../../common/GetStepNames";

export default function Sample(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPop, setOpenPop] = useState(false);

  const mouseDownHandler = (event) => {
    if (event.button === 2) {
      setAnchorEl(event.currentTarget);
      setOpenPop(true);
    }
  };

  const handleSelect = (name) => {
    props.handleRightSelect(name, props);
    setOpenPop(false)
    setAnchorEl(null)
  };

  const nodeId = getNodeId(props.pathItems);
  let node123 = parseNodeId(props?.selectedNode)

  return (
    <>
      <TreeItem
        selectedNode={Object.entries(node123)?.length <= 8 && node123.sample == props.SampleNumber ? props?.selectedNode : ""}
        nodeId={nodeId}
        label={
          <Box
            sx={Object.entries(node123)?.length <= 8 && node123.sample == props.SampleNumber
              ? { backgroundColor: getLabelColor(props), color: "white", minWidth: "180px", width: "auto" }
              : { color: getLabelColor(props), minWidth: "180px", width: "auto" }}
            onClick={(e) => props.handleLabelClick(e, nodeId)}
          >
            <Typography sx={{ width: "180px", }}>
              <pre style={{ margin: 0, display: "inline", fontSize: "12px" }}>  {props.SampleNumber}</pre>  {getLabelText(props)}
            </Typography>
          </Box>
        }
        onMouseDown={(e) => mouseDownHandler(e)}
      >
        {props.children}
      </TreeItem>
      {openPop && <ClickAwayListener onClickAway={() => setOpenPop(false)}>
        <TreeMenu options={Options} anchorEl={anchorEl} openPop={openPop} setOpenPop={setOpenPop} handleClick={handleSelect} />
      </ClickAwayListener>}
    </>
  );
}

const getLabelColor = (props) => {
  if (props.NewVersion == true) {
    return "gray";
  }
  else if (props.IsReleased == false) {
    return props.Colour != null
      ? props.Colour
      : "black";
  }
  else if (props.IsArchived == true) {
    return "gray";
  }
  else if (props.LaboratoryId == 0) {
    return props.Colour != null
      ? props.Colour
      : "black";
  } else if (props.IsInternalOnly == true) {
    return props.Colour != null
      ? props.Colour
      : "black";
  }
  else if (props.IsPredelivery == true) {
    return props.Colour != null
      ? props.Colour
      : "black";
  }
  else {
    return props.Colour != null
      ? props.Colour
      : "black";
  }
};

const getLabelText = (props) => {
  let texts;
  if (props.NewVersion == true) {
    return texts = "  New Version";
  }
  else if (props.IsReleased == false) {
    return texts = "  " + GetStepNames(props.Step)?.mid || "";
  }
  else if (props.IsArchived == true) {
    return texts = "  Archived";
  }
  else if (props.LaboratoryId == 0) {
    return texts = "  Import";

  } else if (props.IsInternalOnly == true) {
    return texts = "  Internal";

  } else if (props.IsPredelivery == true) {
    return texts = "  Predelivery";

  }

  if (props.IsSampleTypeDefault == false && props.TypeCode != null) {
    return texts += "  " + props.TypeCode;
  }

  if (props.ImageCount == true && props.ImageCount.Value > 0) {
    //child.Text += "  " + (props.ImageCount.Value > 1 ? props.ImageCount.Value + " Images" : "1 Image");
    return texts += "  Images " + props.ImageCount.Value;
  }
  else {
    return texts = "";
  }
};


const Options = [
  {
    label: "Copy or Add to Selected",
    name: "null",
    items: [
      { label: "Add Sample to Email Selected Items", name: "email-selected-items-sample" },
      { label: "Copy Sample Number", name: "copy-sample-number-sample" },
    ],
  },
  {
    label: "Tree",
    name: "null",
    items: [
      // { label: "Lift to Top", name: "lift-to-top" },
      // { label: "Go to Parent", name: "go-to-parent" },
      // { label: "Collapse Tree", name: "collapse-tree" },
      { label: "Refresh", name: "refresh-tree" },
    ],
  },
  { label: "Sample Report", name: "sample-report", },
  {
    label: "Additional Reports",
    name: "null",
    items: [
      { label: "Reports by Date", name: "reports-by-date" },
      { label: "Full History as XLSX", name: "history-as-xlsx" },
      { label: "Full History as XML", name: "history-as-xml" },
      { label: "Components No Recent Samples", name: "components-no-recent-samples" },
      { label: "Units No Recent Samples", name: "units-no-recent-samples" },
      { label: "Reference XSD", name: "reference-xsd" },
    ],
  },
  { label: "Unit Hour History", name: "unit-hour-history", },
  { label: "Recent Unit Results", name: "recent-unit-results", },
  { label: "Recent Component Results", name: "recent-component-results", },
  { label: "Add Critical Log", name: "critical-log", },
  {
    label: "Events",
    name: "null",
    items: [
      { label: "View Events", name: "view-event" },
      { label: "New Events", name: "edit-event" },
    ],
  },
  {
    label: "Edit",
    name: "null",
    items: [
      { label: "Sample", name: "edit-sample" },
      { label: "Tray", name: "edit-tray" },
      { label: "Component", name: "edit-component" },
      { label: "Unit", name: "edit-unit" },
      { label: "Site", name: "edit-site" },
      { label: "Customer", name: "edit-customer" },
    ],
  },
  {
    label: "New",
    name: "null",
    items: [
      { label: "Special Component Report", name: "special-component-report" },
      { label: "Special Unit Report", name: "special-unit-report" },
      { label: "Component", name: "add-new-component" },
      { label: "Unit", name: "add-new-unit" },
      { label: "Site", name: "add-new-site" },
      { label: "Customer", name: "add-new-customer" },
    ],
  },
  {
    label: "Manage",
    name: "null",
    items: [
      { label: "Sample", name: "manage-sample" },
      { label: "Component", name: "manage-component" },
      { label: "Unit", name: "manage-unit" },
      { label: "Site", name: "manage-site" },
      { label: "Customer", name: "manage-customer" },
    ],
  },
  {
    label: "Contacts",
    name: "null",
    items: [
      { label: "Access Permission", name: "access-permission-overview" },
      { label: "Sample Reporting", name: "report-contacts" },
      { label: "Primary Contacts", name: "primary-contacts-overview" },
      { label: "Subscriptions", name: "subscription-overview" },
      { label: "Exports", name: "export-contacts-overview" },
    ],
  },
];
