import { Save } from "@mui/icons-material";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LabSteps, ResultColWidths } from "../../../common/Constants";
import DropDown from "../../../components/DropDown";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import { useRoot, useUser } from "../../../rootContext";
import { DateFormat, copyTextToClipboard, toLocalDate } from "../../../utils";
import HistoryTable from "./HistoryTable";
import ResultsTable from "./ResultsTable";
import SamplesTable from "./SamplesTable";
import SearchFilter from "./SearchFilter";
import Hold from "./Hold";
import AppDialog from "./../../../components/AppDialog";
import ImagePick from "./../../../components/SelectImage";
import SwitchInput from "../../../components/SwitchInput";
import { TRAY_APIS } from "../../../common/apis";
import TickBlue from "../../../Image/TickBlue.svg";
import TickGreen from "../../../Image/TickGreen.svg";
import appResources from "../../../app-resources/en/Resources.json";
import {
  ChartsIcon,
  HoldIcon,
  ImagesIcon,
  ReleaseIcon,
  ReleaseSampleIcon,
} from "../../../icons/ButtonIcons";
import ResizePanel from "react-resize-panel";
import { useScreenPopups } from "../../../components/useScreenPopups";
import GroupDialog from "../../../components/GroupDialog";
import { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import { BadgeButton } from "../../../components/FormFooter";
import renderCellExpand from "../../../components/DatatableCellExpand";

let columnsTestItem = "";
export default function Laboratory({ laboratoryActions }) {
  const { post, get, getFile } = useFetch();
  const user = useUser();
  const { CanLaboratoryTrays } = useRoot();

  const [samplesData, setSamplesData] = useState({});
  const [results, setResults] = useState({});
  const [lockResults, setLockResults] = useState(true);
  const [isCompletedSample, setIsCompletedSample] = useState(false);
  const [isCompletedTray, setIsCompletedTray] = useState(false);
  const [infoMessage, setInfoMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [dialogType, setDialogType] = useState({ open: false });
  const [holdOpen, setHoldOpen] = useState(false);
  const [selectedSampleNo, setSelectedSampleNo] = useState(0);
  const [selectedSample, setSelectedSample] = useState(0);
  const [filterState, setFilterState] = useState();
  const [setText, setEnteredText] = useState("");
  const [imageCount, setImageCount] = useState("");
  const [history, setHistory] = useState({});
  const [isShowImage, setIsShowImage] = useState(true);
  const [viewType, setViewType] = useState(0);
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
  });

  useEffect(() => {
    document.title = "Laboratory";
  }, []);

  function sampleInputOnChangeHandler(e) {
    if (selectedSample.Step > 1) {
      setLockResults(e.target.checked);
      setIsWarningTrue(false);
      // setIsSuccessIcon(false);
      setIsInfoIcon(false);
      setInfoMessage();
    } else {
      setIsWarningTrue(true);
      //  setIsSuccessIcon(false);
      setIsInfoIcon(true);
      setInfoMessage(
        `Unable to update cell value. The sample ${selectedSample.SampleNumber} is at registration step at the moment.`
      );
    }
  }

  //useEffect to handle click any where on the screen
  useEffect(() => {
    function handleClick(event) {
      setIsShowImage(false);
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  function handleImageClick() {
    setDialog({
      open: true,
      title: `Sample Images: Sample Number ${selectedSampleNo}`,
    });
  }
  // Imran Zulfiqar

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F4" && samplesData?.Tray?.length > 0) {
        event.preventDefault();
        handleChartClick();
      } else if (event.key === "F2") {
        event.preventDefault();
        if (CanLaboratoryTrays) {
          setHoldOpen(true);
        }
      } else if (event.key === "F3") {
        event.preventDefault();
        handleImageClick();
      } else if (event.key === "F12") {
        event.preventDefault();
        if (
          CanLaboratoryTrays &&
          selectedSample.Step > 1 &&
          selectedSample.HoldType === null
        ) {
          ReleaseSampleClick();
        }
      } else if (event.key === "F11") {
        event.preventDefault();
        if (
          CanLaboratoryTrays &&
          samplesData?.Tray?.every((sample) => sample.HoldType === null) &&
          samplesData?.Tray?.filter((item) => item.AlertColour === "Green")
            .length >
          samplesData?.Tray?.length - 4
        ) {
          ReleaseTrayClick();
        }
      }
    },
    [samplesData, selectedSample]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  async function onViewHandler(filter) {
    if (filter.Date === "Invalid date") {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }

    setIsWarningTrue(false);
    setIsInfoIcon(false);
    setInfoMessage();
    setFilterState(filter);
    const resp = await post(TRAY_APIS.GET_LABORATORY, {
      Date:
        filter.LabAndDateOption === 2 || filter.LabAndDateOption === 4
          ? null
          : moment(filter.Date).format(DateFormat),
      LabId:
        filter.LabAndDateOption === 3 || filter.LabAndDateOption === 4
          ? null
          : user.DefaultLaboratoryId,
      TrayNumber: filter.LabAndDateOption === 0 ? filter.Tray : null,
      MinimumStep: LabSteps.Registration,
      MaximumStep:
        filter.LabAndDateOption === 0
          ? LabSteps.Completed
          : LabSteps.Laboratory,
      Hold: true,
    });
    if (resp?.Tray.length < 1) {
      setSamplesData(resp);
      setIsWarningTrue(true);
      //  setIsSuccessIcon(false);
      setIsInfoIcon(true);

      return setInfoMessage(appResources.TRAY_DOES_NOT_EXIST);
    } else {
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setInfoMessage();
      setSamplesData(resp);
    }

    if (resp.Tray.length > 0) {
      setSelectedSampleNo(resp.Tray[0].SampleNumber);
      setSelectedSample(resp.Tray[0]);
      // setSelectedSampleNo(sampleData.Tray[0].SampleNumber);
      // getData();
    } else {
      setSelectedSampleNo(0);
      setEnteredText("");
    }
  }

  async function refreshGrid() {
    if (filterState) {
      onViewHandler(filterState);
    }
  }

  useEffect(() => {
    if (samplesData.TrayResults) {
      const { data, columns } = getDataAndColumns(
        samplesData,
        lockResults,
        setIsCompletedTray
      );
      setResults({ data, columns });
      return;
    }
    setResults({});
  }, [lockResults, samplesData]);

  useEffect(() => {
    getData();
  }, [selectedSampleNo]);

  function handleChartClick() {
    localStorage.removeItem("isCloseWindow");
    localStorage.setItem("isCloseWindow", false);
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    var url = `/Chart?EqpMachineId=${selectedSample?.EqpMachineId}&sampleId=${selectedSampleNo}&hideProp=2`;
    var windowFeatures =
      "width=" +
      windowWidth +
      ",height=" +
      windowHeight +
      ",top=0,left=0,resizable,scrollbars=yes";

    window.open(url, "_blank", windowFeatures);
  }

  function handelViewTypeChange(e) {
    setViewType(e.target.value);
  }

  function sampleRowSelectedHandler(sample) {
    if (sample.SampleNumber === selectedSampleNo) {
      return;
    }
    setIsWarningTrue(false);
    //   setIsSuccessIcon(false);
    setIsInfoIcon(false);
    //  setSuccessMessage();
    let totalValCount = 0;
    let hasValCount = 0;
    for (const key in sample) {
      if (
        (key.endsWith("_hasVal") || key.endsWith("_valReq")) &&
        sample[key] === true
      ) {
        totalValCount++;
      }
    }
    for (const key in sample) {
      if (key.endsWith("_hasVal") && sample[key] === true) {
        hasValCount++;
      }
    }
    if (totalValCount === hasValCount && totalValCount > 0 && hasValCount > 0) {
      setIsCompletedSample(true);
    } else {
      setIsCompletedSample(false);
    }

    setSelectedSampleNo(sample.SampleNumber);
    setSelectedSample(sample);
    if (sample.Step < 2) {
      setLockResults(true);
    }
    localStorage.removeItem("selectedSampleNo");
    window.localStorage.setItem("selectedSampleNo", sample.SampleNumber);
    getData();
  }

  const nonHoldSamples = useMemo(
    () => (samplesData.Tray || []).filter((x) => x.HoldType === null),
    [samplesData]
  );

  async function ReleaseSampleClick() {
    const response = await post(TRAY_APIS.RELEASE_SAMPLE, {
      SampleNumber: selectedSample.SampleNumber,
      Note: "",
      Screen: "Laboratory",
    });
    if (response > 0) {
      await refreshGrid();
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      //  setIsSuccessIcon(true);
      // setSuccessMessage(
      //   `${selectedSample.SampleNumber} has been released successfully`
      // );
    }
  }
  async function ReleaseTrayClick() {
    const response = await post(
      TRAY_APIS.RELEASE_SAMPLES,
      nonHoldSamples.map((x) => x.SampleNumber)
    );
    if (response > 0) {
      await refreshGrid();
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      //  setIsSuccessIcon(true);
      //  setSuccessMessage(appResources.TRAY_RELEASED);
    }
  }

  function handleSaveClick() {
    saveNotes();
  }

  useEffect(
    function () {
      (async function () {
        if (!selectedSampleNo) {
          setHistory({});
          return;
        }

        const sampleData = await get(
          `${TRAY_APIS.HISTORY}?sampleNo=${selectedSampleNo}&includeLaterSamples=true&resultView=0`,
          {}
        );
        const { columns, data } =
          viewType === 1
            ? getDataAndColumnsPastSamplesHistory(
              sampleData,
              laboratoryActions,
              rowActionHandler
            )
            : viewType === 2
              ? getDataAndColumnsPastSamples(sampleData)
              : {};
        setHistory({ columns, data });
      })();
    },
    [selectedSampleNo, viewType]
  );

  function handleHoldClick() {
    setHoldOpen(true);
  }

  const rowEditHandler = useCallback(function (newRow, oldRow) {
    return new Promise(async function (resolve, reject) {
      const keyUpdated = Object.keys(newRow).find(
        (x) => newRow[x] !== oldRow[x]
      );
      let newValue = newRow[keyUpdated];
      // let nanCheck = isNaN(Number(newValue));
      // if (nanCheck === true) {
      //   newValue = 0;
      //   setIsWarningTrue(true);
      //   //   setIsSuccessIcon(false);
      //   setIsInfoIcon(true);
      //   return setInfoMessage(appResources.VALUES_BE_NUMERIC);
      // }
      setIsWarningTrue(false);
      //  setIsSuccessIcon(false);
      setIsInfoIcon(false);
      setInfoMessage();
      const filteredItems = columnsTestItem.filter(
        (item) => item.TestItemCode === keyUpdated
      );
      let testItemId = filteredItems.map((item) => item.TestItemId);

      if (keyUpdated && testItemId) {
        await get(
          `${TRAY_APIS.EDIT_RESULT_TEXT}?SampleNumber=${newRow.SampleNumber
          }&TestItemId=${testItemId}&formattedValue=${newValue === "" ? null : newValue
          }`
        );
      }
      // resolve({ ...newRow });
    });
  }, []);

  async function getSampleReport(row) {
    try {
      let fileName = `${row?.Customer} ${row?.Site} ${row?.Unit} ${row?.Component} ${row?.SampleNumber} `;
      await getFile(
        `${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`,
        fileName
      );
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  function getSampleGraph(row) {
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    var url = `/Chart?EqpMachineId=${selectedSample?.EqpMachineId}&sampleId=${selectedSampleNo}&hideProp=2`;
    var windowFeatures =
      "width=" +
      windowWidth +
      ",height=" +
      windowHeight +
      ",top=0,left=0,resizable,scrollbars=yes";

    window.open(url, "_blank", windowFeatures);
  }

  function rowActionHandler(row, actionName) {
    if (
      [
        "add-sample-images",
        "edit-sample",
        "hold-management",
        "edit-sample",
      ].indexOf(actionName) > -1
    ) {
      openDialog(row, actionName);
      return;
    } else if (actionName === "view-graphs") {
      return getSampleGraph(row);
    } else if (actionName === "view-sample-report") {
      return getSampleReport(row);
    } else if (actionName === "copy-sample-number") {
      return copyTextToClipboard(row?.SampleNumber);
    }
  }

  const dialogOptions = useScreenPopups({
    data: dialogType.data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
  });

  function openDialog(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <SearchFilter
                  onView={onViewHandler}
                  infoMessage={infoMessage}
                  successMessage={successMessage}
                  isInfoIcon={isInfoIcon}
                  isSuccessIcon={isSuccessIcon}
                  isWarningTrue={isWarningTrue}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={1} alignItems="flex-end"></Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {samplesData?.Tray?.length || 0}</b>
          </Typography>
          <ResizePanel direction="s" height="10px">
            <SamplesTable
              data={samplesData?.Tray || []}
              rowSelected={sampleRowSelectedHandler}
              selectedRow={[selectedSampleNo]}
              actions={laboratoryActions}
              rowActionHandler={rowActionHandler}
            />
          </ResizePanel>
        </Grid>

        <Grid
          item
          xs={12}
          hidden={samplesData && samplesData?.Tray?.length > 0 ? false : true}
        >
          <Card sx={{ p: 1 }}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextBox
                      label={"Notes"}
                      multiline
                      rows={3}
                      // key={selectedSampleNo}
                      readOnly={!CanLaboratoryTrays}
                      value={setText}
                      onChange={(v) => setEnteredText(v.target.value)}
                    />
                  </Grid>

                  <Grid item>
                    {isCompletedSample && (
                      <Box
                        component="img"
                        sx={{
                          height: 41,
                          marginTop: "20px",
                        }}
                        alt="TickBlue"
                        src={TickBlue}
                      />
                    )}{" "}
                    {isCompletedTray && (
                      <Box
                        component="img"
                        sx={{
                          height: 41,
                          marginTop: "20px",
                        }}
                        alt="TickGreen"
                        src={TickGreen}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {CanLaboratoryTrays && (
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<Save />}
                        onClick={handleSaveClick}
                        disabled={!samplesData?.Tray?.length}
                      >
                        Save Text
                      </Button>
                    </Grid>
                  )}
                  {CanLaboratoryTrays && (
                    <Grid item marginTop="7px">
                      <SwitchInput
                        value={lockResults || false}
                        label="Lock Results"
                        className={samplesData?.Tray?.length ? "red" : ""}
                        disabled={!samplesData?.Tray?.length}
                        onChange={sampleInputOnChangeHandler}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <DropDown
                      sx={{ width: "170px" }}
                      value={viewType}
                      name="viewType"
                      onChange={handelViewTypeChange}
                      disabled={!samplesData?.Tray?.length}
                      selectOptions={[
                        { text: "Result", value: 0 },
                        { text: "History", value: 1 },
                        { text: "History Simple", value: 2 },
                      ]}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<ChartsIcon />}
                      onClick={handleChartClick}
                      disabled={!samplesData?.Tray?.length}
                    >
                      Charts (F4)
                    </Button>
                  </Grid>

                  <Grid item>
                    <BadgeButton
                      variant="outlined"
                      startIcon={<ImagesIcon />}
                      badge={{ badgeContent: imageCount || 0, color: "error" }}
                      label="Images (F3)"
                      onClick={handleImageClick}
                    />
                  </Grid>

                  {CanLaboratoryTrays && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={handleHoldClick}
                        startIcon={<HoldIcon />}
                        disabled={!samplesData?.Tray?.length}
                      >
                        Hold (F2)
                      </Button>
                    </Grid>
                  )}

                  {CanLaboratoryTrays &&
                    selectedSample.Step > 1 &&
                    selectedSample.HoldType === null && (
                      <Grid item>
                        <Button
                          startIcon={<ReleaseSampleIcon />}
                          onClick={ReleaseSampleClick}
                        >
                          Release Sample (F12)
                        </Button>
                      </Grid>
                    )}

                  {CanLaboratoryTrays &&
                    samplesData?.Tray?.every(
                      (sample) => sample.HoldType === null
                    ) &&
                    samplesData?.Tray?.filter(
                      (item) => item.AlertColour === "Green"
                    ).length >
                    samplesData?.Tray?.length - 4 && (
                      <Grid item>
                        <Button
                          startIcon={<ReleaseIcon />}
                          onClick={ReleaseTrayClick}
                        >
                          {viewType > 0
                            ? "Release All (F11)"
                            : "Release Tray (F11)"}
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records:
            <b>
              {viewType === 0
                ? results.data
                  ? results.data.length
                  : 0
                : history.data
                  ? history.data.length
                  : 0}
            </b>
          </Typography>
          {viewType === 1 || viewType === 2 ? (
            <ResizePanel direction="s">
              <HistoryTable {...history} key={viewType} />
            </ResizePanel>
          ) : (
            <ResizePanel direction="s">
              <ResultsTable
                {...results}
                rowEditHandler={rowEditHandler}
                rowSelected={sampleRowSelectedHandler}
                selectedRow={[selectedSampleNo]}
              />
            </ResizePanel>
          )}
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={`Sample Images: Sample Number ${selectedSampleNo}`}
        maxWidth="xl"
      >
        <ImagePick sampleId={selectedSampleNo} setDialog={setDialog} />
      </AppDialog>
      <AppDialog
        open={holdOpen}
        handleClose={() => setHoldOpen(false)}
        title="Put on Hold from Laboratory"
        maxWidth="xl"
      >
        <Hold
          sampleId={selectedSampleNo}
          refreshGrid={refreshGrid}
          setHoldOpen={setHoldOpen}
        />
      </AppDialog>

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
    </>
  );

  async function getData() {
    if (selectedSampleNo === 0) {
      return;
    }
    const CommentsData = await get(
      `${TRAY_APIS.SAMPLE_NOT_IMAGE_COUNT}?SampleNumber=${selectedSampleNo}`
    );
    if (CommentsData[0].SampleNotes) {
      setEnteredText(CommentsData[0].SampleNotes);
    } else {
      setEnteredText("");
    }
    setImageCount(CommentsData[0].SampleImageCount);
  }

  async function saveNotes() {
    await post(TRAY_APIS.UPDATE_SAMPLE_NOTE, {
      SampleNumber: selectedSampleNo,
      Note: setText ? setText : null,
      Screen: "Laboratory",
    });
  }
}

function getDataAndColumns(
  { TrayResults: results, TestSetItems: testSetItems, Tray: sampleData },
  lockResults,
  setIsCompletedTray
) {
  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });
  const testItems = new Set();
  const testFilterItemsList = new Set();
  const dataObject = {};
  let testData = [];

  let allRegCheck =
    sampleData.filter((x) => x.Step < 2)?.length > 0 ? false : true;

  results.forEach(function (r) {
    if (r[0] != null) {
      if (!dataObject[r[0].SampleNumber]) {
        dataObject[r[0].SampleNumber] = { ...samplesMap[r[0].SampleNumber] };
      }
    }
    r.forEach(function (item) {
      testItems.add(item.TestItemCode + ": " + item.TestItem);
      testFilterItemsList.add(item.TestItemCode + ":" + item.TestItemId);
      var sample = dataObject[r[0].SampleNumber];
      sample[item.TestItemCode] = item.FormattedText;
      sample[item.TestItemCode + "_id"] = item.TestItemId;
    });
    testData = r.map((item) => {
      return {
        TestItemCode: item.TestItemCode,
        TestItem: item.TestItem,
        TestItemId: item.TestItemId,
      };
    });
  });
  const data = [];

  Object.keys(dataObject).forEach(function (key) {
    var sample = dataObject[key];
    (testSetItems[sample.TestSetId] || []).forEach(function (item) {
      if (!sample[item.TestItemCode]) {
        sample[item.TestItemCode + "_valReq"] = true;
        sample[item.TestItemCode + "_id"] = item.TestItemId;
        return;
      }
      sample[item.TestItemCode + "_hasVal"] = true;
    });
    data.push(sample);
  });

  let columns = [];
  // Condition goes here
  // if (Object.values(testSetItems)[0]?.length <= 0) {
  if (allRegCheck === false) {
    // columns = [...testItems].map((x) => {
    //   return ({
    //     headerName: x.split(":")[0],
    //     field: x.split(":")[0],
    //     testId: x.TestItemId,
    //     minWidth: ResultColWidths[x] || (x.split(":")[0].length < 3 ? 35 : 40),
    //     flex: 1,
    //     sortable: false,
    //     description: `${x.split(":")[0]}: ${x.split(":")[1]}`,
    //     editable: !lockResults,
    //   })
    // });
    columns = testData.map((x) => {
      return {
        headerName: x.TestItemCode,
        field: x.TestItemCode,
        testId: x.TestItemId,
        minWidth: ResultColWidths[x] || (x.TestItemCode.length < 3 ? 35 : 40),
        flex: 1,
        sortable: false,
        description: x.TestItem,
        editable: !lockResults,
        renderCell: renderCellExpand,
      };
    });
  } else {
    columns = Object.values(testSetItems)[0].map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      testId: x.TestItemId,
      minWidth:
        ResultColWidths[x] ||
        (x.TestItemCode.length < 3 ? 35 : x.TestItemCode.length < 5 ? 45 : 60),
      flex: 1,
      sortable: false,
      description: `${x.TestItemCode}: ${x.TestItem}`,
      editable: !lockResults,
      renderCell: renderCellExpand,
    }));
  }
  columnsTestItem = [...columns].map((x) => ({
    TestItemCode: x.field,
    TestItemId: x.testId,
  }));
  columns.unshift({
    headerName: "Sample Number",
    field: "SampleNumber",
    // minWidth: ResultColWidths["SampleNumber"] || 110,
    // maxWidth: ResultColWidths["SampleNumber"] || 110,
    minWidth: 110,
    maxWidth: 110,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  });

  //For Calculating Tray completion
  let totalValCounts = 0;
  let hasValCounts = 0;

  data.forEach((obj) => {
    for (const key in obj) {
      if (
        (key.endsWith("_hasVal") || key.endsWith("_valReq")) &&
        obj[key] === true
      ) {
        totalValCounts++;
      }
    }
  });

  data.forEach((obj) => {
    for (const key in obj) {
      if (key.endsWith("_hasVal") && obj[key] === true) {
        hasValCounts++;
      }
    }
  });

  if (
    totalValCounts === hasValCounts &&
    totalValCounts > 0 &&
    hasValCounts > 0
  ) {
    setIsCompletedTray(true);
  } else {
    setIsCompletedTray(false);
  }
  return { columns, data };
}
//Results for History Simple

function getDataAndColumnsPastSamples({
  PastSamplesElementData: data,
  PastSamples: sampleData,
}) {
  const dataObject = {};
  const colMap = {};
  const columns = [];

  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });

  data.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
    }
    dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    if (colMap[x.TestItemCode]) {
      return;
    }
    colMap[x.TestItemCode] = x;
    columns.push({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      minWidth: ResultColWidths[x.TestItemCode] || 50,
      flex: 1,
      renderCell: renderCellExpand,
    });
  });

  columns.unshift({
    headerName: "Sample Number",
    field: "SampleNumber",
    minWidth: ResultColWidths["SampleNumber"] || 80,
    flex: 1,
  });

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }

  return { columns, data: dataSrc };
}

//Results for History
function getDataAndColumnsPastSamplesHistory(
  { PastSamplesElementData: data, PastSamples: sampleData },
  laboratoryActions,
  rowActionHandler
) {
  const dataObject = {};
  const colMap = {};
  const columns = [];

  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });

  data.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
      // dataObject[x.SampleNumber].UnitHours =
      //   samplesMap[x.SampleNumber].UnitHours;
    }

    dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    if (colMap[x.TestItemCode]) {
      return;
    }

    colMap[x.TestItemCode] = x;
    columns.push({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      minWidth: ResultColWidths[x.TestItemCode] || 50,
      flex: 1,
      renderCell: renderCellExpand,
    });
  });

  const menuOptions = [
    { label: "View Sample Report", name: "view-sample-report" },
  ];

  const extraColumns = [
    // {
    //   headerName: "Sample Number",
    //   field: "SampleNumber",
    //   minWidth: ResultColWidths["SampleNumber"] || 80,
    //   flex: 1,
    // },
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      minWidth: 128,
      maxWidth: 128,
      renderCell: function ({ row }) {
        return (
          <>
            {(laboratoryActions || []).map(function (ac, i) {
              return (
                <DataGridAction
                  key={i}
                  row={row}
                  onClick={ac.action}
                  label={ac.label}
                  startIcon={ac.startIcon}
                />
              );
            })}
            <DropdownMenu
              options={menuOptions}
              onItemSelect={(option) => rowActionHandler(row, option)}
            />
            <Typography paddingTop={0.25}>{row.SampleNumber}</Typography>
          </>
        );
      },
    },
    {
      headerName: "UH",
      field: "UnitHours",
      description: "Unit Hours",
      minWidth: 49,
      flex: 1,
    },
    {
      headerName: "CH",
      field: "ComponentHours",
      description: "Component Hours",
      minWidth: 49,
      flex: 1,
    },
    {
      headerName: "CR",
      field: "ComponentRepaired",
      description: "Component Repaired",
      minWidth: 45,
      flex: 1,
    },
    {
      headerName: "Fluid",
      field: "FluidFormatted",
      minWidth: 150,
      flex: 1,
    },
    {
      headerName: "FH",
      field: "FluidHours",
      description: "Fluid Hours",
      minWidth: 50,
      flex: 1,
    },
    {
      headerName: "FC",
      field: "FluidChanged",
      description: "Fluid Changed",
      minWidth: 45,
      flex: 1,
    },
    {
      headerName: "Topup",
      field: "Topup",
      minWidth: 54,
      flex: 1,
    },
    {
      headerName: "Filter",
      field: "FilterChanged",
      minWidth: 55,
      description: "Filter Changed",
      flex: 1,
    },
  ];

  const extraColumns2 = [
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
      minWidth: 85,
      maxWidth: 85,
    },
    {
      headerName: "SIF",
      field: "SIF",
      minWidth: 70,
      maxWidth: 70,
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      minWidth: 80,
      maxWidth: 80,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Status",
      field: "AlertStatus",
      description: "Alert Status",
      minWidth: 90,
      maxWidth: 90,
    },
  ];

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }
  return {
    columns: [...extraColumns, ...columns, ...extraColumns2],
    data: dataSrc,
  };
}
