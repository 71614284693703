import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function UnitModelsTable({ data, rowSelected, selectedRow }) {
  const options = {
    columns: Columns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.UnitMakeId + x.TemplateId,
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    sx: {
      height: 750,
    },
  };
  return <DataTable {...options} />;
}

const Columns = [
  {
    headerName: "Make",
    field: "UnitMake",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Model",
    field: "UnitModel",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Verified",
    field: "Verified",
    width: 300,
    renderCell: renderCellExpand,
  },
];
