import { useTheme } from "@emotion/react";
import {} from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import useFetch from "../../hooks/useFetch";
import { MANAGEMENT_APIS } from "../../common/apis";
import AppDialog from "../../components/AppDialog";
import AlertDialog from "../../components/AlertDialog";
import { useToast } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";
import renderCellExpand from "../../components/DatatableCellExpand";
export default function CustomerSites({}) {
  const curTheme = useTheme();
  const { get } = useFetch();
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
  });
  const { showToast } = useToast();
  const [rowData, setRowData] = useState();

  const [customersSitesData, setCustomersSitesData] = useState([]);
  //call api for loading data

  useEffect(function () {
    document.title = "My Customers and Sites";
    resetData();
  }, []);
  async function resetData() {
    const response = await get(MANAGEMENT_APIS.OWNED_CUSTOMER);
    if (response.length > 0) {
      setCustomersSitesData(response);
    } else {
      setCustomersSitesData([]);
    }
  }
  //Columns Actions
  function deleteClick(row) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    setRowData(row);
  }
  async function deleteClickAlert() {
    await get(
      `${MANAGEMENT_APIS.DELETE_OWNED_CUSTOMER}?CustomerId=${
        rowData?.CustomerId
      }${rowData?.SiteId ? `&SiteId=${rowData?.SiteId}` : ""}`
    );
    await resetData();
    setDialog((prevState) => ({ ...prevState, open: false }));
    showToast(appResources.OWNED_CUSTOMER_DELETED_SUCCESSFULLY_MSG);
  }

  const tblOptions = {
    columns: GetColumns(deleteClick),
    dataSource: (customersSitesData || []).map((x) => ({
      ...x,
      keyProp: x.CustomerId + "" + (x.Customer || "") + "" + (x.SiteId || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: { height: 760 },
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records:{" "}
            <b> {customersSitesData && customersSitesData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Owned Customer"
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={deleteClickAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(deleteClick) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 200,
    },
    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 300,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      minWidth: 300,
      flex: 1,
    },
  ];
}
