import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function QuickMergeToModelTable({ data, rowSelected }) {
  const toModelOptions = {
    columns: toModelColumns,
    dataSource: data || [],
    rowIdProperty: "UnitMakeId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    sx: { height: 760 },
  };
  return <DataTable {...toModelOptions} />;
}
const toModelColumns = [
  {
    headerName: "To Make",
    field: "UnitMake",
    minWidth: 200,
    renderCell: renderCellExpand,
  },
  {
    headerName: "To Model",
    field: "UnitModel",
    minWidth: 170,
    renderCell: renderCellExpand,
  },
  {
    headerName: "To Verified",
    field: "Verified",
    minWidth: 170,
    flex: 1,
  },
];
