import { Delete } from "@mui/icons-material";
import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import useFetch from "../../hooks/useFetch";
import Form, { FormActions } from "../../components/Form";
import DataTable, { DataGridAction } from "../../components/DataTable";
import { MANAGEMENT_APIS } from "../../common/apis";
import { NewIcon, SaveIcon } from "../../icons/ButtonIcons";
import AppDialog from "../../components/AppDialog";
import AlertDialog from "../../components/AlertDialog";
import { useRoot, useToast, useUser } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";
import renderCellExpand from "../../components/DatatableCellExpand";

export default function PresetComments({ }) {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    defaultPresetComments
  );
  const user = useUser();
  const { CanDoDataMaintenance } = useRoot();
  const { showToast, showErrorToast } = useToast();
  const { get, post } = useFetch();
  const [presetData, setPresetData] = useState([]);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
  });
  //get api for loading data
  useEffect(() => {
    resetData();
  }, [data.IncludePersonal, data.IncludeShared, data.AllPersonal]);

  useEffect(() => {
    document.title = "Preset Comments";
  }, []);
  async function resetData() {
    const responseData = await post(MANAGEMENT_APIS.PRESET_COMMENTS, {
      ...data,
      UserId: user?.UserId,
    });
    setPresetData(responseData);
  }
  //use memo
  const filteredGridData = useMemo(
    () =>
      data.filterType === "Both"
        ? presetData
        : presetData.filter((item) => item.CommentType === data.filterType),
    [presetData, data.filterType]
  );

  const tblOptions = {
    columns: GetColumns(editClick),
    dataSource: filteredGridData || [],
    rowIdProperty: "CommentId",
    isDisableColumnFilter: true,
    sx: { height: 600 },
  };

  function editClick(rowData) {
    dispatch({
      payload: {
        ...rowData,
        //IncludePersonal: rowData.CommentUse,
        //Code: rowData.Code,
        filterType: rowData.CommentType,
        Type: rowData.CommentUse === "Personal" ? 0 : 1,
        useOption:
          rowData.CommentType === "Both"
            ? 0
            : rowData.CommentType === "Evaluation"
              ? 1
              : rowData.CommentType === "Recommendation"
                ? 2
                : 0,
        IncludePersonal: rowData.CommentUse === "Personal" ? true : false,
        IncludeShared: rowData.CommentUse === "Shared" ? true : false,
        _ts_key: rowData.Code,
      },
      type: EventActions.LOAD_DATA,
    });
  }
  //Save data
  function saveClick() {
    (async function () {
      await post(MANAGEMENT_APIS.EDIT_PRESET_COMMENTS, {
        ...data,
        CommentId: data.CommentId ? data.CommentId : null,
        CommentUserId: user?.UserId,
        ForEvaluations:
          data.useOption === 0 || data.useOption === 1 ? true : false,
        ForRecommendations:
          data.useOption === 0 || data.useOption === 2 ? true : false,
        UserId: user?.UserId,
        Screen: "Preset Comments",
      });
      await resetData();
    })();
  }
  //delete
  async function deleteClick(data) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    dispatch({
      payload: {
        ...data,
      },
    });
  }
  async function deleteClickAlert() {
    const response = await get(
      `${MANAGEMENT_APIS.DELETE_PRESET_COMMENTS}?CommentId=${data.CommentId}&Screen=Preset Comments`
    );
    if (response > 0) {
      showToast(appResources.DELETED_SUCCESSFULLY_MSG);
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }
    await resetData();
    setDialog((prevState) => ({ ...prevState, open: false }));
    dispatch({ type: FormActions.RESET_FORM });
  }
  function newCLick() {
    dispatch({ type: FormActions.RESET_FORM });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(newCLick, deleteClick, saveClick)}
              data={data}
              dispatch={dispatch}
              key={data._ts_key}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredGridData?.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Preset Comment"
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={deleteClickAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(editClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "User",
      field: "Fullname",
      minWidth: 200,
    },
    {
      headerName: "Type",
      field: "CommentType",
      minWidth: 150,
    },
    {
      headerName: "Use",
      field: "CommentUse",
      minWidth: 150,
    },
    {
      headerName: "Code",
      field: "Code",
      minWidth: 150,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Comment",
      field: "Comment",
      minWidth: 500,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}

function GetFormSections(newCLick, deleteClick, saveClick) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          group: [
            {
              name: "IncludePersonal",
              label: "Personal",
              type: "switch",
            },
            {
              name: "IncludeShared",
              label: "Shared",
              type: "switch",
            },
            {
              name: "AllPersonal",
              label: "All Personal",
              type: "switch",
            },
            {
              name: "filterType",
              label: "Type",
              type: "dropdown",
              required: true,
              selectOptions: getTypeOptions(),
            },
            {
              name: "Code",
              label: "Code",
              xsCol: 2,
            },

            {
              name: "Type",
              label: "Type",
              type: "dropdown",
              selectOptions: getCommentTypeOptions(),
              xsCol: 2,
            },

            {
              name: "useOption",
              label: "Use",
              type: "dropdown",
              selectOptions: getUseOptions(),
              xsCol: 2,
            },
            {
              name: "Comment",
              label: "Comment",
              xsCol: 6,
              type: "textarea",
            },
            {
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={newCLick}
                    sx={{ mt: "23px" }}
                    startIcon={<NewIcon />}
                  >
                    New
                  </Button>
                );
              },
            },
            {
              component: function ({ data }) {
                return (
                  <Button
                    disabled={!data.Fullname}
                    variant="contained"
                    onClick={deleteClick}
                    sx={{ mt: "23px" }}
                    startIcon={<Delete />}
                  >
                    Delete
                  </Button>
                );
              },
            },
            {
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={saveClick}
                    sx={{ mt: "23px" }}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

const defaultPresetComments = {
  IncludePersonal: true,
  filterType: "Both",
};

const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...defaultPresetComments, _ts_key: Date.now() };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
function getTypeOptions() {
  return [
    { Id: 1, value: "Both", text: "Both" },
    { Id: 2, value: "Evaluation", text: "Evaluations" },
    { Id: 3, value: "Recommendation", text: "Recommendations" },
  ];
}
function getCommentTypeOptions() {
  return [
    { Id: 1, value: 0, text: "Personal" },
    { Id: 2, value: 1, text: "Shared" },
  ];
}
function getUseOptions() {
  return [
    { Id: 1, value: 0, text: "Both" },
    { Id: 2, value: 1, text: "Evaluations" },
    { Id: 3, value: 2, text: "Recommendations" },
  ];
}
