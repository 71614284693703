import { useTheme } from "@emotion/react";
import { Card, Grid, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import DataTable from "../../components/DataTable";
import Form, { FormActions } from "../../components/Form";
import { SearchIcon } from "../../icons/ButtonIcons";
import FormFooter from "../../components/FormFooter";
import appResources from "../../app-resources/en/Resources.json";

export default function SearchPortalUsers({}) {
  const [data, dispatch] = useReducer(SearchFilterReducer);
  const curTheme = useTheme();

  const [labData, setLabData] = useState([]);
  const [isValidSearch, setIsValidSearch] = useState(false);

  const tblOptions = {
    columns: GetColumns(),
    dataSource: (labData || []).map((x) => ({
      ...x,
      keyProp: x.LaboratoryId,
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "laboratories",
    sx: { height: 600 },
  };
  const formActions = {
    searchContact: function (e) {
      if (
        (data && data.Email && data.Email.trim()) ||
        (data && data.FirstName && data.FirstName.trim()) ||
        (data && data.LastName && data.LastName.trim())
      ) {
        setIsValidSearch(false);
      } else {
        setIsValidSearch(true);
      }
    },
  };
  return (
    <>
      <Card sx={{ p: 1, minWidth: "80vw" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: -5.5 }}>
            <FormFooter buttons={GetFormButtons(formActions)} />
          </Grid>
        </Grid>
      </Card>

      <Grid container>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          {!isValidSearch ? (
            <Typography variant="subtitle2">
              Total Records: <b> {labData && labData.length}</b>
            </Typography>
          ) : (
            <Typography variant="subtitle2" sx={{ color: "red", fontSize: 12 }}>
              {appResources.PROVIDE_SEARCH_VALUE}
            </Typography>
          )}

          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns() {
  return [];
}
function GetFormSections() {
  return [
    {
      fields: [
        {
          name: "Email",
          label: "Email",
          xsCol: 3,
        },
        {
          name: "FirstName",
          label: "First Name",
          xsCol: 3,
        },
        {
          name: "LastName",
          label: "Last Name",
          xsCol: 3,
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
const GetFormButtons = ({ searchContact }) => [
  {
    label: "Search",
    onClick: searchContact,
    startIcon: <SearchIcon />,
  },
];
