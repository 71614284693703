import { useReducer, useState } from "react";
import { Card, FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import Form, { FormActions } from "../../components/Form";
import { Search } from "@mui/icons-material";
import FormFooter from "../../components/FormFooter";
import useFetch from "../../hooks/useFetch";
import { ClearIcon } from "../../icons/ButtonIcons";
import { DETAILS_APIS } from "../../common/apis";
import { useRoot } from "../../rootContext";
import SearchGeneralTable from "./SearchGeneralTable";
import renderCellExpand from "../../components/DatatableCellExpand";

export default function SearchGeneral({ handleSearchTree }) {
  const { post } = useFetch();
  const { allProducts } = useRoot();
  const [filter, dispatch] = useReducer(
    SearchQuickDetailsReducer,
    SearchQuickDetailsDefaults
  );
  const [tableData, setTableData] = useState([]);
  const [headerNameList, setHeaderNameList] = useState([]);
  const [errMessage, setErrMessage] = useState("");

  const formActions = {
    search: function (e) {
      handleSearch(filter);
    },
    clear: function (e) {
      dispatch({ type: FormActions.RESET_FORM });
      setTableData([]);
      setErrMessage("");
    },
  };

  const handleRadioChange = (e) => {
    let val = Number(e.target.value);
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name: "archivedOption", value: val },
    });
  };

  const handleSearch = async () => {
    const postObj = {
      ProductId: filter?.ProductId,
      SampleNumber: filter?.SampleNumber,
      ReportNumber: filter?.ReportNumber,
      Sif: filter?.Sif,
      JobNumber: filter?.JobNumber,
      Customer: filter?.Customer,
      CustomerId: filter?.CustomerId === "" ? null : filter?.CustomerId,
      Site: filter?.Site,
      Town: filter?.Town,
      Unit: filter?.Unit,
      Serial: filter?.Serial,
      Make: filter?.Make,
      Model: filter?.Model,
      Component: filter?.Component,
      ComponentId: filter?.ComponentId === "" ? null : filter?.ComponentId,
      EqpMachineId: filter?.EqpMachineId === "" ? null : filter?.EqpMachineId,
      SiteId: filter?.SiteId === "" ? null : filter?.SiteId,
      UnitId: filter?.UnitId === "" ? null : filter?.UnitId,
      EventLogId: filter?.EventLogId === "" ? null : filter?.EventLogId,
      ComponentSerial: filter?.ComponentSerial,
      UnarchivedOnly:
        filter?.archivedOption === 1
          ? true
          : filter?.archivedOption === 1
          ? true
          : null,
      UseStartsWith: filter?.UseStartsWith,
      Parent: filter?.Parent,
    };
    const result = await post(DETAILS_APIS.SEARCH_GENERAL, postObj);
    if (typeof result[0] == "string") {
      return setErrMessage(
        "Please narrow your search scope by providing more information"
      );
    }
    if (result?.length < 1) {
      return setErrMessage("No results were found from your search");
    }
    setTableData(result);
    setErrMessage("");
    let FormFields = GetColumns();
    let headList = FormFields.filter((x) =>
      (Object.keys(result[0]) || []).includes(x.field)
    );
    setHeaderNameList(headList);
  };

  const handleSearchSelect = (row) => {
    handleSearchTree && handleSearchTree(row);
  };

  return (
    <>
      <Grid minHeight={700}>
        <Card sx={{ p: 1, pb: 1, mb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Form
                sections={useFormSections(
                  allProducts,
                  filter,
                  handleRadioChange
                )}
                data={filter}
                dispatch={dispatch}
                key={filter._ts_key || ""}
              />
            </Grid>
          </Grid>
          <FormFooter
            footerTextSx={{ color: "red" }}
            footerText={errMessage}
            buttons={GetFormButtons(formActions)}
          />
        </Card>

        {tableData?.length > 0 ? (
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Total Records: <b>{tableData?.length}</b>
            </Typography>

            <SearchGeneralTable
              data={tableData}
              headerNameList={headerNameList}
              handleSearchSelect={handleSearchSelect}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
}

const GetFormButtons = ({ search, clear }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <ClearIcon />,
  },
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

const useFormSections = (Products, filter, handleRadioChange) => {
  return [
    {
      fields: [
        {
          xsCol: 12,
          group: [
            {
              name: "ProductId",
              label: "Product",
              xsCol: 2,
              type: "dropdown",
              selectOptions: (Products || []).map((x) => ({
                value: x.ProductId,
                text: x.Product,
              })),
              sx: {
                [`.MuiSelect-select`]: {
                  backgroundColor:
                    Products?.filter(
                      (x) => x.ProductId === filter?.ProductId
                    )[0]["Colour"] || "",
                  color: filter.ProductId === 0 ? "" : "white",
                  [`.MuiSvgIcon-root-MuiSelect-icon`]: {
                    color: filter.ProductId === 0 ? "" : "white",
                  },
                },
                [`.MuiSelect-icon`]: {
                  color: filter.ProductId === 0 ? "" : "white",
                },
              },
            },
            { name: "Customer", label: "Customer", xsCol: 2 },
            { name: "Site", label: "Site", xsCol: 2 },
            { name: "Unit", label: "Unit", xsCol: 2 },
            { name: "Serial", label: "Serial", xsCol: 2 },
            {
              name: "archivedOption",
              xsCol: 2,
              component: function () {
                return (
                  <FormControlLabel
                    checked={filter?.archivedOption === 0}
                    onChange={handleRadioChange}
                    value={0}
                    control={<Radio />}
                    label="Archived and Unarchived"
                  />
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            { name: "SampleNumber", label: "Sample Number", xsCol: 2 },
            { name: "Make", label: "Make", xsCol: 2 },
            { name: "Model", label: "Model", xsCol: 2 },
            { name: "Component", label: "Component", xsCol: 2 },
            { name: "ComponentSerial", label: "Comp. Serial", xsCol: 2 },
            {
              name: "archivedOption",
              xsCol: 2,
              component: function () {
                return (
                  <FormControlLabel
                    checked={filter?.archivedOption === 1}
                    onChange={handleRadioChange}
                    value={1}
                    control={<Radio />}
                    label="Unarchived Only"
                  />
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            { name: "Sif", label: "Registration", xsCol: 2 },
            { name: "Town", label: "Town", xsCol: 2 },
            { name: "JobNumber", label: "Job Number", xsCol: 2 },
            { name: "ReportNumber", label: "Report Number", xsCol: 2 },
            { name: "Parent", label: "Parent", xsCol: 2 },
            {
              name: "archivedOption",
              xsCol: 2,
              component: function () {
                return (
                  <FormControlLabel
                    checked={filter?.archivedOption === 2}
                    onChange={handleRadioChange}
                    value={2}
                    control={<Radio />}
                    label="Archived Only"
                  />
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            {
              name: "CustomerId",
              label: "Customer Id",
              xsCol: 2,
              type: "number",
            },
            { name: "SiteId", label: "Site Id", xsCol: 2, type: "number" },
            { name: "UnitId", label: "Unit Id", xsCol: 2, type: "number" },
            {
              name: "ComponentId",
              label: "Component Id",
              xsCol: 2,
              type: "number",
            },
            {
              name: "EventLogId",
              label: "EventLog Id",
              xsCol: 2,
              type: "number",
            },
            {
              name: "EqpMachineId",
              label: "Eqp Machine Id",
              xsCol: 2,
              type: "number",
            },
          ],
        },
      ],
    },
  ];
};

function SearchQuickDetailsReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchQuickDetailsDefaults, _ts_key: Date.now() };
  }
  return state;
}

const SearchQuickDetailsDefaults = {
  SearchTerm: 0,
  ProductId: 0,
  archivedOption: 0,
};

const GetColumns = () => [
  {
    headerName: "SampleNumber",
    field: "SampleNumber",
    width: 125,
  },
  {
    headerName: "SIF",
    field: "SIF",
    width: 125,
    renderCell: renderCellExpand,
  },
  {
    headerName: "ReportNumber",
    field: "ReportNumber",
    width: 107,
    renderCell: renderCellExpand,
  },
  {
    headerName: "AdHocReportType",
    field: "AdHocReportType",
    width: 136,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Product",
    field: "Product",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Parent",
    field: "Parent",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Customer",
    field: "Customer",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Site",
    field: "Site",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Unit",
    field: "Unit",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Model",
    field: "Model",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Serial",
    field: "Serial",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Component",
    field: "Component",
    width: 140,
    renderCell: renderCellExpand,
  },

  {
    headerName: "CompSerial",
    field: "CompSerial",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Predelivery",
    field: "Predelivery",
    width: 75,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Internal",
    field: "Internal",
    width: 70,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Parked",
    field: "Parked",
    width: 75,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Scrapped",
    field: "Scrapped",
    width: 75,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Event",
    field: "Event",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Physical",
    field: "PhysicalCity",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Postal",
    field: "PostalCity",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 80,
  },
];
