import { Typography } from "@mui/material";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import { useEffect, useState } from "react";
import { CONTACTS_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";

const SubscriptionOverview = ({ formData }) => {
  const { post } = useFetch();
  const [subscriptionList, setSubscriptionList] = useState([]);

  useEffect(() => {
    (async function () {
      let parseData = {};
      let formDatas = localStorage.getItem("subscription-overview")

      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas)
      }
      const contactSubscriptionsResponse = await post(
        `${CONTACTS_APIS.CONTACT_SUBSCRIPTIONS}`,
        { CustomerId: formData?.CustomerId || parseData?.CustomerId },
      );
      setSubscriptionList(contactSubscriptionsResponse);
      localStorage.removeItem("subscription-overview");
    })();
  }, []);

  const tableActions = {
    edit: {
      action: function (row) {
        alert(`Edit ${row.DisplayName}`);
      },
      label: "Edit",
    },
    access: {
      action: function (row) {
        alert("Access");
      },
      label: "Access",
    },
    reports: {
      action: function (row) {
        alert("Reports");
      },
      label: "Reports",
    },
    subscriptions: {
      action: function (row) {
        alert("Subscriptions");
      },
      label: "Subscriptions",
    },
    primary: {
      action: function (row) {
        alert("Primary");
      },
      label: "Primary",
    },
    delete: {
      action: function (row) {
        alert("Delete");
      },
      label: "Delete",
    },
  };

  const rowSelected = () => { };

  const options = {
    dataSource: subscriptionList,
    rowIdProperty: "ContactId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };

  return (
    <>
      <Typography>
        There are {subscriptionList?.length} subscription(s)
      </Typography>
      <DataTable
        {...options}
        columns={GetColumns(tableActions)}
        sx={{ minWidth: "85vw", minHeight: "37vw" }}
      />
    </>
  );
};

export default SubscriptionOverview;

const GetColumns = (tableActions) => [
  {
    headerName: "Edit",
    field: "Edit",
    width: 60,
    renderCell: function ({ row }) {
      return (
        <DataGridAction
          minWidth={"50px"}
          row={row}
          onClick={tableActions.edit.action}
          label={tableActions.edit.label}
        />
      );
    },
  },
  {
    headerName: "Access",
    field: "Access",
    width: 65,
    renderCell: function ({ row }) {
      return (
        <DataGridAction
          minWidth={"55px"}
          row={row}
          onClick={tableActions.access.action}
          label={tableActions.access.label}
        />
      );
    },
  },
  {
    headerName: "Reports",
    field: "Reports",
    width: 65,
    renderCell: function ({ row }) {
      return (
        <DataGridAction
          row={row}
          minWidth={"55px"}
          onClick={tableActions.reports.action}
          label={tableActions.reports.label}
        />
      );
    },
  },
  {
    headerName: "Subscriptions",
    field: "Subscriptions",
    width: 96,
    renderCell: function ({ row }) {
      return (
        <DataGridAction
          minWidth={"80px"}
          row={row}
          onClick={tableActions.subscriptions.action}
          label={tableActions.subscriptions.label}
        />
      );
    },
  },
  {
    headerName: "Primary",
    field: "Primary",
    width: 65,
    renderCell: function ({ row }) {
      return (
        <DataGridAction
          row={row}
          minWidth={"55px"}
          onClick={tableActions.primary.action}
          label={tableActions.primary.label}
        />
      );
    },
  },
  {
    headerName: "Email",
    field: "Username",
    width: 154,
  },
  {
    headerName: "Name",
    field: "DisplayName",
    width: 98,
  },
  {
    headerName: "Scope",
    field: "Scope",
    width: 65,
  },
  {
    headerName: "Report",
    field: "Report",
    width: 65,
  },
  {
    headerName: "Weekly",
    field: "Weekly",
    width: 65,
  },
  {
    headerName: "Monthly",
    field: "Monthly",
    width: 65,
  },
  {
    headerName: "Regarding",
    field: "Regarding",
    width: 76,
  },
  {
    headerName: "Source",
    field: "Source",
    width: 70,
  },
  {
    headerName: "Mobile",
    field: "Mobile",
    width: 104,
  },
  {
    headerName: "Telephone",
    field: "Telephone",
    width: 97,
  },
  {
    headerName: "Role",
    field: "Role",
    width: 60,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 69,
  },
  {
    headerName: "Company",
    field: "Company",
    width: 90,
  },
  {
    headerName: "Delete",
    field: "Delete",
    width: 65,
    renderCell: function ({ row }) {
      return (
        <DataGridAction
          minWidth={"55px"}
          row={row}
          onClick={tableActions.delete.action}
          label={tableActions.delete.label}
        />
      );
    },
  },
];
