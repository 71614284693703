import DataTable from "../../../components/DataTable";

export default function JobTable({ data, rowSelected }) {
  const options = {
    columns: Columns,
    dataSource: data,
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options} />;
}
const Columns = [
  {
    headerName: "SampleNumber",
    field: "SampleNumber",
    width: 125,
  },
  {
    headerName: "ReportNumber",
    field: "ReportNumber",
    width: 107,
  },
  {
    headerName: "Product",
    field: "Product",
    width: 107,
  },
  {
    headerName: "Customer",
    field: "Customer",
    width: 170,
  },
  {
    headerName: "Postal",
    field: "PhysicalCity",
    width: 107,
  },
  {
    headerName: "Postal",
    field: "PostalCity",
    width: 107,
  },
  {
    headerName: "Site",
    field: "Site",
    width: 107,
  },
  {
    headerName: "Unit",
    field: "Unit",
    width: 107,
  },
  {
    headerName: "Model",
    field: "Model",
    width: 107,
  },
  {
    headerName: "Serial",
    field: "Serial",
    width: 107,
  },
  {
    headerName: "Component",
    field: "Component",
    width: 107,
  },
  {
    headerName: "Predelivery",
    field: "Predelivery",
    width: 75,
  },
  {
    headerName: "Internal",
    field: "Internal",
    width: 70,
  },

  {
    headerName: "Parked",
    field: "Parked",
    width: 70,
  },
  {
    headerName: "Scrapped",
    field: "Scrapped",
    width: 70,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 70,
  },
];
