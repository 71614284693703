import { Button, Grid } from "@mui/material";
import { useMemo, useReducer, useState } from "react";
import AppDialog from "../../../components/AppDialog";
import Form, { FormActions } from "../../../components/Form";
import { DropdownButton } from "../../../components/FormFooter";
import ManageTestSets from "./ManageTestSets";
import {
  SelectCustomerSite,
  SelectTestSet,
  SelectUsers,
} from "./TestSetFormComponents";
import { ManageIcon } from "../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";

export default function TestSetForm({
  data: testSetData = {},
  masterData,
  saveTestSet,
  testSets = [],
  refreshTestSets,
}) {
  const [data, dispatch] = useReducer(TestSetReducer, {
    ...testSetData.testSet,
    Users: testSetData.Users,
    Customers: testSetData.Customers || [],
  });
  const { CanManageTestSets } = useRoot();

  const [openManage, setOpenManage] = useState(false);
  //   const { get } = useFetch();
  //   useEffect(function () {
  //     (async function () {
  //       const resp = await get(`?testSetId=${testSetId}`);
  //       dispatch({ type: TestSetActions.LOAD_DATA, payload: resp || {} });
  //     })();
  //   }, []);

  function handelManegeClose() {
    setOpenManage(false);
    refreshTestSets && refreshTestSets();
  }

  const testSetsOptions = useMemo(
    function () {
      if (data.Customers && data.Customers.length) {
        return testSets.filter(
          (x) =>
            x.TestSetId !== data.TestSetId &&
            x.FluidTypeId === data.FluidTypeId &&
            x.ProductId === data.ProductId
        );
      }
      return [];
    },
    [testSets, data.TestSetId, data.FluidTypeId, data.ProductId, data.Customers]
  );

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Form
          sections={FormSections}
          dispatch={dispatch}
          data={data}
          key={data?.TestSetId}
          masterData={masterData}
        />
      </Grid>
      <Grid item hidden={!testSetsOptions.length}>
        <SelectTestSet testSets={testSetsOptions} dispatch={dispatch} />
      </Grid>
      <Grid item container justifyContent="space-between">
        {CanManageTestSets && <Grid item>
          <Button
            onClick={() => setOpenManage(true)}
            disabled={!data.TestSetId}
            startIcon={<ManageIcon />}
            variant="outlined"
          >
            Manage
          </Button>
        </Grid>}
        {CanManageTestSets && <Grid item>
          <DropdownButton
            disabled={!data.TestSetId}
            label="Save"
            onClick={() => saveTestSet && saveTestSet(data)}
            options={[
              {
                label: "Save as Copy",
                onClick: () => saveTestSet && saveTestSet(data, true),
              },
            ]}
          />
        </Grid>}
      </Grid>
      <AppDialog
        open={openManage}
        handleClose={handelManegeClose}
        title={data.TestSet}
        maxWidth="xl"
      >
        <ManageTestSets close={handelManegeClose} source={data} />
      </AppDialog>
    </Grid>
  );
}
const FormSections = [
  {
    fields: [
      { name: "TestSet", label: "TestSet", xsCol: 6 },
      {
        name: "ProductId",
        label: "Product",
        xsCol: 6,
        type: "dropdown",
        required: true,
        optionConfigs: {
          name: "Products",
          textProp: "Product",
          valProp: "ProductId",
        },
      },
      {
        name: "FluidTypeId",
        label: "Fluid Type",
        xsCol: 6,
        type: "dropdown",
        required: true,
        optionConfigs: {
          name: "FluidTypes",
          textProp: "FluidType1",
          valProp: "FluidTypeId",
        },
      },
      {
        name: "IsArchived",
        label: "Archived",
        xsCol: 6,
        type: "switch",
        className: "red",
      },
      {
        name: "IsSystemTestSet",
        label: "System Use Only. Not For Registration",
        xsCol: 12,
        type: "switch",
      },
      {
        name: "UseAutoEvaluation",
        label: "Allow Auto Evaluation",
        xsCol: 12,
        disabled: (data) => data.IsSystemTestSet,
        type: "switch",
      },
      {
        xsCol: 12,
        component: function (props) {
          return <SelectUsers {...props} />;
        },
      },
      {
        xsCol: 12,
        component: function (props) {
          return <SelectCustomerSite {...props} />;
        },
      },
    ],
  },
];

export const TestSetActions = {
  LOAD_DATA: "load-data",
  USERS_UPDATED: "users-updated",
  CUSTOMER_DELETED: "customer-deleted",
  CUSTOMER_OR_SITE_ADDED: "customer-or-site-added",
  REPLACE_TO_TEST_SET: "replace-to-test-set",
};
function TestSetReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    if (action.payload.name === "IsSystemTestSet" && action.payload.value) {
      state.UseAutoEvaluation = false;
    }
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === TestSetActions.LOAD_DATA) {
    return action.payload;
  }
  if (action.type === TestSetActions.USERS_UPDATED) {
    state.Users = action.payload.map((x) => ({
      UserId: x,
      TestSetId: state.TestSetId,
    }));
    return { ...state };
  }
  if (action.type === TestSetActions.CUSTOMER_DELETED) {
    state.Customers = [
      ...state.Customers.filter(
        (x) =>
          !(
            x.CustomerId == action.payload.CustomerId &&
            x.SiteId === action.payload.SiteId
          )
      ),
    ];
    return { ...state };
  }
  if (action.type === TestSetActions.CUSTOMER_OR_SITE_ADDED) {
    const cust = [...state.Customers, action.payload];
    return { ...state, Customers: cust };
  }
  if (action.type === TestSetActions.REPLACE_TO_TEST_SET) {
    return { ...state, ToTestSet: action.payload };
  }
  console.warn(
    `actions.type must have a value currently it is ${action.type}`,
    action
  );
  return state;
}
