import * as React from "react";
import { Button, Grid } from "@mui/material";
import { useReducer, useState } from "react";
import AppDialog from "../../components/AppDialog";
import Form, { FormActions } from "../../components/Form";
import useFetch from "../../hooks/useFetch";
import { ContactIcon, DeleteIcon, SaveIcon } from "../../icons/ButtonIcons";
import AccessPermissionOverview from "../reports/critical-logs/access-permission-overview/AccessPermissionOverview";
import SampleReportingOverview from "./SampleReportingOverview";
import AlertDialog from "../../components/AlertDialog";
import { useRoot } from "../../rootContext";
import { FLEET_APIS } from "../../common/apis";
import appResources from "../../app-resources/en/Resources.json";

export default function ViewParent({
  cancel,
  parentsData,
  fetchData,
  setSelectedParent,
  setInfoMessage,
}) {

  const [data, dispatch] = useReducer(FluidReducer, parentsData);
  const [masterData, setMasterData] = React.useState();

  const [dialog, setDialog] = useState({
    title: "",
    message: "",
    actionName: "",
    open: false,
    width: "",
  });

  const { get } = useFetch();
  const { products } = useRoot();

  async function deleteClick() {
    setDialog({
      title: "Delete Parent",
      message: `Do you wish to delete the customer parent?
      This will NOT delete any children customers
      Yes = Delete the parent
      No = do not delete the parent`,
      actionName: "deleteClickAlert",
      open: true,
      width: "md",
    });
  }
  async function deleteClickAlert() {
    const response = await get(`${FLEET_APIS.DELETE_PARENT}?parentid=` + data.ParentId);
    if (response > 0) {
      fetchData();
      setInfoMessage(appResources.CUSTOMER_PARENT_DELETED);
    }
    setSelectedParent({});
  }

  async function saveClick() {
    const updateResponse = await get(`${FLEET_APIS.UPDATE_PARENT}?parentid=` + data.ParentId + "&Parent=" + data.Parent);
    if (updateResponse > 0) {
      setInfoMessage(appResources.CUSTOMER_PARENT_UPDATED);
      fetchData();
    }
    setSelectedParent({});
  }

  async function accessContactsClick() {
    setDialog({
      title: "Access Permissions Overview",
      open: true,
      width: "xl",
    });
  }
  async function reportContactsClick() {
    setDialog({
      title: "Sample Reporting Overview",
      open: true,
      width: "xl",
    });
  }

  async function fetchProductType() {
    const temp = products;
    setMasterData(temp);
    return temp;
  }
  React.useEffect(
    function () {
      (async function () {
        await fetchProductType();
      })();
    },
    [products]
  );

  function handleRowData() { }
  return (
    <>
      <Grid container xs={12}>
        <Grid item md={12} sx={{ p: 1 }}>
          <Form
            sections={GetFormSections(
              masterData,
              saveClick,
              deleteClick,
              accessContactsClick,
              reportContactsClick
            )}
            data={data}
            dispatch={dispatch}
          />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth={dialog.width}
      >
        {dialog.title === "Access Permissions Overview" && (
          <AccessPermissionOverview
            formData={parentsData}
            rowDataProps={handleRowData}
            setDialog={setDialog}
          />
        )}
        {dialog.title === "Sample Reporting Overview" && (
          <SampleReportingOverview
          //  formData={parentsData}
          // rowDataProps={handleRowData}
          />
        )}
        {dialog.title === "Delete Parent" && (
          <AlertDialog
            handleClose={() => {
              setDialog((prevState) => ({
                ...prevState,
                open: false,
              }));
            }}
            alertAction={deleteClickAlert}
            message={dialog.message}
          />
        )}
      </AppDialog>
    </>
  );
}

function GetFormSections(
  masterData,
  saveClick,
  deleteClick,
  accessContactsClick,
  reportContactsClick
) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 6,
          name: "Parent",
          label: "Parent",
        },
        {
          xsCol: 6,
          name: "ProductId",
          label: "Product",
          disabled: true,
          type: "dropdown",
          selectOptions: (masterData || [])?.map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
          defaultValue: 0,
        },
        {
          xsCol: 12,
          group: [
            {
              component: function () {
                return (
                  <Button
                    onClick={deleteClick}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                  >
                    Delete
                  </Button>
                );
              },
            },
            {
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={saveClick}
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                );
              },
            },
          ],
        },

        {
          xsCol: 12,
          group: [
            {
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    onClick={accessContactsClick}
                    startIcon={<ContactIcon />}
                  >
                    Access Contacts
                  </Button>
                );
              },
            },
            {
              component: function () {
                return (
                  <Button
                    variant="outlined"
                    onClick={reportContactsClick}
                    startIcon={<ContactIcon />}
                  >
                    Report Contacts
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
