import { Card, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { useEffect, useReducer, useState } from "react";

import ContactExports from "../reports/critical-logs/contact-permission-overview/ContactExports";
import ContactPrimary from "../reports/critical-logs/contact-permission-overview/ContactPrimary";
import ContactAccessPermission from "../reports/critical-logs/contact-permission-overview/ContactAccessPermission";
import useFetch from "../../hooks/useFetch";
import { CONTACTS_APIS } from "../../common/apis";
import DataTable, { DataGridAction } from "../../components/DataTable";
import AppDialog from "../../components/AppDialog";
import EditContacts from "../../forms/EditContacts";
import Form, { FormActions } from "../../components/Form";
import FormFooter from "../../components/FormFooter";
import { ClearIcon, ContactIcon, SearchIcon } from "../../icons/ButtonIcons";
import { useRoot, useToast } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";
import NewContacts from "../../forms/NewContacts";
import renderCellExpand from "../../components/DatatableCellExpand";

export default function SearchContact({
  actions,
  isShowSelect,
  handleContactRowData,
  setSearchContactDialogueOpen,
}) {
  const { post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();
  const { showWarningToast } = useToast();
  const [data, dispatch] = useReducer(
    SearchContactsFilterReducer,
    SearchContactDefaults
  );
  const [contactsData, setContactsData] = useState([]);
  const [contactId, setContactId] = useState();
  const [searchContactRowData, setSearchContactRowData] = useState();
  const [propId, setPropId] = useState();
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
  });

  function refreshGrid() {
    formActions.search();
  }
  useEffect(() => {
    document.title = "Search Contact";
  }, []);
  const formActions = {
    newContact: function (e) {
      setContactId(0);
      setDialog({
        title: "New Contact from Search Contacts",
        open: true,
      });
    },
    clear: function (e) {
      dispatch({
        type: FormActions.RESET_FORM,
      });
      setContactsData();
    },
    search: async function (e) {
      if (
        data?.ContactId === null &&
        data?.Username === "" &&
        data?.Forename === "" &&
        data?.Surname === "" &&
        data?.Mobile === ""
      ) {
        return showWarningToast(appResources.NO_SEARCH_CONTACT_DATA_FOUND_MSG);
      }

      const resp = await post(CONTACTS_APIS.SEARCH_CONTACTS, {
        ...data,
        ContactId: data.ContactId ? data.ContactId : null,
        Username: data.Username ? data.Username : "",
        Forename: data.Forename ? data.Forename : "",
        Surname: data.Surname ? data.Surname : "",
        Mobile: data.Mobile ? data.Mobile : "",
        NoScopes: false,
        IncludeArchived: data.IsArchived ? data.IsArchived : false,
        ScopeSiteId: null,
      });
      setContactsData(resp);
    },
  };
  //Columns Actions

  function selectClick(row) {
    handleContactRowData(row);
    setSearchContactDialogueOpen(false);
  }
  function editClick(row) {
    setContactId(row.ContactId);
    setSearchContactRowData(row);
    setDialog({
      title: "Edit Contact from Search Contacts",
      open: true,
    });
  }
  async function accessClick(row) {
    setContactId(row.ContactId);
    setSearchContactRowData(row);
    setPropId(2);
    setDialog({
      title: `Contacts Access Permissions for ${row.Username}`,
      open: true,
    });
  }
  function reportsClick(row) {
    setContactId(row.ContactId);
    setSearchContactRowData(row);
    setPropId(3);
    setDialog({
      title: `Contacts Sample Reporting for display ${row.Username}`,
      open: true,
    });
  }
  function subscriptionsClick(row) {
    setContactId(row.ContactId);
    setSearchContactRowData(row);
    setPropId(4);
    setDialog({
      title: `Contacts Subscriptions for ${row.Username}`,
      open: true,
    });
  }

  function exportsClick(row) {
    setContactId(row.ContactId);
    setSearchContactRowData(row);
    setPropId(5);
    setDialog({
      title: "Contacts Exports",
      open: true,
    });
  }
  function primaryClick(row) {
    setContactId(row.ContactId);
    setSearchContactRowData(row);
    setPropId(6);
    setDialog({
      title: `Contacts Primary Mappings for ${row.Username}`,
      open: true,
    });
  }
  const tblOptions = useMemo(
    () => ({
      columns: GetColumns(
        selectClick,
        editClick,
        accessClick,
        reportsClick,
        subscriptionsClick,
        exportsClick,
        primaryClick,
        isShowSelect
      ),
      dataSource: contactsData || [],
      rowIdProperty: "ContactId",
      isDisableColumnFilter: true,
      cellStylingHandler: "searchContact",
      sx: { height: 600 },
    }),
    [contactsData]
  );

  return (
    <>
      <Grid container>
        <Card sx={{ p: 1, minWidth: "100%" }}>
          <Grid item xs={12}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
              key={data._ts_key || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FormFooter
              buttons={GetFormButtons(formActions, CanDoDataMaintenance)}
              hideDivider
              gmt={0}
            />
          </Grid>
        </Card>

        <Grid item xs={12} sx={{ mt: 2 }}>
          {contactsData?.length > 0 && (
            <Typography variant="subtitle2">
              Total Records: <b> {contactsData?.length}</b>
            </Typography>
          )}
          {contactsData?.length < 1 && (
            <Typography sx={{ color: "red" }}>
              {appResources.NO_CONTACTS_FOUND}
            </Typography>
          )}
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={dialog.title}
        maxWidth="100vw"
        sx="50vh"
      >
        {dialog.title ===
          (contactId === 0
            ? "New Contact from Search Contacts"
            : "Edit Contact from Search Contacts") &&
          (contactId === 0 ? (
            <NewContacts
              contactId={contactId}
              setEditContactsDialog={setDialog}
              setContactsData={setContactsData}
              refreshGrid={refreshGrid}
            />
          ) : (
            <EditContacts
              contactId={contactId}
              setEditContactsDialog={setDialog}
              setContactsData={setContactsData}
              refreshGrid={refreshGrid}
            />
          ))}
        {(dialog.title ===
          `Contacts Access Permissions for ${searchContactRowData && searchContactRowData.Username
          }` ||
          dialog.title ===
          `Contacts Sample Reporting for display ${searchContactRowData && searchContactRowData.Username
          }` ||
          dialog.title ===
          `Contacts Subscriptions for ${searchContactRowData && searchContactRowData.Username
          }`) && (
            <ContactAccessPermission
              contactId={contactId}
              propId={propId}
              username={searchContactRowData && searchContactRowData.Username}
              searchContactRowData={searchContactRowData}
            />
          )}
        {dialog.title === "Contacts Exports" && (
          <ContactExports
            contactId={contactId}
            propId={propId}
            searchContactRowData={searchContactRowData}
          />
        )}
        {dialog.title ===
          `Contacts Primary Mappings for ${searchContactRowData && searchContactRowData.Username
          }` && <ContactPrimary contactId={contactId} propId={propId} />}
      </AppDialog>
    </>
  );
}
const GetFormButtons = (
  { newContact, clear, search },
  CanDoDataMaintenance
) => [
    {
      hidden: !CanDoDataMaintenance,
      variant: "outlined",
      label: "New",
      onClick: newContact,
      startIcon: <ContactIcon />,
    },
    {
      variant: "outlined",
      label: "Clear",
      onClick: clear,
      startIcon: <ClearIcon />,
    },
    {
      variant: "contained",
      label: "Search",
      onClick: search,
      startIcon: <SearchIcon />,
    },
  ];

function GetColumns(
  selectClick,
  editClick,
  accessClick,
  reportsClick,
  subscriptionsClick,
  exportsClick,
  primaryClick,
  isShowSelect
) {
  return [
    {
      headerName: "Select",
      field: "Select",
      sortable: false,
      minWidth: 80,
      hide: !isShowSelect,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,

      minWidth: 80,
      hide: isShowSelect,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },

    {
      headerName: "Access",
      field: "HasScope",
      sortable: false,
      minWidth: 90,
      renderCell: function ({ row, value }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={accessClick}
              label={value ? "Yes" : "No"}
            //  className={value ? "outlined" : "contained"}
            // color={value ? "primary" : "error"}
            />
          </>
        );
      },
    },

    {
      headerName: "Reports",
      field: "HasLinkages",
      sortable: false,
      minWidth: 90,
      renderCell: function ({ row, value }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={reportsClick}
              label={value ? "Yes" : "No"}
              //   className={row.HasScope ? "contained" : "contained"}
              disabled={!row.HasScope && !row.HasLinkages}
            // color={
            //   !row.HasScope && !row.HasLinkages
            //     ? "inherit"
            //     : !row.HasLinkages
            //     ? "error"
            //     : "info"
            // }
            />
          </>
        );
      },
    },
    {
      headerName: "Subscriptions",
      field: "HasSubscriptions",
      sortable: false,
      minWidth: 110,
      renderCell: function ({ row, value }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={subscriptionsClick}
              label={value ? "Yes" : "No"}
              //   className={row.HasScope ? "outlined" : "contained"}
              disabled={!row.HasScope}
            />
          </>
        );
      },
    },
    {
      headerName: "Exports",
      field: "HasExports",
      sortable: false,
      minWidth: 90,
      renderCell: function ({ row, value }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={exportsClick}
              label={value ? "Yes" : "No"}
            />
          </>
        );
      },
    },
    {
      headerName: "Primary",
      field: "IsPrimaryContact",
      sortable: false,
      minWidth: 90,
      renderCell: function ({ row, value }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={primaryClick}
              label={value ? "Yes" : "No"}
              // className={row.HasScope ? "outlined" : "contained"}
              disabled={!row.HasScope}
            />
          </>
        );
      },
    },
    {
      headerName: "Email",
      field: "Username",
      minWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      headerName: "DisplayName",
      field: "DisplayName",
      minWidth: 150,
      sortable: false,
      flex: 1,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Source",
      field: "IsKomatsu",
      renderCell: ({ row }) =>
        row.IsKomatsu
          ? "Komatsu"
          : row.IsPortalUser
            ? "Portal"
            : row.IsLabcheckUser
              ? "Labcheck"
              : "No Website",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      headerName: "Mobile",
      field: "Mobile",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      headerName: "Telephone",
      field: "Telephone",
      minWidth: 150,
      sortable: false,
      flex: 1,
    },
    {
      headerName: "Role",
      field: "ContactRole",
      width: 100,
      sortable: false,
      flex: 1,
    },
    {
      headerName: "Archived",
      field: "IsArchived",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      width: 80,
      sortable: false,
    },
    {
      headerName: "Company",
      field: "Company",
      minWidth: 160,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Country",
      field: "Country",
      minWidth: 170,
      sortable: false,
    },
  ];
}

function GetFormSections() {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "Username", label: "Email", xsCol: 3 },
        { name: "Mobile", label: "Mobile", xsCol: 3 },
        { name: "Forename", label: "Forename", xsCol: 2 },
        { name: "Surname", label: "Surname", xsCol: 2 },
        { name: "ContactId", label: "ContactId", xsCol: 2, type: "number" },
        {
          group: [
            {
              name: "IsArchived",
              label: "Include Archive",
              mdCol: 2,
              type: "switch",
            },

            { name: "Komatsu", label: "Komatsu", mdCol: 1, type: "switch" },
            {
              name: "IsLabcheckUser",
              label: "Labcheck",
              mdCol: 1,
              type: "switch",
            },
            {
              name: "NoLinkages",
              label: "No Access",
              mdCol: 1,
              type: "switch",
            },
            {
              name: "IsPortalUser",
              label: "Customer Portal",
              mdCol: 2,
              type: "switch",
            },
            { name: "IsEmailUser", label: "Email", mdCol: 2, type: "switch" },
            { name: "NoScopes", label: "No Reports", mdCol: 2, type: "switch" },
          ],
        },
      ],
    },
  ];
}

function SearchContactsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchContactDefaults, _ts_key: Date.now() };
  }
  return state;
}
const SearchContactDefaults = {
  IsArchived: true,
  Komatsu: true,
  IsLabcheckUser: true,
  IsPortalUser: true,
  IsEmailUser: true,
  ContactId: null,
  Username: "",
  Forename: "",
  Surname: "",
  Mobile: "",
  NoScopes: false,
};
