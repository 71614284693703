import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabEventChart from "./TabEventChart";
import TabTrayChart from "./TabTrayChart";
import ImagePick from "../../components/SelectImage";
import { useLocation } from "react-router-dom";

export default function Chart() {
  const [value, setValue] = React.useState("1");
  const myParam = useLocation().search;
  let location = useLocation();
  const QsampleId = new URLSearchParams(myParam).get("sampleId");
  const tabHideProps = new URLSearchParams(myParam).get("hideProp");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => { document.title = `Charts and Event: ${QsampleId || ""}` }, [QsampleId])

  return (
    <Box sx={{ width: "100%", typography: "body1", mt: -6 }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Events + Chart" value="1" />
            <Tab label="Sample Images" value="2" />
            {tabHideProps !== "1" && <Tab label="Tray Chart" value="3" />}
          </TabList>
        </Box>
        <TabPanel value="1">
          <TabEventChart sampleId={QsampleId} />
        </TabPanel>
        <TabPanel value="2">
          <ImagePick sampleId={QsampleId} />
        </TabPanel>

        {tabHideProps !== "1" && (
          <TabPanel value="3">
            <TabTrayChart sampleId={QsampleId} />
          </TabPanel>
        )}
      </TabContext>
    </Box>
  );
}
