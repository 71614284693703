import { Typography } from "@mui/material";
import { FLEET_APIS } from "../../../common/apis";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function SitesCustomersTable({
  data,
  selectedParent,
  refreshCustomers,
}) {
  const options = {
    columns: Columns(deleteClick, selectedParent),
    dataSource: data,
    rowIdProperty: "IndexId",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    sx: {
      height: 730,
    },
  };
  const { get } = useFetch();
  return (
    <>
      <Typography variant="subtitle2">
        Total Records: <b> {data && data.length}</b>
      </Typography>
      <DataTable {...options} />
    </>
  );

  async function deleteClick(row) {
    await get(
      `${FLEET_APIS.DELETE_SITE_SET_SITE}?SiteSetId=${row.SiteSetId}&SiteId=${row.SiteId}`
    );
    refreshCustomers();
  }
}

function Columns(deleteClick, selectedParent) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 120,
      hide: selectedParent.AutoUpdated === "Yes" ? true : false,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },
    {
      headerName: "Product",
      field: "Product",
      width: 150,
    },
    {
      headerName: "Customer",
      field: "Customer",
      width: 300,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      width: 300,
      renderCell: renderCellExpand,
    },
  ];
}
