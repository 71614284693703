import { useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";
import AddComponent from "./AddComponent";

export default function TemplatesComponentTable({
  data,
  refreshComponents,
  TemplateId,
}) {
  const [openSelectComponent, setOpenSelectComponent] = useState(false);
  const [componentRowData, setComponentRowData] = useState(false);
  const options = {
    columns: Columns(deleteComponentClick, editClick),
    dataSource: data,
    rowIdProperty: "TemplateComponentId",
    isDisableColumnFilter: true,
    sx: {
      height: 600,
    },
  };
  const { get } = useFetch();
  return (
    <>
      <AppDialog
        open={openSelectComponent}
        title="Edit Machine Template Component"
        maxWidth="lg"
        handleClose={() => setOpenSelectComponent(false)}
      >
        <AddComponent
          TemplateId={TemplateId}
          componentData={componentRowData}
          refreshComponents={refreshComponents}
          setOpenSelectComponent={setOpenSelectComponent}
        />
      </AppDialog>
      <DataTable {...options} />{" "}
    </>
  );

  async function deleteComponentClick(row) {
    await get(
      `${FLEET_APIS.DELETE_TEMPLATE_COMPONENT}?TemplateComponentId=${row.TemplateComponentId}&Screen=Machine Templates`
    );
    refreshComponents(row);
  }
  async function editClick(row) {
    setComponentRowData(row);
    setOpenSelectComponent(true);
  }
}

function Columns(deleteComponentClick, editClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      width: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "Component",
      field: "Component",
      minWidth: 150,
    },
    {
      headerName: "Fluid",
      field: "FluidType",
      minWidth: 170,
    },
    {
      headerName: "Type",
      field: "ComponentType",
      minWidth: 170,
    },
    {
      headerName: "Location",
      field: "Location",
      minWidth: 200,
    },
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={deleteComponentClick}
              label="Delete"
            />
          </>
        );
      },
    },
  ];
}
