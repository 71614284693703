import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { TEST_SETS_APIS } from "../../../common/apis";
import SwitchInput from "../../../components/SwitchInput";
import useFetch from "../../../hooks/useFetch";
import TestItemForm, { FormattingOptions } from "./TestItemForm";
import TestItemsTable from "./TestItemsTable";
import TestSetsTable from "./TestSetsTable";
import Colors from "../../../data/colors";
import { NewIcon, } from "../../../icons/ButtonIcons";
import TextBox from "../../../components/TextBox";
import { useRoot } from "../../../rootContext";

export default function TestItems() {
  const { get } = useFetch();
  const { testItemTypes } = useRoot();
  const [testItems, setTestItems] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [includeReport, setIncludeReport] = useState(false);
  const [containsValue, setContainsValue] = useState();
  const [masterData, setMasterData] = useState(null);
  const [selectedTestItem, setSelectedTestItem] = useState(TestItemDefaults);
  const [selectedTestItemRow, setSelectedTestItemRow] = useState();
  useEffect(function () {
    document.title = "Test Items";
    (async function () {
      const masterDataResp = testItemTypes;
      if (masterDataResp) {
        masterDataResp.FormattingOptions = FormattingOptions;
        masterDataResp.Colors = Colors;
        setMasterData(masterDataResp);
      }
      await refreshTestItems();
    })();
  }, []);

  async function refreshTestItems() {
    const testItemsResp = await get(TEST_SETS_APIS.TEST_ITEMS);
    setTestItems(testItemsResp || []);
    setSelectedTestItem({ ...TestItemDefaults, _key: Date.now() });
  }

  async function getTestItemsData(row) {
    if (row) {
      setSelectedTestItemRow(row);
      const resp = await get(
        `${TEST_SETS_APIS.TEST_ITEMS_DETAIL}?testItemId=${row.TestItemId}`
      );
      if (resp) {
        resp.testItem = row;
        resp.testItem.ShowOnReports = row.AlwaysShowIfValue
          ? 2
          : row.HideFromCustomers
            ? 1
            : 0;
        setSelectedTestItem(resp || TestItemDefaults);
        return;
      }
    }
    setSelectedTestItem(TestItemDefaults);
  }

  async function handelRowSelected(row) {
    getTestItemsData(row);
  }
  function handelFilterContainsChange(e) {
    setContainsValue(e.target.value);
  }
  function newClickHandler(e) {
    setSelectedTestItem(TestItemDefaults);
  }

  const testItemsFiltered = useMemo(
    () =>
      testItems.filter((x) => {
        const isArchived = x.IsArchived;
        const testItemType = x.TestItemType?.toLowerCase();
        const testItem = x.TestItem?.toLowerCase();
        const testItemCode = x.TestItemCode?.toLowerCase();

        const shouldShowArchived = showArchived;
        const shouldShowTypes = includeReport;
        const filterValue = containsValue?.toLowerCase();
        const isEmptyFilter = !filterValue || filterValue.trim() === "";

        if (
          isEmptyFilter &&
          ((shouldShowArchived && isArchived) || !isArchived)
        ) {
          return true;
        } else if (
          !isEmptyFilter &&
          (!isArchived || (isArchived && shouldShowArchived)) &&
          (isEmptyFilter ||
            testItem.includes(filterValue) ||
            testItemCode.includes(filterValue) ||
            (shouldShowTypes && testItemType.includes(filterValue)))
        ) {
          return true;
        } else {
          return false;
        }
      }),
    [showArchived, testItems, containsValue, includeReport]
  );

  useEffect(() => {
    if (testItemsFiltered.length > 0) {
      getTestItemsData(testItemsFiltered[0]);
    }
  }, [testItemsFiltered]);

  return !masterData ? (
    <></>
  ) : (
    <Grid container spacing={2} sx={{ pb: 1 }}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={2.5}>
          <TextBox
            name="contains"
            label="Contains"
            value={containsValue}
            onChange={handelFilterContainsChange}
          />
        </Grid>

        <Grid item sx={{ mt: "22px" }}>
          <SwitchInput
            label="Show Archived"
            onChange={(e) => setShowArchived(e.target.checked)}
          />
        </Grid>

        <Grid item xs={2} sx={{ mt: "22px" }}>
          <SwitchInput
            label="Include Report"
            onChange={(e) => setIncludeReport(e.target.checked)}
          />
        </Grid>

        <Grid item sx={{ mt: "13px" }}>
          <Button
            onClick={newClickHandler}
            variant="outlined"
            startIcon={<NewIcon />}
          >
            New
          </Button>
        </Grid>
      </Grid>
      <Grid item md={3} xs={12}>
        <Typography variant="subtitle2">
          Total Records: <b> {testItemsFiltered?.length}</b>
        </Typography>
        <TestItemsTable
          data={testItemsFiltered || []}
          rowSelected={handelRowSelected}
          selectedRow={[selectedTestItemRow?.TestItemId]}
        />
      </Grid>
      <Grid item md={6} xs={12} mt={2}>
        <Card sx={{ p: 1 }}>
          <TestItemForm
            masterData={masterData}
            data={selectedTestItem}
            key={selectedTestItem.testItem?.TestItemId || selectedTestItem._key}
            refreshTestItems={refreshTestItems}
          />
        </Card>
      </Grid>
      <Grid item md={3} xs={12} hidden={!selectedTestItem.testItem?.TestItemId}>
        <Typography variant="subtitle2">
          Total Records: <b> {selectedTestItem?.TestSets?.length}</b>
        </Typography>
        <TestSetsTable
          data={selectedTestItem?.TestSets}
          key={selectedTestItem.testItem?.TestItemId}
        />
      </Grid>
    </Grid>
  );
}

const TestItemDefaults = {
  testItem: {
    DisplayRank: 500,
    LaboratoryRank: 1,
    TechnicianRank: 1,
    TestItemTypeId: 1,
    DisplayOption: -1,
    LimitOption: "Upper",
    IsNumeric: true,
    TestItemColour: 0,
  },
};
