import * as React from "react";
import Collapse from "@mui/material/Collapse";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ExpandLess, ExpandMore, MoreVert } from "@mui/icons-material";
import { IconButton, ListItem, Typography } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";

export default function DropdownMenu({ options, onItemSelect }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  //To Handle Sub Menu Items behaviour
  const [openCollapse, setOpenCollapse] = React.useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleItemClick(option, e) {
    setAnchorEl(null);
    onItemSelect && onItemSelect(option, e);
  }

  function handleOpenSettings(id) {
    setOpenCollapse({ ...openCollapse, [id]: !openCollapse[id] });
  }
  return (
    <>
      <IconButton onClick={handleClick} sx={{ pt: 1, mr: 0.25, maxWidth: 0 }}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((item, index) => (
          <div key={item.name}>
            <MenuItem
              sx={{
                minWidth: 180,
              }}
              key={item.name}
              // onClick={(e) => handleItemClick(item.name, e)}
              onClick={(e) =>
                item.items
                  ? handleOpenSettings(index)
                  : handleItemClick(item.name, e)
              }
            >
              <ListItemText>{item.label}</ListItemText>
              {item.items &&
                (openCollapse[index] ? <ExpandLess /> : <ExpandMore />)}
            </MenuItem>
            <Collapse
              in={!!openCollapse[index]}
              timeout="auto"
              onClick={(Event) => {
                Event.stopPropagation();
              }}
            >
              {item.items?.map((subItem, index) => {
                return (
                  <MenuItem
                    key={index}
                    sx={{
                      pl: "25px",
                    }}
                    onClick={(e) => handleItemClick(subItem.name, e)}
                  >
                    <Typography key={index}>{subItem.label}</Typography>
                  </MenuItem>
                );
              })}
            </Collapse>
          </div>
        ))}
      </Menu>
    </>
  );
}
