import { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable";
import { Button, Divider, Menu, MenuItem, Typography } from "@mui/material";

export const PopUpMenu = ({ anchorEl, openPop, setOpenPop, handleClick, nameVal, rowData }) => {
    const [searchDataCheckbox, setSearchDataCheckbox] = useState([]);
    const [searchDataCheckboxIds, setSearchDataCheckboxIds] = useState([]);

    const SearcTableColumns = [
        {
            headerName: nameVal,
            field: nameVal,
            minWidth: 130,
            valueFormatter: ({ value }) => ((value === true) ? "Yes" : (value === false) ? "No" : value),
        },
    ];

    const unique = [...new Set(rowData.map(item => item[nameVal]))];
    const uniqueList = [...new Set(unique.map(item => { return ({ [nameVal]: item }) }))]

    function rowsSampleSelectedHandler(row) {
        if (row?.length > 0) {
            setSearchDataCheckbox(row);
            setSearchDataCheckboxIds(row?.map((s) => s.keyProp));
        }
    }

    const searchOptions = {
        columns: SearcTableColumns,
        dataSource: (uniqueList || []).map((x, i) => ({
            ...x,
            keyProp: i,
        })),
        rowIdProperty: "keyProp",
        checkBoxSelect: true,
        selectedRowIds: searchDataCheckboxIds,
        isDisableColumnFilter: false,
        rowsSelected: rowsSampleSelectedHandler,
        sx: { height: 150, minWidth: 190 },
        checkboxColor: "#000000",
        selectedRowColor: "transparent",
        selectedTextColor: "#000000",
    };

    useEffect(() => {
        return () => { setSearchDataCheckbox([]) }
    }, [])

    return (
        <Menu anchorEl={anchorEl} open={openPop} onClose={() => setOpenPop(false)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}>
            <MenuItem
                sx={{ minWidth: 180, }}
                onClick={() => handleClick("ascending", nameVal)}
            >
                <Typography >Sort by Ascending</Typography>
            </MenuItem>

            <MenuItem
                sx={{ minWidth: 180, }}
                onClick={() => handleClick("descending", nameVal)}
            >
                <Typography >Sort by Descending</Typography>
            </MenuItem>
            <Divider />
            <MenuItem
                sx={{ minWidth: 180, }}
            >
                <DataTable {...searchOptions} />
            </MenuItem>
            <MenuItem sx={{ float: "right" }}>
                <Button variant="outlined" sx={{ mr: 1 }} onClick={() => { handleClick("filtering", nameVal, searchDataCheckbox) }}>OK</Button>
                <Button variant="outlined" onClick={() => setOpenPop(false)}>Cancel</Button>
            </MenuItem>
        </Menu>
    );
}
