import { useMemo } from "react";
import DataTable from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";

export default function EventLogTable({ data, rowSelected, rowActionHandler }) {
  const columns = useMemo(
    () => GetColumns({ rowActionHandler }),
    [rowActionHandler]
  );
  const options = {
    dataSource: data,
    rowIdProperty: "EventLogId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options} columns={columns} />;
}

const GetColumns = ({ rowActionHandler }) => [
  {
    headerName: "Product",
    field: "Product",
    width: 140,
  },
  {
    headerName: "Customer",
    field: "Customer",
    width: 200,
  },
  {
    headerName: "Site",
    field: "Site",
    width: 140,
  },
  {
    headerName: "Unit",
    field: "Unit",
    width: 140,
  },
  {
    headerName: "Model",
    field: "Model",
    width: 140,
  },
  {
    headerName: "Serial",
    field: "Serial",
    width: 140,
  },
  {
    headerName: "Component",
    field: "Component",
    width: 140,
  },

  {
    headerName: "Parked",
    field: "Parked",
    width: 70,
  },
  {
    headerName: "Scrapped",
    field: "Scrapped",
    width: 70,
  },
  {
    headerName: "Event",
    field: "Event",
    width: 140,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 70,
  },
  {
    headerName: "Actions",
    field: "Actions",
    sortable: false,
    flex: 1,
    renderCell: function ({ row }) {
      return (
        <DropdownMenu
          options={Options}
          onItemSelect={(option) => rowActionHandler(row, option)}
        />
      );
    },
  },
];

const Options = [
  { label: "Search Customer", name: "search-customer" },
  { label: "Search Site", name: "search-site" },
  { label: "Search Unit", name: "search-unit" },
  { label: "Search Component", name: "search-component" },
  { label: "Search Sample", name: "search-sample" },
];
