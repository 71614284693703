import { Delete, Save } from "@mui/icons-material";
import { Button, } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import Form, { FormActions } from "../components/Form";
import Typography from "@mui/material/Typography";
import FormFooter from "../components/FormFooter";
import AlertDialog from "../components/AlertDialog";
import useFetch from "../hooks/useFetch";
import { CONTACTS_APIS } from "../common/apis";
import ContactPrimary from "../features/reports/critical-logs/contact-permission-overview/ContactPrimary";
import AppDialog from "../components/AppDialog";
import ContactAccessPermission from "../features/reports/critical-logs/contact-permission-overview/ContactAccessPermission";
import SearchKomatsuEmployee from "../features/quick-details/SearchKomatsuEmployee";
import { useToast } from "../rootContext";
import { ValidateContact } from "../validation/schemas/ContactSchema";
import appResources from "../app-resources/en/Resources.json";
import SearchPortalUsers from "../features/quick-details/SearchPortalUsers";
let tempUSerName = 300;
export default function NewContacts({
  contactId,
  setEditContactsDialog,
  setContactsData,
  refreshGrid,
}) {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const [contactRoles, setContactRoles] = useState([]);
  const [contactCountries, setContactCountries] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [contactAccessDialogueOpen, setContactAccessDialogueOpen] =
    useState(false);
  const [formErrors, setFormErrors] = useState({});
  const { showToast, showErrorToast } = useToast();
  const [propId, setPropId] = useState();
  const [dialog, setDialog] = useState({
    open: false,
    width: "",
    title: "",
    message: "",
    actionName: "",
  });

  const actions = {
    save: async function (e) {
      //Post the data
      setFormErrors({});
      ValidateContact(data).then(async function ({ data: req, errors }) {
        if (req) {
          await post(CONTACTS_APIS.SAVE_CONTACTS, {
            ...data,
            IsKomatsu: data.IsKomatsu ? true : false,
            IsPortalUser: data.IsPortalUser ? true : false,
            IsLabcheckUser: data.IsLabcheckUser ? true : false,
            WebsiteNoAccess: data.WebsiteNoAccess ? true : false,
            Street: data.Street1 ? data.Street1 : null,
            Suburb: data.Street2 ? data.Street2 : null,
          });

          setEditContactsDialog((prevState) => ({ ...prevState, open: false }));
          refreshGrid();
        }
        if (errors) {
          setFormErrors(errors);
        }
      });
    },
    deleteClick: async function (e) {
      setDialog({
        open: true,
        title: "Delete Komatsu Contact",
        message: appResources.DELETE_CONFIRMATION_MSG,
        actionName: "deleteClickAlert",
      });
    },
    deleteClickAlert: async function () {
      const response = await get(
        `${CONTACTS_APIS.DELETE_CONTACTS}?ContactId=${data.ContactId ? data.ContactId : 0
        }&Screen=Edit Contacts`
      );
      if (response > 0) {
        showToast(appResources.DELETED_SUCCESSFULLY_MSG);
        refreshGrid();
      } else {
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
      setEditContactsDialog((prevState) => ({ ...prevState, open: false }));
      setContactsData();
    },
  };
  function searchKomatsuEmployeeClick() {
    setDialog({
      open: true,
      title: "Search for Komatsu Employees",
      width: "100vw",
    });
  }
  function searchCustomerPortalClick() {
    setDialog({
      open: true,
      title: "Search for Customer Portal Users",
      width: "100vw",
    });
  }
  //Get Edit Contact data
  const { get, post } = useFetch();
  useEffect(
    function () {
      if (contactId) {
        (async function () {
          const contactData = await get(
            `${CONTACTS_APIS.EDIT_CONTACTS}?contactId=${contactId}`
          );
          setContactRoles(contactData.ContactRoles);
          setContactCountries(contactData.Countries);
          setContactData(contactData.Contact);
          contactData.Contact.ContactType = contactData.Contact.IsKomatsu
            ? 1
            : contactData.Contact.IsPortalUser
              ? 2
              : contactData.Contact.IsLabcheckUser
                ? 3
                : 4;
          dispatch({
            type: ContactActions.LOAD_DATA,
            payload: contactData.Contact,
          });
        })();
      } else {
        (async function () {
          const contactData = await get(
            `${CONTACTS_APIS.EDIT_CONTACTS}?contactId=${contactId}`
          );
          setContactRoles(contactData.ContactRoles);
          setContactCountries(contactData.Countries);
        })();
      }
    },
    [contactId]
  );
  function clickAccessPermissions() {
    setPropId(2);
    setContactAccessDialogueOpen(true);
  }
  function clickSampleReports() {
    setPropId(3);
    setContactAccessDialogueOpen(true);
  }
  function clickPrimaryContact() {
    setDialog({
      open: true,
      title: `Contacts Primary Mappings for ${data && data.Username}`,
      width: "xl",
    });
  }
  function generateTempClick() {
    tempUSerName++;
    dispatch({
      payload: {
        ...data,
        Username: "temp@00000" + tempUSerName,
        _key: Date.now(),
      },
      type: ContactActions.LOAD_DATA,
    });
  }
  function maintenanceClick() {
    dispatch({
      payload: {
        ...data,
        Forename: "Maintenance",
        Surname: "Supervisor",
        DisplayName: "Maintenance Supervisor",
        _key: Date.now(),
      },
      type: ContactActions.LOAD_DATA,
    });
  }
  function administratorClick() {
    dispatch({
      payload: {
        ...data,
        Forename: "Administrator",
        Surname: "",
        DisplayName: "Administrator",
        _key: Date.now(),
      },
      type: ContactActions.LOAD_DATA,
    });
  }
  function komatsuEmployeeClick(rowData) {
    dispatch({
      payload: {
        ...data,
        Username: rowData.Mail,
        Forename: rowData.GivenName,
        Surname: rowData.Surname,
        DisplayName: rowData.DisplayName,
        Mobile: rowData?.MobilePhone,
        _key: Date.now(),
      },
      type: ContactActions.LOAD_DATA,
    });
  }
  return (
    <>
      <Form
        sections={GetFormSections(
          contactRoles,
          contactCountries,
          contactId,
          searchKomatsuEmployeeClick,
          searchCustomerPortalClick,
          generateTempClick,
          administratorClick,
          maintenanceClick,
          data
        )}
        data={data}
        spacing={2}
        key={data?._key}
        errors={formErrors}
        dispatch={dispatch}
      />
      <FormFooter buttons={GetFormButtons(actions, contactId)} />
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth={dialog.width}
      >
        {dialog.title ===
          `Contacts Primary Mappings for ${data && data.Username}` && (
            <ContactPrimary contactId={data && data.ContactId} />
          )}

        {dialog.title === "Search for Komatsu Employees" && (
          <SearchKomatsuEmployee
            komatsuEmployeeClick={komatsuEmployeeClick}
            setDialog={setDialog}
          />
        )}
        {dialog.title === "Search for Customer Portal Users" && (
          <SearchPortalUsers />
        )}

        {dialog.title === "Delete Komatsu Contact" && (
          <AlertDialog
            handleClose={() => {
              setDialog((prevState) => ({
                ...prevState,
                open: false,
              }));
            }}
            alertAction={actions.deleteClickAlert}
            message={dialog.message}
          />
        )}
      </AppDialog>
      <AppDialog
        open={contactAccessDialogueOpen}
        handleClose={() => setContactAccessDialogueOpen(false)}
        title={
          propId === 2
            ? `Contacts Access Permissions for ${data.Username}`
            : propId === 3
              ? `Contacts Sample Reporting for display ${data.Username}`
              : propId === 4
                ? `Contacts Subscriptions for ${data.Username}`
                : `Some other message for propId ${propId}`
        }
        maxWidth="xl"
      >
        <ContactAccessPermission
          contactId={data && data.ContactId}
          propId={propId}
        />
      </AppDialog>
    </>
  );
}
const GetFormButtons = ({ save, deleteClick }, contactId) => [
  {
    variant: "contained",
    label: "Delete",
    startIcon: <Delete />,
    onClick: deleteClick,
    disabled: !contactId,
  },
  {
    variant: "contained",
    label: "Save",
    startIcon: <Save />,
    onClick: save,
  },
];
function GetFormSections(
  contactRoles,
  contactCountries,
  contactId,
  searchKomatsuEmployeeClick,
  searchCustomerPortalClick,
  generateTempClick,
  administratorClick,
  maintenanceClick,
  data
) {
  return [
    {
      xlCol: 12,
      fields: [
        {
          xsCol: 6,
          group: [
            {
              xsCol: 12,
              component: function () {
                return contactId ? (
                  <Typography variant="subtitle2">
                    Please update the details for a contact
                  </Typography>
                ) : (
                  <></>
                );
              },
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "IsKomatsu",
                  label: "Komatsu",
                  type: "switch",
                  xsCol: 3,
                },

                {
                  xlCol: 5,
                  alignSelf: "flex-end",
                  hidden: !data.IsKomatsu,
                  component: function () {
                    return (
                      <Button
                        variant="outlined"
                        onClick={searchKomatsuEmployeeClick}
                      >
                        Search Komatsu Employee
                      </Button>
                    );
                  },
                },
                {
                  xlCol: 2,
                  alignSelf: "flex-end",
                  hidden: !data.IsKomatsu,
                  component: function () {
                    return <Typography>KAL Employees</Typography>;
                  },
                },
              ],
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "IsPortalUser",
                  label: "Customer Portal",
                  type: "switch",
                  className: "orange",

                  xsCol: 3,
                },
                {
                  xlCol: 8,
                  alignSelf: "flex-end",
                  hidden: !data.IsPortalUser,
                  component: function () {
                    return (
                      <Button
                        variant="outlined"
                        onClick={searchCustomerPortalClick}
                      >
                        Search Customer Portal
                      </Button>
                    );
                  },
                },
              ],
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "IsLabcheckUser",
                  label: "Labcheck",
                  type: "switch",
                  className: "green",

                  xsCol: 3,
                },
              ],
            },
            {
              xsCol: 12,
              group: [
                {
                  name: "WebsiteNoAccess",
                  label: "No Website",
                  className: "brown",
                  type: "switch",
                  xsCol: 3,
                },
                {
                  xlCol: 8,
                  alignSelf: "flex-end",
                  hidden: !data.WebsiteNoAccess,
                  component: function () {
                    return (
                      <Button variant="outlined" onClick={generateTempClick}>
                        Generate Temp Username
                      </Button>
                    );
                  },
                },
              ],
            },
            {
              name: "Username",
              label: "Email",
              xsCol: 6,
            },
            {
              name: "ContactRoleId",
              label: "Role",
              type: "dropdown",
              xsCol: 6,
              required: true,
              selectOptions: (contactRoles || []).map((x) => ({
                value: x.ContactRoleId,
                text: x.ContactRole,
              })),
            },
            {
              name: "Forename",
              label: "Forename",
              type: "text",
              xsCol: 4,
            },
            {
              name: "Surname",
              label: "Surname",
              xsCol: 4,
            },
            {
              name: "DisplayName",
              label: "Display",
              xsCol: 4,
            },
            {
              xsCol: 12,
              group: [
                {
                  xsCol: 4,
                  alignSelf: "flex-end",
                  component: function () {
                    return (
                      <Button variant="outlined" onClick={maintenanceClick}>
                        Maintenance Supervisor
                      </Button>
                    );
                  },
                },
                {
                  xsCol: 4,
                  alignSelf: "flex-end",
                  component: function () {
                    return (
                      <Button variant="outlined" onClick={administratorClick}>
                        Administrator
                      </Button>
                    );
                  },
                },
                {
                  xsCol: 4,
                  alignSelf: "flex-end",
                  component: function () {
                    return <Button variant="outlined">Set Display</Button>;
                  },
                },
              ],
            },

            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography variant="caption" style={{ fontSize: 13 }}>
                    AU +61 and 1 area code and 8 digits
                  </Typography>
                );
              },
            },
            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography variant="caption" style={{ fontSize: 13 }}>
                    NC +687 and 6 digits
                  </Typography>
                );
              },
            },
            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography variant="caption" style={{ fontSize: 13 }}>
                    NZ Mobile +642 and 7 to 9 digits
                  </Typography>
                );
              },
            },
            {
              xsCol: 6,
              component: function () {
                return (
                  <Typography variant="caption" style={{ fontSize: 13 }}>
                    NZ Phone +64 and 1 area code and 7 digits
                  </Typography>
                );
              },
            },
            {
              name: "AllowMail",
              label: "Allow Postal Mail",
              type: "switch",
              xsCol: 4,
              className: "green",
            },
            {
              name: "AllowSms",
              label: "Allow SMS",
              type: "switch",
              xsCol: 4,
              className: "green",
            },
            {
              name: "Mobile",
              label: "Mobile",
              xsCol: 4,
            },
            {
              name: "Telephone",
              label: "Telephone",
              xsCol: 4,
            },
            {
              name: "Company",
              label: "Company",
              xsCol: 4,
            },

            {
              name: "BranchSite",
              label: "Branch/Site",
              xsCol: 4,
            },
            {
              name: "Department",
              label: "Department",
              xsCol: 4,
            },
            {
              name: "Position",
              label: "Position",
              xsCol: 4,
            },

            {
              name: "Street1",
              label: "Street",
              xsCol: 2,
            },
            {
              xlCol: 2,
              alignSelf: "flex-end",
              component: function () {
                return <Button variant="outlined">View</Button>;
              },
            },
            {
              name: "Street2",
              label: "Suburb",
              xsCol: 4,
            },
            {
              name: "City",
              label: "City",
              xsCol: 4,
            },

            {
              name: "PostCode",
              label: "Postcode",
              xsCol: 4,
            },
            {
              name: "State",
              label: "State",
              xsCol: 4,
            },
            {
              name: "Country",
              label: "Country",
              type: "dropdown",
              xsCol: 8,
              required: true,
              selectOptions: (contactCountries || []).map((x) => ({
                value: x.Country,
                text: x.Country,
              })),
            },
          ],
        },
        {
          xsCol: 6,
          group: [
            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="caption" style={{ marginTop: "25px" }}>
                    1. Choose the contact type - most contacts are Komatsu or
                    KOWA/Customer Portal
                  </Typography>
                );
              },
            },
            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    2. If a Komatsu employee, get the employee details user
                    details with the Search Komatsu Employee button
                  </Typography>
                );
              },
            },
            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    3. If a KOWA/Customer Portal user, get the user details with
                    the Search Customer Portal button
                  </Typography>
                );
              },
            },

            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    4. Apostrophes or special characters in the email address
                    cannot be used to log into the Customer Portal
                  </Typography>
                );
              },
            },

            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    5. If there is no email (username), choose No Website, and
                    use the Generate Temp Username button
                  </Typography>
                );
              },
            },
            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    6. Add user details where available - always select a
                    COUNTRY - this VERY DIFFICULT to change for Portal users
                  </Typography>
                );
              },
            },
            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="caption" style={{}}>
                    7. Do NOT use # or fill in fields with a full stop
                  </Typography>
                );
              },
            },

            {
              name: "IsArchived",
              label: "Archived- no website or reports",
              type: "switch",
              className: "red",
              xsCol: 5,
            },
            {
              name: "OptInEmailsMarketing",
              label: "Allow Informational Emails",
              type: "switch",
              xsCol: 5,
            },
            {
              name: "ZipAttachments",
              label: "Zip",
              type: "switch",
              xsCol: 2,
            },

            {
              name: "AttachmentMB",
              label: "Attachments",
              type: "number",
              xsCol: 5,
            },

            {
              name: "EmailPerAttachment",
              label: "Separate Email per Attachment",
              type: "switch",
              xsCol: 6,
            },

            {
              name: "Notes",
              label: "Contact Comments",
              xsCol: 12,
              rows: "15.5",
              type: "textarea",
            },
          ],
        },
      ],
    },
  ];
}
const ContactActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    const { name, value } = action.payload;

    // Check the input name and apply specific modifications
    if (name === "IsKomatsu") {
      return {
        ...state,
        IsKomatsu: true,
        IsPortalUser: false,
        IsLabcheckUser: false,
        WebsiteNoAccess: false,
      };
    } else if (name === "IsPortalUser") {
      return {
        ...state,
        IsKomatsu: false,
        IsPortalUser: true,
        IsLabcheckUser: false,
        WebsiteNoAccess: false,
      };
    } else if (name === "IsLabcheckUser") {
      return {
        ...state,
        IsKomatsu: false,
        IsPortalUser: false,
        IsLabcheckUser: true,
        WebsiteNoAccess: false,
      };
    } else if (name === "WebsiteNoAccess") {
      return {
        ...state,
        IsKomatsu: false,
        IsPortalUser: false,
        IsLabcheckUser: false,
        WebsiteNoAccess: true,
      };
    }
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === ContactActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}

const SearchFilterDefaults = {
  ContactRoleId: 1,
  IsPortalUser: true,
  AllowSms: true,
  OptInEmailsMarketing: true,
  AttachmentMB: 50,
  ContactType: 2,
  Country: "Australia",
};
