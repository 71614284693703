import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import DataTable from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { TRAY_APIS } from "../../../common/apis";
import { SearchIcon } from "../../../icons/ButtonIcons";
import { useUser } from "../../../rootContext";

export default function InsiteModelSearch({ }) {
  const user = useUser();

  const SearchFilterDefaults = {
    Make: "KOMATSU",
    Model: "",
    Source: "Insite Model Search",
    Username: user.Username,
  };

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const { post } = useFetch();

  const [feedbackData, setFeedbackData] = useState([]);
  //call api for search audit data
  function search() {
    (async function () {
      let resData = await post(TRAY_APIS.MODEL_INSITE, {
        ...data,
      });
      if (resData) {
        setFeedbackData(resData);
      }
    })();
  }
  useEffect(() => {
    document.title = "Insite Model Search";
  }, []);
  const tblOptions = {
    columns: GetColumns(feedbackData),
    dataSource: (feedbackData || []).map((x) => ({
      ...x,
      keyProp: x.Make + "" + (x.Model || ""),
    })),
    rowIdProperty: "keyProp",
    // isDisableColumnFilter: true,
    sx: { height: "70vh" },
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(search, data)}
            data={data}
            dispatch={dispatch}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ marginLeft: "5px", marginTop: "10px" }}
          >
            Total Records: <b>{feedbackData && feedbackData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns() {
  return [
    {
      headerName: "Make",
      field: "Make",
      minWidth: 150,
    },
    {
      headerName: "Model",
      field: "Model",
      minWidth: 150,
    },
  ];
}

function GetFormSections(search, data) {
  return [
    {
      fields: [
        {
          xsCol: 12,
          group: [
            {
              xsCol: 5,
              type: "text",
              name: "Make",
              label: "Make",
              placeholder: "KOMATSU",
              value: "KOMATSU",
              disabled: true,
            },
            {
              xsCol: 5,
              name: "Model",
              label: "Model",
            },
            {
              xsCol: 2,
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={search}
                    sx={{ mt: "17px" }}
                    disabled={
                      data?.Make == "" && data?.Model == "" ? true : false
                    }
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
