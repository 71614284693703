import { Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import {
  ContactPage,
  Edit,
  EventNote,
  NotificationsNone,
} from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import AppDialog from "../../../components/AppDialog";
import { useState, useEffect } from "react";
import EditSite from "../../../forms/EditSite";
import EditEvent from "../../events/EditEvent";
import { DETAILS_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import SearchContact from "../../quick-details/SearchContact";
import { connect } from 'react-redux';
import { useRoot } from "../../../rootContext";

function SiteDetail({
  // data,
  product,
  customer,
  site,
  unit,
  component,
  unitModel,
  siteDetails,
}) {
  const { post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();

  const [open, setOpen] = useState(false);
  const [openEditEvent, setOpenEditEvent] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [isNotify, setIsNotify] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setData(siteDetails)
  }, [siteDetails])

  useEffect(() => {
    if (data?.NotificationUserId !== null) {
      return setIsNotify(true)
    }
    else {
      return setIsNotify(false)
    }
  }, [data?.NotificationUserId])

  const getMachineInfo = async () => {
    let dataList = await post(DETAILS_APIS.UPDATE_MACHINE_NOTIFICATIONS,
      { SiteId: data?.SiteId, HasNotification: isNotify })
    if (dataList?.Success === true) {
      return setIsNotify(!isNotify)
    }
  }

  const actions = {
    edit: function (e) {
      setOpen(true);
    },
    events: function (e) {
      setOpenEditEvent(true);
    },
    notifyMe: function () {
      getMachineInfo()
    },
    contacts: function () {
      setOpenContact(true);
    },
  };
  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections}
          spacing={2}
          data={data}
          key={data && data.SiteId + new Date()}
        />
        <FormFooter buttons={GetFormButtons(data, isNotify, actions, CanDoDataMaintenance)} />
      </Card>
      <AppDialog
        open={open}
        title="Edit Site"
        maxWidth="lg"
        handleClose={() => setOpen(false)}
      >
        <EditSite
          cancel={() => setOpen(false)}
          siteId={data.SiteId}
          customerId={data?.CustomerId}
        //prodId={data.ProductId}
        />
      </AppDialog>
      <AppDialog
        open={openEditEvent}
        title="Edit Event"
        maxWidth="lg"
        handleClose={() => setOpenEditEvent(false)}
      >
        <EditEvent
          cancel={() => setOpenEditEvent(false)}
          eventFilter={{
            siteId: data.SiteId,
            isSite: true,
            product: product,
            customer: customer,
            site: site,
            unit: unit,
            component: component,
            unitModel: unitModel,
          }}
        />
      </AppDialog>

      <AppDialog
        open={openContact}
        title="Search Contacts"
        maxWidth="100vw"
        fullWidth={true}
        handleClose={() => setOpenContact(false)}
      >
        <SearchContact
          cancel={() => setOpenContact(false)}
          siteId={data.SiteId}
          customerId={data?.CustomerId}
        //prodId={data.ProductId}
        />
      </AppDialog>
    </>
  );
}

const mapStateToProps = ({ site }) => {
  const { siteDetails, } = site;
  return { siteDetails, };
};

export default connect(mapStateToProps, null)(SiteDetail);

const GetFormButtons = (data, isNotify, actions, CanDoDataMaintenance) => [
  {
    variant: data?.EventLogCount > 0 ? "contained" : "outlined",
    color: data?.EventLogCount > 0 ? "lightseagreen" : "primary", label: "Event Log",
    startIcon: <EventNote />,
    onClick: actions.events,
    badge: { badgeContent: data.EventLogCount, badgeColor: "lightseagreen", sx: { [`& .MuiBadge-badge`]: { border: "1px solid white" } } },
  },
  {
    variant: "outlined",
    onClick: actions.contacts,
    label: "Contact",
    // disabled: !data.HasPrimaryContact,
    startIcon: <ContactPage />,
  },
  {
    variant: "outlined",
    label: isNotify === true ? "Unnotify" : "Notify Me",
    onClick: actions.notifyMe,
    startIcon: <NotificationsNone />
  },
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Edit",
    onClick: actions.edit,
    startIcon: <Edit />,
  },
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Site Details" />;
        },
      },
      { name: "Site", label: "Site", xsCol: 3 },
      { name: "SiteExportOption", label: "Exports", xsCol: 3 },
      { name: "SiteCmsOwnerName", label: "CMS Owner", xsCol: 3, },
      { name: "KalBranch", label: "Branch", xsCol: 3 },
      // { name: "EventLogCount", label: "Event Log", xsCol: 3 },
      // { name: "HasPrimaryContact", label: "Contacts", xsCol: 3, },
      { name: "SiteNotes", label: "Comment", type: "textarea", xsCol: 12, rows: 2 },
    ],
  },
];
