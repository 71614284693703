import { Grid, Typography, } from "@mui/material";
import AppDialog from "../../../components/AppDialog";

import SampleReportsOverviewFormTable from "./PrimaryContactOverviewFormTable";
import useFetch from "../../../hooks/useFetch";
import SearchContact from "../../quick-details/SearchContact";
import { useEffect, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";

export default function PrimaryContactOverview({
  formData,
  rowDataProps,
  isKomatsuCheck,
  setOpenDialog,
}) {
  const { post } = useFetch();

  const [searchContactDialogueOpen, setSearchContactDialogueOpen] = useState(false);

  function searchContactScope() {
    setSearchContactDialogueOpen(true);
  }
  const [primaryContactData, setPrimaryContactData] = useState([]);
  // const [rowData, setRowData] = useState([]);

  function rowSelectedHandler(rowData) {
    rowDataProps(rowData);
  }

  useEffect(() => {
    (async function () {
      let parseData = {};
      let formDatas = localStorage.getItem("primary-contacts-overview")

      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas)
      }

      let resData = await post(FLEET_APIS.CONTACTS_FOR_PRIMARY, {
        SampleNumber: formData?.SampleNumber || parseData?.SampleNumber,
        CustomerId: formData?.CustomerId || parseData?.CustomerId,
        SiteId: formData?.SiteId || parseData?.SiteId,
        UnitId: formData?.UnitId || parseData?.UnitId,
        ReportNumber: formData?.ReportNumber || parseData?.ReportNumber,
      });
      setPrimaryContactData(resData);
      localStorage.removeItem("primary-contacts-overview");
    })();
  }, [formData?.SampleNumber]);

  return (
    <>
      <Grid container spacing={2} sx={{ minWidth: "90vw" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records:{" "}
            <b> {primaryContactData && primaryContactData.length}</b>
          </Typography>
          <SampleReportsOverviewFormTable
            setOpenDialog={setOpenDialog}
            data={primaryContactData}
            rowSelected={rowSelectedHandler}
          />
        </Grid>
      </Grid>
      <AppDialog
        open={searchContactDialogueOpen}
        handleClose={() => setSearchContactDialogueOpen(false)}
        title="Search Contacts"
        maxWidth="100vw"
      >
        <SearchContact />
      </AppDialog>
    </>
  );
}
