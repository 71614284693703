import NewDataTable from "../../../components/NewDataTable";

export default function ResultsTableNew({
  data = [],
  columns1 = [],
  handleCellSave,
  lockResults,
}) {
  const tblOptions1 = {
    sx: { height: 320 },
    columns: columns1,
    dataSource: data || [],
    enableColumnActions: false,
    enableSorting: false,
    enablePinning: true,
    enableEditing: !lockResults,
    initialState: { columnPinning: { left: ['SampleNumber'] } },
    muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
      onBlur: (event) => {
        return handleCellSave(cell, event.target);
      },
    })
  };
  return <NewDataTable {...tblOptions1} />;
}
