import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function TestSetsTable({ data, actions }) {
  const options = {
    columns: GetColumns(actions),
    dataSource: data || [],
    rowIdProperty: "TestSetId",
    isDisableColumnFilter: true,
    sx: { height: 766 },
  };
  return <DataTable {...options} />;
}
function GetColumns(actions) {
  return [
    {
      headerName: "Test Set",
      field: "TestSet",
      sortable: false,
      minWidth: 260,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Specific",
      field: "IsCustomerSpecific",
      sortable: false,
      valueFormatter: boolToYesAndEmpty,
      minWidth: 80,
    },
    {
      headerName: "System",
      field: "IsSystemTestSet",
      sortable: false,
      valueFormatter: boolToYesAndEmpty,
      minWidth: 80,
    },
    {
      headerName: "Limits",
      field: "Limits",
      sortable: false,
      valueFormatter: boolToYesAndEmpty,
      minWidth: 80,
    },
    {
      headerName: "Fluid",
      field: "FluidType",
      sortable: false,
      minWidth: 120,
    },
    {
      headerName: "Product",
      field: "Product",
      sortable: false,
      minWidth: 120,
    },
    {
      headerName: "Archived",
      field: "IsArchived",
      sortable: false,
      valueFormatter: boolToYesAndEmpty,
      minWidth: 80,
    },
  ];
}

function boolToYesAndEmpty({ value }) {
  return value ? "Yes" : "";
}
