import { Check, } from "@mui/icons-material";
import { Card, Grid, Typography } from "@mui/material";
import { useEffect, } from "react";
import { useReducer, useState } from "react";
import moment from "moment";
import useFetch from "../hooks/useFetch";
import Form, { FormActions } from "../components/Form";
import { MANAGEMENT_APIS } from "../common/apis";
import { useRoot, } from "../rootContext";
import FormFooter from "../components/FormFooter";

export default function SpecialReport() {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const { get, post } = useFetch();
  const { laboratories } = useRoot();
  const [editTrayData, setEditTrayData] = useState([]);


  //Call api for user dropdown data
  useEffect(() => {
    //  resetData();
  }, []);

  async function resetData() {
    let responseTrayData = await get(MANAGEMENT_APIS.MESSAGES_ALL, {});
    if (responseTrayData) {
      if (responseTrayData.MessagesAll.length > 0) {
        setEditTrayData(responseTrayData.MessagesAll);
      } else {
        setEditTrayData([]);
      }
    }
  }

  //call api for search audit data
  async function saveClick() {
    (async function () {
      await post(MANAGEMENT_APIS.EDIT_MESSAGE, {
        ...data,
        //   AddedBy: data.UserId,
        DisplayFrom: data.NoEndDate ? null : data.DisplayFrom,
        DisplayTo: data.NoEndDate ? null : data.DisplayTo,
        AddedBy: "User d1",
        LaboratoryId: data.LaboratoryId === 4 ? 0 : data.LaboratoryId,
        UserId: data.UserId ? data.UserId : 0,
      });
    })();
    await resetData();
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(laboratories)}
              data={data}
              dispatch={dispatch}
              key={data._ts_key}
            />

            <FormFooter buttons={GetFormButtons(saveClick)} />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(laboratories) {
  return [
    {
      fields: [
        {
          xsCol: 12,
          group: [
            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="body1" style={{ fontSize: 13 }}>
                    KOWA 24 Hour Hose
                  </Typography>
                );
              },
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            {
              xsCol: 3,
              component: function () {
                return (
                  <Typography variant="body1" style={{ fontSize: 13 }}>
                    841940 (BER) Hydraulic
                  </Typography>
                );
              },
            },
            {
              name: "IsShowOnWebsite",
              label: "Show On Website",
              xsCol: 6,
              type: "switch",
            },
          ],
        },

        {
          name: "",
          label: "Report Type",
          type: "dropdown",
          xsCol: 2,
          required: true,
          selectOptions: [],
        },
        {
          name: "IsArchived",
          label: "Archived",
          xsCol: 2,
          type: "switch",
        },
        {
          name: "",
          label: "Reference",
          xsCol: 2,
          type: "datetime",
          disablePast: true,
        },
        {
          name: "LaboratoryId",
          label: "Laboratory",
          type: "dropdown",
          xsCol: 2,
          required: true,
          selectOptions: (laboratories || [])
            ?.filter((x) => x?.LaboratoryId !== 0)
            ?.map((x) => ({
              value: x.LaboratoryId,
              text: x.Laboratory,
            })),
        },
        {
          xsCol: 12,
          group: [
            {
              name: "",
              label: "Job No",
              xsCol: 2,
            },
            {
              name: "",
              label: "Unit Hours",
              xsCol: 2,
            },
            {
              name: "",
              label: "Component Hours",
              xsCol: 2,
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            {
              name: "",
              label: "This Version",
              xsCol: 2,
              type: "number",
            },
            {
              name: "Billing Rate",
              label: "Billing Rate",
              xsCol: 2,
              type: "number",
            },
            {
              name: "",
              label: "Hours",
              xsCol: 2,
              type: "number",
            },
          ],
        },
        {
          xsCol: 12,
          group: [
            {
              xsCol: 2,
              alignSelf: "end",
              component: function ({ data }) {
                return (
                  <Typography variant="body1" style={{ fontSize: 13 }}>
                    Report File
                  </Typography>
                );
              },
            },
            {
              name: "Release",
              label: "Release",
              xsCol: 1,
              type: "switch",
            },
          ],
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchFilterDefaults, _ts_key: Date.now() };
  }
  return state;
}
const SearchFilterDefaults = {
  DisplayFrom: moment().toISOString(),
  LaboratoryId: 1,
};
const GetFormButtons = ({ saveClick }) => [
  {
    label: "Save",
    onClick: saveClick,
    startIcon: <Check />,
  },
];
