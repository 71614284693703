import { Box, Typography } from "@mui/material";
import { AddAPhoto, } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import Form from "../components/Form";
import FormFooter from "../components/FormFooter";
import SampleReducer, { SampleActions } from "./reducers/SampleReducer";
import useFetch from "../hooks/useFetch";
import AppDialog from "../components/AppDialog";
import ImagePick from "../components/SelectImage";
import DropDownAdvance from "../components/DropDownAdvance";
import GetStepNames from "../common/GetStepNames";
import { DETAILS_APIS } from "../common/apis";
import { useRoot, useToast } from "../rootContext";
import appResources from "../app-resources/en/Resources.json";
import ManageSample from "../features/quick-details/manage/manage-sample/ManageSample";

export default function EditSample({ cancel, sampleNo, setOpenEditSample }) {

  const { CanDoDataMaintenance, CanLaboratoryTrays, CanEvaluateNotTrainee } = useRoot();
  const [data, dispatch] = useReducer(SampleReducer, {});
  const { get, post } = useFetch();
  const { showToast, showErrorToast } = useToast();

  const [dropdownData, setDropdownData] = useState([]);
  const [imagePickerOpen, setImagePickerOpen] = useState(false);
  const [openManagement, setOpenManagement] = useState(false);

  const actions = {
    save: async function (e, option) {
      if (option) {
        alert(option);
        return;
      }
      //{
      const req = {
        Registration: {
          ...data,
          IsInternalOnly: data.Internal,
          FluidChangedAt: data.FluidChangedHours,
          FluidChangedAtDate: data.FluidChangedDate,
          //  CompChangedEventId: data.ComponentChanged,
          ComponentChangedEventId: data.ComponentChangedEventId,
          ComponentChangedAt: data.CompChangedHours,
          ComponentChangedAtDate: data.CompChangedDate,
          ComponentRepaired: data.ComponentRepaired,
          ComponentRepairedAt: data.CompRepairedHours,
          ComponentRepairedAtDate: data.CompRepairedDate,
          MeterChanged: data.MeterChanged,
          MeterChangedAt: data.MeterChangedHours,
          MeterChangedAtDate: data.MeterChangedDate,

          Screen: "Sample",
        },
        SampleNotes: data.SampleNotes || "",
        CustomerNote: data.CustomerComments || "",
        Delivered: data.Delivered,
        UnitId: data.UnitId ? data.UnitId : null,
      };
      const responseEditSample = await post(DETAILS_APIS.EDIT_SAMPLE, req);
      if (responseEditSample?.EventLogId >= 0) {
        showToast(appResources.SUCCESSFULLY_INSERTED_MSG);
      } else {
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
      setOpenEditSample && setOpenEditSample(false);
      cancel && cancel();
    },
    approve: function (e, approveTray) { },
    imageOpen: function (e) {
      setImagePickerOpen(true);
    },
    manage: async function () {
      setOpenManagement(true);
    },
    cancel: cancel,
  };

  useEffect(() => {
    (async () => {
      if (sampleNo) {
        const data = await get(`/details/sample?sampleNo=${sampleNo}`);
        if (data) {
          setDropdownData(data.MasterData || {});
          dispatch({
            type: SampleActions.LOAD_DATA,
            payload: {
              ...data.Sample,
              ComponentChanged: data.Sample?.CompChangedEventId > 0 ? true : false,
              ComponentRepaired: data.Sample?.CompRepairedEventId > 0 ? true : false,
              FluidChanged: data.Sample?.FluidChangedEventId > 0 ? true : false,
              MeterChanged: data.Sample?.MeterChangedEventId > 0 ? true : false,
            }
          });
          return;
        }
      }
    })();
  }, []);

  const formSections = useMemo(
    () => GetFormSections(data, dropdownData, CanDoDataMaintenance, CanLaboratoryTrays),
    [data, dropdownData, CanDoDataMaintenance, CanLaboratoryTrays]
  );

  return (
    <>
      <Form
        sections={formSections}
        data={data}
        spacing={2}
        dispatch={dispatch}
        key={data.SampleNumber}
      />
      {(CanDoDataMaintenance || CanLaboratoryTrays || CanEvaluateNotTrainee) && <FormFooter
        footerText={`ProductId: ${data?.ProductId || ""}, CustomerId: ${data?.CustomerId || ""
          }, SiteId: ${data?.SiteId || ""}, UnidId: ${data?.UnidId || ""
          } , ComponentId: ${data?.ComponentId || ""} , SampleNumber: ${data?.SampleNumber || ""
          } `}
        buttons={GetFormButtons(data, actions, CanEvaluateNotTrainee)} />}

      <AppDialog
        open={imagePickerOpen}
        handleClose={() => setImagePickerOpen(false)}
        title="Add Image"
        maxWidth="xl"
      >
        <ImagePick sampleId={data.SampleNumber} />
      </AppDialog>
      <AppDialog
        open={openManagement}
        title={`Sample Management  Sample ${data.SampleNumber}`}
        maxWidth="100vw"
        handleClose={() => setOpenManagement(false)}
      >
        <ManageSample unit={data} />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, { cancel, save, approve, imageOpen, manage, CanEvaluateNotTrainee },) => [
  //CanEvaluateNotTrainee&& {
  //   variant: "outlined",
  //   label: "Delete",
  //   onClick: delete,
  //   startIcon: <DeleteIcon />,
  // },
  {
    variant: "contained",
    label: "Manage",
    onClick: manage,
  }, {
    hidden: CanEvaluateNotTrainee,
    label: "Add Image",
    onClick: imageOpen,
    startIcon: <AddAPhoto />,
  },
  {
    hidden: CanEvaluateNotTrainee,
    label: "Resend",
    type: "dropdown",
    onClick: approve,

    options: [{ label: "Resend Tray", onClick: (e) => approve(e, true) }],
  },
  {
    hidden: CanEvaluateNotTrainee,
    label: "Save",
    onClick: save,
    type: "dropdown",
    options: [
      {
        label: "Archived - no website",
        onClick: (e) => save(e, "archive"),
      },
    ],
  },
];

function GetFormSections(data, dropdownData, CanDoDataMaintenance, CanLaboratoryTrays) {
  let componentDetails = dropdownData?.Component;
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          component: function () {
            return (
              <Typography>{` ${componentDetails?.Product}, ${componentDetails?.Customer}, ${componentDetails?.Site}, ${componentDetails?.Unit}  (${componentDetails?.UnitModelFormatted} ${componentDetails?.UnitSerial}), ${componentDetails?.Component}`}</Typography>
            );
          },
        },
        {
          xsCol: 12,
          sx: { paddingTop: 0 },
          component: function () {
            return (
              <Typography
                sx={{ color: "purple" }}
              >{`If anything is UNKNOWN. e.g. Meter Reading, then make the value EMPTY and NOT ZERO; zero means that the value is known and brand new`}</Typography>
            );
          },
        },
        {
          name: "SampleNumber",
          label: "Sample Number",
          xsCol: 2,
          readOnly: true,
        },
        { name: "SIF", label: "Registration", xsCol: 2, readOnly: true },

        {
          name: "Step",
          label: "Step",
          xsCol: 2,
          readOnly: true,
          getValue: (data) =>
            data.Step ? `${GetStepNames(data.Step).long} : ${data.Step}` : "",
        },
        {
          name: "OwnerLaboratoryId",
          label: "Tray Owner",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (dropdownData.Laboratories || []).map((x) => ({
            value: x.LaboratoryId,
            text: x.Laboratory,
          })),
        },
        {
          xsCol: 4,
          group: [
            { name: "Sampled", label: "Sampled", xsCol: 6, type: "datetime" },
            {
              name: "SampledAssumed",
              label: "Assumed",
              type: "switch",
              xsCol: 6,
            },
          ],
        },
        {
          name: "Released",
          label: "Laboratory",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "TrayDateDate",
          label: "Tray Date",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "RegistrationCreated",
          label: "Card Created",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "QualityChecked",
          label: "Quality Check",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "Released",
          label: "Released",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
      ],
    },
    {
      xlCol: 12,
      fields: [
        {
          xsCol: 6,
          group: [
            {
              name: "MeterChanged",
              label: "Meter Changed",
              value: true,
              type: "switch",
              xsCol: 4,
            },
            {
              name: "MeterChangedHours",
              label: "Changed At",
              xsCol: 4,
              disabled: !data.MeterChanged,
            },
            {
              name: "MeterChangedDate",
              label: "Approx. Changed",
              type: "datetime",
              xsCol: 4,
              disabled: !data.MeterChanged,
            },
          ],
        },
        {
          xsCol: 6,
          group: [
            {
              name: "ComponentChanged",
              label: "Component Changed",
              type: "switch",
              xsCol: 4,
            },
            {
              name: "CompChangedHours",
              label: "Changed At",
              xsCol: 4,
              disabled: !data.ComponentChanged,
            },
            {
              name: "CompChangedDate",
              label: "Approx. Changed",
              type: "datetime",
              xsCol: 4,
              disabled: !data.ComponentChanged,
            },
          ],
        },
        {
          xsCol: 6,
          group: [
            {
              name: "ComponentRepaired",
              label: "Component Repaired",
              type: "switch",
              xsCol: 4,
            },
            {
              name: "CompRepairedHours",
              label: "Repaired At",
              xsCol: 4,
              disabled: !data.ComponentRepaired,
            },
            {
              name: "CompRepairedDate",
              label: "Approx. Repaired",
              type: "datetime",
              xsCol: 4,
              disabled: !data.ComponentRepaired,
            },
          ],
        },
        {
          xsCol: 6,
          group: [
            {
              name: "FluidChanged",
              label: "Fluid Changed",
              type: "switch",
              xsCol: 4,
            },
            {
              name: "FluidChangedHours",
              label: "Changed At",
              xsCol: 4,
              disabled: !data.FluidChanged,
            },
            {
              name: "FluidChangedDate",
              label: "Approx. Changed",
              type: "datetime",
              xsCol: 4,
              disabled: !data.FluidChanged,
            },
          ],
        },
      ],
    },
    {
      xsCol: 12,
      fields: [
        { name: "FluidHours", label: "Fluid Hours", xsCol: 2, type: "number" },
        {
          name: "SampleOptionId",
          label: "Kidney Loop",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (dropdownData.SampleOptions || []).map((x) => ({
            value: x.SampleOptionId,
            text: x.SampleOption,
          })),
        },
        { name: "Topup", label: "Topup", xsCol: 2, type: "number" },
        {
          name: "FilterChanged",
          label: "Filter Changed",
          xsCol: 2,
          type: "switch",
        },

        {
          name: "ServiceTypeId",
          label: "Service",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (dropdownData.ServiceTypes || []).map((x) => ({
            value: x.ServiceTypeId,
            text: x.ServiceType,
          })),
        },
        { name: "JobNumber", label: "Job Number", xsCol: 2 },
        {
          xsCol: 4,
          group: [
            {
              name: "MeterReading",
              label: "Meter Reading",
              xsCol: 6,
              type: "number",
            },
            {
              name: "MeterReadingAssumed",
              label: "Assumed",
              type: "switch",
              xsCol: 6,
            },
          ],
        },

        {
          xsCol: 4,
          group: [
            {
              name: "ComponentReading",
              label: "Component Reading",
              xsCol: 6,
              type: "number",
            },
            {
              name: "ComponentReadingAssumed",
              label: "Assumed",
              type: "switch",
              xsCol: 6,
            },
          ],
        },
        {
          xsCol: 4,
          group: [
            {
              name: "TestSetId",
              label: "Test Set",
              xsCol: 6,
              type: "dropdown",
              selectOptions: (dropdownData.TestSetsForUnitFluidType || []).map(
                (x) => ({
                  value: x.TestSetId,
                  text: x.TestSet,
                })
              ),
            },
            {
              name: "TestSetMakeDefault",
              label: "Make Default",
              type: "switch",
              xsCol: 6,
            },
          ],
        },

        { name: "UnitHours", label: "Unit Hours", readOnly: true, xsCol: 2 },
        {
          name: "ComponentHours",
          label: "Component Hours",
          xsCol: 2,
          readOnly: true,
        },
        /////////////////////////////////////////////////////////////////////////////
        {
          xsCol: 4,
          group: [
            {
              name: "Delivered",
              label: "Delivered",
              xsCol: 6,
              type: "date",
              disabled: !data.UseDelivered,
            },

            {
              name: "UseDelivered",
              label: "Use Delivered",
              type: "switch",
              xsCol: 6,
            },
          ],
        },

        {
          name: "Evaluated",
          label: "Evaluation",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "SampleTypeId",
          label: "Kit Type",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (dropdownData.SampleTypes || []).map((x) => ({
            value: x.SampleTypeId,
            text: x.SampleType,
          })),
        },
        {
          xsCol: 6,
          group: [
            {
              name: "FluidType",
              label: "Fluid Type",
              xsCol: 4,
              readOnly: true,
            },
            {
              xsCol: 8,
              name: "FluidId",
              component: function ({ id, value, setValue }) {
                return (
                  <FluidsDropDown
                    id={id}
                    fluids={dropdownData.Fluids || []}
                    defaultValue={value}
                    onChange={(e, val) => setValue(val)}
                  />
                );
              },
              type: "dropdown",
              selectOptions: (dropdownData.Fluids || []).map((x) => ({
                value: x.FluidId,
                text: x.FluidFormatted,
              })),
            },
          ],
        },
        {
          xsCol: 4,
          alignSelf: "flex-end",
          group: [
            {
              name: "FluidMakeDefault",
              label: "Make Default",
              type: "switch",
              xsCol: 6,
            },
            {
              name: "FluidApplyToAll",
              label: "Apply To All Samples",
              type: "switch",
              xsCol: 6,
            },
          ],
        },
        {
          xsCol: 2,
          label: "Internal (no website)",
          name: "IsInternalOnly",
          type: "switch",
        },
      ],
    },
    {
      xsCol: 12,
      fields: [
        {
          name: "CustomerComments",
          label: "Registration Comments",
          xsCol: 6,
          type: "textarea",
          rows: 4,
        },
        {
          name: "SampleNotes",
          label: "Sample Comments",
          xsCol: 6,
          type: "textarea",
          rows: 4,
        },
        {
          name: "Evaluation",
          label: "Evaluation",
          label2: `Technician \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
          label2Color: "black",
          xsCol: 6,
          type: "textarea",
          readOnly: true,
          rows: 4,
        },
        {
          name: "Recommendation",
          label: "Recommendation",
          xsCol: 6,
          type: "textarea",
          readOnly: true,
          rows: 4,
        },
      ],
    },
  ];
}

function FluidsDropDown({ id, fluids, onChange, defaultValue }) {
  const [selectedFluid] = useState(defaultValue);

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" {...props} key={option.FluidId}>
        {option.FluidFormatted}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options.filter((x, i) => i < 100)
      : options
        .filter((x) =>
          x.FluidFormatted.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
        .filter((x, i) => i < 100);
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.FluidFormatted || "";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label="Fluid"
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, fluid) => onChange(e, (fluid || {}).FluidId || null)}
      options={fluids || []}
      defaultValue={fluids.find((x) => x.FluidId === selectedFluid) || null}
    />
  );
}
