import { useMemo } from "react";
import DataTable from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";

export default function TownTable({ data, rowSelected, rowActionHandler }) {
  const columns = useMemo(
    () => GetColumns({ rowActionHandler }),
    [rowActionHandler]
  );
  const options = {
    dataSource: data,
    rowIdProperty: "SiteId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options} columns={columns} />;
}

const GetColumns = ({ rowActionHandler }) => [
  {
    headerName: "Product",
    field: "Product",
    width: 140,
  },

  {
    headerName: "Customer",
    field: "Customer",
    width: 200,
  },
  {
    headerName: "Customer Physical",
    field: "CustomerPhysicalCity",
    width: 140,
  },
  {
    headerName: "Customer Postal",
    field: "PostalCity",
    width: 140,
  },

  {
    headerName: "Site",
    field: "Site",
    width: 140,
  },
  {
    headerName: "Site Physical",
    field: "SitePhysicalCity",
    width: 140,
  },
  {
    headerName: "Site Postal",
    field: "SitePostalCity",
    width: 140,
  },

  {
    headerName: "Archived",
    field: "Archived",
    width: 140,
  },
  {
    headerName: "Actions",
    field: "Actions",
    sortable: false,
    flex: 1,
    renderCell: function ({ row }) {
      return (
        <DropdownMenu
          options={Options}
          onItemSelect={(option) => rowActionHandler(row, option)}
        />
      );
    },
  },
];

const Options = [
  { label: "Search Unit", name: "search-unit" },
  { label: "Search Component", name: "search-component" },
  { label: "Search Sample", name: "search-sample" },

  { label: "Unit No Recent Samples", name: "unit-recent-report" },
  { label: "Components No Recent Samples", name: "comp-recent-report" },
];
