import DataTable from "../../../components/DataTable";
import renderCellExpand from "./../../../components/DatatableCellExpand";

export default function ComponentModelsTable({
  data,
  rowSelected,
  selectedRow,
}) {
  const options = {
    columns: Columns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.ComponentMakeId + x.ComponentModel,
    })),
    rowIdProperty: "keyProp",

    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    sx: {
      height: 750,
    },
  };
  return <DataTable {...options} />;
}

const Columns = [
  {
    headerName: "Make",
    field: "ComponentMake",
    width: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Model",
    field: "ComponentModel",
    width: 150,
    renderCell: renderCellExpand,
  },
];
