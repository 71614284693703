import { Box, ClickAwayListener, Typography, } from "@mui/material";
import TreeItem from "../../../components/tree/TreeItem";
import getNodeId from "./nodeId";
import productColors from "../../../common/ProductColors";
import { useEffect, useState } from "react";
import TreeMenu from "../TreeMenu";

export default function CustomerSl(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPop, setOpenPop] = useState(false);

  const mouseDownHandler = (event) => {
    if (event.button === 2) {
      setAnchorEl(event.currentTarget);
      setOpenPop(true);
    }
  };

  const handleSelect = (name) => {
    props.handleRightSelect(name, props)
    setOpenPop(false)
    setAnchorEl(null)
  };

  const [productColor, setProductColor] = useState({})

  const nodeId = getNodeId(props.pathItems);
  const expanded = props.expandedNode.includes(nodeId);

  useEffect(() => {
    let color2 = productColors(props?.ProductId);
    setProductColor(color2);
  }, []);

  return (
    <>
      <TreeItem
        nodeId={nodeId}
        customProps={{ hasChildren: !!props.CustomerCount, expanded }}
        onMouseDown={(e) => mouseDownHandler(e)}
        label={
          <Box sx={props?.selectedNode === nodeId ? { ...productColor } : ""}
            onClick={(e) => props.handleLabelClick && props.handleLabelClick(e, nodeId)}
          >
            <Typography            >
              {props.StartLetterName + " [" + props.CustomerCount + "]" + (props.IsArchived ? " Archived" : "")}
            </Typography>
          </Box>
        }
      >
        {props.children}
      </TreeItem>
      {openPop && <ClickAwayListener onClickAway={() => setOpenPop(false)}>
        <TreeMenu options={Options} anchorEl={anchorEl} openPop={openPop} setOpenPop={setOpenPop} handleClick={handleSelect} />
      </ClickAwayListener>}
    </>
  );
}

const Options = [
  {
    label: "Tree",
    name: "null",
    items: [
      // { label: "Lift to Top", name: "lift-to-top" },
      // { label: "Collapse Tree", name: "collapse-tree" },
      { label: "Refresh", name: "refresh-tree" },
    ],
  },
  {
    label: "Additional Reports",
    name: "null",
    items: [
      { label: "Reports by Date", name: "reports-by-date" },
      { label: "Components No Recent Samples", name: "components-no-recent-samples" },
      { label: "Units No Recent Samples", name: "units-no-recent-samples" },
      { label: "Reference XSD", name: "reference-xsd" },
    ],
  },
  {
    label: "New",
    name: "null",
    items: [
      { label: "Customer", name: "add-new-customer" },
    ],
  },
  {
    label: "Contacts",
    name: "null",
    items: [
      { label: "Access Permission", name: "access-permission-overview" },
      { label: "Sample Reporting", name: "report-contacts" },
      { label: "Subscription", name: "subscription-overview" },
      { label: "Exports", name: "export-contacts-overview" },
    ],
  },
];
