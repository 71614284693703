import Constants from "../../common/Constants";
import GetStepNames from "../../common/GetStepNames";
import DataTable from "../../components/DataTable";
import { toLocalDate } from "../../utils";

export default function SampleEventsTable({ data, rowSelected, sx }) {
  const options = {
    columns: Columns,
    dataSource: data,
    rowIdProperty: "EventLogId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options} sx={sx} />;
}

const Columns = [
  {
    headerName: "Scope",
    field: "Scope",
    width: 100,
  },
  {
    headerName: "Type",
    field: "EventType",
    width: 200,
  },
  {
    headerName: "Occured",
    field: "EventDate",
    valueFormatter: ({ value }) => toLocalDate(value),
    width: 150,
  },
  {
    headerName: "Hours",
    field: "UnitHours",
    width: 120,
  },

  {
    headerName: "Reading",
    field: "MeterReading",
    width: 120,
  },
  {
    headerName: "Event",
    field: "Event",
    width: 180,
  },
];
