import { useTheme } from "@emotion/react";
import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import Form, { FormActions } from "./../../../../components/Form";
import DataTable from "./../../../../components/DataTable";
import useFetch from "./../../../../hooks/useFetch";
import { CONTACTS_APIS } from "./../../../../common/apis";
import { toLocalDateTime } from "../../../../utils";

export default function ContactExports({ contactId, searchContactRowData, treeData }) {

  const curTheme = useTheme();
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const [contactsExportData, setContactsExportData] = useState([]);

  const filteredExportData = useMemo(() => {
    if (data?.Filter) {
      return (contactsExportData || []).filter((x) => {
        // Ensure x.SampleNumber is a string before using includes()
        return String(x.SampleNumber)?.includes(data?.Filter);
      });
    } else {
      return contactsExportData;
    }
  }, [data, contactsExportData]);

  const options = {
    columns: GetColumns(),
    dataSource: (filteredExportData || [])?.map((x, i) => ({
      ...x,
      keyProp: i,
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "contactExports",
    sx: { height: 500 },
  };
  //call api

  const getContactsForExport = async (dataProps) => {
    const contactExportResponse = await post(
      `${CONTACTS_APIS.CONTACT_FOR_EXPORT}`, {
      ContactId: dataProps?.ContactId || null,
      ProductId: dataProps?.ProductId || null,
      SiteSetId: dataProps?.SiteSetId || null,
      ParentId: dataProps?.ParentId || null,
      CustomerId: dataProps?.CustomerId || null,
      SiteId: dataProps?.SiteId || null,
      UnitId: dataProps?.UnitId || null,
      ComponentId: dataProps?.ComponentId || null,
      SampleNumber: dataProps?.SampleNumber || null,
      ReportNumber: dataProps?.ReportNumber || null,
      LimitResults: data?.limitResults == false ? false : true,
    },
    );
    setContactsExportData(contactExportResponse)
  }
  useEffect(
    function () {
      (async function () {
        if (treeData !== undefined) {
          return getContactsForExport(treeData);
        }
        let parseData = {};
        let formDatas = localStorage.getItem("export-contacts-overview")

        if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
          parseData = JSON.parse(formDatas)
          localStorage.removeItem("export-contacts-overview");
          return getContactsForExport(parseData);
        }
        else {
          const contactExportResponse = await get(`${CONTACTS_APIS.CONTACT_EXPORTS}?contactId=${contactId ? contactId : 0}&limitResults=true`, { skipBlocker: true });
          const contactsExportDataWithProperties = contactExportResponse?.map(
            (item) => ({
              ...item,
              Username: searchContactRowData?.Username,
              DisplayName: searchContactRowData?.DisplayName,
            })
          );
          setContactsExportData(contactsExportDataWithProperties);
        }
      })();
    },
    [data?.limitResults]
  );

  return (
    <>
      <Grid container sx={{ minWidth: "90vw" }}>
        <Grid item xs={12}>
          <Form sections={GetFormSections()} data={data} dispatch={dispatch} />
        </Grid>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredExportData?.length}</b>
          </Typography>
          <DataTable {...options} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns() {
  return [
    {
      headerName: "Username",
      field: "Username",
      minWidth: 200,
    },
    {
      headerName: "Name",
      field: "DisplayName",
      minWidth: 150,
    },
    {
      headerName: "Added",
      field: "Added",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      minWidth: 120,
    },
    {
      headerName: "Batch",
      field: "BatchAdded",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      minWidth: 150,
    },
    {
      headerName: "Sample",
      field: "IsSampleReport",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
    },
    {
      headerName: "SMS",
      field: "IsSMS",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      minWidth: 100,
    },
    {
      headerName: "SampleNumber",
      field: "SampleNumber",
      minWidth: 120,
    },
    {
      headerName: "Component",
      field: "Component",
      minWidth: 170,
    },
    {
      headerName: "Unit",
      field: "Unit",
      minWidth: 170,
    },
    {
      headerName: "Site",
      field: "Site",
      minWidth: 150,
    },

    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 160,
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 120,
    },
  ];
}

function GetFormSections() {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "Filter", label: "Filter", type: "number", xsCol: 3 },
        {
          name: "limitResults",
          label: "Show Only Last 3 Months",
          xsCol: 4,
          type: "switch",
        },
      ],
    },
  ];
}
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }

  return state;
}
const SearchFilterDefaults = {
  limitResults: true,
};
