import renderCellExpand from "../../../components/DatatableCellExpand";

export const AutoEvaluationOverviewColumns = [
  {
    headerName: "Laboratory",
    field: "Laboratory",
    flex: 1,
  },
  {
    headerName: "Overall",
    field: "AutoEvalTotal",
    flex: 1,
  },
  {
    headerName: "Latest",
    field: "AutoEvalLatest",
    flex: 1,
  },
  {
    headerName: "Latest %",
    field: "LatestPercent",
    flex: 1,
  },
  {
    headerName: "Reprocessed",
    field: "AutoEvalReprocessed",
    flex: 1,
  },
  {
    headerName: "Reprocessed %",
    field: "ReprocessedPercent",
    flex: 1,
  },
  {
    headerName: "Incomplete",
    field: "AutoEvalIncomplete",
    flex: 1,
  },
  {
    headerName: "Incomplete %",
    field: "IncompletePercent",
    flex: 1,
  },
];

export const AutoEvaluationDetailsColumns = [
  {
    headerName: "Laboratory",
    field: "Laboratory",
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "System",
    field: "System",
    flex: 1,
  },
  {
    headerName: "Total",
    field: "Total",
    flex: 1,
  },
  {
    headerName: "Alerts",
    field: "Alerts",
    flex: 1,
  },
  {
    headerName: "Alerts %",
    field: "AlertsPercent",
    flex: 1,
  },
  {
    headerName: "Agree",
    field: "Agree",
    flex: 1,
  },
  {
    headerName: "Agree %",
    field: "AgreePercent",

    flex: 1,
  },
  {
    headerName: "Disagree",
    field: "Disagree",
    flex: 1,
  },
  {
    headerName: "Disagree %",
    field: "DisagreePercent",
    flex: 1,
  },
  {
    headerName: "Normal",
    field: "AlertsNormal",
    flex: 1,
  },
  {
    headerName: "Normal %",
    field: "AlertsNormalPercent",
    width: 250,
    flex: 1,
  },
  {
    headerName: "Agree1",
    field: "AgreeNormal",
    flex: 1,
  },

  {
    headerName: "Agree1 %",
    field: "AgreeNormalPercent",
    width: 250,
    flex: 1,
  },
  {
    headerName: "Disagree1",
    field: "DisagreeNormal",
    flex: 1,
  },
  {
    headerName: "Disagree1 %",
    field: "DisagreeNormalPercent",
    flex: 1,
  },
  {
    headerName: "Not Normal",
    field: "AlertsNotNormal",
    flex: 1,
  },
  {
    headerName: "Not Normal %",
    field: "AlertsNotNormalPercent",
    width: 250,
    flex: 1,
  },
  {
    headerName: "Agree 2-5",
    field: "AgreeNotNormal",
    flex: 1,
  },

  {
    headerName: "Agree 2-5%",
    field: "AgreeNotNormalPercent",
    flex: 1,
  },
  {
    headerName: "Disagree 2-5",
    field: "DisagreeNotNormal",
    flex: 1,
  },
  {
    headerName: "Disagree 2-5%",
    field: "DisagreeNotNormalPercent",
    width: 250,
    flex: 1,
  },
  {
    headerName: "No Alerts",
    field: "Errors",
    flex: 1,
  },
  {
    headerName: "No Alerts %",
    field: "ErrorsPercent",
    flex: 1,
  },
  {
    headerName: "Unprocessed",
    field: "Unprocessed",
    flex: 1,
  },
  {
    headerName: "Unprocessed %",
    field: "UnprocessedPercent",
    flex: 1,
  },
  {
    headerName: "Pushed",
    field: "CmsPushed",
    flex: 1,
  },
  {
    headerName: "Pushed %",
    field: "CmsPushedPercent",
    flex: 1,
  },
];
