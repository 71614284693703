import * as yup from "yup";
import { GetErrorObject } from "../Utils";

export const ContactSchema = yup.object().shape({
  Username: yup
    .string()
    .transform((value) => value || "")
    .required("Email is required.")
    .email("Invalid Email format."),
  Forename: yup
    .string()
    .transform((value) => value || "")
    .required("Fore Name is required."),
});

export function ValidateContact(input) {
  return new Promise(async function (resolve, reject) {
    try {
      ContactSchema.validate(input, { abortEarly: false })
        .then(function () {
          const data = {
            Username: {
              ...input,
            },
            Forename: {
              ...input,
            },
          };
          resolve({ data });
        })
        .catch(function (err) {
          const errors = GetErrorObject(err);
          resolve({ errors });
        });
    } catch (e) {
      reject(e);
    }
  });
}
