import DataTable from "../../../components/DataTable";
import renderCellExpand from "./../../../components/DatatableCellExpand";

export default function FluidsTable({ data, rowSelected, selectedRow }) {
  const options = {
    columns: Columns,
    dataSource: data,
    rowIdProperty: "FluidId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    sx: {
      height: 750,
    },
  };
  return <DataTable {...options} />;
}

const Columns = [
  {
    headerName: "Type",
    field: "FluidType",
    width: 150,
  },
  {
    headerName: "Fluid",
    field: "FluidFormatted",
    width: 300,
    renderCell: renderCellExpand,
  },
];
