// import { Visibility } from "@mui/icons-material";
// import DataTable, { DataGridAction } from "../../../components/DataTable";
// import renderCellExpand from "../../../components/DatatableCellExpand";

import { Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import { DASHBOARD_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import { useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import NewDataTable, {
  NewDataGridAction,
} from "../../../components/NewDataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function Cleanup() {
  const { get, getFile } = useFetch();
  const [cleanupData, setCleanUpData] = useState([]);
  const { showWarningToast } = useToast();

  useEffect(function () {
    document.title = "Cleanup Dashboard";
    getCleanUpData();
  }, []);

  const viewClick = async () => {
    getCleanUpData();
  };
  async function getCleanUpData() {
    const cleanUpResponse = await get(DASHBOARD_APIS.CLEANUP);
    if (cleanUpResponse.length > 0) {
      setCleanUpData(cleanUpResponse);
    } else {
      setCleanUpData();
      showWarningToast(appResources.NO_CLEANUP_DASHBOARD_DATA_FOUND_MSG);
    }
  }

  const exportClick = (row) => {
    let fileName = "";
    switch (row?.CleanupId) {
      case 1:
        fileName = "Unmatched Komatsu Units";
        break;
      case 2:
        fileName = "Customer-Site Reorganisation";
        break;
      case 3:
        fileName = "Mismatched Fluidtypes";
        break;
      case 4:
        fileName = "Fluid Grade Formatting";
        break;
      case 5:
        fileName = "Large Unit Hours";
        break;
      case 6:
        fileName = "Dummy Samples";
        break;
      case 7:
        fileName = "Missing Component Types";
        break;
      case 8:
        fileName = "Unmatched Komatsu Models";
        break;
      case 9:
        fileName = "Unmatched Non-Komatsu Models";
        break;
      case 10:
        fileName = "Missing Component Types";
        break;
      default:
        return;
    }
    getFile(
      `${DASHBOARD_APIS.CLEANUP_EXPORT}?cleanupId=${row?.CleanupId}`,
      fileName
    );
  };

  const columns1 = useMemo(
    () => [
      {
        id: "sendEmail",
        header: "Send Email",
        columnDefType: "display", //turns off data column features like sorting, filtering, etc.
        enableColumnOrdering: true, //but you can turn back any of those features on if you want like this
        Cell: ({ row }) => (
          <NewDataGridAction
            label={"Export"}
            // onClick={() => exportClick(row?.original?.CleanupId)}
            onClick={() => exportClick(row?.original)}
          />
        ),
        size: 50,
        maxSize: 50,
      },
      {
        accessorKey: "Cleanup",
        header: "Cleanup Task",
        size: 150,
        maxSize: 160,
      },
      {
        accessorKey: "Samples",
        header: "Total",
        size: 50,
        maxSize: 60,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx:
              row?.original?.Samples > 0
                ? { backgroundColor: "red", color: "white", py: 0.25 }
                : {},
          };
        },
      },
      {
        accessorKey: "KOWA",
        header: "KOWA",
        size: 50,
        maxSize: 60,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx:
              row?.original?.KOWA > 0
                ? { backgroundColor: "red", color: "white", py: 0.25 }
                : {},
          };
        },
      },
      {
        accessorKey: "KowaArchive",
        header: "KOWA Archive",
        size: 60,
        maxSize: 60,
      },
      {
        accessorKey: "Labcheck",
        header: "Labcheck",
        size: 50,
        maxSize: 60,
        muiTableBodyCellProps: ({ row }) => {
          return {
            sx:
              row?.original?.Labcheck > 0
                ? { backgroundColor: "red", color: "white", py: 0.25 }
                : { py: 0.25 },
          };
        },
      },
      {
        accessorKey: "LabcheckArchive",
        header: "Labcheck Archive",
        size: 60,
        maxSize: 60,
      },
      {
        accessorKey: "Instructions",
        header: "Instructions",
        size: 500,
        maxSize: 600,
      },
    ],
    []
  );

  const tblOptions1 = {
    columns: columns1,
    dataSource: cleanupData || [],
    enablePagination: false,
    //   rowIdProperty: "CleanupId",
    //   isDisableColumnFilter: true,
    //   cellStylingHandler: "cleanUp",
    sx: { height: 680 },
    // enableColumnResizing: true,
    enableColumnActions: false,
  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Grid container>
          <Grid item xs={6}>
            {cleanupData?.length > 1 ? (
              <Typography sx={{ fontSize: 11 }} mt={1} color="red">
                {/* Total Records: <b> {cleanupData && cleanupData.length}</b> */}
                There are {cleanupData?.length} cleanup tasks, of which ALL
                require attention
              </Typography>
            ) : cleanupData?.length === 1 ? (
              <Typography
                sx={{ fontWeight: 700, fontSize: 11 }}
                mt={1}
                color="red"
              >
                There is {cleanupData.length} cleanup task, which requires
                attention
              </Typography>
            ) : (
              <Typography
                sx={{ fontWeight: 700, fontSize: 11 }}
                mt={1}
                color="red"
              >
                There are no cleanup tasks that require attention
              </Typography>
            )}
          </Grid>

          <Grid item xs={6} justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={viewClick}
              sx={{ float: "right" }}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>

        {/* <Grid item xs={12} mt={1}>
          <DataTable {...tblOptions} />
        </Grid> */}
        <Grid item xs={12} mt={1}>
          <NewDataTable {...tblOptions1} />
        </Grid>
      </Card>
    </>
  );
}

// function GetColumns(exportClick) {
//   return [
//     {
//       headerName: "Export",
//       field: "Export",
//       sortable: false,
//       width: 65,
//       renderCell: function ({ row }) {
//         return (
//           <>
//             <DataGridAction
//               minWidth={"55px"}
//               row={row}
//               onClick={exportClick}
//               label="Export"
//             />
//           </>
//         );
//       },
//     },
//     {
//       headerName: "Cleanup Task",
//       field: "Cleanup",
//       width: 250,
//     },
//     {
//       headerName: "Total",
//       field: "Samples",
//       width: 75,
//     },
//     {
//       headerName: "KOWA",
//       field: "KOWA",
//       width: 75,
//     },
//     {
//       headerName: "KOWA Archive",
//       field: "KowaArchive",
//       width: 99,
//     },
//     {
//       headerName: "Labcheck",
//       field: "Labcheck",
//       width: 75,
//     },
//     {
//       headerName: "Labcheck Archive",
//       field: "LabcheckArchive",
//       width: 119,
//     },
//     {
//       headerName: "Instructions",
//       field: "Instructions",
//       flex: 1,
//       width: 400,
//       renderCell: renderCellExpand,
//     },
//   ];
// }

// const tblOptions = {
//   columns: GetColumns(exportClick),
//   dataSource: cleanupData || [],
//   rowIdProperty: "CleanupId",
//   isDisableColumnFilter: true,
//   cellStylingHandler: "cleanUp",
//   sx: { height: 400 },
// };
