import { useState } from "react";
import { DataGridAction } from "../../../components/DataTable";
import DataTable from "../../../components/DataTable";
import AppDialog from "../../../components/AppDialog";
import EditContacts from "../../../forms/EditContacts";
import ContactPrimary from "../../reports/critical-logs/contact-permission-overview/ContactPrimary";
import ContactAccessPermission from "../../reports/critical-logs/contact-permission-overview/ContactAccessPermission";

export default function PrimaryContactOverviewFormTable({
  data,
  rowSelected,
  setOpenDialog,
  ...props
}) {
  const [row, setRow] = useState({});
  const [propId, setPropId] = useState();
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
  });
  const options = {
    ...props,
    columns: GetColumns(
      editClick,
      accessClick,
      reportsClick,
      subscriptionsClick,
      primaryClick,
      deleteClick
    ),
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp:
        x.ContactId +
        "" +
        (x.Unit || "") +
        "" +
        (x.Site || "") +
        (x.Scope || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "contactReportsPermission",
    sx: { height: 600 },
    // rowsSelected: function (r) {
    //   r.length && rowSelected && rowSelected(r[0]);
    // },
  };

  return (
    <>
      <DataTable {...options} />
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth="xl"
      >
        {dialog.title === "Edit Contact" && (
          <EditContacts
            contactId={row.ContactId}
            setEditContactsDialog={setDialog}
          />
        )}
        {dialog.title === `Contact Primary Mappings for ${row.Username}` && (
          <ContactPrimary contactId={row.ContactId} />
        )}
        {(dialog.title === `Contacts Access Permissions for ${row.Username}` ||
          dialog.title ===
            `Contact Sample Reporting for ${row.DisplayName} ${row.Username}` ||
          dialog.title === `Contact Subscriptions for ${row.Username}`) && (
          <ContactAccessPermission
            contactId={row.ContactId}
            propId={propId}
            username={row && row.DisplayName}
          />
        )}

        {/* {dialog.title ===
          `Contact Sample Reporting for ${row.DisplayName} ${row.Username}` && (
          <ContactSampleReportingMapping />
        )}

        {dialog.title === `Contact Subscriptions for ${row.Username}` && (
          <ContactSubscriptionMapping />
        )} */}
      </AppDialog>
    </>
  );
  // function selectClick(row) {
  //   rowSelected(row);
  //   setOpenDialog(false);
  // }
  function editClick(row) {
    setRow(row);
    setDialog({
      open: true,
      title: "Edit Contact",
    });
  }

  function accessClick(row) {
    setRow(row);
    setPropId(2);
    setDialog({
      open: true,
      title: `Contacts Access Permissions for ${row.Username}`,
    });
  }
  function reportsClick(row) {
    setRow(row);
    setPropId(3);
    setDialog({
      open: true,
      title: `Contact Sample Reporting for ${row.DisplayName} ${row.Username}`,
    });
  }
  function subscriptionsClick(row) {
    setRow(row);
    setPropId(4);
    setDialog({
      open: true,
      title: `Contact Subscriptions for ${row.Username}`,
    });
  }
  function primaryClick(row) {
    setRow(row);
    setPropId(5);
    setDialog({
      open: true,
      title: `Contact Primary Mappings for ${row.Username}`,
    });
  }
  function deleteClick(row) {
    // setRow(row);
    // rowSelected(row);
    // setDialog({
    //   open: true,
    //   title: `Contact Primary Mappings for ${row.Username}`,
    // });
  }
}
const GetColumns = (
  editClick,
  accessClick,
  reportsClick,
  subscriptionsClick,
  primaryClick,
  deleteClick
) => [
  // {
  //   headerName: "Select",
  //   field: "Select",
  //   sortable: false,
  //   flex: 1,

  //   width: 80,
  //   renderCell: function ({ row }) {
  //     return (
  //       <>
  //         <DataGridAction row={row} onClick={selectClick} label="Select" />
  //       </>
  //     );
  //   },
  // },
  {
    headerName: "Edit",
    field: "Edit",
    sortable: false,
    flex: 1,
    width: 60,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"50px"}
            row={row}
            onClick={editClick}
            label="Edit"
          />
        </>
      );
    },
  },
  {
    headerName: "Access",
    field: "Access",
    sortable: false,
    flex: 1,
    width: 65,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"55px"}
            row={row}
            onClick={accessClick}
            label="Access"
          />
        </>
      );
    },
  },
  {
    headerName: "Reports",
    field: "Reports",
    sortable: false,
    flex: 1,
    width: 65,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"55px"}
            row={row}
            onClick={reportsClick}
            label="Reports"
          />
        </>
      );
    },
  },
  {
    headerName: "Subscriptions",
    field: "Subscriptions",
    sortable: false,
    flex: 1,
    width: 96,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            row={row}
            minWidth={"80px"}
            onClick={subscriptionsClick}
            label="Subscriptions"
          />
        </>
      );
    },
  },
  {
    headerName: "Primary",
    field: "Primary",
    sortable: false,
    flex: 1,
    width: 65,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"55px"}
            row={row}
            onClick={primaryClick}
            label="Primary"
          />
        </>
      );
    },
  },
  {
    headerName: "Email",
    field: "Username",
    width: 154,
  },
  {
    headerName: "Name",
    field: "DisplayName",
    width: 98,
  },

  {
    headerName: "Reports From",
    field: "ReportRankFrom",
    width: 105,
  },

  {
    headerName: "Site",
    field: "Site",
    width: 74,
  },

  {
    headerName: "Source",
    field: "Source",
    width: 70,
  },

  {
    headerName: "Mobile",
    field: "Mobile",
    width: 104,
  },
  {
    headerName: "Telephone",
    field: "Telephone",
    width: 97,
  },
  {
    headerName: "Role",
    field: "ContactRole",
    width: 60,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 69,
  },
  {
    headerName: "Company",
    field: "Company",
    width: 90,
    flex: 1,
  },
  {
    headerName: "Delete",
    field: "Delete",
    sortable: false,
    flex: 1,
    width: 65,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"55px"}
            row={row}
            onClick={deleteClick}
            label="Delete"
          />
        </>
      );
    },
  },
];
