import { Typography } from "@mui/material";
import GetStepNames from "../../../common/GetStepNames";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function SamplesTable({
  data = [],
  rowsSelected,
  selectedRow,
  sx,
  rowActionHandler,
  actions
}) {
  const options = {
    columns: GetColumns(actions, { rowActionHandler }),
    opacityCheck: true,
    dataSource: data,
    rowIdProperty: "SampleNumber",
    rowStylingHandler: "sample",
    cellStylingHandler: "sampleBackGroundWhite",
    isDisableColumnFilter: true,
    rowsSelected: rowsSelected,
    selectionModel: selectedRow,
  };
  return <DataTable {...options} sx={sx} />;
}

const Options = [
  { label: "Copy Sample Number", name: "copy-sample-number" },
  { label: "Tray Label and Training", name: "update-tray-label" },
];

const Delete = [{ label: "Delete Sample Number", name: "delete-sample-number" },]
const Report = [{ label: "View Sample Report", name: "view-sample-report" },]
const Edit = [{ label: "Edit Sample", name: "edit-sample" },]

const GetColumns = (actions, { rowActionHandler }) => [
  {
    headerName: "Sample Number",
    field: "SampleNumber",
    minWidth: 128,
    maxWidth: 128,
    renderCell: function ({ row }) {
      return (
        <>
          {(actions || []).map(function (ac, i) {
            return (
              <DataGridAction
                key={i}
                row={row}
                onClick={ac.action}
                label={ac.label}
                startIcon={ac.startIcon}
              />
            );
          })}
          <DropdownMenu
            options={(row?.Step < 2)
              ? [...Options, ...Delete]
              : (row?.Step > 1 && row?.AlertStatus === null) ? [...Options, ...Edit]
                : (row?.Step > 1 && row?.AlertStatus !== null) ? [...Options, ...Report, ...Edit]
                  : Options}
            onItemSelect={(option) => rowActionHandler(row, option)}
          />
          <Typography paddingTop={0.25}>{row.SampleNumber}</Typography>
        </>
      );
    },
  },
  {
    headerName: "Step",
    field: "Step",
    minWidth: 60,
    maxWidth: 60,
    flex: 1,
    valueFormatter: ({ value }) => GetStepNames(value).short,
    sortable: false,
  },
  {
    headerName: "Product",
    field: "Product",
    width: 100,
    sortable: false,
  },
  {
    headerName: "Test Set",
    field: "TestSet",
    minWidth: 140,
    maxWidth: 140,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Customer",
    field: "Customer",
    minWidth: 100,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Site",
    field: "Site",
    minWidth: 80,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Unit",
    field: "Unit",
    minWidth: 80,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Model",
    field: "UnitModelFormatted",
    minWidth: 90,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Serial",
    field: "Serial",
    minWidth: 130,
    maxWidth: 130,
    sortable: false,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Component",
    field: "Component",
    minWidth: 110,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Label",
    field: "Label",
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  },
];
