import { Dashboard } from "@mui/icons-material";
import {
  ApprovalForReleaseIcon,
  AuditLogIcon,
  AutoEvaluationIcon,
  CleanUpIcon,
  ComponentModelIcon,
  ContactIcon,
  CreateTrayIcon,
  CriticalLogsIcon,
  CustomerParentIcon,
  DashboardIcon,
  EmailSelectedItemsIcon,
  EvaluationIcon,
  FluidIcon,
  FluidWatchIcon,
  GroupEmailIcon,
  HelpIcon,
  IKowaFeedbackIcon,
  LaboratoryIcon,
  MachineNotificationIcon,
  MasterDataIcon,
  MessagesIcon,
  MissedSamplesIcon,
  MyCustomersSitesIcon,
  PresetIcon,
  ProductIcon,
  ProvidedLimitIcon,
  QualityCheckIcon,
  QuickDetailsIcon,
  QuickMergeIcon,
  QuickMoveSiteIcon,
  RegistrationIcon,
  ReportsIcon,
  RerunSampleIcon,
  ReviewIcon,
  SiteIcon,
  SpecialReportIcon,
  SpecialReportTypesIcon,
  TestItemIcon,
  TestSetIcon,
  TraysIcon,
  UnitModelIcon,
  UserManualIcon,
  ViewKomatsuMachineIcon,
  SettingsMenuIcon,
  AdministrationIcon,
  WebsiteNewsIcon,
  SpecialReportBillingRatesIcon,
  ManagementReportIcon,
  GlobalSettingsIcon,
} from "../icons/ButtonIcons";

const menu = [
  {
    name: "administration",
    title: "Administration",
    icon: <AdministrationIcon />,
    items: [
      { title: "User Overview", path: "management/usersOverview", icon: <RegistrationIcon />, },
      { title: "Audit Log", path: "reports/audit-Log", icon: <AuditLogIcon />, },
      { title: "Group Email Domains", path: "management/group-email-domains", icon: <GroupEmailIcon />, },
      { title: "Auto-Eval Comments", path: "management/auto-evaluation-comments", icon: <AutoEvaluationIcon />, },
      { title: "Laboratories", path: "management/laboratories", icon: <LaboratoryIcon />, },
      { title: "Products", path: "management/products", icon: <ProductIcon />, },
      { title: "Management Report", icon: <ManagementReportIcon />, modal: "management-report", },
      { title: "Special Report Billing Rates", icon: <SpecialReportBillingRatesIcon />, modal: "edit-billing-rates", },
      { title: "Global Settings", icon: <GlobalSettingsIcon />, modal: "global-settings", },
      { title: "Website News", path: "management/website-news", icon: <WebsiteNewsIcon />, },
    ],
  },
  {
    name: "settings",
    title: "Settings",
    icon: <SettingsMenuIcon />,
    items: [
      {
        title: "Messages",
        path: "management/messages",
        icon: <MessagesIcon />,
      },
      {
        title: "Preset Comments",
        path: "management/preset-comments",
        icon: <PresetIcon />,
      },
      {
        title: "Machine Notifications",
        path: "management/machine-notifications",
        icon: <MachineNotificationIcon />,
      },
      {
        title: "My Customers & Sites",
        path: "management/customerSites",
        icon: <MyCustomersSitesIcon />,
      },
      {
        title: "Fluid Type Notifications",
        // path: "settings/fluid-type-notifications",
        modal: "fluid-type-notifications",
        icon: <RegistrationIcon />,
      },
      {
        title: "Product Notifications",
        // path: "settings/product-notifications",
        modal: "product-notifications",
        icon: <RegistrationIcon />,
      },
      // {
      //   title: "Hold Management",
      //   path: "management/hold-management",
      //   icon: <HoldIcon />,
      // },
    ],
  },
  {
    name: "dashboards",
    title: "Dashboard",
    icon: <DashboardIcon />,
    items: [
      {
        title: "Tray",
        path: "dashboards/tray",
        icon: <Dashboard />,
      },
      {
        title: "Auto-Evaluation",
        path: "dashboards/auto-evaluation",
        icon: <AutoEvaluationIcon />,
      },
      {
        title: "Clean-up",
        path: "dashboards/Cleanup",
        icon: <CleanUpIcon />,
      },
      {
        title: "i-KOWA Feedback",
        path: "dashboards/ikowa-feedback",
        icon: <IKowaFeedbackIcon />,
      },
    ],
  },
  {
    name: "quickDetails",
    title: "Quick Details",
    icon: <QuickDetailsIcon />,
    items: [
      {
        title: "Quick Details",
        path: "details/quick",
        icon: <QuickDetailsIcon />,
      },
      // { title: "Quick Details 2", path: "quick-details/QuickDetails2" },
      // {
      //   title: "Unit Hour History",
      //   path: "quick-details/unitHourHistory",
      //   icon: <UnitHourHistoryIcon />,
      // },
      {
        title: "Search Registrations",
        path: "registrations/search",
        icon: <RegistrationIcon />,
      },
    ],
  },
  {
    name: "trays",
    title: "Tray",
    icon: <TraysIcon />,
    items: [
      { title: "Create Trays", path: "trays/create", icon: <CreateTrayIcon /> },
      {
        title: "Registration",
        path: "trays/registration",
        icon: <RegistrationIcon />,
      },
      {
        title: "Laboratory",
        path: "trays/laboratory",
        icon: <LaboratoryIcon />,
      },
      {
        title: "Quality Check",
        path: "trays/quality-check",
        icon: <QualityCheckIcon />,
      },
      {
        title: "Auto-Evaluation",
        path: "trays/auto-evaluation",
        icon: <AutoEvaluationIcon />,
      },
      {
        title: "Evaluation",
        path: "trays/evaluation",
        icon: <EvaluationIcon />,
      },
      {
        title: "Approval to Release",
        path: "trays/approval",
        icon: <ApprovalForReleaseIcon />,
      },
      { title: "Review", path: "trays/review", icon: <ReviewIcon /> },
    ],
  },
  {
    name: "reports",
    title: "Reports",
    icon: <ReportsIcon />,
    items: [
      {
        title: "Email Selected Items",
        path: "reports/email-selected-items",
        icon: <EmailSelectedItemsIcon />,
      },
      {
        title: "Critical Logs",
        path: "reports/critical-logs",
        icon: <CriticalLogsIcon />,
      },
      {
        title: "Rerun Samples",
        path: "reports/rerun-samples",
        icon: <RerunSampleIcon />,
      },
      {
        title: "Missed & Hold Samples",
        path: "reports/missed-and-hold-samples",
        icon: <MissedSamplesIcon />,
      },
      {
        title: "Special Report",
        path: "reports/special-report",
        icon: <SpecialReportIcon />,
      },
      {
        title: "Special Report Types",
        path: "reports/special-report-types",
        icon: <SpecialReportTypesIcon />,
      },
    ],
  },
  {
    name: "masterData",
    title: "Master Data",
    icon: <MasterDataIcon />,
    items: [
      // {
      //   title: "Provided Limits",
      //   path: "master-data/provided-limits",
      //   icon: <ProvidedLimitIcon />,
      // },
      {
        title: "Contacts",
        path: "management/search-contact",
        icon: <ContactIcon />,
      },
      { title: "Test Sets", path: "master-data/index", icon: <TestSetIcon /> },
      {
        title: "Test Items",
        path: "master-data/test-items",
        icon: <TestItemIcon />,
      },
      {
        title: "Fluids", path: "master-data/fluids", icon: <FluidIcon />,
      },
      {
        title: "Fluid Watch",
        path: "master-data/FluidWatch",
        icon: <FluidWatchIcon />,
      },
      {
        title: "Customer Parents",
        path: "customer/Parents",
        icon: <CustomerParentIcon />,
      },
      {
        title: "Unit Models",
        path: "master-data/UnitModels",
        icon: <UnitModelIcon />,
      },
      {
        title: "Component Models",
        path: "master-data/ComponentModels",
        icon: <ComponentModelIcon />,
      },

      {
        name: "View Komatsu",
        title: "View Komatsu",
        icon: <QuickMergeIcon />,
        items: [
          {
            title: "Machines",
            path: "master-data/view-komatsu-machines",
            icon: <ViewKomatsuMachineIcon />,
          },
          {
            title: "Models",
            path: "master-data/view-komatsu-models",
            icon: <ViewKomatsuMachineIcon />,
          },
        ],
      },

      {
        title: "Machine Templates",
        path: "master-data/machine-templates",
        icon: <ProvidedLimitIcon />,
      },
      { title: "Site Sets", path: "master-data/SiteSets", icon: <SiteIcon /> },
      {
        name: "Quick Merge",
        title: "Quick Merge",
        icon: <QuickMergeIcon />,
        items: [
          {
            title: "Fluid",
            path: "master-data/quickMergeFluid",
            icon: <FluidIcon />,
          },
          {
            title: "Model",
            path: "master-data/quickMergeModel",
            icon: <UnitModelIcon />,
          },
          {
            title: "Sites",
            path: "master-data/quickMergeSites",
            icon: <SiteIcon />,
          },
        ],
      },

      {
        title: "Quick Move Sites",
        path: "master-data/quickMoveSites",
        icon: <QuickMoveSiteIcon />,
      },
    ],
  },
  {
    name: "help",
    title: "Help",
    icon: <HelpIcon />,
    items: [
      { title: "User Manual", path: "KOWA_KAL_UserGuide.pdf", icon: <UserManualIcon /> },
    ],
  },
]
export default menu;
