import { Button, Card, FormGroup, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography, } from "@mui/material";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { TRAY_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import { useRoot } from "../../../rootContext";
import { DateFormat } from "../../../utils";
import SearchFilter from "./SearchFilter";
import AutoEvaluationTable from "./AutoEvaluationTable";
import appResources from "../../../app-resources/en/Resources.json";
import { UpdateIcon } from "../../../icons/ButtonIcons";

export default function AutoEvaluation() {
  const { post } = useFetch();
  const { user, CanQualityCheckTrays, CanEvaluate } = useRoot();

  const [samplesData, setSamplesData] = useState([]);
  const [filterState, setFilterState] = useState();
  const [infoMessage, setInfoMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [autoUpdateMinutesDropdown, setAutoUpdateMinutesDropdown] = useState(10);
  const [updatedDate, setUpdatedDate] = useState();
  const [autoUpdateMinutes, setAutoUpdateMinutes] = useState(10);
  const [refreshOption] = useState([0, 2, 5, 10, 15, 20, 30, 45, 60]);

  useEffect(() => {
    document.title = "Auto-Evaluation";
  }, [user]);

  async function onViewHandler(filter) {
    if (filter.Date === "Invalid date") {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      //  setIsSuccessIcon(false);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setFilterState(filter);
    const sampleData = await post(TRAY_APIS.AUTO_EVAL, {
      Date:
        filter.LabAndDateOption === 2 || filter.LabAndDateOption === 4
          ? null
          : moment(filter.Date).format(DateFormat),
      LabId:
        filter.LabAndDateOption === 3 || filter.LabAndDateOption === 4
          ? null
          : user.DefaultLaboratoryId,
      TrayNumber:
        filter.LabAndDateOption === 0 || filter.LabAndDateOption === 5
          ? filter.Tray
          : null,

      OnlyStep4:
        filter.LabAndDateOption === 5 || filter.LabAndDateOption === 6
          ? false
          : true,
    });
    if (sampleData?.length < 1) {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      setSamplesData();
      return setInfoMessage(appResources.NO_SAMPLES_WERE_FOUND_MSG);
    } else {
      setUpdatedDate(moment().format("hh:mm:ss ddd D MMM YYYY"));
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setSamplesData(sampleData);
    }
  }
  function handleChangeDropdown(e) {
    setAutoUpdateMinutesDropdown(e.target.value);
  }

  // Function to set up the interval
  const setupInterval = () => {
    const intervalFunction = () => {
      onViewHandler(filterState);
    };
    const intervalTime = autoUpdateMinutes * 60 * 1000;
    return setInterval(intervalFunction, intervalTime);
  };

  // Set up the interval when autoUpdateMinutes changes or on component mount
  useEffect(() => {
    let intervalId;
    if (autoUpdateMinutes !== 0) {
      intervalId = setupInterval();
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoUpdateMinutes]);

  function updateClick() {
    setAutoUpdateMinutes(autoUpdateMinutesDropdown);
  }

  const UpdateMinutesView = () => {
    return <Grid item mt={0.5}>
      <InputLabel>Auto Update Every</InputLabel>
      <FormGroup row={true}>
        <Select
          value={autoUpdateMinutesDropdown}
          onChange={handleChangeDropdown}
          displayEmpty
          inputProps={{ "aria-label": "Auto Update Every" }}
          sx={{ width: 70, mr: 2 }}
        >
          {refreshOption.map((op) => (
            <MenuItem value={op} key={op}>
              {op}
            </MenuItem>
          ))}
        </Select>
        <Typography mt={1} mr={2}>
          Minutes
        </Typography>
        {(CanQualityCheckTrays || CanEvaluate) && <Button
          variant="contained"
          onClick={updateClick}
          startIcon={<UpdateIcon />}
        >
          Update
        </Button>}
        <FormHelperText sx={{ mt: 1, ml: 2 }}>
          Updated: {updatedDate}
        </FormHelperText>
      </FormGroup>
    </Grid>
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ p: 1 }}>
          <Grid container justifyContent="space-between" alignItems="flex-end">
            <Grid item xs={12}>
              <SearchFilter
                onView={onViewHandler}
                infoMessage={infoMessage}
                successMessage={successMessage}
                isInfoIcon={isInfoIcon}
                isSuccessIcon={isSuccessIcon}
                isWarningTrue={isWarningTrue}
                UpdateMinutesView={UpdateMinutesView}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Total Records: <b> {(samplesData && samplesData?.length) || 0}</b>
        </Typography>
        <AutoEvaluationTable data={samplesData} filterState={filterState} canModify={(CanQualityCheckTrays || CanEvaluate)} />
      </Grid>
    </Grid>
  );
}
