import DataTable from "../../../components/DataTable";
import { DashboardColumns } from "./DashboardColumns";

export default function TrayDashboardTable(props) {
  const options = {
    ...props,
    columns: DashboardColumns(props),
    dataSource: props.data,
    rowIdProperty: "Tray",
    cellStylingHandler: "trayDashboard",
    isDisableColumnFilter: true,
    sx: {
      height: 320,
    },
  };
  return <DataTable {...options} />;
}
