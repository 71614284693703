import React, { useState } from "react";
import SearchDetails from "./SearchDetails";
import useFetch from "../../hooks/useFetch";
import ViewDetails from "./ViewDetails";
import { DETAILS_APIS } from "../../common/apis";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CustomerAction from '../../redux/actions/CustomerActions';

function QuickDetails({ CustomerAction }) {
  const [sectionsData, setSectionsData] = useState({});
  const { get } = useFetch();
  const [nodeLevel, setNodeLevel] = useState(0);

  const getNodeDetailData = async (data, nodeType) => {
    const resp = await callNodeDetailApi(data, nodeType);
    setSectionsData(resp);
    CustomerAction.updateCustomer(resp.Customer)
    setNodeLevel(nodeType);
  };

  const callNodeDetailApi = async (data, node) => {
    if (node === 2) {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer&cId=${data.CustomerId}`
      );
    }
    if (node === 3) {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer,site&cId=${data.CustomerId}&sId=${data.SiteId}`
      );
    }
    if (node === 4) {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer,site,unit&cId=${data.CustomerId}&sId=${data.SiteId}&uId=${data.UnitId}`
      );
    }
    if (node === 5) {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer,site,unit,component&cId=${data.CustomerId}&sId=${data.SiteId}&uId=${data.UnitId}&compId=${data.ComponentId}`
      );
    }
    if (node === 6) {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer,site,unit,component,sample&cId=${data.CustomerId}&sId=${data.SiteID}&uId=${data.UnitId}&compId=${data.ComponentId}&sampleNo=${data.SampleNumber}`
      );
    }
    // if (node === 8) {
    //   return await get(
    //     `${DETAILS_APIS.DETAILS}?types=customer,site,unit,component&cId=${data.CustomerId}&sId=${data.SiteId}&uId=${data.UnitId}&compId=${data.ComponentId}`
    //   );
    // }
    // if (node === 9) {
    //   return await get(
    //     `${DETAILS_APIS.DETAILS}?types=customer,site&cId=${data.CustomerId}&sId=${data.SiteId}`
    //   );
    // }
    return {};
  };
  function rowSelected(data, nodeType) {
    getNodeDetailData(data, nodeType);
    //setNodeLevel(level);
  }
  return (
    <>
      <SearchDetails rowSelected={rowSelected} />
      <ViewDetails sectionsData={sectionsData} nodeLevel={nodeLevel} />
    </>
  );
}


const mapDispatchToProps = (dispatch) => {
  return { CustomerAction: bindActionCreators(CustomerAction, dispatch), };
}

export default connect(null, mapDispatchToProps)(QuickDetails);