import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, } from "react";
import { useState } from "react";
import useFetch from "../../../hooks/useFetch";
import QuickMergeFluidTable from "./QuickMergeFluidTable";
import QuickMergeToFluidTable from "./QuickMergeToFluidTable";
import { FLEET_APIS } from "./../../../common/apis";
import { SaveIcon, } from "../../../icons/ButtonIcons";
import { useRoot, useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";

export default function QuickMergeFluid({ }) {

  const { get, post } = useFetch();
  const { CanDoDataMaintenance } = useRoot();
  const [fluidData, setFromFluidData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedToFluidRows, setSelectedToFluidRows] = useState([]);
  const { showToast } = useToast();

  //call api for loading data
  useEffect(function () {
    document.title = "Quick Merge Fluid";
    getFluidsAllData();
  }, []);
  async function getFluidsAllData() {
    const response = await get(FLEET_APIS.FLUIDS_ALL);
    if (response.length > 0) {
      setFromFluidData(response);
    }
  }
  const rowsSelectedHandler = (selection) => {
    setSelectedRows([...selection]);
  };
  const rowToFluidSelectedHandler = (selection) => {
    setSelectedToFluidRows(selection);
  };

  async function saveClicked() {
    await post(FLEET_APIS.MERGE_FLUID, {
      FromFluidId: selectedRows.map((fluid) => fluid.FluidId),
      ToFluidId: selectedToFluidRows.FluidId,
      Screen: "Merge Fluids",
    });
    await getFluidsAllData();
    showToast(appResources.SELECTED_FLUID_UPDATED_SUCCESSFULLY_MSG);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {fluidData && fluidData.length}</b>
          </Typography>
          <QuickMergeFluidTable
            rowsSelected={rowsSelectedHandler}
            data={fluidData}
          />
        </Grid>
        {CanDoDataMaintenance && <Grid item xs={4} mt={2}>
          {selectedRows.length > 0 ? (
            <Card sx={{ p: 1, height: 784 }}>
              <Typography>From Fluids:</Typography>
              {selectedRows &&
                selectedRows.map((item, index) => (
                  <Typography
                    variant="body2"
                    sx={{ m: (1) }}
                    key={index}
                  >
                    {index + 1}. {item.FluidFormatted}
                  </Typography>
                ))}

              <Typography>To Fluids:</Typography>
              <Typography variant="body2" sx={{ m: (1) }}>
                {selectedToFluidRows.FluidFormatted}
              </Typography>
              <Grid
                container
                justifyContent="flex-end"
                spacing={2}
                sx={{ marginTop: "10px" }}
              >
                <Button
                  startIcon={<SaveIcon />}
                  onClick={saveClicked}
                  disabled={!selectedToFluidRows.FluidFormatted}
                >
                  Save
                </Button>
              </Grid>
            </Card>
          ) : (
            <Card sx={{ p: 1, height: 745 }}>
              <Typography>Please select both From and To Fluids</Typography>
            </Card>
          )}
        </Grid>}
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {fluidData && fluidData.length}</b>
          </Typography>
          <QuickMergeToFluidTable
            rowSelected={rowToFluidSelectedHandler}
            data={fluidData}
          />
        </Grid>
      </Grid>
    </>
  );
}
