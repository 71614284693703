import renderCellExpand from "../../../../components/DatatableCellExpand";
import { toLocalDateTime } from "../../../../utils";
import DataTable from "./../../../../components/DataTable";

export default function AddCriticalLogsTable({ data, rowsSelected, ...props }) {
  const options = {
    ...props,
    columns: Columns,
    dataSource: data,
    rowIdProperty: "CriticalLogId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      rowsSelected && rowsSelected(r[0]);
    },
    sx: { height: 450 },
  };
  return <DataTable {...options}></DataTable>;
}

const Columns = [
  {
    headerName: "Technician",
    field: "Evaluator",
    minWidth: 150,
    renderCell: renderCellExpand,
  },

  {
    headerName: "Contact",
    field: "Contact",
    minWidth: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Telephone",
    field: "Telephone",
    minWidth: 150,
  },
  {
    headerName: "Email",
    field: "Email",
    minWidth: 170,
  },
  {
    headerName: "Win",
    field: "IsWin",
    valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    cellClassName: (params) => {
      if (!params.value) {
        return "";
      }
      return "cell-fg-wt";
    },
    minWidth: 100,
  },
  {
    headerName: "Created",
    field: "Created",
    valueFormatter: ({ value }) => toLocalDateTime(value),
    minWidth: 170,
  },
  {
    headerName: "Created By",
    field: "CreatedBy",
    minWidth: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Modified",
    field: "Modified",
    minWidth: 150,
  },
  {
    headerName: "Modified By",
    field: "ModifiedBy",
    minWidth: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Comments",
    field: "Comments",
    minWidth: 200,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Corrective Action",
    field: "CorrectiveAction",
    minWidth: 150,
    flex: 1,
  },
];
