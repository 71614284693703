import moment from "moment/moment";
import {
  TrayDateFormat,
  UserDateFormat,
  toLocalDateTime,
} from "../../../utils";
import renderCellExpand from "../../../components/DatatableCellExpand";

export const RerunSamplesColumns = [
  {
    headerName: "Laboratory",
    field: "Laboratory",
    minWidth: 120,
  },
  {
    headerName: "Tray",
    field: "Tray",
    width: 40,
  },
  {
    headerName: "Tray Date",
    field: "TrayDate",
    valueFormatter: (params) =>
      moment(params.value, TrayDateFormat).format(UserDateFormat),
    minWidth: 120,
  },
  {
    headerName: "SampleNumber",
    field: "SampleNumber",
    minWidth: 125,
  },
  {
    headerName: "SampleCount",
    field: "SampleCount",
    minWidth: 100,
  },
  {
    headerName: "Sent Back",
    field: "SentBack",
    valueFormatter: ({ value }) => toLocalDateTime(value),
    minWidth: 150,
  },
  {
    headerName: "Sent By",
    field: "SentBy",
    minWidth: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Sent From",
    field: "SentFrom",
    minWidth: 150,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Released",
    field: "LaboratoryRelease",
    valueFormatter: ({ value }) => toLocalDateTime(value),
    minWidth: 150,
  },
  {
    headerName: "Reason",
    field: "Reason",
    minWidth: 600,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
