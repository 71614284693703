import * as React from "react";
import { Card, Grid, Typography, } from "@mui/material";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import ComponentModelsTable from "../components-model/ComponentModelsTable";
import ViewComponentModels from "../components-model/ViewComponentModels";
import { FLEET_APIS } from "../../../common/apis";
import { useTheme } from "@emotion/react";

export default function ComponentModels() {
  const [unitsData, setUnitsData] = React.useState([]);
  const [selectedComponent, setSelectedComponent] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const { get } = useFetch();
  const theme = useTheme();
  //   const [data, dispatch] = React.useReducer(FluidReducer, {});

  async function fetchComponentModels() {
    const temp = await get(FLEET_APIS.COMPONENT_MODELS);
    setUnitsData(temp || []);
    setSelectedComponent(temp[0]);
  }
  React.useEffect(function () {
    document.title = "Component Models";
    fetchComponentModels();
    //func();
  }, []);

  function handelFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }
  // function onClickNewFluid(event) {}
  function unitRowSelectedHandler(row) {
    setSelectedComponent(row);
  }

  const filteredData = React.useMemo(
    function () {
      return unitsData.filter(
        (x) =>
          !filter.startsWith ||
          x.ComponentMakeFormatted?.toUpperCase().startsWith(
            filter.startsWith.toUpperCase()
          )
      );
    },
    [unitsData, filter]
  );
  //const [newFluidOpen, setnewFluidOpen] = React.useState(false);
  // function handleNewFluidClick() {
  //   setnewFluidOpen(true);
  // }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2} alignItems="flex-end">
          <Grid item>
            <TextBox
              name="startsWith"
              label="Starts with"
              //value={filter.startsWith}
              onChange={handelFilterChange}
            />
          </Grid>
          {/* <Grid item>
            <Typography variant="subtitle2">
              Total Records: <b> {filteredData && filteredData.length}</b>
            </Typography>
          </Grid> */}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData?.length}</b>
          </Typography>
          <ComponentModelsTable
            data={filteredData}
            rowSelected={unitRowSelectedHandler}
            selectedRow={
              selectedComponent?.ComponentMakeId +
              selectedComponent?.ComponentModel
            }
          />
        </Grid>
        <Grid item xs={6} mt={2}>
          <Card sx={{ p: 1 }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: 13,

                mb: theme.spacing(0.5),
                color: "#8B4513",
              }}
            >
              This is for components / parts, and not units : do NOT use unit
              makes and models
            </Typography>

            <ViewComponentModels
              unitModel={selectedComponent}
              key={selectedComponent.ComponentMakeId}
              fetchComponentModels={fetchComponentModels}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
