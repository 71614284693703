import { Save } from "@mui/icons-material";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { LabSteps, ResultColWidths } from "../../../common/Constants";
import DropDown from "../../../components/DropDown";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import { useToast, useUser } from "../../../rootContext";
import { DateFormat, toLocalDate } from "../../../utils";
import SamplesTable from "./SamplesTable";
import SearchFilter from "./SearchFilter";
import Hold from "./Hold";
import AppDialog from "./../../../components/AppDialog";
import ImagePick from "./../../../components/SelectImage";
import SwitchInput from "../../../components/SwitchInput";
import { TRAY_APIS } from "../../../common/apis";
import TickBlue from "../../../Image/TickBlue.svg";
import TickGreen from "../../../Image/TickGreen.svg";
import appResources from "../../../app-resources/en/Resources.json";
import {
  ChartsIcon,
  HoldIcon,
  ImagesIcon,
  ReleaseIcon,
  ReleaseSampleIcon,
} from "../../../icons/ButtonIcons";
import ResizePanel from "react-resize-panel";
import { useScreenPopups } from "../../../components/useScreenPopups";
import GroupDialog from "../../../components/GroupDialog";
import DropdownMenu from "../../../components/DropdownMenu";
import { BadgeButton } from "../../../components/FormFooter";
import ResultsTableNew from "./ResultsTableNew";
import { useRef } from "react";
import HistoryTableNew from "./HistoryTableNew";
import { NewDataGridAction } from "../../../components/NewDataTable";

let columnsTestItem = "";
export default function LaboratoryNew({ laboratoryActions }) {
  const { post, get, getFile } = useFetch();
  const user = useUser();

  const [isRoleValid, setIsRoleValid] = useState(false);
  const [samplesData, setSamplesData] = useState({});
  const [results, setResults] = useState({});
  const [lockResults, setLockResults] = useState(true);
  const [isCompletedSample, setIsCompletedSample] = useState(false);
  const [isCompletedTray, setIsCompletedTray] = useState(false);
  const [dialogType, setDialogType] = useState({ open: false });
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
  });

  useEffect(() => {
    if (user.RoleLaboratory || user.RoleDeveloper || user.RoleAdministration) {
      setIsRoleValid(true);
    }
  }, [user]);

  const [holdOpen, setHoldOpen] = useState(false);

  function sampleInputOnChangeHandler(e) {
    if (selectedSample.Step > 1) {
      setLockResults(e.target.checked);
    } else {
      showWarningToast(
        `Unable to update cell value. The sample ${selectedSample.SampleNumber} is at registration step at the moment.`
      );
    }
  }

  const [isShowImage, setIsShowImage] = useState(true);
  const { showToast, showWarningToast } = useToast();
  //useEffect to handle click any where on the screen
  useEffect(() => {
    function handleClick(event) {
      setIsShowImage(false);
    }
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  function handleImageClick() {
    setDialog({
      open: true,
      title: `Sample Images: Sample Number ${selectedSampleNo}`,
    });
  }
  // Imran Zulfiqar
  const [selectedSampleNo, setSelectedSampleNo] = useState(0);
  const [selectedSample, setSelectedSample] = useState(0);
  const [filterState, setFilterState] = useState();
  const [setText, setEnteredText] = useState("");
  const [imageCount, setImageCount] = useState("");
  const resultData = useRef([]);
  const columnsData = useRef([]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F4" && samplesData?.Tray?.length > 0) {
        event.preventDefault();
        handleChartClick();
      } else if (event.key === "F2") {
        event.preventDefault();
        if (isRoleValid) {
          setHoldOpen(true);
        }
      } else if (event.key === "F3") {
        event.preventDefault();
        handleImageClick();
      } else if (event.key === "F12") {
        event.preventDefault();
        if (
          isRoleValid &&
          selectedSample.Step > 1 &&
          selectedSample.HoldType === null
        ) {
          ReleaseSampleClick();
        }
      } else if (event.key === "F11") {
        event.preventDefault();
        if (
          isRoleValid &&
          samplesData?.Tray?.every((sample) => sample.HoldType === null) &&
          samplesData?.Tray?.filter((item) => item.AlertColour === "Green")
            .length >
            samplesData?.Tray?.length - 4
        ) {
          ReleaseTrayClick();
        }
      }
    },
    [samplesData, isRoleValid, selectedSample]
  );
  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [handleKeyDown]);

  //On view click to load lab data
  async function onViewHandler(filter) {
    if (filter.Date === "Invalid date") {
      return showWarningToast(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setFilterState(filter);
    const resp = await post(TRAY_APIS.GET_LABORATORY, {
      Date:
        filter.LabAndDateOption === 2 || filter.LabAndDateOption === 4
          ? null
          : moment(filter.Date).format(DateFormat),
      LabId:
        filter.LabAndDateOption === 3 || filter.LabAndDateOption === 4
          ? null
          : user.DefaultLaboratoryId,
      TrayNumber: filter.LabAndDateOption === 0 ? filter.Tray : null,
      MinimumStep: LabSteps.Registration,
      MaximumStep:
        filter.LabAndDateOption === 0
          ? LabSteps.Completed
          : LabSteps.Laboratory,
      Hold: true,
    });
    if (resp?.Tray.length < 1) {
      setSamplesData(resp);
      return showWarningToast(appResources.TRAY_DOES_NOT_EXIST);
    }
    setSamplesData(resp);

    if (resp.Tray.length > 0) {
      setSelectedSampleNo(resp.Tray[0].SampleNumber);
      setSelectedSample(resp.Tray[0]);
      // setSelectedSampleNo(sampleData.Tray[0].SampleNumber);
      // getData();
    } else {
      setSelectedSampleNo(0);
      setEnteredText("");
    }
  }

  function refreshGrid() {
    if (filterState) {
      onViewHandler(filterState);
    }
  }

  useEffect(() => {
    if (samplesData.TrayResults) {
      const { data, columns, columns1 } = getDataAndColumns(
        samplesData,
        lockResults,
        setIsCompletedTray
      );
      setResults({ data, columns, columns1 });
      resultData.current = data;
      columnsData.current = columns1;
      return;
    }
  }, [lockResults, samplesData]);

  useEffect(() => {
    getData();
  }, [selectedSampleNo]);

  function handleChartClick() {
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    var url = "/Chart?sampleId=" + selectedSampleNo + "&hideProp=2";
    var windowFeatures =
      "width=" +
      windowWidth +
      ",height=" +
      windowHeight +
      ",top=0,left=0,resizable,scrollbars=yes";

    window.open(url, "_blank", windowFeatures);
  }

  const [viewType, setViewType] = useState(0);
  function handelViewTypeChange(e) {
    setViewType(e.target.value);
  }

  function sampleRowSelectedHandler(sample) {
    if (sample.SampleNumber === selectedSampleNo) {
      return;
    }
    let totalValCount = 0;
    let hasValCount = 0;
    for (const key in sample) {
      if (
        (key.endsWith("_hasVal") || key.endsWith("_valReq")) &&
        sample[key] === true
      ) {
        totalValCount++;
      }
    }
    for (const key in sample) {
      if (key.endsWith("_hasVal") && sample[key] === true) {
        hasValCount++;
      }
    }
    if (totalValCount === hasValCount && totalValCount > 0 && hasValCount > 0) {
      setIsCompletedSample(true);
    } else {
      setIsCompletedSample(false);
    }

    setSelectedSampleNo(sample.SampleNumber);
    setSelectedSample(sample);
    if (sample.Step < 2) {
      setLockResults(true);
    }
    localStorage.removeItem("selectedSampleNo");
    window.localStorage.setItem("selectedSampleNo", sample.SampleNumber);
    getData();
  }

  const nonHoldSamples = useMemo(
    () => (samplesData.Tray || []).filter((x) => x.HoldType === null),
    [samplesData]
  );

  async function ReleaseSampleClick() {
    const response = await post(TRAY_APIS.RELEASE_SAMPLE, {
      SampleNumber: selectedSample.SampleNumber,
      Note: "",
      Screen: "Laboratory",
    });
    if (response > 0) {
      showToast(
        `${selectedSample.SampleNumber} has been released successfully`
      );
      refreshGrid();
    }
  }
  async function ReleaseTrayClick() {
    const response = await post(
      TRAY_APIS.RELEASE_SAMPLES,
      nonHoldSamples.map((x) => x.SampleNumber)
    );
    if (response > 0) {
      showToast(`Tray has been released`);
      refreshGrid();
    }
  }

  function handleSaveClick() {
    saveNotes();
  }

  const [history, setHistory] = useState({});

  useEffect(
    function () {
      (async function () {
        if (!selectedSampleNo) {
          setHistory({});
          return;
        }

        const sampleData = await get(
          `${TRAY_APIS.HISTORY}?sampleNo=${selectedSampleNo}&includeLaterSamples=true&resultView=0`,
          {}
        );
        const { columns1, columns, data } =
          viewType === 1
            ? getDataAndColumnsPastSamplesHistory(
                sampleData,
                laboratoryActions,
                rowActionHandler
              )
            : viewType === 2
            ? getDataAndColumnsPastSamples(
                sampleData,
                laboratoryActions,
                rowActionHandler
              )
            : {};
        setHistory({ columns1, columns, data });
      })();
    },
    [selectedSampleNo, viewType]
  );

  function handleHoldClick() {
    setHoldOpen(true);
  }

  const handleCellSave = useCallback(function (cellData, target) {
    return new Promise(async function (resolve, reject) {
      let newValue = target.value;
      let testName = target.name;
      let testItemId = cellData?.column?.columnDef?.testId;
      let nanCheck = isNaN(Number(newValue));
      if (nanCheck === true) {
        showWarningToast(appResources.VALUES_BE_NUMERIC);
        return (newValue = 0);
      }
      let filteredItems = resultData.current.map((item) => {
        if (item.SampleNumber === cellData?.row?.original?.SampleNumber) {
          return { ...item, [testName]: newValue };
        } else {
          return item;
        }
      });
      resultData.current = filteredItems;
      setResults({ data: filteredItems, columns1: columnsData.current });
      if (testItemId) {
        await get(
          `${TRAY_APIS.EDIT_RESULT_TEXT}?SampleNumber=${
            cellData?.row?.original?.SampleNumber
          }&TestItemId=${testItemId}&formattedValue=${
            newValue === "" ? 0 : newValue
          }`
        );
      }
    });
  }, []);

  async function getSampleReport(row) {
    try {
      await getFile(
        `${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`
      );
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  function getSampleGraph(row) {
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    var url = "/Chart?sampleId=" + row?.SampleNumber + "&hideProp=2";
    var windowFeatures =
      "width=" +
      windowWidth +
      ",height=" +
      windowHeight +
      ",top=0,left=0,resizable,scrollbars=yes";

    window.open(url, "_blank", windowFeatures);
  }

  function rowActionHandler(row, actionName) {
    if (
      [
        "add-sample-images",
        "edit-sample",
        "hold-management",
        "edit-sample",
      ].indexOf(actionName) > -1
    ) {
      openDialog(row, actionName);
      return;
    } else if (actionName === "view-graphs") {
      return getSampleGraph(row);
    } else if (actionName === "view-sample-report") {
      return getSampleReport(row);
    }
  }

  const dialogOptions = useScreenPopups({
    data: dialogType.data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
  });

  function openDialog(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={8}>
                <SearchFilter onView={onViewHandler} />
              </Grid>
              <Grid item>
                <Grid container spacing={1} alignItems="flex-end"></Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {samplesData?.Tray?.length || 0}</b>
          </Typography>
          <ResizePanel direction="s" height="10px">
            <SamplesTable
              data={samplesData?.Tray || []}
              rowSelected={sampleRowSelectedHandler}
              selectedRow={[selectedSampleNo]}
              actions={laboratoryActions}
              rowActionHandler={rowActionHandler}
            />
          </ResizePanel>
        </Grid>

        <Grid
          item
          xs={12}
          hidden={samplesData && samplesData?.Tray?.length > 0 ? false : true}
        >
          <Card sx={{ p: 1 }}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {isRoleValid && (
                    <Grid item xs={6}>
                      <TextBox
                        label={"Notes"}
                        multiline
                        rows={3}
                        key={selectedSampleNo}
                        value={setText}
                        onChange={(v) => setEnteredText(v.target.value)}
                      />
                    </Grid>
                  )}

                  <Grid item>
                    {isCompletedSample && (
                      <Box
                        component="img"
                        sx={{
                          height: 41,
                        }}
                        alt="TickBlue"
                        src={TickBlue}
                      />
                    )}{" "}
                    {isCompletedTray && (
                      <Box
                        component="img"
                        sx={{
                          height: 41,
                        }}
                        alt="TickGreen"
                        src={TickGreen}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {isRoleValid && (
                    <Grid item>
                      <Button
                        variant="contained"
                        startIcon={<Save />}
                        onClick={handleSaveClick}
                        disabled={!samplesData?.Tray?.length}
                      >
                        Save Text
                      </Button>
                    </Grid>
                  )}
                  {isRoleValid && (
                    <Grid item marginTop="7px">
                      <SwitchInput
                        value={lockResults || false}
                        label="Lock Results"
                        className={samplesData?.Tray?.length ? "red" : ""}
                        disabled={!samplesData?.Tray?.length}
                        onChange={sampleInputOnChangeHandler}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <DropDown
                      sx={{ width: "170px" }}
                      value={viewType}
                      name="viewType"
                      onChange={handelViewTypeChange}
                      disabled={!samplesData?.Tray?.length}
                      selectOptions={[
                        { text: "Result", value: 0 },
                        { text: "History", value: 1 },
                        { text: "History Simple", value: 2 },
                      ]}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<ChartsIcon />}
                      onClick={handleChartClick}
                      disabled={!samplesData?.Tray?.length}
                    >
                      Charts (F4)
                    </Button>
                  </Grid>

                  <Grid item>
                    <BadgeButton
                      variant="outlined"
                      startIcon={<ImagesIcon />}
                      badge={{ badgeContent: imageCount || 0, color: "error" }}
                      label="Images (F3)"
                      onClick={handleImageClick}
                    />
                  </Grid>

                  {isRoleValid && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={handleHoldClick}
                        startIcon={<HoldIcon />}
                        disabled={!samplesData?.Tray?.length}
                      >
                        Hold (F2)
                      </Button>
                    </Grid>
                  )}

                  {isRoleValid &&
                    selectedSample.Step > 1 &&
                    selectedSample.HoldType === null && (
                      <Grid item>
                        <Button
                          startIcon={<ReleaseSampleIcon />}
                          onClick={ReleaseSampleClick}
                        >
                          Release Sample (F12)
                        </Button>
                      </Grid>
                    )}

                  {isRoleValid &&
                    samplesData?.Tray?.every(
                      (sample) => sample.HoldType === null
                    ) &&
                    samplesData?.Tray?.filter(
                      (item) => item.AlertColour === "Green"
                    ).length >
                      samplesData?.Tray?.length - 4 && (
                      <Grid item>
                        <Button
                          startIcon={<ReleaseIcon />}
                          onClick={ReleaseTrayClick}
                        >
                          {viewType > 0
                            ? "Release All (F11)"
                            : "Release Tray (F11)"}
                        </Button>
                      </Grid>
                    )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records:
            <b>
              {viewType === 0
                ? results.data
                  ? results.data.length
                  : 0
                : history.data
                ? history.data.length
                : 0}
            </b>
          </Typography>
          {viewType === 1 || viewType === 2 ? (
            <>
              {/* <ResizePanel direction="s">
              </ResizePanel> */}
              <HistoryTableNew {...history} />
            </>
          ) : (
            <>
              {/* <ResizePanel direction="s"> */}
              <ResultsTableNew
                {...results}
                handleCellSave={handleCellSave}
                rowSelected={sampleRowSelectedHandler}
                selectedRow={[selectedSampleNo]}
                lockResults={lockResults}
              />
              {/* </ResizePanel> */}
            </>
          )}
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={`Sample Images: Sample Number ${selectedSampleNo}`}
        maxWidth="xl"
      >
        <ImagePick sampleId={selectedSampleNo} setDialog={setDialog} />
      </AppDialog>
      <AppDialog
        open={holdOpen}
        handleClose={() => setHoldOpen(false)}
        title="Put on Hold from Laboratory"
        maxWidth="xl"
      >
        <Hold
          sampleId={selectedSampleNo}
          refreshGrid={refreshGrid}
          setHoldOpen={setHoldOpen}
        />
      </AppDialog>

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
    </>
  );

  async function getData() {
    if (selectedSampleNo === 0) {
      return;
    }
    const CommentsData = await get(
      `${TRAY_APIS.SAMPLE_NOT_IMAGE_COUNT}?SampleNumber=${selectedSampleNo}`
    );
    if (CommentsData[0].SampleNotes) {
      setEnteredText(CommentsData[0].SampleNotes);
    } else {
      setEnteredText("");
    }
    setImageCount(CommentsData[0].SampleImageCount);
  }

  async function saveNotes() {
    await post(TRAY_APIS.UPDATE_SAMPLE_NOTE, {
      SampleNumber: selectedSampleNo,
      Note: setText ? setText : null,
      Screen: "Laboratory",
    });
  }
}

const menuOptions = [
  { label: "View Sample Report", name: "view-sample-report" },
];

function getDataAndColumns(
  { TrayResults: results, TestSetItems: testSetItems, Tray: sampleData },
  lockResults,
  setIsCompletedTray
) {
  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });
  const testItems = new Set();
  const testFilterItemsList = new Set();
  const dataObject = {};

  results.forEach(function (r) {
    if (r[0] != null) {
      if (!dataObject[r[0].SampleNumber]) {
        dataObject[r[0].SampleNumber] = { ...samplesMap[r[0].SampleNumber] };
      }
    }
    r.forEach(function (item) {
      testItems.add(item.TestItemCode + ": " + item.TestItem);
      testFilterItemsList.add(item.TestItemCode + ":" + item.TestItemId);
      var sample = dataObject[r[0].SampleNumber];
      sample[item.TestItemCode] = item.FormattedText;
      sample[item.TestItemCode + "_id"] = item.TestItemId;
    });
  });

  const data = [];

  Object.keys(dataObject).forEach(function (key) {
    var sample = dataObject[key];
    (testSetItems[sample.TestSetId] || []).forEach(function (item) {
      if (!sample[item.TestItemCode]) {
        sample[item.TestItemCode + "_valReq"] = true;
        sample[item.TestItemCode + "_id"] = item.TestItemId;
        return;
      }
      sample[item.TestItemCode + "_hasVal"] = true;
    });
    data.push(sample);
  });

  let columns = [];
  let columns1 = [];
  if (Object.values(testSetItems)[0]?.length <= 0) {
    columns1 = [...testItems].map((x) => ({
      accessorKey: x.split(":")[0],
      header: x.split(":")[0],
      testId: x.TestItemId,
      maxSize: ResultColWidths[x] || (x.split(":")[0].length < 3 ? 35 : 40),
      flex: 1,
      sortable: false,
      description: `${x.split(":")[0]}: ${x.split(":")[1]}`,
      editable: !lockResults,
      muiTableBodyCellEditTextFieldProps: {
        sx: {
          width: 100,
          height: "18px",
          padding: 0,
          marginTop: "-3px",
          color: "white",
          marginLeft: "-10px",
          [`.MuiInputBase`]: { padding: 0, color: "white" },
          boxShadow: "inset 0 0 0 10em rgba(255, 255, 255, 0.3)",
        },
        variant: "outlined",
      },
      muiTableBodyCellProps: ({ row }) => {
        return {
          sx: {
            py: 0.25,
            backgroundColor:
              true === row?.original[x.TestItemCode + "_hasVal"]
                ? "green"
                : true === row?.original[x.TestItemCode + "_valReq"]
                ? "red"
                : "",
            color:
              true === row?.original[x.TestItemCode + "_hasVal"] ||
              true === row?.original[x.TestItemCode + "_valReq"]
                ? "white"
                : "",
          },
        };
      },
    }));

    columns = [...testItems].map((x) => ({
      headerName: x.split(":")[0],
      field: x.split(":")[0],
      testId: x.TestItemId,
      minWidth: ResultColWidths[x] || (x.split(":")[0].length < 3 ? 35 : 40),
      flex: 1,
      sortable: false,
      columnDef: `${x.split(":")[0]}: ${x.split(":")[1]}`,
      editable: !lockResults,
    }));
  } else {
    columns = Object.values(testSetItems)[0].map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      testId: x.TestItemId,
      minWidth:
        ResultColWidths[x] ||
        (x.TestItemCode.length < 3 ? 35 : x.TestItemCode.length < 5 ? 45 : 60),
      flex: 1,
      sortable: false,
      description: `${x.TestItemCode}: ${x.TestItem}`,
      editable: lockResults,
    }));
    columns1 = Object.values(testSetItems)[0].map((x) => ({
      accessorKey: x.TestItemCode,
      header: x.TestItemCode,
      testId: x.TestItemId,
      maxSize:
        ResultColWidths[x] ||
        (x.TestItemCode.length < 3 ? 35 : x.TestItemCode.length < 5 ? 45 : 60),
      minSize:
        ResultColWidths[x] ||
        (x.TestItemCode.length < 3 ? 35 : x.TestItemCode.length < 5 ? 45 : 60),
      sortable: false,
      // columnDef: `${x.TestItemCode}: ${x.TestItem}`,
      id: x.TestItemCode,
      muiTableBodyCellEditTextFieldProps: {
        sx: {
          width: 100,
          height: "18px",
          padding: 0,
          marginTop: "-3px",
          color: "white",
          marginLeft: "-10px",
          [`.MuiInputBase`]: { padding: 0, color: "white" },
          boxShadow: "inset 0 0 0 10em rgba(255, 255, 255, 0.3)",
        },
        variant: "outlined",
      },
      muiTableBodyCellProps: ({ row }) => {
        return {
          sx: {
            py: 0.25,
            backgroundColor:
              true === row?.original[x.TestItemCode + "_hasVal"]
                ? "green"
                : true === row?.original[x.TestItemCode + "_valReq"]
                ? "red"
                : "",
            color:
              true === row?.original[x.TestItemCode + "_hasVal"] ||
              true === row?.original[x.TestItemCode + "_valReq"]
                ? "white"
                : "",
          },
        };
      },
    }));
  }
  columnsTestItem = [...columns].map((x) => ({
    TestItemCode: x.field,
    TestItemId: x.testId,
  }));
  columns.unshift({
    headerName: "Sample Number",
    field: "SampleNumber",
    // minWidth: ResultColWidths["SampleNumber"] || 110,
    // maxWidth: ResultColWidths["SampleNumber"] || 110,
    minWidth: 140,
    maxWidth: 140,
    flex: 1,
    sortable: false,
  });
  columns1.unshift({
    accessorKey: "SampleNumber",
    header: "Sample Number",
    minSize: 110,
    maxSize: 110,
    sortable: false,
    enableClickToCopy: true,
    muiTableBodyCellProps: () => {
      return {
        sx: {
          py: 0.25,
        },
      };
    },
  });
  //For Calculating Tray completion
  let totalValCounts = 0;
  let hasValCounts = 0;

  data.forEach((obj) => {
    for (const key in obj) {
      if (
        (key.endsWith("_hasVal") || key.endsWith("_valReq")) &&
        obj[key] === true
      ) {
        totalValCounts++;
      }
    }
  });

  data.forEach((obj) => {
    for (const key in obj) {
      if (key.endsWith("_hasVal") && obj[key] === true) {
        hasValCounts++;
      }
    }
  });

  if (
    totalValCounts === hasValCounts &&
    totalValCounts > 0 &&
    hasValCounts > 0
  ) {
    setIsCompletedTray(true);
  } else {
    setIsCompletedTray(false);
  }
  return { columns, data, columns1 };
}
//Results for History Simple

function getDataAndColumnsPastSamples(
  { PastSamplesElementData: data, PastSamples: sampleData },
  laboratoryActions,
  rowActionHandler
) {
  const dataObject = {};
  const colMap = {};
  const columns1 = [];

  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });

  data.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
    }
    dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    if (colMap[x.TestItemCode]) {
      return;
    }
    colMap[x.TestItemCode] = x;

    columns1.push({
      accessorKey: x.TestItemCode,
      header: x.TestItemCode,
      size: 50,
      maxSize: 50,
      flex: 1,
    });
  });

  columns1.unshift({
    header: "Sample Number",
    accessorKey: "SampleNumber",
    maxSize: 120,
    minSize: 120,
    Cell: ({ row }) => {
      return (
        <>
          {(laboratoryActions || []).map(function (ac, i) {
            return (
              <NewDataGridAction
                height={"15px"}
                key={i}
                row={row}
                label={ac.label}
                startIcon={ac.startIcon}
                onClick={() => console.log(row?.original?.CleanupId)}
              />
            );
          })}
          <DropdownMenu
            options={menuOptions}
            onItemSelect={(option) => rowActionHandler(row.original, option)}
          />
          <Typography paddingTop={0.25}>{row.original.SampleNumber}</Typography>
        </>
      );
    },
  });

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }

  return { columns1, data: dataSrc };
}

//Results for History
function getDataAndColumnsPastSamplesHistory(
  { PastSamplesElementData: data, PastSamples: sampleData },
  laboratoryActions,
  rowActionHandler
) {
  const dataObject = {};
  const colMap = {};
  const columns1 = [];

  const samplesMap = {};
  sampleData.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });

  data.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
      // dataObject[x.SampleNumber].UnitHours =
      //   samplesMap[x.SampleNumber].UnitHours;
    }

    dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    if (colMap[x.TestItemCode]) {
      return;
    }

    colMap[x.TestItemCode] = x;

    columns1.push({
      accessorKey: x.TestItemCode,
      header: x.TestItemCode,
      size: 50,
      maxSize: 50,
    });
  });

  const extraColumns11 = [
    // {
    //   headerName: "Sample Number",
    //   field: "SampleNumber",
    //   minWidth: ResultColWidths["SampleNumber"] || 80,
    //   flex: 1,
    // },
    {
      header: "Sample Number",
      accessorKey: "SampleNumber",
      minSize: 120,
      maxSize: 120,
      Cell: ({ row }) => {
        return (
          <>
            {(laboratoryActions || []).map(function (ac, i) {
              return (
                <NewDataGridAction
                  height={"15px"}
                  key={i}
                  row={row}
                  label={ac.label}
                  startIcon={ac.startIcon}
                  onClick={() => console.log(row?.original?.CleanupId)}
                />
              );
            })}
            <DropdownMenu
              options={menuOptions}
              onItemSelect={(option) => rowActionHandler(row.original, option)}
            />
            <Typography paddingTop={0.25}>
              {row.original.SampleNumber}
            </Typography>
          </>
        );
      },
    },
    {
      header: "UH",
      accessorKey: "UnitHours",
      minSize: 49,
      maxSize: 49,
      flex: 1,
    },
    {
      header: "CH",
      accessorKey: "ComponentHours",
      minSize: 49,
      maxSize: 49,
      flex: 1,
    },
    {
      header: "CR",
      accessorKey: "ComponentRepaired",
      minSize: 49,
      maxSize: 49,
      flex: 1,
      Cell: ({ cell }) => <span>{cell.getValue() == true ? "Yes" : "No"}</span>,
    },
    {
      header: "Fluid",
      accessorKey: "FluidFormatted",
      minSize: 200,
      flex: 1,
    },
    {
      header: "FH",
      accessorKey: "FluidHours",
      descriSize: "Fluid Hours",
      minSize: 49,
      maxSize: 49,
      flex: 1,
    },
    {
      header: "FC",
      accessorKey: "FluidChanged",
      minSize: 49,
      maxSize: 49,
      flex: 1,
      Cell: ({ cell }) => <span>{cell.getValue() == true ? "Yes" : "No"}</span>,
    },
    {
      header: "Topup",
      accessorKey: "Topup",
      minSize: 49,
      maxSize: 49,
      flex: 1,
    },
    {
      header: "Filter",
      accessorKey: "FilterChanged",
      minSize: 49,
      maxSize: 49,
      flex: 1,
      Cell: ({ cell }) => <span>{cell.getValue() == true ? "Yes" : "No"}</span>,
    },
  ];
  const extraColumns21 = [
    {
      header: "Sampled",
      accessorKey: "Sampled",
      minSize: 80,
      maxSize: 80,
      Cell: ({ cell }) => <span>{toLocalDate(cell.getValue())}</span>,
    },
    {
      header: "SIF",
      accessorKey: "SIF",
      minSize: 70,
      maxSize: 70,
    },
    {
      header: "Technician",
      accessorKey: "Evaluator",
      minSize: 80,
      maxSize: 80,
    },

    {
      header: "Status",
      accessorKey: "AlertStatus",
      minSize: 90,
      maxSize: 90,
    },
  ];

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }
  return {
    columns1: [...extraColumns11, ...columns1, ...extraColumns21],
    data: dataSrc,
  };
}
