import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";
import SwitchInput from "../../../components/SwitchInput";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import QuickMoveSitesTable from "./QuickMoveSitesTable";
import QuickMoveToSitesTable from "./QuickMoveToSitesTable";
import { SaveIcon } from "../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";

export default function QuickMoveSites() {

  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);
  const [masterData, setMasterData] = useState();
  const { get, post } = useFetch();
  const { products, CanDoDataMaintenance } = useRoot();

  const [customersData, setCustomersData] = useState([]);
  const [sitesData, setSitesData] = useState([]);
  const [filter, setFilter] = useState({});
  const [isKeepContacts, setIsKeepContacts] = useState(false);
  const [isShowSiteName, setIsShowSiteName] = useState(false);
  const [isArchived, setIsArchived] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedQMSiteRows, setSelectedQMSiteRows] = useState();
  const [newSite, setNewSite] = useState();
  const [selectedToFluidRows, setSelectedToFluidRows] = useState([]);

  //call api for loading data
  useEffect(
    function () {
      document.title = "Quick Move Sites";
      (async function () {
        const temp = products;
        if (temp) {
          setMasterData(temp);
        }
      })();
    },
    [products]
  );

  useEffect(
    function () {
      getSitesAllData();
    },
    [data.ProductId]
  );

  async function getSitesAllData() {
    const response = await get(
      `${FLEET_APIS.SITES_ALL}?ProductId=${data.ProductId}`
    );
    if (response.sitesAll?.length > 0) {
      setSitesData(response.sitesAll);
      setCustomersData(response.customersAll);
      setSelectedRows([]);
      setSelectedRows([response.sitesAll[0]]);
      setNewSite(response.sitesAll[0]?.Site);
      setSelectedQMSiteRows(response.sitesAll[0]);
    } else {
      setSitesData([]);
      setCustomersData([]);
    }
  }

  const rowsSelectedHandler = (selection) => {
    setSelectedRows([]);
    setSelectedRows([selection]);
    setNewSite(selection.Site);
    setSelectedQMSiteRows(selection);
  };
  const rowToFluidSelectedHandler = (selection) => {
    setSelectedToFluidRows(selection);
  };
  function handelFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }
  function handelDestinationFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }
  const filteredData = useMemo(
    function () {
      return sitesData.filter(
        (x) =>
          !filter.startsWith ||
          x.Site?.toUpperCase().startsWith(filter.startsWith.toUpperCase()) ||
          x.Customer?.toUpperCase().startsWith(filter.startsWith.toUpperCase())
      );
    },
    [sitesData, filter]
  );
  const filteredDestData = useMemo(
    function () {
      return customersData.filter(
        (x) =>
          !filter.startsDestination ||
          x.Customer?.toUpperCase().startsWith(
            filter.startsDestination.toUpperCase()
          )
      );
    },
    [customersData, filter]
  );
  async function saveClicked() {
    await post(FLEET_APIS.MOVE_SITE_SPECIAL, {
      SourceSiteId: selectedRows.map((site) => site.SiteId),
      DestinationCustomerId: selectedToFluidRows.CustomerId,
      SiteName: newSite,
      ShowSiteName: isShowSiteName,
      KeepContacts: isKeepContacts,
      IsArchived: isArchived,
      Screen: "Quick Move Sites",
    });
    await getSitesAllData();
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={6} spacing={2}>
          <Grid item sx={{ m: 1 }}>
            <TextBox
              name="startsWith"
              label="Customer/Site Starts With"
              onChange={handelFilterChange}
            />
          </Grid>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <Grid item>
            <TextBox
              name="startsDestination"
              label="Customers Starts With"
              onChange={handelDestinationFilterChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData?.length}</b>
          </Typography>
          <QuickMoveSitesTable
            rowSelected={rowsSelectedHandler}
            selectedRow={
              selectedQMSiteRows?.CustomerId + selectedQMSiteRows?.SiteId
            }
            data={filteredData}
          />
        </Grid>
        <Grid item xs={4} mt={2}>
          <Card sx={{ p: 1, height: 674 }}>
            <Form
              sections={FormSections(masterData)}
              data={data}
              masterData={masterData}
              dispatch={dispatch}
              key={data.SiteId}
            />

            <Typography sx={{ mb: 2 }}>
              From Site (required for update or move)
            </Typography>
            {selectedRows &&
              selectedRows.map((item, index) => (
                <>
                  <Typography sx={{ mb: 2 }}>
                    From Site : KOWA(2) {item.Customer}({item.CustomerId}){" "}
                    {item.Site} ({item.SiteId})
                  </Typography>
                </>
              ))}
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TextBox name="site" label="Site" value={newSite} />
              </Grid>
              <Grid item>
                <SwitchInput
                  label="Hide Site Name On Reports"
                  onChange={(e) => setIsShowSiteName(e.target.checked)}
                />
              </Grid>
              <Grid item>
                <SwitchInput
                  label="Archived"
                  onChange={(e) => setIsArchived(e.target.checked)}
                />
              </Grid>
              <Grid item>
                <SwitchInput
                  label="Keep Contacts"
                  onChange={(e) => setIsKeepContacts(e.target.checked)}
                />
              </Grid>
            </Grid>

            {(CanDoDataMaintenance && selectedToFluidRows.Customer) && (
              <>
                <Typography sx={{ mb: 2, mt: 2 }}>
                  To Customer: KOWA(2) {selectedToFluidRows.Customer} (
                  {selectedToFluidRows.CustomerId})
                </Typography>

                <Button
                  disabled={!newSite}
                  startIcon={<SaveIcon />}
                  onClick={saveClicked}
                >
                  Save
                </Button>
              </>
            )}
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredDestData?.length}</b>
          </Typography>
          <QuickMoveToSitesTable
            rowSelected={rowToFluidSelectedHandler}
            data={filteredDestData}
          />
        </Grid>
      </Grid>
    </>
  );
}

function FormSections(masterData) {
  return [
    {
      fields: [
        {
          group: [
            {
              name: "ProductId",
              label: "Product",
              type: "dropdown",
              xsCol: 4,
              required: true,
              selectOptions: (masterData || [])?.map((x) => ({
                value: x.ProductId,
                text: x.Product,
              })),
              defaultValue: 0,
            },
          ],
        },
      ],
    },
  ];
}
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
const SearchFilterDefaults = { ProductId: 2 };
const EventActions = {
  LOAD_DATA: "load-data",
};
