import { FormatColorFill, } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import { TEST_SETS_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";
import Form, { FormActions } from "../../../components/Form";
import useFetch from "../../../hooks/useFetch";
import { ValidateTestItem } from "../../../validation/schemas/TestItemSchema";
import ManageTestItem from "./ManageTestItem";
import TextualRepresentation from "./TextualRepresentation";
import { ManageIcon, SaveIcon } from "../../../icons/ButtonIcons";
import Colors from "../../../data/colors";
import { useEffect } from "react";

export default function TestItemForm({
  data: testItemData = {},
  masterData,
  refreshTestItems,
}) {
  const [data, dispatch] = useReducer(TestSetReducer, {
    ...testItemData.testItem,
    Representations: testItemData.Representations,
    TestSets: testItemData.TestSets,
  });
  const { get, post } = useFetch();
  const [openManage, setOpenManage] = useState(false);
  const [testItemTypes, setTestItemTypes] = useState();
  const [formErrors, setFormErrors] = useState({});

  async function saveTestItem() {
    ValidateTestItem(data).then(async function ({ data: req, errors }) {
      if (req) {
        await post(TEST_SETS_APIS.SAVE_TEST_ITEMS, req);
        refreshTestItems && refreshTestItems();
        return;
      }
      if (errors) {
        setFormErrors(errors);
      }
    });
  }

  function handelManegeClose() {
    setOpenManage(false);
    // refreshTestItems && refreshTestItems();
  }
  function refreshTestItemsGrid() {
    refreshTestItems && refreshTestItems();
  }
  useEffect(function () {
    getTestItemTypes();
  }, []);

  async function getTestItemTypes() {
    const testItemTypesResp = await get(TEST_SETS_APIS.TEST_ITEMS_TYPES);
    setTestItemTypes(testItemTypesResp || []);
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Form
          sections={FormSections(Colors, data, testItemTypes)}
          dispatch={dispatch}
          data={data}
          key={data?.TestItemId}
          masterData={masterData}
          errors={formErrors}
        />
      </Grid>
      <Grid item container justifyContent="space-between">
        <Grid item>
          <Button
            onClick={() => setOpenManage(true)}
            disabled={!data.TestItemId}
            startIcon={<ManageIcon />}
            variant="outlined"
          >
            Manage
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={saveTestItem} startIcon={<SaveIcon />}>
            Save
          </Button>
        </Grid>
      </Grid>
      <AppDialog
        open={openManage}
        handleClose={handelManegeClose}
        title={data.TestSet}
        maxWidth="xl"
      >
        <ManageTestItem
          close={handelManegeClose}
          source={data}
          refreshTestItems={refreshTestItemsGrid}
        />
      </AppDialog>
    </Grid>
  );
}
const FormSections = (Colors, data, testItemTypes) => {
  return [
    {
      xsCol: 6,
      fields: [
        { name: "TestItem", label: "Long Name", xsCol: 8 },
        {
          name: "IsArchived",
          label: "Archived",
          xsCol: 4,
          type: "switch",
        },
        { name: "TestItemCode", label: "Sort Name", xsCol: 6 },

        { name: "XmlTag", label: "XML Name", xsCol: 6 },
        {
          name: "TestItemTypeId",
          label: "Type",
          xsCol: 12,
          type: "dropdown",
          required: true,
          selectOptions: (testItemTypes || []).map((x) => ({
            value: x.TestItemTypeId,
            text: x.TestItemType,
          })),
        },
        {
          name: "ShowCodeOnReports",
          label: "Also show Short Name on Reports. e.g. Iron(Fe)",
          type: "switch",
          xsCol: 12,
        },
        { name: "DisplayUnits", label: "Units", xsCol: 6 },
        { name: "DisplayStandard", label: "Method", xsCol: 6 },
        {
          name: "DisplayDescription",
          label: "Description",
          xsCol: 12,
          type: "textarea",
        },
        {
          name: "ShowOnReports",
          label: "Report",
          xsCol: 12,
          type: "dropdown",
          optionsArray: [
            "Show when item has a value and in test set",
            "Hide test item on all reports",
            "Always show test item DESPITE test set",
          ],
        },
        {
          name: "TestItemColour",
          label: "Chart Colour",
          xsCol: 12,
          disabled: (data) => !data.IsNumeric || data.LimitToRepresentations,
          type: "dropdown",
          selectOptions: (Colors || []).map((x) => ({
            value: x.TestItemColour,
            text: x.TestItemColour,
          })),
          sx: {
            [`.MuiSelect-select`]: {
              backgroundColor: data?.TestItemColour,
              color: data?.TestItemColour ? "white" : "",
              [`.MuiSvgIcon-root-MuiSelect-icon`]: {
                color: data?.TestItemColour ? "white" : "",
              },
            },
            [`.MuiSelect-icon`]: {
              color: data?.TestItemColour ? "white" : "",
            },
          },
        },
        {
          name: "DisplayOption",
          label: "Formatting",
          xsCol: 12,
          type: "dropdown",
          required: true,
          optionConfigs: {
            name: "FormattingOptions",
          },
        },
        {
          name: "IsNumeric",
          label: "Numeric. can be graphed",
          xsCol: 12,
          type: "switch",
        },
        {
          name: "LimitOption",
          label: "Limit Options",
          xsCol: 12,
          type: "dropdown",
          required: true,
          selectOptions: ["Upper", "Both", "Lower"].map((x) => ({
            text: x,
            value: x,
          })),
        },
      ],
    },
    {
      xsCol: 6,
      fields: [
        {
          name: "LimitToRepresentations",
          label: "Use Textual Representation ONLY",
          xsCol: 12,
          type: "switch",
        },
        {
          name: "DisplayRank",
          label: "Report Order",
          xsCol: 4,
          type: "number",
        },
        {
          name: "LaboratoryRank",
          label: "Laboratory",
          xsCol: 4,
          type: "number",
        },
        {
          name: "TechnicianRank",
          label: "Technician",
          xsCol: 4,
          type: "number",
        },
        {
          name: "LessThanDisplay",
          label: "< X Display",
          disabled: (data) => !data.IsNumeric || data.LimitToRepresentations,
          xsCol: 6,
        },
        {
          name: "LessThanGraph",
          label: "< X Chart",
          disabled: (data) => !data.IsNumeric || data.LimitToRepresentations,
          xsCol: 6,
          type: "number",
        },
        {
          name: "GreaterThanDisplay",
          label: "> X Display",
          disabled: (data) => !data.IsNumeric || data.LimitToRepresentations,
          xsCol: 6,
        },
        {
          name: "GreaterThanGraph",
          label: "> X Chart",
          disabled: (data) => !data.IsNumeric || data.LimitToRepresentations,
          xsCol: 6,
          type: "number",
        },
        {
          xsCol: 6,
          component: function () {
            return <Typography>E.g for {"<. display= <1.chart=0"}</Typography>;
          },
        },
        {
          xsCol: 6,
          component: function () {
            return (
              <Button
                variant="outlined"
                //  onClick={view}

                startIcon={<FormatColorFill />}
              >
                Fill (F10)
              </Button>
            );
          },
        },
        {
          xsCol: 12,
          component: function (props) {
            return <TextualRepresentation {...props} />;
          },
        },
      ],
    },
  ];
};

export const FormattingOptions = [
  { value: -1, text: "None" },
  { value: 0, text: "0  decimal places" },
  { value: 1, text: "1  decimal place" },
  { value: 2, text: "2  decimal places" },
  { value: 3, text: "3  decimal places" },
  { value: 4, text: "4  decimal places" },
  { value: 10, text: "dd/dd  or  dd/dd/dd  for Particle Count" },
  { value: 12, text: "dd/d-d  for Corrosion" },
];

export const TestItemActions = {
  LOAD_DATA: "load-data",
  ADD_TEXT_REP: "add-text-rep",
  REMOVE_TEXT_REP: "remove-text-rep",
  DIRTY_TEXT_REP: "dirty-text-rep",
};
function TestSetReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    if (action.payload.name === "IsSystemTestSet" && action.payload.value) {
      state.UseAutoEvaluation = false;
    }
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === TestItemActions.LOAD_DATA) {
    return action.payload;
  }

  if (action.type === TestItemActions.ADD_TEXT_REP) {
    const reps = state.Representations || [];
    const maxId = reps.length
      ? Math.max(...reps.map((o) => o.TestItemRepresentationId))
      : 0;
    return {
      ...state,
      Representations: [
        ...reps,
        { TestItemRepresentationId: maxId + 1, isNew: true },
      ],
    };
  }
  if (action.type === TestItemActions.REMOVE_TEXT_REP) {
    const reps = (state.Representations || []).map((x) =>
      x.TestItemRepresentationId === action.payload
        ? { ...x, deleted: true }
        : x
    );
    return {
      ...state,
      Representations: reps,
    };
  }
  if (action.type === TestItemActions.DIRTY_TEXT_REP) {
    const reps = (state.Representations || []).map((x) =>
      x.TestItemRepresentationId === action.payload.TestItemRepresentationId
        ? action.payload
        : x
    );
    return { ...state, Representations: reps };
  }
  console.warn(
    `actions.type must have a value currently it is ${action.type}`,
    action
  );
  return state;
}
