import { Button, Checkbox, FormControlLabel, Grid, Typography, } from "@mui/material";
import Form, { FormActions } from "../components/Form";
import { useReducer } from "react";
import { useState } from "react";
import { useRoot } from "../rootContext";
import { useEffect } from "react";
import useFetch from "../hooks/useFetch";
import { DETAILS_APIS } from "../common/apis";

export default function UpdateTrayLabel({ rowData, cancel, onView }) {

    const { originalLabs } = useRoot()
    const { post } = useFetch()
    const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);
    const [isTraining, setIsTraining] = useState(false)

    const handleSubmit = async () => {
        const postData = { "Laboratory": data?.LaboratoryId, "OwnerLaboratory": data?.OwnerLaboratoryId, "Tray": data?.Tray, "TrayDate": data?.TrayDate, "Label": data?.Label, "IsTraining": isTraining }
        const resp = await post(DETAILS_APIS.EDIT_TRAY_LABEL, postData)
        if (resp > 0) {
            onView && onView()
            cancel()
        }
    }

    useEffect(() => {
        dispatch({ type: EventActions.LOAD_DATA, payload: { ...rowData } });
        setIsTraining(rowData?.IsTrainingSample);
    }, [rowData])

    return (
        <>
            <Grid container spacing={2} sx={{ p: 1, width: "30vw" }}>
                <Grid item xs={12}>
                    <Typography sx={{ color: "green" }}>Tray {rowData?.Tray} for {rowData?.Laboratory}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Form
                        sections={GetFormSections(isTraining, setIsTraining, handleSubmit, originalLabs)}
                        data={data}
                        spacing={2}
                        dispatch={dispatch}
                        key={
                            (data.LaboratoryId || "") +
                            (data._ts || "")
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
}

function GetFormSections(isTraining, setIsTraining, handleSubmit, originalLabs) {
    return [
        {
            fields: [
                {
                    name: "Label",
                    label: "Tray Label",
                    type: "text",
                    xsCol: 8,
                },
                {
                    xs: 12,
                    component: function () {
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isTraining"
                                        checked={isTraining}
                                        onClick={() => setIsTraining(!isTraining)}
                                        sx={{ [`.MuiSvgIcon-root`]: { fontSize: "18px" } }}
                                    />
                                }
                                label="Tray Contains Training Sample"
                                sx={{ margin: 0 }} />
                        );
                    },
                },
                {
                    label: "Tray Owner",
                    name: "LaboratoryId",
                    type: "dropdown",
                    xsCol: 8,
                    alignSelf: "end",
                    required: true,
                    selectOptions: (originalLabs || []).map((x) => ({
                        value: x.LaboratoryId,
                        text: x.Laboratory,
                    })),
                },
                {
                    alignSelf: "end",
                    xs: 4,
                    component: function () {
                        return (
                            <><Button sx={{ float: "right" }} onClick={handleSubmit}>Save</Button></>
                        );
                    },
                },
            ]
        }]
}

const EventActions = {
    LOAD_DATA: "load-data",
};

function SearchFilterReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    if (action.type === EventActions.LOAD_DATA) {
        return {
            ...action.payload, _ts: Date.now(),
        };
    }
    return state;
}

const SearchFilterDefaults = {
    Label: "",
    LaboratoryId: 2
};