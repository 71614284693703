import { Add, } from "@mui/icons-material";
import { Card, Grid, Typography } from "@mui/material";
import { useReducer, } from "react";
import Form, { FormActions } from "../../../../components/Form";
import FormFooter from "../../../../components/FormFooter";

export default function ContactSubscriptionMapping() {

  const [data, dispatch] = useReducer(SearchFilterReducer, defaultProducts);

  const formActions = {
    newContact: function (e) { },
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: "14px", marginBottom: "16px" }}>
            Select the reporting options and alert level for this mapping
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
            // key={data.ProductId}
            />

            <FormFooter
              buttons={GetFormButtons(formActions)}
              hideDivider
              gmt={0}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections() {
  return [
    {
      fields: [
        {
          group: [
            {
              name: "Monthly",
              label: "Monthly",
              type: "radio",
              xsCol: 3,
            },

            {
              name: "Weekly",
              label: "Weekly",
              type: "radio",
              xsCol: 3,
            },
          ],
        },
        {
          group: [
            {
              name: "Report",
              label: "Report",
              type: "dropdown",
              xsCol: 4,
              required: true,
              selectOptions: getOptions(),
            },
          ],
        },
      ],
    },
  ];
}
function getOptions() {
  return [
    { Id: 0, value: 0, text: "Summary Report (PDF)" },
    { Id: 1, value: 1, text: "Daily Report (XLSX)" },
  ];
}
const defaultProducts = {
  ShowArchived: true,
};

const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
const GetFormButtons = ({ newContact }) => [
  {
    label: "Save",
    onClick: newContact,
    startIcon: <Add />,
  },
];
