import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { FLEET_APIS } from "../../../../common/apis";
import useFetch from "../../../../hooks/useFetch";
import SearchComponent from "../search/SearchComponent";
import { useState } from "react";
import { useEffect } from "react";

const steps = [
  "Select Action",
  "Select Destination Component",
  "Action Summary",
];

export default function ManageSample({ unit, close, handleSearchTree }) {

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedUnit, setSelectedUnit] = React.useState({});
  const [skipped, setSkipped] = React.useState(new Set());
  const [processResponse, setProcessResponse] = React.useState("Process completed successfully.");
  const [selectedOption, setSelectedOption] = React.useState("moveSample");
  const [sampleDetails, setSampleDetails] = useState({})

  const { get } = useFetch();

  const getSampleDetails = async () => {
    const data = await get(`/details/sample?sampleNo=${unit?.SampleNumber}`);

    const respData = {
      ProductId: data?.MasterData?.Component?.ProductId,
      Product: data?.MasterData?.Component?.Product,
      Site: data?.MasterData?.Component?.Site,
      Customer: data?.MasterData?.Component?.Customer,
      Unit: data?.MasterData?.Component?.Unit,
      Component: data?.MasterData?.Component?.Component,
      ComponentId: data?.MasterData?.Component?.ComponentId,
      Serial: data?.MasterData?.Component?.Serial,
      Sample: data?.Sample?.SampleNumber
    }
    setSampleDetails(respData)
  }

  useEffect(() => { getSampleDetails() }, [])

  async function PerformOperation() {
    if (selectedOption === "moveSample") {
      const treeData = { ProductId: selectedUnit?.ProductId, StartLetter: selectedUnit?.StartLetter, CustomerId: selectedUnit?.CustomerId, SiteId: selectedUnit?.SiteId, UnitId: selectedUnit?.UnitId, ComponentId: selectedUnit?.ComponentId }
      const result = await get(`${FLEET_APIS.MANAGE_SAMPLE}?SampleNumber=${unit?.SampleNumber}&ComponentId=${selectedUnit?.ComponentId}&actionToTake=${1}&Screen=Sample Management,`);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData)
      }
    }
    else if (selectedOption === "deleteSample") {
      const treeData = { ProductId: unit?.ProductId, StartLetter: unit?.StartLetter, CustomerId: unit?.CustomerId, SiteId: unit?.SiteId, UnitId: unit?.UnitId, ComponentId: unit?.ComponentId }
      const result = await get(`${FLEET_APIS.MANAGE_SAMPLE}?SampleNumber=${unit?.SampleNumber}&ComponentId=${sampleDetails?.ComponentId}&actionToTake=${2}&Screen=Sample Management,`);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData)
      }
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 2) {
      PerformOperation();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };
  const handleBack = () => {
    if (selectedOption === "deleteSample") {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
      setSelectedOption("deleteOriginal");
      setSelectedUnit({});
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedUnit({});
    }
  };

  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleReset = () => {
    setActiveStep(0);
    close();
    // refreshGrid();
  };

  // Table's Styling
  const tableCol2 = {
    background: "lightblue",
    color: "blue",
  };
  const tableCol3 = {
    background: "lightgreen",
    color: "green",
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="moveSample"
              name="manageAction"
              onChange={handleRadioGroupChange}
            >
              <FormControlLabel
                value="moveSample"
                control={<Radio />}
                label="Move the sample to a different component"
              />
              <FormControlLabel
                value="deleteSample"
                control={<Radio />}
                label="Delete Sample"
              />
            </RadioGroup>
          </FormControl>
        );
      case 1:
        return selectedOption === "deleteSample" ? (
          handleNext()
        ) : (
          <SearchComponent
            selectedUnit={sampleDetails}
            rowSelected={(row) => setSelectedUnit(row)}
          />
        );
      case 2:
        return selectedOption === "deleteSample" ? (
          <>
            <Typography sx={{ mb: 1 }}>
              The Sample {unit?.SampleNumber} will be DELETED
            </Typography>
            <TableContainer sx={{ mb: 1 }} component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{sampleDetails?.Product || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{sampleDetails?.Customer || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Site
                    </TableCell>
                    <TableCell sx={tableCol2}>{sampleDetails?.Site || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Unit
                    </TableCell>
                    <TableCell sx={tableCol2}>{sampleDetails?.Unit || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Component
                    </TableCell>
                    <TableCell sx={tableCol2}>
                      {sampleDetails?.Component || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sample Number
                    </TableCell>
                    <TableCell sx={tableCol2}>
                      {unit?.SampleNumber || ""}
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                    {/* Col 3 */}
                    <TableCell sx={tableCol3}>Destination</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{sampleDetails?.Product || ""}</TableCell>
                    <TableCell sx={tableCol3}>
                      {selectedUnit?.Product}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{sampleDetails?.Customer || ""}</TableCell>
                    <TableCell sx={tableCol3}>
                      {selectedUnit?.Customer}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Site
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.Site || ""}</TableCell>
                    <TableCell sx={tableCol3}>
                      {selectedUnit?.Site || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Unit
                    </TableCell>
                    <TableCell sx={tableCol2}>{sampleDetails?.Unit || ""}</TableCell>
                    <TableCell sx={tableCol3}>
                      {selectedUnit?.Unit || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Component
                    </TableCell>
                    <TableCell sx={tableCol2}>
                      {sampleDetails?.Component || ""}
                    </TableCell>
                    <TableCell sx={tableCol3}>
                      {selectedUnit?.Component || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      ComponentId
                    </TableCell>
                    <TableCell sx={tableCol2}>
                      {unit?.ComponentId || ""}
                    </TableCell>
                    <TableCell sx={tableCol3}>
                      {selectedUnit?.ComponentId || ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      default:
        return "Unknown step";
    }
  }
  return (
    <Box
      sx={{
        padding: "10px",
        minWidth: "70vw",
        minHeight: "23vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stepper activeStep={activeStep} sx={{ mr: 2 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: "-20%" }}>{processResponse}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button onClick={handleReset} sx={{ ml: "auto", mr: 1 }}>
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={ClassNames.instructions} sx={{ mt: 4 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              onClick={handleNext}
              disabled={activeStep === 1 && !selectedUnit?.Customer}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
