import React, { createContext, useContext, useState } from "react";

var RootContext = createContext(null);

export const useRoot = () => useContext(RootContext);
export const useUser = () => {
  const { user } = useContext(RootContext);
  return user;
};
export const useSetActiveTab = () => {
  const { setActiveTab } = useContext(RootContext);
  return (tabKey) => setActiveTab(tabKey);
};
export const useBlockScreen = () => {
  const { setScreenBlocked } = useContext(RootContext);
  return {
    block: () => setScreenBlocked(true),
    unblock: () => setScreenBlocked(false),
  };
};

export const useErrorBox = () => {
  const { setErrorText } = useContext(RootContext);
  return (msg) => setErrorText(msg);
};

export const useToast = () => {
  const { setToastOption } = useContext(RootContext);
  return {
    showToast: (text) =>
      setToastOption({ text, severity: "success", open: true }),
    showInfoToast: (text) =>
      setToastOption({ text, severity: "info", open: true }),
    showErrorToast: (text) =>
      setToastOption({ text, severity: "error", open: true }),
    showWarningToast: (text) =>
      setToastOption({ text, severity: "warning", open: true }),
  };
};


export const RootProvider = ({ children }) => {

  const [activeTab, setActiveTab] = useState("");
  const [screenBlocked, setScreenBlocked] = useState(false);
  const [user, setUser] = useState({});
  const [errorText, setErrorText] = useState("");
  const [toastOptions, setToastOption] = useState({ text: "", open: false });
  const [products, setProducts] = useState();
  const [laboratories, setLaboratories] = useState();
  const [allLaboratories, setAllLaboratories] = useState();
  const [originalLabs, setOriginalLabs] = useState();
  const [allProducts, setAllProducts] = useState();
  const [testItemTypes, setTestItemTypes] = useState();
  const [componentFluidTypes, setComponentFluidTypes] = useState();
  const [fluids, setFluids] = useState();
  const [fluidTypes, setFluidTypes] = useState();
  const [parentsAll, setParentsAll] = useState();

  let CanDoDataMaintenance = (
    (user?.RoleRegistration === true && user?.RoleEvaluationTrainee !== true)
    || (user?.RoleEvaluation === true && user?.RoleEvaluationTrainee !== true)
    || user?.RoleAdministration || user?.RoleDeveloper); //CanManageContacts

  let CanCreateTrays = (user?.RoleLaboratory || user?.RoleDeveloper || user?.RoleRegistration || user?.RoleAdministration);

  let CanRegister = (user?.RoleRegistration || user?.RoleAdministration || user?.RoleDeveloper);

  let CanQualityCheckTrays = (user?.RoleQualityCheck || user?.RoleAdministration || user?.RoleDeveloper);

  let CanLaboratoryTrays = (user?.RoleLaboratory || user?.RoleAdministration || user?.RoleDeveloper);

  let CanDevelop = user?.RoleDeveloper;

  let CanEvaluate = (user?.RoleEvaluation || user?.RoleEvaluationTrainee === true || user?.RoleAdministration || user?.RoleDeveloper);

  let CanEvaluateNotTrainee = (user?.RoleEvaluation === true && user?.RoleEvaluationTrainee === false) || user?.RoleAdministration || user?.RoleDeveloper;//CanApproveSamples

  let CanEvaluateTrainee = (user?.RoleEvaluation === true && user?.RoleEvaluationTrainee === true);

  let CanViewEvaluations = user?.RoleLaboratory;

  let CanDoSpecialReports = user?.RoleSpecialReports || user?.RoleAdministration || user?.RoleDeveloper;

  let CanManageMachineTemplates = user?.RoleMachineTemplates || user?.RoleAdministration || user?.RoleDeveloper;

  let CanManageTestSets = user?.RoleTestSets || user?.RoleAdministration || user?.RoleDeveloper;

  let CanImportHistory = user?.RoleImportHistory || user?.RoleAdministration || user?.RoleDeveloper;

  let CanAdministrate = user?.RoleAdministration || user?.RoleDeveloper;

  return (
    <RootContext.Provider
      value={{
        activeTab,
        setActiveTab,
        screenBlocked,
        setScreenBlocked,
        user,
        setUser,
        setErrorText,
        errorText,
        toastOptions,
        setToastOption,
        products,
        setProducts,
        allProducts,
        setAllProducts,
        laboratories,
        setLaboratories,
        allLaboratories,
        setAllLaboratories,
        originalLabs,
        setOriginalLabs,
        testItemTypes,
        setTestItemTypes,
        componentFluidTypes,
        setComponentFluidTypes,
        fluids,
        setFluids,
        fluidTypes,
        setFluidTypes,
        parentsAll,
        setParentsAll,

        CanDoDataMaintenance,
        CanCreateTrays,
        CanRegister,
        CanQualityCheckTrays,
        CanLaboratoryTrays,
        CanDevelop,
        CanEvaluate,
        CanEvaluateNotTrainee,
        CanEvaluateTrainee,
        CanViewEvaluations,
        CanDoSpecialReports,
        CanManageMachineTemplates,
        CanManageTestSets,
        CanImportHistory,
        CanAdministrate
      }}
    >
      {children}
    </RootContext.Provider>
  );
};
