import {
  Button,
  InputLabel,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useState } from "react";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import { TRAY_APIS } from "../../../common/apis";
import { RerunSampleIcon } from "../../../icons/ButtonIcons";
import moment from "moment";
import { useEffect } from "react";

export default function RerunToLaboratory({
  sampleId,
  reLoadViewHandler,
  handleClose,
  sampleData,
  sampleDate,
  screenName,
  option
}) {

  const sampleNumber = sampleId;
  const { post } = useFetch();

  const [commentValue, setCommentValue] = useState(
    "Returned to laboratory as "
  );
  const [selectedValue, setSelectedValue] = useState("1");

  useEffect(() => {
    if (option !== undefined) {
      setSelectedValue(option)
    }
  }, [])

  //hold sample click
  function returnSampleClick() {
    (async function () {
      if (!sampleNumber === 0) return;
      if (screenName === "Quality Check") {
        if (selectedValue === "1") {
          let resp = await post(`${TRAY_APIS.QUALITY_CHECK_RERUN_SAMPLE}`, { SampleNumber: Number(sampleNumber), Reason: commentValue });
          if (resp > -1) {
            handleClose && handleClose(false);
          }
        }
        if (selectedValue === "2") {
          let resp = await post(`${TRAY_APIS.QUALITY_CHECK_RERUN_TRAY}`, { SampleNumber: Number(sampleNumber), Reason: commentValue });
          if (resp > -1) {
            handleClose && handleClose(false);
          }
        }
      }
      if (screenName === "Evaluation") {
        if (selectedValue === "1") {
          let resp = await post(`${TRAY_APIS.QUALITY_CHECK_RERUN_SAMPLE}`, { SampleNumber: Number(sampleNumber), Reason: commentValue });
          if (resp > -1) {
            handleClose && handleClose(false);
            reLoadViewHandler && reLoadViewHandler();
          }
        }
        if (selectedValue === "2") {
          let resp = await post(`${TRAY_APIS.QUALITY_CHECK_RERUN_SAMPLE}`, { SampleNumber: Number(sampleNumber), Reason: commentValue });
          if (resp > -1) {
            handleClose && handleClose(false);
            reLoadViewHandler && reLoadViewHandler();
          }
        }
      }
    })();
  }

  return (
    <>
      <Grid container spacing={2} sx={{ p: 1, width: 1200 }}>
        <Grid item xs={2}>
          <Typography variant="subtitle2" style={{ marginTop: "6px" }}>
            Return to Laboratory
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid item container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedValue === "1"}
                    onClick={() => setSelectedValue("1")}
                    name="Sample"
                    size="small"
                    value="1"
                  />
                }
                sx={{ mt: "2px" }}
                label={"Sample " + sampleNumber}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={selectedValue === "2"}
                    onClick={() => setSelectedValue("2")}
                    value="2"
                    name="additional"
                    size="small"
                  />
                }
                sx={{ mt: "2px" }}
                // label="No additional samples are available "
                label={`All available samples in tray ${sampleData?.Tray} on ${moment.utc(sampleDate).local().format("dddd DD MMM  YYYY")} for ${sampleData?.Laboratory}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel style={{ marginTop: "15px" }}>Reason</InputLabel>
          <TextBox
            multiline
            rows={5}
            value={commentValue}
            // disabled={selectedValue === "2"}
            onChange={(v) => setCommentValue(v.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container direction="row-reverse" spacing={2}>
        <Grid item>
          <Button
            item
            variant="contained"
            startIcon={<RerunSampleIcon />}
            style={{ marginTop: "5px" }}
            // disabled={selectedValue === "2"}
            onClick={returnSampleClick}
          >
            Return and Rerun
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
