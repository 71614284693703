import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { TRAY_APIS } from "../../../common/apis";
import { SearchIcon } from "../../../icons/ButtonIcons";
import { useUser } from "../../../rootContext";

export default function InsiteMachineSearch({ selectCheck, userData }) {
  const user = useUser();

  const SearchFilterDefaults = {
    Make: userData?.UnitMakeOriginal || userData?.UnitMake || "KOMATSU",
    Model: userData?.UnitModelOriginal || userData?.UnitModel,
    Serial: userData?.SerialOriginal || userData?.Serial,
    FleetNumber: "",
    userName: user.Username,
  };

  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const { post, } = useFetch();

  const [feedbackData, setFeedbackData] = useState([]);
  // const [contactId, setContactId] = useState();
  // const [propId, setPropId] = useState();
  useEffect(() => {
    document.title = "Insite Machine Search";
  }, []);
  //call api for search audit data
  function search() {
    (async function () {
      let resData = await post(TRAY_APIS.MACHINE_INSITE, {
        ...data,
      });
      if (resData) {
        setFeedbackData(resData);
      }
    })();
  }

  const tblOptions = {
    columns: GetColumns(feedbackData, selectCheck),

    dataSource: feedbackData || [],
    rowIdProperty: "MachineId",
    // isDisableColumnFilter: true,
    sx: { height: "70vh" },
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1, minWidth: "80vw" }}>
            <Form
              sections={GetFormSections(search, data)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            sx={{ marginLeft: "5px", marginTop: "10px" }}
          >
            Total Records: <b>{feedbackData && feedbackData.length}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(selectCheck) {
  return [
    {
      hide: !!selectCheck,
      headerName: "Select",
      field: "Select",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={() => alert("hello")}
              label="Edit"
            />
          </>
        );
      },
    },
    {
      headerName: "Make",
      field: "Make",
      minWidth: 150,
    },
    {
      headerName: "Model",
      field: "Model",
      minWidth: 150,
    },
    {
      headerName: "Serial",
      field: "Serial",
      minWidth: 150,
    },
    {
      headerName: "Fleet Number",
      field: "FleetNo",
      minWidth: 150,
    },
    {
      headerName: "SMR",
      field: "SMR",
      minWidth: 150,
    },
    {
      headerName: "SMR Date",
      field: "SmrDate",
      minWidth: 150,
    },
    {
      headerName: "Customer",
      field: "Customer",
      width: "auto",
    },
  ];
}

function GetFormSections(search, data) {
  return [
    {
      fluid: true,
      fields: [
        {
          xsCol: 12,
          group: [
            {
              name: "Make",
              label: "Make",
            },
            {
              name: "Model",
              label: "Model",
            },
            {
              name: "Serial",
              label: "Serial",
            },
            {
              name: "FleetNumber",
              label: "Fleet No.",
            },

            {
              component: function () {
                return (
                  <Button
                    variant="contained"
                    onClick={search}
                    sx={{ mt: "17px" }}
                    disabled={
                      data?.Make == "" &&
                        data?.Model == "" &&
                        data?.Serial == "" &&
                        data?.FleetNumber == ""
                        ? true
                        : false
                    }
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
