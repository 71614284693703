import * as React from "react";
import { Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import { Edit, } from "@mui/icons-material";
import { useState } from "react";
import AppDialog from "../../../components/AppDialog";
import SearchContact from "../SearchContact";

export default function PrimaryContact({ data }) {
  const dataArr = [
    data.Contact1 || {},
    data.Contact2 || {},
    data.Contact3 || {},
  ];
  const [open, setOpen] = useState(false);

  const dataObj = {};
  dataArr.forEach((x, i) => {
    Object.keys(x).forEach((key, j) => {
      dataObj[key + (i + 1)] = x[key];
    });
  });
  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections}
          data={dataObj}
          key={data && data.SiteId}
        />
        <FormFooter buttons={GetFormButtons(setOpen)} />
      </Card>

      <AppDialog
        open={open}
        title="Edit Site"
        maxWidth="lg"
        handleClose={() => setOpen(false)}
      >
        <SearchContact
          cancel={() => setOpen(false)}
          siteId={data.SiteId}
          customerId={data?.CustomerId}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (setOpen) => [
  { variant: "contained", label: "Edit", startIcon: <Edit />, onClick: () => { setOpen(true) } },
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Primary Contact" />;
        },
      },
    ],
  },
  {
    xsCol: 4,
    fields: [
      { name: "Forename1", label: "Primary 1", xsCol: 6 },
      { name: "Surname1", alignSelf: "flex-end", xsCol: 6 },
      { name: "Username1", label: "Username", xsCol: 12 },
      { name: "Telephone1", label: "Phone", xsCol: 12 },
      { name: "Mobile1", label: "Mobile", xsCol: 12 },
      { name: "Street11", label: "Address 1", xsCol: 12 },
      { name: "Street21", label: "Address 2", xsCol: 12 },
      { name: "City1", label: "Town", xsCol: 12 },
      { name: "PostCode1", label: "Postcode", xsCol: 6 },
      { name: "State1", label: "State", xsCol: 6 },
      { name: "Country1", label: "Country", xsCol: 12 },
    ],
  },
  {
    xsCol: 4,
    fields: [
      { name: "Forename2", label: "Primary 2", xsCol: 6 },
      { name: "Surname2", alignSelf: "flex-end", xsCol: 6 },
      { name: "Username2", label: "Username", xsCol: 12 },
      { name: "Telephone2", label: "Phone", xsCol: 12 },
      { name: "Mobile2", label: "Mobile", xsCol: 12 },
      { name: "Street12", label: "Address 1", xsCol: 12 },
      { name: "Street22", label: "Address 2", xsCol: 12 },
      { name: "City2", label: "Town", xsCol: 12 },
      { name: "PostCode2", label: "Postcode", xsCol: 6 },
      { name: "State2", label: "State", xsCol: 6 },
      { name: "Country2", label: "Country", xsCol: 12 },
    ],
  },
  {
    xsCol: 4,
    fields: [
      { name: "Forename3", label: "Primary 3", xsCol: 6 },
      { name: "Surname3", alignSelf: "flex-end", xsCol: 6 },
      { name: "Username3", label: "Username", xsCol: 12 },
      { name: "Telephone3", label: "Phone", xsCol: 12 },
      { name: "Mobile3", label: "Mobile", xsCol: 12 },
      { name: "Street13", label: "Address 1", xsCol: 12 },
      { name: "Street23", label: "Address 2", xsCol: 12 },
      { name: "City3", label: "Town", xsCol: 12 },
      { name: "PostCode3", label: "Postcode", xsCol: 6 },
      { name: "State3", label: "State", xsCol: 6 },
      { name: "Country3", label: "Country", xsCol: 12 },
    ],
  },
];
