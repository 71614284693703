import { Card, Grid, Typography, } from "@mui/material";
import { useEffect, useMemo, useReducer, useState } from "react";
import { CRITICAL_LOGS_APIS, FLEET_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";

import {
  EventReducer,
  FormSections,
  SearchFilterDefaults,
} from "./SampleReportsOverviewForm";
import SampleReportsOverviewFormTable from "./SampleReportsOverviewFormTable";
import useFetch from "../../../hooks/useFetch";
import SearchContact from "../../quick-details/SearchContact";
import Form from "../../../components/Form";

export default function SampleReportsOverview({
  formData,
  rowDataProps,
  setOpenDialog,
}) {

  const { post, postFile } = useFetch();
  const [data, dispatch] = useReducer(EventReducer, SearchFilterDefaults);
  const [searchContactDialogueOpen, setSearchContactDialogueOpen] = useState(false);
  const [sampleReportsData, setSampleReportsData] = useState([]);
  const [customer, setCustomer] = useState("")
  function searchContactScope() {
    setSearchContactDialogueOpen(true);
  }

  const handleExportContacts = async () => {
    let resp = await postFile(CRITICAL_LOGS_APIS.EXPORT_CONTACTS_FOR_SCOPE, {
      ParentId: formData?.ParentId || 0,
      ProductId: formData?.ProductId || 0,
    });
    const blob = await resp.blob();
    const url = URL.createObjectURL(blob);
    return window.open(url);
  }

  // const [rowData, setRowData] = useState([]);

  function rowSelectedHandler(rowData) {
    rowDataProps(rowData);
  }

  const filteredData = useMemo(() => {
    if (data) {
      return (sampleReportsData || []).filter((x) => {
        let filterMatch = true;
        if (data.Filter) {
          const filterLower = data.Filter.toLowerCase();
          filterMatch =
            x.DisplayName?.toLowerCase().includes(filterLower) ||
            x.Username?.toLowerCase().includes(filterLower) ||
            x.Company?.toLowerCase().includes(filterLower);
        }

        const alertCondition =
          !data.showAllAlerts && parseInt(data.AlertsFrom) <= x.Level;

        return (
          filterMatch &&
          ((filterMatch && data.Komatsu && x.IsKomatsu) ||
            (data.Other && !x.IsKomatsu)) &&
          ((data.Product && x.LevelText === "Product") ||
            (data.SiteSet && x.LevelText === "Site Set") ||
            (data.Customer && x.LevelText === "Customer") ||
            (data.Site && x.LevelText === "Site") ||
            (data.Unit && x.LevelText === "Unit") ||
            (data.Parent && x.LevelText === "Parent") ||
            (data.Unit && x.LevelText === "Unit") ||
            (data.Component && x.LevelText === "Component")) &&
          (data.showAllAlerts || alertCondition)
        );
      });
    } else {
      return sampleReportsData;
    }
  }, [data, sampleReportsData]);
  // const filteredData = useMemo(
  //   () =>
  //     data
  //       ? (sampleReportsData || []).filter((x) => {
  //           let filterMatch = true;
  //           if (data.Filter) {
  //             const filterLower = data.Filter.toLowerCase();
  //             filterMatch =
  //               x.DisplayName?.toLowerCase().includes(filterLower) ||
  //               x.Username?.toLowerCase().includes(filterLower) ||
  //               x.Company?.toLowerCase().includes(filterLower);
  //           }
  //           return (
  //             ((filterMatch && data.Komatsu && x.IsKomatsu) ||
  //               (data.Other && !x.IsKomatsu)) &&
  //             ((data.Product && x.LevelText === "Product") ||
  //               (data.SiteSet && x.LevelText === "Site Set") ||
  //               (data.Customer && x.LevelText === "Customer") ||
  //               (data.Site && x.LevelText === "Site") ||
  //               (data.Unit && x.LevelText === "Unit") ||
  //               (data.Parent && x.LevelText === "Parent") ||
  //               (data.Unit && x.LevelText === "Unit") ||
  //               (data.Component && x.LevelText === "Component"))
  //           );
  //         })
  //       : sampleReportsData,
  //   [data, sampleReportsData]
  // );

  useEffect(() => {
    (async function () {
      let parseData = {};
      let formDatas = localStorage.getItem("report-contacts")

      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas)
        setCustomer(parseData?.Customer)
      }
      let resData = await post(FLEET_APIS.CONTACT_FOR_LINKAGE, {
        //   SampleNumber: formData.SampleNumber,
        ComponentId: formData?.ComponentId || parseData?.ComponentId || 0,
        CustomerId: formData?.CustomerId || parseData?.CustomerId || 0,
      });
      setSampleReportsData(resData);
      localStorage.removeItem("report-contacts");
    })();
  }, [formData?.SampleNumber]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={FormSections(searchContactScope, handleExportContacts)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length} </b> for customer {formData?.Customer || customer}
          </Typography>
          <SampleReportsOverviewFormTable
            setOpenDialog={setOpenDialog}
            data={filteredData}
            rowSelected={rowSelectedHandler}
          />
        </Grid>
      </Grid>
      <AppDialog
        open={searchContactDialogueOpen}
        handleClose={() => setSearchContactDialogueOpen(false)}
        title="Search Contacts"
        maxWidth="100vw"
      >
        <SearchContact />
      </AppDialog>
    </>
  );
}
