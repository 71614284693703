import { useState } from "react";
import { DataGridAction } from "./../../../../components/DataTable";
import DataTable from "./../../../../components/DataTable";
import AppDialog from "../../../../components/AppDialog";
import EditContacts from "../../../../forms/EditContacts";
import ContactSampleReportingMapping from "../contact-permission-overview/ContactSampleReportingMapping";
import ContactAccessPermission from "../contact-permission-overview/ContactAccessPermission";
import ContactSubscriptionMapping from "../contact-permission-overview/ContactSubscriptionMapping";
import ContactPrimary from "../contact-permission-overview/ContactPrimary";
import renderCellExpand from "../../../../components/DatatableCellExpand";

export default function AccessPermissionOverviewTable({
  data,
  rowSelected,
  setOpenDialog,
  setDialogClose,
  ...props
}) {
  const [row, setRow] = useState({});
  const [propId, setPropId] = useState();
  const [dialog, setDialog] = useState({
    open: false,
    title: "",
  });
  const options = {
    ...props,
    columns: GetColumns(
      selectClick,
      editClick,
      accessClick,
      reportsClick,
      subscriptionsClick,
      primaryClick
    ),
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp:
        x.ContactId +
        "" +
        (x.Unit || "") +
        "" +
        (x.Site || "") +
        (x.Scope || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "accessPermissionOverviewForm",
    sx: { height: 600 },
    // rowsSelected: function (r) {
    //   r.length && rowSelected && rowSelected(r[0]);
    // },
  };

  return (
    <>
      <DataTable {...options} />
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
        title={dialog.title}
        maxWidth="xl"
      >
        {dialog.title === "Edit Contact" && (
          <EditContacts
            contactId={row.ContactId}
            setEditContactsDialog={setDialog}
          />
        )}
        {dialog.title === `Contact Primary Mappings for ${row.Username}` && (
          <ContactPrimary contactId={row.ContactId} />
        )}
        {(dialog.title === `Contacts Access Permissions for ${row.Username}` ||
          dialog.title ===
            `Contact Sample Reporting for ${row.DisplayName} ${row.Username}` ||
          dialog.title === `Contact Subscriptions for ${row.Username}`) && (
          <ContactAccessPermission
            contactId={row.ContactId}
            propId={propId}
            username={row && row.DisplayName}
          />
        )}

        {/* {dialog.title ===
          `Contact Sample Reporting for ${row.DisplayName} ${row.Username}` && (
          <ContactSampleReportingMapping />
        )}

        {dialog.title === `Contact Subscriptions for ${row.Username}` && (
          <ContactSubscriptionMapping />
        )} */}
      </AppDialog>
    </>
  );
  function selectClick(row) {
    rowSelected(row);
    setDialogClose((prevState) => ({
      open: false,
    }));
  }
  function editClick(row) {
    setRow(row);
    setDialog({
      open: true,
      title: "Edit Contact",
    });
  }

  function accessClick(row) {
    setRow(row);
    setPropId(2);
    setDialog({
      open: true,
      title: `Contacts Access Permissions for ${row.Username}`,
    });
  }
  function reportsClick(row) {
    setRow(row);
    setPropId(3);
    setDialog({
      open: true,
      title: `Contact Sample Reporting for ${row.DisplayName} ${row.Username}`,
    });
  }
  function subscriptionsClick(row) {
    setRow(row);
    setPropId(4);
    setDialog({
      open: true,
      title: `Contact Subscriptions for ${row.Username}`,
    });
  }
  function primaryClick(row) {
    setRow(row);
    rowSelected(row);
    setDialog({
      open: true,
      title: `Contact Primary Mappings for ${row.Username}`,
    });
  }
}
const GetColumns = (
  selectClick,
  editClick,
  accessClick,
  reportsClick,
  subscriptionsClick,
  primaryClick
) => [
  {
    headerName: "Select",
    field: "Select",
    sortable: false,
    width: 65,

    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"55px"}
            row={row}
            onClick={selectClick}
            label="Select"
          />
        </>
      );
    },
  },
  {
    headerName: "Edit",
    field: "Edit",
    sortable: false,
    flex: 1,
    minWidth: 60,
    maxWidth: 60,

    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"50px"}
            row={row}
            onClick={editClick}
            label="Edit"
          />
        </>
      );
    },
  },
  {
    headerName: "Access",
    field: "Access",
    sortable: false,
    flex: 1,
    minWidth: 65,
    maxWidth: 65,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"55px"}
            row={row}
            onClick={accessClick}
            label="Access"
          />
        </>
      );
    },
  },
  {
    headerName: "Reports",
    field: "Reports",
    sortable: false,
    flex: 1,
    minWidth: 65,
    maxWidth: 65,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"55px"}
            row={row}
            onClick={reportsClick}
            label="Reports"
          />
        </>
      );
    },
  },
  {
    headerName: "Subscriptions",
    field: "Subscriptions",
    sortable: false,
    flex: 1,
    minWidth: 96,
    maxWidth: 96,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"80px"}
            row={row}
            onClick={subscriptionsClick}
            label="Subscriptions"
          />
        </>
      );
    },
  },
  {
    headerName: "Primary",
    field: "Primary",
    sortable: false,
    flex: 1,
    minWidth: 65,
    maxWidth: 65,
    renderCell: function ({ row }) {
      return (
        <>
          <DataGridAction
            minWidth={"55px"}
            row={row}
            onClick={primaryClick}
            label="Primary"
          />
        </>
      );
    },
  },
  {
    headerName: "Email",
    field: "Username",
    minWidth: 183,
    maxWidth: 183,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Name",
    field: "DisplayName",
    minWidth: 100,
    maxWidth: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Mobile",
    field: "Mobile",
    minWidth: 105,
    maxWidth: 115,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Telephone",
    field: "Telephone",
    minWidth: 100,
    maxWidth: 110,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Scope",
    field: "Scope",
    minWidth: 82,
    maxWidth: 82,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Customer",
    field: "Customer",
    minWidth: 80,
    maxWidth: 80,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Site",
    field: "Site",
    minWidth: 76,
    maxWidth: 76,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Unit",
    field: "Unit",
    minWidth: 76,
    maxWidth: 76,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Component",
    field: "Component",
    minWidth: 85,
    maxWidth: 85,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Source",
    field: "Source",
    minWidth: 70,
    maxWidth: 70,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Role",
    field: "ContactRole",
    minWidth: 80,
    maxWidth: 80,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Archived",
    field: "Archived",
    minWidth: 70,
    maxWidth: 70,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Company",
    field: "Company",
    flex: 1,
    renderCell: renderCellExpand,
  },
];
