import DataTable from "../../../components/DataTable";
import renderCellExpand from "./../../../components/DatatableCellExpand";

export default function QuickMoveSitesTable({
  data,
  rowSelected,
  selectedRow,
}) {
  const fromSiteOptions = {
    columns: fromSiteColumns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.CustomerId + x.SiteId,
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    sx: { height: 690 },
  };
  return <DataTable {...fromSiteOptions} />;
}

const fromSiteColumns = [
  {
    headerName: "Product",
    field: "Product",
    minWidth: 180,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Source Customer",
    field: "Customer",
    minWidth: 190,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Source Site",
    field: "Site",
    minWidth: 120,
    flex: 1,
  },
];
