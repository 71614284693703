import { Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { TEST_SETS_APIS } from "../../../common/apis";
import SwitchInput from "../../../components/SwitchInput";
import useFetch from "../../../hooks/useFetch";
import { useRoot, useToast } from "../../../rootContext";
import TestItemsTable from "./TestItemsTable";
import TestSetForm from "./TestSetForm";
import TestSetsTable from "./TestSetsTable";
import AppDialog from "../../../components/AppDialog";
import AlertDialog from "../../../components/AlertDialog";
import appResources from "../../../app-resources/en/Resources.json";

export default function TestSets() {
  const { showToast } = useToast();
  const { get, post } = useFetch();
  const useRootMasterData = useRoot();

  const [testSets, setTestSets] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [masterData, setMasterData] = useState({});
  const [dirtyTestItems, setDirtyTestItems] = useState({});
  const [selectedTestSet, setSelectedTestSet] = useState([]);
  const [selectedTestSetRow, setSelectedTestSetRow] = useState();
  const [copied, setCopied] = useState();
  const [tempData, setTempData] = useState();
  const [dialog, setDialog] = useState({ open: false, message: "", });

  useEffect(function () {
    document.title = "Test Sets";
    (async function () {
      await refreshTestSets();
    })();
  }, []);

  async function refreshTestSets() {
    const testSetsResp = await get("/testSets");
    setTestSets(testSetsResp || []);
    setSelectedTestSet({});
    setDirtyTestItems({});
    const masterDataResp = {
      FluidTypes: useRootMasterData.fluidTypes,
      Products: useRootMasterData.products,
    };
    setMasterData(masterDataResp || {});
  }

  // async function handelRowSelected(row) {
  //   const resp = await get(
  //     `${TEST_SETS_APIS.DETAILS}?testSetId=${row.TestSetId}`
  //   );
  //   if (resp) {
  //     resp.testSet = row;
  //     setSelectedTestSet(resp || {});
  //     return;
  //   }
  //   setSelectedTestSet({});
  // }

  async function handelRowSelected(row) {
    getTestSetsDetails(row);
  }

  async function getTestSetsDetails(row) {
    if (row) {
      setSelectedTestSetRow(row);
      const resp = await get(
        `${TEST_SETS_APIS.DETAILS}?testSetId=${row.TestSetId}`
      );
      if (resp) {
        resp.testSet = row;
        setSelectedTestSet(resp || {});
        return;
      }
    }
    setSelectedTestSet({});
  }

  async function handelSaveTestSet(data, copy) {
    if (copy) {
      setDialog({
        open: true,
        message: "Are you sure you want to copy this test set?",
      });
      setCopied(copy);
      setTempData(data);
      return;
    }
    await saveTestSet(data);
    showToast(appResources.TEST_SET_SAVED_SUCCESSFULLY_MSG);
  }

  async function handelSaveTestSetAlert() {
    await saveTestSet(
      {
        ...tempData,
        TestSetId: null,
        TestSet: tempData.TestSet + " copy",
      },
      copied
    );
    setDialog((prevState) => ({ ...prevState, open: false }));
    showToast(appResources.TEST_SET_COPY_SUCCESSFULLY_MSG);
  }

  async function saveTestSet(data, copy) {
    const { Customers, Users, ToTestSetId, UseAutoEvaluation, ...TestSet } =
      data;

    const TestSetItems =
      Object.keys(dirtyTestItems).length || copy
        ? (selectedTestSet.TestSetItems || []).map(
          (x) => dirtyTestItems[x.TestItemId] || x
        )
        : null;

    const resp = await post(TEST_SETS_APIS.SAVE, {
      Customers,
      Users: copy ? null : Users,
      ToTestSetId,
      UseAutoEvaluation,
      TestSet,
      TestSetItems,
    });
    await refreshTestSets();
  }
  const testSetsFiltered = useMemo(
    () =>
      testSets.filter((x) => !x.IsArchived || x.IsArchived === showArchived),
    [showArchived, testSets]
  );

  useEffect(() => {
    if (testSetsFiltered.length > 0) {
      getTestSetsDetails(testSetsFiltered[0]);
    }
  }, [testSetsFiltered]);

  return (
    <>
      <Grid container spacing={2} sx={{ pb: 1 }}>
        <Grid item xs={12}>
          <SwitchInput
            label="Show Archived"
            onChange={(e) => setShowArchived(e.target.checked)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {testSetsFiltered?.length}</b>
          </Typography>
          <TestSetsTable
            data={testSetsFiltered}
            rowSelected={handelRowSelected}
            selectedRow={[selectedTestSetRow?.TestSetId]}
            key={selectedTestSetRow?.TestSetId}
          //  selectedRow={[selectedTestSetRow?.TestSetId]}
          />
        </Grid>
        <Grid item md={4} xs={12} mt={2}>
          <Card sx={{ p: 1 }}>
            <TestSetForm
              masterData={masterData}
              data={selectedTestSet}
              key={selectedTestSet.testSet?.TestSetId}
              saveTestSet={handelSaveTestSet}
              testSets={testSets}
              refreshTestSets={refreshTestSets}
            />
          </Card>
        </Grid>
        <Grid item md={4} xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {selectedTestSet.TestSetItems?.length}</b>
          </Typography>
          <TestItemsTable
            data={selectedTestSet.TestSetItems || []}
            key={selectedTestSet.TestSetId}
            setDirtyTestItems={setDirtyTestItems}
          />
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Note"
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={handelSaveTestSetAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}
