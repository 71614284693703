import { Button } from "@mui/material";
import { Check, Search } from "@mui/icons-material";
import { useEffect, useMemo, useReducer, useState } from "react";
import Form, { SectionTitle } from "../components/Form";
import FormFooter from "../components/FormFooter";
import SiteReducer, { SiteActions } from "./reducers/SiteReducer";
import useFetch from "../hooks/useFetch";
import TextBoxGroup from "./../components/TextBoxGroup";
import AppDialog from "./../components/AppDialog";
import SearchContact from "./../features/quick-details/SearchContact";
import { useRef } from "react";
import useMasterData from "../hooks/useMasterData";
import { ValidateSite } from "../validation/schemas/SiteSchema";
import { DETAILS_APIS } from "../common/apis";
import { NewIcon } from "../icons/ButtonIcons";
import { useRoot, useToast, useUser } from "../rootContext";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as SiteAction from "../redux/actions/SiteActions";
import appResources from "../app-resources/en/Resources.json";
import { getStartLetter } from "../utils";
import ManageSite from "../features/quick-details/manage/manage-site/ManageSite";

function EditSite({
  productId,
  customerId,
  customer,
  siteId,
  registrationDone,
  cancel,
  SiteAction,
  handleSearchTree,
  setExpandTreeData
}) {
  const [data, dispatch] = useReducer(SiteReducer, { SiteExportOptionId: 1, });

  const [dropdownData, setDropdownData] = useState([]);
  const [dialogType, setDialogType] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [editCheck, setEditCheck] = useState(false);
  const [openManagement, setOpenManagement] = useState(false);

  const user = useUser();
  const { CanDoDataMaintenance } = useRoot();
  const { get, post } = useFetch();
  const { getMasterData } = useMasterData();
  const { showToast, showWarningToast, showErrorToast } = useToast();

  // const handleKeyDown = useCallback(
  //   (event) => {
  //     if (event.key === "F11" || event.key === "F12") {
  //       event.preventDefault();
  //       actions.save();
  //     }
  //   },
  //   [data]
  // );
  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [handleKeyDown]);

  const actions = {
    save: async function () {
      if (siteId) {
        setFormErrors({});
        ValidateSite(data).then(async function ({ data: req, errors }) {
          if (req) {
            let result = await post(DETAILS_APIS.UPDATE_SITE, {
              Site: data.Site,
              CustomerId: customerId,
              UserId: user.UserId,
              SiteId: data.SiteId,
              KalBranchId: data?.KalBranchId,
              ShowSiteName: !!data.ShowSiteName,
              ApplyKalBranchToAll: !!data.ApplyKALBranch,
              ExportOptionId: data.SiteExportOptionId,
              IsPredelivery: !!data.IsPredelivery,
              IsArchived: !!data.SiteArchived,

              MakeAllParked: data?.makeAllUnits === "1" ? true : false,
              MakeAllScrapped: data?.makeAllUnits === "2" ? true : false,
              MakeAllArchived: data?.makeAllUnits === "3" ? true : false,

              IsShowLimitsOnReport: !!data.IsShowLimitsOnReport || false,
              IsAutoCreated: false,
              OwnerUserId: data?.SiteCmsOwnerId || 0,

              Notes: data?.SiteNotes,

              ExportBlock: data?.LocationTaken,

              PostalAddressId: data?.a,
              PostalStreet1: data?.PostalStreet1,
              PostalStreet2: data?.PostalStreet2,
              PostalCity: data?.PostalCity,
              PostalState: data?.PostalState,
              PostalPostCode: data?.PostalPostCode,
              PostalCountry: data?.PostalCountry,

              PhysicalAddressId: data?.a,
              PhysicalStreet1: data?.PhysicalStreet1,
              PhysicalStreet2: data?.PhysicalStreet2,
              PhysicalCity: data?.PhysicalCity,
              PhysicalState: data?.PhysicalState,
              PhysicalPostCode: data?.PhysicalPostCode,
              PhysicalCountry: data?.PhysicalCountry,

              PrimaryContactId1: data?.PrimaryContactId1,
              PrimaryContactId2: data?.PrimaryContactId2,
              PrimaryContactId3: data?.PrimaryContactId3,

              Latitude: data?.Latitude,
              Longitude: data?.Longitude,

              LocationTaken: data?.LocationTaken,

              Screen: "Edit Site",
            });
            registrationDone && registrationDone(data.SampleNumber);

            if (result === -10) {
              return showWarningToast(
                appResources.PROVIDE_UNIQUE_NAME_FOR_SITE_MSG
              );
            }
            if (result >= 0 || result?.length > 0) {
              showToast(appResources.SITE_SAVED_SUCCESSFULLY_MSG);
              SiteAction.updateSite(data);
              return cancel();
            }
            if (result !== null && (result < 1 || result?.length < 1)) {
              return showErrorToast(appResources.SITE_NOT_SAVED_MSG);
            }
            return;
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      } else {
        setFormErrors({});
        ValidateSite(data).then(async function ({ data: req, errors }) {
          if (req) {
            let name = customer.trim();
            let slIndex = Number(getStartLetter(name[0]));

            let result = await post(DETAILS_APIS.ADD_SITE, {
              Site: data.Site,
              CustomerId: customerId,
              UserId: user.UserId,
              KalBranchId: data?.KalBranchId,
              ShowSiteName: !!data.ShowSiteName,
              ApplyKalBranchToAll: !!data.ApplyKALBranch,
              ExportOptionId: data.SiteExportOptionId,
              IsPredelivery: !!data.IsPredelivery,
              IsArchived: !!data.SiteArchived,

              MakeAllParked: data?.makeAllUnits === "1" ? true : false,
              MakeAllScrapped: data?.makeAllUnits === "2" ? true : false,
              MakeAllArchived: data?.makeAllUnits === "3" ? true : false,

              IsShowLimitsOnReport: !!data.IsShowLimitsOnReport || false,
              IsAutoCreated: false,
              OwnerUserId: data?.SiteCmsOwnerId || 0,

              Notes: data?.SiteNotes,

              ExportBlock: data?.LocationTaken,

              PostalAddressId: data?.a,
              PostalStreet1: data?.PostalStreet1,
              PostalStreet2: data?.PostalStreet2,
              PostalCity: data?.PostalCity,
              PostalState: data?.PostalState,
              PostalPostCode: data?.PostalPostCode,
              PostalCountry: data?.PostalCountry,

              PhysicalAddressId: data?.a,
              PhysicalStreet1: data?.PhysicalStreet1,
              PhysicalStreet2: data?.PhysicalStreet2,
              PhysicalCity: data?.PhysicalCity,
              PhysicalState: data?.PhysicalState,
              PhysicalPostCode: data?.PhysicalPostCode,
              PhysicalCountry: data?.PhysicalCountry,

              PrimaryContactId1: data?.PrimaryContactId1,
              PrimaryContactId2: data?.PrimaryContactId2,
              PrimaryContactId3: data?.PrimaryContactId3,

              Latitude: data?.Latitude,
              Longitude: data?.Longitude,

              LocationTaken: data?.LocationTaken,

              Screen: "New Site",
            });
            registrationDone && registrationDone(data.SampleNumber);
            if (result === -10) {
              return showWarningToast(
                "Please provide a UNIQUE name for the Site"
              );
            }
            if (result >= 0 || result?.length > 0) {
              if (result > 0) {
                const treeData = { ProductId: productId, StartLetter: slIndex, CustomerId: customerId, SiteId: result }
                setExpandTreeData && setExpandTreeData(treeData)
                handleSearchTree && handleSearchTree(treeData)
              }
              showToast(appResources.SITE_SAVED_SUCCESSFULLY_MSG);
              return cancel();
            }
            if (result !== null && (result < 1 || result?.length < 1)) {
              return showErrorToast(appResources.SITE_NOT_SAVED_MSG);
            }
            return;
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      }
    },
    manage: async function () {
      setOpenManagement(true);
    },
  };

  useEffect(() => {
    (async () => {

      if (!siteId) {
        let masterData = await getMasterData(
          "KalBranches,ExportOptions,Users,Countries"
        );
        masterData.Users?.unshift({ UserId: 0, Username: "Default User" });
        setDropdownData(masterData || {});
      }
      if (siteId) {
        (async () => {
          let data = await get(`/details/site?siteId=${siteId}`);
          data.Users?.unshift({ UserId: 0, Username: "Default User" });
          setDropdownData(data || {});
          dispatch({ type: SiteActions.LOAD_DATA, payload: data.Site });
          setEditCheck(true)
        })();
      }
    })();
  }, []);

  function openEdit(type) {
    setDialogType(type);
  }

  //handle logo upload
  const fileInputRef = useRef(null);
  function handleFileInputClick() {
    fileInputRef.current.click();
  }
  // const handleFileChange = (e) => {
  //   const imgUrl = URL.createObjectURL(e.target.files[0]);
  // };

  return (
    <>
      <Form
        sections={FormSections(
          dropdownData,
          openEdit,
          handleFileInputClick
        )}
        data={data}
        spacing={2}
        dispatch={dispatch}
        errors={formErrors}
        key={data.SiteId}
      />
      <FormFooter buttons={GetFormButtons(data, actions, editCheck, CanDoDataMaintenance)} />
      <EditDialog type={dialogType} data={data} setDialogType={setDialogType} />
      <AppDialog
        open={openManagement}
        title={`Site Management  Site ${data.SiteId}`}
        maxWidth="100vw"
        handleClose={() => setOpenManagement(false)}
      >
        <ManageSite unit={data} />
      </AppDialog>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return { SiteAction: bindActionCreators(SiteAction, dispatch) };
};

export default connect(null, mapDispatchToProps)(EditSite);

//Edit dialogue
function EditDialog({ type, data, setDialogType }) {
  const [editOpen, setEditOpen] = useState(!!type);
  useEffect(
    function () {
      setEditOpen(!!type);
    },
    [type]
  );
  useEffect(
    function () {
      !editOpen && setDialogType("");
    },
    [editOpen]
  );

  const content = useMemo(
    function () {
      return {
        "search-contact": {
          title: "Search Contacts",
          component: <SearchContact cancel={() => setEditOpen(false)} />,
        },
      }[type];
    },
    [type]
  );
  return (
    <>
      {content && (
        <AppDialog
          open={!!editOpen}
          title={content.title}
          maxWidth="lg"
          handleClose={() => setEditOpen(false)}
        >
          {content.component}
        </AppDialog>
      )}
    </>
  );
}
//edit dialog ended
const GetFormButtons = (data, { save, manage }, editCheck, CanDoDataMaintenance) => [
  !!editCheck && {
    variant: "contained",
    label: "Manage",
    onClick: manage,
  },
  !!editCheck && {
    variant: "contained",
    label: "Save New",
    onClick: save,
    startIcon: <NewIcon />,
  },
  {
    hidden: !CanDoDataMaintenance,
    variant: "contained",
    label: "Save",
    onClick: save,
    startIcon: <Check />,
  },
];

const FormSections = (
  dropdownData,
  openEdit,
  handleFileInputClick,
  editCheck
) => {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "Site", label: "Name", xsCol: 9 },
        {
          name: "SiteArchived",
          label: "Archived - no website",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "SiteCmsOwnerId",
          label: "CMS Owner",
          type: "dropdown",
          xsCol: 4,
          selectOptions: (dropdownData.Users || []).map((x) => ({
            value: x.UserId,
            text: x.Username,
          })),
        },
        {
          name: "KalBranchId",
          label: "Branch",
          type: "dropdown",
          xsCol: 4,
          selectOptions: (dropdownData.KalBranches || []).map((x) => ({
            value: x.BranchId,
            text: x.FullBranch,
          })),
        },
        {
          name: "ApplyKALBranch",
          label: "Apply KAL Branch to ALL Units for this Site",
          type: "switch",
          xsCol: 4,
        },
        {
          xsCol: 4,
          name: "PrimaryContactId1",
          component: function ({ id, value }) {
            const options = {
              label: "Primary1",
              id,
              value,
              readOnly: true,
              buttons: [
                {
                  onClick: () => openEdit("search-contact"),
                  icon: <Search />,
                },
              ],
            };
            return <TextBoxGroup {...options} />;
          },
        },
        {
          xsCol: 4,
          name: "PrimaryContactId2",
          component: function ({ id, value }) {
            const options = {
              label: "Primary2",
              id,
              value,
              readOnly: true,
              buttons: [
                {
                  onClick: () => openEdit("search-contact"),
                  icon: <Search />,
                },
              ],
            };
            return <TextBoxGroup {...options} />;
          },
        },
        {
          xsCol: 4,
          name: "PrimaryContactId3",
          component: function ({ id, value }) {
            const options = {
              label: "Primary3",
              id,
              value,
              readOnly: true,
              buttons: [
                {
                  onClick: () => openEdit("search-contact"),
                  icon: <Search />,
                },
              ],
            };
            return <TextBoxGroup {...options} />;
          },
        },
        {
          name: "LocationTaken",
          label: "Date Taken",
          xsCol: 4,
          type: "datetime",
        },
        { name: "Latitude", label: "Latitude (Negative Number)", xsCol: 4 },
        { name: "Longitude", label: "Longitude (Positive Number)", xsCol: 4 },
        !!editCheck && {
          component: function () {
            return (
              <>
                <Button sx={{ marginTop: "17px" }} variant="contained">
                  Get Address
                </Button>
              </>
            );
          },
        },
        !!editCheck && {
          component: function () {
            return (
              <>
                <Button sx={{ marginTop: "17px" }} variant="contained">
                  View
                </Button>
              </>
            );
          },
        },
        {
          name: "makeAllUnits",
          label: "Apply to All Units in this Site:",
          type: "dropdown",
          xsCol: 4,
          selectOptions: [
            { value: "1", text: "Make ALL Units Parked -on website" },
            { value: "2", text: "Make ALL Units Scrapped -not on website" },
            { value: "2", text: "Make ALL Units Archived -no website" },
          ],
        },
        {
          name: "IsShowLimitsOnReport",
          label: "Show Test Limits on Report",
          type: "switch",
          xsCol: 3,
        },
        {
          xsCol: 12,
          component: function () {
            return <SectionTitle title="Additional Information" />;
          },
        },
        { name: "SiteNotes", label: "Comment", xsCol: 12, type: "textarea" },
      ],
    },
    {
      xsCol: 6,
      fields: [
        { name: "PhysicalStreet1", label: "Physical Address 1", xsCol: 12 },
        { name: "PhysicalStreet2", label: "Address 2", xsCol: 12 },
        { name: "PhysicalCity", label: "Town", xsCol: 12 },
        { name: "PhysicalState", label: "State", xsCol: 6 },
        { name: "PhysicalPostCode", label: "Postcode", xsCol: 6 },
        {
          name: "PhysicalCountry",
          label: "Country",
          type: "dropdown",
          xsCol: 12,
          selectOptions: (dropdownData.Countries || []).map((x) => ({
            value: x.Country,
            text: x.Country,
          })),
        },
      ],
    },
    {
      xsCol: 6,
      fields: [
        { name: "PostalStreet1", label: "Postal Address 1", xsCol: 12 },
        { name: "PostalStreet2", label: "Address 2", xsCol: 12 },
        { name: "PostalCity", label: "Town", xsCol: 12 },
        { name: "PostalState", label: "State", xsCol: 6 },
        { name: "PostalPostCode", label: "Postcode", xsCol: 6 },
        {
          name: "PostalCountry",
          label: "Country",
          type: "dropdown",
          selectOptions: (dropdownData.Countries || []).map((x) => ({
            value: x.Country,
            text: x.Country,
          })),
          xsCol: 12,
        },
      ],
    },
    {
      xsCol: 12,
      fields: [
        {
          name: "SiteBlockEvaluationsFrom",
          label: "Limited From",
          xsCol: 3,
          type: "date",
        },
        {
          name: "SiteExportOptionId",
          label: "Exports",
          type: "dropdown",
          xsCol: 3,
          required: true,
          selectOptions: (dropdownData.ExportOptions || []).map((x) => ({
            value: x.ExportOptionId,
            text: x.ExportOption,
          })),
        },
      ],
    },
  ];
};
