import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  styled,
  Badge,
  Avatar,
} from "@mui/material";
import logo from "./Image/kowa.svg";
import {
  MissedSamplesIcon,
  MessagesIcon,
  RerunSampleIcon,
  CriticalLogsIcon,
} from "./icons/ButtonIcons";
import { useMsal } from "@azure/msal-react";
import MuiAppBar from "@mui/material/AppBar";
import { useEffect, useState } from "react";
import { useUser } from "./rootContext";
import { Link, useLocation } from "react-router-dom";
import AppDialog from "./components/AppDialog";
import LabDialog from "./components/LabDialog";
import AboutDialog from "./components/AboutDialog";
import UserMessagesDialog from "./components/UserMessagesDialog";
import MissedSamples from "./features/reports/missed-samples/MissedSamples";
import RerunSamples from "./features/reports/rerun-samples/RerunSamples";
import useFetch from "./hooks/useFetch";
import { DETAILS_APIS, WEBSITE_APIS } from "./common/apis";
import OptionMenu from "./components/optionMenu";
import menu from "./header/menu";
import CriticalLogs from "./features/reports/critical-logs/CriticalLogs";

export const drawerWidth = 220;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppTitleBar() {
  const { instance } = useMsal();
  const user = useUser();
  const { get, post } = useFetch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [lab, setLab] = useState("");
  const [selectedLabID, setSelectedLabID] = useState();
  const [topNot, setTopNot] = useState([]);
  const [websiteLinks, setWebsiteLinks] = useState([]);
  const [menuList, setMenuList] = useState([]);

  useEffect(function () {
    (async function () {
      const result = await get(WEBSITE_APIS.GET_WEBSITE_LINKS);
      if (result) {
        setWebsiteLinks(result);
      }
    })();
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [dialog, setDialog] = useState({
    open: false,
    fullWidth: null,
    width: "",
    title: "",
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLab = (lab) => {
    setLab(lab);
  };

  //To not show Sidebar on Charts Routes
  let location = useLocation();

  useEffect(() => {
    (async function () {
      const topNotifications = await post(DETAILS_APIS.TOP_NOTIFICATIONS, {
        LaboratoryId: user.DefaultLaboratoryId,
        ExcludeToday: false,
        IncludeOnHold: false,
        showToday: false,
        showOutstandingOnly: true,
      });
      setTopNot(topNotifications);
    })();

    if ((user?.RoleAdministration || user?.RoleDeveloper) !== true) {
      let list = menu.filter((x) => x.name !== "administration");
      setMenuList(list);
    }
    if ((user?.RoleAdministration || user?.RoleDeveloper) === true) {
      setMenuList(menu);
    }
  }, []);

  useEffect(() => {
    // runs on location, i.e. route, change
    if (location?.pathname !== "/Chart") {
      localStorage.setItem("isCloseWindow", true);
    }
  }, [location]);

  const handleMouseClick = (event, path) => {
    if (event.button === 1) {
      window.open(path, '_blank')
    }
  }
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: "1px 1px 0px 0px #0000001f",
          display: location.pathname !== "/Chart" ? "block" : "none",
        }}
      >
        <Toolbar style={{ minHeight: "50px" }}>
          <Link to="/">
            <Box
              component="img"
              sx={{
                height: 30,
                mr: 1,
              }}
              alt="KOWA"
              src={logo}
            />
          </Link>
          <Box
            sx={{
              flexGrow: 1,
              mt: "-3px",
              display: { /*xs: "none",*/ md: "flex" },
            }}
          >
            {menuList.map((page, i) => (
              <OptionMenu {...page} key={page.name} />
            ))}

            <OptionMenu websiteLinks={websiteLinks} />
          </Box>

          <Box
            sx={{
              marginLeft: "auto",
              display: "flex",
              mr: 0.5,
              mt: 1,
              cursor: "pointer",
            }}
          >
            <Badge
              badgeContent={topNot?.CriticalSamples}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              className="top-badge"
              onMouseDown={(e) => handleMouseClick(e, '/reports/critical-logs')}

              onClick={() =>
                setDialog({
                  fullWidth: true,
                  open: true,
                  title: "Critical Logs",
                  width: "100vw",
                })
              }
              title={"Critical Logs"}
            >
              <CriticalLogsIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1rem",
                  height: "1rem",
                  color: "#253FC8", //"#0085FF",
                  paddingTop: "4px",
                }}
              />
            </Badge>
            <Badge
              badgeContent={topNot?.OustandingRerun}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              className="top-badge"
              onMouseDown={(e) => handleMouseClick(e, '/reports/rerun-samples')}
              onClick={() =>
                setDialog({
                  fullWidth: true,
                  open: true,
                  title: "Rerun Samples",
                  width: "100vw",
                })
              }
              title={"Rerun Samples"}
            >
              <RerunSampleIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1.2rem",
                  height: "1.2rem",
                  color: "#253FC8", //"#0085FF",
                  paddingTop: "4px",
                }}
              />
            </Badge>
            <Badge
              badgeContent={topNot?.MissedSamples}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              className="top-badge"
              onMouseDown={(e) => handleMouseClick(e, '/missed-samples')}
              onClick={() =>
                setDialog({
                  fullWidth: true,
                  open: true,
                  title: "Missed and Hold Samples",
                  width: "100vw",
                })
              }
              title={"Missed Samples"}
            >
              <MissedSamplesIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1rem",
                  color: "#253FC8", //"#0085FF",
                }}
              />
            </Badge>
            <Badge
              badgeContent={topNot?.Messages}
              max={999}
              color="error"
              sx={{
                marginRight: "15px",
                [`.MuiBadge-badge`]: { right: "-3px" },
              }}
              onClick={() =>
                setDialog({
                  open: true,
                  title: "Messages",
                  width: "md",
                })
              }
              title={"Messages"}
            >
              <MessagesIcon
                sx={{
                  fontSize: "25px!important",
                  width: "1rem",
                  height: "1rem",
                  color: "#253FC8", //"#0085FF",
                  paddingTop: "4px",
                }}
              />
            </Badge>
            <Typography
              title="Selected Lab"
              variant="subtitle2"
              noWrap
              textOverflow={"hidden"}
              sx={{ color: "#003300", mx: 0.75 }}
              onClick={() =>
                setDialog({
                  open: true,
                  title: "Change Laboratory",
                  width: "md",
                })
              }
            >
              {lab || user.DefaultLaboratory}
            </Typography>
            <Typography variant="subtitle2" noWrap sx={{ color: "#000" }}>
              | {user.Fullname}
            </Typography>
          </Box>

          <IconButton onClick={handleMenu}>
            <Avatar
              sx={{
                fontSize: "12px !important",
                bgcolor: "#FFDB78",
                color: "#000",
              }}
            >
              {user?.Forename?.slice(0, 1).toUpperCase() +
                user?.Surname?.slice(0, 1).toUpperCase()}
            </Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                setDialog({
                  open: true,
                  title: "About",
                  width: "md",
                }) & setAnchorEl(null)
              }
            >
              About
            </MenuItem>
            <MenuItem onClick={() => instance.logoutRedirect()}>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <AppDialog
        open={dialog.open}
        fullWidth={dialog.fullWidth}
        title={dialog.title}
        maxWidth={dialog.width}
        handleClose={() =>
          setDialog((prevState) => ({
            ...prevState,
            open: false,
          }))
        }
      >
        {dialog.title === "Change Laboratory" && (
          <LabDialog
            setSelectedLabID={setSelectedLabID}
            selectedLabID={selectedLabID}
            getLab={getLab}
            setDialog={setDialog}
          />
        )}
        {dialog.title === "Missed and Hold Samples" && <MissedSamples />}
        {dialog.title === "Critical Logs" && <CriticalLogs />}
        {dialog.title === "About" && <AboutDialog />}
        {dialog.title === "Messages" && <UserMessagesDialog />}
        {dialog.title === "Rerun Samples" && <RerunSamples />}
      </AppDialog>
    </>
  );
}
