import { Button, Card, Grid, Typography, } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import Form, { FormActions } from "../../../components/Form";
import DataTable from "../../../components/DataTable";
import { toLocalDate, } from "../../../utils";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";
import { ExcelIcon, SaveCommentsIcon, UpdateIcon, } from "./../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";

let eventsCount = 0;
let fluidChangedCount = 0;
let lstEditUnitHourHistory = [];

export default function UnitHourHistory({ unitId }) {

  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);

  const { get, post, getFile } = useFetch();
  const { CanEvaluate } = useRoot();
  const [unitHourData, setUnitHourData] = useState([]);
  const [compTypes, setCompTypes] = useState([]);

  useEffect(() => {
    (async function () {
      let parseData = {};
      let formDatas = localStorage.getItem("unit-hour-history")

      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas)
        return loadUnitHourHistoryData(parseData?.unitId);
      }
      loadUnitHourHistoryData();
    })();
  }, []);

  // load unit hour history
  async function loadUnitHourHistoryData(UnitId) {
    const resData = await get(`${DETAILS_APIS.UNIT_HOUR_HISTORY}?UnitId=${UnitId || unitId}`);
    if (resData) {
      const compTypesTemp = Object.keys(
        resData.reduce((acc, item) => {
          item.customId =
            item.ComponentId +
            "" +
            (item.EventLogId || "") +
            (item.SampleNumber || "");
          if (item.Component && !acc[item.Component]) {
            acc[item.Component] = true;
          }
          return acc;
        }, {})
      )
        .sort((a, b) => a.localeCompare(b))
        .map((x) => ({ text: x, value: x }));
      compTypesTemp.unshift({ text: "All Components", value: 0 });
      setCompTypes(compTypesTemp);

      const filteredEventCount = resData.filter((item) => item.Event !== null);
      eventsCount = filteredEventCount.length;
      const filteredFluidChangedCount = resData.filter(
        (item) => item.IsFluidChange
      );
      fluidChangedCount = filteredFluidChangedCount.length;

      setUnitHourData(resData);
    }
  }
  const filteredData = useMemo(
    () =>
      unitHourData.filter((item) => {
        return (
          (data.FluidHours || data.FluidHours === item.IsFluidChange) &&
          (!data.component || item.Component === data.component)
        );
      }),
    [unitHourData, data.component, data.FluidHours]
  );
  //Edit cell click
  const rowEditHandler = useCallback(function (newRow, oldRow) {
    return new Promise(async function (resolve, reject) {
      // Check if SampleNumber already exists in lstEditUnitHourHistory
      const existingRecord = lstEditUnitHourHistory.find(
        (record) => record.SampleNumber === newRow.SampleNumber
      );

      // If an existing record is found, update the properties
      if (existingRecord) {
        for (const key in newRow) {
          if (
            newRow.hasOwnProperty(key) &&
            newRow[key] !== oldRow[key] &&
            key !== "SampleNumber"
          ) {
            existingRecord[key] = newRow[key];
          }
        }
      } else {
        // If no existing record is found, create a new object and add it to lstEditUnitHourHistory
        lstEditUnitHourHistory.push({
          SampleNumber: newRow.SampleNumber,
          MeterReading: parseInt(newRow.MeterReading),
          ComponentReading: parseInt(newRow.ComponentReading),
          FluidHours: parseInt(newRow.FluidHours),
          Sampled: newRow.Sampled,
        });
      }

      resolve({ lstEditUnitHourHistory });
    });
  }, []);

  const tblOptions = {
    columns: GetColumns(data),
    dataSource: (filteredData || []).map((x) => ({
      ...x,
      keyProp:
        x.ComponentId +
        "" +
        (x.SampleNumber || "") +
        (x.EventLogId || "") +
        (x.UnitHours || "") +
        (x.Sampled || "") +
        (x.UnitHours || "") +
        (x.Sampled || "") +
        (x.MeterReading || "") +
        (x.Component || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "unitHourHistory",
    rowsSelected: function (r) {
      if (r) {
        r.length && rowSelected(r[0]);
      }
    },
    rowEditHandler: rowEditHandler,
    experimentalFeatures: { newEditingApi: true },
    onProcessRowUpdateError: console.error,
    sx: { height: 700 },
  };
  function rowSelected(row) { }
  function excelClick() {
    getFile(
      `${DETAILS_APIS.EXPORT_UNIT_HOUR_HISTORY}?UnitId=${unitId}`,
      "UnitHourHistory"
    );
  }
  async function saveEditCellsData() {
    const responseData = await post(
      DETAILS_APIS.EDIT_SAMPLE_HOUR,
      lstEditUnitHourHistory
    );
    if (responseData !== 0) {
      loadUnitHourHistoryData();
      lstEditUnitHourHistory = [];
    }
  }

  return (
    <>
      <Grid container spacing={2} sx={{ minWidth: "90vw" }}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <Form
                  sections={FormSections(excelClick, data, saveEditCellsData, CanEvaluate)}
                  data={data}
                  dispatch={dispatch}
                  masterData={{ compTypes }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            There are {eventsCount} events for this unit: Fluid Changed{" "}
            {fluidChangedCount}
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(data) {
  return [
    {
      headerName: "Component",
      field: "Component",
      minWidth: 180,
      sortable: false,
    },
    {
      headerName: "Event",
      field: "Event",
      minWidth: 170,
      sortable: false,
    },
    // {
    //   headerName: "Meter Changed",
    //   field: "IsMeterChange",
    //   valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    //   minWidth: 150,
    //   sortable: false,
    // },
    {
      headerName: "Fluid Changed",
      field: "IsFluidChange",
      hide: !data.FluidHours,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      minWidth: 80,
      sortable: false,
    },
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
      minWidth: 140,
      sortable: false,
    },
    {
      headerName: "Meter Reading",
      field: "MeterReading",
      editable: data?.AllowEditing,
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Unit Hours",
      field: "UnitHours",
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Component Hours",
      field: "ComponentReading",
      editable: data?.AllowEditing,
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Fluid Hours",
      field: "FluidHours",
      editable: data?.AllowEditing,
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      minWidth: 200,
      sortable: false,
      flex: 1,
    },
  ];
}
function FormSections(excelClick, data, saveEditCellsData, CanEvaluate) {
  return [
    {
      fields: [
        {
          name: "component",
          label: "Component",
          type: "dropdown",
          xsCol: 2,
          required: true,
          alignSelf: "end",
          optionConfigs: {
            name: "compTypes",
          },
        },
        {
          name: "FluidHours",
          label: "Fluid Hours",
          type: "switch",
        },
        {
          name: "AllowEditing",
          label: "Allow Editing",
          type: "switch",
          className: "red",
        },
        {
          name: "Sampled",
          label: "+Sampled",
          type: "switch",
        },
        {
          mdCol: 3,
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={excelClick}
                sx={{ mt: "23px" }}
                startIcon={<ExcelIcon />}
              >
                Export
              </Button>
            );
          },
        },
      ],
    },
    {
      fields: [
        {
          mdCol: 12,
          component: function () {
            return data && data.AllowEditing ? (
              <Typography sx={{ mb: -4 }}>
                If anything is UNKNOWN, e.g. Meter Reading, then make the value
                EMPTY and NOT ZERO; zero means that the value is known and brand
                new
              </Typography>
            ) : (
              <></>
            );
          },
        },
      ],
    },
    data && data.AllowEditing ? (
      {
        fields: [
          {
            component: function () {
              return (
                <Typography variant="subtitle2" sx={{ mt: 3.8 }}>
                  Set 0s to Empty
                </Typography>
              );
            },
          },
          {
            name: "MeterReadings",
            label: "Meter Readings",
            type: "switch",
          },
          {
            name: "isComponentHours",
            label: "Component Hours",
            type: "switch",
          },
          {
            name: "FluidHoursSave",
            label: "Fluid Hours",
            type: "switch",
          },

          {
            component: function () {
              return (
                <Button
                  variant="contained"
                  //  onClick={}
                  sx={{ mt: "23px" }}
                  startIcon={<UpdateIcon />}
                >
                  Update to Empty
                </Button>
              );
            },
          },
          CanEvaluate && {
            component: function () {
              return (
                <Button
                  variant="contained"
                  onClick={saveEditCellsData}
                  sx={{ mt: "23px" }}
                  startIcon={<SaveCommentsIcon />}
                >
                  Save
                </Button>
              );
            },
          },
        ],
      }
    ) : (
      <></>
    ),
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
const SearchFilterDefaults = {
  FluidHours: true,
  MeterReadings: true,
  isComponentHours: true,
  FluidHoursSave: true,
  component: 0,
};
