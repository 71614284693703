import DataTable from "./../../../components/DataTable";
import { HoldTableColmns } from "./HoldTableColmns";

export default function HoldTable(props) {
  const options = {
    columns: HoldTableColmns,
    dataSource: props.data,
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    sx: {
      height: 510,
    },
  };
  return <DataTable {...options}></DataTable>;
}
