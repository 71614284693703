import React, { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  Grid,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AppRouter from "./AppRouter";
import { useRoot } from "./rootContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  useMsal,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Close, Error } from "@mui/icons-material";
import useFetch from "./hooks/useFetch";
import AppTitleBar from "./AppTitleBar";
import { MASTER_DATA_APIS } from "./common/apis";
import UserNotFound from "./components/UserNotFound";
import useRestrictedResolution from "./components/useRestrictedResolution";
import appResources from "./app-resources/en/Resources.json";

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { msai } from "./authConfig";

function App(props) {
  const { screenBlocked } = useRoot();
  let isResolutionRestricted = useRestrictedResolution(1366, 651);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      errorComponent={MsalErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <BrowserRouter>
        <ErrorComponent />
        {/* <Header title={t("AppName")} /> */}
        <AuthenticatedTemplate>
          <RouterWrapper />
        </AuthenticatedTemplate>
        <ToastMessage />
        <Backdrop
          sx={isResolutionRestricted === true
            ? { backgroundColor: "#fff" }
            : { backgroundColor: "rgba(246, 246, 246,0.5)" }}
          open={screenBlocked || isResolutionRestricted}
          style={{ zIndex: 2000 }}
        >
          {isResolutionRestricted === true
            ? <Typography sx={{ color: "red", fontSize: 13 }}>
              {appResources.SCREEN_NOT_SUPPORTED}
            </Typography>
            : <CircularProgress color="inherit" />}
        </Backdrop>
      </BrowserRouter>
    </MsalAuthenticationTemplate>
  );
}

function RouterWrapper() {
  const { t } = useTranslation("common");
  const { get } = useFetch();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const {
    setUser,
    user,
    setProducts,
    setAllProducts,
    setOriginalLabs,
    setLaboratories,
    setAllLaboratories,
    setTestItemTypes,
    setComponentFluidTypes,
    setFluids,
    setFluidTypes,
    setParentsAll,
  } = useRoot();

  useEffect(function () {
    (async function () {
      const temp = await get("/account/profile");
      setUser(
        {
          ...temp,
          RealDefaultLaboratory: temp?.DefaultLaboratory,
          RealDefaultLaboratoryId: temp?.DefaultLaboratoryId,
        } || {}
      );

      const emailId = temp?.EmailAddress;
      appInsights.trackException({ name: emailId });
      appInsights.trackEvent({ name: emailId });
      appInsights.trackPageView({ properties: { name: emailId } });
      //Products without AllProducts
      if (temp) {
        const response = await get(
          `${MASTER_DATA_APIS.GET_BY_TYPES}?types=Products,Laboratories,TestItemTypes,ComponentFluidTypes,Fluids,FluidTypes,ParentsAll`
        );
        setProducts(response.Products || {});
        setOriginalLabs(response.Laboratories || []);
        setLaboratories(response.Laboratories || []);

        const allLabs = JSON.parse(JSON.stringify(response.Laboratories));
        if (allLabs) {
          allLabs.unshift({
            LaboratoryId: 4,
            Laboratory: "All Laboratories",
          });
        }
        setAllLaboratories(allLabs || []);
        setTestItemTypes(response.TestItemTypes || []);
        setComponentFluidTypes(response.ComponentFluidTypes || []);
        setFluids(response?.Fluids || []);
        setFluidTypes(response?.FluidTypes || []);
        setParentsAll(response?.ParentsAll || []);

        //Products with AllProducts added at 0 index
        const allProd = JSON.parse(JSON.stringify(response.Products));
        if (allProd) {
          allProd.unshift({
            ProductId: 0,
            Product: "All Products",
            Colour: "",
          });
        }
        setAllProducts(allProd || {});
      }
    })();
  }, []);

  let location = useLocation();
  const [checkLocation, setCheckLocation] = useState("");

  useEffect(() => {
    // let check = localStorage.getItem("tenant-key");
    // if (check !== "900") {
    //   localStorage.clear();
    //   localStorage.setItem("tenant-key", 900);
    // } // runs on location, i.e. route, change
    if (location?.pathname === "/Chart") {
      setCheckLocation(location?.pathname);
    }
  }, [location]);
  // let isResolutionRestricted = useRestrictedResolution(1366, 651);

  return (
    <>
      {user.UserId ? (
        // isResolutionRestricted && checkLocation !== "/Chart" ? (
        //   <Typography sx={{ color: "red", fontSize: 13 }}>
        //     {appResources.SCREEN_NOT_SUPPORTED}
        //   </Typography>
        // ) :
        (
          <>
            <AppTitleBar title={t("AppName")} />
            <Box sx={{ marginTop: "43px", p: 2 }}>
              <AppRouter user={user} />
            </Box>
            {/* <Footer /> */}
          </>
        )
      ) : (
        <Routes>
          <Route
            path="/user-not-found"
            element={<UserNotFound account={account} />}
          />
        </Routes>
      )}
    </>
  );
}

function ToastMessage() {
  const { toastOptions, setToastOption } = useRoot();
  const handleClose = (event, reason) => {
    // setToastOption({});
    setToastOption((prevState) => ({ ...prevState, open: false }));
  };
  return (
    <div>
      <Snackbar
        // open={!!toastOptions.text}
        open={toastOptions.open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={toastOptions.severity}
          sx={{ width: "100%" }}
        >
          {toastOptions.text}
        </Alert>
      </Snackbar>
    </div>
  );
}

function ErrorComponent() {
  const theme = useTheme();
  const { errorText, setErrorText } = useRoot();
  return (
    errorText && (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 1,
          bgcolor: theme.palette.error.dark,
          color: theme.palette.common.white,
          position: "fixed",
          zIndex: 2001,
        }}
      >
        <Grid item>
          <Grid container alignItems="center">
            <Error />
            <Typography variant="subtitle2" sx={{ ml: theme.spacing(0.5) }}>
              {errorText}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton onClick={() => setErrorText("")} color="inherit">
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    )
  );
}
const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: msai?.instrumentationKey, //process.env.REACT_APP_APPINSIGHTS_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

appInsights.loadAppInsights();
export { reactPlugin, appInsights };

export default withAITracking(reactPlugin, App);

function MsalErrorComponent({ error }) {
  return <>An Error Occurred: {error}</>;
}

function LoadingComponent() {
  return <>Authentication is in progress...</>;
}
