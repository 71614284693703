import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function TemplatesTable({ data, rowSelected, selectedRow }) {
  const templateOptions = {
    columns: fromSiteColumns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.TemplateId,
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,

    sx: { height: 725 },
  };
  return <DataTable {...templateOptions} />;
}

const fromSiteColumns = [
  {
    headerName: "Template",
    field: "Template",
    width: 150,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
