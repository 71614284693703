export function useWindowPopups(windowType, data) {

    let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    let windowFeatures = "width=" + windowWidth + ",height=" + windowHeight + ",top=0,left=0,resizable,scrollbars=yes";

    if (
        windowType === WindowTypes.WEBSITE_CONTACTS ||
        windowType === WindowTypes.ACCESS_PERMISSION_OVERVIEW
    ) {
        localStorage.setItem("AccessPermissionOverview", JSON.stringify({
            SampleNumber: data?.SampleNumber,
            ParentId: data?.ParentId,
            ProductId: data?.ProductId,
            CustomerId: data?.CustomerId,
        }))
        return window.open(`/AccessPermissionOverview`, "_blank", windowFeatures);
    };
    if (windowType === WindowTypes.PRIMARY_CONTACTS_OVERVIEW) {
        localStorage.setItem("primary-contacts-overview", JSON.stringify({
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            SampleNumber: data?.SampleNumber,
            ReportNumber: data?.ReportNumber,
        }))
        return window.open(`/primary-contacts-overview`, "_blank", windowFeatures);
    };
    if (windowType === WindowTypes.EXPORT_CONTACTS_OVERVIEW) {
        localStorage.setItem("export-contacts-overview", JSON.stringify({
            ContactId: data?.ContactId,
            ProductId: data?.ProductId,
            SiteSetId: data?.SiteSetId,
            ParentId: data?.ParentId,
            CustomerId: data?.CustomerId,
            SiteId: data?.SiteId,
            UnitId: data?.UnitId,
            ComponentId: data?.ComponentId,
            SampleNumber: data?.SampleNumber,
            ReportNumber: data?.ReportNumber,
        }))
        return window.open(`/export-contacts-overview`, "_blank", windowFeatures);
    };
    if (windowType === WindowTypes.REPORT_CONTACTS) {
        localStorage.setItem("report-contacts", JSON.stringify({
            ComponentId: data?.ComponentId,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
        }))
        return window.open(`/report-contacts`, "_blank", windowFeatures);
    };
    if (windowType === WindowTypes.SUBSCRIPTION_OVERVIEW) {
        localStorage.setItem("subscription-overview", JSON.stringify({
            CustomerId: data?.CustomerId
        }))
        return window.open(`/subscription-overview`, "_blank", windowFeatures);
    };
    if (windowType === WindowTypes.UNIT_HOUR_HISTORY) {
        localStorage.setItem("unit-hour-history", JSON.stringify({
            unitId: data?.UnitId
        }))
        return window.open(`/unit-hour-history`, "_blank", windowFeatures);
    }
    if (windowType === WindowTypes.CRITIICAL_LOG) {
        localStorage.setItem("add-critical-logs", JSON.stringify({
            SampleNumber: data?.SampleNumber
        }))
        return window.open(`/add-critical-logs`, "_blank", windowFeatures);
    }
    if (windowType === WindowTypes.HOLD_MANAGEMENT) {
        localStorage.setItem("hold-management", JSON.stringify({
            SampleNumber: data?.SampleNumber,
            unitId: data?.UnitId
        }))
        return window.open(`/management/hold-management`, "_blank", windowFeatures);
    }
    if (windowType === WindowTypes.RECENT_UNIT_RESULTS) {
        localStorage.setItem("recent-unit-results", JSON.stringify({
            UnitId: data?.UnitId
        }))
        return window.open(`/recent-unit-results`, "_blank", windowFeatures);
    }
}

export const WindowTypes = {
    CRITIICAL_LOG: "critical-log",
    ACCESS_PERMISSION_OVERVIEW: "access-permission-overview",
    PRIMARY_CONTACTS_OVERVIEW: "primary-contacts-overview",
    EXPORT_CONTACTS_OVERVIEW: "export-contacts-overview",
    REPORT_CONTACTS: "report-contacts",
    SUBSCRIPTION_OVERVIEW: "subscription-overview",
    UNIT_HOUR_HISTORY: "unit-hour-history",
    HOLD_MANAGEMENT: "hold-management",
    RECENT_UNIT_RESULTS: "recent-unit-results",

}
