import { useTheme } from "@emotion/react";
import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import Form, { FormActions } from "../../components/Form";
import useMasterData from "../../hooks/useMasterData";
import { CONTACTS_APIS } from "../../common/apis";
import useFetch from "../../hooks/useFetch";
import { useToast } from "../../rootContext";
import appResources from "../../app-resources/en/Resources.json";
export default function SiteSetSearch({ ContactId, setDialog, getData }) {
  const [data, dispatch] = useReducer(SearchFilterReducer, defaultProducts);
  const { getMasterData } = useMasterData();
  const curTheme = useTheme();
  const { get, post } = useFetch();
  const { showWarningToast } = useToast();
  //get api for loading data
  const [masterData, setMasterData] = useState({});
  const [productsData, setProductsData] = useState([]);
  useEffect(function () {
    (async function () {
      const temp = await getMasterData("UserProducts");
      if (temp.UserProducts) {
        temp.UserProducts.unshift({ Product: "All Products", ProductId: 0 });
      }
      setMasterData(temp);
    })();
  }, []);
  //use memo
  const filteredData = useMemo(
    () =>
      data?.ProductId
        ? productsData
        : (productsData || []).filter((x) => {
            return productsData;
          }),
    [data, productsData]
  );
  const tblOptions = {
    columns: GetColumns(selectClick),
    dataSource: filteredData || [],
    rowIdProperty: "SiteSetId",
    isDisableColumnFilter: true,
    cellStylingHandler: "siteSetProduct",
    sx: { height: 500, minWidth: 500 },
  };
  async function selectClick(rowData) {
    await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, {
      ContactId: ContactId,
      SiteSetId: rowData.SiteSetId,
      Screen: "Contact Access Permissions",
    });
    setDialog((prevState) => ({ ...prevState, open: false }));
    getData();
  }
  //Save data
  async function searchClick() {
    let resData = await get(
      `${CONTACTS_APIS.SITE_SETS_FOR_CONTACT}?ContactId=${ContactId}&ExcludeScoped=TRUE&ProductId=${data.ProductId}`
    );
    if (resData) {
      setProductsData(resData);
    } else {
      setProductsData();
      showWarningToast(appResources.NO_MATCHING_SITESETS_WERE_FOUND_MSG);
    }
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(masterData, searchClick)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(selectClick) {
  return [
    {
      headerName: "Select",
      field: "Select",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 200,
    },
    {
      headerName: "Site Set",
      field: "SiteSet",
      minWidth: 200,
    },

    {
      headerName: "Automatic",
      field: "IsAutoUpdated",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
    },
    {
      headerName: "Archived",
      field: "ProductArchived",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
      flex: 1,
    },
  ];
}

function GetFormSections(masterData, searchClick) {
  return [
    {
      fields: [
        {
          name: "ProductId",
          label: "Product",
          xsCol: 4,
          type: "dropdown",
          selectOptions: (masterData.UserProducts || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
        },
        {
          mdCol: 4,
          component: function () {
            return (
              <Button sx={{ mt: 1.5 }} onClick={searchClick}>
                Search
              </Button>
            );
          },
        },
      ],
    },
  ];
}
const defaultProducts = {
  ProductId: 0,
};
const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
