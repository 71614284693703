import { memo } from "react";
import DataTable from "../../../components/DataTable";

export default memo(function ResultsTable({ data, columns, rowSelected, sx }) {
  const options = {
    columns: columns || [],
    dataSource: data || [],
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    rowStylingHandler: "sample",
    cellStylingHandler: "sampleBackGroundWhite",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    minHeight: 30,
    heightValue: 100,
  };
  return <DataTable {...options} sx={sx} />;
});
