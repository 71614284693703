import DataTable from "../../components/DataTable";
import renderCellExpand from "./../../components/DatatableCellExpand";

export default function ParentsCustomersTable({ data, rowSelected }) {
  const options = {
    columns: Columns,
    dataSource: data,
    rowIdProperty: "CustomerId",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    sx: {
      height: 720,
    },
  };
  return <DataTable {...options} />;
}

const Columns = [
  {
    headerName: "Product",
    field: "Product",
    minWidth: 150,
  },
  {
    headerName: "Customer",
    field: "Customer",
    minWidth: 300,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
