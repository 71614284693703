import { memo } from "react";
import DataTable from "../../../components/DataTable";

export default memo(function ImportHistoryTable({ columns, data }) {
  const options = {
    checkBoxSelect: true,
    columns: columns || [],
    dataSource: data || [],
    rowIdProperty: "labno",
    isDisableColumnFilter: true,
    minHeight: 30,
    heightValue: 700,
  };
  return <DataTable {...options} />;
});
