import { useTheme } from "@emotion/react";
import { Card, Grid, Typography } from "@mui/material";
import { useReducer, useState } from "react";
import useFetch from "../../hooks/useFetch";
import Form, { FormActions } from "../../components/Form";
import { ClearIcon, SearchIcon } from "../../icons/ButtonIcons";
import DataTable, { DataGridAction } from "../../components/DataTable";
import FormFooter from "../../components/FormFooter";
import AppDialog from "../../components/AppDialog";
import SearchContact from "../quick-details/SearchContact";

export default function SampleReportingOverview({ }) {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const curTheme = useTheme();
  const { post, postFile } = useFetch();

  const [feedbackData, setFeedbackData] = useState([]);
  const [searchContactDialogue, setSearchContactDialogue] = useState(false);
  // const [propId, setPropId] = useState();
  const actions = {
    reset: function (e) {
      dispatch({
        type: FormActions.RESET_FORM,
        payload: { ...SearchFilterDefaults },
      });
    },
    searchContact: function (e) {
      setSearchContactDialogue(true);
    },
  };

  const tblOptions = {
    columns: GetColumns(editClick),
    dataSource: (feedbackData || []).map((x) => ({
      ...x,
      keyProp: x.SampleNumber + "" + (x.UnitHours || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: { height: 685 },
  };

  function editClick() { }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
            />
            <FormFooter buttons={GetFormButtons(data, actions)} />
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {feedbackData && feedbackData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={searchContactDialogue}
        handleClose={() => setSearchContactDialogue(false)}
        title="Search Contacts"
        maxWidth="100vw"
      >
        <SearchContact />
      </AppDialog>
    </>
  );
}

function GetColumns(editClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "Access",
      field: "Access",
      minWidth: 150,
    },
    {
      headerName: "Reports",
      field: "Reports",
      minWidth: 150,
    },
    {
      headerName: "Subscriptions",
      field: "Subscriptions",
      minWidth: 150,
    },
    {
      headerName: "Primary",
      field: "Primary",
      minWidth: 150,
    },
    // {
    //   headerName: "Email",
    //   field: "Email",
    //   minWidth: 150,
    // },
    {
      headerName: "Name",
      field: "Name",
      minWidth: 150,
    },
    {
      headerName: "Scope",
      field: "Scope",
      minWidth: 150,
    },
    {
      headerName: "Email",
      field: "Email",
      minWidth: 150,
    },
    {
      headerName: "Sample",
      field: "Sample",
      minWidth: 150,
    },
    {
      headerName: "Excel",
      field: "Excel",
      minWidth: 150,
    },
    {
      headerName: "Machine",
      field: "Machine",
      minWidth: 150,
    },
    {
      headerName: "SMS",
      field: "SMS",
      minWidth: 150,
    },

    {
      headerName: "XML",
      field: "XML",
      minWidth: 150,
    },

    {
      headerName: "Mail",
      field: "Email",
      minWidth: 150,
    },
    {
      headerName: "Source",
      field: "Source",
      minWidth: 150,
    },
    {
      headerName: "Mobile",
      field: "Mobile",
      minWidth: 150,
    },
    {
      headerName: "Telephone",
      field: "Telephone",
      minWidth: 150,
    },
    {
      headerName: "Role",
      field: "Role",
      minWidth: 150,
    },
    {
      headerName: "Archived",
      field: "Archived",
      minWidth: 150,
    },

    {
      headerName: "Company",
      field: "Company",
      minWidth: 150,
      flex: 1,
    },
  ];
}
function GetFormSections() {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "Filter",
          label: "Filter",
        },
        {
          name: "KomatsuOnly",
          label: "Komatsu",
          type: "switch",
        },
        {
          name: "Other",
          label: "Other",
          type: "switch",
        },
        {
          name: "showAllAlerts",
          label: "Show All Alerts Levels, or",
          type: "switch",
        },
        {
          name: "AlertsFrom",
          label: "Alerts From",
          xsCol: 1,
          type: "number",
        },
        {
          name: "Product",
          label: "Product",
          type: "switch",
        },
        {
          name: "Parent",
          label: "Parent",
          type: "switch",
        },
        {
          name: "SiteSet",
          label: "Site Set",
          type: "switch",
        },
        {
          name: "Customer",
          label: "Customer",
          type: "switch",
        },
        {
          name: "Site",
          label: "Site",
          type: "switch",
        },
        {
          name: "Unit",
          label: "Unit",
          type: "switch",
        },
        {
          name: "Component",
          label: "Component",
          type: "switch",
        },
      ],
    },
  ];
}
const GetFormButtons = (data, actions) => [
  { label: "Reset", onClick: actions.reset, startIcon: <ClearIcon /> },
  {
    label: " Search Contacts",
    onClick: actions.searchContact,
    startIcon: <SearchIcon />,
  },
];
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  return state;
}
const SearchFilterDefaults = {
  Other: true,
  KomatsuOnly: true,
  showAllAlerts: true,
  AlertsFrom: 2,
  Product: true,
  Parent: true,
  SiteSet: true,

  Customer: true,
  Site: true,
  Unit: true,
  Component: true,
};
