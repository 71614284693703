import {
  Typography,
  Grid,
  InputLabel,
  Button,
  FormGroup,
  Select,
  MenuItem,
} from "@mui/material";
import { EditIcon, SaveIcon } from "../../../icons/ButtonIcons";
import TextBox from "../../../components/TextBox";
import DataTable from "../../../components/DataTable";
import { Delete } from "@mui/icons-material";
import useFetch from "../../../hooks/useFetch";
import { TRAY_APIS } from "../../../common/apis";
import { useEffect, useState } from "react";
import AppDialog from "../../../components/AppDialog";
import AlertDialog from "../../../components/AlertDialog";
import { useToast } from "../../../rootContext";
import { toLocalDayDate, toLocalDayDateTime } from "../../../utils";
import appResources from "../../../app-resources/en/Resources.json";
const AddNotes = ({ unitId }) => {
  const { get } = useFetch();

  const [notesData, setNotesData] = useState([]);
  const [selectedNote, setSelectedNote] = useState({});
  const [dropDownData, setDropDownData] = useState([]);
  const [notesDataList, setNotesDataList] = useState([]);
  const [ddVal, setddVal] = useState("All Notes");
  const { showToast, showErrorToast } = useToast();
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
  });

  async function getNotes() {
    const noteData = await get(
      `${TRAY_APIS.NOTES_BY_UNIT}?UnitId=${unitId}`,
      {}
    );
    setNotesData(noteData);
    setNotesDataList(noteData);
    noteRowSelectedHandler(noteData[0]);

    let arr = noteData.map((item) => {
      return item.Component;
    });

    let arr2 = arr.filter(function (item, index) {
      if (arr.indexOf(item) == index) return item;
    });
    setDropDownData(["All Notes", ...arr2]);
  }

  useEffect(() => {
    getNotes();
  }, []);

  async function handleDeleteClick() {
    setDialog({
      open: true,
      message: "Do you wish to delete the selected note?",
    });
  }
  async function handleDeleteClickAlert() {
    const response = await get(
      `${TRAY_APIS.DELETE_NOTES}?NoteId=${selectedNote?.NoteId}&Screen=Notes`,
      { skipBlocker: true }
    );
    if (response > 0) {
      showToast(appResources.DELETED_SUCCESSFULLY_MSG);
      getNotes();
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }

    setDialog((prevState) => ({ ...prevState, open: false }));
  }

  async function handleSaveClick() {
    const sampleData = await get(
      `${TRAY_APIS.SAVE_NOTES_BY_UNIT}?NoteId=${
        selectedNote?.NoteId
      }&Note=${selectedNote?.Note.toString()}&Screen=Notes`
    );
  }

  const noteRowSelectedHandler = async (note) => {
    if (note?.noteNumber && note?.noteNumber === selectedNote.noteNumber)
      return;

    // await loadMoreData(note.noteNumber);
    setSelectedNote(note || {});
  };

  function GetColumns() {
    return [
      {
        headerName: "Component",
        field: "Component",
        minWidth: 150,
      },
      {
        headerName: "Added",
        field: "Added",
        minWidth: 150,
      },
      {
        headerName: "Note",
        field: "Note",
        minWidth: 500,
      },
    ];
  }

  const options = {
    columns: GetColumns(),
    dataSource: notesDataList,
    rowIdProperty: "NoteId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && noteRowSelectedHandler(r[0]);
    },
  };

  const handleDDFilter = (e) => {
    setddVal(e.target.value);
    if (e.target.value === "All Notes") {
      return setNotesDataList(notesData);
    } else {
      let newArray = notesData.filter(function (el) {
        return el.Component === e.target.value;
      });
      setNotesDataList(newArray);
    }
  };

  return (
    <>
      <Grid container spacing={1} sx={{ marginBottom: "10px" }}>
        <Grid item xs={4}>
          <InputLabel>Component</InputLabel>
          <FormGroup row={true}>
            <Select
              value={ddVal}
              onChange={handleDDFilter}
              inputProps={{ "aria-label": "Notes" }}
            >
              {dropDownData.map((item, i) => {
                return (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormGroup>
        </Grid>
      </Grid>
      <DataTable {...options} />
      <Grid container spacing={2} sx={{ mt: "5px", width: "850px" }}>
        <Grid item xs={6}>
          <Typography>{`Added by: ${toLocalDayDateTime(selectedNote?.Added)}  ${
            selectedNote?.AddedBy
          }`}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ textAlign: "right" }}>{`Updated by: ${
            toLocalDayDateTime(selectedNote?.Updated) || ""
          }  ${selectedNote?.UpdatedBy || ""}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextBox
            label={"Notes"}
            multiline
            rows={3}
            value={selectedNote?.Note}
            onChange={(e) => {
              setSelectedNote({ ...selectedNote, Note: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row-reverse" spacing={1}>
            <Grid item>
              <Button
                item
                variant="contained"
                onClick={handleSaveClick}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button
                item
                disabled={!selectedNote?.NoteId}
                variant="outlined"
                onClick={handleDeleteClick}
                startIcon={<Delete />}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Note"
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={handleDeleteClickAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
};

export default AddNotes;
