import { useEffect, useReducer, useState } from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import Form, { FormActions } from "../../../components/Form";
import { Search, } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import UnitTable from "../../quick-details/tables/UnitTable";
import useMasterData from "../../../hooks/useMasterData";
import { ClearIcon, } from "../../../icons/ButtonIcons";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";

export default function SelectUnitManagement({ unitData }) {

    // const [data, dispatch] = useReducer(UnitDetailsReducer, { ProductId: unitData?.ProductId, Customer: unitData?.Customer, Site: unitData?.Site });
    const [data, dispatch] = useReducer(UnitDetailsReducer, UnitManagementDefaults);

    const { post } = useFetch();

    const { getMasterData } = useMasterData();
    const [productsList, setProductsList] = useState([]);
    const [tableData, setTableData] = useState([]);

    const formActions = {
        search: async function () {
            const temp = await post(DETAILS_APIS.SEARCH_UNITS, data);
            setTableData(temp);
        },
        clear: function (e) {
            dispatch({ type: FormActions.RESET_FORM });
        },
    };

    useEffect(function () {
        (async function () {
            const temp = await getMasterData("Products");
            if (temp.Products) {
                temp.Products.unshift(
                    { ProductId: 0, Product: "All Products", }
                );
            }
            setProductsList(temp.Products);
        })();
    }, []);

    const FormSections = useFormSections(productsList);

    return (
        <>
            <Card sx={{ p: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Form
                            sections={FormSections}
                            data={data}
                            spacing={2}
                            dispatch={dispatch}
                            key={data?.UnitId}
                        />
                    </Grid>
                </Grid>

                <FormFooter buttons={GetFormButtons(formActions)} gmt={2} />

                <Typography variant="subtitle2" style={{ marginTop: "-25px" }}>
                    Total Records: <b> {tableData?.length}</b>
                </Typography>
            </Card>

            <Box sx={{ mt: 1 }}>
                <UnitTable
                    data={tableData || []}
                    rowSelected={{}}
                    rowActionHandler={{}}
                    actions={{}}
                    rowIdProperty={"UnitId"}
                />
            </Box>
        </>
    );
}

const GetFormButtons = ({ search, clear }) => [
    {
        variant: "outlined",
        label: "Clear",
        onClick: clear,
        startIcon: <ClearIcon />,
    },
    {
        variant: "contained",
        label: "Search",
        onClick: search,
        startIcon: <Search />,
    },
];

const useFormSections = (productsList) => {
    return [
        {
            fields: [
                {
                    xsCol: 2,
                    name: "ProductId",
                    label: "Product",
                    type: "dropdown",
                    selectOptions: (productsList || []).map((x) => ({
                        value: x.ProductId,
                        text: x.Product,
                    }))
                },
                { name: "Unit", label: "Unit", xsCol: 2 },
                { name: "Make", label: "Make", xsCol: 2 },
                { name: "Model", label: "Model", xsCol: 2 },
                { name: "Serial", label: "Serial", xsCol: 2 },
                { name: "Customer", label: "Customer", xsCol: 2 },
                { name: "Site", label: "Site", xsCol: 2 },
            ]
        }]
}

function UnitDetailsReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    if (action.type === FormActions.RESET_FORM) {
        // return { ...UnitManagementDefaults };
        return { _key: Date.now() };

    }
}
const UnitManagementDefaults = { Customer: "8898", Site: "Site22573", ProductId: 2, };