import { LogLevel } from "@azure/msal-browser";
export const msalConfig = {
  auth: {
    clientId: "e6da947e-d5c3-4b9f-b714-395a97707cea", //"e3373b3f-c2e9-4e0e-80dc-c0e0b2c3409b", // app registraion front-end web client
    authority:
    "https://login.microsoftonline.com/a6bd55f8-4d88-4103-904e-9287fc1eec88",
      // "https://login.microsoftonline.com/879bb0b5-82db-44f4-90a6-2e5a8e6facee", // KLTD Dev tenant for authentication
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            //   console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
export const loginRequest = {
  scopes: ["api://e6da947e-d5c3-4b9f-b714-395a97707cea/api.scope"],
 //this key is used for api authorization.
};

export const msai = {
  instrumentationKey: "5afbfb76-07f0-4628-be12-93c0d831eaeb"//MS Azure App insights key
}