import { Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import Form, { FormActions } from "../../../components/Form";

import useFetch from "../../../hooks/useFetch";
import ComponentHistoryTable from "./ComponentHistoryTable";
import { toLocalDate } from "../../../utils";
import { ResultColWidths } from "../../../common/Constants";
import { DETAILS_APIS } from "../../../common/apis";

export default function RecentComponentResults({ allData }) {

  const [dispatchData, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);
  const { get, getFile } = useFetch();

  const [history, setHistory] = useState({});
  const [historySimple, setHistorySimple] = useState({});
  const [history1, setHistory1] = useState({});
  const [historySimple1, setHistorySimple1] = useState({});
  const [componentDetails, setComponentDetails] = useState({});

  //get data for grid
  useEffect(() => {
    if (allData?.UnitId > 0) {
      (async function () {
        let resData = await get(`${DETAILS_APIS?.COMPONENT_HISTORY}?componentId=${allData?.ComponentId || 0}&resultView=${dispatchData?.filterDropdown || 0}`);
        if (resData) {
          const { columns, data } = getFullHistory(resData, dispatchData);
          const { columns1, data1 } = getSimpleHistory(resData, dispatchData);

          let newData = data?.filter(item => item.Rank <= 2)
          let newData1 = data1?.filter(item => item.Rank <= 2)

          setHistory({ columns, data });
          setHistorySimple({ columns1, data1 });
          setHistory1({ columns, newData });
          setHistorySimple1({ columns1, newData1 });
          setComponentDetails(resData?.ComponentHistorySimpleHeader[0] || {})
        }
      })();
    }
  }, [dispatchData?.filterDropdown]);

  useEffect(() => {
    if (Object.entries(history).length > 0 && dispatchData?.LatestSamplesOnly === true) {
      let data = history?.data?.filter(item => item.Rank <= 2)
      let data1 = historySimple?.data1?.filter(item => item.Rank <= 2)
      setHistory1({ ...history, data });
      setHistorySimple1({ ...historySimple, data1 });
    }
    if (Object.entries(history).length > 0 && dispatchData?.LatestSamplesOnly === false) {
      let data = history?.data?.filter(item => item.Rank < 8)
      let data1 = historySimple?.data1?.filter(item => item.Rank < 8)
      setHistory1({ ...history, data });
      setHistorySimple1({ ...historySimple, data1 });
    }
  }, [dispatchData?.LatestSamplesOnly]);

  async function recentComponentExcelClick() {
    await getFile(`${DETAILS_APIS?.EXPORT_COMPONENT_HISTORY}?componentId=${allData?.ComponentId}`, "Recent Component History");
  }

  return (
    <>
      <Grid container width={"95vw"}>
        <Grid item xs={12}>
          <Typography >{`${componentDetails?.Product || ""} ${componentDetails?.Customer || ""} ${componentDetails?.Site || ""} ${componentDetails?.Unit || ""} ${componentDetails?.UnitModelFormatted || ""} ${componentDetails?.Component || ""}`}</Typography>
          <Typography >{`Default Oil ${componentDetails?.Product || ""} Default Testset ${componentDetails?.Product || ""}`}</Typography>
        </Grid>

        <Grid item xs={6} mt={1}>
          <Typography sx={{ color: "green" }}> {history1?.data1?.length || history1?.newData?.length || historySimple1?.data1?.length} sample(s) displayed of the most recent 1 per component.</Typography>
        </Grid>

        <Grid item xs={6} float="right">
          <Form
            sections={GetFormSections(recentComponentExcelClick, allData)}
            data={dispatchData}
            dispatch={dispatch}
            sx={{ justifyContent: "flex-end" }}
          />
        </Grid>

        <Grid item xs={12} mt={1}>
          <ComponentHistoryTable
            columns={
              dispatchData?.SimplesView === true
                ? (historySimple1?.columns1)
                : (history1?.columns)
            }
            data={
              dispatchData?.SimplesView === true
                ? (historySimple1?.data1 || historySimple1?.newData1)
                : (history1?.data || history1?.newData)
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

function GetFormSections(recentComponentExcelClick, allData) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "SimplesView",
          label: "Simples View",
          type: "switch",
        },
        {
          name: "LatestSamplesOnly",
          label: "Latest Samples Only",
          type: "switch",
        },
        {
          label: "Filter",
          name: "filterDropdown",
          type: "dropdown",
          alignSelf: "end",
          xsCol: 3,
          required: true,
          selectOptions: getOptions(),
        },
        {
          component: function ({ data }) {
            return (
              <>
                <Button
                  variant="outlined"
                  onClick={recentComponentExcelClick}
                  sx={{ mt: "15px" }}
                  disabled={!(allData?.ComponentId > 0)}
                >
                  Export
                </Button>
              </>
            )
          }
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

const SearchFilterDefaults = {
  SimplesView: true,
  LatestSamplesOnly: true,
  filterDropdown: 0,
};

function getOptions() {
  return [
    { Id: 1, value: 0, text: "Unadjusted" },
    { Id: 2, value: 1, text: "Divide by Fluid Hours" },
    { Id: 3, value: 2, text: "Adjust for Fluid Added" },
  ];
}

//columns for History
function getFullHistory({
  ComponentHistory: data,
  ComponentHistorySimpleHeader: sampleData,
}) {
  const dataObject = {};
  const colMap = {};

  const samplesMap = {};
  let hasSif = false;
  sampleData?.forEach(function (s) {
    if (s.SIF) {
      hasSif = true;
    }
    samplesMap[s.SampleNumber] = s;
  });

  data?.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
    }
    if (x.TestItemCode === "ISO3") {
      dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    } else {
      dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    }
    if (x.FormattedNumeric === 0 || x.FormattedNumeric < 0) {
      dataObject[x.SampleNumber][x.TestItemCode] = "<1";
    }
    if (colMap[x.TestItemCode]) {
      return;
    }
    colMap[x.TestItemCode] = x;
  });

  const columnsSimple = Object.keys(colMap)
    .map((x) => colMap[x])
    .sort(sortFunction)
    .map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      minWidth: ResultColWidths[x.TestItemCode] || 50,
      flex: 1,
    }));

  const col0 = [
    {
      headerName: "Component",
      field: "Component",
      minWidth: 120,
    },
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      minWidth: ResultColWidths["SampleNumber"] || 80,
    },
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
      minWidth: 85,
      maxWidth: 85,
    },

  ];

  const col1 = [

    {
      headerName: "Status",
      field: "AlertStatus",
      description: "Alert Status",
      minWidth: 90,
      maxWidth: 90,
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      minWidth: 80,
      maxWidth: 80,
    },

  ];

  const col2 = [
    {
      headerName: "UH",
      field: "UnitHours",
      description: "Unit Hours",
      minWidth: 49,
    },
    {
      headerName: "CH",
      field: "ComponentHours",
      description: "Component Hours or KMs",
      minWidth: 49,
    },
    {
      headerName: "FH",
      field: "FluidHours",
      description: "Fluid Hours",
      minWidth: 50,
    },
  ];

  const col3 = [
    {
      headerName: "Fluid",
      field: "FluidFormatted",
      description: "Fluid Formatted",
      minWidth: 150,
    },
    {
      headerName: "CR",
      field: "ComponentRepaired",
      description: "Component Repaired",
      minWidth: 45,
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      headerName: "FC",
      field: "FluidChanged",
      description: "Fluid Changed",
      minWidth: 45,
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      headerName: "Topup",
      field: "Topup",
      description: "Topup",
      minWidth: 54,
    },
    {
      headerName: "Filter",
      field: "FilterChanged",
      description: "Filter Changed",
      minWidth: 55,
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    },
  ];

  const col4 = [
    {
      headerName: "Evaluation",
      field: "Evaluation",
      description: "Evaluation",
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "Recommendation",
      field: "Recommendation",
      description: "Recommendation",
      minWidth: 250,
      flex: 1,
    },
  ]

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }

  return {
    columns: [...col0, ...col1, ...col2, ...col3, ...columnsSimple, ...col4],
    data: dataSrc,
  };
}
function getSimpleHistory({
  ComponentHistory: data,
  ComponentHistorySimpleHeader: sampleData,
}) {
  const dataObject = {};
  const colMap = {};

  const samplesMap = {};
  let hasSif = false;
  sampleData?.forEach(function (s) {
    if (s.SIF) {
      hasSif = true;
    }
    samplesMap[s.SampleNumber] = s;
  });

  data?.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
    }
    if (x.TestItemCode === "ISO3") {
      dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    } else {
      dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    }
    if (x.FormattedNumeric === 0 || x.FormattedNumeric < 0) {
      dataObject[x.SampleNumber][x.TestItemCode] = "<1";
    }
    if (colMap[x.TestItemCode]) {
      return;
    }
    colMap[x.TestItemCode] = x;
  });

  const columnsSimple = Object.keys(colMap)
    .map((x) => colMap[x])
    .sort(sortFunction)
    .map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      minWidth: ResultColWidths[x.TestItemCode] || 50,
      flex: 1,
    }));

  const col0 = [
    {
      headerName: "Component",
      field: "Component",
      minWidth: 120,
    },
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      minWidth: ResultColWidths["SampleNumber"] || 80,
    },
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
      minWidth: 85,
      maxWidth: 85,
    },
  ];

  const col2 = [
    {
      headerName: "UH",
      field: "UnitHours",
      description: "Unit Hours",
      minWidth: 49,
    },
    {
      headerName: "CH",
      field: "ComponentHours",
      description: "Component Hours or KMs",
      minWidth: 49,
    },
    {
      headerName: "FH",
      field: "FluidHours",
      description: "Fluid Hours",
      minWidth: 50,
    },
  ];

  const columnsSimpleTrue = [...col0, ...col2, ...columnsSimple,];

  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }

  return {
    columns1: columnsSimpleTrue,
    data1: dataSrc,
  };
}
function sortFunction(a, b) {
  return (
    a.TechnicianRank - b.TechnicianRank ||
    a.TestItemCode.localeCompare(b.TestItemCode)
  );
}
