import { Box, Grid, Typography } from "@mui/material";
import { useMemo } from "react";

const TraySummary = ({
  summary = {},
  data: { Trays: trays, LabId: labId },
  trayDate,
  labs = [],
}) => {
  const traySelected = !!summary.Tray;
  let traysVal = trays.length;

  let samplesVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.SampleCount;
      }, 0),
    [trays]
  );

  let registerVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.Step1SampleCount;
      }, 0),
    [trays]
  );

  let laboratoryVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.Step2SampleCount;
      }, 0),
    [trays]
  );

  let qualityVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.Step3SampleCount;
      }, 0),
    [trays]
  );

  let autoEvalVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.Step4SampleCount;
      }, 0),
    [trays]
  );

  let evalVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.Step5SampleCount;
      }, 0),
    [trays]
  );

  let approvalVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.Step6SampleCount;
      }, 0),
    [trays]
  );

  let completedVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.Step8SampleCount;
      }, 0),
    [trays]
  );

  let rerunsVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.Reruns;
      }, 0),
    [trays]
  );

  let newVersionsVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.NewVersions;
      }, 0),
    [trays]
  );

  let onHoldVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.HoldNotReruns;
      }, 0),
    [trays]
  );

  let criticalLogsVal = useMemo(
    () =>
      trays.reduce(function (total, item) {
        return total + item.CriticalLogs;
      }, 0),
    [trays]
  );

  if (traySelected) {
    samplesVal = summary ? summary.SampleCount + " of " + samplesVal : "";
    traysVal = summary ? summary.Tray + " of " + traysVal : "";
    registerVal = summary.Step1SampleCount;
    laboratoryVal = summary.Step2SampleCount;
    qualityVal = summary.Step3SampleCount;
    autoEvalVal = summary.Step4SampleCount;
    evalVal = summary.Step5SampleCount;
    approvalVal = summary.Step6SampleCount;
    rerunsVal = summary.Reruns;
    newVersionsVal = summary.NewVersions;
    completedVal = summary.Step8SampleCount;
    onHoldVal = summary.HoldNotReruns;
    criticalLogsVal = summary.CriticalLogs;
  }

  let noTraysMsg;
  if (!trays.length) {
    noTraysMsg = `No trays were created for ${labs.find((x) => x.Id === labId)?.Name ?? ""
      }, ${trayDate && trayDate.format("dddd DD MMMM YYYY")}`;
  }

  if (noTraysMsg) {
    return (
      <Typography
        sx={{ textAlign: "center", color: "red", lineHeight: "40px" }}
      >
        {noTraysMsg}
      </Typography>
    );
  }

  return (
    <Box sx={{ width: "100%", p: 1, backgroundColor: "#D1E4F5" }}>
      <Typography variant="caption">
        <Grid container spacing={2}>
          <Grid item md={9}>
            <Grid container>
              <Grid item lg={1}>
                {traySelected ? "Tray" : "Trays"}
              </Grid>
              <Grid item lg={1}>
                <b>{traysVal}</b>
              </Grid>
              <Grid item lg={1}>
                Registration
              </Grid>
              <Grid item lg={1}>
                <b>{registerVal}</b>
              </Grid>
              <Grid item lg={1}>
                Quality
              </Grid>
              <Grid item lg={1}>
                <b>{qualityVal}</b>
              </Grid>
              <Grid item lg={1}>
                Approval
              </Grid>
              <Grid item lg={1}>
                <b>{approvalVal}</b>
              </Grid>
              <Grid item lg={1}>
                Reruns
              </Grid>
              <Grid item lg={1}>
                <b>{rerunsVal}</b>
              </Grid>
              <Grid item lg={1}>
                New Versions
              </Grid>
              <Grid item lg={1}>
                <b>{newVersionsVal}</b>
              </Grid>
              <Grid item lg={1}>
                Samples
              </Grid>
              <Grid item lg={1}>
                <b>{samplesVal}</b>
              </Grid>
              <Grid item lg={1}>
                Laboratory
              </Grid>
              <Grid item lg={1}>
                <b>{laboratoryVal}</b>
              </Grid>
              <Grid item lg={1}>
                Evaluation
              </Grid>
              <Grid item lg={1}>
                <b>{evalVal}</b>
              </Grid>
              <Grid item lg={1}>
                Completed
              </Grid>
              <Grid item lg={1}>
                <b>{completedVal}</b>
              </Grid>
              <Grid item lg={1}>
                On Hold
              </Grid>
              <Grid item lg={1}>
                <b>{onHoldVal}</b>
              </Grid>
              <Grid item lg={1}>
                Critical Logs
              </Grid>
              <Grid item lg={1}>
                <b>{criticalLogsVal}</b>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid item lg={12}>
              {trayDate && trayDate.format("MMMM")} Total{" "}
              {trays && trays[0].MonthRegistered} (
              {trays && trays[0].PrevMonthRegistered})
            </Grid>
            <Grid item lg={12} style={{ color: "red" }}>
              There are {trays.length} trays
            </Grid>
          </Grid>
        </Grid>
      </Typography>
    </Box>
  );
};

export default TraySummary;
