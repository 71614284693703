import { useTheme } from "@emotion/react";
import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useReducer, useState } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";
import Form, { FormActions } from "../../components/Form";
import useFetch from "../../hooks/useFetch";
import { SearchIcon } from "../../icons/ButtonIcons";
import FormFooter from "../../components/FormFooter";
import { CONTACTS_APIS } from "../../common/apis";
import appResources from "../../app-resources/en/Resources.json";
import renderCellExpand from "../../components/DatatableCellExpand";
export default function SearchKomatsuEmployee({
  komatsuEmployeeClick,
  setDialog,
  Username,
}) {
  const [data, dispatch] = useReducer(SearchFilterReducer);
  const curTheme = useTheme();
  const { post } = useFetch();

  const [komatsuEmployeeData, setKomatsuEmployeeData] = useState();
  const [isValidSearch, setIsValidSearch] = useState(false);

  const tblOptions = {
    columns: GetColumns(selectClick),
    dataSource: komatsuEmployeeData || [],
    rowIdProperty: "Id",
    isDisableColumnFilter: true,
    sx: { height: 600 },
  };
  useEffect(
    function () {
      if (Username) {
        dispatch({
          payload: {
            Mail: Username,
            _key: Date.now(),
          },
          type: FormActions.LOAD_DATA,
        });
        formActions.searchContact();
      }
    },
    [Username]
  );
  const formActions = {
    searchContact: async function (e) {
      if (
        (data && data.Mail && data.Mail.trim()) ||
        (data && data.FirstName && data.FirstName.trim()) ||
        (data && data.LastName && data.LastName.trim())
      ) {
        setIsValidSearch(false);
        const responseData = await post(CONTACTS_APIS.SEARCH_KOMATSU_EMPLOYEE, {
          ...data,
        });
        setKomatsuEmployeeData(responseData);
      } else {
        setIsValidSearch(true);
      }
    },
  };
  function selectClick(rowData) {
    komatsuEmployeeClick(rowData);
    setDialog((prevState) => ({ ...prevState, open: false }));
  }
  return (
    <>
      <Card sx={{ p: 1, minWidth: "91vw" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Form
              sections={GetFormSections()}
              data={data}
              dispatch={dispatch}
              key={data?._key}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: -5.5 }}>
            <FormFooter buttons={GetFormButtons(formActions)} />
          </Grid>
        </Grid>
      </Card>

      <Grid container>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          {!isValidSearch ? (
            <Typography variant="subtitle2">
              Total Records: <b> {komatsuEmployeeData?.length}</b>
            </Typography>
          ) : (
            <Typography variant="subtitle2" sx={{ color: "red", fontSize: 12 }}>
              {appResources.PROVIDE_SEARCH_VALUE}
            </Typography>
          )}

          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(selectClick) {
  return [
    {
      headerName: "Select",
      field: "Select",
      sortable: false,
      maxWidth: 80,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Username",
      field: "Mail",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Source",
      field: "Source",
      minWidth: 130,
    },
    {
      headerName: "Komatsu",
      field: "Komatsu",
      minWidth: 100,
    },
    {
      headerName: "Forename",
      field: "GivenName",
      minWidth: 130,
    },
    {
      headerName: "Surname",
      field: "Surname",
      minWidth: 130,
    },
    {
      headerName: "Display",
      field: "DisplayName",
      minWidth: 150,
    },
    {
      headerName: "Telephone",
      field: "Telephone",
      minWidth: 120,
    },
    {
      headerName: "Mobile",
      field: "MobilePhone",
      minWidth: 150,
    },
    {
      headerName: "State",
      field: "State",
      minWidth: 120,
    },
    {
      headerName: "Country",
      field: "Country",
      minWidth: 150,
    },
    {
      headerName: "AccountLocked",
      field: "AccountLocked",
      minWidth: 100,
    },
    {
      headerName: "AccountExpired",
      field: "AccountExpired",
      minWidth: 100,
    },
  ];
}
function GetFormSections() {
  return [
    {
      fields: [
        {
          name: "Mail",
          label: "Mail",
          xsCol: 3,
        },
        {
          name: "FirstName",
          label: "First Name",
          xsCol: 3,
        },
        {
          name: "LastName",
          label: "Last Name",
          xsCol: 3,
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
const GetFormButtons = ({ searchContact }) => [
  {
    label: "Search",
    onClick: searchContact,
    startIcon: <SearchIcon />,
  },
];
