import { combineReducers } from "redux";
import CustomerReducer from "./CustomerReducer";
import SiteReducer from "./SiteReducer";
import UnitReducer from "./UnitReducer";
import ComponentReducer from "./ComponentReducer";

export default combineReducers({
    customer: CustomerReducer,
    site: SiteReducer,
    unit: UnitReducer,
    component: ComponentReducer,
});

