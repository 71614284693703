import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import {
  useFormSections,
  RegistrationActions,
  RegistrationFormReducer,
  useFormButtons,
} from "./edit-registration/EditRegistration.form";
import useFetch from "../../../hooks/useFetch";
import { useBlockScreen, useRoot, useToast } from "../../../rootContext";
import useMasterData from "../../../hooks/useMasterData";
import Form from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import AppDialog from "../../../components/AppDialog";
import { TRAY_APIS } from "../../../common/apis";
import AlertDialog from "../../../components/AlertDialog";
import { useLocation } from "react-router-dom";
import appResources from "../../../app-resources/en/Resources.json";
const ScreenName = "Edit Registration";

export default function EditRegistration({
  regId,
  editCheck,
  setEditRegId,
  getRegistration,
  searchRegClickHandler,
}) {
  const { masterData, setMasterData } = useMasterData(
    "Customers,ComponentLocations,FluidTypes,Measures,ServiceTypes"
  );

  const { CanRegister } = useRoot();
  const loc = useLocation();
  const { block, unblock } = useBlockScreen();
  const { get, post, getFile } = useFetch();
  const { showToast } = useToast();
  const [dialog, setDialog] = useState({ open: false, message: "", });
  const [data, dispatch] = useReducer(RegistrationFormReducer, {});
  const [location, setLocation] = useState("");
  const [componentData, setComponentData] = useState({});

  useEffect(function () {
    setLocation(loc.pathname);
    (async function () {
      try {
        if (!regId) return;
        block();
        const resp = await get(`/trays/registrations?regId=${regId}`, {
          skipBlocker: true,
        });
        if (resp) {
          let sitesResp = [];
          let unitsResp = [];
          let compResp = [];

          if (resp.Registration.CustomerId !== null) {
            sitesResp = await get(
              `/masterData/sites?custId=${resp.Registration.CustomerId}`,
              { skipBlocker: true }
            );
          }
          if (resp.Registration.SiteId !== null) {
            unitsResp = await get(
              `/masterData/units?siteId=${resp.Registration.SiteId}`,
              { skipBlocker: true }
            );
          }
          if (resp.Registration.UnitId !== null) {
            compResp = await get(
              `/masterData/components?unitId=${resp.Registration.UnitId}`,
              { skipBlocker: true }
            );
          }

          setMasterData((x) => ({
            ...x,
            Sites: sitesResp,
            Units: unitsResp,
            Components: compResp,
          }));

          resp.Registration &&
            dispatch({
              type: RegistrationActions.LOAD_DATA,
              payload: resp.Registration,
            });
          setComponentData(resp.Component)
          return;
        }
      } finally {
        unblock();
      }
    })();
  }, []);

  const onChange = useCallback(async function (name, value) {
    if (name === "CustomerId" && value) {
      const sitesResp = await get(`/masterData/sites?custId=${value}`);
      setMasterData((x) => ({
        ...x,
        Sites: sitesResp,
        Units: [],
        Components: [],
      }));
      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          CustomerId: value,
          SiteId: null,
          UnitId: null,
          ComponentId: null,
        },
      });
    } else if (name === "SiteId" && value) {
      const unitsResp = await get(`/masterData/units?siteId=${value}`);
      setMasterData((x) => ({
        ...x,
        Units: unitsResp,
        Components: [],
      }));
      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          SiteId: value,
          UnitId: null,
          ComponentId: null,
        },
      });
    } else if (name === "UnitId" && value) {
      const compResp = await get(`/masterData/components?unitId=${value}`);
      const {
        UnitMake: UnitMakeOriginal = null,
        UnitModel: UnitModelOriginal = null,
        Serial: SerialOriginal = null,
      } = (masterData.Units || []).find((x) => x.UnitId === value) || {};

      setMasterData((x) => ({
        ...x,
        Components: compResp,
      }));

      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          UnitId: value,
          ComponentId: null,
          UnitMakeOriginal,
          UnitModelOriginal,
          SerialOriginal,
        },
      });
    } else if (name === "ComponentId" && value) {
      const {
        Component: ComponentOriginal = null,
        ComponentId: ComponentIdOriginal = null,
        ComponentMake: ComponentMakeOriginal = null,
        ComponentModel: ComponentModelOriginal = null,
        Location: ComponentLocationOriginal = null,
        LocationId: ComponentLocationId = null,
        Capacity: CapacityOriginal = null,
        Serial: ComponentSerialOriginal = null,
        ComponentFluidTypeId: FluidTypeId = null,
      } = (masterData.Components || []).find((x) => x.ComponentId === value) ||
        {};

      dispatch({
        type: RegistrationActions.RESET_FIELDS,
        payload: {
          ComponentId: value,
          ComponentOriginal,
          ComponentIdOriginal,
          ComponentMakeOriginal,
          ComponentModelOriginal,
          CapacityOriginal,
          ComponentSerialOriginal,
          FluidTypeId,
          ComponentLocationOriginal,
          ComponentLocationId,
        },
      });
    }
  }, []);

  const onCustomerChanged = useCallback(function (name, value) {
    onChange(name, value);
  }, []);

  const decoupleHandler = useCallback(
    function () {
      setDialog({
        open: true,
        title: "Decouple Registration",
        actionName: "decoupleHandlerAlert",
        message: `Do you wish to decouple this registration from sample number '${data.SampleNumber}'? You will remove the linkage between this registration and the sample`,
      });
    },
    [data.SampleNumber]
  );
  async function decoupleHandlerAlert() {
    const resp = await get(
      `/trays/decouple-reg?regId=${data.RegistrationId}&screen=${ScreenName}`
    );
    showToast(
      `Registration '${data.SIF}' decoupled from Sample '${data.SampleNumber}' successfully.`
    );
  }

  const submitHandler = useCallback(
    async function () {
      setDialog({
        open: true,
        title: "Update Registration",
        actionName: "submitHandlerAlert",
        message: "Do you wish to UPDATE the registration?",
      });
    },
    [data]
  );
  async function submitHandlerAlert() {
    const {
      UnitNameNew: Unit,
      SerialNew: UnitSerial,
      UnitMakeNew: UnitMake,
      UnitModelNew: UnitModel,
      ComponentNameNew: Component,
      ComponentSerialNew: ComponentSerial,
      ComponentMakeNew: ComponentMake,
      ComponentModelNew: ComponentModel,
      ComponentLocationNew: ComponentLocation,
      CapacityNew: ComponentCapacity,
      FluidName: Fluid,
      UnitMeterChangedAtDate: UnitMeterChangedDate,
      ComponentChangedAtDate: ComponentChangedDate,
      ComponentRepairedAtDate: ComponentRepairedDate,
      FluidChangedAtDate: FluidChangedDate,
      ...rest
    } = data;

    const DoCreate = false;

    const resp = await post("/trays/update-reg", {
      ...rest,
      DoCreate,
      Unit,
      UnitSerial,
      UnitMake,
      UnitModel,
      Component,
      ComponentSerial,
      ComponentMake,
      ComponentModel,
      ComponentLocation,
      ComponentCapacity,
      Fluid,
      UnitMeterChangedDate,
      ComponentChangedDate,
      ComponentRepairedDate,
      FluidChangedDate,
      Screen: ScreenName,
    });
    showToast(appResources.REGISTRATION_UPDATED_MSG);
    setEditRegId && setEditRegId(0);
    getRegistration && getRegistration(data.SIF);
  }

  const handleRegistrationClick = useCallback(
    async function () {
      const {
        UnitNameNew: Unit,
        SerialNew: UnitSerial,
        UnitMakeNew: UnitMake,
        UnitModelNew: UnitModel,
        ComponentNameNew: Component,
        ComponentSerialNew: ComponentSerial,
        ComponentMakeNew: ComponentMake,
        ComponentModelNew: ComponentModel,
        ComponentLocationNew: ComponentLocation,
        CapacityNew: ComponentCapacity,
        FluidName: Fluid,
        UnitMeterChangedAtDate: UnitMeterChangedDate,
        ComponentChangedAtDate: ComponentChangedDate,
        ComponentRepairedAtDate: ComponentRepairedDate,
        FluidChangedAtDate: FluidChangedDate,
        ...rest
      } = data;

      const DoCreate = false;
      const resp = await post("/trays/update-reg", {
        ...rest,
        DoCreate,
        Unit,
        UnitSerial,
        UnitMake,
        UnitModel,
        Component,
        ComponentSerial,
        ComponentMake,
        ComponentModel,
        ComponentLocation,
        ComponentCapacity,
        Fluid,
        UnitMeterChangedDate,
        ComponentChangedDate,
        ComponentRepairedDate,
        FluidChangedDate,
        Screen: ScreenName,
      });
      setEditRegId && setEditRegId(0);
      getRegistration && getRegistration(data.SIF);
      searchRegClickHandler && searchRegClickHandler(false);
    },
    [data]
  );

  async function handleDeleteRegistration() {
    setDialog({
      open: true,
      title: "Delete Registration",
      actionName: "handleDeleteRegistrationAlert",
      message: "Are you sure you want to Delete?",
    });
  }

  async function handleDeleteRegistrationAlert() {
    // const resp = await post("/trays/update-reg");
    showToast(appResources.REGISTRATION_WILL_DELETED_MSG);
    setEditRegId && setEditRegId(0);
  }

  async function handleRegistrationCard() {
    await getFile(
      `${TRAY_APIS.REGISTRATION_CARD_REPORT}?RegistrationId=${data.RegistrationId}`
    );
  }

  const formActions = useMemo(() => ({ onChange }), [onChange]);
  const formSections = useFormSections({ onCustomerChanged, data, dispatch, componentData, CanRegister });
  const formButtons = useFormButtons({
    CanRegister,
    location,
    editCheck,
    submitHandler,
    handleDeleteRegistration,
    handleRegistrationClick,
    handleRegistrationCard,
    decoupleHandler,
    sampleNo: data.SampleNumber,
  });
  const alertFunctions = [
    handleDeleteRegistrationAlert,
    decoupleHandlerAlert,
    submitHandlerAlert,
  ];

  const selectedFunction = alertFunctions.filter((func) => {
    return func.name === dialog.actionName;
  });

  return (
    <>
      <Grid container direction="column">
        <Grid item>
          <Form
            sections={formSections}
            data={data}
            dispatch={dispatch}
            masterData={masterData}
            key={data.SIF + data._key}
            actions={formActions}
          />
        </Grid>
        <FormFooter
          buttons={formButtons}
          footerText={`RegistrationId: ${data.RegistrationId || ""}, SiteId: ${data.SiteId || ""
            }, UnidId: ${data.UnitId || ""},  ComponentId: ${data.ComponentId || ""
            } `}
        />
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={dialog.title}
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={selectedFunction[0]}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}
