import { FormActions } from "../../components/Form";

export const CustomerActions = {
  LOAD_DATA: "load-data",
};

export default function CustomerReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === CustomerActions.LOAD_DATA) {
    return action.payload;
  }
  return state;
}
