import { Close, Search } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import { CONTACTS_APIS, DETAILS_APIS } from "../../../../common/apis";
import DataTable, { DataGridAction } from "../../../../components/DataTable";
import Form, { FormActions } from "../../../../components/Form";
import FormFooter from "../../../../components/FormFooter";
import useFetch from "../../../../hooks/useFetch";
import { useRoot, useToast } from "../../../../rootContext";
import appResources from "../../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../../components/DatatableCellExpand";

export default function SearchComponent({
  actions,
  contactId,
  rowSelected,
  selectedUnit,
}) {
  const { showWarningToast } = useToast();
  const [componentsData, setComponentsData] = useState([]);
  const { post } = useFetch();
  const { allProducts } = useRoot();
  const [data, dispatch] = useReducer(SearchFluidsFilterReducer, searchFilterDefaults);

  useEffect(() => {
    if (selectedUnit) {
      dispatch({
        type: FormActions.LOAD_DATA,
        payload: {
          ProductId: selectedUnit?.ProductId,
          Customer: selectedUnit?.Customer,
          Site: selectedUnit?.Site,
          Unit: selectedUnit?.Unit,
          UseStartsWith: true,
          _key: Date.now(),
        },
      });
    }
  }, [selectedUnit]);

  const tblOptions = {
    columns: GetColumns(actions),
    dataSource: componentsData,
    rowIdProperty: "ComponentId",
    cellStylingHandler: "productCommonCellColour",
    isDisableColumnFilter: true,
    rowsSelected: function (rows) {
      rowSelected && rowSelected(rows[0]);
    },
  };

  const formActions = {
    clear: function () {
      dispatch({
        payload: {
          ...searchFilterDefaults,
          _key: Date.now(),
        },
        type: FormActions.RESET_FORM,
      });
    },

    search: async function () {
      if (contactId) {
        const tempComponentData = await post(
          CONTACTS_APIS.COMPONENTS_FOR_CONTACT,
          {
            ...data,
            ContactId: contactId,
            ExcludeScoped: true,
            UseStartsWith: data?.UseStartsWith || false,
            ShowAll: true,
          }
        );
        if (tempComponentData.length > 0) {
          setComponentsData(tempComponentData);
        } else {
          showWarningToast(appResources.NO_MATCHING_COMPONENTS_WERE_FOUND_MSG);
          setComponentsData(tempComponentData);
        }
      } else {
        const tempComponentData = await post(DETAILS_APIS.SEARCH_COMPONENTS, {
          ...data,
        });
        if (tempComponentData.length > 0) {
          let filteredData = tempComponentData.filter(item => item?.ComponentId !== selectedUnit?.ComponentId);
          setComponentsData(filteredData);
        } else {
          showWarningToast(appResources.NO_MATCHING_COMPONENTS_WERE_FOUND_MSG);
          setComponentsData(tempComponentData);
        }
      }
    },
  };

  return (
    <>
      <Grid container minWidth={800}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(allProducts)}
            data={data}
            dispatch={dispatch}
            key={data && data._key}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: -5 }}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {componentsData && componentsData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

const GetFormButtons = (data, { clear, search }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <Close />,
  },
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

function GetColumns(actions) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      minWidth: 60,
      hide: !actions || !actions.length,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
            />
          );
        });
      },
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Customer",
      field: "Customer",
      renderCell: renderCellExpand,
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Unit",
      field: "Unit",
      minWidth: 100,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Model",
      field: "UnitModelFormatted",
      minWidth: 125,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Serial",
      field: "Serial",
      minWidth: 100,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      minWidth: 100,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Component",
      field: "Component",
      minWidth: 125,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Predelivery",
      field: "Predelivery",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Parked",
      field: "Parked",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Scrapped",
      field: "Scrapped",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Archived",
      field: "Archived",
      minWidth: 100,
      sortable: false,
    },
  ];
}

function GetFormSections(allProducts) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 2,
          name: "ProductId",
          label: "Product",
          type: "dropdown",
          required: true,
          selectOptions: (allProducts || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
        },
        {
          xsCol: 2,
          name: "Site",
          label: "Site",
        },

        {
          xsCol: 2,
          name: "Customer",
          label: "Customer",
        },
        {
          xsCol: 2,
          name: "Unit",
          label: "Unit",
        },

        {
          xsCol: 4,
          name: "UseStartsWith",
          label: "Starts With Search",
          type: "switch",
        },
        {
          xsCol: 2,
          name: "Component",
          label: "Component",
        },
        {
          xsCol: 2,
          name: "Serial",
          label: "Serial",
        },
      ],
    },
  ];
}
const searchFilterDefaults = {
  ProductId: 0,
  UseStartsWith: true,
};
function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...action.payload };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  return state;
}
