import * as yup from "yup";
import { GetErrorObject } from "../Utils";

export const CreateTraySchema = yup.object().shape({
  Tray: yup
    .number()
    .transform((value) => value || "")
    .min(0, "Tray must be a positive value.")
    .required("Tray is required."),
  NumberOfSamples: yup
    .number()
    .transform((value) => value || "")
    .min(0, "Tray must be a positive value.")
    .required("Number of Samples is required."),
  Label: yup
    .string()
    .transform((value) => value || "")
    .required("Label value is required."),
  NumberOfSamples: yup
    .number()
    .transform((value) => value || "")
    .min(0, "Tray must be a positive value.")
    .required("Number of Samples is required."),
});

export function ValidateCreateTray(input) {
  return new Promise(async function (resolve, reject) {
    try {
      CreateTraySchema.validate(input, { abortEarly: false })
        .then(function () {
          const data = {
            Tray: {
              ...input,
            },
            Label: {
              ...input,
            },
            NumberOfSamples: {
              ...input,
            },
          };
          resolve({ data });
        })
        .catch(function (err) {
          const errors = GetErrorObject(err);
          resolve({ errors });
        });
    } catch (e) {
      reject(e);
    }
  });
}
