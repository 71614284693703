import { useTheme } from "@emotion/react";
import {} from "@mui/icons-material";
import { Grid, Typography, Button } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import { MASTER_DATA_APIS } from "../../common/apis";
import DataTable, { DataGridAction } from "../../components/DataTable";
import useFetch from "../../hooks/useFetch";
import Form, { FormActions } from "../../components/Form";
import { AddIcon } from "../../icons/ButtonIcons";
import AppDialog from "../../components/AppDialog";
import NewUser from "./NewUser";
import renderCellExpand from "../../components/DatatableCellExpand";

export default function UsersOverview({}) {
  const curTheme = useTheme();
  const { get } = useFetch();

  const [usersData, setUsersData] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const [editData, setEditData] = useState({});

  const [data, dispatch] = useReducer(SearchFilterReducer, usersData);

  //call api for loading data
  useEffect(function () {
    document.title = "Users Overview";
    refreshUsersData();
  }, []);

  async function refreshUsersData() {
    const usersResponse = await get(
      `${MASTER_DATA_APIS.GET_BY_TYPES}?types=Users`
    );
    if (usersResponse.Users.length > 0) {
      setUsersData(usersResponse.Users);
    }
  }

  function editClick(row) {
    setAddUserModal(true);
    setEditData(row);
  }

  const filteredData = useMemo(() => {
    return data?.UserId == 0 || data?.UserId == undefined
      ? usersData
      : (usersData || []).filter((x) => {
          return x.UserId === data?.UserId;
        });
  }, [usersData, data.UserId]);

  const tblOptions = {
    columns: GetColumns(editClick),
    dataSource: filteredData,
    rowIdProperty: "UserId",
    isDisableColumnFilter: true,
    cellStylingHandler: "usersOverview",
    sx: { height: 780 },
  };

  function GetFormSections(usersData) {
    return [
      {
        fields: [
          {
            xsCol: 2,
            name: "UserId",
            label: "Users",
            type: "dropdown",
            selectOptions: (usersData || []).map((x) => ({
              value: x.UserId,
              text: x.Username,
            })),
          },
          {
            component: function () {
              return (
                <Button
                  variant="contained"
                  onClick={handleAddUser}
                  sx={{ mt: "13px" }}
                  startIcon={<AddIcon />}
                >
                  Add User
                </Button>
              );
            },
          },
        ],
      },
    ];
  }

  function handleAddUser() {
    setEditData(null);
    setAddUserModal(true);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }} id="test">
          <Form
            sections={GetFormSections(usersData)}
            data={data}
            spacing={2}
            dispatch={dispatch}
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>

      <AppDialog
        open={addUserModal}
        handleClose={() => setAddUserModal(false)}
        title={editData !== null ? "Edit User" : "Add New User"}
        maxWidth="md"
      >
        <NewUser
          editData={editData}
          usersData={usersData}
          refreshUsersData={refreshUsersData}
          setAddUserModal={setAddUserModal}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(editClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      flex: 1,
      minWidth: 75,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "Name",
      field: "FullName",
      minWidth: 170,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Username",
      field: "Username",
      minWidth: 160,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Administrator",
      field: "Adminstrator",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 60,
      flex: 1,
    },
    {
      headerName: "Registration",
      field: "Registration",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 60,
      flex: 1,
    },
    {
      headerName: "Laboratory",
      field: "Laboratory",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 60,
      flex: 1,
    },
    {
      headerName: "Quality",
      field: "QualityCheck",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 60,
      flex: 1,
    },
    {
      headerName: "Evaluation",
      field: "Evaluation",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 60,
      flex: 1,
    },
    {
      headerName: "Templates",
      field: "Templates",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 60,
      flex: 1,
    },
    {
      headerName: "TestSets",
      field: "RoleTestSets",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 60,
      flex: 1,
    },
    {
      headerName: "Non-Fluids",
      field: "SpecialReports",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 60,
      flex: 1,
    },
    {
      headerName: "Import",
      field: "Import",
      minWidth: 100,
    },
    {
      headerName: "Criticals",
      field: "ActionCriticals",
      minWidth: 100,
    },
    {
      headerName: "Feedback",
      field: "BlockFeedback",
      minWidth: 100,
    },
    {
      headerName: "Fluids",
      field: "Fluids",
      minWidth: 400,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Developer",
      field: "Developer",
      minWidth: 75,
      flex: 1,
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

// const SearchFilterDefaults = userData;
