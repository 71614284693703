import { Button, Grid, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import TextBox from "../../components/TextBox"
import useFetch from "../../hooks/useFetch"
import { REPORT_APIS } from "../../common/apis"
import { useState } from "react"
import moment from "moment";
import { DateFormat } from "../../utils"

const ManagementReport = ({ cancel }) => {

    const { getFile } = useFetch();

    const [reportDate, setReportDate] = useState(new moment().format(DateFormat));

    const handleChangeDate = (dateParam) => {
        let date = moment(dateParam).format(DateFormat)
        setReportDate(date);
    };

    const onExportClick = async () => {
        await getFile(`${REPORT_APIS.MANAGEMENT_REPORT_XLSX}?date=${reportDate}`)
        cancel && cancel()
    }

    return (
        <Grid
            container
            spacing={2}
            sx={{ minWidth: "30vw", minHeight: "25vh", }}
        >
            <Grid item xs={12} sx={{ padding: 1, }}>
                <Grid item xs={12} mt={1}>
                    <Typography>Select the month for the Management report</Typography>
                    <Grid item xs={6} mt={1}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="From"
                                inputFormat="DD/MM/YYYY"
                                value={reportDate}
                                onChange={handleChangeDate}
                                renderInput={(params) => <TextBox {...params} />}
                                componentsProps={{
                                    actionBar: {
                                        actions: ["today"],
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ position: "absolute", right: "25px", bottom: "25px" }}>
                    <Button onClick={onExportClick}>Export</Button>
                </Grid>

            </Grid>
        </Grid>
    )
}
export default ManagementReport;