import React from "react";
import { Button, Grid, Typography } from "@mui/material";

const AlertDialog = ({
  handleClose,
  alertAction,
  message,
  isRecords,
  buttonText,
}) => {
  const handleClick = () => {
    alertAction();
    // handleClose();
  };
  const formatMessage = (message) => {
    const lines = message?.split("\n");
    return lines?.map((line, index) => (
      <Typography variant="subtitle1" key={index}>
        {line}
      </Typography>
    ));
  };
  const closeHandler = (e, reason) => {
    if (["escapeKeyDown", "backdropClick"].indexOf(reason) < 0)
      handleClose(e, reason);
  };
  return (
    <>
      {/* <Card
        sx={{
          p: 2,
          minWidth: "20vw",
          maxWidth: "30vw",
          overflow: "auto",
          minHeight: "13vh",
        }}
      > */}
      <Grid
        sx={{
          p: 0.25,
          minWidth: "28vw",
          maxWidth: "30vw",
          overflow: "auto",
        }}
      >
        <Grid container sx={{ my: 3 }}>
          <Grid item>{formatMessage(message)}</Grid>
        </Grid>

        {isRecords && (
          <Grid conatainer sx={{ display: "flex", justifyContent: "end" }}>
            <Grid item marginRight={0.5}>
              <Button
                sx={{
                  backgroundColor: buttonText?.isDelete ? "#E1464a" : "#253FC8",
                  "&:hover": {
                    backgroundColor: buttonText?.isDelete
                      ? "#E1464a"
                      : "#253FC8",
                  },
                }}
                onClick={handleClick}
              >
                Yes
              </Button>
            </Grid>
            <Grid item marginLeft={0.5}>
              <Button variant="outlined" onClick={handleClose}>
                No
              </Button>
            </Grid>
          </Grid>
        )}
        {isRecords === undefined && (
          <Grid conatainer sx={{ display: "flex", justifyContent: "end" }}>
            <Grid item marginRight={0.5}>
              <Button
                sx={{
                  backgroundColor: buttonText?.isDelete ? "#E1464a" : "#253FC8",
                  "&:hover": {
                    backgroundColor: buttonText?.isDelete
                      ? "#E1464a"
                      : "#253FC8",
                  },
                }}
                onClick={handleClick}
              >
                {buttonText?.isAdd
                  ? "Add"
                  : buttonText?.isYesButton
                  ? "Yes"
                  : buttonText?.isDelete
                  ? "Delete"
                  : "Yes"}
              </Button>
            </Grid>
            <Grid item marginLeft={0.5}>
              <Button variant="outlined" onClick={handleClose}>
                {buttonText?.isCancelButton
                  ? "Cancel"
                  : buttonText?.isNoButton
                  ? "No"
                  : "No"}
              </Button>
            </Grid>
          </Grid>
        )}
        {!isRecords && isRecords !== undefined && (
          <Grid conatainer sx={{ display: "flex", justifyContent: "end" }}>
            <Grid item marginLeft={0.5}>
              <Button
                sx={{
                  backgroundColor: "#E1464a",
                  "&:hover": {
                    backgroundColor: "#e8272c",
                  },
                }}
                onClick={handleClose}
              >
                Ok
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default AlertDialog;
