import { Typography } from "@mui/material";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";

export default function SampleTable({
  data,
  rowSelected,
  actions,
  rowActionHandler,
}) {
  const options = {
    columns: Columns(actions, { rowActionHandler }),
    rowStylingHandler: "sample",
    cellStylingHandler: "sampleBackGroundWhite",
    dataSource: data,
    svgColor: "black",
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options} />;
}
const Columns = (actions, { rowActionHandler }) => [
  {
    headerName: "SampleNumber",
    field: "SampleNumber",
    width: 125,
    renderCell: function ({ row }) {
      return (
        <>
          {(actions || []).map(function (ac, i) {
            return (
              <DataGridAction
                key={i}
                row={row}
                onClick={ac.action}
                label={ac.label}
                startIcon={ac.startIcon}
              />
            );
          })}
          <DropdownMenu
            options={Options}
            onItemSelect={(option) => rowActionHandler(row, option)}
          />
          <Typography paddingTop={0.25}>{row.SampleNumber}</Typography>
        </>
      );
    },
  },

  // {
  //   headerName: "SIF",
  //   field: "SIF",
  //   width: 83,
  // },
  {
    headerName: "Product",
    field: "Product",
    width: 103,
  },
  {
    headerName: "Customer",
    field: "Customer",
    width: 200,
  },

  {
    headerName: "Site",
    field: "Site",
    width: 103,
  },
  {
    headerName: "Unit",
    field: "Unit",
    width: 103,
  },
  {
    headerName: "Model",
    field: "UnitModelFormatted",
    minWidth: 180,
  },
  {
    headerName: "Serial",
    field: "Serial",
    width: 180,
  },
  {
    headerName: "Component",
    field: "Component",
    width: 110,
  },
  {
    headerName: "Predelivery",
    field: "Predelivery",
    width: 103,
  },
  {
    headerName: "Internal",
    field: "InternalOnly",
    width: 80,
  },

  {
    headerName: "Parked",
    field: "Parked",
    width: 80,
  },
  {
    headerName: "Scrapped",
    field: "Scrapped",
    width: 80,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 80,
  },
];

const Options = [
  { label: "Recent Unit Results", name: "recent-unit-results" },
  { label: "Recent Component Results", name: "recent-component-results" },
  {
    label: "Manage",
    name: "manage",
    items: [
      { label: "Sample", name: "manage-sample" },
      { label: "Component", name: "manage-component" },
      { label: "Unit", name: "manage-unit" },
      { label: "Site", name: "manage-site" },
      { label: "Customer", name: "manage-customer" },
    ],
  },
];
