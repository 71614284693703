import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import SwitchInput from "../../components/SwitchInput";
import useFetch from "../../hooks/useFetch";
import EditEventForm from "./EditEventForm";
import EditEventTable from "./EditEventTable";
import { DETAILS_APIS, MASTER_DATA_APIS } from "../../common/apis";

export default function EditEvent({
  eventFilter,
  eventsRowData,
  setOpenEditEvent,
  fetchData,
  cancel,
}) {
  const [hideEvents, setHideEvents] = useState(true);
  const [eventsData, setEventsData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const { get } = useFetch();

  useEffect(function () {
    getEventsData();
  }, []);

  async function getEventsData() {
    const params = [];
    if (eventFilter) {
      if (eventFilter.siteId || eventFilter.SiteId) {
        params.push(`sId=${eventFilter.siteId || eventFilter.SiteId}`);
        if (eventFilter.unitId || eventFilter.UnitId) {
          params.push(`uId=${eventFilter.unitId || eventFilter.UnitId}`);
          if (eventFilter.componentId || eventFilter.ComponentId) {
            params.push(`compId=${eventFilter.componentId || eventFilter.ComponentId}`);
            if (eventFilter.sampleNo || eventFilter.SampleNumber) {
              params.push(`sampleNo=${eventFilter.sampleNo || eventFilter.SampleNumber}`);
            }
          }
        }
        const temp = await get(`${DETAILS_APIS.GET_EVENTS_LOGS}?` + params.join("&"));
        setEventsData(temp);
      }
    }
  }
  //Event types call
  useEffect(function () {
    (async function () {
      const data = await get(
        `${MASTER_DATA_APIS.GET_BY_TYPES}?types=EventTypes&compId=1`,
        {}
      );
      if (data?.EventTypes?.length > 0) {
        setEventTypes(data.EventTypes);
      }
    })();
  }, []);

  const data = useMemo(
    function () {
      return hideEvents
        ? eventsData.filter((x) => x.EventTypeId !== 3)
        : eventsData;
    },
    [hideEvents, eventsData]
  );

  function rowSelectedHandler(rowDatas) {
    setRowData(rowDatas);
  }

  return (
    <>
      <Grid container>
        <>
          {(eventFilter.product || eventFilter?.Product) && (
            <Typography style={{ marginBottom: 10 }}>
              {eventFilter?.product || eventFilter?.Product}
              ,&nbsp;
              {eventFilter?.customer || eventFilter?.Customer}
              ,&nbsp;
              {eventFilter?.site || eventFilter?.Site}
              ,&nbsp;
              {eventFilter?.unit || eventFilter?.Unit}
              ,&nbsp;
              {eventFilter?.unitModel || eventFilter?.UnitModel}
              ,&nbsp;
              {eventFilter?.component || eventFilter?.Component}
            </Typography>
          )}
        </>
        <EditEventForm
          setOpenEditEvent={setOpenEditEvent}
          fetchData={fetchData}
          conditions={{
            isSite: eventFilter?.isSite,
            isUnit: eventFilter?.isUnit,
            isComponent: eventFilter?.isComponent,
            siteId: eventFilter?.siteId,
            unitId: eventFilter?.unitId,
            componenetId: eventFilter?.componentId,
          }}
          formData={rowData}
          eventTypes={eventTypes}
          eventsRowData={eventsRowData}
          eventFilter={eventFilter}
          key={rowData?.EventLogId}
          cancel={cancel}
        />

        <Grid container>
          <Grid item xs={6}>
            <SwitchInput
              label="Hide Fluid Change Events"
              value={hideEvents}
              onChange={(e) => setHideEvents(e.target.checked)}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {data && data?.length}</b>
          </Typography>
          <EditEventTable rowSelected={rowSelectedHandler} data={data} />
        </Grid>
      </Grid>
    </>
  );
}
