import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Draggable from "react-draggable";
import Paper from "@mui/material/Paper";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[className*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function AppDialog({
  open,
  maxWidth,
  sx,
  children,
  title,
  handleClose,
  fullWidth,
}) {
  const theme = useTheme();
  //const [open, setOpen] = useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const closeHandler = (e, reason) => {
    if (["escapeKeyDown", "backdropClick"].indexOf(reason) < 0)
      handleClose(e, reason);
  };

  return (
    <Dialog
      open={open}
      maxWidth={maxWidth}
      PaperComponent={PaperComponent}
      sx={sx}
      fullWidth={fullWidth}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Grid item sx={{ alignSelf: "center" }}>
            <Typography> {title}</Typography>
          </Grid>
          <Grid item style={{ float: "right" }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              color="inherit"
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      {/* <DialogTitle>{title}</DialogTitle> */}
      <DialogContent>
        <Box sx={{ pt: theme.spacing(1) }}>{children}</Box>
      </DialogContent>
      {/* <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
    </Dialog>
  );
}
