import { Box, Button, Card, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TRAY_APIS } from "../../../common/apis";
import { LabSteps, ResultColWidths } from "../../../common/Constants";
import DropDown from "../../../components/DropDown";
import DropDownAdvance from "../../../components/DropDownAdvance";
import { BadgeButton } from "../../../components/FormFooter";
import SwitchInput from "../../../components/SwitchInput";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import useMasterData from "../../../hooks/useMasterData";
import { useRoot, useUser } from "../../../rootContext";
import { DateFormat, copyTextToClipboard, toLocalDate } from "../../../utils";
import ResultsTable from "./ResultsTable";
import SamplesTable from "./SamplesTable";
import SearchFilter from "./SearchFilter";
import useQuery from "../../../hooks/useQuery";
import AppDialog from "../../../components/AppDialog";
import RerunSamples from "../../reports/rerun-samples/RerunSamples";
import FluidWatch from "./../../master-data/fluids/FluidWatch";
import Hold from "../laboratory/Hold";
import NewVersion from "./NewVersion";
import {
  ChartsIcon,
  FluidWatchIcon,
  HoldIcon,
  ImagesIcon,
  NewVersionIcon,
  NotesIcon,
  ProvidedLimitIcon,
  ReleaseIcon,
  RerunSampleIcon,
  SaveCommentsIcon,
} from "../../../icons/ButtonIcons";
import SelectImage from "../../../components/SelectImage";
import { useScreenPopups } from "../../../components/useScreenPopups";
import GroupDialog from "../../../components/GroupDialog";
import ResizePanel from "react-resize-panel";
import { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import RerunToLaboratory from "../quality-check/RerunToLaboratory";
import appResources from "../../../app-resources/en/Resources.json";
import QuickDetailsTree3 from "../../details-old/QuickDetailsTree4";
import { useNavigate } from "react-router-dom";
import renderCellExpand from "../../../components/DatatableCellExpand";
import { WindowTypes, useWindowPopups } from "../../../components/useWindowPopups";

let sampleNumberSelect = "";
let SampleRow = "";
let isChartAlreadyOpen = false;

export default function Evaluation({ evaluationActions }) {

  const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
  const windowFeatures = "width=" + windowWidth + ",height=" + windowHeight + ",top=0,left=0,resizable,scrollbars=yes";

  const { sampleNo } = useQuery();
  const navigate = useNavigate();

  const { post, get, getFile } = useFetch();
  const { getPresetComments, getMasterData } = useMasterData();
  const { CanEvaluate } = useRoot();
  const user = useUser();

  const [selectedRowType, setSelectedRowType] = useState("");
  const [selectedSample, setSelectedSample] = useState(0);
  const [selectedHistSample, setSelectedHistSample] = useState({});
  const [samplesData, setSamplesData] = useState([]);
  const [samplesMoreData, setSampleMoreData] = useState({});
  const [history, setHistory] = useState({});
  const [commentOptions, setCommentOptions] = useState({});
  const [masterData, setMasterData] = useState({});
  const [comments, setComments] = useState({});
  const [rerunSamplesOpen, setRerunSamplesOpen] = useState(false);
  const [fluidWatchOpen, setFluidWatchOpen] = useState(false);
  const [selectedSampleRowData, setSelectedSampleRowData] = useState();
  const [holdOpen, setHoldOpen] = useState(false);
  const [newVersionOpen, setNewVersionOpen] = useState(false);
  const [rerunToLaboratoryOpen, setRerunToLaboratoryOpen] = useState(false);
  const [alertIdStatusState, setAlertIdStatusState] = useState(1);
  const [dialogType, setDialogType] = useState({ open: false });
  const [imagesOpen, setImagesOpen] = useState(false);
  const [isRoleValid, setIsRoleValid] = useState(false);
  const [isHistorical, setIsHistorical] = useState(true);
  const [isHasExternalFactors, setIsHasExternalFactors] = useState(false);
  const [isDoQuickExport, setIsDoQuickExport] = useState(false);
  const [filterData, setFilterData] = useState();
  const [showTree, setShowTree] = useState(false);
  const [expandTreeData, setExpandTreeData] = useState(null);
  const [infoMessage, setInfoMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [viewType, setViewType] = useState(0);
  const [viewTypeResults, setViewTypeResults] = useState(0);
  const [simplifyHistory, setSimplifyHistory] = useState(true);
  const [alertIdState, setAlertIdState] = useState(2);

  function isHistoricalOnChangeHandler(e) {
    setIsHistorical(e.target.checked);
  }
  function isHasExternalFactorsOnChangeHandler(e) {
    setIsHasExternalFactors(e.target.checked);
  }
  function isDoQuickExportOnChangeHandler(e) {
    setIsDoQuickExport(e.target.checked);
  }

  useEffect(() => {
    document.title = "Evaluation";
    if (
      user.RoleEvaluation ||
      user.RoleTrainee ||
      user.RoleDeveloper ||
      user.RoleAdministration
    ) {
      setIsRoleValid(true);
    }
  }, [user]);

  function handleHoldClick() {
    handleHoldClickNewWindow()
    // setHoldOpen(true);
  }
  function handleNewVersionClick() {
    setNewVersionOpen(true);
  }
  function newVersionAddedToast() {
    setIsWarningTrue(false);
    setIsInfoIcon(false);
  }

  const compCommentsRef = useRef();
  const unitCommentsRef = useRef();
  const siteCommentsRef = useRef();
  const custCommentsRef = useRef();

  useEffect(function () {
    (async function () {
      const temp = await getPresetComments(true);
      setCommentOptions({
        evaluations: temp.filter((x) => x.ForEvaluations),
        recommendations: temp.filter((x) => x.ForRecommendations),
      });
      const mData = await getMasterData("AlertStatuses");
      setMasterData(mData);
    })();
    let view1 = localStorage.getItem("evalViewTypeResults");
    let view2 = localStorage.getItem("evalViewType");
    if (view1 !== null) {
      view1 = Number(view1);
      setViewTypeResults(view1);
    }
    if (view2 !== null) {
      view2 = Number(view2);
      setViewType(view2);
    }
  }, []);

  const alertStatuses = useMemo(
    function () {
      return (masterData.AlertStatuses || [])
        .filter((x) => x.ProductId === selectedSample.ProductId)
        .map((x) => ({
          value: x.AlertRank,
          text: x.AlertStatus,
          id: x.AlertStatusId,
        }));
    },
    [selectedSample]
  );

  const handleViewType = (e) => {
    setViewType(e.target.value);
    localStorage.setItem("evalViewType", e.target.value);
  };
  const handleViewTypeResults = (e) => {
    setViewTypeResults(e.target.value);
    localStorage.setItem("evalViewTypeResults", e.target.value);
  };

  function handelViewTypeChange(e) {
    e.target.name === "viewType" && handleViewType(e);
    e.target.name === "viewTypeResults" && handleViewTypeResults(e);
  }

  function onSimplifyHistoryChange(e) {
    setSimplifyHistory(e.target.checked);
  }

  const onViewHandler = useCallback(async function (filter) {
    console.log(user)
    if (filter.Date === "Invalid date") {
      //  setIsSuccessIcon(false);
      setIsWarningTrue(true);
      setIsInfoIcon(true);

      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setFilterData(filter);
    const query = filter.myFluids ? "?byUser=true" : "";
    const sampleData = await post(TRAY_APIS.SEARCH_EVALUATIONS + query, {
      Date:
        filter.LabAndDateOption === 3 || filter.LabAndDateOption === 5
          ? null
          : moment(filter.Date).format(DateFormat),
      LabId:
        filter.LabAndDateOption === 4 || filter.LabAndDateOption === 5
          ? null
          : user.DefaultLaboratoryId,
      TrayNumber: filter.LabAndDateOption === 0 ? filter.Tray : null,
      MinimumStep: LabSteps.Evaluate,
      MaximumStep:
        filter.LabAndDateOption === 0 ? LabSteps.Completed : LabSteps.Evaluate,
      // MinimumStep: 1,
      // MaximumStep: 8,
      Hold: false,
    });
    if (sampleData?.length < 1) {
      //  setIsSuccessIcon(false);
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      setInfoMessage(appResources.NO_SAMPLES_WERE_FOUND_MSG);
    } else {
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setInfoMessage();
    }
    if (sampleData.length > 0) {
      SampleRow = sampleData[0];
      sampleNumberSelect = sampleData[0].SampleNumber;
      setSelectedSampleRowData(sampleData[0]);
      if (sampleData[0]?.HasExternalFactors) {
        setIsHasExternalFactors(true);
      }
      setIsHasExternalFactors();
      setSamplesData(sampleData);
      setSelectedSample(sampleData[0]);
      sampleRowSelectedHandler(sampleData[0]);

      setAlertIdStatusState(sampleData[0].AlertRank || 1);
      //load chart on first row select default
      if (sampleData?.length > 0 && isChartAlreadyOpen === false) {
        isChartAlreadyOpen = true;
        localStorage.removeItem("isCloseWindow");
        localStorage.setItem("isCloseWindow", false);

        var url = "/Chart?EqpMachineId=" + sampleData[0].EqpMachineId + "&sampleId=" + sampleData[0].SampleNumber + "&hideProp=1";

        window.open(url, "_blank", windowFeatures);
      }
    } else {
      setSamplesData();
      setSelectedSample(0);
    }

    if (!sampleData.length) sampleRowSelectedHandler({});
  }, [user.DefaultLaboratoryId]);

  const sampleRowSelectedHandler = useCallback(
    async function (sample) {
      //  setIsSuccessIcon(false);
      SampleRow = sample;
      sampleNumberSelect = sample.SampleNumber;
      setSelectedSampleRowData(sample);
      if (sample?.HasExternalFactors) {
        setIsHasExternalFactors(true);
      }
      if (sample?.Step > 1 && showTree === true) {
        setExpandTreeData({
          ProductId: sample?.ProductId,
          StartLetter: sample?.StartLetter,
          CustomerId: sample?.CustomerId,
          SiteId: sample?.SiteId,
          UnitId: sample?.UnitId,
          ComponentId: sample?.ComponentId,
          SampleNumber: sample?.SampleNumber,
        });
      }

      setAlertIdStatusState(sample.AlertRank || 1);
      if (
        sample.SampleNumber &&
        sample.SampleNumber === selectedSample.SampleNumber
      )
        return;
      refreshSampleData(sample);
    },
    [selectedSample]
  );

  async function refreshSampleData(sample) {
    // await loadMoreData(sample && sample.SampleNumber);
    await loadHistory(sample && sample);

    setSelectedSample(sample || {});

    setSelectedRowType("sample");
    localStorage.setItem("selectedSampleNo", sample && sample.SampleNumber);
    localStorage.setItem("EqpMachineId", sample && sample.EqpMachineId);
    return window.dispatchEvent(new Event('storage'))
  }

  const sampleHistoryRowSelectedHandler = useCallback(
    async function (sample) {
      if (isHistorical) {
        setSelectedHistSample(sample);
        setSelectedRowType("history");
      }
    },
    [isHistorical]
  );

  //set value of alert status id dropdown
  function handelAlertIdStatus(e) {
    setAlertIdStatusState(e.target.value);
    let val = alertStatuses.filter((x) => x.value === e.target.value);
    if (val?.length > 0) {
      val = val[0]?.id;
      setAlertIdState(val);
    }
  }

  async function releaseHandler(e) {
    const data = {
      SampleNumber: selectedSample.SampleNumber,
      ...comments,
      // AlertStatusId: alertIdStatusState ?? null,
      AlertStatusId: alertIdState ?? null,
      HasExternalFactors: isHasExternalFactors ?? false,
      DoQuickExport: isDoQuickExport ?? false,
      Evaluator: user.Username,
    };
    const response = await post(TRAY_APIS.EVALUATE_SAMPLE, data);
    onViewHandler(filterData);
  }

  async function loadMoreData(sampleNo, force) {
    if (!sampleNo || (samplesMoreData[sampleNo] && !force)) return;

    const resp = await get(
      `${TRAY_APIS.NOTES_AND_IMG_COUNT}?sampleNo=${sampleNo}`
    );
    if (resp) {
      setSampleMoreData({ ...samplesMoreData, [resp.SampleNumber]: resp });
    }
  }

  async function loadHistory(sampleNo) {
    if (
      typeof sampleNo === "undefined" ||
      sampleNo === 0 ||
      Object.keys(sampleNo).length === 0
    ) {
      setHistory({});
      return;
    }
    const sampleData = await get(
      `${TRAY_APIS.HISTORY}?sampleNo=${sampleNo.SampleNumber}&includeLaterSamples=true&resultView=${viewTypeResults}`
    );

    if (sampleData.PastSamplesElementData.length > 0) {
      const temp = prepareResultsData(
        sampleData,
        evaluationActions,
        rowActionHandler
      );
      setHistory(temp);
    }
    setSampleMoreData({
      ...samplesMoreData,
      [sampleData?.SampleNotesAndImageCount?.SampleNumber]:
        sampleData?.SampleNotesAndImageCount,
    });
    setSelectedHistSample({});
  }

  useEffect(
    function () {
      loadHistory(selectedSample);
    },
    [viewTypeResults]
  );

  function commentInputChangeHandler(e) {
    setComments({ ...comments, [e.target.name]: e.target.value });
  }

  function getChangeHandler(name) {
    return (e, value) => {
      if (value) {
        const prevVal = comments[name] || "";
        const alreadyAdded = prevVal && prevVal.includes(value.Comment);
        if (!alreadyAdded)
          setComments({ ...comments, [name]: prevVal + " " + value.Comment });
      }
    };
  }

  const getCurData = useCallback(
    function () {
      if (!selectedSample.SampleNumber) return {};
      if (selectedRowType === "sample") {
        return samplesMoreData[selectedSample.SampleNumber] || {};
      }
      if (selectedRowType === "history") {
        const componentInfo =
          samplesMoreData[selectedSample.SampleNumber] || {};
        const { Recommendation, SampleNotes, Evaluation, SampleNumber } =
          selectedHistSample;
        return {
          ...componentInfo,
          Recommendation,
          SampleNotes,
          Evaluation,
          SampleNumber,
        };
      }
      return {};
    },
    [selectedSample, selectedRowType, selectedHistSample, samplesMoreData]
  );
  const curData = getCurData();

  const handleRouteChange = (rows) => {
    let treeData = {
      ProductId: rows?.ProductId,
      StartLetter: rows?.StartLetter,
      CustomerId: rows?.CustomerId,
      SiteId: rows?.SiteId,
      UnitId: rows?.UnitId,
      ComponentId: rows?.ComponentId,
      SampleNumber: rows?.SampleNumber,
    };
    localStorage.setItem("treeData", JSON.stringify(treeData));
    navigate("/details/quick");
  };

  useEffect(
    function () {
      const { Evaluation, Recommendation } = getCurData();
      setComments({ Evaluation, Recommendation });
    },
    [selectedSample, selectedRowType, selectedHistSample]
  );

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" {...props} key={option.CommentId}>
        <Typography
          variant="subtitle2"
          sx={{ minWidth: "200px", maxWidth: "200px" }}
        >
          {option.Code}:{" "}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ minWidth: "400px", maxWidth: "400px" }}
        >
          {option.Comment}:{" "}
        </Typography>
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options.filter((x) =>
        x.Code.toLowerCase().includes(value.inputValue.toLowerCase())
      );
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.Code || "";
  }, []);

  async function saveCommentsHandler() {
    const comp = compCommentsRef.current.value;
    const unit = unitCommentsRef.current.value;
    const site = siteCommentsRef.current.value;
    const cust = custCommentsRef.current.value;
    await post(TRAY_APIS.UPDATE_COMMENTS, {
      SampleNumber: selectedSample.SampleNumber,
      ...comments,
      ComponentNote: comp,
      UnitNote: unit,
      SiteNote: site,
      CustomerNote: cust,
      ScreenName: "Evaluation",
    });
    await loadMoreData(selectedSample.SampleNumber, true);
  }
  function handleChartClick() {
    localStorage.removeItem("isCloseWindow");
    localStorage.setItem("isCloseWindow", false);

    var url = "/Chart?EqpMachineId=" + selectedSample.EqpMachineId + "&sampleId=" + selectedSample.SampleNumber + "&hideProp=1";

    window.open(url, "_blank", windowFeatures);
  }

  function FluidWatchOpenClick() {
    // setFluidWatchOpen(true);
    handleFluidWatchClickNewWindow();
  }

  function handleFluidWatchClickNewWindow() {
    var url = `/master-data/FluidWatch?urlfluidId=${selectedSampleRowData && selectedSampleRowData.FluidId}&fluidTypeId=${selectedSampleRowData && selectedSampleRowData.FluidTypeId}`;
    window.open(url, "_blank", windowFeatures);
  }

  function handleHoldClickNewWindow() {
    var url = `/hold?urlsampleNumber=${selectedSampleRowData && selectedSampleRowData.SampleNumber}`;
    window.open(url, "_blank", windowFeatures);
  }

  function ImagesOpenClick() {
    // setFluidWatchOpen(true);
    handleImagesNewWindow();
  }

  function handleImagesNewWindow() {
    var url = `/images?urlsampleNumber=${selectedSampleRowData && selectedSampleRowData.SampleNumber}`;
    window.open(url, "_blank", windowFeatures);
  }

  function handleRerunSampleClick() {
    setRerunToLaboratoryOpen(true);
  }
  function reLoadViewHandler() {
    onViewHandler(filterData);
  }

  async function getSampleReport(row) {
    try {
      let fileName = `${row?.Customer} ${row?.Site} ${row?.Unit} ${row?.Component} ${row?.SampleNumber} `;
      await getFile(`${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`, fileName);
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  const viewMachineReport = async (data) => {
    let fileName = `${data?.Customer || ""} ${data?.Site || ""} ${data?.Unit || ""} `;
    await getFile(`${TRAY_APIS.MACHINE_REPORT}/?Machine_UnitId=${data.UnitId}`, fileName);
  };

  const HandleNewWindow = (actionName, data) => {
    useWindowPopups(actionName, data)
  }

  function rowActionHandler(row, actionName) {
    if (Object.values(WindowTypes).includes(actionName)) {
      HandleNewWindow(actionName, row)
      return;
    }
    if (
      [
        "edit-sample",
        "unit-hour-history",
        "recent-unit-results",
        "primary-contacts-overview",
        "report-contacts",
        "website-contacts",
        "critical-log",
        "hold-management",
      ].indexOf(actionName) > -1
    ) {
      openDialog(row, actionName);
      return;
    } else if (actionName === "view-sample-report") {
      return getSampleReport(row);
    } else if (actionName === "view-mhr-report") {
      return viewMachineReport(row);
    } else if (actionName === "view-in-quick-details") {
      return handleRouteChange(row);
    } else if (actionName === "copy-sample-number") {
      return copyTextToClipboard(row?.SampleNumber);
    }
  }

  const dialogOptions = useScreenPopups({
    data: dialogType.data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
  });

  function openDialog(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F4" && samplesData?.length > 0) {
        event.preventDefault();
        // setFluidWatchOpen(true);
        FluidWatchOpenClick();
      } else if (event.key === "F11") {
        event.preventDefault();
        if (isRoleValid && samplesData?.length > 0) {
          handleHoldClick();
          // setHoldOpen(true);
        }
      } else if (event.key === "F12") {
        event.preventDefault();
        if (samplesData.length > 0 && isRoleValid) {
          releaseHandler();
        }
      } else if (event.key === "F3") {
        event.preventDefault();
        if (
          selectedSampleRowData?.UnitNoteCount +
          selectedSampleRowData?.ComponentNoteCount >
          0
        ) {
        }
      } else if (event.key === "F10") {
        event.preventDefault();
        if (samplesData.length > 0) {
          setRerunToLaboratoryOpen(true);
        }
      }
    },
    [samplesData, isRoleValid, selectedSampleRowData, selectedSample]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={8}>
                <SearchFilter
                  onView={onViewHandler}
                  showTree={showTree}
                  setShowTree={setShowTree}
                  infoMessage={infoMessage}
                  successMessage={successMessage}
                  isInfoIcon={isInfoIcon}
                  isSuccessIcon={isSuccessIcon}
                  isWarningTrue={isWarningTrue}
                />
              </Grid>
              <Grid item>
                <Grid container spacing={1} alignItems="flex-end">
                  {selectedSampleRowData?.UsesLimits && (
                    <Grid item>
                      <Button
                        variant="contained"
                        //onClick={handleRerunClick}
                        startIcon={<ProvidedLimitIcon />}
                      >
                        Limits
                      </Button>
                    </Grid>
                  )}
                  {samplesData?.length > 0 && (
                    <Grid item>
                      <SwitchInput
                        label="Simplify History"
                        value={simplifyHistory}
                        onChange={onSimplifyHistoryChange}
                      />
                    </Grid>
                  )}
                  {samplesData?.length > 0 && (
                    <Grid item minWidth="170px">
                      <DropDown
                        value={viewType}
                        name="viewType"
                        onChange={handelViewTypeChange}
                        selectOptions={[
                          { text: "Tray and History", value: 0 },
                          { text: "History", value: 1 },
                          { text: "Tray", value: 2 },
                        ]}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={1} mt={1} hidden={!showTree}>
          <QuickDetailsTree3
            expandTreeData={expandTreeData}
            minHeight={"79vh"}
            maxHeight={"79vh"}
          />
        </Grid>

        <Grid item xs={showTree === true ? 10 : 12}>
          <Grid item container spacing={0.5}>
            <Grid item xs={12} hidden={viewType === 1}>
              <Typography variant="subtitle2">
                Total Records:{" "}
                <b> {(samplesData && samplesData?.length) || 0}</b>
              </Typography>
              <ResizePanel direction="s" style={{ paddingBottom: "10px" }}>
                <SamplesTable
                  data={samplesData}
                  rowSelected={sampleRowSelectedHandler}
                  selectedRow={
                    samplesData?.length > 0
                      ? [selectedSample.SampleNumber]
                      : undefined
                  }
                  actions={evaluationActions}
                  rowActionHandler={rowActionHandler}
                />
              </ResizePanel>
            </Grid>

            {/* BUTTONS AND COMMENTS */}
            <Grid item xs={12}>
              <Card sx={{ p: 0.5 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12} container spacing={1}>
                    <Grid item xs={4}>
                      <TextBox
                        name="SampleNotes"
                        readOnly={!CanEvaluate}
                        value={curData.SampleNotes || ""}
                        onChange={commentInputChangeHandler}
                        label="Sample Comments: internal only"
                        multiline
                        rows={5}
                        key={curData.SampleNumber}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={1} direction="column">
                        <Grid item>
                          <DropDownAdvance
                            renderOptions={renderOptions}
                            filterOptions={filterOptions}
                            getOptionLabel={getOptionLabel}
                            onChange={getChangeHandler("Evaluation")}
                            options={commentOptions.evaluations || []}
                          />
                        </Grid>
                        <Grid item>
                          <TextBox
                            name="Evaluation"
                            readOnly={!CanEvaluate}
                            value={comments.Evaluation || ""}
                            multiline
                            rows={4}
                            key={curData.SampleNumber}
                            onChange={commentInputChangeHandler}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container spacing={1} direction="column">
                        <Grid item>
                          <DropDownAdvance
                            renderOptions={renderOptions}
                            filterOptions={filterOptions}
                            getOptionLabel={getOptionLabel}
                            options={commentOptions.recommendations || []}
                            onChange={getChangeHandler("Recommendation")}
                          />
                        </Grid>
                        <Grid item>
                          <TextBox
                            name="Recommendation"
                            readOnly={!CanEvaluate}
                            value={comments.Recommendation || ""}
                            multiline
                            rows={4}
                            key={curData.SampleNumber}
                            onChange={commentInputChangeHandler}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* BUTTONS SECTIONS */}
                  <Grid
                    item
                    xs={12}
                    hidden={
                      samplesData?.length === undefined ||
                      samplesData?.length <= 0
                    }
                  >
                    <Grid container spacing={1}>
                      {CanEvaluate && (
                        <Grid item>
                          <Button
                            variant="contained"
                            startIcon={<SaveCommentsIcon />}
                            onClick={saveCommentsHandler}
                            disabled={samplesData?.length === 0}
                          >
                            Save Comments
                          </Button>
                        </Grid>
                      )}
                      {CanEvaluate && (
                        <>
                          <Grid item mt={0.75}>
                            <SwitchInput
                              name="Historical"
                              value={isHistorical}
                              label="Historical"
                              onChange={isHistoricalOnChangeHandler}
                            />
                          </Grid>
                          <Grid item mt={0.75}>
                            <SwitchInput
                              name="HasExternalFactors"
                              value={isHasExternalFactors}
                              label="Other Factors"
                              onChange={isHasExternalFactorsOnChangeHandler}
                            />
                          </Grid>
                          <Grid item mt={0.75}>
                            <SwitchInput
                              name="DoQuickExport"
                              value={isDoQuickExport}
                              label="Quick Export"
                              className={isDoQuickExport ? "red" : ""}
                              onChange={isDoQuickExportOnChangeHandler}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <DropDown
                              renderValue={(val) =>
                                ["Results", "Divided", "Adjusted"][val]
                              }
                              value={viewTypeResults}
                              name="viewTypeResults"
                              onChange={handelViewTypeChange}
                              selectOptions={[
                                { text: "Results", value: 0 },
                                { text: "Divided by Fluid Hours", value: 1 },
                                { text: "Adjusted for Topup", value: 2 },
                              ]}
                              disabled={selectedSample.FluidTypeId !== 1}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={1}>
                        <DropDown
                          name="AlertStatusId"
                          // value={selectedSampleRowData?.AlertStatusId}
                          value={alertIdStatusState}
                          key={selectedSampleRowData?.ProductId}
                          onChange={handelAlertIdStatus}
                          readOnly={samplesData?.length === 0 || !CanEvaluate}
                          required={true}
                          selectOptions={alertStatuses}
                          style={{
                            backgroundColor:
                              alertIdStatusState === 1
                                ? "green"
                                : alertIdStatusState === 2
                                  ? "DodgerBlue"
                                  : alertIdStatusState === 3
                                    ? "orange"
                                    : alertIdStatusState === 4
                                      ? "DeepPink"
                                      : alertIdStatusState === 5
                                        ? "red"
                                        : samplesData?.length === 0
                                          ? ""
                                          : "",
                            color: "#fff",
                          }}
                          sx={{ [`.MuiSelect-icon`]: { color: "#fff" } }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant={
                            selectedSampleRowData?.HasFluidWatch === true
                              ? "contained"
                              : "outlined"
                          }
                          color={
                            selectedSampleRowData?.HasFluidWatch === true
                              ? "lightseagreen"
                              : "primary"
                          }
                          startIcon={<FluidWatchIcon />}
                          onClick={FluidWatchOpenClick}
                          disabled={samplesData?.length === 0}
                        >
                          Fluid Watch (F4)
                        </Button>
                      </Grid>
                      {(CanEvaluate) && (
                        <>
                          <Grid item>
                            {selectedSampleRowData?.UnitNoteCount +
                              selectedSampleRowData?.ComponentNoteCount >
                              0 && (
                                <Button
                                  variant="outlined"
                                  startIcon={<NotesIcon />}
                                >
                                  Notes (F3)
                                </Button>
                              )}
                          </Grid>
                          {selectedSampleRowData?.Step > 6 && (
                            <Grid item>
                              <Button
                                variant="outlined"
                                startIcon={<NewVersionIcon />}
                                disabled={samplesData?.length === 0}
                                onClick={handleNewVersionClick}
                              >
                                New Version
                              </Button>
                            </Grid>
                          )}
                          <Grid item>
                            <Button
                              variant="outlined"
                              startIcon={<ChartsIcon />}
                              onClick={handleChartClick}
                              disabled={samplesData?.length === 0}
                            >
                              Charts
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              onClick={handleHoldClick}
                              startIcon={<HoldIcon />}
                              disabled={samplesData?.length === 0}
                            >
                              Hold (F11)
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="error"
                              startIcon={<RerunSampleIcon sx={{ mt: 0.5 }} />}
                              onClick={handleRerunSampleClick}
                              disabled={samplesData?.length === 0}
                            >
                              Rerun Sample (F10)
                            </Button>
                          </Grid>

                          <Grid item>
                            <BadgeButton
                              variant="outlined"
                              startIcon={<ImagesIcon />}
                              badge={{
                                badgeContent: curData.SampleImageCount,
                              }}
                              label="Images"
                              onClick={ImagesOpenClick}
                              disabled={(curData.SampleImageCount || 0) < 1}
                            />
                          </Grid>

                          <Grid item>
                            <Button
                              variant="contained"
                              startIcon={<ReleaseIcon />}
                              onClick={releaseHandler}
                              disabled={
                                !(
                                  user.RoleAdministration ||
                                  user.RoleDeveloper ||
                                  user.RoleEvaluation ||
                                  user.RoleTrainee
                                ) ||
                                // boolState === false ||
                                samplesData?.length === 0
                              }
                            >
                              {selectedSampleRowData?.IsAutoEvaluated
                                ? selectedSampleRowData?.Approved === null &&
                                  selectedSampleRowData?.Sampled === null
                                  ? "Release Auto (F12)"
                                  : "Rerelease Auto (F12)"
                                : selectedSampleRowData?.Approved === null &&
                                  selectedSampleRowData?.Sampled === null
                                  ? "Release (F12)"
                                  : "Rerelease (F12)"}
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>

            {/* DATA TABLE SECTION */}
            <Grid item xs={12} hidden={viewType === 2}>
              <Typography variant="subtitle2">
                Total Records:{" "}
                <b> {(history.data && history.data?.length) || 0}</b>
              </Typography>
              <ResizePanel direction="s" style={{ paddingBottom: "10px" }}>
                <ResultsTable
                  //key={Date.now() + ""}
                  data={history.data && history.data}
                  rowSelected={sampleHistoryRowSelectedHandler}
                  columns={
                    simplifyHistory ? history.columnsSimple : history.columns
                  }
                />
              </ResizePanel>
            </Grid>

            {/* COMMENTS SECTION */}
            <Grid item xs={12}>
              <Card sx={{ p: 0.5 }}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <TextBox
                      defaultValue={curData.ComponentNotes || ""}
                      label="Component Comments: internal only"
                      multiline
                      rows={3}
                      ref={compCommentsRef}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      defaultValue={curData.UnitNotes || ""}
                      label="Unit Comments: internal only"
                      multiline
                      rows={3}
                      ref={unitCommentsRef}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      defaultValue={curData.SiteNotes || ""}
                      label="Site Comments: internal only"
                      multiline
                      rows={3}
                      ref={siteCommentsRef}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      defaultValue={curData.CustomerNotes || ""}
                      label="Customer Comments: internal only"
                      multiline
                      rows={3}
                      ref={custCommentsRef}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <>
        <AppDialog
          open={rerunSamplesOpen}
          handleClose={() => setRerunSamplesOpen(false)}
          title="Rerun Samples"
          maxWidth="xl"
        >
          <RerunSamples />
        </AppDialog>
        <AppDialog
          open={fluidWatchOpen}
          handleClose={() => setFluidWatchOpen(false)}
          title="Fluid Watch"
          maxWidth="xl"
        >
          <FluidWatch
            fluidId={selectedSampleRowData && selectedSampleRowData.FluidId}
            fluidTypeId={selectedSampleRowData && selectedSampleRowData.FluidTypeId}
            setFluidWatchOpen={setFluidWatchOpen}
          />
        </AppDialog>
        <AppDialog
          open={holdOpen}
          handleClose={() => setHoldOpen(false)}
          title="Put on Hold from Laboratory"
          maxWidth="xl"
        >
          <Hold
            sampleId={selectedSampleRowData && selectedSampleRowData.SampleNumber}
          />
        </AppDialog>
        <AppDialog
          open={newVersionOpen}
          handleClose={() => setNewVersionOpen(false)}
          title="New Version"
          maxWidth="xl"
        >
          <NewVersion
            sampleId={selectedSampleRowData && selectedSampleRowData.SampleNumber}
            setNewVersionOpen={setNewVersionOpen}
            newVersionAddedToast={newVersionAddedToast}
          />
        </AppDialog>

        <AppDialog
          open={imagesOpen}
          handleClose={() => setImagesOpen(false)}
          title="New Version"
          maxWidth="xl"
        >
          <SelectImage
            cancel={() => setImagesOpen(false)}
            sampleId={
              selectedSampleRowData && selectedSampleRowData?.SampleNumber
            }
            setImagePickerOpen={setImagesOpen}
          />
        </AppDialog>
        <AppDialog
          open={rerunToLaboratoryOpen}
          handleClose={() => setRerunToLaboratoryOpen(false)}
          title="Return to Laboratory as Rerun"
          maxWidth="xl"
        >
          <RerunToLaboratory
            sampleDate={moment(filterData?.Date).format(DateFormat)}
            sampleData={selectedSampleRowData}
            sampleId={selectedSampleRowData?.SampleNumber}
            reLoadViewHandler={reLoadViewHandler}
            handleClose={setRerunToLaboratoryOpen}
            screenName={"Evaluation"}
          />
        </AppDialog>
      </>
      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
    </>
  );
}

function prepareResultsData(
  { PastSamplesElementData: data, PastSamples: sampleData, TestSetSuperset },
  evaluationActions,
  rowActionHandler
) {
  const colMap = {};
  const samplesMap = {};
  let hasSif = false;
  sampleData.forEach(function (s) {
    if (s.SIF) {
      hasSif = true;
    }
    samplesMap[s.SampleNumber] = s;
  });

  let newSampleNumber = "";
  if (SampleRow.NewVersion !== null) {
    newSampleNumber = SampleRow.NewVersion;
  }
  // data.forEach((x) => {
  //   //for new version
  //   if (newSampleNumber !== "") {
  //     if (x.SampleNumber === newSampleNumber) {
  //       samplesMap[x.SampleNumber][x.TestItemCode] = x.FormattedText;
  //       if (colMap[x.TestItemCode]) {
  //         return;
  //       }
  //       colMap[x.TestItemCode] = x;
  //     }
  //   } else {
  //     // if (x.SampleNumber === sampleNumberSelect) {
  //     samplesMap[x.SampleNumber][x.TestItemCode] = x.FormattedText;
  //     if (colMap[x.TestItemCode]) {
  //       return;
  //     }
  //     colMap[x.TestItemCode] = x;
  //     // }
  //   }
  // });
  data.forEach((x) => {
    //for new version
    if (Object.keys(samplesMap).filter(item => item == x?.SampleNumber)?.length > 0) {

      if (newSampleNumber !== "") {
        // if (x.SampleNumber === newSampleNumber) {
        if (samplesMap[x?.SampleNumber] !== undefined) {
          samplesMap[x?.SampleNumber][x?.TestItemCode] = x?.FormattedText || x?.FormattedNumeric || "";
          if (colMap[x?.TestItemCode]) {
            return;
          }
          colMap[x?.TestItemCode] = x;
          // }
        }
      } else {
        // if (x.SampleNumber === sampleNumberSelect) {
        if (samplesMap[x?.SampleNumber] !== undefined) {
          samplesMap[x?.SampleNumber][x?.TestItemCode] = x?.FormattedText || x?.FormattedNumeric || "";
          if (colMap[x?.TestItemCode]) {
            return;
          }
          colMap[x?.TestItemCode] = x;
        }
        // }
      }
    }
  });
  const columns = TestSetSuperset.sort(sortFunction).map((x) => ({
    headerName: x.TestItemCode,
    field: x.TestItemCode,
    minWidth: ResultColWidths[x.TestItemCode] || 50,
    flex: 1,
    // renderCell: renderCellExpand,
  }));

  const columnsSimple = Object.keys(colMap)
    .map((x) => colMap[x])
    .sort(sortFunction)
    .map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      minWidth: ResultColWidths[x.TestItemCode] || 50,
      flex: 1,
      // renderCell: renderCellExpand,
    }));

  const menuOptions = [
    { label: "Copy Sample Number", name: "copy-sample-number" },
    { label: "View Sample Report", name: "view-sample-report" },
    { label: "Unit Hour Sample", name: "unit-hour-history" },
    { label: "Recent Unit History", name: "recent-unit-results" },
    { label: "Edit Sample", name: "edit-sample" },
  ];

  const extraColumns1 = [
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      minWidth: 128,
      maxWidth: 128,
      renderCell: function ({ row }) {
        return (
          <>
            {(evaluationActions || []).map(function (ac, i) {
              return (
                <DataGridAction
                  key={i}
                  row={row}
                  onClick={ac.action}
                  label={ac.label}
                  startIcon={ac.startIcon}
                />
              );
            })}
            <DropdownMenu
              options={menuOptions}
              onItemSelect={(option) => rowActionHandler(row, option)}
            />
            <Typography paddingTop={0.25}>{row.SampleNumber}</Typography>
          </>
        );
      },
    },
    {
      headerName: "UH",
      field: "UnitHours",
      description: "Unit Hours",
      minWidth: 50,
      flex: 1,
    },
    {
      headerName: "CH",
      field: "ComponentHours",
      description: "Component Hours",
      minWidth: 50,
      flex: 1,
    },
    {
      headerName: "FH",
      field: "FluidHours",
      description: "Fluid Hours",
      minWidth: 50,
      flex: 1,
    },
    {
      headerName: "Changed",
      field: "FluidChanged",
      description: "Fluid Changed",
      minWidth: 50,
      flex: 1,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },
    {
      headerName: "Filter",
      field: "FilterChanged",
      description: "Filter Changed",
      minWidth: 50,
      flex: 1,
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
    },
  ];

  const extraColumns2 = [
    {
      headerName: "Fluid",
      field: "FluidFormatted",
      description: "Topup in Litres",
      minWidth: 200,
    },
    {
      headerName: "TU",
      field: "Topup",
      description: "Topup in Litres",
    },
    {
      headerName: "KL",
      field: "KidneyLoop",
      description: "Kidney Loop",
    },
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      renderCell: renderCellExpand,
    },
    {
      headerName: "Registration",
      field: "SIF",
      hide: !hasSif,
    },
  ];
  const dataSrc = Object.keys(samplesMap).map((x) => samplesMap[x]);

  return {
    columns: [...extraColumns1, ...columns, ...extraColumns2],
    columnsSimple: [...extraColumns1, ...columnsSimple, ...extraColumns2],
    data: dataSrc,
  };
}

function sortFunction(a, b) {
  return (
    a.TechnicianRank - b.TechnicianRank ||
    a.TestItemCode.localeCompare(b.TestItemCode)
  );
}
