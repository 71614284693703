import * as React from "react";
import { Button, Card, Grid, Typography } from "@mui/material";
import useMasterData from "../../../hooks/useMasterData";
import TextBox from "../../../components/TextBox";
import DropDown from "../../../components/DropDown";
import FluidsTable from "./FluidsTable";
import AppDialog from "../../../components/AppDialog";
import NewFluid from "./NewFluid";
import ViewFluid from "./ViewFluid";
import { NewIcon } from "../../../icons/ButtonIcons";
import { useEffect } from "react";
import { useRoot } from "../../../rootContext";

export default function Fluids() {
  const { getMasterData } = useMasterData();
  const { componentFluidTypes, CanDoDataMaintenance } = useRoot();
  const [fluidsData, setFluidsData] = React.useState([]);
  const [fluidsTypeData, setFluidsTypeData] = React.useState();
  const [selectedFluid, setSelectedFluid] = React.useState({});
  const [filter, setFilter] = React.useState({});
  //   const [data, dispatch] = React.useReducer(FluidReducer, {});

  async function fetchFluidsDetails(fluidTypeId) {
    const masterData = await getMasterData(
      "Fluids",
      `&fluidTypeId=${fluidTypeId}`
    );
    setFluidsData(masterData.Fluids);
    return masterData;
  }
  async function fetchFluidType() {
    let filterTypes = [];
    if (componentFluidTypes?.length > 0) {
      filterTypes = componentFluidTypes?.filter((x) => !x.IsNonFluid);
      filterTypes.unshift({
        FluidType: "Show All",
        FluidTypeId: 0,
      });
    }

    setFluidsTypeData(filterTypes);
    return filterTypes;
  }
  React.useEffect(
    function () {
      document.title = "Fluids";
      (async function () {
        const fluidMasterData = await fetchFluidType();
        const temp = await fetchFluidsDetails(0);
        if (temp.Fluids) {
          const fluidTypeIds = fluidMasterData?.map((x) => x.FluidTypeId);

          temp.Fluids = temp.Fluids.filter((x) =>
            fluidTypeIds.includes(x.FluidTypeId)
          );
          setFluidsData(temp.Fluids || []);
        }
      })();
    },
    [componentFluidTypes]
  );

  function handelFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }

  function fluidRowSelectedHandler(row) {
    setSelectedFluid(row);
  }

  const filteredData = React.useMemo(
    function () {
      return fluidsData.filter(
        (x) =>
          (!filter.startsWith ||
            x.FluidFormatted.toUpperCase().startsWith(
              filter.startsWith.toUpperCase()
            )) &&
          (x.FluidTypeId === filter.fluidType || !filter.fluidType)
      );
    },
    [fluidsData, filter]
  );

  const filteredData2 = React.useMemo(
    function () {
      return fluidsData.filter(
        (x) => x.FluidTypeId === filter.fluidType || !filter.fluidType
      );
    },
    [fluidsData]
  );
  useEffect(() => {
    if (filteredData2.length > 0) {
      setSelectedFluid(filteredData2[0]);
    }
  }, [filteredData2]);

  const [newFluidOpen, setNewFluidOpen] = React.useState(false);
  function handleNewFluidClick() {
    setNewFluidOpen(true);
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2} alignItems="flex-end">
          <Grid item>
            <TextBox
              name="startsWith"
              label="Starts with"
              //value={filter.startsWith}
              onChange={handelFilterChange}
            />
          </Grid>
          <Grid item>
            <DropDown
              name="fluidType"
              label="Fluid Types"
              selectOptions={(fluidsTypeData || [])?.map((x) => ({
                value: x.FluidTypeId,
                text: x.FluidType,
              }))}
              defaultValue={0}
              onChange={handelFilterChange}
            />
          </Grid>
          {CanDoDataMaintenance && <Grid item>
            <Button
              onClick={handleNewFluidClick}
              variant="outlined"
              startIcon={<NewIcon />}
            >
              New Fluid
            </Button>
          </Grid>}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData?.length}</b>
          </Typography>
          <FluidsTable
            data={filteredData}
            rowSelected={fluidRowSelectedHandler}
            selectedRow={[selectedFluid?.FluidId]}
          />
        </Grid>
        <Grid item xs={6} hidden={!selectedFluid.FluidId} mt={2}>
          <Card sx={{ p: 1 }}>
            <ViewFluid
              fluid={selectedFluid}
              key={selectedFluid.FluidId}
              fetchFluidsDetails={fetchFluidsDetails}
            />
          </Card>
        </Grid>
      </Grid>
      <AppDialog
        open={newFluidOpen}
        handleClose={() => setNewFluidOpen(false)}
        title="New Fluid from Fluids"
        maxWidth="md"
      >
        <NewFluid
          setNewFluidOpen={setNewFluidOpen}
          fetchFluidsDetails={fetchFluidsDetails}
        />
      </AppDialog>
    </>
  );
}
