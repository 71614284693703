import * as React from "react";
import { Check, OilBarrel, } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { useReducer, } from "react";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import { FLEET_APIS } from "../../../common/apis";
import { KomatsuCheckIcon } from "../../../icons/ButtonIcons";

export default function AddUnit({ TemplateId, unitSearchRowSelected }) {

  const [error, setError] = React.useState([]);
  const [manageUnitOpen, setManageUnitOpen] = React.useState(false);
  const [komatsuCheckOpen, setKomatsuCheckOpen] = React.useState(false);
  const { get, post } = useFetch();
  //useFetch
  const [data, dispatch] = useReducer(
    FluidReducer,
    unitSearchRowSelected || []
  );
  const actions = {
    save: async function () {
      const response = await get(
        `${FLEET_APIS.ADD_DELETE_TEMPLATE_MODEL
        }?TemplateId=${TemplateId}&UnitMakeId=${unitSearchRowSelected?.UnitMakeId || data?.UnitMake
        }&IsDelete=false&Screen=Machine Templates`
      );

      //  await fetch(DETAILS_APIS.SAVE_UNIT_MAKE, {
      //   ...data,
      //   Screen: "Unit Models",
      //   Verified: data.Verified ? "Yes" : "No",
      // });

      if (response.length > 2) {
        setError(response);
      }
    },
    manage: function () {
      setManageUnitOpen(true);
    },
    // komatsuCheck: function () {
    //   setKomatsuCheckOpen(true);
    // },
  };
  function komatsuCheck() {
    setKomatsuCheckOpen(true);
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <p>{error}</p>
          <Form
            sections={GetFormSections(komatsuCheck)}
            data={data}
            dispatch={dispatch}
          />
        </Grid>

        <Grid item xs={12}>
          <FormFooter buttons={GetFormButtons(actions)} hideDivider gmt={0} />
        </Grid>
      </Grid>
    </>
  );
}

const GetFormButtons = ({ manage, save }) => [
  // {
  //   variant: "outlined",
  //   onClick: komatsuCheck,
  //   label: "Komatsu Check",
  //   startIcon: <OilBarrel />,
  // },
  // {
  //   variant: "outlined",
  //   label: "Manage",
  //   onClick: manage,
  //   startIcon: <Settings />,
  // },
  {
    variant: "contained",
    label: "Save",
    type: "submit",
    onClick: save,
    startIcon: <Check />,
  },
];

function GetFormSections(komatsuCheck) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 6,
          name: "UnitMake",
          label: "Make",
        },
        {
          xsCol: 6,
          name: "UnitModel",
          label: "Model",
        },
        {
          xsCol: 2,
          name: "Verified",
          label: "Verified",
          type: "switch",
        },
        {
          xsCol: 2,
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={komatsuCheck}
                startIcon={<OilBarrel />}
              >
                Override Limits
              </Button>
            );
          },
        },
        {
          xsCol: 2,
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={komatsuCheck}
                startIcon={<KomatsuCheckIcon />}
                disabled={true}
              >
                Komatsu Check
              </Button>
            );
          },
        },
        {
          xsCol: 12,
          name: "UnitModelNotes",
          label: "Notes",
          type: "textarea",
        },
      ],
    },
  ];
}

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
