import { Button, Grid, Typography } from "@mui/material";
// import { useCallback, useEffect, useMemo, useState } from "react";
// import { async } from "q";
import useFetch from "../../../hooks/useFetch";
import TextBox from "../../../components/TextBox";
import SwitchInput from "../../../components/SwitchInput";
import { DETAILS_APIS } from "../../../common/apis";
import { NewIcon, SaveIcon, TestIcon } from "../../../icons/ButtonIcons";
import { useToast } from "../../../rootContext";
import { useState } from "react";
import { useEffect } from "react";
import * as Yup from "yup";
import appResources from "../../../app-resources/en/Resources.json";
export default function LinkFluidWatch({
  fluidId,
  onFluidLinkUpdated,
  setAddLinkDialogueOpen,
}) {
  const { get, post } = useFetch();
  const { showToast, showErrorToast } = useToast();
  const [linksList, setLinksList] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [errorMessageDescription, setErrorMessageDescription] = useState();

  useEffect(() => {
    (async () => {
      const getFluidLinkData = await get(
        `${DETAILS_APIS.GET_FLUID_WATCH_LINKS}?FluidId=${fluidId || 0}`
      );
      if (getFluidLinkData.length > 0) {
        setLinksList(getFluidLinkData);
      } else {
        setLinksList([...linksList, {}]);
      }
    })();
  }, [fluidId]);

  const getWatchChangeHandler = (link, index) =>
    function (e) {
      linksList[index][e.target.name] = e.target.value;
      setLinksList([...linksList]);
    };

  const getWatchDeleteHandler = (link, index) =>
    function (e) {
      linksList[index].Delete = e.target.checked;
      setLinksList([...linksList]);
    };

  const getTestClickHandler = (link, index) =>
    function (e) {
      const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;
      if (urlPattern.test(link.Link)) {
        setErrorMessage("");
        window.open(link.Link, "_blank", "noopener,noreferrer");
      } else {
        setErrorMessage("Invalid URL format!");
      }
    };

  async function SaveClick() {
    try {
      // Validate each item in the linksList array
      await Promise.all(
        linksList?.map(async (x) => {
          await validationSchema.validate(x, { abortEarly: false });
        })
      );

      const temp = linksList?.map((x) => ({
        ...x,
        Link: x.Link || null,
        LinkDescription: x.LinkDescription || null,
        FluidId: fluidId,
        Delete: isNaN(x.Delete) ? false : x.Delete,
        Screen: "Fluid Watch Links",
      }));

      const response = await post(DETAILS_APIS.SAVE_FLUID_WATCH_LINKS, temp);
      if (response > 0) {
        showToast(appResources.UPDATED_LINKS_SUCCESSFULLY_MSG);
        onFluidLinkUpdated();
      } else {
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
      setAddLinkDialogueOpen(false);
    } catch (error) {
      // Handle validation errors
      if (error.name === "ValidationError") {
        // Display the error messages from Yup

        setErrorMessageDescription(error.errors[0]);
        setErrorMessage(error.errors[1]);
        // showErrorToast(errorMessages);
      } else {
        // Handle other errors
        console.error(error);
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
    }
  }

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    Link: Yup.string().required("Link is required"),
    LinkDescription: Yup.string().required("Link Description is required"),
  });

  return (
    <>
      <Grid container xs={12} spacing={2}>
        <Grid container xs={12} item justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle2">
              Add BOTH a link and a description; use the Test button to check
              your link
            </Typography>
          </Grid>
          <Grid item>
            <Button
              item
              startIcon={<NewIcon />}
              variant="outlined"
              onClick={() => setLinksList([...linksList, {}])}
            >
              Add New
            </Button>
          </Grid>
        </Grid>

        <Grid container xs={12} item spacing={1}>
          {linksList?.map((value, index) => (
            <Grid container item key={index} spacing={1}>
              <Grid item xs={10}>
                <TextBox
                  name="Link"
                  label="URL"
                  value={value.Link || ""}
                  onChange={getWatchChangeHandler(value, index)}
                  errorMsg={
                    !value.Link ||
                      value.Link === null ||
                      errorMessage === "Invalid URL format!"
                      ? errorMessage
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={2} sx={{ mt: "12px" }}>
                <Button
                  item
                  onClick={getTestClickHandler(value, index)}
                  variant="outlined"
                  startIcon={<TestIcon />}
                >
                  Test
                </Button>
              </Grid>
              <Grid item xs={10}>
                <TextBox
                  name="LinkDescription"
                  label={"Text"}
                  value={value.LinkDescription || ""}
                  onChange={getWatchChangeHandler(value, index)}
                  errorMsg={
                    !value.LinkDescription || value.LinkDescription === null
                      ? errorMessageDescription
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={2} sx={{ mt: "18px" }}>
                <SwitchInput
                  label="Delete"
                  value={value.Delete || false}
                  disabled={
                    value.FluidWatchId == null &&
                    value.FluidWatchId == undefined
                  }
                  onChange={getWatchDeleteHandler(value, index)}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid container xs={12} item direction="row-reverse">
          <Grid item>
            <Button
              item
              onClick={SaveClick}
              disabled={linksList?.length < 1}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
