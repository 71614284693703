import { Navigate, useRoutes } from "react-router-dom";
import ProvidedLimits from "./features/master-data/provided-limits/ProvidedLimits";
import TestSets from "./features/master-data/test-sets/TestSets";
import TestItems from "./features/master-data/test-items/TestItems";
import SearchRegistration from "./features/quick-details/registrations/SearchRegistration";
import TrayDashboard from "./features/dashboards/tray/TrayDashboard";
import AutoEvaluation from "./features/trays/04-auto-evaluation/AutoEvaluation";
import Evaluation from "./features/trays/05-evaluation/Evaluation";
import CreateTray from "./features/trays/create-tray/CreateTray";
import MissedSamples from "./features/reports/missed-samples/MissedSamples";
import Laboratory from "./features/trays/laboratory/Laboratory";
import Registration from "./features/trays/01-registration/Registration";
import QualityCheck from "./features/trays/quality-check/QualityCheck";
import SelectImage from "./components/SelectImage";
import AutoEvaluationDashboard from "./features/dashboards/tray/AutoEvaluationDashboard";
import Chart from "./features/charts/Chart";
import FluidWatch from "./features/master-data/fluids/FluidWatch";
import Review from "./features/trays/review/Review";
import EditContacts from "./forms/EditContacts";
import AddCriticalLogs from "./features/reports/critical-logs/add-critical-logs/AddCriticalLogs";
import SearchContact from "./features/quick-details/SearchContact";
import QuickDetails from "./features/quick-details/QuickDetails";
import Fluids from "./features/master-data/fluids/Fluids";
import CustomerParents from "./features/customer/Parents";
import UnitModels from "./features/master-data/unit-models/UnitModels";
import ComponentModels from "./features/master-data/components-model/ComponentModels";
import SiteSets from "./features/master-data/sites-set/SiteSets";
import UsersOverview from "./features/management/UsersOverview";
import AuditLogs from "./features/reports/audit-logs/AuditLogs";
import Laboratories from "./features/management/Laboratories";
import Products from "./features/management/Products";
import AutoEvaluationComments from "./features/management/AutoEvaluationComments";
import UnitHourHistory from "./features/quick-details/unit-hour-history/UnitHourHistory";
import RecentUnitResults from "./features/quick-details/recent-unit-result/RecentUnitResults";
import PresetComments from "./features/management/PresetComments";
import Messages from "./features/management/Messages";
import CustomerSites from "./features/management/CustomerSites";

import QuickMergeFluid from "./features/master-data/quick-merge-fluid/QuickMergeFluid";
import QuickMergeModel from "./features/master-data/quick-merge-model/QuickMergeModel";
import QuickMoveSites from "./features/master-data/quick-move-sites/QuickMoveSites";
import QuickMergeSites from "./features/master-data/quick-merge-sites/QuickMergeSites";
import Cleanup from "./features/dashboards/cleanup/Cleanup";
import IkowaFeedback from "./features/dashboards/ikowa-feedback/IkowaFeedback";
import RerunSamples from "./features/reports/rerun-samples/RerunSamples";
import HoldManagement from "./features/management/HoldManagement";
import CriticalLogs from "./features/reports/critical-logs/CriticalLogs";
import AccessPermissionOverview from "./features/reports/critical-logs/access-permission-overview/AccessPermissionOverview";
import EditRegistration from "./features/quick-details/registrations/EditRegistration";
import InsiteMachineSearch from "./features/master-data/unit-models/InsiteMachineSearch";
import InsiteModelSearch from "./features/master-data/unit-models/InsiteModelSearch";
import MachineTemplates from "./features/master-data/machine-templates/MachineTemplates";
import GroupEmailDomains from "./features/management/GroupEmailDomains";
import MachineNotifications from "./features/management/MachineNotifications";
import ApprovalToRelease from "./features/trays/approval-to-release/ApprovalToRelease";
import TrayDashboard2 from "./features/dashboards/tray/TrayDashboard2";
import SelectUnitManagement from "./features/master-data/unit-models/SelectUnitManagement";
import QuickDetails3 from "./features/details-old/QuickDetails3";
import EmailsAndSamplesReports from "./features/quick-details/manage/email-sample-report/EmailsAndSamplesReports";
import QuickDetails4 from "./features/details-old/QuickDetails4";
import EditTray from "./forms/EditTray";
import SpecialReport from "./forms/SpecialReport";
import LaboratoryNew from "./features/trays/laboratory/LaboratoryNew";
import SpecialReportTemplates from "./features/reports/special-reports/SpecialReportTemplates";
import SpecialReportTemplatesTypes from "./features/reports/special-reports/SpecialReportTemplatesTypes";
import ImportHistory from "./features/reports/import-history/ImportHistory";
import ReportToMail from "./features/reports/report-to-mail/ReportToMail";
import FluidNotifications from "./features/management/Notifications/FluidNotifications";
import ProductNotifications from "./features/management/Notifications/ProductNotifications";
import Hold from "./features/trays/laboratory/Hold";
import ManagementReport from "./features/management/ManagementReport";
import EditBillingRates from "./features/management/EditBillingRates";
import GlobalSettings from "./features/management/GlobalSettings";
import WebsiteNews from "./features/management/WebsiteNews/WebsiteNews";
import PrimaryContactOverview from "./features/management/primary-contact-overview/PrimaryContactOverview";
import ContactExports from "./features/reports/critical-logs/contact-permission-overview/ContactExports";
import SampleReportsOverview from "./features/management/sample-report-overview/SampleReportsOverview";
import SubscriptionOverview from "./features/quick-details/subscription/SubscriptionOverview";

export default function AppRouter({ user }) {

  const adminRoutes = [
    { path: "management/usersOverview", element: <UsersOverview /> },
    { path: "reports/audit-Log", element: <AuditLogs /> },
    { path: "management/group-email-domains", element: <GroupEmailDomains />, },
    { path: "management/auto-evaluation-comments", element: <AutoEvaluationComments />, },
    { path: "management/laboratories", element: <Laboratories /> },
    { path: "management/products", element: <Products /> },
    { path: "management/management-report", element: <ManagementReport /> },
    { path: "management/edit-billing-rates", element: <EditBillingRates /> },
    { path: "management/global-settings", element: <GlobalSettings /> },
    { path: "management/website-news", element: <WebsiteNews /> },
  ]
  const nonAdminRoutes = [
    { exact: true, path: "/", element: <TrayDashboard2 /> },
    {
      path: "recent-unit-results",
      element: <RecentUnitResults />,
    },
    {
      path: "add-critical-logs",
      element: <AddCriticalLogs />,
    },
    {
      path: "unit-hour-history",
      element: <UnitHourHistory />,
    },
    {
      path: "primary-contacts-overview",
      element: <PrimaryContactOverview />,
    },
    {
      path: "export-contacts-overview",
      element: <ContactExports />,
    },
    {
      path: "report-contacts",
      element: <SampleReportsOverview />,
    },
    {
      path: "subscription-overview",
      element: <SubscriptionOverview />,
    },

    {
      path: "trays/laboratory2",
      element: <LaboratoryNew />,
    },
    {
      path: "management/preset-comments",
      element: <PresetComments />,
    },
    {
      path: "management/messages",
      element: <Messages />,
    },
    {
      path: "management/machine-notifications",
      element: <MachineNotifications />,
    },
    {
      path: "management/customerSites",
      element: <CustomerSites />,
    },
    {
      path: "settings/fluid-type-notifications",
      element: <FluidNotifications />,
    },
    {
      path: "settings/product-notifications",
      element: <ProductNotifications />,
    },
    {
      path: "management/email-and-sample-reports",
      element: <EmailsAndSamplesReports />,
    },

    { path: "master-data/provided-limits", element: <ProvidedLimits /> },
    { path: "master-data/index", element: <TestSets /> },
    { path: "master-data/test-items", element: <TestItems /> },
    { path: "registrations/search", element: <SearchRegistration />, },
    { path: "trays/create", element: <CreateTray /> },
    { path: "trays/registration", element: <Registration /> },
    { path: "trays/laboratory", element: <Laboratory /> },
    { path: "trays/quality-check", element: <QualityCheck /> },
    { path: "trays/auto-evaluation", element: <AutoEvaluation /> },
    { path: "trays/evaluation", element: <Evaluation /> },
    { path: "trays/approval", element: <ApprovalToRelease /> },
    { path: "trays/review", element: <Review /> },
    { path: "details/quick4", element: <QuickDetails /> },
    { path: "details/quick3", element: <QuickDetails3 /> },
    { path: "details/quick", element: <QuickDetails4 /> },
    { path: "dashboards/tray", element: <TrayDashboard2 /> },
    { path: "dashboards/tray2", element: <TrayDashboard /> },
    { path: "missed-samples", element: <MissedSamples /> },
    { path: "chart", element: <Chart /> },
    { path: "master-data/fluids", element: <Fluids /> },
    { path: "master-data/quickMergeFluid", element: <QuickMergeFluid /> },
    { path: "master-data/quickMergeModel", element: <QuickMergeModel /> },
    { path: "master-data/quickMoveSites", element: <QuickMoveSites /> },
    { path: "master-data/quickMergeSites", element: <QuickMergeSites /> },
    { path: "reports/critical-logs", element: <CriticalLogs /> },
    { path: "test/chart", element: <Chart /> },
    { path: "images", element: <SelectImage />, },
    {
      path: "test/edit-reg",
      element: <EditRegistration regId={236627} />,
    },
    {
      path: "master-data/FluidWatch",
      element: <FluidWatch fluidId={null} isShowTitle={true} />,
    },
    {
      path: "customer/Parents",
      element: <CustomerParents fluidId={null} />,
    },
    {
      path: "management/search-contact",
      element: <SearchContact />,
    },
    {
      path: "dashboards/auto-evaluation",
      element: <AutoEvaluationDashboard />,
    },
    {
      path: "dashboards/ikowa-feedback",
      element: <IkowaFeedback />,
    },
    {
      path: "dashboards/Cleanup",
      element: <Cleanup />,
    },
    {
      path: "AccessPermissionOverview",
      element: <AccessPermissionOverview />,
    },

    {
      path: "test/edit-contacts",
      element: <EditContacts />,
    },
    {
      path: "test/edit-tray",
      element: <EditTray />,
    },
    {
      path: "test/special-report",
      element: <SpecialReport />,
    },
    {
      path: "master-data/UnitModels",
      element: <UnitModels />,
    },
    {
      path: "master-data/ComponentModels",
      element: <ComponentModels />,
    },
    {
      path: "master-data/machine-templates",
      element: <MachineTemplates />,
    },
    {
      path: "master-data/view-komatsu-machines",
      element: <InsiteMachineSearch />,
    },
    {
      path: "master-data/view-komatsu-models",
      element: <InsiteModelSearch />,
    },
    {
      path: "master-data/SiteSets",
      element: <SiteSets />,
    },
    {
      path: "reports/rerun-samples",
      element: <RerunSamples />,
    },
    {
      path: "reports/missed-and-hold-samples",
      element: <MissedSamples />,
    },
    {
      path: "reports/special-report",
      element: <SpecialReportTemplates />,
    },
    {
      path: "reports/special-report-types",
      element: <SpecialReportTemplatesTypes />,
    },
    {
      path: "reports/import-history",
      element: <ImportHistory />,
    },
    {
      path: "reports/reports-email",
      element: <ReportToMail />,
    },
    {
      path: "reports/email-selected-items",
      element: <EmailsAndSamplesReports />,
    },
    {
      path: "management/hold-management",
      element: <HoldManagement />,
    },
    {
      path: "hold",
      element: <Hold />
    },
    {
      path: "test/unitmanagement",
      element: <SelectUnitManagement />,
    },
    {
      path: "*",
      element: <Navigate to="/" />
    },
  ]
  let routes = ((user?.RoleAdministration || user?.RoleDeveloper) === true) ? [...adminRoutes, ...nonAdminRoutes] : nonAdminRoutes
  let element = useRoutes(routes);

  return element;
}
