import { Check, } from "@mui/icons-material";
import { Card, Grid, Typography } from "@mui/material";
import { useEffect, } from "react";
import { useReducer, useState } from "react";
import useFetch from "../hooks/useFetch";
import Form, { FormActions } from "../components/Form";
import DataTable from "../components/DataTable";
import { toLocalDateTime } from "../utils";
import { DETAILS_APIS, } from "../common/apis";
import { useRoot, } from "../rootContext";
import FormFooter from "../components/FormFooter";

export default function EditTray() {

  const [data, dispatch] = useReducer(SearchFilterReducer, SearchFilterDefaults);
  const { get, post } = useFetch();
  const { laboratories, } = useRoot();
  const [rerunsByDateData, setRerunsByDateData] = useState([]);
  const [trayOverview, setTrayOverview] = useState();

  //Call api for user dropdown data
  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    let responseTrayData = await get(
      `${DETAILS_APIS.TRAY_OVERVIEW}?laboratoryId=1&tray=6&trayDate=20211213`
    );
    if (responseTrayData) {
      if (responseTrayData.trayOverview.length > 0) {
        setTrayOverview(responseTrayData.trayOverview[0]);
        setRerunsByDateData(responseTrayData.rerunsByDate);
        dispatch({
          payload: {
            ...responseTrayData.trayOverview[0],
            key: Date.now(),
          },
          type: FormActions.LOAD_DATA,
        });
      } else {
        setRerunsByDateData([]);
        setTrayOverview();
      }
    }
  }

  //call api for search audit data
  async function saveClick() {
    await post(DETAILS_APIS.EDIT_TRAY, {
      ...data,
      LaboratoryId: data?.LaboratoryId,
      Tray: data?.Tray,
      TrayDate: data?.TrayDate,
      Delivered: data?.ReceivedMinimum,
      IsTrainingSample: data?.IsTrainingSample || false,
      OwnerLaboratoryId: data?.OwnerLaboratoryId,
      TrayLabel: data?.TrayLabel,
    });
    await loadData();
  }
  const tblOptions = {
    columns: GetColumns(),
    dataSource: rerunsByDateData || [],
    rowIdProperty: "Dummy1",
    isDisableColumnFilter: true,
    sx: { height: 600 },
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(laboratories, trayOverview, data)}
              data={data}
              dispatch={dispatch}
              key={data?.key}
            />
            <FormFooter buttons={GetFormButtons(saveClick,)} />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns() {
  return [
    {
      headerName: "Type",
      field: "Type",
      minWidth: 120,
    },
    {
      headerName: "SampleNumber",
      field: "SampleNumber",
      minWidth: 120,
    },
    {
      headerName: "Sample Count",
      field: "SampleCount",
      minWidth: 100,
    },
    {
      headerName: "Send Back",
      field: "SentBack",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      minWidth: 170,
    },

    {
      headerName: "Send By",
      field: "SentBy",
      minWidth: 200,
    },
    {
      headerName: "Reason",
      field: "Reason",
      minWidth: 200,
      flex: 1,
    },
  ];
}

function GetFormSections(laboratories, trayOverview, data) {
  return [
    {
      fields: [
        {
          xsCol: 12,
          group: [
            {
              xsCol: 12,
              component: function () {
                return (
                  <Typography variant="body1" style={{ fontSize: 13 }}>
                    {/* Tray {trayOverview?.Tray} on {trayOverview?.TrayDateDate} Laboratory  */}
                  </Typography>
                );
              },
            },
          ],
        },

        {
          name: "TrayLabel",
          label: "Tray Label",
          xsCol: 1,
        },
        {
          name: "LaboratoryId",
          label: "Owner",
          type: "dropdown",
          xsCol: 2,
          required: true,
          selectOptions: (laboratories || [])
            ?.filter((x) => x?.LaboratoryId !== 0)
            ?.map((x) => ({
              value: x.LaboratoryId,
              text: x.Laboratory,
            })),
        },

        {
          name: "IsTrainingSample",
          label: "Training",
          xsCol: 1,
          type: "switch",
        },
        {
          name: "IsDelivered",
          label: "Delivered",
          xsCol: 1,
          type: "switch",
        },
        {
          name: "ReceivedMinimum",
          label: "Use Delivered",
          xsCol: 2,
          type: "datetime",
          disabled: !data?.IsDelivered,
          disableFuture: true,
        },
        {
          xsCol: 2,
          alignSelf: "end",
          component: function ({ data }) {
            return (
              <Typography variant="body1" style={{ fontSize: 13 }}>
                The earliest Tray Date is{" "}
                {toLocalDateTime(trayOverview?.ReceivedMinimum)}
              </Typography>
            );
          },
        },
        {
          name: "Re-Approve",
          label: "Re-Approve",
          type: "switch",
          xsCol: 1,
        },
        {
          xsCol: 2,
          alignSelf: "end",
          component: function ({ data }) {
            return (
              <Typography variant="body1" style={{ fontSize: 13 }}>
                Tray. where already released
              </Typography>
            );
          },
        },

        {
          xsCol: 12,
          group: [
            {
              xsCol: 2,
              alignSelf: "end",
              component: function () {
                return (
                  <Typography variant="body1" style={{ fontSize: 13 }}>
                    Sample Count {trayOverview?.SampleCount}
                  </Typography>
                );
              },
            },
            {
              xsCol: 2,
              alignSelf: "end",
              component: function () {
                return (
                  <Typography variant="body1" style={{ fontSize: 13 }}>
                    New Version Count {trayOverview?.NewVersionCount}
                  </Typography>
                );
              },
            },
            {
              xsCol: 6,
              alignSelf: "end",
              component: function () {
                return (
                  <Typography variant="body1" style={{ fontSize: 13 }}>
                    On Hold Count {trayOverview?.HoldCount}
                  </Typography>
                );
              },
            },
          ],
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return action.payload;
  }
  return state;
}
const SearchFilterDefaults = {
  LaboratoryId: 1,
};
const GetFormButtons = (saveClick,) => [
  {
    label: "Save",
    onClick: saveClick,
    startIcon: <Check />,
  },
];
