import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function QuickMergeSitesTable({ data, rowsSelected }) {
  const fromMergeOptions = {
    columns: fromMergeColumns,
    checkBoxSelect: true,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.CustomerId + "" + (x.Site || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: false,
    rowsSelected: rowsSelected,
    cellStylingHandler: "productCommonCellColour",
    sx: { height: 760 },
  };
  return <DataTable {...fromMergeOptions} />;
}

const fromMergeColumns = [
  {
    headerName: "From Product",
    field: "Product",
    minWidth: 180,
    renderCell: renderCellExpand,
  },
  {
    headerName: "From Customer",
    field: "Customer",
    minWidth: 190,
    renderCell: renderCellExpand,
  },
  {
    headerName: "From Site",
    field: "Site",
    minWidth: 120,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
