import { Typography } from "@mui/material";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";

export default function SiteTable({
  data,
  rowSelected,
  actions,
  rowActionHandler,
  propValue,
  selectedRow,
}) {
  const options = {
    columns: GetColumns(actions, propValue, { rowActionHandler }),
    dataSource: data,
    rowIdProperty: "SiteId",
    selectionModel: selectedRow,

    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected && rowSelected(r[0]);
    },
  };
  return <DataTable {...options}></DataTable>;
}

const GetColumns = (actions, propValue, { rowActionHandler }) => [
  {
    headerName: "Product",
    field: "Product",
    minWidth: 150,
    renderCell: function ({ row }) {
      return (
        <>
          {(actions || []).map(function (ac, i) {
            return (
              <DataGridAction
                key={i}
                row={row}
                onClick={ac.action}
                label={ac.label}
                startIcon={ac.startIcon}
              />
            );
          })}
          <DropdownMenu
            options={Options}
            onItemSelect={(option) => rowActionHandler(row, option)}
          />
          <Typography paddingTop={0.25}>{row.Product}</Typography>
        </>
      );
    },
  },
  //  {
  //   headerName: "Product",
  //   field: "Product",
  //   width: 140,
  // },
  {
    headerName: "Customer",
    field: "Customer",
    width: 200,
  },
  {
    headerName: "Site",
    field: "Site",
    width: 140,
  },
  {
    headerName: "Postal",
    field: "PhysicalCity",
    width: 140,
  },
  {
    headerName: "Postal",
    field: "PostalCity",
    width: 140,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 140,
  },
  // {
  //   headerName: "Actions",
  //   field: "Actions",
  //   sortable: false,
  //   //hide: (actions || []).length < 1,
  //   flex: 1,
  //   renderCell: function ({ row }) {
  //     return (
  //       <>
  //         {(actions || []).map(function (ac, i) {
  //           return (
  //             <DataGridAction
  //               key={i}
  //               row={row}
  //               onClick={ac.action}
  //               label={ac.label}
  //               startIcon={ac.startIcon}
  //             />
  //           );
  //         })}
  //         {propValue && propValue === 4 ? null : (
  //           <DropdownMenu
  //             options={Options}
  //             onItemSelect={(option) => rowActionHandler(row, option)}
  //           />
  //         )}
  //       </>
  //     );
  //   },
  // },
];

const Options = [
  {
    label: "Additional Reports",
    name: "additional-reports",
    items: [
      { label: "Reports by Date", name: "reports-by-date" },
      { label: "Full History as XLSX", name: "report-contacts" },
      { label: "Full History as XML", name: "reports-by-date" },
      {
        label: "Components No Recent Samples",
        name: "components-no-recent-samples",
      },
      { label: "Units No Recent Samples", name: "units-no-recent-samples" },
      { label: "Reference XSD", name: "reports-by-date" },
    ],
  },
  // { label: "Edit Site", name: "edit-site" },
  { label: "New Unit", name: "add-new-unit" },
  {
    label: "Manage ",
    name: "manage",
    items: [
      { label: "Site", name: "manage-site" },
      { label: "Customer", name: "manage-customer" },
    ],
  },
  {
    label: "Contacts",
    name: "contacts",
    items: [
      { label: "Access Permission", name: "access-permission-overview" },
      { label: "Sample Reporting", name: "report-contacts" },
      { label: "Primary Contact", name: "primary-contacts-overview" },
      { label: "Subscriptions", name: "subscription-overview" },
      { label: "Exports", name: "export-contacts-overview" },
    ],
  },
  {
    label: "Search",
    name: "search",
    items: [
      { label: "Customer", name: "search-customer" },
      { label: "Unit", name: "search-unit" },
      { label: "Component", name: "search-component" },
      { label: "Sample", name: "search-sample" },
    ],
  },
];
