import { Card, Grid, Typography, } from "@mui/material";
import { useEffect, useMemo, useReducer, useState } from "react";
import { CRITICAL_LOGS_APIS } from "../../../../common/apis";
import AppDialog from "../../../../components/AppDialog";
import useFetch from "../../../../hooks/useFetch";

import { EventReducer, FormSections, SearchFilterDefaults, } from "./AccessPermissionOverviewForm";
import AccessPermissionOverviewTable from "./AccessPermissionOverviewTable";
import Form from "./../../../../components/Form";
import SearchContact from "../../../quick-details/SearchContact";

export default function AccessPermissionOverview({
  formData,
  rowDataProps,
  isKomatsuCheck,
  setOpenDialog,
  setDialog,
}) {
  const { post, postFile } = useFetch();
  const [data, dispatch] = useReducer(EventReducer, SearchFilterDefaults);
  const [searchContactDialogueOpen, setSearchContactDialogueOpen] = useState(false);

  function searchContactScope() {
    setSearchContactDialogueOpen(true);
  }
  const [accessPermissionOverviewData, setAccessPermissionOverviewData] =
    useState([]);
  // const [rowData, setRowData] = useState([]);

  function rowSelectedHandler(rowData) {
    rowDataProps(rowData);
  }

  const handleExportContacts = async () => {
    let resp = await postFile(
      CRITICAL_LOGS_APIS.EXPORT_CONTACTS_FOR_SCOPE,
      {
        ParentId: formData?.ParentId,
        ProductId: formData?.ProductId,
        SampleNumber: formData?.SampleNumber,
      },
      "Access Contacts"
    );
    // const blob = await resp.blob();
    // const url = URL.createObjectURL(blob);
    // return window.open(url);
  };

  const filteredData = useMemo(
    () =>
      data
        ? (accessPermissionOverviewData || []).filter((x) => {
          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.DisplayName?.toLowerCase().includes(filterLower) ||
              x.Username?.toLowerCase().includes(filterLower) ||
              x.Company?.toLowerCase().includes(filterLower);
          }
          return (
            ((filterMatch && data.Komatsu && x.IsKomatsu) ||
              (data.Other && !x.IsKomatsu)) &&
            ((data.Product && x.Scope === "Product") ||
              (data.SiteSet && x.Scope === "Site Set") ||
              (data.Customer && x.Scope === "Customer") ||
              (data.Site && x.Scope === "Site") ||
              (data.Unit && x.Scope === "Unit") ||
              (data.Parent && x.Scope === "Parent") ||
              (data.Unit && x.Scope === "Unit") ||
              (data.Component && x.Scope === "Component"))
          );
        })
        : accessPermissionOverviewData,
    [data, accessPermissionOverviewData]
  );

  useEffect(() => {
    (async function () {
      let parseData = {};
      let formDatas = localStorage.getItem("AccessPermissionOverview")

      if (formDatas !== null && formDatas !== "null" && formDatas !== undefined) {
        parseData = JSON.parse(formDatas)
      }

      let resData = await post(CRITICAL_LOGS_APIS.CONTACTS_FOR_SCOPE, {
        SampleNumber: formData?.SampleNumber || parseData?.SampleNumber,
        ParentId: formData?.ParentId || parseData?.ParentId,
        ProductId: formData?.ProductId || parseData?.ProductId,
        CustomerId: formData?.CustomerId || parseData?.CustomerId,
      });
      setAccessPermissionOverviewData(resData);
      localStorage.removeItem("AccessPermissionOverview");

    })();
  }, [formData?.SampleNumber]);

  return (
    <>
      <Grid container spacing={2} minWidth={"90vw"}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={FormSections(searchContactScope, handleExportContacts)}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData && filteredData.length}</b>
          </Typography>
          <AccessPermissionOverviewTable
            setOpenDialog={setOpenDialog}
            data={filteredData}
            rowSelected={rowSelectedHandler}
            setDialogClose={setDialog}
          />
        </Grid>
      </Grid>
      <AppDialog
        open={searchContactDialogueOpen}
        handleClose={() => setSearchContactDialogueOpen(false)}
        title="Search Contacts"
        maxWidth="100vw"
      >
        <SearchContact />
      </AppDialog>
    </>
  );
}
