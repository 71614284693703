import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import { Card, InputLabel } from "@mui/material";
import TrayDashboardTable from "./TrayDashboardTable";
import useFetch from "../../../hooks/useFetch";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TraySummary from "./TraySummary";
import moment from "moment";
import AppDialog from "../../../components/AppDialog";

import TextBox from "../../../components/TextBox";
import { DASHBOARD_APIS } from "../../../common/apis";
import RerunSamples from "../../reports/rerun-samples/RerunSamples";
import MissedSamples from "../../reports/missed-samples/MissedSamples";
import SwitchInput from "../../../components/SwitchInput";
import { RerunSampleIcon } from "../../../icons/ButtonIcons";

const TrayDashboard = () => {
  const [refreshOption] = useState(["0", "2", "5", "10", "15"]);
  const [labs, setLabs] = useState([
    { Name: "Brisbane", Id: 1, checked: true },
    { Name: "Perth", Id: 2, checked: true },
    { Name: "Newcastle", Id: 3, checked: true },
  ]);
  const [dataSource, setDataSource] = useState([]);
  const [trayDate, setTrayDate] = useState(new moment());
  const [traySummaries, setTraySummaries] = useState({});

  const { post } = useFetch();

  // Imran Zulfiqar
  const [rerunSamplesOpen, setRerunSamplesOpen] = useState(false);
  function handleRerunClick() {
    setRerunSamplesOpen(true);
  }
  const [missedSamplesOpen, setMissedSamplesOpen] = useState(false);

  function handleMissedClick() {
    setMissedSamplesOpen(true);
  }

  const handleChangeDate = (trayDateParam) => {
    setTrayDate(trayDateParam);
  };

  const handleChangeSwitch = (event) => {
    const labId = event.target.name.split("_")[1];
    const lab = labs.find((x) => x.Id == labId);

    lab.checked = event.target.checked;

    setLabs(
      [...labs.filter((x) => x.Id != labId), lab].sort((a, b) => a.Id - b.Id)
    );
  };

  useEffect(() => {
    (async () => {
      if (trayDate._isValid) {
        const formattedDate = trayDate.format("YYYYMMDD");
        const data = await post(DASHBOARD_APIS.TRAY, {
          LabIds: labs?.filter((x) => x.checked).map((x) => x.Id),
          TrayDate: formattedDate,
        });
        setDataSource(data);
      } else return;
    })();
    //  setTrayDate(new moment());
  }, [trayDate, labs]);

  const rowsSelectedHandler = (index) => (rows) => {
    setTraySummaries({ ...traySummaries, [index]: rows[rows.length - 1] });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    <InputLabel>Tray Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={trayDate}
                        onChange={handleChangeDate}
                        renderInput={(params) => <TextBox {...params} />}
                        componentsProps={{
                          actionBar: {
                            actions: ["today"],
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item ml={1}>
                    <InputLabel sx={{ paddingBottom: 1 }}>
                      Select Labs
                    </InputLabel>
                    {labs?.map((lab) => (
                      <FormControlLabel
                        control={
                          <SwitchInput
                            checked={lab.checked}
                            onChange={handleChangeSwitch}
                            name={"lab_" + lab.Id}
                            size="small"
                            label={"CMS " + lab.Name}
                          />
                        }
                        key={lab.Id}
                      />
                    ))}
                  </Grid>
                  <Grid item>
                    {/* <InputLabel >Auto Update Every</InputLabel>
            <FormGroup row={true}>
              <Select
                value={autoUpdate}
                onChange={handleChangeDropdown}
                displayEmpty
                inputProps={{ "aria-label": "Auto Update Every" }}
                sx={{ width: 100 }}
              >
                {refreshOption.map((op) => (
                  <MenuItem value={op} key={op}>
                    {op}
                  </MenuItem>
                ))}
              </Select> */}
                    {/* <Button
                      variant="contained"
                      sx={{ mt: "20px" }}
                      startIcon={<UpdateIcon />}
                    >
                      Update
                    </Button> */}
                  </Grid>
                  <Grid item>
                    <FormHelperText sx={{ mt: "10px", mt: "27px" }}>
                      Last Update:{" "}
                      {trayDate && trayDate.format("dddd DD MMMM YYYY")}
                    </FormHelperText>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {dataSource?.map((data, i) => {
          return (
            <Grid item key={i} xs={12}>
              <TrayDashboardTable
                data={data.Trays}
                rowsSelected={rowsSelectedHandler(i)}
                labName={labs.find((x) => x.Id === data.LabId)?.Name || ""}
              />

              <Card>
                <TraySummary
                  summary={traySummaries && traySummaries[i]}
                  data={data}
                  trayDate={trayDate}
                  labs={labs}
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <AppDialog
        open={rerunSamplesOpen}
        handleClose={() => setRerunSamplesOpen(false)}
        title="Rerun Samples"
        maxWidth="xl"
      >
        <RerunSamples />
      </AppDialog>

      <AppDialog
        open={missedSamplesOpen}
        handleClose={() => setMissedSamplesOpen(false)}
        title="Missed and Hold Samples"
        maxWidth="xl"
      >
        <MissedSamples screenName={"TrayDashboard"} />
      </AppDialog>
    </>
  );
};

export default TrayDashboard;
