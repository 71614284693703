import { toLocalDateTime } from "../../../utils";

export const HoldTableColmns = [
  {
    headerName: "Laboratory",
    field: "Laboratory",
    width: 120,
  },
  {
    headerName: "Tray",
    field: "Tray",

    minWidth: 55,
  },
  {
    headerName: "SampleNumber",
    field: "SampleNumber",

    minWidth: 165,
  },
  {
    headerName: "Hold",
    field: "HoldType",

    minWidth: 165,
  },
  {
    headerName: "Added",
    field: "Added",
    valueFormatter: ({ value }) => toLocalDateTime(value),

    minWidth: 165,
  },
  {
    headerName: "Until",
    field: "HoldUntil",
    valueFormatter: ({ value }) => toLocalDateTime(value),
    flex: 1,
    minWidth: 165,
  },
];
