import DataTable from "../../components/DataTable";
import renderCellExpand from "../../components/DatatableCellExpand";

export default function ParentsTable({ data, rowSelected, selectedRow }) {
  const options = {
    columns: Columns,
    dataSource: data,
    rowIdProperty: "ParentId",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    sx: {
      height: 720,
    },
  };
  return <DataTable {...options} />;
}

const Columns = [
  {
    headerName: "Product",
    field: "Product",
    minWidth: 150,
  },
  {
    headerName: "Parent",
    field: "Parent",
    minWidth: 300,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
