import * as React from "react";
import { Check, Settings } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useReducer, useState } from "react";
import AppDialog from "../../../components/AppDialog";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import { FLEET_APIS } from "../../../common/apis";
import ManageComponentModels from "./ManageComponentModels";
import { useRoot, useToast } from "../../../rootContext";
import { ValidateMakeModel } from "../../../validation/schemas/Make_ModelSchema";
import appResources from "../../../app-resources/en/Resources.json";

export default function ViewComponentModels({
  unitModel,
  fetchComponentModels,
}) {
  const { CanDoDataMaintenance } = useRoot();
  const { showToast, showErrorToast } = useToast();
  const { post } = useFetch();
  const [formErrors, setFormErrors] = useState({});
  //useFetch
  const [data, dispatch] = useReducer(FluidReducer, unitModel);
  const [manageUnitOpen, setManageUnitOpen] = useState(false);

  const actions = {
    save: async function () {
      setFormErrors({});
      ValidateMakeModel(data).then(async function ({ data: req, errors }) {
        if (req) {
          const response = await post(FLEET_APIS.SAVE_COMPONENT_MODELS, {
            ComponentMakeId: data.ComponentMakeId,
            Make: data.ComponentMake,
            Model: data.ComponentModel,
            Notes: data.ComponentMakesNotes,
            Screen: "Component Models",
          });
          if (response.length > 0) {
            showToast(appResources.COMPONENT_SAVED_SUCCESSFULLY_MSG);
            await fetchComponentModels();
          } else {
            showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
          }
        }
        if (errors) {
          setFormErrors(errors);
        }
      });
    },
    manage: function () {
      setManageUnitOpen(true);
    },
  };
  async function refreshGrid() {
    await fetchComponentModels();
    dispatch({ type: FormActions.RESET_FORM });
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          {/* <p>{error}</p> */}
          <Form
            sections={GetFormSections()}
            data={data}
            dispatch={dispatch}
            key={data && data._key}
            errors={formErrors}
          />
        </Grid>

        {CanDoDataMaintenance && <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(actions, unitModel, data,)}
            hideDivider
            gmt={0}
          />
        </Grid>}
      </Grid>
      <AppDialog
        open={manageUnitOpen}
        handleClose={() => setManageUnitOpen(false)}
        title={unitModel.ComponentMakeFormatted}
        maxWidth="xl"
      >
        <ManageComponentModels
          SelectedUnit={unitModel}
          refreshGrid={refreshGrid}
          setManageUnitOpen={setManageUnitOpen}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = ({ manage, save }, unitModel, data,) => [
  {
    variant: "outlined",
    label: "Manage",
    onClick: manage,
    disabled: (unitModel && !unitModel.ComponentMakeId),
    startIcon: <Settings />,
  },
  {
    variant: "contained",
    label: "Save",
    type: "submit",
    onClick: save,
    disabled: !data.ComponentMake || !data.ComponentModel,
    startIcon: <Check />,
  },
];

function GetFormSections() {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 6,
          name: "ComponentMake",
          label: "Make",
        },
        {
          xsCol: 6,
          name: "ComponentModel",
          label: "Model",
        },

        {
          xsCol: 12,
          name: "ComponentMakesNotes",
          label: "Notes",
          type: "textarea",
        },
      ],
    },
  ];
}

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { _key: Date.now() };
  }
  return state;
}
