import { Card, Grid, } from "@mui/material";
import { useMemo, useState } from "react";
import QuickDetailsTree3 from "./QuickDetailsTree3";
import useFetch from "../../hooks/useFetch";
import { DETAILS_APIS } from "../../common/apis";
import ViewDetails from "./ViewDetails";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CustomerAction from '../../redux/actions/CustomerActions';
import * as SiteAction from '../../redux/actions/SiteActions';
import * as UnitAction from '../../redux/actions/UnitActions';
import * as ComponentAction from '../../redux/actions/ComponentActions';

function QuickDetails3({ CustomerAction, SiteAction, UnitAction, ComponentAction }) {

  const { get } = useFetch();

  const [nodeLevel, setNodeLevel] = useState(0);

  const [sectionsData, setSectionsData] = useState({});

  const treeLevels = useMemo(
    () => ({
      product: 0,
      stLetter: 1,
      customer: 2,
      site: 3,
      unit: 4,
      component: 5,
      sample: 6,
    }),
    []
  );

  const nodeSelectedHandler = (node) => {
    setNodeLevel(treeLevels[node.nodeType] || 0);
    getNodeDetailData(node);
  };

  const treeLevelData = (node, data) => {
    if (node.nodeType === "customer") {
      CustomerAction.updateCustomer(data.Customer)
    }
    if (node.nodeType === "site") {
      CustomerAction.updateCustomer(data.Customer)
      SiteAction.updateSite(data.Site)
    }
    if (node.nodeType === "unit") {
      CustomerAction.updateCustomer(data.Customer)
      SiteAction.updateSite(data.Site)
      UnitAction.updateUnit(data.Unit)
    }
    if (node.nodeType === "component") {
      CustomerAction.updateCustomer(data.Customer)
      SiteAction.updateSite(data.Site)
      UnitAction.updateUnit(data.Unit)
      ComponentAction.updateComponent(data.Component)
    }
    if (node.nodeType === "sample") {
      CustomerAction.updateCustomer(data.Customer)
      SiteAction.updateSite(data.Site)
      UnitAction.updateUnit(data.Unit)
      ComponentAction.updateComponent(data.Component)
    }
  }

  const callNodeDetailApi = async (node) => {
    if (node.nodeType === "customer") {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer&cId=${node.customer}`
      );
    }
    if (node.nodeType === "site") {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer,site&cId=${node.customer}&sId=${node.site}`
      );
    }
    if (node.nodeType === "unit") {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer,site,unit&cId=${node.customer}&sId=${node.site}&uId=${node.unit}`
      );
    }
    if (node.nodeType === "component") {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer,site,unit,component&cId=${node.customer}&sId=${node.site}&uId=${node.unit}&compId=${node.component}`
      );
    }
    if (node.nodeType === "sample") {
      return await get(
        `${DETAILS_APIS.DETAILS}?types=customer,site,unit,component,sample&cId=${node.customer}&sId=${node.site}&uId=${node.unit}&compId=${node.component}&sampleNo=${node.sample}`
      );
    }
    return {};
  };

  const getNodeDetailData = async (node) => {
    const data = await callNodeDetailApi(node);
    treeLevelData(node, data)
    setSectionsData(data);
  };

  const handleSearchTreeData = (node, data) => {
    treeLevelData(node, data)
    setNodeLevel(treeLevels[node.nodeType] || 0);
    setSectionsData(data);
  }

  return (
    <Grid container  >
      <Grid item flex={1} sx={{ mr: 1 }}>
        <Card
          variant="outlined"
          mx={1}
          sx={{ minHeight: "92vh", maxHeight: "92vh", overflowX: "hidden", overflowY: "auto", }}
        >
          <QuickDetailsTree3
            nodeSelected={nodeSelectedHandler}
            handleSearchTreeData={handleSearchTreeData}
          />
        </Card>
      </Grid>

      <Grid item flex={5} sx={{ overflowY: "auto", minHeight: "92vh", maxHeight: "92vh", pr: "5px" }}>
        <ViewDetails sectionsData={sectionsData} nodeLevel={nodeLevel} />
      </Grid>
    </Grid>

  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    CustomerAction: bindActionCreators(CustomerAction, dispatch),
    SiteAction: bindActionCreators(SiteAction, dispatch),
    UnitAction: bindActionCreators(UnitAction, dispatch),
    ComponentAction: bindActionCreators(ComponentAction, dispatch),

  };
}

export default connect(null, mapDispatchToProps)(QuickDetails3);
