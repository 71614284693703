import { Button, Card, Grid } from "@mui/material";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import SiteSetsTable from "./SiteSetsTable";
import ViewSiteSets from "./ViewSiteSets";
import SitesCustomersTable from "./SitesCustomersTable";
import AppDialog from "../../../components/AppDialog";
import SearchDetails from "../../quick-details/SearchDetails";
import { CheckCircle } from "@mui/icons-material";
import { useEffect, useMemo, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import SwitchInput from "../../../components/SwitchInput";
import DropDown from "../../../components/DropDown";
import { useRoot } from "../../../rootContext";

export default function SiteSets() {
  const { get } = useFetch();
  const { allProducts, CanDoDataMaintenance } = useRoot();
  const [masterData, setMasterData] = useState();
  const [filter, setFilter] = useState({});
  const [parentsData, setParentsData] = useState([]);
  const [parentsCustomerData, setParentsCustomerData] = useState([]);
  const [selectedParent, setSelectedParent] = useState({});
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  const [isChecked, setIsChecked] = useState();

  async function fetchProductType() {
    let temp = allProducts;

    setMasterData(temp);
    return temp;
  }

  useEffect(
    function () {
      document.title = "Site Sets";
      (async function () {
        await fetchProductType();
      })();
    },
    [allProducts]
  );

  const isNotArchivedData = parentsData.filter((data) => !data.IsArchived);

  function handleFilterChange(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }
  function handleDropdown(e) {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    handleNewParentClick();
  }
  useEffect(() => {
    const newData = isChecked ? parentsData : isNotArchivedData;
    setFilter({ ...filter, data: newData });
  }, [filter, isChecked, isNotArchivedData]);

  const showArchived = (e) => {
    e.target.checked ? setIsChecked(true) : setIsChecked(false);
    const newData = e.target.checked ? parentsData : isNotArchivedData;
    setFilter({ ...filter, data: newData });
    handleNewParentClick();
  };

  async function refreshParentsData() {
    const sampleData = await get(FLEET_APIS.SITE_SETS);
    if (sampleData.length > 0) {
      const temp = sampleData.map((x, i) => ({ ...x, IndexId: i }));
      setParentsData(temp);
      setSelectedParent(temp[0]);
      fetchCustomerData(temp[0].SiteSetId);
    }
  }
  async function fetchCustomerData(siteId) {
    setParentsCustomerData([]);
    const sampleData = await get(
      `${FLEET_APIS.SITE_SETS_SITES}?siteSetId=` + siteId
    );
    const temp = sampleData.map((x, i) => ({ ...x, IndexId: i }));
    setParentsCustomerData(temp);
  }
  useEffect(function () {
    refreshParentsData();
  }, []);

  function refreshCustomers() {
    fetchCustomerData(selectedParent.SiteSetId);
  }

  function ParentRowSelectedHandler(row) {
    setSelectedParent(row);
    fetchCustomerData(row.SiteSetId);
  }

  function handleNewParentClick() {
    setSelectedParent({});
    setParentsCustomerData([]);
  }

  const filteredData = useMemo(() => {
    return parentsData
      .filter((x) => {
        return (
          !filter.startsWith ||
          x.SiteSet?.toUpperCase().startsWith(filter.startsWith.toUpperCase())
        );
      })
      .filter((x) => {
        return !filter.data || filter.data.includes(x);
      })
      .filter((x) => {
        return !filter.ProductId || filter.ProductId === x.ProductId;
      });
  }, [parentsData, filter]);

  async function AddCustomerHandler(row) {
    await get(
      `${FLEET_APIS.ADD_SITE_SET_SITE}?CustomerId=${row.CustomerId}&siteSetId=${selectedParent.SiteSetId}`
    );
    setOpenSelectCustomer(false);
    // setParentsCustomerData(temp);
    await fetchCustomerData(selectedParent.SiteSetId);
  }
  async function AddSiteHandler(row) {
    await get(
      `${FLEET_APIS.ADD_SITE_SET_SITE}?siteid=${row.SiteId}&siteSetId=${selectedParent.SiteSetId}`
    );
    setOpenSelectCustomer(false);
    await fetchCustomerData(selectedParent.SiteSetId);
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xs={6} spacing={2} alignItems={"center"}>
          <Grid item>
            <TextBox
              name="startsWith"
              label="Starts with"
              onChange={handleFilterChange}
            />
          </Grid>

          <Grid item minWidth={"160px"}>
            <DropDown
              name="ProductId"
              label="Product"
              selectOptions={(masterData || []).map((x) => ({
                value: x.ProductId,
                text: x.Product,
              }))}
              required={true}
              defaultValue={0}
              onChange={handleDropdown}
            />
          </Grid>
          <Grid item mt={3}>
            <SwitchInput
              name="showArchived"
              label={"Show Archived"}
              onChange={showArchived}
            />
          </Grid>
        </Grid>

        <Grid item container xs={6} spacing={2} justifyContent="flex-end">
          <Grid item>
            {CanDoDataMaintenance && <Button onClick={handleNewParentClick}>New</Button>}
          </Grid>

          {CanDoDataMaintenance && <Grid item>
            <Button
              onClick={() => setOpenSelectCustomer(true)}
              disabled={parentsData.isAutoupdate || !selectedParent.SiteSetId}
            >
              Add Site/ Customer
            </Button>
          </Grid>}
        </Grid>

        <Grid item xs={4}>
          <SiteSetsTable
            data={filteredData}
            rowSelected={ParentRowSelectedHandler}
            selectedRow={selectedParent?.IndexId}
          />
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ p: 1, marginTop: 2.5 }}>
            <ViewSiteSets
              parentsData={selectedParent}
              key={selectedParent.SiteSetId}
              refreshParentsData={refreshParentsData}
              handleNewParentClick={handleNewParentClick}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <SitesCustomersTable
            data={parentsCustomerData}
            selectedParent={selectedParent}
            refreshCustomers={refreshCustomers}
          />
        </Grid>
      </Grid>
      <AppDialog
        open={openSelectCustomer}
        title="Select Customer/Site"
        maxWidth="lg"
        handleClose={() => setOpenSelectCustomer(false)}
      >
        <SearchDetails
          activeOnly="customer,site"
          cancel={() => setOpenSelectCustomer(false)}
          siteActions={[
            {
              action: AddSiteHandler,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          custActions={[
            {
              action: AddCustomerHandler,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
        />
      </AppDialog>
    </>
  );
}
