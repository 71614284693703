import { Check } from "@mui/icons-material";
import { useEffect, useReducer } from "react";
import Form, { FormActions } from "../../components/Form";
import FormFooter from "../../components/FormFooter";
import useFetch from "../../hooks/useFetch";
import { DETAILS_APIS } from "../../common/apis";
import moment from "moment";
import { useRoot } from "../../rootContext";

export default function AddEventForm({
  setAddEditEvent,
  fetchData,
  formData,
  eventTypes,
  conditions,
  eventsRowData,
  eventFilter,
  sampleNumber,
}) {
  const { CanDoDataMaintenance } = useRoot()
  const defaultEventsData = {
    EventTypeId: 3,
    EventDate: new moment(),
    SampleNumber: sampleNumber,
  };

  const [data, dispatch] = useReducer(
    EventReducer,
    eventsRowData ? eventsRowData : defaultEventsData
  );
  const { post } = useFetch();

  const actions = {
    save: async function (e) {
      await post(DETAILS_APIS.ADD_EVENT_LOG, {
        EventLogId: data?.EventLogId,
        EventTypeId: data?.EventTypeId,
        MeterReading: data?.MeterReading,
        EventDate: data?.EventDate,
        SiteId: conditions.siteId ? conditions.siteId : null,
        UnitId: conditions.unitId ? conditions.unitId : null,
        ComponentId: conditions.componentId ? conditions.componentId : null,
        Note: data?.Event,
        SampleNumber: Number(data?.SampleNumber) || 0,
        Name: "User F1061",
      });
      setAddEditEvent && setAddEditEvent(false);
      fetchData && fetchData(data?.SampleNumber);
    },
  };

  useEffect(
    function () {
      (async function () {
        if (formData?.SampleNumber) {
          dispatch({
            type: EventActions.LOAD_DATA,
            payload: {
              ...eventsRowData,
            },
          });
        }
      })();
    },
    [formData]
  );

  return (
    <>
      <Form
        sections={FormSections(data, eventTypes)}
        data={data}
        spacing={2}
        dispatch={dispatch}
        key={data?.EventLogId}
      />
      <FormFooter
        buttons={GetFormButtons(data, actions, CanDoDataMaintenance)}
        footerText={`ProductId: ${eventFilter?.productId || eventFilter?.ProductId || ""}, CustomerId: ${eventFilter?.customerId || eventFilter?.CustomerId || ""
          }, SiteId: ${eventFilter?.siteId || eventFilter?.SiteId || ""}, UnidId: ${eventFilter?.unitId || eventFilter?.UnitId || ""
          } , ComponentId: ${eventFilter?.componentId || eventFilter?.ComponentId || ""} , EventLogId: ${eventFilter?.eventLogId || eventFilter?.EventLogId || ""
          } `}
      />
    </>
  );
}

const GetFormButtons = (data, { save }, CanDoDataMaintenance) => [
  {
    hidden: !CanDoDataMaintenance,
    variant: "contained",
    label: "Save",
    onClick: save,
    disabled: !(
      data?.MeterReading &&
      data?.Event &&
      data?.EventDate &&
      data?.EventTypeId
    ),
    startIcon: <Check />,
  },
];

function FormSections(data, eventTypes) {
  return [
    {
      fields: [
        {
          name: "EventTypeId",
          label: "Event Type",
          required: true,
          type: "dropdown",
          xsCol: 3,
          selectOptions: (eventTypes || []).map((x) => ({
            value: x.EventTypeId,
            text: x.EventType,
          })),
        },
        {
          name: "SampleNumber",
          label: "Sample Number",
          xsCol: 2,
        },
        { name: "EventDate", label: "Occurred", xsCol: 3, type: "datetime" },
        {
          name: "MeterReading",
          label: "Meter Reading",
          xsCol: 2,
          type: "number",
        },
        { name: "UnitHours", label: "Unit Hours", xsCol: 2, disabled: "true" },
        {
          name: "Event",
          label: "Description",
          type: "textarea",
          xsCol: 8,
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
};
function EventReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return action.payload;
  }

  return state;
}
