import { Card, Grid, Typography } from "@mui/material";
import {
  AccessibilityNew,
  CheckCircle,
  ForumOutlined,
  FullscreenExitOutlined,
  GroupAdd,
  Inventory2Outlined,
  LanguageOutlined,
  ViewModuleOutlined,
} from "@mui/icons-material";
import { useEffect, useMemo, useReducer, useState } from "react";
import { EventReducer, FormSections, ContactAccessPermissionDefaults, } from "./ContactAccessPermissionForm";
import ContactAccessPermissionTable from "./ContactAccessPermissionTable";
import ContactAccessSubscriptionsTable from "./ContactAccessSubscriptionsTable";
import ContactReportsPermissionTable from "./ContactReportsPermissionTable";
import useFetch from "./../../../../hooks/useFetch";
import Form from "./../../../../components/Form";
import { CONTACTS_APIS } from "./../../../../common/apis";
import FormFooter from "../../../../components/FormFooter";
import ProductSearch from "../../../management/ProductSearch";
import AppDialog from "../../../../components/AppDialog";
import ParentSearch from "../../../management/ParentSearch";
import SiteSetSearch from "../../../management/SiteSetSearch";
import ContactSampleReportingMapping from "./ContactSampleReportingMapping";
import ContactSubscriptionMapping from "./ContactSubscriptionMapping";
import AlertDialog from "../../../../components/AlertDialog";
import SearchCustomer from "../../../quick-details/manage/search/SearchCustomer";
import SearchSite from "../../../quick-details/manage/search/SearchSite";
import SearchUnit from "../../../quick-details/manage/search/SearchUnit";
import SearchComponent from "../../../quick-details/manage/search/SearchComponent";

export default function ContactAccessPermission({
  contactId,
  propId,
  username,
  searchContactRowData,
}) {
  const [accessData, setAccessData] = useState([]);
  const [linkagesData, setLinkagesData] = useState([]);
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  const [openSelectSite, setOpenSelectSite] = useState(false);
  const [openSelectUnit, setOpenSelectUnit] = useState(false);
  const [openSelectComponent, setOpenSelectComponent] = useState(false);
  const [propValue, setPropValue] = useState();
  const [reportRowSelected, setReportRowSelected] = useState();
  const { get, post } = useFetch();
  const [dialog, setDialog] = useState({
    title: "",
    open: false,
    message: "",
    width: "",
    actionName: "",
  });
  const [data, dispatch] = useReducer(
    EventReducer,
    ContactAccessPermissionDefaults
  );
  const actions = {
    addProduct: async function () {
      setDialog({
        title: "Add Product Disclaimer",
        open: true,
        message:
          "This will give access to the all samples within this PRODUCT (e.g KOWA, Labcheck) to the contact. For non-Komatsu staff, this is likely a breach of Australian privacy legislation.\n This is NOT recommended for non-Komatsu users. If unsure, please discuss with your Manager first.",
        width: "md",
        actionName: "addProductAlert",
      });
    },
    addProductAlert: async function () {
      setDialog({ open: true, title: "Product Search", width: "xl" });
    },
    addParent: async function () {
      setDialog({
        title: "Add Parent Disclaimer",
        open: true,
        message:
          "This will give access to the all samples within this PARENT (e.g Rio Tinto) to the contact. For non-Komatsu staff, this is likely a breach of Australian privacy legislation. \n This is NOT recommended for non-Komatsu users. If unsure, please discuss with your Manager first.",
        width: "md",
        actionName: "addParentAlert",
      });
    },
    addParentAlert: async function () {
      setDialog({ open: true, title: "Parent Search", width: "xl" });
    },
    addCustomer: async function () {
      setPropValue(3);
      setOpenSelectCustomer(true);
    },
    addSite: async function () {
      setPropValue(4);
      setOpenSelectSite(true);
    },
    addUnit: async function () {
      setPropValue(5);
      setOpenSelectUnit(true);
    },
    addComponent: async function () {
      setPropValue(6);
      setOpenSelectComponent(true);
    },

    addSiteSet: async function () {
      setDialog({
        title: "Add SiteSet Disclaimer",
        open: true,
        message:
          "This will give access to the all samples within this SITE SET (e.g Komatsu New Zealand) to the contact. For non-Komatsu staff, this is likely a breach of Australian privacy legislation.\n This is NOT recommended for non-Komatsu users. If unsure, please discuss with your Manager first.",
        width: "md",
        actionName: "addSiteSetAlert",
      });
    },
    addSiteSetAlert: async function () {
      setDialog({ open: true, title: "SiteSet Search", width: "xl" });
    },
  };
  const filteredAccessData = useMemo(
    () =>
      data
        ? (accessData || []).filter((x) => {
          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.Product?.toLowerCase().includes(filterLower) ||
              x.Customer?.toLowerCase().includes(filterLower) ||
              x.Scope?.toLowerCase().includes(filterLower);
          }
          return (
            (filterMatch && data.Product && x.Scope === "Product") ||
            (filterMatch && data.Customer && x.Scope === "Customer") ||
            (filterMatch && data.Parents && x.Scope === "Parent") ||
            (filterMatch && data.Sites && x.Scope === "Site") ||
            (filterMatch && data.Units && x.Scope === "Unit") ||
            (filterMatch && data.Components && x.Scope === "Component") ||
            (filterMatch && data.SiteSets && x.Scope === "Site Set")
          );
        })
        : accessData,
    [data, accessData]
  );

  const filteredData = useMemo(
    () =>
      data
        ? (linkagesData || []).filter((x) => {
          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.Product?.toLowerCase().includes(filterLower) ||
              x.Customer?.toLowerCase().includes(filterLower) ||
              x.LevelText?.toLowerCase().includes(filterLower) ||
              x.Site?.toLowerCase().includes(filterLower);
          }
          return (
            filterMatch &&
            ((data.Product && x.LevelText === "Product") ||
              (data.Customer && x.LevelText === "Customer") ||
              (data.Parents && x.LevelText === "Parent") ||
              (data.Sites && x.LevelText === "Site") ||
              (data.Units && x.LevelText === "Unit") ||
              (data.SiteSets && x.LevelText === "Site Set") ||
              (data.Components && x.LevelText === "Component"))
          );
        })
        : linkagesData,
    [data, linkagesData]
  );
  const filteredSubscriptionData = useMemo(
    () =>
      data
        ? (linkagesData || []).filter((x) => {
          let filterMatch = true;
          if (data.Filter) {
            const filterLower = data.Filter.toLowerCase();
            filterMatch =
              x.Product?.toLowerCase().includes(filterLower) ||
              x.Customer?.toLowerCase().includes(filterLower) ||
              x.Scope?.toLowerCase().includes(filterLower);
          }
          return (
            (filterMatch && data.Product && x.LevelText === "Product") ||
            (filterMatch && data.Customer && x.LevelText === "Customer") ||
            (filterMatch && data.Parents && x.LevelText === "Parent") ||
            (filterMatch && data.Sites && x.LevelText === "Site") ||
            (filterMatch && data.Units && x.LevelText === "Unit") ||
            (filterMatch && data.Components && x.LevelText === "Component")
          );
        })
        : linkagesData,
    [data, linkagesData]
  );
  useEffect(() => {
    getData();
  }, [contactId]);

  async function getData() {
    if (propId === 2) {
      const contactScopeResponse = await get(
        `${CONTACTS_APIS.CONTACT_SCOPES_ASSIGNED}?contactId=${contactId ? contactId : 0
        }`
      );
      setAccessData(contactScopeResponse);
    } else if (propId === 3) {
      const linkagesResponse = await get(
        `${CONTACTS_APIS.CONTACT_LINKAGES_ASSIGNED}?contactId=${contactId ? contactId : 0
        }`
      );
      setLinkagesData(linkagesResponse);
    } else if (propId === 4) {
      const contactSubscriptionsResponse = await get(
        `${CONTACTS_APIS.CONTACT_SUBSCRIPTIONS}?contactId=${contactId ? contactId : 0
        }`
      );
      setLinkagesData(contactSubscriptionsResponse);
    }
  }
  function editReportMappingClicked(row) {
    setReportRowSelected(row);
    setDialog({
      open: true,
      title: `Contact Sample Report Mapping for ${username}`,
      width: "xl",
    });
  }

  const alertFunctions = [
    actions.addProductAlert,
    actions.addParentAlert,
    actions.addSiteSetAlert,
  ];

  const selectedFunction = alertFunctions.filter((func) => {
    return func.name === dialog.actionName;
  });

  //handle customer row
  async function searchCustomerSelect(row) {
    await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, {
      ContactId: contactId,
      CustomerId: row.CustomerId,
      Screen: "Contact Access Permissions",
    });
    setOpenSelectCustomer(false);
    await getData();
  }
  async function searchSiteSelect(row) {
    await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, {
      ContactId: contactId,
      SiteId: row.SiteId,
      Screen: "Contact Access Permissions",
    });
    setOpenSelectSite(false);
    await getData();
  }
  async function searUnitSelect(row) {
    await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, {
      ContactId: contactId,
      SiteId: row.SiteId,
      UnitId: row.UnitId,
      Screen: "Contact Access Permissions",
    });
    setOpenSelectUnit(false);
    await getData();
  }
  async function searComponentsSelect(row) {
    await post(CONTACTS_APIS.ADD_SCOPE_FOR_CONTACT, {
      ContactId: contactId,
      ComponentId: row.ComponentId,
      // UnitId: row.UnitId,
      Screen: "Contact Access Permissions",
    });
    setOpenSelectComponent(false);
    await getData();
  }

  return (
    <>
      <Grid container spacing={2} sx={{ minWidth: "90vw", minHeight: "70vh" }}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form sections={FormSections()} data={data} dispatch={dispatch} />
            <FormFooter
              buttons={GetFormButtons(propId, actions)}
              hideDivider
              gmt={1}
            />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" sx={{ marginLeft: "5px" }}>
            {filteredAccessData.length > 0 && (
              <b>Total Records: {filteredAccessData.length}</b>
            )}

            {filteredData.length > 0 && (
              <b>Total Records: {filteredData.length}</b>
            )}

            {propId === 4 && filteredSubscriptionData.length === 0 && (
              <p style={{ color: "red" }}>
                No report subscriptions have been added - You can only add
                subscriptions for customers, etc that has Access Permissions to
              </p>
            )}
            {propId === 2 && filteredAccessData.length === 0 && (
              <p style={{ color: "red" }}>
                The contact has no access - add the required customers etc that
                they should be able to see
              </p>
            )}
            {propId === 3 && filteredData.length === 0 && (
              <p style={{ color: "red" }}>
                No sample reporting has been added - You can only add reports
                for customers, etc that {searchContactRowData?.DisplayName} has
                Access Permissions to
              </p>
            )}
          </Typography>

          {propId === 2 ? (
            <ContactAccessPermissionTable
              getData={getData}
              data={filteredAccessData}
            />
          ) : propId === 3 ? (
            <ContactReportsPermissionTable
              data={filteredData}
              editReportMappingClicked={editReportMappingClicked}
            />
          ) : propId === 4 ? (
            <ContactAccessSubscriptionsTable data={filteredSubscriptionData} />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>

      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title={dialog.title}
        maxWidth={dialog.width}
      >
        {dialog.title === "Product Search" && (
          <ProductSearch
            ContactId={contactId}
            setDialog={setDialog}
            getData={getData}
          />
        )}

        {dialog.title === "Parent Search" && (
          <ParentSearch
            ContactId={contactId}
            setDialog={setDialog}
            getData={getData}
          />
        )}

        {dialog.title === "SiteSet Search" && (
          <SiteSetSearch
            ContactId={contactId}
            setDialog={setDialog}
            getData={getData}
          />
        )}

        {dialog.title === `Contact Sample Report Mapping for ${username}` && (
          <ContactSampleReportingMapping
            selectedRowData={reportRowSelected}
            contactId={contactId}
            setDialog={setDialog}
            getData={getData}
          />
        )}

        {dialog.title === `Contact Subscription Mapping for ${username}` && (
          <ContactSubscriptionMapping />
        )}

        {dialog.title === "Add Product Disclaimer" && (
          <AlertDialog
            handleClose={() => {
              setDialog((prevState) => ({ ...prevState, open: false }));
            }}
            alertAction={selectedFunction[0]}
            message={dialog.message}
          />
        )}
        {dialog.title === "Add Parent Disclaimer" && (
          <AlertDialog
            handleClose={() => {
              setDialog((prevState) => ({ ...prevState, open: false }));
            }}
            alertAction={selectedFunction[0]}
            message={dialog.message}
          />
        )}
        {dialog.title === "Add SiteSet Disclaimer" && (
          <AlertDialog
            handleClose={() => {
              setDialog((prevState) => ({ ...prevState, open: false }));
            }}
            alertAction={selectedFunction[0]}
            message={dialog.message}
          />
        )}
      </AppDialog>
      {/* Search customer */}
      <AppDialog
        open={openSelectCustomer}
        maxWidth="lg"
        handleClose={() => setOpenSelectCustomer(false)}
        title="Customer Search"
      >
        <SearchCustomer
          actions={[
            {
              action: searchCustomerSelect,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          contactId={contactId}
        />
      </AppDialog>

      {/* Search site */}
      <AppDialog
        open={openSelectSite}
        maxWidth="lg"
        handleClose={() => setOpenSelectSite(false)}
        title="Site Search"
      >
        <SearchSite
          actions={[
            {
              action: searchSiteSelect,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          contactId={contactId}
        />
      </AppDialog>

      {/* Search Unit */}
      <AppDialog
        open={openSelectUnit}
        maxWidth="xl"
        handleClose={() => setOpenSelectUnit(false)}
        title="Unit Search"
      >
        <SearchUnit
          actions={[
            {
              action: searUnitSelect,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          contactId={contactId}
        />
      </AppDialog>

      {/* Search Component */}
      <AppDialog
        open={openSelectComponent}
        maxWidth="xl"
        handleClose={() => setOpenSelectComponent(false)}
        title="Component Search"
      >
        <SearchComponent
          actions={[
            {
              action: searComponentsSelect,
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          contactId={contactId}
        />
      </AppDialog>
    </>
  );
}
const GetFormButtons = (
  propId,
  {
    addProduct,
    addParent,
    addCustomer,
    addSite,
    addUnit,
    addComponent,
    addSiteSet,
    machAccess,
  }
) => [
    {
      variant: "outlined",
      label: "Add Product",
      onClick: addProduct,
      startIcon: <Inventory2Outlined />,
    },
    {
      variant: "outlined",
      label: "Add Parent",
      onClick: addParent,
      startIcon: <AccessibilityNew />,
    },
    {
      variant: "outlined",
      label: "Add Customer",
      onClick: addCustomer,
      startIcon: <GroupAdd />,
    },
    {
      variant: "outlined",
      label: "Add Site",
      onClick: addSite,
      startIcon: <LanguageOutlined />,
    },
    {
      variant: "outlined",
      label: "Add Unit",
      onClick: addUnit,
      startIcon: <ForumOutlined />,
    },
    {
      variant: "outlined",
      label: "Add Component",
      onClick: addComponent,
      startIcon: <FullscreenExitOutlined />,
    },
    {
      variant: "outlined",
      label: "Add Site Set",
      onClick: addSiteSet,
      startIcon: <ViewModuleOutlined />,
    },
    // {
    //   variant: "outlined",
    //   label: "Mach Access",
    //   onClick: machAccess,
    //   hidden: propId === 2 || propId === 4,
    //   startIcon: <JoinInnerOutlined />,
    // },
  ];
