import { Box } from "@mui/system";
import { DataGrid, GridToolbarContainer, GridToolbarExport, } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo } from "react";
import { Button, colors, useTheme } from "@mui/material";
import useTableStyles, { useCellColorResolver, useRowColorResolver, } from "../hooks/useTableStyles";
import SwitchInput from "./SwitchInput";
import { useState } from "react";

export default function DataTable({
  dataSource,
  sx,
  svgColor,
  minHeight,
  heightValue,
  rowStylingHandler,
  cellStylingHandler,
  opacityCheck,
  columns,
  clsCheck,
  ...props
}) {
  const theme = useTheme();
  const tableStyles = useTableStyles();
  const colorResolver = useRowColorResolver();
  const colorResolverCell = useCellColorResolver();

  let getRowId = null;
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);

  useEffect(
    function () {
      setSelectedRowIds(props?.selectedRowIds);
    },
    [props?.selectedRowIds]
  );

  if (props.rowIdProperty) {
    getRowId = (row) => row[props.rowIdProperty];
  }
  let disableColumnFilter = null;
  if (props.isDisableColumnFilter) {
    disableColumnFilter = props.isDisableColumnFilter;
  } else {
    disableColumnFilter = props.isDisableColumnFilter;
  }

  const handleOnSelectionModelChange = (ids) => {
    const selectedRows =
      dataSource &&
      dataSource.filter((x) => ids.includes(x[props.rowIdProperty]));

    props.rowsSelected && props.rowsSelected(selectedRows);
    setSelectedRowIds(ids);
    if (selectedRows?.length > 0) {
      setSelectedRow(selectedRows[0])
    }
    if ((selectedRows?.length < 1 || selectedRows === undefined) && clsCheck !== true) {
      if (dataSource?.length > 0) {
        setSelectedRow(dataSource[0]);
      }
      else { setSelectedRow({ AlertColour: null }); }
    }
  };

  const processRowUpdateHandler = useCallback(
    function (newRow, oldRow) {
      if (props.rowEditHandler)
        return props.rowEditHandler && props.rowEditHandler(newRow, oldRow);
      return new Promise((resolve, reject) => {
        resolve({ ...oldRow });
      });
    },
    [props.rowEditHandler]
  );

  function CustomToolbar() {
    return (
      props.showExport && (
        <GridToolbarContainer>
          <GridToolbarExport />
        </GridToolbarContainer>
      )
    );
  }

  const resolveColor = useMemo(
    function () {
      return colorResolver[rowStylingHandler] || null;
    },
    [rowStylingHandler]
  );

  const resolveCellColor = useMemo(
    function () {
      return colorResolverCell[cellStylingHandler] || null;
    },
    [cellStylingHandler]
  );

  function getRowClassName(params) {
    let colorClassName = "";

    if (params.row.AlertStatusColour) {
      colorClassName = `row-status--${params.row.AlertStatusColour || "none"} `;
      return `${colorClassName}${params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
        }`;
    }

    colorClassName = resolveColor
      ? `row-status--${resolveColor(params.row) || "none"} `
      : "";
    return `${colorClassName}${params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd"
      }`;
  }

  function getCellClassName(params) {
    let colorClassName = resolveCellColor
      ? `cell-status--${resolveCellColor(params) || "none"} `
      : "";
    // if (params?.row?.Step > 6 && params?.row?.Step !== undefined && opacityCheck === true) {
    //   if (params?.row?.HoldTypeId !== null) { colorClassName = `cell-status--${resolveCellColor("sampleBackGroundWhite") || "none"} ` }
    // }
    return colorClassName;
  }

  return (
    <Box
      sx={{
        minHeight: minHeight ? `${minHeight}vh` : "0px",
        height: heightValue ? `${heightValue}%` : "240px",
        mb: 1,
        ...(sx || {}),
      }}
    >
      <DataGrid
        getRowClassName={getRowClassName}
        getCellClassName={getCellClassName}
        onSelectionModelChange={handleOnSelectionModelChange}
        processRowUpdate={processRowUpdateHandler}
        rowHeight={24}
        headerHeight={25}
        // rowHeight={24}
        // headerHeight={24}
        hideFooterPagination
        hideFooterSelectedRowCount
        checkboxSelection={props?.checkBoxSelect}
        selectionModel={selectedRowIds}
        getRowId={getRowId}
        rows={dataSource || []}
        components={{
          Toolbar: CustomToolbar,
        }}
        disableColumnFilter={disableColumnFilter}
        disableColumnMenu={disableColumnFilter}
        hideFooter
        columns={columns}
        // paginationMode="server"
        {...props}
        sx={{
          // paddingBottom: "15px",
          borderColor: theme.palette.primary.main,
          backgroundColor: colors.common.white,
          [`.MuiSvgIcon-root`]: { fontSize: "16px" },
          "& .MuiDataGrid-row": {
            "&.Mui-odd": {
              backgroundColor: "#e2f2ff", //Light Blue
              "& .MuiSvgIcon-root": {
                color: "inherit",
              },
              //"&:hover": {
              //    backgroundColor: "#ccedf9",
              //},
            },
            "&.Mui-even": {
              "& .MuiSvgIcon-root": {
                color: "inherit",
              },
            },
            "& .MuiSvgIcon-root": {
              color: svgColor || colors.common.white,
            },
            "& .MuiDataGrid-editInputCell input": { padding: 0, color: "#010101" },
          },
          "& .MuiDataGrid-cell": {
            borderLeft: "1px solid #d0d0d0"
          },
          "& .MuiDataGrid-cell:focus": {
            color: "lime",
          },
          ...tableStyles,
          "& .MuiDataGrid-row.Mui-selected": {
            color: props?.selectedTextColor || colors.common.white,
            opacity: opacityCheck === true
              ? ((selectedRow?.Step > 6 && selectedRow?.Step !== undefined && selectedRow.AlertRank > 0) ? 0.6 : 1) : 1,
            backgroundColor: opacityCheck === true
              ? ((selectedRow?.Step > 6 && selectedRow?.Step !== undefined && selectedRow.AlertRank > 0) ? "" : "#7030a0")
              : (props?.selectedRowColor || "#7030a0"), //Dark Purple "#7030a0"
            // backgroundColor: "#7030a0", //Light Purple  "#8238ba"
            "&:hover": {
              backgroundColor: "#8238ba", //Light Purple  "#8238ba"
              color: "white"
            },
            "& .MuiDataGrid-booleanCell": {
              color: colors.common.white,
            },
            "& .MuiSvgIcon-root": {
              color: props?.checkboxColor || colors.common.white,
            },
            "& .MuiButton-root": {
              color: colors.common.white,
              backgroundColor: theme.palette.primary.main,
              "&:hover": {
                backgroundColor: theme.palette.primary.light,
              },
            },
          },
        }}
      />
    </Box>
  );
}

export function DataGridAction({
  label = "",
  startIcon,
  onClick,
  row,
  className,
  color,
  disabled,
  minWidth,
}) {
  return (
    <Button
      variant={className || "outlined"}
      color={color}
      onClick={(e) => onClick && onClick(row, e)}
      startIcon={startIcon}
      sx={{
        height: 18,
        padding: 0.125,
        minWidth: minWidth,
        backgroundColor: "white",
      }}
      disabled={disabled || false}
    >
      {label}
    </Button>
  );
}
export function DataGridCheckBox({ label = "", onToggle, row }) {
  return (
    <SwitchInput
      label={label}
      // value={(data || {})[field.name] || false}
      onChange={(e) => onToggle(row, e)}
    />
  );
}
