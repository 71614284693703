import { Grid, Typography } from "@mui/material";
import DataTable, { DataGridAction } from "../../../../components/DataTable";
import { useRoot } from "../../../../rootContext";

export default function SearchProduct({ actions, rowSelected, }) {
    const { products } = useRoot();

    const tblOptions = {
        columns: GetColumns(actions),
        dataSource: products,
        rowIdProperty: "ProductId",
        cellStylingHandler: "productCommonCellColour",
        isDisableColumnFilter: true,
        sx: { height: "400px" },
        rowsSelected: function (rows) {
            rowSelected && rowSelected(rows[0]);
        },
    };

    return (
        <>
            <Grid container minWidth={800}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        Total Records: <b> {products && products.length}</b>
                    </Typography>
                    <DataTable {...tblOptions} />
                </Grid>
            </Grid>
        </>
    );
};

function GetColumns(actions) {
    return [
        {
            headerName: "Product",
            field: "DisplayProduct",
            minWidth: 130,
            sortable: false,
        },
        {
            headerName: "AutoEval",
            field: "CanAutoEvaluate",
            valueFormatter: ({ value }) => (value ? "Yes" : "No"),
            minWidth: 200,
            sortable: false,
        },
        {
            headerName: "Evaluate",
            field: "NoEvaluations",
            valueFormatter: ({ value }) => (value ? "Yes" : "No"),
            minWidth: 100,
            sortable: false,
        },
        {
            headerName: "Archived",
            field: "IsArchived",
            valueFormatter: ({ value }) => (value ? "Yes" : "No"),
            minWidth: 130,
            sortable: false,
        },
        {
            headerName: "Hidden",
            field: "HideProduct",
            valueFormatter: ({ value }) => (value ? "Yes" : "No"),
            minWidth: 200,
            sortable: false,
        },
        {
            headerName: "Display",
            field: "DisplayRank",
            minWidth: 100,
            sortable: false,
        },
    ];
}
