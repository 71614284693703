import {
  Button,
  FormGroup,
  InputLabel,
  Grid,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import HoldTable from "./HoldTable";
import DateTime from "./../../../components/DateTime";
import { EditIcon, HoldIcon } from "../../../icons/ButtonIcons";
import { TRAY_APIS } from "../../../common/apis";
import AppDialog from "../../../components/AppDialog";
import AlertDialog from "../../../components/AlertDialog";
import { useRoot } from "../../../rootContext";
import useQuery from "../../../hooks/useQuery";

const todayDate = new Date();
const maxDateAllowed = new Date(
  todayDate.getFullYear(),
  todayDate.getMonth(),
  todayDate.getDate() + 5
);

export default function Hold({
  sampleId: sampleNumber,
  refreshGrid,
  setHoldOpen,
}) {
  const { get } = useFetch();
  const { CanDoDataMaintenance } = useRoot();
  const [holdTypes, setHoldTypes] = useState([]);
  const [commentValue, setCommentValue] = useState("");
  const [dataSource, setDataSource] = useState([]);
  // const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
  // const [trayDate, setTrayDate] = useState(currentDate);
  const [trayDate, setTrayDate] = useState();
  const [holdType, setHoldType] = useState({
    HoldType: "Waiting on Tests",
    HoldTypeId: 2,
  });
  const [dialog, setDialog] = useState({ open: false, message: "" });
  const { urlsampleNumber } = useQuery();

  const handleChangeDropdown = (event) => {
    setHoldType(
      holdTypes.find((x) => event.target.value === x.HoldTypeId) || {}
    );
  };

  const handleChangeDate = (trayDateParam) => {
    setTrayDate(trayDateParam);
  };

  const hasData = !!dataSource.length;

  useEffect(function () {
    (async function () {
      if (sampleNumber) {
        const getSampleData = await get(
          `/trays/GetTraySamplesOnHold?sampleNumber=${sampleNumber}&showArchived=true`
        );

        if (getSampleData.TraySamplesOnHold.length > 0) {
          setDataSource(getSampleData.TraySamplesOnHold);
          setCommentValue(getSampleData.TraySamplesOnHold[0].HoldNotes);
          setTrayDate(getSampleData.TraySamplesOnHold[0].HoldUntil);
          setHoldType({
            HoldType: getSampleData.TraySamplesOnHold[0].HoldType,
            HoldTypeId: getSampleData.TraySamplesOnHold[0].HoldTypeId,
          });
        }
        if (getSampleData.HoldTypes.length > 0) {
          setHoldTypes(
            getSampleData.HoldTypes.map((x) => ({
              HoldTypeId: x.HoldTypeId,
              HoldType: x.HoldType,
            }))
          );
        }
      }
      if (urlsampleNumber) {
        const getSampleData = await get(
          `/trays/GetTraySamplesOnHold?sampleNumber=${urlsampleNumber}&showArchived=true`
        );

        if (getSampleData.TraySamplesOnHold.length > 0) {
          setDataSource(getSampleData.TraySamplesOnHold);
          setCommentValue(getSampleData.TraySamplesOnHold[0].HoldNotes);
          setTrayDate(getSampleData.TraySamplesOnHold[0].HoldUntil);
          setHoldType({
            HoldType: getSampleData.TraySamplesOnHold[0].HoldType,
            HoldTypeId: getSampleData.TraySamplesOnHold[0].HoldTypeId,
          });
        }
        if (getSampleData.HoldTypes.length > 0) {
          setHoldTypes(
            getSampleData.HoldTypes.map((x) => ({
              HoldTypeId: x.HoldTypeId,
              HoldType: x.HoldType,
            }))
          );
        }
      }
    })();
  }, []);
  //hold sample click
  async function holdSampleClick() {
    if (sampleNumber) {
      await get(`${TRAY_APIS.SAMPLES_HOLD}?SampleNumber=${sampleNumber}&holdTypeId=${holdType.HoldTypeId}&holdUntil=${trayDate}&holdNotes=${commentValue}&Screen=Put on Hold from Laboratory`);
      setHoldOpen(false);
      refreshGrid();
    }

    if (urlsampleNumber) {
      await get(`${TRAY_APIS.SAMPLES_HOLD}?SampleNumber=${urlsampleNumber}&holdTypeId=${holdType.HoldTypeId}&holdUntil=${trayDate}&holdNotes=${commentValue}&Screen=Put on Hold from Laboratory`);
    }
  }

  //hold tray click
  async function holdTrayClick() {
    if (!sampleNumber === 0) return;
    setDialog({
      open: true,
      message: "Are you sure you want to ON HOLD all the samples?",
    });
  }
  async function holdTrayClickAlert() {
    await get(
      `${TRAY_APIS.SAMPLES_TRAY_HOLD}?SampleNumber=${sampleNumber || urlsampleNumber}&holdTypeId=${holdType.HoldTypeId}&holdUntil=${trayDate}&holdNotes=${commentValue}&Screen=Put on Hold from Laboratory`
    );
    setHoldOpen && setHoldOpen(false);
    refreshGrid && refreshGrid();
    setDialog && setDialog((prevState) => ({ ...prevState, open: false }));
  }
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F11" && dataSource.length > 0) {
        event.preventDefault();
        holdTrayClick();
      } else if (event.key === "F12" && dataSource.length > 0) {
        event.preventDefault();
        holdSampleClick();
      }
    },
    [dataSource]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Grid container spacing={2} sx={{ pt: "20px", width: 1200 }}>
        <Grid item xs={12} style={{ paddingBottom: "30px" }}>
          {!hasData ? (
            <Typography variant="subtitle2" sx={{ color: "red" }}>
              The Tray for samples {sampleNumber} has no samples on hold
            </Typography>
          ) : (
            <Typography variant="subtitle2" style={{}}>
              Tray {dataSource[0].Tray} has{" "}
              {dataSource.filter((item) => item.HoldTypeId !== null).length} of{" "}
              {dataSource[0].TraySampleCount} samples on hold including sample{" "}
              {sampleNumber}:{dataSource[0].HoldTypeName}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={2}>
          <InputLabel>Hold Type</InputLabel>
          <FormGroup row={true}>
            <Select
              value={holdType.HoldTypeId}
              onChange={handleChangeDropdown}
              displayEmpty
              inputProps={{ "aria-label": "Owner" }}
              sx={{ width: 180 }}
            >
              {holdTypes.map(
                (op) =>
                  op.HoldTypeId !== 1 && (
                    <MenuItem value={op.HoldTypeId} key={op.HoldType}>
                      {op.HoldType}
                    </MenuItem>
                  )
              )}
            </Select>
          </FormGroup>
        </Grid>
        <Grid item xs={3}>
          <DateTime
            label="Hold Until"
            value={trayDate}
            onChange={handleChangeDate}
            maxDate={maxDateAllowed}
            disablePast
          />
        </Grid>

        <Grid item xs={7}>
          <Grid container direction="row-reverse" spacing={2}>
            <Grid item style={{ marginTop: "23px" }}>
              {hasData && (
                <Button
                  item
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={holdSampleClick}
                >
                  Update
                </Button>
              )}
              {CanDoDataMaintenance && !hasData && (
                <Button
                  item
                  variant="contained"
                  startIcon={<HoldIcon />}
                  onClick={holdSampleClick}
                >
                  Hold Sample (F12)
                </Button>
              )}
            </Grid>
            {CanDoDataMaintenance && (
              <Grid item style={{ marginTop: "23px" }}>
                <Button
                  item
                  variant="contained"
                  onClick={holdTrayClick}
                  startIcon={<HoldIcon />}
                >
                  Hold Tray (F11)
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <InputLabel style={{ marginTop: "15px" }}>Reason</InputLabel>
          <TextBox
            multiline
            rows={3}
            value={commentValue}
            onChange={(v) => setCommentValue(v.target.value)}
          />
        </Grid>
      </Grid>
      <HoldTable data={dataSource} />
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Hold All Samples"
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={holdTrayClickAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}
