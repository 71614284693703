export const CUSTOMER_ACTIONS = {
    CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
};

export const SITE_ACTIONS = {
    SITE_DETAILS: "SITE_DETAILS",
};

export const UNIT_ACTIONS = {
    UNIT_DETAILS: "UNIT_DETAILS",
};

export const COMPONENT_ACTIONS = {
    COMPONENT_DETAILS: "COMPONENT_DETAILS",
};