import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchCustomer from "../search/SearchCustomer";
import useFetch from "../../../../hooks/useFetch";
import SearchProduct from "../search/SearchProduct";
import { FLEET_APIS } from "../../../../common/apis";
import { useState } from "react";
import { useUser } from "../../../../rootContext";

const steps = [
  "Select Action",
  "Select Destination Customer",
  "Action Summary",
];

export default function ManageCustomer({ unit, close, handleSearchTree }) {
  const { post, get } = useFetch();
  const user = useUser();
  const treeData = { ProductId: unit?.ProductId, }

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedUnit, setSelectedUnit] = React.useState({});
  const [skipped, setSkipped] = React.useState(new Set());
  const [selectedOption, setSelectedOption] = React.useState("deleteOriginal");
  const [processResponse, setProcessResponse] = React.useState("Process completed successfully.");
  const [keepContacts, setKeepContacts] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});

  const getComponentDetails = async () => {
    const data = await get(`/details/customer?custId=${unit?.CustomerId || 0}`, { skipBlocker: true });

    const respData = {
      ProductId: data?.Customer?.ProductId,
      Product: data?.Customer?.Product,
      Customer: data?.Customer?.Customer,
      CustomerId: data?.Customer?.CustomerId,
    }
    setCustomerDetails(respData)
  }

  React.useEffect(() => { getComponentDetails() }, [])

  async function PerformOperation() {
    if (selectedOption === "deleteOriginal") {
      const data = {
        FromCustomerId: unit.CustomerId,
        ToCustomerId: selectedUnit.CustomerId,
        ProductId: selectedUnit.ProductId,
        ActionToTake: 2,
        PreserveContacts: keepContacts,
        UserId: user?.UserId,
        Screen: "Customer Management",
      };
      const result = await post(FLEET_APIS.MANAGE_CUSTOMER, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData)
      }
    } else if (selectedOption === "keepOriginal") {
      const data = {
        FromCustomerId: unit.CustomerId,
        ToCustomerId: selectedUnit.CustomerId,
        ProductId: selectedUnit.ProductId,
        ActionToTake: 1,
        PreserveContacts: keepContacts,
        UserId: user?.UserId,
        Screen: "Customer Management",
      };
      const result = await post(FLEET_APIS.MANAGE_CUSTOMER, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData)
      }
    } else if (selectedOption === "moveCustomer") {
      const data = {
        FromCustomerId: unit.CustomerId,
        ToCustomerId: selectedUnit.CustomerId,
        ProductId: selectedUnit.ProductId,
        ActionToTake: 3,
        PreserveContacts: keepContacts,
        UserId: user?.UserId,
        Screen: "Customer Management",
      };
      const result = await post(FLEET_APIS.MANAGE_CUSTOMER, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData)
      }
    } else if (selectedOption === "deleteCustomer") {
      const data = {
        FromCustomerId: unit.CustomerId,
        ToCustomerId: selectedUnit.CustomerId,
        ProductId: selectedUnit.ProductId,
        ActionToTake: 4,
        PreserveContacts: keepContacts,
        UserId: user?.UserId,
        Screen: "Customer Management",
      };
      const result = await post(FLEET_APIS.MANAGE_CUSTOMER, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData)
      }
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 2) {
      PerformOperation();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    if (selectedOption === "deleteCustomer") {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
      setSelectedOption("deleteOriginal");
      setSelectedUnit({});
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedUnit({});
    }
  };

  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleReset = () => {
    setActiveStep(0);
    close();
    // refreshGrid();
  };

  // Table's Styling
  const tableCol2 = {
    background: "lightblue",
    color: "blue",
  };
  const tableCol3 = {
    background: "lightgreen",
    color: "green",
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="deleteOriginal"
              name="manageAction"
              //value={selectedOption}
              onChange={handleRadioGroupChange}
            >
              <FormControlLabel
                value="deleteOriginal"
                control={<Radio />}
                label="Merge the customer into a different customer, and DELETE the original customer"
              />
              <FormControlLabel
                value="keepOriginal"
                control={<Radio />}
                label="Merge the customer into a different customer, and KEEP the original customer"
              />
              <FormControlLabel
                value="moveCustomer"
                control={<Radio />}
                label="Move the customer to a different product"
              />
              <FormControlLabel
                value="deleteCustomer"
                control={<Radio />}
                label="Delete the customer and DELETE all of its samples"
              />
            </RadioGroup>
            <Typography mt={1}>Options:</Typography>
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { color: keepContacts === true ? "#228B22" : "#BF40BF" } }}
              checked={keepContacts}
              control={<Checkbox />}
              onChange={() => setKeepContacts(!keepContacts)}
              label="Keep all contacts that are on the Customer, Sites, Units or Components"
            />
          </FormControl>
        );
      case 1:
        return (
          selectedOption === "moveCustomer"
            ? <SearchProduct
              selectedUnit={unit}
              rowSelected={(row) => setSelectedUnit(row)}
            /> : selectedOption === "deleteCustomer" ? (
              handleNext()
            ) : <SearchCustomer
              selectedUnit={unit}
              rowSelected={(row) => setSelectedUnit(row)}
            />
        );
      case 2:
        return selectedOption === "deleteCustomer" ? (
          <>
            <Typography sx={{ mb: 1 }}>
              The Customer {customerDetails?.Customer} will be DELETED
            </Typography>
            <TableContainer sx={{ mb: 1 }} component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{customerDetails?.Product || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.Customer || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CustomerId
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.CustomerId || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      SampleCount
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.SampleCount || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Special Report Count
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.SpecialReportCount || 0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>) : (
          <>
            <TableContainer component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                    {/* Col 3 */}
                    <TableCell sx={tableCol3}>Destination</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{customerDetails?.Product || ""}</TableCell>
                    <TableCell sx={tableCol3}>  {selectedUnit?.Product}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.Customer || ""}</TableCell>
                    <TableCell sx={tableCol3}>{selectedUnit?.Customer}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CustomerId
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.CustomerId || ""}  </TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.CustomerId || ""} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sample Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {unit?.SampleCount || 0}  </TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.SampleCount || 0}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Special Reports Count
                    </TableCell>
                    <TableCell sx={tableCol2}>   {unit?.SpecialReportsCount || 0}</TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.SpecialReportsCount || 0} </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <Box
      sx={{
        padding: "10px",
        minWidth: "70vw",
        minHeight: "23vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stepper activeStep={activeStep} sx={{ mr: 2 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: "-20%" }}>{processResponse}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button onClick={handleReset} sx={{ ml: "auto", mr: 1 }}>
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={ClassNames.instructions} sx={{ mt: 4 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              onClick={handleNext}
              disabled={activeStep === 1 && (!selectedUnit?.ProductId)}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
