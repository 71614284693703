import { Typography } from "@mui/material";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";

export default function ComponentTable({
  data,
  actions,
  rowSelected,
  rowActionHandler,
  propValue,
  selectedRow,
}) {
  const options = {
    columns: GetColumns(actions, propValue, { rowActionHandler }),
    dataSource: data,
    rowIdProperty: "ComponentId",
    isDisableColumnFilter: true,
    selectionModel: selectedRow,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options}></DataTable>;
}
function GetColumns(actions, propValue, { rowActionHandler }) {
  return [
    {
      headerName: "Product",
      field: "Product",
      width: 140,
      renderCell: function ({ row }) {
        return (
          <>
            {(actions || []).map(function (ac, i) {
              return (
                <DataGridAction
                  key={i}
                  row={row}
                  onClick={ac.action}
                  label={ac.label}
                  startIcon={ac.startIcon}
                />
              );
            })}
            <DropdownMenu
              options={Options}
              onItemSelect={(option) => rowActionHandler(row, option)}
            />
            <Typography paddingTop={0.25}>{row.Product}</Typography>
          </>
        );
      },
    },
    {
      headerName: "Customer",
      field: "Customer",
      width: 200,
    },

    {
      headerName: "Site",
      field: "Site",
      width: 140,
    },
    {
      headerName: "Unit",
      field: "Unit",
      width: 140,
    },
    {
      headerName: "Model",
      field: "Model",
      width: 140,
    },
    {
      headerName: "Serial",
      field: "Serial",
      width: 140,
    },
    {
      headerName: "Component",
      field: "Component",
      width: 140,
    },

    {
      headerName: "CompSerial",
      field: "CompSerial",
      width: 140,
    },
    {
      headerName: "Parked",
      field: "Parked",
      width: 110,
    },
    {
      headerName: "Scrapped",
      field: "Scrapped",
      width: 110,
    },
    {
      headerName: "Archived",
      field: "Archived",
      width: 110,
    },
  ];
}

const Options = [
  { label: "MHR Report", name: "view-mhr-report" },
  {
    label: "Additional Reports",
    name: "additional-reports",
    items: [
      { label: "Reports by Date", name: "reports-by-date" },
      { label: "Full History as XLSX", name: "report-contacts" },
      { label: "Full History as XML", name: "reports-by-date" },
      {
        label: "Components No Recent Samples",
        name: "components-no-recent-samples",
      },
      { label: "Units No Recent Samples", name: "units-no-recent-samples" },
      { label: "Reference XSD", name: "reports-by-date" },
    ],
  },
  { label: "Unit Hour History", name: "unit-hour-history" },
  { label: "Recent Unit Results", name: "recent-unit-results" },
  { label: "Recent Component Results", name: "recent-component-results" },
  {
    label: "Events",
    name: "event",
    items: [
      { label: "View Events", name: "edit-event" },
      { label: "New Event", name: "add-new-event" },
    ],
  },
  // { label: "Edit Unit", name: "edit-unit" },
  {
    label: "New",
    name: "new",
    items: [{ label: "Special Unit Report", name: "special-unit-report" }],
  },
  {
    label: "Manage ",
    name: "manage",
    items: [
      { label: "Component", name: "manage-component" },
      { label: "Unit", name: "manage-unit" },
      { label: "Site", name: "manage-site" },
      { label: "Customer", name: "manage-customer" },
    ],
  },
  {
    label: "Contacts",
    name: "contacts",
    items: [
      { label: "Access Permission", name: "access-permission-overview" },
      { label: "Sample Reporting", name: "report-contacts" },
      { label: "Primary Contact", name: "primary-contacts-overview" },
      { label: "Subscriptions", name: "subscription-overview" },
      { label: "Exports", name: "export-contacts-overview" },
    ],
  },
  {
    label: "Search",
    name: "search",
    items: [
      { label: "Customer", name: "search-customer" },
      { label: "Site", name: "search-site" },
      { label: "Unit", name: "search-unit" },
      { label: "Sample", name: "search-sample" },
    ],
  },
];
