import DataTable, { DataGridAction } from "../../../components/DataTable";
import { toLocalDate } from "../../../utils";
import renderCellExpand from "./../../../components/DatatableCellExpand";

export default function RegistrationsTable({ data, actions, screen }) {
  const options = {
    columns: GetColumns(actions),
    dataSource: data || [],
    rowIdProperty: "SIF",
    cellStylingHandler: "siteSetProduct",
    isDisableColumnFilter: true,
    sx: {
      minHeight: "550px",
      minWidth: screen === "useScreenPopups" ? "90vw" : "80vw",
    },
  };
  return <DataTable {...options} />;
}
function GetColumns(actions) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      width: 85,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              minWidth={"70px"}
              key={i}
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
            />
          );
        });
      },
    },
    {
      headerName: "Registration",
      field: "SIF",
      sortable: false,
      width: 90,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Product",
      field: "Product",
      sortable: false,
      width: 80,
    },
    {
      headerName: "Customer",
      field: "Customer",
      sortable: false,
      flex: 1,
      minWidth: 74,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      sortable: false,
      width: 110,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Unit",
      field: "Unit",
      sortable: false,
      width: 110,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Make",
      field: "UnitMake",
      sortable: false,
      width: 100,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Model",
      field: "UnitModel",
      sortable: false,
      width: 110,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Serial",
      field: "Serial",
      sortable: false,
      width: 150,
    },
    {
      headerName: "Component",
      field: "Component",
      sortable: false,
      minWidth: 155,
      maxWidth: 155,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      sortable: false,
      minWidth: 123,
    },
    {
      headerName: "Sampled",
      field: "Sampled",
      render: (val) => toLocalDate(val),
      sortable: false,
      width: 80,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Added",
      field: "Added",
      render: (val) => toLocalDate(val),
      sortable: false,
      width: 80,
    },
    {
      headerName: "Received",
      field: "Received",
      render: (val) => toLocalDate(val),
      sortable: false,
      width: 80,
    },
    {
      headerName: "Approved",
      field: "Approved",
      sortable: false,
      width: 80,
    },
    {
      headerName: "MR",
      field: "UnitMeter",
      sortable: false,
      width: 80,
    },
    {
      headerName: "CR",
      field: "ComponentMeter",
      sortable: false,
      width: 80,
    },
    {
      headerName: "FH",
      field: "FluidMeter",
      sortable: false,
      width: 80,
    },
  ];
}

/*
{
    "RegistrationId": 236854,
    "SIF": "00236854",
    "Added": "2022-10-24T07:59:55.947",
    "SampleNumber": null,
    "Received": null,
    "Approved": null,
    "AlertColour": null,
    "ProductId": 2,
    "ProductColour": "Navy",
    "Product": "KOWA",
    "StartLetter": 25,
    "CustomerId": 18985,
    "Customer": "19010",
    "SiteId": 43840,
    "Site": "Site43841",
    "UnitId": 174841,
    "Unit": "Unit174841",
    "Serial": "AMA00825",
    "Parked": "No",
    "IsParked": false,
    "Scrapped": "No",
    "IsScrapped": false,
    "Deleted": "No",
    "IsDeleted": false,
    "UnitMakeId": 16649,
    "UnitMake": "CATERPILLAR",
    "UnitModel": "D11T",
    "ComponentId": 634676,
    "Component": "Coolant",
    "ComponentMakeId": null,
    "ComponentMake": null,
    "ComponentModel": null,
    "Sampled": "2022-10-23T00:00:00",
    "UnitMeter": 13945,
    "ComponentMeter": 13945,
    "FluidMeter": 2350,
    "Archived": "No",
    "IsNewCustomer": false,
    "New": null
  }
*/
