import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import Grid from "@mui/material/Grid";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import TextBox from "../../../components/TextBox";
import { DASHBOARD_APIS } from "../../../common/apis";
import SwitchInput from "../../../components/SwitchInput";
import GetStepNames from "../../../common/GetStepNames";
import GetLaboratoriesNames from "../../../common/GetLaboratoriesNames";
import Constants from "../../../common/Constants";
import { useUser } from "../../../rootContext";
import { Fragment } from "react";
import { UpdateIcon } from "../../../icons/ButtonIcons";
import TraySummary from "./TraySummary";

const TrayDashboard2 = () => {
  const [refreshOption] = useState([0, 2, 5, 10, 15, 20, 30, 45, 60]);
  const [labs, setLabs] = useState([
    { Name: "Brisbane", Id: 1, checked: true },
    { Name: "Perth", Id: 2, checked: true },
    { Name: "Newcastle", Id: 3, checked: true },
  ]);
  let staticData = [
    "Laboratory",
    "Labels",
    "Samples",
    "Minimum Step",
    "Maximum Step",
    "Reruns",
    "Hold",
    "Missing Results",
    "Critical Logs",
    "Auto Evaluated",
  ];
  const [dataSource, setDataSource] = useState([]);
  const [trayDate, setTrayDate] = useState(new moment());
  const [updatedDate, setUpdatedDate] = useState();
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedCardLab1, setSelectedCardLab1] = useState(null);
  const [selectedCardLab2, setSelectedCardLab2] = useState(null);
  const [selectedCardLab3, setSelectedCardLab3] = useState(null);
  const [autoUpdateMinutesDropdown, setAutoUpdateMinutesDropdown] = useState(10);
  const [autoUpdateMinutes, setAutoUpdateMinutes] = useState(10);

  const { post } = useFetch();
  const user = useUser();
  // To handle Table's Width

  const handleChangeDate = async (trayDateParam) => {
    if (trayDateParam._isValid) {
      const formattedDate = trayDateParam.format("YYYYMMDD");
      const data = await post(DASHBOARD_APIS.TRAY, {
        LabIds: labs?.filter((x) => x.checked).map((x) => x.Id),
        TrayDate: formattedDate,
      });
      if (data.length > 0) {
        setSelectedCardLab1();
        setSelectedCardLab2();
        setSelectedCardLab3();
        setDataSource(data);
        setUpdatedDate(moment().format("hh:mm:ss ddd D MMM YYYY"));
      } else {
        setDataSource();
      }
      setTrayDate(trayDateParam);
      localStorage.setItem("trayDash", JSON.stringify({ trayDate: trayDateParam, CreationDate: moment.utc().format("YYYYMMDD") }))
    };
  }

  const handleChangeSwitch = async (event) => {
    const labId = event.target.name.split("_")[1];
    const lab = labs.find((x) => x.Id == labId);

    lab.checked = event.target.checked;
    if (trayDate._isValid) {
      const formattedDate = trayDate.format("YYYYMMDD");
      const data = await post(DASHBOARD_APIS.TRAY, {
        LabIds: [...labs.filter((x) => x.Id != labId), lab].sort((a, b) => a.Id - b.Id)?.filter((x) => x.checked).map((x) => x.Id),
        TrayDate: formattedDate,
      });
      if (data.length > 0) {
        setSelectedCardLab1();
        setSelectedCardLab2();
        setSelectedCardLab3();
        setDataSource(data);
        setUpdatedDate(moment().format("hh:mm:ss ddd D MMM YYYY"));
      } else {
        setDataSource();
      }
      setLabs(
        [...labs.filter((x) => x.Id != labId), lab].sort((a, b) => a.Id - b.Id)
      );
    };
  }

  async function getTrayDashboardData() {
    setSelectedCardLab1();
    setSelectedCardLab2();
    setSelectedCardLab3();
    if (trayDate._isValid) {
      const formattedDate = trayDate.format("YYYYMMDD");
      const data = await post(DASHBOARD_APIS.TRAY, {
        LabIds: labs?.filter((x) => x.checked).map((x) => x.Id),
        TrayDate: formattedDate,
      });
      if (data.length > 0) {
        setDataSource(data);
        setUpdatedDate(moment().format("hh:mm:ss ddd D MMM YYYY"));
      } else {
        setDataSource();
      }
    } else return;
  }

  useEffect(() => {
    (async () => {
      document.title = "Tray Dashboard";
      let trayDash = localStorage.getItem("trayDash");
      if (trayDash !== null) {
        let checkDate = moment.utc().format("YYYYMMDD")
        trayDash = JSON.parse(trayDash);
        if (checkDate === trayDash?.CreationDate) {

          if (trayDash?.trayDate) {
            let newDate = new Date(trayDash?.trayDate);
            const formattedDate = moment(newDate).format("YYYYMMDD");
            const data = await post(DASHBOARD_APIS.TRAY, {
              LabIds: labs?.filter((x) => x.checked).map((x) => x.Id),
              TrayDate: formattedDate,
            });
            if (data.length > 0) {
              setSelectedCardLab1();
              setSelectedCardLab2();
              setSelectedCardLab3();
              setDataSource(data);
              setUpdatedDate(moment().format("hh:mm:ss ddd D MMM YYYY"));
            } else {
              setDataSource();
            }
            setTrayDate(moment(newDate));
          }
        }
        if (checkDate !== trayDash?.CreationDate) {
          getTrayDashboardData();
        }
      }
      else {
        getTrayDashboardData();
      }
    })();
  }, []);

  const handleCardClick = (index) => (items) => {
    if (index === 0) {
      setSelectedCardLab1(items);
    } else if (index === 1) {
      setSelectedCardLab2(items);
    } else if (index === 2) {
      setSelectedCardLab3(items);
    } else {
    }
    setSelectedCard(items);
  };

  function handleChangeDropdown(e) {
    setAutoUpdateMinutesDropdown(e.target.value);
  }

  // Function to set up the interval
  const setupInterval = () => {
    const intervalFunction = () => {
      getTrayDashboardData();
    };
    const intervalTime = autoUpdateMinutes * 60 * 1000;
    return setInterval(intervalFunction, intervalTime);
  };

  // Set up the interval when autoUpdateMinutes changes or on component mount
  useEffect(() => {
    let intervalId;
    if (autoUpdateMinutes !== 0) {
      intervalId = setupInterval();
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoUpdateMinutes]);

  function updateClick() {
    setAutoUpdateMinutes(autoUpdateMinutesDropdown);
  }
  let monthVal = dataSource?.map(i => i.Trays[0]).map(p => p !== undefined ? p.MonthRegistered : 0).reduce((acc, cV) => { return acc + cV }, 0);
  let totalMonthVal = dataSource?.map(i => i.Trays[0]).map(p => p !== undefined ? p.PrevMonthRegistered : 0).reduce((acc, cV) => { return acc + cV }, 0);

  return (
    <>
      <Box>
        <Grid
          container
          spacing={2}
          sx={{ userSelect: "none", marginTop: -2.5 }}
        >
          <Grid item xs={12}>
            <Card
              sx={{
                p: 1,
                padding: 0.5,
                width: "100%",
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item mt={0.5}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Tray Date"
                          inputFormat="DD/MM/YYYY"
                          value={trayDate}
                          onChange={handleChangeDate}
                          renderInput={(params) => <TextBox {...params} />}
                          componentsProps={{
                            actionBar: {
                              actions: ["today"],
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item ml={1}>
                      <Typography sx={{ paddingBottom: 1 }}>
                        Select Labs
                      </Typography>
                      {labs?.map((lab) => (
                        <FormControlLabel
                          control={
                            <SwitchInput
                              checked={lab.checked}
                              onChange={handleChangeSwitch}
                              name={"lab_" + lab.Id}
                              size="small"
                              label={"CMS " + lab.Name}
                            />
                          }
                          key={lab.Id}
                        />
                      ))}
                    </Grid>
                    <Grid item mt={0.5}>
                      <InputLabel>Auto Update Every</InputLabel>
                      <FormGroup row={true}>
                        <Select
                          value={autoUpdateMinutesDropdown}
                          onChange={handleChangeDropdown}
                          displayEmpty
                          inputProps={{ "aria-label": "Auto Update Every" }}
                          sx={{ width: 100, mr: 2 }}
                        >
                          {refreshOption.map((op) => (
                            <MenuItem value={op} key={op}>
                              {op}
                            </MenuItem>
                          ))}
                        </Select>
                        <Typography mt={1} mr={2}>
                          Minutes
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={updateClick}
                          startIcon={<UpdateIcon />}
                        >
                          Update
                        </Button>
                      </FormGroup>
                    </Grid>

                    <Grid item>
                      <FormHelperText sx={{ mt: "25px" }}>
                        Updated: {updatedDate}
                      </FormHelperText>
                    </Grid>
                    <Grid item>
                      <FormHelperText sx={{ mt: "25px", color: "red" }}>
                        {trayDate.format("MMMM YYYY")}   Total {monthVal || 0} ({totalMonthVal || 0})
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Card>
          </Grid>

          {/* loop start */}

          {dataSource?.map((data, i) => {
            return (
              <Fragment key={i}>
                <Grid container width={"99vw"}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      overflowX: "auto",
                      flexWrap: "nowrap",
                      ml: 2,
                      mt: 0.5,
                      borderRadius: 1,
                      display: "inline-flex",
                      "& > div:nth-child(2)": {
                        marginLeft: "120px",
                      },
                    }}
                  >
                    <React.Fragment key={i}>
                      {data.Trays.length > 0 && (
                        <Grid item sx={{ position: "absolute" }}>
                          <Card
                            sx={{
                              borderRadius: 0,
                              borderLeft: "1.1px solid gray",
                            }}
                          >
                            <CardContent
                              style={{
                                padding: "4px",
                                backgroundColor:
                                  user?.DefaultLaboratoryId === data.LabId
                                    ? "#253fc8"
                                    : "",
                                color:
                                  user?.DefaultLaboratoryId === data.LabId
                                    ? "white"
                                    : "",
                                minWidth: "110px",
                              }}
                            >
                              {staticData.map((item, index) => (
                                <Typography
                                  sx={{
                                    borderBottom: "1px solid #C0C0C0",
                                    margin: "4px 0",
                                    minWidth: "25px",
                                    lineHeight: "1.33",
                                    fontWeight: 650,
                                  }}
                                  key={index}
                                >
                                  {index === 0
                                    ? GetLaboratoriesNames(data.LabId).name
                                    : item}
                                </Typography>
                              ))}
                            </CardContent>
                          </Card>
                        </Grid>
                      )}
                      {data.Trays.length > 0 &&
                        data.Trays?.map((items, j) => (
                          <React.Fragment key={j}>
                            <Grid item textAlign="center">
                              <Card
                                sx={{
                                  borderRadius: 0,
                                  borderLeft: "1.1px solid gray",
                                }}
                              >
                                <CardContent
                                  onClick={() => handleCardClick(i)(items)}
                                  style={{
                                    padding: "4px",
                                    backgroundColor:
                                      selectedCard === items ? "#87CEFA" : null,
                                  }}
                                >
                                  <Typography
                                    style={{
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                      fontWeight: 650,
                                    }}
                                  >
                                    {"Tray " + items.Tray}
                                  </Typography>

                                  <Typography
                                    style={{
                                      lineHeight: "1.3",
                                      margin: "4px 0",
                                      width: "117px", // Set the desired width of the Typography component
                                      whiteSpace: "nowrap", // Prevent text from wrapping to multiple lines
                                      overflow: "hidden", // Hide any overflowing text
                                      textOverflow: "ellipsis", // Show ellipsis (...) for truncated text
                                      backgroundColor: "black",
                                      color: "white",
                                    }}
                                    title={items.Label} // Show full text on hover using the title attribute
                                  >
                                    {items.Label}
                                  </Typography>

                                  <Typography
                                    style={{
                                      lineHeight: "1.3",
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                    }}
                                  >
                                    {items.SampleCount}
                                  </Typography>
                                  <Typography
                                    style={{
                                      lineHeight: "1.3",
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                      backgroundColor:
                                        items.MinimumStep ===
                                          Constants.completedStep
                                          ? "green"
                                          : items.MinimumStep ==
                                            Constants.approval
                                            ? "blue"
                                            : "",
                                      color:
                                        items.MinimumStep ===
                                          Constants.completedStep ||
                                          items.MinimumStep == Constants.approval
                                          ? "white"
                                          : "",
                                    }}
                                  >
                                    {GetStepNames(items.MinimumStep).mid}
                                  </Typography>
                                  <Typography
                                    style={{
                                      lineHeight: "1.3",
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                      backgroundColor:
                                        items.MaximumStep ===
                                          Constants.completedStep
                                          ? "green"
                                          : items.MaximumStep ==
                                            Constants.approval
                                            ? "blue"
                                            : "",
                                      color:
                                        items.MaximumStep ===
                                          Constants.completedStep ||
                                          items.MaximumStep == Constants.approval
                                          ? "white"
                                          : "",
                                    }}
                                  >
                                    {GetStepNames(items.MaximumStep).mid}
                                  </Typography>
                                  <Typography
                                    style={{
                                      lineHeight: "1.3",
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                    }}
                                  >
                                    {items.Reruns}
                                  </Typography>
                                  <Typography
                                    style={{
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                    }}
                                  >
                                    {items.HoldNotReruns}
                                  </Typography>
                                  <Typography
                                    style={{
                                      lineHeight: "1.3",
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                      backgroundColor:
                                        items.MaximumStep >= 3 &&
                                          items.MissingResults > 0
                                          ? "red"
                                          : items.MaximumStep >= 3 &&
                                            items.MissingResults === 0
                                            ? "green"
                                            : "",
                                      color:
                                        (items.MaximumStep >= 3 &&
                                          items.MissingResults > 0) ||
                                          (items.MaximumStep >= 3 &&
                                            items.MissingResults === 0)
                                          ? "white"
                                          : "",
                                    }}
                                  >
                                    {items.MissingResults}
                                  </Typography>
                                  <Typography
                                    style={{
                                      lineHeight: "1.3",
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                      backgroundColor:
                                        items.MaximumStep > 6 &&
                                          items.CriticalLogs > 0
                                          ? "#ec4cad"
                                          : "",
                                      color:
                                        items.MaximumStep > 6 &&
                                          items.CriticalLogs > 0
                                          ? "white"
                                          : "",
                                    }}
                                  >
                                    {items.CriticalLogs}
                                  </Typography>
                                  <Typography
                                    style={{
                                      lineHeight: "1.3",
                                      borderBottom: "1px solid #C0C0C0",
                                      margin: "4px 0",
                                    }}
                                  >
                                    {items.AutoEval}
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  </Grid>
                </Grid>

                <Grid container>
                  {data && data.Trays.length > 0 && (
                    <Grid
                      item
                      sx={{
                        marginLeft: "16px",
                        marginRight: "16px",
                        mt: "5px",
                        width: "100%",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "auto",
                      }}
                    >
                      {data.LabId === 1 ? (
                        <TraySummary
                          summary={selectedCardLab1 && selectedCardLab1}
                          data={data}
                          trayDate={trayDate}
                          labs={labs}
                        />
                      ) : data.LabId === 2 ? (
                        <TraySummary
                          summary={selectedCardLab2 && selectedCardLab2}
                          data={data}
                          trayDate={trayDate}
                          labs={labs}
                        />
                      ) : data.LabId === 3 ? (
                        <TraySummary
                          summary={selectedCardLab3 && selectedCardLab3}
                          data={data}
                          trayDate={trayDate}
                          labs={labs}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            );
          })}
          {/* loop end */}
        </Grid>
      </Box>
    </>
  );
};

export default TrayDashboard2;
