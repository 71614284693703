import { Checkbox } from "@mui/material";
import { useCallback } from "react";
import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function TestItemsTable({ data, setDirtyTestItems }) {
  const rowEditHandler = useCallback(function (newRow, oldRow) {
    return new Promise(async function (resolve, reject) {
      setDirtyTestItems((testItems) => {
        return { ...testItems, [newRow.TestItemId]: newRow };
      });
      resolve({ ...newRow });
    });
  }, []);
  const options = {
    columns: GetColumns(),
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.TestItemId + "" + (x.TestSetId || ""),
    })),
    rowIdProperty: "keyProp",

    isDisableColumnFilter: true,
    sx: { height: "750px" },
    // rowEditHandler: rowEditHandler,
    // experimentalFeatures: { newEditingApi: true },
  };
  return <DataTable {...options} />;
}
function GetColumns() {
  return [
    {
      headerName: "Test Item",
      field: "TestItem",
      sortable: false,
      minWidth: 180,
      renderCell: renderCellExpand,
    },
    {
      headerName: "AutoEval",
      field: "IsAutoEvaluation",
      sortable: false,
      type: "boolean",
      editable: true,
      //renderCell: renderCheckBox,
      flex: 1,
    },
    {
      headerName: "Min",
      field: "AutoEvaluationLower",
      type: "number",
      editable: true,
      sortable: false,
      flex: 1,
    },
    {
      headerName: "Max",
      field: "AutoEvaluationUpper",
      type: "number",
      editable: true,
      sortable: false,
      flex: 1,
    },
    {
      headerName: "Selected",
      field: "Selected",
      type: "boolean",
      editable: true,
      sortable: false,
      //renderCell: renderCheckBox,
      flex: 1,
    },
  ];
}

function renderCheckBox({ row, value, ...props }) {
  return <Checkbox defaultChecked={value || false} />;
}
