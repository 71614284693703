import { useState } from "react";
import { CONTACTS_APIS } from "../../../../common/apis";
import useFetch from "../../../../hooks/useFetch";
import DataTable, {
  DataGridAction,
  DataGridCheckBox,
} from "../../../../components/DataTable";
import AppDialog from "../../../../components/AppDialog";
import AlertDialog from "../../../../components/AlertDialog";
import { useToast } from "../../../../rootContext";
import appResources from "../../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../../components/DatatableCellExpand";
export default function ContactAccessPermissionTable({
  getData,
  data,
  rowSelected,
  ...props
}) {
  const options = {
    ...props,
    columns: GetColumns(deleteClick),
    dataSource: data,
    rowIdProperty: "ContactScopeId",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    sx: { height: 500 },
  };
  const [rowData, setRowData] = useState();
  const { get } = useFetch();
  const { showToast, showErrorToast } = useToast();
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
  });

  function deleteClick(row) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    setRowData(row);
  }
  async function deleteClickAlert() {
    const response = await get(
      `${CONTACTS_APIS.DELETE_SCOPE_FOR_CONTACT}?ContactScopeId=${rowData.ContactScopeId}&Screen=Contact Access Permissions`
    );
    if (response > 0) {
      showToast(appResources.DELETED_SUCCESSFULLY_MSG);
      getData();
    } else {
      showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
    }

    setDialog((prevState) => ({ ...prevState, open: false }));
  }

  return (
    <>
      <DataTable {...options}></DataTable>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Access"
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={deleteClickAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(deleteClick) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minminWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },

    {
      headerName: "Scope",
      field: "Scope",
      minWidth: 150,
    },
    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Parent",
      field: "Parent",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site Set",
      field: "SiteSet",
      minWidth: 150,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Product",
      field: "Product",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
  ];
}
