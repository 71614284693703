import { Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import { Assessment, Edit, EventNote, Insights, LocationOn, NotificationsNone, } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import EditUnit from "../../../forms/EditUnit";
import AppDialog from "../../../components/AppDialog";
import { useState, useEffect } from "react";
import EditEvent from "../../events/EditEvent";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS, TRAY_APIS } from "../../../common/apis";

export default function UnitDetail({
  data,
  product,
  customer,
  site,
  unit,
  component,
  unitModel,
}) {
  const { post, getFile } = useFetch();

  const [open, setOpen] = useState(false);
  const [openEditEvent, setOpenEditEvent] = useState(false);
  const [isNotify, setIsNotify] = useState(false);

  useEffect(() => {
    if (data?.NotificationUserId !== null) {
      return setIsNotify(true)
    }
    else {
      return setIsNotify(false)
    }
  }, [data?.NotificationUserId])

  const getMachineInfo = async () => {
    let dataList = await post(DETAILS_APIS.UPDATE_MACHINE_NOTIFICATIONS,
      { UnitId: data?.UnitId, HasNotification: isNotify })
    if (dataList?.Success === true) {
      return setIsNotify(!isNotify)
    }
  }

  const viewHealthReport = async () => {
    await getFile(
      `${TRAY_APIS.MACHINE_HEALTH_REPORT}/?EqpMachineId=${data?.EqpMachineId}`
    );
  };

  const actions = {
    edit: function (e) {
      setOpen(true);
    },
    events: function (e) {
      setOpenEditEvent(true);
    },
    notifyMe: function () {
      getMachineInfo()
    },
    healthReport: function () {
      viewHealthReport()
    },
    viewInsite: function () {
      window.open(
        `https://insite.komatsu.com.au/list?make=${data?.UnitMake}&model=${data?.UnitModel}&serial=${data?.Serial}`,
        "_blank"
      );
    },
    viewLocation: function () {
      window.open(
        `https://maps.google.com/?q=${data?.LocationLatitude},${data?.LocationLongitude}`,
        "_blank"
      );
    }
  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections}
          data={data}
          key={data && data?.UnitId}
        />
        <FormFooter buttons={GetFormButtons(data, isNotify, actions)} />
      </Card>
      <AppDialog
        open={open}
        title="Edit Unit"
        maxWidth="xl"
        handleClose={() => setOpen(false)}
      >
        <EditUnit
          cancel={() => setOpen(false)}
          unitData={{
            ProductId: data?.ProductId,
            Product: data?.Product,
            CustomerId: data?.CustomerId,
            Customer: data?.Customer,
            SiteId: data?.SiteId,
            Site: data?.Site,
            UnitId: data?.UnitId,
          }}
        //prodId={data?.ProductId}
        />
      </AppDialog>
      <AppDialog
        open={openEditEvent}
        title="Edit Event"
        maxWidth="lg"
        handleClose={() => setOpenEditEvent(false)}
      >
        <EditEvent
          cancel={() => setOpenEditEvent(false)}
          eventFilter={{
            siteId: data?.SiteId,
            unitId: data?.UnitId,
            isUnit: true,
            product: product,
            customer: customer,
            site: site,
            unit: unit,
            component: component,
            unitModel: unitModel,
          }}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, isNotify, actions) => [
  {
    variant: "outlined",
    label: "Event Log",
    startIcon: <EventNote />,
    onClick: actions.events,
    badge: { badgeContent: data?.EventLogCount },
  },
  {
    variant: "outlined", label: "Location", startIcon: <LocationOn />,
    onClick: actions.viewLocation,
    disabled: (data?.LocationLatitude === null && data?.LocationLongitude === null)
  },
  { variant: "outlined", label: "Insite", startIcon: <Insights />, onClick: actions.viewInsite },
  {
    variant: "outlined", label: "Health Report", startIcon: <Assessment />,
    disabled: (data?.EqpMachineId === null),
    onClick: actions.healthReport,
  },
  {
    variant: "outlined",
    label: isNotify === true ? "Unnotify" : "Notify Me",
    startIcon: <NotificationsNone />, onClick: actions.notifyMe,
  },
  {
    variant: "contained",
    label: "Edit",
    onClick: actions.edit,
    startIcon: <Edit />,
  },
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Unit Details" />;
        },
      },
    ],
  },
  {
    fields: [
      { name: "Unit", label: "Unit / Fleet No.", xsCol: 3 },
      { name: "UIN", label: "UIN Asset No.", xsCol: 3 },
      { name: "KalBranch", label: "KAL Branch", xsCol: 3 },
      { name: "Serial", label: "Serial", xsCol: 3 },
      { name: "UnitTypeId", label: "Type", xsCol: 3 },
      { name: "MeasureLong", label: "Measure", xsCol: 3 },
      { name: "UnitModelFormatted", label: "Model", xsCol: 3 },
      { name: "EventLogCount", label: "Event Log", xsCol: 3 },
      { name: "PIN", label: "PIN", xsCol: 3 },
      { name: "EqpMachineId", label: "MachineId", xsCol: 3 },
      { name: "LocationLatitude", label: "Latitude", xsCol: 3 },
      { name: "LocationLongitude", label: "Longitude", xsCol: 3 },
      { name: "UnitNotes", label: "Comment", type: "textarea", xsCol: 12 },
    ],
  },
];
