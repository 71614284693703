import { Card, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import { useEffect, useMemo, useState } from "react";
import { TRAY_APIS } from "../../../common/apis";
import { LabSteps } from "../../../common/Constants";
import GroupDialog from "../../../components/GroupDialog";
import useFetch from "../../../hooks/useFetch";
import useMasterData from "../../../hooks/useMasterData";
import { useRoot } from "../../../rootContext";
import { DateFormat, copyTextToClipboard } from "../../../utils";
import RegistrationForm from "./RegistrationForm";
import SampleFilter from "./SampleFilter";
import SamplesTable from "./SamplesTable";
import appResources from "../../../app-resources/en/Resources.json";
import { DialogTypes, useScreenPopups, } from "../../../components/useScreenPopups";
import QuickDetailsTree3 from "../../details-old/QuickDetailsTree4";
import AppDialog from "../../../components/AppDialog";
import ConfirmModal from "../../../components/ConfirmModal";

export default function Registration() {

  const { user, } = useRoot();

  const { post, get, getFile } = useFetch();
  const { getMasterData } = useMasterData();

  const [filter, setFilter] = useState({ LabAndDateOption: 0, Tray: 1 });
  const [data, setData] = useState([]);
  const [regData, setRegData] = useState(null);
  const [compData, setCompData] = useState(null);
  const [sampleData, setSampleData] = useState({});
  const [sampleDetails, setSampleDetails] = useState({});
  const [unregisteredOnly, setUnregisteredOnly] = useState([]);
  const [editRegId, setEditRegId] = useState(0);
  const [masterData, setMasterData] = useState({});
  const [dialogType, setDialogType] = useState({ open: false });
  const [regSampleExists, setRegSampleExists] = useState({});
  const [expandTreeData, setExpandTreeData] = useState(null);
  const [infoMessage, setInfoMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [startLetter, setStartLetter] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("false");

  async function onView(filterData) {
    setFilter({ ...filterData });
  }

  useEffect(
    function () {
      document.title = "Registration";

      filter && refreshData();
    },
    [filter]
  );

  async function refreshData(i) {
    if (filter.Date === "Invalid date") {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      //  setIsSuccessIcon(false);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    const resp = await post(TRAY_APIS.GET, {
      Date: filter.LabAndDateOption === 2 || filter.LabAndDateOption === 4 ? null : moment(filter.Date).format(DateFormat),
      LabId: filter.LabAndDateOption === 3 || filter.LabAndDateOption === 4 ? null : user.DefaultLaboratoryId,
      TrayNumber: filter.LabAndDateOption === 0 ? filter.Tray : null,
      MinimumStep: LabSteps.Registration,
      MaximumStep: filter.LabAndDateOption === 0 ? LabSteps.Completed : LabSteps.Registration,
      Hold: true,
    });

    if (resp?.length < 1) {
      setData();
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.NO_SAMPLES_WERE_FOUND_MSG);
    } else {
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setData(resp);
    }

    let filteredData = resp.filter((data) => {
      if (data?.Step < 2) {
        return data;
      }
    });
    if (filteredData?.length > 0) {
      return handleRowsSelected([filteredData[0]]);
    } else {
      return handleRowsSelected([resp[0]]);
    }
  }

  function onUnregisteredOnlyChanged(val) {
    setUnregisteredOnly(val);
  }

  async function updateComponentHandler(compId) {
    const temp = await get(`${TRAY_APIS.COMPONENT}?compId=` + compId);
    setCompData(temp);
  }

  async function registrationDoneHandler(sampleNumber) {
    setRegData(null);
    setCompData(null);
    setSampleData({});
    setSampleDetails({
      ...sampleDetails,
      [sampleNumber]: null,
    });
    await refreshData();
  }

  function searchRegClickHandler(check) {
    setDialogType({
      type: DialogTypes.SEARCH_REGISTRATION,
      open: check == undefined ? true : check,
    });
  }

  async function getRegistration(regNo) {
    if (!!regNo) {
      const temp =
        (await get(`${TRAY_APIS.REGISTRATIONS}?regId=` + regNo)) || {};
      setRegData(temp.Registration || null);
      setCompData(temp.Component || null);
    }
  }

  async function handleExistingReg(temp) {
    setRegSampleExists({
      SampleNumber: temp?.Registration?.SampleNumber,
      RegistrationId: temp?.Registration?.RegistrationId,
    });
  }

  async function retrieveRegistration(regNo) {
    if (!!regNo) {
      const temp =
        (await get(`${TRAY_APIS.REGISTRATIONS}?regId=` + regNo)) || {};
      if (temp?.Registration === null && temp?.Component === null) {
        return searchRegClickHandler();
      }
      if (temp?.Registration?.SampleNumber > 0) {
        return handleExistingReg(temp);
      } else {
        setEditRegId(regNo);
      }
    }
  }

  async function selectRegistration(reg) {
    await getRegistration(reg.SIF);
    closeDialog();
  }

  async function handleRowsSelected(rows) {
    if (Array.isArray(rows) && rows?.length === 0) {
      return;
    }
    setRegData(null);
    setCompData(null);

    let selectedRow;
    if (Array.isArray(rows)) {
      selectedRow = rows[0] || undefined;
    } else {
      selectedRow = rows;
    }
    setStartLetter(rows[0]?.StartLetter)
    if (rows[0]?.Step > 1) {
      setExpandTreeData({
        ProductId: rows[0]?.ProductId,
        StartLetter: rows[0]?.StartLetter,
        CustomerId: rows[0]?.CustomerId,
        SiteId: rows[0]?.SiteId,
        UnitId: rows[0]?.UnitId,
        ComponentId: rows[0]?.ComponentId,
      });
    }
    if (selectedRow && selectedRow.SampleNumber) {
      // const tempSampleData = sampleDetails[selectedRow.SampleNumber];
      // if (!tempSampleData) {

      const temp = await get(`${TRAY_APIS.REGISTRATION}?sampleNo=` + selectedRow.SampleNumber);
      setSampleDetails({
        ...sampleDetails,
        [selectedRow.SampleNumber]: temp,
      });
      setSampleData(temp);
      return;
      // }
      // setSampleData(tempSampleData);
    }
  }

  function openDialog(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  async function handleDeleteSample() {
    const resp = await get(`${TRAY_APIS.DELETE_SELECTED_SAMPLE}?SampleNumber=${sampleData.SampleNumber}&Screen=Registration`);
    if (resp > 0) {
      setOpenAlert(false)
      onView()
    }
  }

  function deleteSampleModal(row) {
    setAlertMessage(`Are you sure you want to delete Sample Number ${row?.SampleNumber} in Tray ${row?.Tray} for ${row?.Laboratory}`)
    setOpenAlert(true)
  }

  async function getSampleReport(row) {
    try {
      let fileName = `${row?.Customer} ${row?.Site} ${row?.Unit} ${row?.Component} ${row?.SampleNumber} `
      await getFile(`${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`, fileName);
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  function rowActionHandler(row, actionName) {
    if (
      ["update-tray-label",
        "edit-sample",
      ].indexOf(actionName) > -1
    ) {
      openDialog(row, actionName);
      return;
    } else if (actionName === "view-sample-report") {
      return getSampleReport(row);
    } else if (actionName === "delete-sample-number") {
      return deleteSampleModal(row);
    } else if (actionName === "copy-sample-number") {
      return copyTextToClipboard(row?.SampleNumber);
    }
  }

  const dataSrc = useMemo(
    function () {
      return unregisteredOnly ? data?.filter((x) => x.Step === 1) : data;
    },
    [data, unregisteredOnly]
  );

  useEffect(function () {
    (async function () {
      const temp = await getMasterData(
        "Laboratories,SampleTypes,ServiceTypes,SampleOptions"
      );
      setMasterData(temp);
    })();
  }, []);

  const dialogOptions = useScreenPopups({
    data: dialogType.data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
    selectRegistration: selectRegistration,
    getRegistration: getRegistration,
    searchRegClickHandler: searchRegClickHandler,
    onView: onView
  });

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ py: 0.5, px: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <SampleFilter
                  onView={onView}
                  onUnregisteredOnlyChanged={onUnregisteredOnlyChanged}
                  infoMessage={infoMessage}
                  successMessage={successMessage}
                  isInfoIcon={isInfoIcon}
                  isSuccessIcon={isSuccessIcon}
                  isWarningTrue={isWarningTrue}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={2} mt={1}>
          <QuickDetailsTree3
            expandTreeData={expandTreeData}
            fromReg={true}
            comp2Click={updateComponentHandler}
            minHeight={"81vh"}
            maxHeight={"81vh"}
          />
        </Grid>

        <Grid item xs={10}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" sx={{ mt: 0.5 }}>
              Total Records: <b> {dataSrc && dataSrc?.length}</b>
            </Typography>
            {/* <ResizePanel direction="s"> */}
            <SamplesTable
              sx={{ height: "160px" }}
              data={dataSrc}
              rowsSelected={handleRowsSelected}
              selectedRow={[sampleData.SampleNumber]}
              rowActionHandler={rowActionHandler}
            />
            {/* </ResizePanel> */}
          </Grid>

          <Card sx={{ py: 1, mt: 1, px: "15px" }}>
            <RegistrationForm
              trayData={data}
              regData={regData}
              compData={compData}
              sampleData={sampleData}
              key={
                sampleData.SampleNumber +
                ((regData && regData.SIF) || "") +
                ((compData && compData.ComponentId) || "")
              }
              masterData={masterData}
              updateComponent={updateComponentHandler}
              registrationDone={registrationDoneHandler}
              getRegistration={getRegistration}
              searchRegClickHandler={searchRegClickHandler}
              retrieveRegistration={retrieveRegistration}
              setDialogType={setDialogType}
              editRegId={editRegId}
              setEditRegId={setEditRegId}
              regSampleExists={regSampleExists}
              setRegSampleExists={setRegSampleExists}
              startLetter={startLetter}
              setExpandTreeData={setExpandTreeData}
            />
          </Card>
        </Grid>
      </Grid>

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
      <AppDialog
        open={openAlert}
        title={"Delete Sample"}
        maxWidth="md"
        handleClose={() => { setOpenAlert(false); }}
      >
        <ConfirmModal
          handleClose={() => { setOpenAlert(false); }}
          alertAction={handleDeleteSample}
          message={alertMessage}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}
