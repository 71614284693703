import { useTheme } from "@emotion/react";
import { Button, Card, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import DataTable from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";
import { FileUpload } from "@mui/icons-material";
import DropDown from "../../../components/DropDown";
import ImportHistoryTable from "./ImportHistoryTable";

export default function ImportHistory() {
  const curTheme = useTheme();
  const { post, postFile } = useFetch();

  const [jsonArray, setJsonArray] = useState([]);
  const [history, setHistory] = useState({});
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const xmlText = event.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlText, "text/xml");
        const jsonData = xmlToJson(xmlDoc);

        setJsonArray((prevJsonArray) => [...prevJsonArray, ...jsonData]);

        const { columns, data } = getDataAndColumns([
          ...jsonArray,
          ...jsonData,
        ]);
        setHistory({ columns, data });
      };
      reader.readAsText(file);
    }
  };

  const xmlToJson = (xml) => {
    const data = [];
    const tableNodes = xml.getElementsByTagName("Table1");

    for (let i = 0; i < tableNodes.length; i++) {
      const tableNode = tableNodes[i];
      const entry = {};

      for (let j = 0; j < tableNode.childNodes.length; j++) {
        const childNode = tableNode.childNodes[j];
        if (childNode.nodeType === Node.ELEMENT_NODE) {
          entry[childNode.nodeName] = childNode.textContent;
        }
      }

      data.push(entry);
    }

    return data;
  };

  function handleDropdown(e) { }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <DropDown
                  name="fileId"
                  label="File Type"
                  selectOptions={[
                    { text: "Oil Commander XML", value: 0 },
                    { text: "XML", value: 1 },
                    {
                      text: "Manage Equipment > Fluid Analysis > Sample Data Extract; choose XML for Format Type",
                      value: 2,
                    },
                  ]}
                  required={true}
                  value={0}
                  onChange={handleDropdown}
                />
              </Grid>
              <Grid item xs={7} mt={2.2}>
                <input type="file" onChange={handleFileChange} />
              </Grid>
              <Grid item xs={2} mt={1.6}>
                <Button
                  startIcon={<FileUpload />}
                // onClick={handleChartClick}
                >
                  Import (F12)
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          {jsonArray?.length > 0 && (
            <Typography variant="subtitle2" sx={{ color: "green" }}>
              {jsonArray?.length} third-party history samples are displayed
            </Typography>
          )}
          <ImportHistoryTable {...history} />
        </Grid>
      </Grid>
    </>
  );
}

function getDataAndColumns(jsonData) {
  const transformedData = transformData(jsonData);
  const generatedColumns = generateColumns(transformedData[0]);

  return { columns: generatedColumns, data: transformedData };
}
const transformData = (jsonData) => {
  const transformedData = jsonData.map(transformEntry);
  return transformedData;
};

const columnsToSkip = ["custid", "compartid", "compartsn", "modeldesc"];

const generateColumns = (transformedEntry) => {
  const columns = Object.keys(transformedEntry)
    .filter((key) => !columnsToSkip.includes(key))
    .map((key) => {
      let headerName = key; // Default headerName is the key
      // Apply custom headerName logic based on key
      if (key === "labno") {
        headerName = "SampleId";
      } else if (key === "cust_name") {
        headerName = "Customer";
      } else if (key === "Job_x0020_Site") {
        headerName = "Site";
      } else if (key === "unitno") {
        headerName = "Unit";
      } else if (key === "makeid") {
        headerName = "Make";
      } else if (key === "modelid") {
        headerName = "Model";
      } else if (key === "serialno") {
        headerName = "Serial";
      } else if (key === "compart") {
        headerName = "Component";
      } else if (key === "Job_x0020_Site") {
        headerName = "Site";
      } else if (key === "") {
        headerName = "";
      } else if (key === "") {
        headerName = "";
      } else if (key === "") {
        headerName = "";
      } else if (key === "") {
        headerName = "";
      } else if (key === "") {
        headerName = "";
      } else if (key === "") {
        headerName = "";
      }

      return {
        headerName,
        field: key,
        width: key === "cust_name" ? 180 : key === "compart" ? 150 : 80,
      };
    });

  return columns;
};

const transformEntry = (entry) => {
  const transformedEntry = {};

  for (const key in entry) {
    const value = entry[key];
    let transformedValue = value;

    // Apply your transformation logic based on the key
    if (key === "makeid") {
      switch (value) {
        case "KDC":
          transformedValue = "Komatsu";
          break;
        case "KM":
          transformedValue = "Komatsu";
          break;
        case "AA":
          transformedValue = "Caterpillar";
          break;
        default:
          transformedValue = "Unknown";
          break;
      }
    } else if (key === "sampledate" || key === "processdate") {
      transformedValue = new Date(value);
    }
    // Add more transformations as needed for other keys

    transformedEntry[key] = transformedValue;
  }

  return transformedEntry;
};
