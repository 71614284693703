import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function QuickMoveToSitesTable({ data, rowSelected }) {
  const toSiteOptions = {
    columns: toSiteColumns,
    dataSource: data || [],
    rowIdProperty: "CustomerId",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    sx: { height: 690 },
  };
  return <DataTable {...toSiteOptions} />;
}
const toSiteColumns = [
  {
    headerName: "Product",
    field: "Product",
    minWidth: 180,
  },
  {
    headerName: "Destination Customer",
    field: "Customer",
    minWidth: 190,
    renderCell: renderCellExpand,
  },
];
