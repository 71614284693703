import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function QuickMergeToSitesTable({ data, rowSelected }) {
  const toMergeOptions = {
    columns: toMergeColumns,
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.CustomerId + "" + (x.Site || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: false,
    cellStylingHandler: "productCommonCellColour",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    sx: { height: 760 },
  };
  return <DataTable {...toMergeOptions} />;
}
const toMergeColumns = [
  {
    headerName: "To Product",
    field: "Product",
    minWidth: 200,
    renderCell: renderCellExpand,
  },
  {
    headerName: "To Customer",
    field: "Customer",
    minWidth: 170,
    renderCell: renderCellExpand,
  },
  {
    headerName: "To Site",
    field: "Site",
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
