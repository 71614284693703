import { Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import { Edit, EventNote, NotificationsNone } from "@mui/icons-material";
import AppDialog from "../../../components/AppDialog";
import EditComponent from "../../../forms/EditComponent";
import { useState, useEffect } from "react";
import EditEvent from "../../events/EditEvent";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";

export default function Component({
  data,
  product,
  customer,
  site,
  unit,
  component,
  unitModel,
}) {
  const { post } = useFetch();

  const [open, setOpen] = useState(false);
  const [openEditEvent, setOpenEditEvent] = useState(false);
  const [isNotify, setIsNotify] = useState(false);

  useEffect(() => {
    if (data?.NotificationUserId !== null) {
      return setIsNotify(true)
    }
    else {
      return setIsNotify(false)
    }
  }, [data?.NotificationUserId])

  const getMachineInfo = async () => {
    let dataList = await post(DETAILS_APIS.UPDATE_MACHINE_NOTIFICATIONS,
      { ComponentId: data?.ComponentId, HasNotification: isNotify })
    if (dataList?.Success === true) {
      return setIsNotify(!isNotify)
    }
  }

  const actions = {
    edit: function (e) {
      setOpen(true);
    },
    events: function (e) {
      setOpenEditEvent(true);
    },
    notifyMe: function () {
      getMachineInfo()
    },
  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={GetFormSections(data)}
          data={data}
          key={data && data.ComponentId}
        />
        <FormFooter buttons={GetFormButtons(data, isNotify, actions)} />
      </Card>
      <AppDialog
        open={open}
        title="Edit Component"
        maxWidth="lg"
        handleClose={() => setOpen(false)}
      >
        <EditComponent
          cancel={() => setOpen(false)}
          //unitId={data.UnitId}
          compId={data.ComponentId}
          //prodId={data.ProductId}
          fluidTypeId={data.ComponentFluidTypeId}
        />
      </AppDialog>
      <AppDialog
        open={openEditEvent}
        title="Edit Event"
        maxWidth="lg"
        handleClose={() => setOpenEditEvent(false)}
      >
        <EditEvent
          cancel={() => setOpenEditEvent(false)}
          eventFilter={{
            siteId: data.SiteId,
            unitId: data.UnitId,
            componentId: data.ComponentId,
            isComponent: true,
            product: product,
            customer: customer,
            site: site,
            unit: unit,
            component: component,
            unitModel: unitModel,
          }}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, isNotify, actions) => [
  {
    variant: "outlined",
    label: "Event Log",
    startIcon: <EventNote />,
    onClick: actions.events,
    badge: { badgeContent: data.EventLogCount },
  },
  {
    variant: "outlined",
    label: isNotify === true ? "Unnotify" : "Notify Me",
    startIcon: <NotificationsNone />, onClick: actions.notifyMe,
  },
  {
    variant: "contained",
    label: "Edit",
    onClick: actions.edit,
    startIcon: <Edit />,
  },
];

const GetFormSections = (data) => [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Component Details" />;
        },
      },
      { name: "Component", label: "Component", xsCol: 3 },
      { name: "Serial", label: "Serial", xsCol: 3 },
      { name: "ComponentModel", label: "Model", xsCol: 3 },
      { name: "Defaults", label: "Defaults", xsCol: 3 },
      {
        name: "DefaultFluidFormatted",
        label: (data && data.DefaultFluidType) || "Oil",
        xsCol: 3,
      },
      { name: "DefaultTestSet", label: "Test Set", xsCol: 3 },

      { name: "CIN", label: "CIN", xsCol: 3 },
      { name: "ComponentType", label: "Type", xsCol: 3 },
      { name: "Capacity", label: "Capacity", xsCol: 3 },
      { name: "Location", label: "Location", xsCol: 3 },
      // { name: "EventLogCount", label: "Event Log", xsCol: 3 },
      {
        name: "ComponentNotes",
        label: "Comments",
        type: "textarea",
        xsCol: 12,
      },
    ],
  },
];
