import { Close, Search } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import { CONTACTS_APIS, DETAILS_APIS } from "../../../../common/apis";
import DataTable, { DataGridAction } from "../../../../components/DataTable";
import Form, { FormActions } from "../../../../components/Form";
import FormFooter from "../../../../components/FormFooter";
import useFetch from "../../../../hooks/useFetch";
import { useRoot, useToast } from "../../../../rootContext";
import appResources from "../../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../../components/DatatableCellExpand";

export default function SearchSite({
  actions,
  contactId,
  rowSelected,
  selectedUnit,
}) {

  const { allProducts } = useRoot();
  const { showWarningToast } = useToast();
  const { post } = useFetch();

  const [sitesData, setSitesData] = useState([]);
  const [data, dispatch] = useReducer(SearchFluidsFilterReducer, searchFilterDefaults);
  // ProductId: selectedUnit?.ProductId || 0,
  // UseStartsWith: true,
  // Customer: selectedUnit?.Customer,
  useEffect(() => {
    if (selectedUnit) {
      dispatch({
        payload: {
          ProductId: selectedUnit?.ProductId,
          Customer: selectedUnit?.Customer,
          UseStartsWith: true,
          _key: Date.now(),
        },
        type: FormActions.LOAD_DATA,
      });
    }
  }, [selectedUnit]);

  const tblOptions = {
    columns: GetColumns(actions),
    dataSource: sitesData,
    rowIdProperty: "SiteId",
    sx: { height: "500px" },
    cellStylingHandler: "productCommonCellColour",
    isDisableColumnFilter: true,
    rowsSelected: function (rows) {
      rowSelected && rowSelected(rows[0]);
    },
  };

  const formActions = {
    clear: function () {
      dispatch({
        payload: {
          ...searchFilterDefaults,
          _key: Date.now(),
        },
        type: FormActions.RESET_FORM,
      });
    },
    search: async function () {
      if (contactId) {
        const tempSitesData = await post(CONTACTS_APIS.SITES_FOR_CONTACT, {
          ...data,
          ContactId: contactId,
          ExcludeScoped: true,
          UseStartsWith: data?.UseStartsWith || false,
          ShowAll: true,
        });
        if (tempSitesData.length > 0) {
          setSitesData(tempSitesData);
        } else {
          showWarningToast(appResources.NO_MATCHING_SITES_WERE_FOUND_MSG);
          setSitesData(tempSitesData);
        }
      } else {
        const tempSitesData = await post(DETAILS_APIS.SEARCH_SITES, {
          ...data,
        });
        if (tempSitesData?.length > 0) {
          let filteredData = tempSitesData.filter(item => item?.SiteId !== selectedUnit?.SiteId);
          setSitesData(filteredData);
        } else {
          showWarningToast(appResources.NO_MATCHING_SITES_WERE_FOUND_MSG);
          setSitesData(tempSitesData);
        }
      }
    },
  };

  return (
    <>
      <Grid container minWidth={800}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(allProducts)}
            data={data}
            dispatch={dispatch}
            key={data && data._key}
          />
        </Grid>
        <Grid item xs={12} sx={{ ml: 15, mt: -4 }}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2">
            Total Records: <b> {sitesData && sitesData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}
const GetFormButtons = (data, { clear, search }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <Close />,
  },
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    disabled: !(data?.Customer || data?.Site),
    startIcon: <Search />,
  },
];

function GetColumns(actions) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      minWidth: 60,
      hide: !actions || !actions.length,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
            />
          );
        });
      },
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 130,
      sortable: false,
    },
    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 130,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      minWidth: 130,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Predelivery",
      field: "Predelivery",
      minWidth: 130,
      sortable: false,
    },
    {
      headerName: "Archived",
      field: "SiteArchived",
      minWidth: 100,
      sortable: false,
    },
  ];
}

function GetFormSections(allProducts) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 2,
          name: "ProductId",
          label: "Product",
          type: "dropdown",
          required: true,
          selectOptions: (allProducts || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
        },
        {
          xsCol: 3,
          name: "Customer",
          label: "Customer",
        },
        {
          xsCol: 3,
          name: "Site",
          label: "Site",
        },
        {
          xsCol: 3,
          name: "UseStartsWith",
          label: "Starts With Search",
          type: "switch",
          alignSelf: "end",
        },
      ],
    },
  ];
}
const searchFilterDefaults = {
  ProductId: 0,
  UseStartsWith: true,
};
function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...action.payload };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  return state;
}
