import React, { useReducer, useState, } from "react";
import { Check, Settings } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import AppDialog from "../../../components/AppDialog";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import ManageUnitModels from "./ManageUnitModels";
import { FLEET_APIS } from "../../../common/apis";
// import InsiteMachineSearch from "./InsiteMachineSearch";
import InsiteModelSearch from "./InsiteModelSearch";
import { useRoot, useToast } from "../../../rootContext";
import { KomatsuCheckIcon } from "../../../icons/ButtonIcons";
import appResources from "../../../app-resources/en/Resources.json";

export default function ViewUnitModels({
  unitModel,
  fetchUnitModels,
  handleClose,
  showMessage,
}) {
  const { CanDoDataMaintenance } = useRoot();
  const { showToast, } = useToast();
  const [error, setError] = useState([]);
  const [manageUnitOpen, setManageUnitOpen] = useState(false);
  const [komatsuCheckOpen, setKomatsuCheckOpen] = useState(false);
  const { post } = useFetch();
  const [data, dispatch] = useReducer(FluidReducer, {
    ...unitModel,
    Verified: unitModel && unitModel.Verified === "Yes",
  });

  const actions = {
    save: async function () {
      // unitModel && (async function () {
      // })
      const response = await post(FLEET_APIS.SAVE_UNIT_MAKE, {
        UnitMakeId: data?.UnitMakeId,
        Make: data?.UnitMake,
        Model: data?.UnitModel,
        Screen: "Unit Models",
        UnitModelNotes: data?.UnitModelNotes || "",
        Verified: data.Verified ? "Yes" : "No",
      });

      if (response.length > 2) {
        setError(response);
      }
      if (response[0].failureReason !== null) {
        showMessage(
          "The unit model was unable to be updated as: " +
          response[0].failureReason
        );
        //  showInfoToast(response[0].failureReason);
      } else {
        handleClose && handleClose(true);
        showToast(appResources.UNIT_MODEL_ADDED_SUCCESSFULLY_MSG);
        dispatch({ type: FormActions.RESET_FORM });
      }
      fetchUnitModels();
    },
    manage: function () {
      setManageUnitOpen(true);
    },
  };

  function komatsuCheck() {
    setKomatsuCheckOpen(true);
  }

  async function refreshGrid() {
    await fetchUnitModels();
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <p>{error}</p>
          <Form
            sections={GetFormSections(komatsuCheck, unitModel)}
            data={data}
            dispatch={dispatch}
            key={data && data._key}
          />
        </Grid>

        <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(actions, unitModel, data, CanDoDataMaintenance)}
            hideDivider
            gmt={0}
          />
        </Grid>
      </Grid>

      <AppDialog
        open={manageUnitOpen}
        handleClose={() => setManageUnitOpen(false)}
        title={`Unit Model Management ${unitModel && unitModel.UnitModelFormatted
          }`}
        maxWidth="xl"
      >
        <ManageUnitModels
          SelectedUnit={unitModel}
          refreshGrid={refreshGrid}
          setManageUnitOpen={setManageUnitOpen}
        />
      </AppDialog>

      <AppDialog
        open={komatsuCheckOpen}
        handleClose={() => setKomatsuCheckOpen(false)}
        title="Insite Model Search"
        maxWidth="xl"
      >
        <InsiteModelSearch />
      </AppDialog>
    </>
  );
}

const GetFormButtons = ({ manage, save }, unitModel, data, CanDoDataMaintenance) => [
  {
    variant: "outlined",
    label: "Manage",
    disabled: unitModel && !unitModel.UnitMakeId,
    onClick: manage,
    hidden: typeof unitModel === "undefined",
    startIcon: <Settings />,
  },
  {
    variant: "contained",
    label: "Save",
    type: "submit",
    onClick: save,
    startIcon: <Check />,
    hidden: !CanDoDataMaintenance,
    disabled: !data.UnitModel || !data.UnitMake,
  },
];

function GetFormSections(komatsuCheck, unitModel) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          component: function () {
            return (
              <Typography>
                {unitModel?.UnitModelFormatted?.length > 0
                  ? unitModel?.UnitModelFormatted
                  : "Please provide the component model details"}
              </Typography>
            );
          },
        },
        {
          xsCol: 6,
          name: "UnitMake",
          label: "Make",
        },
        {
          xsCol: 6,
          name: "UnitModel",
          label: "Model",
        },
        {
          xsCol: 2,
          name: "Verified",
          label: "Verified",
          type: "switch",
          hidden: typeof unitModel === "undefined",
        },
        {
          xsCol: 4,
          hidden: typeof unitModel === "undefined",
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={komatsuCheck}
                startIcon={<KomatsuCheckIcon />}
              >
                Komatsu Check
              </Button>
            );
          },
        },
        {
          xsCol: 12,
          name: "UnitModelNotes",
          label: "Notes",
          type: "textarea",
        },
      ],
    },
  ];
}

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { _key: Date.now() };
  }

  return state;
}
