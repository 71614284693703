import { Button, Typography } from "@mui/material"
import moment from "moment";
import { FormActions } from "../../../components/Form";

export const FormSections = (hanldeButtonClick, deleteWebsiteNews, handleSaveClick,) => {

    return [
        {
            xsCol: 12,
            fields: [
                { xsCol: 12, component: function () { return (<Typography sx={{ fontWeight: "500" }}>News Item HTML - Heading and Summary are Required</Typography>); }, },
                { xsCol: 8, name: "HeadingHtml", label: "Heading", type: "textarea", rows: 3 },
                {
                    xsCol: 4,
                    group: [
                        { xsCol: 3, component: function () { return (<Button variant="outlined" sx={{ width: "80px", mt: 1.5 }} onClick={() => hanldeButtonClick("HeadingHtml", "<h4></h4>")}>Standard</Button>); }, },
                        { xsCol: 9, component: function () { return (<Typography sx={{ mt: 1.5 }}>The text should be wrapped in HTML tags</Typography>); }, },
                        { xsCol: 3, component: function () { return (<Button variant="outlined" sx={{ width: "80px", }} onClick={() => hanldeButtonClick("HeadingHtml", "<h4 style='color:Red;'></h4>")}>Colour</Button>); }, },
                        { xsCol: 9, component: function () { return (<Typography>{"Line Break = <br/>"}<br />{"Paragraph = <p>My body text here</p>"}<br />{"Heading = <h4>My heading here</h4>"}</Typography>); }, },
                    ],
                },
                { xsCol: 8, name: "BodyHtml", label: "Summary", type: "textarea", rows: 5 },
                {
                    xsCol: 4,
                    group: [
                        { xsCol: 3, component: function () { return (<Button variant="outlined" sx={{ width: "80px", mt: 1.5 }} onClick={() => hanldeButtonClick("BodyHtml", "<p></p>")}>Standard</Button>); }, },
                        { name: "overrideTextLimit", label: "Override Text Limit", type: "switch", },
                    ],
                },
                { xsCol: 8, name: "FullBodyHtml", label: "Main Text", type: "textarea", rows: 15 },
                {
                    xsCol: 4,
                    group: [
                        { xsCol: 3, component: function () { return (<Button variant="outlined" sx={{ width: "80px", mt: 1.5 }} onClick={() => hanldeButtonClick("FullBodyHtml", "<p></p>")}>Standard</Button>); }, },
                    ],
                },
                { xsCol: 12, name: "IsArchived", label: "Archived", type: "switch", },
                { xsCol: 6, name: "noStartDate", label: "No Start Date", type: "switch", },
                { xsCol: 6, name: "noEndDate", label: "No End Date", type: "switch", },
                { xsCol: 6, name: "DisplayFrom", label: "From", type: "date", },
                { xsCol: 6, name: "DisplayTo", label: "To", type: "date", },
                { name: "IsKomatsu", label: "Komatsu", type: "switch", },
                { name: "IsPortal", label: "Portal", type: "switch", },
                { name: "IsLabcheck", label: "Labcheck", type: "switch", },
                { xsCol: 6, name: "DisplayRank", label: "Display Order", min: 0, type: "number", },
                { xsCol: 6, component: function () { return (<Button sx={{ width: "80px", }} onClick={deleteWebsiteNews}>Delete</Button>); }, },
                { xsCol: 6, component: function () { return (<Button sx={{ width: "80px", float: "right" }} onClick={handleSaveClick}>Save</Button>); }, },
            ]
        }]
}

export const WebsiteFormReducer = (state, action) => {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { [action.payload.name]: action.payload.value, ...state, };
    }
    if (action.type === FormActions.RESET_FORM) {
        return { ...WebsiteFormDefaults, key: Date.now() };
    }
    if (action.type === FormActions.LOAD_DATA) {
        return { ...action.payload, key: Date.now() };
    }
    return state;
}

export const WebsiteFormDefaults = {
    WebsiteNewsId: null,

    HeadingHtml: "<h4></h4>",
    BodyHtml: "<p></p>",
    FullBodyHtml: "<p></p>",//text length should be 276

    DisplayFrom: moment(),
    DisplayTo: moment(),

    DisplayRank: 1,
    IsArchived: false,
    IsKomatsu: false,
    IsPortal: false,
    IsLabcheck: true,

    Screen: "Website News",
    key: Date.now()
};