import { Typography } from "@mui/material";
import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function SiteSetsTable({ data, rowSelected, selectedRow }) {
  const options = {
    columns: Columns,
    dataSource: data,
    rowIdProperty: "IndexId",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    sx: {
      height: 730,
    },
  };
  return (
    <>
      <Typography variant="subtitle2">
        Total Records: <b> {data && data.length}</b>
      </Typography>
      <DataTable {...options} />
    </>
  );
}

const Columns = [
  {
    headerName: "Product",
    field: "Product",
    width: 150,
  },
  {
    headerName: "Site Set",
    field: "SiteSet",
    width: 250,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Automatic",
    field: "AutoUpdated",
    miWidth: 70,
  },
];
