import { Check } from "@mui/icons-material";
import { useEffect, useReducer, useState } from "react";
import Form, { SectionTitle } from "../components/Form";
import FormFooter from "../components/FormFooter";
import CustomerReducer, { CustomerActions } from "./reducers/CustomerReducer";
import useFetch from "./../hooks/useFetch";
import { Button, Typography } from "@mui/material";
import { useRef } from "react";
import useMasterData from "../hooks/useMasterData";
import { ValidateCustomer } from "../validation/schemas/CustomerSchema.";
import { DETAILS_APIS } from "../common/apis";
import { FormActions } from "../components/Form";
import { NewIcon, ReportLogoIcon } from "../icons/ButtonIcons";
import { useRoot, useToast } from "../rootContext";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as CustomerAction from "../redux/actions/CustomerActions";
import appResources from "../app-resources/en/Resources.json";
import { getStartLetter } from "../utils";
import AppDialog from "../components/AppDialog";
import ManageCustomer from "../features/quick-details/manage/manage-customer/ManageCustomer";

function EditCustomer({
  custId,
  prodId,
  registrationDone,
  cancel,
  handleNewCustomer,
  handleSearchTree,
  CustomerAction,
  setExpandTreeData,
}) {

  const [data, dispatch] = useReducer(CustomerReducer, {
    ProductId: prodId || 0,
    ExportOptionId: 1,
    BlockCustomerEvaluationsFrom: "2100-01-01T00:00:00",
  });
  const { get, post } = useFetch();
  const { getMasterData } = useMasterData();
  const { showToast, showWarningToast, showErrorToast } = useToast();
  const { products, CanDoDataMaintenance, CanAdministrate } = useRoot();

  const [dropdownData, setDropdownData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [editCheck, setEditCheck] = useState(false);
  const [openManagement, setOpenManagement] = useState(false);

  // const handleKeyDown = useCallback(
  //   (event) => {
  //     if (event.key === "F11" || event.key === "F12") {
  //       event.preventDefault();
  //       actions.save();
  //     }
  //   },
  //   [data]
  // );
  // useEffect(() => {
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [handleKeyDown]);

  const actions = {
    save: async function () {
      if (custId) {
        setFormErrors({});
        ValidateCustomer(data).then(async function ({ data: req, errors }) {
          if (req) {
            const postPayload = {
              // ...data,
              CustomerId: data?.CustomerId,
              ProductId: data?.ProductId,
              Customer: data?.Customer,
              KnownAs: data?.AlternativeNames,
              OwnerUserId: data?.CmsOwnerId,
              SetOwnerToAllSites: data?.SetOwnerToAllSites,

              SapNumber: data?.AccountNumber,
              SapCustomer: data?.SapCustomer,

              ParentId: data?.ParentId,
              Notes: data?.CustomerNotes,
              ExportOptionId: data?.ExportOptionId,
              SetExportToAllSites: data?.SetExportToAllSites,

              PostalAddressId: data?.PostalAddressId,
              PostalStreet1: data?.PostalStreet1,
              PostalStreet2: data?.PostalStreet2,
              PostalCity: data?.PostalCity,
              PostalState: data?.PostalState,
              PostalCountry: data?.PostalCountry,
              PostalPostCode: data?.PostalPostCode,

              PhysicalAddressId: data?.PhysicalAddressId,
              PhysicalStreet1: data?.PhysicalStreet1,
              PhysicalStreet2: data?.PhysicalStreet2,
              PhysicalCity: data?.PhysicalCity,
              PhysicalState: data?.PhysicalState,
              PhysicalCountry: data?.PhysicalCountry,
              PhysicalPostCode: data?.PhysicalPostCode,

              IsArchived: !!data.CustomerArchived,
              IsShowLimitsOnReport: !!data.IsShowLimitsOnReport,
              Screen: "Edit Customer",
            };
            let result = await post(DETAILS_APIS.UPDATE_CUSTOMER, postPayload);
            if (result > 0 || result?.length > 0) {
              // {
              //   "CustomerId": data.CustomerId,
              //   "Customer": data.Customer,
              //   "CmsOwnerName": data.CmsOwnerName,
              //   "Product": data.Product,
              //   "AccountNumber": data.AccountNumber,
              //   "SapCustomer": data,
              //   "ExportOption": data,
              //   "Parent": data,
              //   "AlternativeNames": data,
              //   "CustomerNotes": data,
              // }
              showToast(appResources.CUSTOMER_UPDATED_SUCCESSFULLY_MSG);
              CustomerAction.updateCustomer(data);
              return cancel();
            }

            if (result !== null && (result < 1 || result?.length < 1)) {
              return showErrorToast(appResources.CUSTOMER_NOT_SAVED_MSG);
            }

            registrationDone && registrationDone(data.SampleNumber);
            return;
          }
          if (errors) {
            setFormErrors(errors);
          }
        });
      } else {
        setFormErrors({});
        ValidateCustomer(data).then(async function ({ data: req, errors }) {
          if (req) {
            let name = data?.Customer.trim();
            let slIndex = Number(getStartLetter(name[0]));

            let result = await post(DETAILS_APIS.ADD_CUSTOMER, {
              ProductId: data?.ProductId,
              Customer: data?.Customer,
              KnownAs: data?.AlternativeNames,
              OwnerUserId: data?.OwnerUserId,
              SetOwnerToAllSites: data?.SetOwnerToAllSites,

              SapNumber: data?.AccountNumber,
              SapCustomer: data?.SapCustomer,

              ParentId: data?.ParentId,
              Notes: data?.CustomerNotes,
              ExportOptionId: data?.ExportOptionId,
              SetExportToAllSites: data?.SetExportToAllSites,

              PostalAddressId: data?.PostalAddressId,
              PostalStreet1: data?.PostalStreet1,
              PostalStreet2: data?.PostalStreet2,
              PostalCity: data?.PostalCity,
              PostalState: data?.PostalState,
              PostalCountry: data?.PostalCountry,
              PostalPostCode: data?.PostalPostCode,

              PhysicalAddressId: data?.PhysicalAddressId,
              PhysicalStreet1: data?.PhysicalStreet1,
              PhysicalStreet2: data?.PhysicalStreet2,
              PhysicalCity: data?.PhysicalCity,
              PhysicalState: data?.PhysicalState,
              PhysicalCountry: data?.PhysicalCountry,
              PhysicalPostCode: data?.PhysicalPostCode,

              IsArchived: !!data.CustomerArchived,
              IsShowLimitsOnReport: !!data.IsShowLimitsOnReport,
              Screen: "New Customer",
            });
            registrationDone && registrationDone(data.SampleNumber);

            if (result === -10) {
              return showWarningToast(
                appResources.PROVIDE_UNIQUE_NAME_FOR_CUSTOMER_MSG
              );
            }
            if (result >= 0 || result?.length > 0) {
              if (result > 0) {
                const treeData = { ProductId: data?.ProductId, StartLetter: slIndex, CustomerId: result }
                setExpandTreeData && setExpandTreeData(treeData)
                handleSearchTree && handleSearchTree(treeData)
              }
              showToast(appResources.CUSTOMER_SAVED_SUCCESSFULLY_MSG);
              handleNewCustomer && handleNewCustomer(data?.Customer, data?.ProductId);
              return cancel();
            }
            if (result !== null && (result < 1 || result?.length < 1)) {
              return showErrorToast(appResources.CUSTOMER_NOT_SAVED_MSG);
            }
            return;
          }

          if (errors) {
            setFormErrors(errors);
          }
        });
      }
    },
    manage: async function () {
      setOpenManagement(true);
    },
  };

  useEffect(() => {
    (async () => {
      if (!custId) {
        let masterData = await getMasterData(`Users,ExportOptions,Countries,Parents&ProductId=${prodId}`);
        masterData.Users?.unshift({ UserId: 0, FullName: "Default User" });
        setDropdownData(masterData || {});
      }
      if (custId) {
        let data = await get(`/details/customer?custId=${custId}`);
        data.Users?.unshift({ UserId: 0, FullName: "Default User" });
        setDropdownData(data || {});
        dispatch({ type: CustomerActions.LOAD_DATA, payload: data.Customer });
        setEditCheck(true)
      }
    })();
  }, []);

  //handle logo upload
  const fileInputRef = useRef(null);

  function handleFileInputClick() {
    fileInputRef.current.click();
  }

  const handleFileChange = (e) => {
    const imgUrl = URL.createObjectURL(e.target.files[0]);
  };

  function handleCopyToPostal() {
    // CanDoDataMaintenance for button
    dispatch({
      type: FormActions.INPUT_CHANGED,
      // CustomerActions.LOAD_DATA,
      payload: {
        ...data,
        PostalStreet1: data?.PhysicalStreet1 || "",
        PostalStreet2: data?.PhysicalStreet2 || "",
        PostalCity: data?.PhysicalCity || "",
        PostalPostCode: data?.PhysicalPostCode || "",
        PostalState: data?.PhysicalState || "",
        PostalCountry: data?.PhysicalCountry || "",
      },
    });
  }

  function handleCopyToPhysical() {
    // CanDoDataMaintenance for button 
    dispatch({
      ...data,
      PhysicalStreet1: data.PostalStreet1,
      PhysicalStreet2: data.PostalStreet2,
      PhysicalCity: data.PostalCity,
      PhysicalPostCode: data.PostalPostCode,
      PostalCountry: data.PhysicalCountry,
    });
  }

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      <Form
        sections={FormSections(
          custId,
          editCheck,
          dropdownData,
          handleFileInputClick,
          handleCopyToPostal,
          handleCopyToPhysical,
          products, CanDoDataMaintenance, CanAdministrate
        )}
        data={data}
        spacing={2}
        dispatch={dispatch}
        key={data.CustomerId}
        errors={formErrors}
      />
      <FormFooter
        footerText={`ProductId: ${data?.ProductId || ""}, CustomerId: ${data?.CustomerId || ""},`}
        buttons={GetFormButtons(data, actions, editCheck, CanDoDataMaintenance)} />
      <AppDialog
        open={openManagement}
        title={`Customer Management  Customer ${data.CustomerId}`}
        maxWidth="100vw"
        handleClose={() => setOpenManagement(false)}
      >
        <ManageCustomer unit={data} />
      </AppDialog>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return { CustomerAction: bindActionCreators(CustomerAction, dispatch) };
};

export default connect(null, mapDispatchToProps)(EditCustomer);

const GetFormButtons = (data, { save, manage }, editCheck, CanDoDataMaintenance) => [
  !!editCheck && {
    variant: "contained",
    label: "Manage",
    onClick: manage,
  },
  !!editCheck && {
    variant: "contained",
    label: "Save New",
    onClick: save,
    startIcon: <NewIcon />,
  },
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Save",
    onClick: save,
    startIcon: <Check />,
  },
];

function FormSections(
  editCheck,
  custId,
  dropdownData,
  handleFileInputClick,
  handleCopyToPostal,
  handleCopyToPhysical,
  products, CanDoDataMaintenance, CanAdministrate
) {
  return [
    {
      fields: [
        //Customer & Product Details
        {
          name: "Customer",
          label: "Name",
          xsCol: 6,
        },
        {
          name: "ProductId",
          label: "Product",
          type: "dropdown",
          required: "true",
          selectOptions: (products || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
          xsCol: 3,
        },
        {
          name: "CustomerArchived",
          label: "Archived - no website",
          type: "switch",
          xsCol: 3,
        },
        { name: "AccountNumber", label: "Account", xsCol: 3 },
        { name: "SapCustomer", label: "Acc Name", xsCol: 3 },
        {
          name: "ParentId",
          label: "Parent",
          type: "dropdown",
          required: "true",
          xsCol: 3,
          selectOptions: (dropdownData.Parents || []).map((x) => ({
            value: x.ParentId,
            text: x.Parent,
          })),
        },
        {
          name: "BlockCustomerEvaluationsFrom",
          label: "Limited From",
          xsCol: 3,
          type: "date",
        },
        {
          name: "ExportOptionId",
          label: "Exports",
          type: "dropdown",
          required: "true",
          xsCol: 3,
          selectOptions: (dropdownData.ExportOptions || []).map((x) => ({
            value: x.ExportOptionId,
            text: x.ExportOption,
          })),
        },

        {
          name: "CmsOwnerId",
          label: "CMS Owner",
          type: "dropdown",
          xsCol: 3,
          selectOptions: (dropdownData.Users || []).map((x) => ({
            value: x.UserId,
            text: x.FullName,
          })),
        },
        {
          name: "OwnerToAllSites",
          label: "Owner To All Sites",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "IsShowLimitsOnReport",
          label: "Show Test Limits on Report",
          type: "switch",
          xsCol: 3,
        },
        {
          name: "AlternativeNames",
          label: "Known As",
          xsCol: 6,
          type: "textarea",
        },
        { name: "CustomerNotes", label: "Comment", xsCol: 6, type: "textarea" },
        {
          xsCol: 12,
          component: function () {
            return <SectionTitle title="Additional Information" />;
          },
        },
      ],
    },

    //Address Details

    {
      xsCol: 6,
      fields: [
        { name: "PhysicalStreet1", label: "Physical Address 1", xsCol: 12 },
        { name: "PhysicalStreet2", label: "Address 2", xsCol: 12 },
        { name: "PhysicalCity", label: "Town", xsCol: 12 },
        { name: "PhysicalPostCode", label: "Postcode", xsCol: 6 },
        { name: "PhysicalState", label: "State", xsCol: 6 },
        {
          name: "PhysicalCountry",
          label: "Country",
          type: "dropdown",
          selectOptions: (dropdownData.Countries || []).map((x) => ({
            value: x.Country,
            text: x.Country,
          })),
          xsCol: 12,
        },
      ],
    },
    {
      xsCol: 6,
      fields: [
        { name: "PostalStreet1", label: "Postal Address 1", xsCol: 12 },
        { name: "PostalStreet2", label: "Address 2", xsCol: 12 },
        { name: "PostalCity", label: "Town", xsCol: 12 },
        { name: "PostalPostCode", label: "Postcode", xsCol: 6 },
        { name: "PostalState", label: "State", xsCol: 6 },
        {
          name: "PostalCountry",
          label: "Country",
          type: "dropdown",
          selectOptions: (dropdownData.Countries || []).map((x) => ({
            value: x.Country,
            text: x.Country,
          })),
          xsCol: 12,
        },
      ],
    },
    CanAdministrate && {
      xsCol: 7,
      fields: [
        {
          variant: "outlined",
          label: "Report Logo",
          xsCol: 3,
          component: function () {
            return (
              <Button
                startIcon={<ReportLogoIcon />}
                variant="outlined"
                onClick={handleFileInputClick}
                disabled={!CanDoDataMaintenance}
              >
                Report Logo
              </Button>
            );
          },
        },
        {
          variant: "outlined",
          label: "Report Logo",
          xsCol: 9,
          component: function () {
            return (
              <Typography style={{ marginTop: 7 }}>
                Image requirements: JPEG, 100px to 1000px high, e.g. 200 x 100
              </Typography>
            );
          },
        },
      ],
    },
  ];
}
