import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import SwitchInput from "../../../components/SwitchInput";
import useFetch from "../../../hooks/useFetch";
import QuickMergeFluidTable from "./QuickMergeModelTable";
import QuickMergeToModelTable from "./QuickMergeToModelTable";
import { SaveIcon, } from "../../../icons/ButtonIcons";
import { useRoot, useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";

export default function QuickMergeModel() {

  const { get, post } = useFetch();
  const { showToast } = useToast();
  const { CanDoDataMaintenance } = useRoot();

  const [modelData, setModelData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedToFluidRows, setSelectedToFluidRows] = useState([]);
  //call api for loading data
  useEffect(function () {
    document.title = "Quick Merge Model";
    getModelsAllData();
  }, []);

  async function getModelsAllData() {
    const response = await get(FLEET_APIS.UNIT_MODELS_ALL);
    if (response?.length > 0) {
      setModelData(response);
    }
  }

  const rowsSelectedHandler = (selection) => {
    setSelectedRows([...selection]);
  };
  const rowToFluidSelectedHandler = (selection) => {
    setSelectedToFluidRows(selection);
  };

  const [isKomatsuChecked, setIsKomatsuChecked] = useState(false);
  function onFilterKomatsuChange(e) {
    setIsKomatsuChecked(e.target.checked);
  }
  const filteredData = useMemo(
    function () {
      return isKomatsuChecked
        ? modelData.filter((x) => x.UnitMake === "KOMATSU")
        : modelData;
    },
    [modelData, isKomatsuChecked]
  );
  async function saveClicked() {
    await post(FLEET_APIS.MERGE_UNIT_MAKES, {
      FromUnitMakeId: selectedRows?.map((unit) => unit.UnitMakeId),
      ToUnitMakeId: selectedToFluidRows.UnitMakeId,
      Screen: "Merge Unit Makes",
    });
    setSelectedToFluidRows([]);
    setSelectedRows([]);
    await getModelsAllData();
    showToast(appResources.SELECTED_FLUID_UPDATED_SUCCESSFULLY_MSG);
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {filteredData?.length}</b>
          </Typography>
          <QuickMergeFluidTable
            rowsSelected={rowsSelectedHandler}
            data={filteredData}
          />
        </Grid>
        {CanDoDataMaintenance && <Grid item xs={4} mt={2}>
          <Card sx={{ p: 1, height: 745 }}>
            {selectedRows?.length > 0 ? (
              <>
                <Typography>Please select both From and To models</Typography>
                <Grid item sx={{ my: (2) }}>
                  <SwitchInput
                    label="Filter to Komatsu"
                    value={isKomatsuChecked}
                    onChange={onFilterKomatsuChange}
                  />
                </Grid>
                <Typography>From Model:</Typography>
                {selectedRows &&
                  selectedRows?.map((item, index) => (
                    <Typography
                      variant="body2"
                      sx={{ m: (1) }}
                      key={index}
                    >
                      {index + 1}. {item.UnitModel}
                    </Typography>
                  ))}

                <Typography>To Model:</Typography>
                <Typography variant="body2" sx={{ m: (1) }}>
                  {selectedToFluidRows.UnitModel}
                </Typography>

                <Button
                  startIcon={<SaveIcon />}
                  onClick={saveClicked}
                  disabled={!selectedToFluidRows.UnitModel}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Typography>Please select both From and To models</Typography>
                <Grid item sx={{ my: (2) }}>
                  <SwitchInput
                    label="Filter to Komatsu"
                    value={isKomatsuChecked}
                    onChange={onFilterKomatsuChange}
                  />
                </Grid>
              </>
            )}
          </Card>
        </Grid>}
        <Grid item xs={4}>
          <Typography variant="subtitle2">
            Total Records: <b> {modelData?.length}</b>
          </Typography>
          <QuickMergeToModelTable
            rowSelected={rowToFluidSelectedHandler}
            data={modelData}
          />
        </Grid>
      </Grid>
    </>
  );
}
