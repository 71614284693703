import { Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import { Edit } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import AppDialog from "../../../components/AppDialog";
import EditSite from "../../../forms/EditSite";
import { useState } from "react";

export default function SiteAddress({ data }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections}
          data={data}
          key={data && data.SiteId}
        />
        <FormFooter buttons={GetFormButtons(setOpen)} />
      </Card>

      <AppDialog
        open={open}
        title="Edit Site"
        maxWidth="lg"
        handleClose={() => setOpen(false)}
      >
        <EditSite
          cancel={() => setOpen(false)}
          siteId={data.SiteId}
          customerId={data?.CustomerId}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (setOpen) => [
  { variant: "contained", label: "Edit", startIcon: <Edit />, onClick: (e) => { setOpen(true); } },
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Site Address" />;
        },
      },
    ],
  },
  {
    xsCol: 6,
    fields: [
      { name: "PhysicalStreet1", label: "Physical Address 1", xsCol: 12 },
      { name: "PhysicalStreet2", label: "Address 2", xsCol: 12 },
      { name: "PhysicalCity", label: "Town", xsCol: 12 },
      { name: "PhysicalPostCode", label: "Postcode", xsCol: 6 },
      { name: "PhysicalState", label: "State", xsCol: 6 },
      { name: "PhysicalCountry", label: "Country", xsCol: 12 },
    ],
  },
  {
    xsCol: 6,
    fields: [
      { name: "PostalStreet1", label: "Physical Address 1", xsCol: 12 },
      { name: "PostalStreet2", label: "Address 2", xsCol: 12 },
      { name: "PostalCity", label: "Town", xsCol: 12 },
      { name: "PostalPostCode", label: "Postcode", xsCol: 6 },
      { name: "PostalState", label: "State", xsCol: 6 },
      { name: "PostalCountry", label: "Country", xsCol: 12 },
    ],
  },
];
