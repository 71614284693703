import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { FormHelperText, InputLabel, Typography } from "@mui/material";
import { Fragment } from "react";

export default function TextBoxGroup({
  id,
  name,
  label,
  value,
  defaultValue,
  onChange,
  errorMsg,
  buttons,
  readOnly,
  sx,
  labelColor
}) {
  return (
    <>
      <InputLabel htmlFor={id} sx={{ color: labelColor || "", padding: 0, margin: 0 }}>{label}</InputLabel>
      <Paper
        variant="outlined"
        sx={{
          p: "0 6px 0 0",
          height: "30px",
          display: "flex",
          alignItems: "center",
          borderRadius: "0px",
          borderWidth: "0px 0px 1px 0px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <InputBase
          sx={{ flex: 1, "& .MuiInputBase-input": { p: "5px 9px" }, }}
          id={id}
          name={name}
          defaultValue={defaultValue}
          value={value}
          onChange={(e) => {
            setTimeout(function () {
              onChange(e);
            }, 0);
          }}
          readOnly={readOnly}
        />
        {(buttons || []).map((x, i) => {
          return (
            <Fragment key={i}>
              <Divider sx={{ height: 24, m: 0.5 }} orientation="vertical" />
              <IconButton type="button" onClick={x.onClick}>
                {x.icon}
                <Typography sx={{ color: "#000" }}>{x.label}</Typography>
              </IconButton>
            </Fragment>
          );
        })}
      </Paper>

      <FormHelperText error={!!errorMsg}>{errorMsg || ""}</FormHelperText>
    </>
  );
}
