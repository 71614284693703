import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import SearchUnitModels from "./SearchUnitModel";
import { FLEET_APIS } from "../../../common/apis";

const steps = ["Select action", "Select replacement Model", "Action summary"];

export default function ManageUnitModels({
  SelectedUnit: Unit,
  refreshGrid,
  setManageUnitOpen,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [SelectedUnit, setSelectedUnit] = React.useState({});
  const [skipped, setSkipped] = React.useState(new Set());
  const [processResponse, setProcessResponse] = React.useState(
    "Process completed successfully."
  );

  const [selectedOption, setSelectedOption] = React.useState("deleteOriginal");
  const { post } = useFetch();

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 2) {
      PerformOperation();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };
  async function PerformOperation() {
    if (selectedOption === "deleteOriginal") {
      const data = {
        OriginalUnitMakeId: Unit.UnitMakeId,
        ReplacementUnitMakeId: SelectedUnit.UnitMakeId,
        ActionToTake: 2,
        Screen: "Unit Model Management",
      };
      const result = await post(FLEET_APIS.MANAGE_UNIT, data);
      if (result.length > 2) {
        setProcessResponse(result);
      }
    } else {
      const data = {
        OriginalUnitMakeId: Unit.UnitMakeId,
        ReplacementUnitMakeId: SelectedUnit.UnitMakeId,
        ActionToTake: 1,
        Screen: "Unit Model Management",
      };
      const result = await post(FLEET_APIS.MANAGE_UNIT, data);
      if (result.length > 2) {
        setProcessResponse(result);
      }
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedUnit({});
  };

  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
    //setSelectedOption(undefined)
  };
  const handleReset = () => {
    setActiveStep(0);
    setManageUnitOpen(false);
    refreshGrid();
  };

  // Table's Styling
  const tableCol2 = {
    background: "lightblue",
    color: "blue",
  };
  const tableCol3 = {
    background: "lightgreen",
    color: "green",
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="deleteOriginal"
              name="manageAction"
              //value={selectedOption}
              onChange={handleRadioGroupChange}
            >
              <FormControlLabel
                value="deleteOriginal"
                control={<Radio />}
                label="Replace all instances of the Model with a different one, and DELETE the original fluid."
              />
              <FormControlLabel
                value="keepOriginal"
                control={<Radio />}
                label="Replace all instances of the Model with a different one, but KEEP the original fluid."
              />
            </RadioGroup>
          </FormControl>
        );
      case 1:
        return <SearchUnitModels rowSelected={(row) => setSelectedUnit(row)} />;
      case 2:
        return (
          <>
            <TableContainer component={Paper} sx={{ mt: 6, mb: 4 }}>
              <Table
                sx={{ minWidth: 400, textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                    {/* Col 3 */}
                    <TableCell sx={tableCol3}>Replacement</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Make
                    </TableCell>
                    <TableCell sx={tableCol2}>{Unit?.UnitMake || ""}</TableCell>
                    <TableCell sx={tableCol3}>
                      {SelectedUnit?.UnitMake}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Model
                    </TableCell>
                    <TableCell sx={tableCol2}>
                      {Unit?.UnitModel || ""}
                    </TableCell>
                    <TableCell sx={tableCol3}>
                      {SelectedUnit?.UnitModel}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Verified
                    </TableCell>
                    <TableCell sx={tableCol2}>{Unit?.Verified || ""}</TableCell>
                    <TableCell sx={tableCol3}>
                      {SelectedUnit?.Verified || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      UnitMakeId
                    </TableCell>
                    <TableCell sx={tableCol2}>
                      {Unit?.UnitMakeId || ""}
                    </TableCell>
                    <TableCell sx={tableCol3}>
                      {SelectedUnit?.UnitMakeId || ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <Box
      sx={{
        padding: "10px",
        minWidth: "70vw",
        minHeight: "23vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stepper activeStep={activeStep} sx={{ mr: 2 }}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: "-20%" }}>{processResponse}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button onClick={handleReset} sx={{ ml: "auto", mr: 1 }}>
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={ClassNames.instructions} sx={{ mt: 4 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              onClick={handleNext}
              disabled={activeStep === 1 && !SelectedUnit?.UnitMakeId}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
