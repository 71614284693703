import { memo } from "react";
import GetStepNames from "../../../common/GetStepNames";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import { Typography } from "@mui/material";
import DropdownMenu from "../../../components/DropdownMenu";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default memo(function SamplesTable({
  data,
  rowSelected,
  sx,
  actions,
  rowActionHandler,
}) {
  const options = {
    opacityCheck: true,
    columns: GetColumns(actions, { rowActionHandler }),
    dataSource: data,
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    rowStylingHandler: "sample",
    cellStylingHandler: "sampleBackGroundWhite",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    minHeight: 20,
    heightValue: 100,
  };
  return <DataTable {...options} sx={sx} />;
});

const GetColumns = (actions, { rowActionHandler }) => [
  {
    headerName: "Sample Number",
    field: "SampleNumber",
    width: 123,
    renderCell: function ({ row }) {
      return (
        <>
          {(actions || []).map(function (ac, i) {
            return (
              <DataGridAction
                key={i}
                row={row}
                onClick={ac.action}
                label={ac.label}
                startIcon={ac.startIcon}
              />
            );
          })}
          <DropdownMenu
            options={Options}
            onItemSelect={(option) => rowActionHandler(row, option)}
          />
          <Typography paddingTop={0.25}>{row.SampleNumber}</Typography>
        </>
      );
    },
  },
  {
    headerName: "Step",
    field: "Step",
    valueFormatter: ({ value }) => GetStepNames(value).short,
    width: 73,
  },
  {
    headerName: "Auto Eval",
    field: "IsAutoEvaluated",
    valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    width: 75,
  },
  {
    headerName: "Test Set",
    field: "TestSet",
    width: 140,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Status",
    field: "AlertStatus",
    width: 70,
  },
  {
    headerName: "Product",
    field: "Product",
    width: 75,
  },
  {
    headerName: "Customer",
    field: "Customer",
    width: 90,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Site",
    field: "Site",
    width: 79,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Unit",
    field: "Unit",
    width: 85,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Model",
    field: "UnitMake",
    width: 100,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Serial",
    field: "Serial",
    width: 75,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Component",
    field: "Component",
    width: 120,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Fluid",
    field: "FluidFormatted",
    width: 180,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Capacity",
    field: "Capacity",
    width: 75,
  },
  {
    headerName: "Label",
    field: "Label",
    width: 85,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Hold",
    field: "HoldName",
    width: 85,
    renderCell: renderCellExpand,
  },
];

const Options = [
  { label: "View Sample Report", name: "view-sample-report" },
  { label: `Edit Sample `, name: "edit-sample" },
  { label: "Unit Hour History", name: "unit-hour-history" },
  {
    label: "Contacts",
    name: "contacts",
    items: [
      { label: "Primary Contacts", name: "primary-contacts-overview" },
      { label: "Report Contacts", name: "report-contacts" },
      { label: "Website Contacts", name: "website-contacts" },
    ],
  },
  { label: "Critical Log", name: "critical-log" },
  { label: "Hold Management", name: "hold-management" },
  { label: "View in Quick Details", name: "view-in-quick-details" },
];
// Options={{
//   ...Options,
//   [Options[1].label]: "Edit Sample " + row.SampleNumber,
//   // ({ ...filter, [e.target.name]: e.target.value });
// }}
