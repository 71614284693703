import { Edit, Search } from "@mui/icons-material";
import { Card, Typography, Grid } from "@mui/material";
import { useMemo, useState, useReducer, useEffect } from "react";
import AppDialog from "../../../components/AppDialog";
import { TRAY_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import RegistrationsTable from "./RegistrationsTable";
import EditRegistration from "./EditRegistration";
import { ClearIcon } from "../../../icons/ButtonIcons";
import { useRoot } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";

export default function SearchRegistration({
  actions,
  getRegistration,
  searchRegClickHandler,
  screen,
}) {
  const { post } = useFetch();
  const { allProducts, CanRegister } = useRoot();
  const [data, setData] = useState([]);
  const [editRegId, setEditRegId] = useState(0);
  const [filterData, dispatch] = useReducer(
    RegistrationFilterReducer,
    RegistrationFilterDefaults
  );

  useEffect(() => {
    document.title = "Registration Search";
  }, []);
  const formActions = {
    search: async function () {
      const temp = await post(TRAY_APIS.FILTER_REGISTRATIONS, filterData);
      setData(temp);
    },
    clear: function (e) {
      dispatch({ type: FormActions.RESET_FORM });
    },
  };

  const tableActions = useMemo(
    () => [
      {
        action: function (row) {
          setEditRegId(row.SIF);
        },
        label: "Select",
        startIcon: <Edit />,
      },
      ...(actions || []),
    ],
    []
  );

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Card sx={{ p: 1 }}>
          <Form
            sections={GetFormSections(allProducts, filterData)}
            data={filterData}
            spacing={2}
            dispatch={dispatch}
            key={filterData._ts_key || ""}
          />
          <FormFooter
            buttons={GetFormButtons(filterData, formActions, CanRegister)}
            gmt={2}
          />
          {data?.length < 1 && (
            <Typography sx={{ color: "red", mt: -2.5 }}>
              {appResources.NO_REGISTRATION_FOUND}
            </Typography>
          )}
        </Card>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">
          Total Records: <b> {data?.length}</b>
        </Typography>
        <RegistrationsTable
          screen={screen}
          data={data}
          actions={tableActions}
        />
      </Grid>
      <AppDialog
        open={!!editRegId}
        handleClose={() => setEditRegId(0)}
        title="Edit Registration"
        maxWidth="xl"
      >
        <EditRegistration
          regId={editRegId}
          setEditRegId={setEditRegId}
          getRegistration={getRegistration}
          searchRegClickHandler={searchRegClickHandler}
        />
      </AppDialog>
    </Grid>
  );
}

const GetFormButtons = (data, { search, clear }, CanRegister) => [
  {
    variant: "outlined",
    disabled:
      (data?.Customer === undefined || data?.Customer === "") &&
      (data?.Site === undefined || data?.Site === "") &&
      (data?.Unit === undefined || data?.Unit === "") &&
      (data?.Serial === undefined || data?.Serial === "") &&
      (data?.Component === undefined || data?.Component === "") &&
      (data?.SIF === undefined || data?.SIF === ""),
    label: "Clear",
    onClick: clear,
    startIcon: <ClearIcon />,
  },
  {
    hidden: !CanRegister,
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

function GetFormSections(allProducts, filterData) {
  return [
    {
      fields: [
        {
          name: "ProductId",
          label: "Product",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (allProducts || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
          sx: {
            [`.MuiSelect-select`]: {
              backgroundColor:
                allProducts?.filter(
                  (x) => x.ProductId === filterData?.ProductId
                )[0]["Colour"] || "",
              color: filterData.ProductId === 0 ? "" : "white",
              [`.MuiSvgIcon-root-MuiSelect-icon`]: {
                color: filterData.ProductId === 0 ? "" : "white",
              },
            },
            [`.MuiSelect-icon`]: {
              color: filterData.ProductId === 0 ? "" : "white",
            },
          },
        },
        {
          name: "Customer",
          label: "Customer",
          xsCol: 2,
        },
        {
          name: "Site",
          label: "Site",
          xsCol: 2,
        },
        {
          name: "Unit",
          label: "Unit",
          xsCol: 2,
        },
        {
          name: "Serial",
          label: "Serial",
          xsCol: 2,
        },
        {
          name: "Component",
          label: "Component",
          xsCol: 2,
        },
        {
          name: "SIF",
          label: "Registration",
          xsCol: 2,
        },
        {
          name: "IncludeRegistered",
          label: "Include Registered or Deleted",
          type: "switch",
        },
        {
          name: "UseStartsWith",
          label: "Starts With Search",
          type: "switch",
        },
        // {
        //   alignSelf: "flex-end",
        //   component: function ({ data, dispatch }) {
        //     return (
        //       <Button sx={{ float: "right" }} >
        //         Set SMR
        //       </Button>
        //     );
        //   },
        // },
        // {
        //   alignSelf: "flex-end",
        //   component: function ({ data, dispatch }) {
        //     return (
        //       <Button                            >
        //         Set SMR
        //       </Button>
        //     );
        //   },
        // },
      ],
    },
  ];
}

function RegistrationFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...RegistrationFilterDefaults, _ts_key: Date.now() };
  }
  return state;
}

const RegistrationFilterDefaults = {
  IncludeRegistered: false,
  UseStartsWith: false,
  ProductId: 0,
};
