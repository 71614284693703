import { useTheme } from "@emotion/react";
import { CheckCircle, Close, PlaylistAdd, Search, } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import useMasterData from "../../../hooks/useMasterData";
import { DETAILS_APIS } from "../../../common/apis";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import AppDialog from "../../../components/AppDialog";
import NewFluid from "./NewFluid";
import useFetch from "../../../hooks/useFetch";
import { ValidateFluidSearch } from "../../../validation/schemas/FluidSearchSchema";
import { EditIcon } from "../../../icons/ButtonIcons";

export default function SearchFluid({
  cancel,
  actions,
  rowSelected,
  rowManageSelected,
  fetchFluidsDetails,
  registrationSearchFluid,
}) {
  const curTheme = useTheme();
  const [formErrors, setFormErrors] = useState({});
  const { getMasterData } = useMasterData();
  const [fluidsData, setFluidsData] = useState([]);
  const [selectedSearchFluidRow, setSelectedSearchFluidRow] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newFluidOpen, setNewFluidOpen] = useState(false);
  const [masterData, setMasterData] = useState({});
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(
    SearchFluidsFilterReducer,
    rowManageSelected
  );

  const tblOptions = {
    columns: GetColumns(actions, selectClick, editClick),
    dataSource: fluidsData,
    rowIdProperty: "FluidId",
    isDisableColumnFilter: true,
    // rowsSelected: function (rows) {
    //   rowSelected && rowSelected(rows[0]);
    // },
  };

  const formActions = {
    addNew: function () {
      setDialogOpen(true);
    },

    search: async function () {
      setFormErrors({});
      ValidateFluidSearch(data).then(async function ({ data: req, errors }) {
        if (req) {
          const tempFluidsData = await post(DETAILS_APIS.SEARCH, data);
          setFluidsData(tempFluidsData);
        }
        if (errors) {
          setFormErrors(errors);
        }
      });
    },
    clear: function () {
      dispatch({
        type: FormActions.RESET_FORM,
        payload: {
          FluidTypeId: 1,
          key: Date.now(),
        },
      });
    },
  };

  useEffect(function () {
    (async function () {
      const resp = await getMasterData("FluidTypes");
      setMasterData(resp);
    })();
  }, []);
  function selectClick(row) {
    rowSelected(row);
  }
  function editClick(row) {
    setSelectedSearchFluidRow(row);
    setNewFluidOpen(true);
  }
  async function getFluidControlsData() {
    if (registrationSearchFluid?.FluidId) {
      const response = await get(
        `${DETAILS_APIS.GET_FLUID}?fluidId=${registrationSearchFluid?.FluidId}`
      );
      if (response?.length > 0) {
        dispatch({
          payload: {
            ...response[0],
            key: Date.now(),
          },
          type: EventActions.LOAD_DATA,
        });
        setFluidsData(response);
      }
    } else {
      dispatch({
        payload: {
          FluidTypeId: 1,
          key: Date.now(),
        },
        type: EventActions.LOAD_DATA,
      });
    }
  }
  useMemo(() => {
    getFluidControlsData();
  }, [registrationSearchFluid]);
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(data, masterData)}
            data={data}
            dispatch={dispatch}
            key={data?.key}
            errors={formErrors}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          {/* {fluidsData.length > 0 && (
            <Typography sx={{ color: "green", mb: 1 }}>
              {fluidsData.length} fluid were found. To add a new fluid, add ALL
              the available details and then SAVE
            </Typography>
          )} */}

          {fluidsData?.length > 0 ? (
            <Typography variant="subtitle2">
              Total Records: <b>{fluidsData.length}</b>
            </Typography>
          ) : (
            <Typography variant="subtitle2">
              No fluids were found. To add a new fluid, add ALL the available
              details and then SAVE
            </Typography>
          )}

          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={!!dialogOpen}
        title="New Fluid"
        maxWidth="md"
        handleClose={() => setDialogOpen(false)}
      >
        <NewFluid
          setDialogOpen={setDialogOpen}
          rowManageSelected={rowManageSelected}
          fetchFluidsDetails={fetchFluidsDetails}
        />
      </AppDialog>

      <AppDialog
        open={newFluidOpen}
        title="Edit Fluid"
        maxWidth="md"
        handleClose={() => setNewFluidOpen(false)}
      >
        <NewFluid
          setNewFluidOpen={setNewFluidOpen}
          rowManageSelected={selectedSearchFluidRow}
          getFluidControlsData={getFluidControlsData}
          isEdit={true}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, { addNew, clear, search }) => [
  {
    variant: "outlined",
    label: "New Fluid",
    onClick: addNew,
    startIcon: <PlaylistAdd />,
  },
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <Close />,
  },
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

function GetColumns(actions, selectClick, editClick) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      flex: 1,
      hide: !actions || !actions.length,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
            />
          );
        });
      },
    },
    {
      headerName: "Select",
      field: "Select",
      hide: actions && actions.length,
      sortable: false,
      width: 80,

      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={selectClick}
              startIcon=<CheckCircle />
              label="Select"
            />
          </>
        );
      },
    },
    {
      headerName: "Edit",
      field: "edit",
      hide: actions && actions.length,
      sortable: false,
      width: 80,

      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={editClick}
              startIcon=<EditIcon />
              label="Edit"
            />
          </>
        );
      },
    },
    {
      headerName: "Make",
      field: "FluidLevel1",
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Type",
      field: "FluidLevel2",
      minWidth: 120,
      sortable: false,
    },
    {
      headerName: "Grade",
      field: "FluidLevel3",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Display",
      field: "FluidFormatted",
      minWidth: 400,
      sortable: false,
    },
    {
      headerName: "Category",
      field: "FluidType",
      minWidth: 130,
      flex: 1,
      sortable: false,
    },
  ];
}

function GetFormSections(_, { FluidTypes }) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          group: [
            {
              xsCol: 3,
              name: "FluidTypeId",
              label: "Type",
              type: "dropdown",
              selectOptions: (FluidTypes || []).map((x) => ({
                value: x.FluidTypeId,
                text: x.FluidType,
              })),
            },
            {
              xsCol: 6,
              name: "FluidFull",
              label: "Fluid",
            },
          ],
        },
        {
          xsCol: 3,
          name: "FluidLevel1",
          label: "Make",
        },
        {
          xsCol: 3,
          name: "FluidLevel2",
          label: "Type",
        },
        {
          xsCol: 3,
          name: "FluidLevel3",
          label: "Grade",
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
