import { FLEET_APIS } from "../../../common/apis";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";

export default function TemplatesModelTable({ data, refreshModels }) {
  const options = {
    columns: Columns(deleteClick),
    dataSource: data,
    rowIdProperty: "TemplateUnitMakeId",
    isDisableColumnFilter: true,
    sx: {
      height: 600,
    },
  };
  const { get } = useFetch();
  return <DataTable {...options} />;

  async function deleteClick(row) {
    await get(
      `${FLEET_APIS.ADD_DELETE_TEMPLATE_MODEL}?TemplateId=${row.TemplateId}&UnitMakeId=${row.UnitMakeId}&IsDelete=true&Screen=Machine Templates`
    );
    refreshModels(row);
  }
}

function Columns(deleteClick) {
  return [
    {
      headerName: "Make",
      field: "UnitMake",
      minWidth: 130,
    },
    {
      headerName: "Model",
      field: "UnitModel",
      minWidth: 130,
    },
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 60,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },
  ];
}
