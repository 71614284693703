import { COMPONENT_ACTIONS } from '../../common/ActionTypes';

const INITIAL_STATE = {
    componentDetails: {},
};

export default (state, action) => {
    if (!state) state = INITIAL_STATE;

    switch (action.type) {
        case COMPONENT_ACTIONS.COMPONENT_DETAILS:
            {
                return {
                    ...state,
                    componentDetails: { ...action.payload },
                };
            }
        default:
            return state;
    }
};