import { CheckCircle, } from "@mui/icons-material";
import { Grid, Button, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import useFetch from "./../../../../hooks/useFetch";
import Form, { FormActions } from "./../../../../components/Form";
import DataTable, { DataGridAction } from "./../../../../components/DataTable";
import { CONTACTS_APIS } from "./../../../../common/apis";
import { AddIcon } from "../../../../icons/ButtonIcons";
import AppDialog from "../../../../components/AppDialog";
import SearchDetails from "../../../quick-details/SearchDetails";
import AlertDialog from "../../../../components/AlertDialog";
import appResources from "../../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../../components/DatatableCellExpand";
import { useRoot } from "../../../../rootContext";

export default function ContactPrimary({ contactId }) {
  const { CanDoDataMaintenance } = useRoot();
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(SearchMappingFilterReducer, {});
  const [contactsPrimaryData, setContactsPrimaryData] = useState([]);
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  const [propValue, setPropValue] = useState();
  const [rowData, setRowData] = useState();
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
  });

  const filteredData = useMemo(
    () =>
      data
        ? (contactsPrimaryData || []).filter((x) => {
            let filterMatch = true;
            if (data.Filter) {
              const filterLower = data.Filter.toLowerCase();
              filterMatch =
                x.Customer?.toLowerCase().includes(filterLower) ||
                x.Site?.toLowerCase().includes(filterLower);
            }
            return filterMatch;
          })
        : contactsPrimaryData,
    [data, contactsPrimaryData]
  );
  const options = {
    columns: GetColumns(deleteClick, CanDoDataMaintenance),
    dataSource: filteredData || [],
    rowIdProperty: "SiteId",
    isDisableColumnFilter: true,
    cellStylingHandler: "contactsPrimaryMappings",
    sx: { height: 300 },
  };
  useEffect(() => {
    getData();
  }, [contactId]);

  async function getData() {
    const contactExportResponse = await get(
      `${CONTACTS_APIS.CONTACT_PRIMARIES_ASSIGNED}?contactId=${
        contactId ? contactId : 0
      }`
    );
    setContactsPrimaryData(contactExportResponse);
  }

  function addSite() {
    setOpenSelectCustomer(true);
    setPropValue(4);
  }
  function addCustomer() {
    setOpenSelectCustomer(true);
    setPropValue(3);
  }
  function deleteClick(row) {
    setDialog({
      open: true,
      message: appResources.DELETE_CONFIRMATION_MSG,
    });
    setRowData(row);
  }
  async function deleteClickAlert() {
    await get(
      `${CONTACTS_APIS.DELETE_CONTACT_PRIMARY}?ContactId=${rowData.ContactId}&SiteId=${rowData.SiteId}&Screen=Contact Primary Mappings`
    );
    await getData();
    setDialog((prevState) => ({ ...prevState, open: false }));
  }
  return (
    <>
      <Grid container sx={{ minWidth: 1250 }}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(addSite, addCustomer, CanDoDataMaintenance)}
            data={contactsPrimaryData}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ marginLeft: "5px" }}>
            {contactsPrimaryData?.length > 0 && (
              <b>Total Records: {filteredData && filteredData.length}</b>
            )}{" "}
            {contactsPrimaryData?.length === 0 && (
              <p style={{ color: "red" }}>
                The contact display test is not primary contact for any sites
              </p>
            )}
          </Typography>

          <DataTable {...options} />
        </Grid>
      </Grid>

      <AppDialog
        open={openSelectCustomer}
        title={
          propValue === 3
            ? "Customer Search"
            : propValue === 4
            ? "Site Search"
            : ""
        }
        maxWidth="lg"
        handleClose={() => setOpenSelectCustomer(false)}
      >
        <SearchDetails
          propValue={propValue}
          activeOnly={
            propValue === 3 ? "customer" : propValue === 4 ? "site" : ""
          }
          activeTab={
            propValue === 3 ? "customer" : propValue === 4 ? "site" : ""
          }
          cancel={() => setOpenSelectCustomer(false)}
          siteActions={[
            {
              action: async function (rowData) {
                await post(CONTACTS_APIS.ADD_CONTACT_PRIMARY, {
                  ContactId: contactId,
                  SiteId: rowData.SiteId,
                  Screen: "Contact Primary Mappings",
                });
                setOpenSelectCustomer(false);
                await getData();
              },
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          custActions={[
            {
              action: async function (rowData) {
                await post(CONTACTS_APIS.ADD_CONTACT_PRIMARY, {
                  ContactId: contactId,
                  CustomerId: rowData.CustomerId,
                  Screen: "Contact Primary Mappings",
                });
                setOpenSelectCustomer(false);
                await getData();
              },
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
        />
      </AppDialog>
      <AppDialog
        open={dialog.open}
        handleClose={() =>
          setDialog((prevState) => ({ ...prevState, open: false }))
        }
        title="Delete Contact"
        maxWidth="md"
      >
        <AlertDialog
          handleClose={() => {
            setDialog((prevState) => ({ ...prevState, open: false }));
          }}
          alertAction={deleteClickAlert}
          message={dialog.message}
        />
      </AppDialog>
    </>
  );
}

function GetColumns(deleteClick, CanDoDataMaintenance) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            {CanDoDataMaintenance && <DataGridAction row={row} onClick={deleteClick} label="Delete" />}
          </>
        );
      },
    },

    {
      headerName: "Product",
      field: "Product",
      minWidth: 170,
    },

    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 160,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      minWidth: 170,
      flex: 1,
    },
  ];
}

function GetFormSections(addSite, addCustomer, CanDoDataMaintenance) {
  return [
    {
      xsCol: 12,
      fields: [
        { name: "Filter", label: "Filter" },
        CanDoDataMaintenance && {
          variant: "outlined",
          label: "Add Site",
          component: function () {
            return (
              <Button
                sx={{ marginTop: "15px" }}
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={addSite}
              >
                Add Site
              </Button>
            );
          },
        },
        CanDoDataMaintenance && {
          variant: "outlined",
          label: "Add Customer",
          component: function () {
            return (
              <Button
                sx={{ marginTop: "15px" }}
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={addCustomer}
              >
                Add Customer
              </Button>
            );
          },
        },
      ],
    },
  ];
}
function SearchMappingFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  // if (action.type === FormActions.RESET_FORM) {
  //   return { _ts_key: Date.now() };
  // }
  return state;
}
