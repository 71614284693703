import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, } from "@mui/material";
import { useCallback, useEffect, useReducer, useState, } from "react"; import Form, { FormActions } from "../../../components/Form";
import useMasterData from "../../../hooks/useMasterData";
import { CheckNumber, toIsoFormat, toLocalDayDate2, toLocalDate2, } from "../../../utils";
import Constants from "../../../common/Constants";
import { useRoot, useToast, useUser } from "../../../rootContext";
import useFetch from "../../../hooks/useFetch";
import { ValidateRegistration } from "../../../validation/schemas/RegistrationSchema";
import GroupDialog from "../../../components/GroupDialog";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RegistrationFormActions, useGetFormSections, GetOverViewString, } from "./Options";
import TextBox from "../../../components/TextBox";
import appResources from "../../../app-resources/en/Resources.json";
import { TRAY_APIS } from "../../../common/apis";
import {
  AddIcon,
  ClearIcon,
  DeleteIcon,
  DeleteTrayIcon,
  NewIcon,
  RegisterIcon,
  RetrieveRegIcon,
  SearchRegistrationIcon,
  UpdateIcon,
} from "../../../icons/ButtonIcons";
import SwitchInput from "../../../components/SwitchInput";
import AlertDialog from "../../../components/AlertDialog";
import AppDialog from "../../../components/AppDialog";
import EditRegistration from "../../quick-details/registrations/EditRegistration";
import NewFluid from "../../master-data/fluids/NewFluid";

import { DialogTypes, useScreenPopups, } from "../../../components/useScreenPopups";

export default function RegistrationForm({
  sampleData,
  regData,
  compData,
  masterData,
  updateComponent,
  registrationDone,
  getRegistration,
  searchRegClickHandler,
  retrieveRegistration,
  setEditRegId,
  editRegId,
  regSampleExists,
  setRegSampleExists,
  // selectRegistration
  trayData,
  startLetter,
  setExpandTreeData,
}) {
  const user = useUser();
  const { CanRegister, CanDoDataMaintenance } = useRoot();

  const { get, post } = useFetch();
  const { getMasterData } = useMasterData();
  const [data, dispatch] = useReducer(
    RegistrationFormReducer,
    mapFields({ sampleData, regData, compData, trayData, user })
  );
  const { showToast, showWarningToast } = useToast();

  const [formErrors, setFormErrors] = useState({});
  const [dialogType, setDialogType] = useState({ open: false });
  const [isLockRegisterChecked, setIsLockRegisterChecked] = useState(false);
  const [labelValue, setLabelValue] = useState(data.Label);
  const [extraSampleValue, setExtraSampleValue] = useState(1);
  const [forTrayValue, setForTrayValue] = useState(1);
  const [moveToTrayValue, setMoveToTrayValue] = useState(1);
  const [moveFromTrayValue, setMoveFromTrayValue] = useState(1);
  const [moveToLaboratoryValue, setMoveToLaboratoryValue] = useState(1);
  const [moveFromLaboratoryValue, setMoveFromLaboratoryValue] = useState(1);
  const [alertActionName, setAlertActionName] = useState(null);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [registrationVal, setRegistrationVal] = useState("");
  const [newFluidOpen, setNewFluidOpen] = useState(false);
  const [selectedSearchFluidRow, setSelectedSearchFluidRow] = useState();
  const [testSets, setTestSets] = useState([]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F12") {
        event.preventDefault();
        if (data?.SampleNumber === undefined || data?.Step > 1) return;
        actions.register();
      }
    },
    [data]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const actions = {
    openDialog: function (type) {
      setDialogType({ type, open: true });
    },
    clear: function (type) {
      dispatch({ type: RegistrationFormActions.RESET_FORM });
    },
    updateLabel: async function () {
      if (labelValue) {
        const responseData = await get(
          `${TRAY_APIS.UPDATE_TRAY_LABEL}?Laboratory=${data.OwnerLaboratoryId}&Tray=${data.Tray}&TrayDate=${data.TrayDate}&Label=${labelValue}`
        );
        if (responseData >= 1) {
          showToast(appResources.LABEL_UPDATED_SUCCESSFULLY_MSG);
          registrationDone(data.SampleNumber);
        }
      }
    },
    deleteSample: async function () {
      if (data.Step === 1) {
        setAlertTitle("Delete Sample");
        setAlertActionName("alertDeleteSample");
        setOpenAlert(true);
        setAlertMessage(
          `${appResources.CREATE_TRAY_DELETE_LAST_SAMPLE_MSG} ${data.SampleNumber} in tray ${data.Tray} for ${data.Laboratory}?`
        );
      } else {
        showWarningToast(appResources.CANNOT_DELETE_REGISTERED_SAMPLE_MSG);
      }
    },

    deleteTray: async function () {
      setAlertTitle(`Delete Tray ${data.Tray}`);
      setAlertActionName("alertDeleteTray");
      setOpenAlert(true);
      setAlertMessage(
        `${appResources.DELETE_TRAY_CONFIRMATION_MSG} ${forTrayValue || data.Tray
        } for ${data.Laboratory}?\n ${appResources.NONE_OF_THE_SAMPLES_MSG}`
      );
    },

    addExtraSample: async function () {
      // if (extraSampleValue) {
      setAlertTitle("Add Extra Sample");
      setAlertActionName("alertAddExtraSample");
      setOpenAlert(true);
      setAlertMessage(
        `${appResources.CREATE_TRAY_ADD_EXTRA_SAMPLE_MSG} on ${toLocalDayDate2(
          data.TrayDate
        )} for ${data.Laboratory}?`
      );
    },
    moveTray: async function () {
      setAlertTitle("Move Tray");
      setAlertActionName("alertMoveTray");
      setOpenAlert(true);
      setAlertMessage(
        `${appResources.MOVE_TRAY_CONFIRMATION_MSG
        } ${moveFromTrayValue}  from ${moveFromLaboratoryValue === "1"
          ? "CMS Brisbane"
          : moveFromLaboratoryValue === "2"
            ? "CMS Perth"
            : moveFromLaboratoryValue === "3"
              ? "CMS New Castle"
              : ""
        } to tray ${moveToTrayValue} for ${moveToLaboratoryValue === "1"
          ? "CMS Brisbane"
          : moveToLaboratoryValue === "2"
            ? "CMS Perth"
            : moveToLaboratoryValue === "3"
              ? "CMS New Castle"
              : ""
        } for ${toLocalDayDate2(
          data.TrayDate
        )}\n If existing, this will push the destination and later trays to new trays and sample numbers ?`
      );
    },
    swapTray: async function () {
      setAlertTitle("Swap Tray");
      setAlertActionName("alertSwapTray");
      setOpenAlert(true);
      setAlertMessage(
        `${appResources.SWAP_TRAY_CONFIRMATION_MSG
        } ${moveFromTrayValue}  from ${moveFromLaboratoryValue === "1"
          ? "CMS Brisbane"
          : moveFromLaboratoryValue === "2"
            ? "CMS Perth"
            : moveFromLaboratoryValue === "3"
              ? "CMS New Castle"
              : ""
        } to tray ${moveToTrayValue} for ${moveToLaboratoryValue === "1"
          ? "CMS Brisbane"
          : moveToLaboratoryValue === "2"
            ? "CMS Perth"
            : moveToLaboratoryValue === "3"
              ? "CMS New Castle"
              : ""
        } for ${toLocalDayDate2(
          data.TrayDate
        )} \n This requires that both trays do exist`
      );
    },

    register: async function () {
      setFormErrors({});
      const {
        CompChangedHours,
        MeterChangedHours,
        CompRepairedHours,
        CompRepairedDate,
        CompChangedDate,
        ...restData
      } = data; // Spreading data and extracting MeterChangedHours
      const req = {
        ...restData,
        ComponentChangedAt: CompChangedHours === "" ? null : CompChangedHours,
        ComponentChangedAtDate: CompChangedDate,
        MeterChangedAt: MeterChangedHours === "" ? null : MeterChangedHours,
        ComponentRepairedAt:
          CompRepairedHours === "" ? null : CompRepairedHours,
        ComponentRepairedAtDate: CompRepairedDate,
      }; // Spreading restData and adding MeterChangedAt

      ValidateRegistration(req).then(async function ({ data: req, errors }) {
        if (req) {
          await post(TRAY_APIS.REGISTER, req);
          registrationDone(data.SampleNumber);
          return;
        }
        if (errors) {
          setFormErrors(errors);
        }
      });
    },
  };

  const actionsAlert = {
    alertAddExtraSample: async function () {
      const addedSample = await post(TRAY_APIS.ADD_EXTRA_SAMPLE, {
        TrayDate: toIsoFormat(data.TrayDate),
        // TrayDate: toLocalDate2(data.TrayDate),
        Laboratory: data.LaboratoryId,
        Tray: data.Tray,
        NoofSamples: extraSampleValue >= 1 ? extraSampleValue : 1,
        Screen: "Registration",
      });
      if (addedSample[0]?.Added) {
        showToast(appResources.EXTRA_SAMPLE_ADDED_SUCCESSFULLY_MSG);
      } else {
        showWarningToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
      registrationDone(data.SampleNumber);
    },
    alertDeleteSample: async function () {
      const sampleDeleted = await get(
        `${TRAY_APIS.DELETE_SELECTED_SAMPLE}?SampleNumber=${data.SampleNumber}&Screen=Registration`
      );
      if (sampleDeleted > 0) {
        showToast(
          `Sample ${data?.SampleNumber} for ${data.Laboratory} has been DELETED`
        );
        registrationDone(data.SampleNumber);
      } else {
        showWarningToast(appResources.SOMETHING_WENT_WRONG_MSG);
      }
    },
    alertDeleteTray: async function () {
      const deleteData = await post(TRAY_APIS.DELETE_TRAY, {
        // TrayDate: data.TrayDate.toString(),
        TrayDate: toIsoFormat(data.TrayDate),
        Laboratory: data.LaboratoryId,
        Tray: forTrayValue,
        UserId: user.UserId,
        Screen: "Registration",
      });
      if (deleteData.Deleted) {
        showToast(
          `Tray ${forTrayValue || data.Tray} for ${data.Laboratory
          } has been DELETED`
        );
        registrationDone(data.SampleNumber);
      } else {
        showWarningToast(
          `Tray ${data.Tray} for ${data.Laboratory} has NOT DELETED!`
        );
        // registrationDone(data.SampleNumber);
      }
    },
    alertMoveTray: async function () {
      const addedSample = await post(TRAY_APIS.ADD_EXTRA_SAMPLE, {
        FromLaboratoryId: moveFromLaboratoryValue,
        FromTray: moveFromTrayValue,
        ToLaboratoryId: moveToLaboratoryValue,
        ToTray: moveToTrayValue,
        TrayDate: toLocalDate2(data.TrayDate),
        Screen: "Registration",
      });
      registrationDone(data.SampleNumber);
    },
    alertSwapTray: async function () {
      const addedSample = await post(TRAY_APIS.ADD_EXTRA_SAMPLE, {
        LaboratoryId1: moveFromLaboratoryValue,
        Tray1: moveFromTrayValue,
        LaboratoryId2: moveToLaboratoryValue,
        Tray2: moveToTrayValue,
        TrayDate: toLocalDate2(data.TrayDate),
        Screen: "Registration",
      });
      registrationDone(data.SampleNumber);
    },
  };

  useEffect(
    function () {
      (async function () {
        if (data?.ComponentId) {
          const temp = await getMasterData(
            "TestSets",
            `&compId=${data.ComponentId}`
          );
          setTestSets(temp.TestSets || []);
        }
      })();
    },
    [data.ComponentId]
  );

  function selectFluid(fluid) {
    dispatch({ type: RegistrationFormActions.APPLY_FLUID, payload: fluid });
    closeDialog();
  }
  function editFluid(row) {
    setSelectedSearchFluidRow(row);
    setNewFluidOpen(true);
  }

  function selectComponent(comp) {
    updateComponent(comp.ComponentId);
  }

  const dialogOptions = useScreenPopups({
    data: data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
    selectFluid: selectFluid,
    editFluid: editFluid,
    selectComponent: selectComponent,
    setExpandTreeData: setExpandTreeData,
    startLetter: startLetter,
  });

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  function onLockRegisterChange(e) {
    setIsLockRegisterChecked(e.target.checked);
  }

  const alertFunctions = [
    actionsAlert.alertAddExtraSample,
    actionsAlert.alertDeleteSample,
    actionsAlert.alertDeleteTray,
    actionsAlert.alertMoveTray,
    actionsAlert.alertSwapTray,
  ];

  const selectedFunction = alertFunctions.filter((func) => {
    return func.name === alertActionName;
  });

  const GetFormSections = useGetFormSections(
    regSampleExists,
    setRegSampleExists,
    testSets,
    data,
    actions,
    { ...masterData, user }
  );
  let regVal = "";

  const handleDateString = () => {
    let a = data?.DateSampledStr;

    if (a?.length === 6) {
      const dd = a.slice(0, 2);
      const mm = a.slice(2, 4);
      const yy = a.slice(4, 6);
      if (yy < 24) {
        let date1 = 20 + yy + "-" + mm + "-" + dd;
        // let time = moment(date1).toISOString();
        let time = toIsoFormat(date1);
        if (time !== null) {
          dispatch({
            type: RegistrationFormActions.LOAD_DATA,
            payload: { Sampled: time },
          });
        }
      } else return;
    }
  };

  useEffect(() => {
    handleDateString();
  }, [data?.DateSampledStr]);

  return (
    <>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        sx={{ mb: 1 }}
      >
        <Accordion
          sx={{ width: "100%" }}
          hidden={!data.SampleNumber || !CanRegister}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            sx={{ color: "white" }}
          >
            <Typography>Management Section</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid container item xs={9} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={4}>
                    <TextBox
                      label="Label"
                      id="my-textbox"
                      value={labelValue}
                      onChange={(e) => setLabelValue(e.target.value)}
                    />
                  </Grid>

                  {CanRegister && <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<UpdateIcon />}
                      onClick={() => actions.updateLabel()}
                      sx={{ marginTop: "14px" }}
                    >
                      Update Label
                    </Button>
                  </Grid>}
                </Grid>

                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={4}>
                    <TextBox
                      label="Tray Value"
                      type="number"
                      placeholder="For Tray"
                      value={forTrayValue}
                      onChange={(e) => setForTrayValue(e.target.value)}
                    />
                  </Grid>
                  {CanRegister && <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon sx={{ marginBottom: "3px" }} />}
                      onClick={() => actions.deleteTray()}
                      name="alertDeleteTray"
                      sx={{ marginTop: "14px" }}
                    >
                      Delete Tray
                    </Button>
                  </Grid>}

                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={4}>
                      <TextBox
                        label="Samples"
                        type="number"
                        value={extraSampleValue}
                        onChange={(e) => setExtraSampleValue(e.target.value)}
                      />
                    </Grid>
                    {CanRegister && <Grid item>
                      <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        // <AddExtraSampleIcon sx={{ marginTop: "5px" }} />}
                        onClick={() => actions.addExtraSample()}
                        name="alertAddExtraSample"
                        sx={{ marginTop: "14px" }}
                      >
                        Add Sample
                      </Button>
                    </Grid>}
                    {CanRegister && <Grid item>
                      <Button
                        variant="outlined"
                        startIcon={<DeleteIcon sx={{ marginBottom: "3px" }} />}
                        // {<DeleteTrayIcon sx={{ marginTop: "5px" }} />}
                        onClick={() => actions.deleteSample()}
                        name="alertDeleteSample"
                        sx={{ marginTop: "14px" }}
                      >
                        Delete Sample
                      </Button>
                    </Grid>}
                  </Grid>
                </Grid>

                <Grid container item xs={7} spacing={1}>
                  <Grid item xs={3}>
                    <TextBox
                      label="From Tray"
                      type="number"
                      InputProps={{
                        inputProps: { min: 1, max: 10, maxLength: 3 },
                      }}
                      placeholder="Tray"
                      value={moveFromTrayValue}
                      onChange={(e) => setMoveFromTrayValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      label="To Tray"
                      type="number"
                      InputProps={{
                        inputProps: { min: 1, max: 10, maxLength: 3 },
                      }}
                      placeholder="to"
                      value={moveToTrayValue}
                      onChange={(e) => setMoveToTrayValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      label="From Lab"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 3 },
                      }}
                      placeholder="Lab"
                      value={moveFromLaboratoryValue}
                      onChange={(e) =>
                        setMoveFromLaboratoryValue(e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextBox
                      label="To Lab"
                      type="number"
                      InputProps={{
                        inputProps: { min: 0, max: 3 },
                      }}
                      placeholder="to"
                      value={moveToLaboratoryValue}
                      onChange={(e) => setMoveToLaboratoryValue(e.target.value)}
                    />
                  </Grid>
                  {CanRegister && <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteTrayIcon sx={{ marginTop: "3px" }} />}
                      onClick={() => actions.moveTray()}
                      name="alertMoveTray"
                    >
                      Move Tray
                    </Button>
                  </Grid>}
                  {CanRegister && <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteTrayIcon sx={{ marginTop: "3px" }} />}
                      onClick={() => actions.swapTray()}
                      name="alertSwapTray"
                    >
                      Swap Trays
                    </Button>
                  </Grid>}
                </Grid>
              </Grid>

              {/* MOSTLY TEXT DATA */}
              <Grid item container xs={3}>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Displaying Tray <b>{data.Tray}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1">
                    Sample <b>{data.Sample}</b> for{" "}
                    <b>{toLocalDayDate2(data.TrayDate)}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1">
                    Tray Laboratory is <b>{data.Laboratory}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <SwitchInput
                    label="Lock Already Registered"
                    value={isLockRegisterChecked}
                    onChange={onLockRegisterChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} container justifyContent="space-between">
          <Grid item xs={8}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item sx={{ w: 10 }}>
                <TextBox
                  // ref={regNoRef}
                  value={registrationVal}
                  autoFocus
                  onChange={(e) => {
                    let a = e.target.value.replaceAll("*", "");
                    setRegistrationVal(a);
                  }}
                  placeHolder={"Registration Id"}
                  disabled={!data.SampleNumber}
                  onPaste={() => retrieveRegistration(registrationVal)}
                  autoComplete="off"
                />
              </Grid>
              {CanRegister && data.SampleNumber && (
                <Grid item>
                  <Button
                    startIcon={<RetrieveRegIcon />}
                    // onClick={() => getRegistration(regNoRef.current.value)}
                    onClick={() => retrieveRegistration(registrationVal)}
                  >
                    Retrieve Registration
                  </Button>
                </Grid>
              )}
              <AppDialog
                open={!!editRegId}
                handleClose={() => setEditRegId(0)}
                title="Edit Registration"
                maxWidth="xl"
              >
                <EditRegistration
                  regId={editRegId}
                  setEditRegId={setEditRegId}
                  getRegistration={getRegistration}
                />
              </AppDialog>
              <Grid item hidden={!data.SampleNumber}>
                <Button
                  startIcon={<SearchRegistrationIcon />}
                  onClick={searchRegClickHandler}
                >
                  Search Registration
                </Button>
              </Grid>
              {(CanRegister || CanDoDataMaintenance) && data?.UnitId && (
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<NewIcon />}
                    onClick={() =>
                      actions.openDialog(DialogTypes.ADD_NEW_COMPONENT)
                    }
                  >
                    New Component
                  </Button>
                </Grid>
              )}

              {(CanRegister || CanDoDataMaintenance) && data?.SiteId && (
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<NewIcon />}
                    onClick={() => actions.openDialog(DialogTypes.ADD_NEW_UNIT)}
                  >
                    New Unit
                  </Button>
                </Grid>
              )}
              {(CanRegister || CanDoDataMaintenance) && data?.CustomerId && (
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<NewIcon />}
                    onClick={() => actions.openDialog(DialogTypes.ADD_NEW_SITE)}
                  >
                    New Site
                  </Button>
                </Grid>
              )}
              {(CanRegister || CanDoDataMaintenance) && data?.ProductId && (
                <Grid item>
                  <Button
                    variant="outlined"
                    startIcon={<NewIcon />}
                    onClick={() =>
                      actions.openDialog(DialogTypes.ADD_NEW_CUSTOMER)
                    }
                  >
                    New Customer
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid container justifyContent="flex-end">
              {!data.ProductArchived &&
                !data.CustomerArchived &&
                !data.SiteArchived &&
                !data?.ArchivedUpper &&
                (CanRegister || CanDoDataMaintenance) &&
                data?.Sif &&
                data.Step === Constants.regStep &&
                !isLockRegisterChecked && (
                  <Grid item>
                    <Button
                      startIcon={<RegisterIcon />}
                      variant="contained"
                      onClick={actions.register}
                      sx={{ marginRight: "10px" }}
                    >
                      Register (F12)
                    </Button>
                  </Grid>
                )}
              <Grid item>
                <Button
                  startIcon={<ClearIcon />}
                  onClick={() => actions.clear()}
                  variant="outlined"
                >
                  Clear
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Form
            sections={GetFormSections}
            data={data}
            errors={formErrors}
            spacing={1}
            dispatch={dispatch}
            key={
              (data.SampleNumber || "") +
              // (data.Sif || "") +
              (data.ComponentId || "") +
              (data._ts || "")
            }
          />
        </Grid>
      </Grid>

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
      {/* Dialog Box */}
      <AppDialog
        open={openAlert}
        title={alertTitle}
        maxWidth="md"
        handleClose={() => {
          setOpenAlert(false);
        }}
      >
        <AlertDialog
          handleClose={() => {
            setOpenAlert(false);
          }}
          alertAction={selectedFunction[0]}
          message={alertMessage}
        />
      </AppDialog>
      <AppDialog
        open={newFluidOpen}
        title="Edit Fluid"
        maxWidth="md"
        handleClose={() => setNewFluidOpen(false)}
      >
        <NewFluid
          setNewFluidOpen={setNewFluidOpen}
          rowManageSelected={selectedSearchFluidRow}
        />
      </AppDialog>
    </>
  );
}

//REsponse data mapping
function mapFields({ sampleData, regData, compData, trayData, user }) {
  const sampleTransformed = {
    SampleNumber: sampleData.SampleNumber,
    Tray: sampleData.Tray,
    TrayDate: sampleData.TrayDate,
    Sample: sampleData.Sample,
    Laboratory: sampleData.Laboratory,
    Label: sampleData.Label,
    Sif: sampleData.SIF,
    ComponentId: sampleData.ComponentId,
    AddressId: sampleData.AddressId,
    Street1: sampleData.Street1,
    City: sampleData.City,
    State: sampleData.State,
    PostCode: sampleData.PostCode,
    Country: sampleData.Country,
    ComponentType: sampleData.ComponentType,
    Serial: sampleData.Serial,
    DefaultFluidId: sampleData.DefaultFluidId,
    DefaultFluidType: sampleData.DefaultFluidType,
    DefaultFluidFormatted: sampleData.DefaultFluidFormatted,
    DefaultFluid: sampleData.DefaultFluid || compData?.DefaultFluid,
    DefaultTestSetId: sampleData.DefaultTestSetId,
    LastFluidId: sampleData.LastFluidId,
    LastFluid: sampleData.LastFluid,
    LastFluidFormatted: sampleData.LastFluidFormatted,
    LastFluidType: sampleData.LastFluidType,
    LastTestSetId: sampleData.LastTestSetId,
    LastTestSet: sampleData.LastTestSet,
    DefaultTestSet: sampleData.DefaultTestSet,
    OwnerLaboratoryId: sampleData.LaboratoryId,
    LaboratoryId: sampleData.LaboratoryId,
    //IsPredelivery: "",
    IsInternalOnly: sampleData.IsInternalOnly,
    IsArchived: sampleData.SampleArchived,
    //IsDynamic: "",
    MeterReading: CheckNumber(sampleData.MeterReading),
    ComponentReading: CheckNumber(sampleData.ComponentReading),
    MeterChangedHours: sampleData.MeterChangedHours,
    MeterChangedAtDate: sampleData.MeterChangedDate,
    MeterChanged:
      sampleData.MeterChangedEventId !== null ? true : false || false,
    MeterChangedEventId: sampleData.MeterChangedEventId,
    CompChangedHours: sampleData.CompChangedHours,
    CompChangedDate: sampleData.CompChangedDate,
    ComponentChanged:
      sampleData.CompChangedEventId !== null ? true : false || false,

    ComponentChangedEventId: sampleData.ComponentChangedEventId,
    CompChangedEventId: sampleData.CompChangedEventId,
    ComponentRepairedAt: sampleData.CompRepairedHours,
    ComponentRepairedAtDate: sampleData.CompRepairedDate,
    ComponentRepaired:
      sampleData.CompRepairedEventId !== null ? true : false || false,
    ComponentRepairedEventId: sampleData.ComponentRepairedEventId,
    FluidChangedAt: sampleData.FluidChangedHours,
    FluidChangedAtDate: sampleData.FluidChangedDate,
    FluidChanged: sampleData.FluidChanged || !!sampleData.FluidChangedEventId,
    FluidChangedEventId: sampleData.FluidChangedEventId,
    FluidHours: sampleData.FluidHours,
    FluidFormatted: sampleData.FluidFormatted || compData?.DefaultFluidFormatted,
    Topup: sampleData.Topup,
    FilterChanged: sampleData.FilterChanged,
    FluidId: sampleData.FluidId || compData?.DefaultFluidId,
    MakeFluidDefault: false,
    ApplyFluidToAllSamples: false,
    TestSetId: sampleData.TestSetId,
    MakeTestSetDefault: false,
    Sampled: sampleData.Sampled || new Date(),
    Registered: sampleData.Registered,
    SampledAssumed: sampleData.SampledAssumed,
    JobNumber: sampleData.JobNumber,
    SampleOptionId: sampleData.SampleOptionId,
    //InternalNumber: "",
    ServiceTypeId: sampleData.ServiceTypeId,
    SampleTypeId: sampleData.SampleTypeId,
    UserId: user?.UserId,
    Screen: "Registration",

    Component: sampleData.Component,
    Unit: sampleData.Unit,
    Site: sampleData.Site,
    Customer: sampleData.Customer,

    UnitId: sampleData.UnitId,
    SiteId: sampleData.SiteId,
    CustomerId: sampleData.CustomerId,

    SampleNote: sampleData.SampleNotes || "",
    ComponentNote: sampleData.ComponentNotes || "",
    UnitNote: sampleData.UnitNotes || "",
    SiteNote: sampleData.SiteNotes || "",
    CustomerNote: sampleData.CustomerNotes || "",
    CustomerComments: sampleData.CustomerComments || "",

    Step: sampleData.Step,

    Product: sampleData.Product,
    ProductId: sampleData.ProductId,
    FluidTypeId: sampleData.FluidTypeId,
    Model: sampleData.UnitModelFormatted,
    Measure: sampleData.MeasureLong,
    Capacity: sampleData.Capacity,
  };

  let tempCompData = {};
  if (compData) {
    tempCompData = {
      Component: compData.Component,
      Unit: compData.Unit,
      Site: compData.Site,
      Customer: compData.Customer,

      ComponentNote: compData.ComponentNotes || "",
      UnitNote: compData.UnitNotes || "",
      SiteNote: compData.SiteNotes || "",
      CustomerNote: compData.CustomerNotes || "",

      ComponentId: compData.ComponentId,
      AddressId: compData.AddressId,
      Street1: compData.Street1,
      City: compData.City,
      State: compData.State,
      PostCode: compData.PostCode,
      Country: compData.Country,
      ComponentType: compData.ComponentType,
      Serial: compData.Serial,
      DefaultFluidId: compData.DefaultFluidId,
      DefaultFluidType: compData.DefaultFluidType,
      DefaultFluidFormatted: compData.DefaultFluidFormatted,
      DefaultFluid: compData.DefaultFluid,
      DefaultTestSetId: compData.DefaultTestSetId,
      LastFluidId: compData.LastFluidId,
      LastFluid: compData.LastFluid,
      LastFluidType: compData.LastFluidType,
      LastTestSetId: compData.LastTestSetId,
      LastTestSet: compData.LastTestSet,
      DefaultTestSet: compData.DefaultTestSet,
      LastFluidFormatted: compData.LastFluidFormatted,
      UnitId: compData.UnitId,
      SiteId: compData.SiteId,
      CustomerId: compData.CustomerId,
      Model: compData.UnitModelFormatted,
      Capacity: compData.Capacity,
      Measure: compData.MeasureLong,
      LastFluidHours: compData.LastFluidHours,
      LastMeterReading: compData.LastMeterReading,
      LastComponentHours: compData.LastComponentHours,
      Product: compData.Product,
      ProductId: compData.ProductId,
      TestSetId: compData.TestSetId,
    };
  }

  let data;
  if (regData) {
    data = {
      ...sampleTransformed,
      Sif: regData.SIF,
      Product: regData.Product,
      RegistrationId: regData.RegistrationId,
      JobNumber: regData.JobNumber,
      Sampled: regData.Sampled,
      SampledAssumed: !!regData.Sampled,
      ComponentId: regData.ComponentId,
      // AddressId: sampleData.AddressId,
      // Street1: sampleData.Street1,
      // City: sampleData.City,
      // State: sampleData.State,
      // PostCode: sampleData.PostCode,
      // Country: sampleData.Country,
      ComponentType: regData.ComponentType,
      Serial: regData.Serial,
      DefaultFluidId: regData.DefaultFluidId,
      DefaultFluidType: regData.DefaultFluidType,
      DefaultFluidFormatted: regData.DefaultFluidFormatted,
      DefaultFluid: regData.DefaultFluid || compData?.DefaultFluidFormatted,
      DefaultTestSetId: regData.DefaultTestSetId,
      LastFluidId: regData.LastFluidId,
      LastFluid: regData.LastFluid,
      LastFluidType: regData.LastFluidType,
      LastTestSetId: regData.LastTestSetId,
      LastTestSet: regData.LastTestSet,
      DefaultTestSet: regData.DefaultTestSet,
      MeterReading: CheckNumber(regData.UnitMeter),
      ComponentReading: CheckNumber(regData.ComponentMeter),
      FluidHours: CheckNumber(regData.FluidMeter),
      Topup: CheckNumber(regData.Topup),
      MeterChanged: regData.UnitMeterChanged || false,
      MeterChangedHours: regData.UnitMeterChanged
        ? CheckNumber(regData.UnitMeterChangedAt || regData.UnitMeter)
        : "",
      MeterChangedAtDate: regData.UnitMeterChangedAtDate || regData.Sampled,

      ComponentChanged: regData.ComponentChanged || false,
      CompChangedHours: regData.ComponentChanged
        ? CheckNumber(regData.ComponentChangedAt || regData.UnitMeter)
        : "",
      CompChangedDate: regData.ComponentChangedAtDate || regData.Sampled,

      ComponentRepaired: regData.ComponentRepaired || false,
      ComponentRepairedAt: regData.ComponentRepaired
        ? CheckNumber(regData.ComponentRepairedAt || regData.UnitMeter)
        : "",
      ComponentRepairedAtDate:
        regData.ComponentRepairedAtDate || regData.Sampled,

      FluidChanged: regData.FluidChanged,
      FluidChangedAt: regData.FluidChanged
        ? CheckNumber(regData.FluidChangedAt || regData.UnitMeter)
        : "",
      FluidChangedAtDate: regData.FluidChangedAtDate || regData.Sampled,

      FluidFormatted: regData.FluidName || compData?.DefaultFluidFormatted,
      FluidId: regData.FluidId || compData?.DefaultFluidId,
      MakeFluidDefault: false, //TODO

      ServiceTypeId: regData.ServiceTypeId,

      CustomerComments: regData.Notes
        ? regData.Notes + (regData.AddedBy ? "\r\n" + regData.AddedBy : "")
        : "",
      FilterChanged: regData.FilterChanged,

      regSampleNo: regData.SampleNumber,

      ...tempCompData,
    };
    GetOverViewString(data, trayData);
    return data;
  }
  data = { ...sampleTransformed, ...tempCompData };
  GetOverViewString(data, trayData);
  return data;
}
//Regisatration form data mapping
function RegistrationFormReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === RegistrationFormActions.APPLY_FLUID) {
    return {
      ...state,
      FluidId: action.payload.FluidId,
      FluidFormatted: action.payload.FluidFormatted,
      _ts: Date.now(),
    };
  }
  if (action.type === RegistrationFormActions.LOAD_DATA) {
    return { ...state, ...action.payload, _ts_key: Date.now() };
  }
  if (action.type === RegistrationFormActions.RESET_FORM) {
    return { _ts: Date.now() };
  }
  return state;
}
