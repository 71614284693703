import useFetch from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import Tree from "../../components/tree/Tree";
import Product from "./treeItems/Products";
import CustomerSl from "./treeItems/CustomerSl";
import Customer from "./treeItems/Customer";
import getNodeId, { parseNodeId } from "./treeItems/nodeId";
import Site from "./treeItems/Site";
import Unit from "./treeItems/Unit";
import Component from "./treeItems/Component";
import Sample from "./treeItems/Sample";
import Button from "@mui/material/Button";
import AppDialog from "./../../components/AppDialog";
import { DETAILS_APIS, TRAY_APIS } from "../../common/apis";
import { SearchIcon } from "../../icons/ButtonIcons";
import { Box, Checkbox, FormControlLabel, } from "@mui/material";
import { DialogTypes, useScreenPopups } from "../../components/useScreenPopups";
import GroupDialog from "../../components/GroupDialog";
import productColors from "../../common/ProductColors";
import SearchGeneral from "./SearchGeneral";

export default function QuickDetailsTree3(props) {
  const { get, getFile } = useFetch();
  const [productsWithCount, setProductsWithCount] = useState([]);
  const [node, setNode] = useState("");
  const [nodes, setNodes] = useState({});
  const [expandedNode, setExpandedNode] = useState([]);
  const [customerLetters, setCustomersLetters] = useState([]);
  const [archivedCheck, setArchivedCheck] = useState(true);
  const [quickSearchOpen, setquickSearchOpen] = useState(false);
  const [dialogType, setDialogType] = useState({ open: false });
  const [productColor, setProductColor] = useState({})
  const [productsWithCountList, setProductsWithCountList] = useState([]);
  const [selectedNode, setSelectedNode] = useState("")

  useEffect(() => {
    let color2 = productColors(2);
    setProductColor(color2);

    (async () => {
      const data = await get(DETAILS_APIS.PRODUCTS_WITH_CUSTOMERCOUNT);
      setProductsWithCount(data.productWithCount);
      setProductsWithCountList((data.productWithCount))
      setCustomersLetters(data.customerStartLetters);
    })();
  }, []);

  const handleSelected = (e, nodeId) => {
    let prod = nodeId.split("/")[0];
    let color1 = Number(prod.split("_")[1]);
    let color2 = productColors(color1);
    setProductColor(color2);
    // setSelectedNode(nodeId)
    if (node === nodeId) {
      expandNodes(nodeId);
    }
    setNode(nodeId);
  };

  const handleNodeSelected = (e, nodeId, check) => {
    setSelectedNode(nodeId)
    if (check === true) { return; }
    else { handleSelected(e, nodeId) };
  };

  useEffect(() => {
    (async () => {
      const nodePath = node && parseNodeId(node);
      if (node) {
        if (!nodes[node]) {
          if (nodePath.nodeType === "product") {
            setNodes({ ...nodes, [node]: customerLetters[nodePath.product] });
          } else if (nodePath.nodeType === "stLetter") {
            const data = await getNodeData(
              "/Details/CustomersWithCount?productId=" +
              nodePath.product +
              "&stLetter=" +
              nodePath.stLetter
            );
            setNodes({ ...nodes, [node]: data });
          } else if (nodePath.nodeType === "customer") {
            const data = await getNodeData(
              "/Details/SitesWithCount?customerId=" + nodePath.customer
            );
            setNodes({ ...nodes, [node]: data });
          } else if (nodePath.nodeType === "site") {
            const data = await getNodeData(
              "/Details/UnitsWithCount?customerId=" +
              nodePath.customer +
              "&siteId=" +
              nodePath.site
            );
            setNodes({ ...nodes, [node]: data });
          } else if (nodePath.nodeType === "unit") {
            const data = await getNodeData(
              "/Details/ComponentsWithCount?unitId=" + nodePath.unit
            );
            setNodes({ ...nodes, [node]: data });
          } else if (nodePath.nodeType === "component") {
            const data = await getNodeData(
              "/Details/SamplesWithCount?componentId=" + nodePath.component
            );
            setNodes({ ...nodes, [node]: data });
          }
          return;
        }
        expandNodes();
      }
    })();
  }, [node]);

  useEffect(() => {
    expandNodes();
  }, [nodes]);

  const expandNodes = (prevNodeId) => {
    if (prevNodeId && expandedNode.includes(prevNodeId)) {
      setExpandedNode(expandedNode.filter((e) => e !== prevNodeId));
      return;
    }
    if (!node) return;
    if (expandedNode.includes(node)) {
      setExpandedNode(expandedNode.filter((e) => e !== node));
      return;
    }
    nodes[node] &&
      setExpandedNode(expandedNode.length ? [...expandedNode, node] : [node]);
  };

  const getNodeData = async (url) => {
    let data = await get(url);
    if (data.length) return data;
    return null;
  };

  function openQuickSearchClick() {
    setquickSearchOpen(true);
  }

  const handleLabelClick = (e, nodes) => {
    const nodePath = nodes && parseNodeId(nodes);
    props.nodeSelected && props.nodeSelected(nodePath);
    handleNodeSelected(e, nodes, true)
    e.stopPropagation();
    e.preventDefault();
  }

  const handleSearchTree = async (rowData) => {
    let color2 = productColors(rowData?.ProductId);
    setProductColor(color2);
    const product = { name: "product", val: rowData?.ProductId, };
    const stLetter = { name: "stLetter", val: rowData?.StartLetter, };
    const customer = { name: "customer", val: rowData?.CustomerId, };
    const site = { name: "site", val: rowData?.SiteId || rowData?.SiteID, };
    const unit = { name: "unit", val: rowData?.UnitId, };
    const component = { name: "component", val: rowData?.ComponentId, };
    const sample = { name: "sample", val: rowData?.SampleNumber, };

    const productNode = getNodeId([product])
    const stLetterNode = getNodeId([product, stLetter])
    const customerNode = getNodeId([product, stLetter, customer])
    const siteNode = getNodeId([product, stLetter, customer, site])
    const unitNode = getNodeId([product, stLetter, customer, site, unit])
    const componentNode = getNodeId([product, stLetter, customer, site, unit, component])
    const sampleNode = getNodeId([product, stLetter, customer, site, unit, component, sample]);

    // types: customer,site,unit,component,sample
    if (rowData?.SiteId === undefined && rowData?.SiteID === undefined) {
      const url = `types=customer&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`);

      setNodes({ ...nodes, [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount });
      setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode]);
      props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "customer" }, data?.sampleDetails)
      setSelectedNode(customerNode)
      return setquickSearchOpen(false);
    }

    if (rowData?.UnitId === undefined) {
      const url = `types=customer,site&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteId || rowData?.SiteID}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`);

      setNodes({ ...nodes, [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount });
      setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode, siteNode])
      props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "site" }, data?.sampleDetails)
      setSelectedNode(siteNode)
      return setquickSearchOpen(false);
    }

    if (rowData?.ComponentId === undefined) {
      const url = `types=customer,site,unit&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteId}&uid=${rowData?.UnitId}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`);

      setNodes({ ...nodes, [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount });
      setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode, siteNode, unitNode])
      props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "unit" }, data?.sampleDetails)
      setSelectedNode(unitNode)
      setquickSearchOpen(false);
      return;
    }

    if (rowData?.SampleNumber === undefined) {
      const url = `types=customer,site,unit,component&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteId}&uid=${rowData?.UnitId}&compid=${rowData?.ComponentId}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`);

      setNodes({ ...nodes, [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount, [componentNode]: data?.samplesWithCount });
      setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode, siteNode, unitNode, componentNode])
      props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "component" }, data?.sampleDetails)
      setSelectedNode(componentNode)
      return setquickSearchOpen(false);
    }

    if (rowData?.SampleNumber !== undefined) {
      const url = `types=customer,site,unit,component,sample&pid=${rowData?.ProductId}&lId=${rowData?.StartLetter}&cId=${rowData?.CustomerId}&sid=${rowData?.SiteID}&uid=${rowData?.UnitId}&compid=${rowData?.ComponentId}&sampleNo=${rowData?.SampleNumber}`
      const data = await get(`${DETAILS_APIS.SEARCH_TREE}?${url}`);

      setNodes({ ...nodes, [productNode]: customerLetters[rowData?.ProductId], [stLetterNode]: data?.customerWithCount, [customerNode]: data?.sitesWithCount, [siteNode]: data?.unitsWithCount, [unitNode]: data?.componentsWithCount, [componentNode]: data?.samplesWithCount });
      setExpandedNode([...expandedNode, productNode, stLetterNode, customerNode, siteNode, unitNode, componentNode])
      props.handleSearchTreeData && props.handleSearchTreeData({ nodeType: "sample" }, data?.sampleDetails)
      setSelectedNode(sampleNode)
      return setquickSearchOpen(false);
    }
  }

  const handleArchive = () => {
    if (archivedCheck === true) {
      let unarchivedProducts = productsWithCount.filter(x => x.IsArchived === false)
      setProductsWithCountList(unarchivedProducts);
    }
    if (archivedCheck === false) {
      setProductsWithCountList(productsWithCount);
    }
    setArchivedCheck(!archivedCheck)
  }

  function closeDialog() {
    setDialogType({ open: false });
  }

  async function getSampleReport(row) {
    try {
      await getFile(
        `${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`
      );
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  const viewMachineReport = async (data) => {
    await getFile(`${TRAY_APIS.MACHINE_REPORT}/?Machine_UnitId=${data.UnitId}`);
  };


  function addNew(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  function handleRightSelect(actionName, data) {
    if (Object.values(DialogTypes).includes(actionName)) {
      addNew(data, actionName);
      return;
    }
    else { console.err("err", actionName); }
  }

  const dialogOptions = useScreenPopups({
    dialogType: dialogType.type,
    data: dialogType.data || {},
    cancel: closeDialog,
  });

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, []);

  return (
    <Box id="treee" >

      <Box sx={{ py: 0.5, ...productColor, }} >
        <FormControlLabel
          control={
            <Checkbox
              name="isArchived"
              value="remember"
              checked={archivedCheck}
              onChange={handleArchive}
              sx={{ [`.MuiSvgIcon-root`]: { fill: "white", fontSize: "18px" } }}
            />
          }
          label="Show Archived"
          sx={{ margin: 0 }} />

        <Button
          variant="outlined"
          sx={{ float: "right", mr: 1, mt: 0.5, backgroundColor: "white", }}
          startIcon={<SearchIcon />}
          onClick={openQuickSearchClick}
        >
          Search
        </Button>
      </Box>

      <Box sx={{ mt: 0.5, }}>
        <Tree
          onNodeSelect={handleNodeSelected}
          // onNodeSelect={handleSelected}
          expanded={expandedNode}
        >
          {productsWithCountList.map((p, i) => {
            const productId = { name: "product", val: p.ProductId };
            return (
              <Product
                {...p}
                key={i + ""}
                pathItems={[productId]}
                expandedNode={expandedNode}
                handleRightSelect={handleRightSelect}
                selectedNode={selectedNode}
              >
                {(nodes[getNodeId([productId])] || [{}]).map((s, j) => {
                  const stlId = { name: "stLetter", val: s.StartLetter };
                  return (
                    <CustomerSl
                      {...s}
                      key={j + ""}
                      pathItems={[productId, stlId]}
                      expandedNode={expandedNode}
                      handleRightSelect={handleRightSelect}
                      selectedNode={selectedNode}
                    >
                      {(nodes[getNodeId([productId, stlId])] || [{}]).map(
                        (c, k) => {
                          const custId = { name: "customer", val: c.CustomerId };
                          return (
                            <Customer
                              {...c}
                              key={k + ""}
                              pathItems={[productId, stlId, custId]}
                              expandedNode={expandedNode}
                              handleLabelClick={handleLabelClick}
                              handleRightSelect={handleRightSelect}
                              selectedNode={selectedNode}
                            >
                              {(
                                nodes[getNodeId([productId, stlId, custId])] || [{},]).map((s, l) => {
                                  const siteId = { name: "site", val: s.SiteId };
                                  return (
                                    <Site
                                      {...s}
                                      key={l + ""}
                                      pathItems={[productId, stlId, custId, siteId]}
                                      expandedNode={expandedNode}
                                      handleLabelClick={handleLabelClick}
                                      handleRightSelect={handleRightSelect}
                                      selectedNode={selectedNode}
                                    >
                                      {(
                                        nodes[
                                        getNodeId([productId, stlId, custId, siteId,])] || [{}]).map((u, m) => {
                                          const unitId = { name: "unit", val: u.UnitId, };
                                          return (
                                            <Unit
                                              {...u}
                                              key={m + ""}
                                              pathItems={[productId, stlId, custId, siteId, unitId,]}
                                              expandedNode={expandedNode}
                                              handleLabelClick={handleLabelClick}
                                              handleRightSelect={handleRightSelect}
                                              selectedNode={selectedNode}
                                            >
                                              {(
                                                nodes[
                                                getNodeId([productId, stlId, custId, siteId, unitId,])] || [{}]).map((comp, n) => {
                                                  const componentId = { name: "component", val: comp.ComponentId, };
                                                  return (
                                                    <Component
                                                      {...comp}
                                                      key={n + ""}
                                                      pathItems={[productId, stlId, custId, siteId, unitId, componentId,]}
                                                      expandedNode={expandedNode}
                                                      handleLabelClick={handleLabelClick}
                                                      handleRightSelect={handleRightSelect}
                                                      selectedNode={selectedNode}
                                                    >
                                                      {(
                                                        nodes[
                                                        getNodeId([productId, stlId, custId, siteId, unitId, componentId])] || [{}]).map((samp, o) => {
                                                          const sample = { name: "sample", val: samp.SampleNumber, };
                                                          return (
                                                            <Sample
                                                              {...samp}
                                                              key={o + ""}
                                                              pathItems={[productId, stlId, custId, siteId, unitId, componentId, sample,]}
                                                              handleLabelClick={handleLabelClick}
                                                              handleRightSelect={handleRightSelect}
                                                              selectedNode={selectedNode}
                                                            />
                                                          );
                                                        })}
                                                    </Component>
                                                  );
                                                })}
                                            </Unit>
                                          );
                                        })}
                                    </Site>
                                  );
                                })}
                            </Customer>
                          );
                        }
                      )}
                    </CustomerSl>
                  );
                })}
              </Product>
            );
          })}
        </Tree>
      </Box >

      <AppDialog
        open={quickSearchOpen}
        handleClose={() => setquickSearchOpen(false)}
        title="Search from Quick Details"
        maxWidth="100vw"
        fullWidth
      >
        <SearchGeneral handleSearchTree={handleSearchTree} />
      </AppDialog>

      {(dialogType?.type !== undefined) && <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />}
    </Box>
  );
}