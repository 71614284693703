import { FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Fragment } from "react";

export default function DropDown({
  id,
  name,
  label,
  value,
  selectOptions,
  errorMsg,
  required,
  ...options
}) {
  return (
    <>
      <InputLabel htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        {...options}
        id={id}
        name={name}
        value={value}
        displayEmpty
        error={!!errorMsg}
      >
        {!selectOptions.find((x) => x.value === 0) && !required && (
          <MenuItem value={0} key={"select-option-0"}>
            Select
          </MenuItem>
        )}
        {selectOptions.map((op) => (
          <MenuItem value={op.value} key={op.value}>
            {op.text}
          </MenuItem>
        ))}
      </Select>
      {errorMsg && (
        <FormHelperText error={!!errorMsg}>{errorMsg}</FormHelperText>
      )}
    </>
  );
}
