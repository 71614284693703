import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function QuickMergeFluidTable({ data, rowsSelected }) {
  const fromModelOptions = {
    columns: fromModelColumns,
    dataSource: data || [],
    checkBoxSelect: true,
    rowIdProperty: "UnitMakeId",
    isDisableColumnFilter: true,
    rowsSelected: rowsSelected,
    sx: { height: 760 },
  };
  return <DataTable {...fromModelOptions} />;
}

const fromModelColumns = [
  {
    headerName: "From Make",
    field: "UnitMake",
    minWidth: 180,
    renderCell: renderCellExpand,
  },
  {
    headerName: "From Model",
    field: "UnitModel",
    minWidth: 190,
    renderCell: renderCellExpand,
  },
  {
    headerName: "From Verified",
    field: "Verified",
    minWidth: 120,
    flex: 1,
  },
];
