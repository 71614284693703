import { Typography } from "@mui/material";
import GetStepNames from "../../../common/GetStepNames";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function SamplesTable({
  data,
  rowSelected,
  selectedRow,
  actions,
  rowActionHandler,
}) {
  const options = {
    opacityCheck: true,
    columns: GetColumns(actions, { rowActionHandler }),
    dataSource: data,
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    rowStylingHandler: "sample",
    svgColor: "black",
    cellStylingHandler: "sampleBackGroundWhite",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    minHeight: 30,
    heightValue: 100,
  };
  return <DataTable {...options} />;
}

const GetColumns = (actions, { rowActionHandler }) => [
  {
    headerName: "Sample Number",
    field: "SampleNumber",
    minWidth: 128,
    maxWidth: 128,
    renderCell: function ({ row }) {
      return (
        <>
          {(actions || []).map(function (ac, i) {
            return (
              <DataGridAction
                key={i}
                row={row}
                onClick={ac.action}
                label={ac.label}
                startIcon={ac.startIcon}
              />
            );
          })}
          <DropdownMenu
            options={Options}
            onItemSelect={(option) => rowActionHandler(row, option)}
          />
          <Typography paddingTop={0.25}>{row.SampleNumber}</Typography>
        </>
      );
    },
  },
  {
    headerName: "Step",
    field: "Step",
    valueFormatter: ({ value }) => GetStepNames(value).short,
    minWidth: 55,
    maxWidth: 55,
  },
  {
    headerName: "Status",
    field: "AlertStatus",
    minWidth: 68,
    maxWidth: 68,
  },
  {
    headerName: "Test Set",
    field: "TestSet",
    minWidth: 125,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Product",
    field: "Product",
    valueFormatter: "",
    minWidth: 75,
    maxWidth: 100,
  },
  {
    headerName: "Customer",
    field: "Customer",
    minWidth: 250,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Site",
    field: "Site",
    minWidth: 100,
    maxWidth: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Unit",
    field: "Unit",
    minWidth: 100,
    maxWidth: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Serial",
    field: "Serial",
    minWidth: 115,
    maxWidth: 115,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Component",
    field: "Component",
    minWidth: 125,
    maxWidth: 125,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Fluid",
    field: "FluidFormatted",
    minWidth: 300,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Label",
    field: "Label",
    minWidth: 250,
    renderCell: renderCellExpand,
  },
];

const Options = [
  { label: "Copy Sample Number", name: "copy-sample-number" },
  { label: "Sample Images", name: "add-sample-images" },
  { label: "View Graphs", name: "view-graphs" },
  { label: "View Sample Report", name: "view-sample-report" },
  { label: "Edit Sample Details", name: "edit-sample" },
];
