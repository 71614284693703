import { Button, Grid } from "@mui/material";
import { useState } from "react";
import AppDialog from "../../../components/AppDialog";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import DropDown from "../../../components/DropDown";
import TagsInput from "../../../components/TagsInput";
import { TestSetActions } from "./TestSetForm";
import SearchGeneral from "../../details-old/SearchGeneral";

export function SelectUsers({ data, dispatch, masterData }) {
  const userIds = (data.Users || []).map((x) => x.UserId);
  const handelUsersChange = function (e, items) {
    dispatch({
      type: TestSetActions.USERS_UPDATED,
      payload: items.map((x) => x.UserId),
    });
  };

  return (
    <TagsInput
      label="Notify by Email When Test Set is First Released"
      options={masterData.Users}
      value={(masterData.Users || []).filter((x) => userIds.includes(x.UserId))}
      textProp="FullName"
      onChange={handelUsersChange}
    />
  );
}

export function SelectCustomerSite({ data, dispatch, masterData }) {
  const [openSelectCustomer, setOpenSelectCustomer] = useState(false);
  const handelDeleteClick = function (row) {
    dispatch({ type: TestSetActions.CUSTOMER_DELETED, payload: row });
  };

  return (
    <>
      <Grid container spacing={2} flexDirection="column">
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenSelectCustomer(true);
            }}
          >
            Select Customer or Site to Make Specific
          </Button>
        </Grid>
        <Grid item>
          <CustomersTable
            data={data.Customers}
            handelDeleteClick={handelDeleteClick}
          />
        </Grid>
      </Grid>
      <AppDialog
        open={openSelectCustomer}
        title="Select Customer/Site"
        maxWidth="100vw"
        handleClose={() => setOpenSelectCustomer(false)}
      >
        <SearchGeneral handleSearchTree={(row) => {
          dispatch({
            type: TestSetActions.CUSTOMER_OR_SITE_ADDED,
            payload: row,
          });
          setOpenSelectCustomer(false);
        }} />

        {/* <SearchDetails
          activeOnly="customer,site"
          //activeTab="component"
          cancel={() => setOpenSelectCustomer(false)}
          siteActions={[
            {
              action: (row) => {
                dispatch({
                  type: TestSetActions.CUSTOMER_OR_SITE_ADDED,
                  payload: row,
                });
                setOpenSelectCustomer(false);
              },
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
          custActions={[
            {
              action: (row) => {
                dispatch({
                  type: TestSetActions.CUSTOMER_OR_SITE_ADDED,
                  payload: row,
                });
                setOpenSelectCustomer(false);
              },
              label: "Select",
              startIcon: <CheckCircle />,
            },
          ]}
        /> */}
      </AppDialog>
    </>
  );
}
export function SelectTestSet({ testSets, dispatch }) {
  return (
    <>
      <DropDown
        label="Replace Default Test Set for Customers/Sites"
        required={true}
        onChange={(e) =>
          dispatch({
            type: TestSetActions.REPLACE_TO_TEST_SET,
            payload: e.target.value,
          })
        }
        selectOptions={(testSets || []).map((x) => ({
          value: x.TestSetId,
          text: x.TestSet,
        }))}
      />
    </>
  );
}

function CustomersTable({ data, handelDeleteClick }) {
  const options = {
    columns: GetColumns({ handelDeleteClick }),
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.CustomerId + "" + (x.SiteId || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    //sx: { height: "750px" },
  };
  return <DataTable {...options} />;
}

function GetColumns({ handelDeleteClick }) {
  return [
    {
      headerName: "Customer",
      field: "Customer",
      sortable: false,
      flex: 2,
    },
    {
      headerName: "Site",
      field: "Site",
      sortable: false,
      flex: 2,
    },
    {
      headerName: "Delete",
      sortable: false,
      flex: 1,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={handelDeleteClick}
              label="Delete"
            />
          </>
        );
      },
    },
  ];
}
