import { useTheme } from "@emotion/react";
import { Close, Search } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import { CONTACTS_APIS, DETAILS_APIS } from "../../../../common/apis";
import DataTable, { DataGridAction } from "../../../../components/DataTable";
import Form, { FormActions } from "../../../../components/Form";
import FormFooter from "../../../../components/FormFooter";
import useFetch from "../../../../hooks/useFetch";
import { useRoot, useToast } from "../../../../rootContext";
import appResources from "../../../../app-resources/en/Resources.json";
import renderCellExpand from "../../../../components/DatatableCellExpand";

export default function SearchCustomer({
  actions,
  contactId,
  rowSelected,
  selectedUnit,
}) {
  const curTheme = useTheme();
  const { showWarningToast } = useToast();
  const [customersData, setCustomersData] = useState([]);
  const { post } = useFetch();
  const { allProducts } = useRoot();

  const [data, dispatch] = useReducer(
    SearchFluidsFilterReducer,
    searchFilterDefaults
  );

  useEffect(() => {
    if (selectedUnit) {
      dispatch({
        payload: {
          ProductId: selectedUnit?.ProductId,
          UseStartsWith: true,
        },
        type: FormActions.LOAD_DATA,
      });
    }
  }, [selectedUnit]);

  const tblOptions = {
    columns: GetColumns(actions),
    dataSource: customersData,
    rowIdProperty: "CustomerId",
    cellStylingHandler: "productCommonCellColour",
    isDisableColumnFilter: true,
    sx: { height: "500px" },
    rowsSelected: function (rows) {
      rowSelected && rowSelected(rows[0]);
    },
  };

  const formActions = {
    clear: function () {
      dispatch({
        payload: {
          ...searchFilterDefaults,
          _key: Date.now(),
        },
        type: FormActions.RESET_FORM,
      });
    },
    search: async function () {
      if (contactId) {
        const customersData = await post(CONTACTS_APIS.CUSTOMERS_FOR_CONTACT, {
          ...data,
          ContactId: contactId,
          ExcludeScoped: true,
          UseStartsWith: data?.UseStartsWith || false,
          ShowAll: true,
        });
        if (customersData.length > 0) {
          setCustomersData(customersData);
        } else {
          showWarningToast(appResources.NO_MATCHING_CUSTOMERS_WERE_FOUND_MSG);
          setCustomersData(customersData);
        }
        //incase of call from management
      } else {
        const customersData = await post(DETAILS_APIS.SEARCH_CUSTOMERS, {
          ...data,
          // ContactId: contactId,
          Town: null,
          UseStartsWith: data?.UseStartsWith || false,
          UnarchivedOnly: null,
          Parent: null,
        });
        if (customersData.length > 0) {
          setCustomersData(customersData);
        } else {
          showWarningToast(appResources.NO_MATCHING_CUSTOMERS_WERE_FOUND_MSG);
          setCustomersData(customersData);
        }
      }
    },
  };

  return (
    <>
      <Grid container minWidth={800} spacing={1}>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(allProducts)}
            data={data}
            dispatch={dispatch}
            key={data && data._key}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: -5 }}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <Typography variant="subtitle2">
            Total Records: <b> {customersData && customersData.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

const GetFormButtons = (data, { clear, search }) => [
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <Close />,
  },
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

function GetColumns(actions) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      minWidth: 60,
      hide: !actions || !actions.length,
      renderCell: function ({ row }) {
        return (actions || []).map(function (ac, i) {
          return (
            <DataGridAction
              row={row}
              onClick={ac.action}
              label={ac.label}
              startIcon={ac.startIcon}
            />
          );
        });
      },
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 150,
      sortable: false,
    },
    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Archived",
      field: "IsArchived",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 100,
      sortable: false,
    },
  ];
}

function GetFormSections(allProducts) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 2,
          name: "ProductId",
          label: "Product",
          type: "dropdown",
          required: true,
          selectOptions: (allProducts || []).map((x) => ({
            value: x.ProductId,
            text: x.Product,
          })),
        },
        {
          xsCol: 4,
          name: "Customer",
          label: "Customer",
        },
        {
          xsCol: 4,
          name: "UseStartsWith",
          label: "Starts With Search",
          type: "switch",
          alignSelf: "flex-end",
        },
      ],
    },
  ];
}
const searchFilterDefaults = {
  ProductId: 0,
  UseStartsWith: true,
};
function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.LOAD_DATA) {
    return { ...action.payload };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...action.payload };
  }
  return state;
}
