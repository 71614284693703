import { useMemo } from "react";
import DataTable, { DataGridAction } from "../../components/DataTable";

export default function SearchGeneralTable({
    data,
    headerNameList,
    handleSearchSelect,
}) {
    const tableData = useMemo(
        function () {
            return (data || []).map((x) => ({
                ...x,
                keyProp: (x?.CustomerId || "") + (x?.SiteID || x?.SiteID || "") + (x?.UnitId || "") + (x?.ComponentId || "") + (x?.EventLodId || "") + (x?.EqpMachineId || "") + (x?.SampleNumber || ""),
            }))
        },
        [data]
    );
    // "CustomerId"+ (x?.CustomerId || "") +"SiteId"+ (x?.SiteId || x?.SiteID || "") +"UnitId"+ (x?.UnitId || "") + "ComponentId"+ (x?.ComponentId || "") + "EventLodId"+ (x?.EventLodId || "") +"EqpMachineId"+(x?.EqpMachineId || ""),
    const rowActionSelect = [{
        headerName: "Actions",
        field: "Actions",
        sortable: false,
        width: 80,
        renderCell: function ({ row }) {
            return (
                <DataGridAction
                    row={row}
                    onClick={() => handleSearchSelect(row)}
                    label={"Select"}
                />
            );
        },
    },]

    const options = {
        dataSource: tableData,
        rowIdProperty: "keyProp",
        columns: rowActionSelect.concat(headerNameList),
        cellStylingHandler: "productCommonCellColour",
        rowStylingHandler: "none",
        // rowsSelected: function (r) {
        //     r.length && rowSelected(r[0]);
        // },
        minHeight: 45
    };
    return <DataTable {...options}></DataTable>;
}
