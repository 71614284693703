import React, { useState, useEffect } from "react";
import { Button, Grid, Typography } from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import { TRAY_APIS } from "../../../common/apis";

export default function AutoEvaluationBatchFile({ batchNumber }) {
  const { get, getFile } = useFetch();

  const [batchData, setBatchData] = useState({});

  useEffect(() => {
    (async function () {
      let resData = await get(
        `${TRAY_APIS.GET_BATCH}?batchId=${batchNumber || ""}`
      );
      setBatchData(resData[0]);
    })();
  }, []);

  const handleExportBatchSample = async () => {
    await getFile(
      `${TRAY_APIS.EXPORT_BATCH_SAMPLES}?batchId=${batchNumber || ""}`,
      "Batch Samples" + " " + batchNumber
    );
  };
  const handleExportRequestSample = async () => {
    await getFile(
      `${TRAY_APIS.EXPORT_BATCH_SAMPLES_REQJSON}?batchId=${batchNumber || ""}`,
      "iKOWA Request"
    );
  };
  const handleExportResponseSample = async () => {
    await getFile(
      `${TRAY_APIS.EXPORT_BATCH_SAMPLES_RESPJSON}?batchId=${batchNumber || ""}`,
      "iKOWA Response"
    );
  };
  return (
    <>
      <Grid
        sx={{
          p: 1.5,
          minWidth: "33vw",
          overflow: "auto",
          minHeight: "20vh",
        }}
      >
        <Grid container flex spacing={2}>
          <Grid item xs={2}>
            <Typography>Batch</Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>{batchNumber}</Typography>
          </Grid>
        </Grid>

        <Grid container flex spacing={2} mt={0.5}>
          <Grid item xs={2}>
            <Typography>iKOWA</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>{batchData?.JpSampleCount}</Typography>
          </Grid>
          <Grid item xs={3}>
            {batchData.JpRequest == null && (
              <Button variant="outlined" onClick={handleExportRequestSample}>
                Request JSON
              </Button>
            )}
          </Grid>
          <Grid item xs={3}>
            {batchData.JpResponse != null && (
              <Button variant="outlined" onClick={handleExportResponseSample}>
                Response JSON
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container flex spacing={2} mt={0.5}>
          <Grid item xs={2}>
            <Typography>AU System</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>{batchData?.AuSampleCount}</Typography>
          </Grid>
          <Grid item xs={3}>
            {batchData.AuRequest != null && (
              <Button variant="outlined" onClick={handleExportRequestSample}>
                Request JSON
              </Button>
            )}
          </Grid>
          <Grid item xs={3}>
            {batchData.AuResponse != null && (
              <Button variant="outlined" onClick={handleExportResponseSample}>
                Response JSON
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid container flex spacing={2} mt={0.5}>
          <Grid item xs={2}>
            <Typography>Samples</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography>{batchData?.SampleCount}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Button variant="outlined" onClick={handleExportBatchSample}>
              Export Batch
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
