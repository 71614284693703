import CustomerDetail from "./details/CustomerDetail";
import CustomerAddress from "./details/CustomerAddress";
import SiteDetail from "./details/SiteDetail";
import SiteAddress from "./details/SiteAddress";
import PrimaryContact from "./details/PrimaryContact";
import UnitDetail from "./details/UnitDetail";
import Component from "./details/ComponentDetail";
import SampleOrReportDetail from "./details/SampleOrReportDetail";
import SampleResult from "./details/SampleResult";
import { Card, Grid, Typography, } from "@mui/material";
import { useState } from "react";
import SwitchInput from "../../components/SwitchInput";

export default function ViewDetails({ sectionsData, nodeLevel, setSearchOpen }) {
    const handleChangeSwitch = (infoType) => (e) => {
        const curSwitch = infoTypes.find((x) => x.id === infoType.id);
        curSwitch.checked = e.target.checked;
        setInfoTypes([...infoTypes]);
    };

    const [infoTypes, setInfoTypes] = useState([
        { id: 1, name: "Customer", checked: true, minLevel: 2 },
        { id: 2, name: "Customer Address", checked: false, minLevel: 2 },
        { id: 3, name: "Site", checked: true, minLevel: 3 },
        { id: 4, name: "Site Address", checked: false, minLevel: 3 },
        { id: 5, name: "Primary Contact", checked: false, minLevel: 3 },
        { id: 6, name: "Unit", checked: true, minLevel: 4 },
        { id: 7, name: "Component", checked: true, minLevel: 5 },
        { id: 8, name: "Sample or Report", checked: true, minLevel: 6 },
        { id: 9, name: "Sample Result", checked: true, minLevel: 6 },
    ]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card sx={{ p: 1 }}>
                    <Typography variant="caption" sx={{ mr: 2 }}>
                        Show
                    </Typography>
                    {infoTypes.map((infoType, index) => (
                        <SwitchInput
                            key={index}
                            label={infoType.name}
                            value={infoType.checked}
                            onChange={handleChangeSwitch(infoType)}
                        />
                    ))}
                </Card>
            </Grid>
            {(() => {
                const components = {
                    1: <CustomerDetail data={sectionsData.Customer || {}} setSearchOpen={setSearchOpen} />,
                    2: <CustomerAddress data={sectionsData.Customer || {}} />,
                    3: (
                        <SiteDetail
                            data={sectionsData.Site || {}}
                            product={sectionsData.Customer && sectionsData.Customer.Product}
                            customer={sectionsData.Customer && sectionsData.Customer.Customer}
                            site={sectionsData.Site && sectionsData.Site.Site}
                            unit={sectionsData.Unit && sectionsData.Unit.Unit}
                            component={
                                sectionsData.Component && sectionsData.Component.Component
                            }
                            unitModel={
                                sectionsData.Unit && sectionsData.Unit.UnitModelFormatted
                            }
                        />
                    ),
                    4: <SiteAddress data={sectionsData.Site || {}} />,
                    5: <PrimaryContact data={sectionsData || {}} />,
                    6: (
                        <UnitDetail
                            data={sectionsData.Unit || {}}
                            product={sectionsData.Customer && sectionsData.Customer.Product}
                            customer={sectionsData.Customer && sectionsData.Customer.Customer}
                            site={sectionsData.Site && sectionsData.Site.Site}
                            unit={sectionsData.Unit && sectionsData.Unit.Unit}
                            component={
                                sectionsData.Component && sectionsData.Component.Component
                            }
                            unitModel={
                                sectionsData.Unit && sectionsData.Unit.UnitModelFormatted
                            }
                        />
                    ),
                    7: (
                        <Component
                            data={sectionsData.Component || {}}
                            product={sectionsData.Customer && sectionsData.Customer.Product}
                            customer={sectionsData.Customer && sectionsData.Customer.Customer}
                            site={sectionsData.Site && sectionsData.Site.Site}
                            unit={sectionsData.Unit && sectionsData.Unit.Unit}
                            component={
                                sectionsData.Component && sectionsData.Component.Component
                            }
                            unitModel={
                                sectionsData.Unit && sectionsData.Unit.UnitModelFormatted
                            }
                        />
                    ),
                    8: (
                        <SampleOrReportDetail
                            data={sectionsData.Sample || {}}
                            productId={
                                sectionsData.Customer && sectionsData.Customer.ProductId
                            }
                            product={sectionsData.Customer && sectionsData.Customer.Product}
                            customer={sectionsData.Customer && sectionsData.Customer.Customer}
                            site={sectionsData.Site && sectionsData.Site.Site}
                            unit={sectionsData.Unit && sectionsData.Unit.Unit}
                            component={
                                sectionsData.Component && sectionsData.Component.Component
                            }
                            unitModel={
                                sectionsData.Unit && sectionsData.Unit.UnitModelFormatted
                            }
                            customerId={
                                sectionsData.Customer && sectionsData.Customer.CustomerId
                            }
                            componentId={
                                sectionsData.Component && sectionsData.Component.ComponentId
                            }
                        />
                    ),
                    9: <SampleResult data={sectionsData.SampleResult || []} />,
                };
                let count = -1;
                return infoTypes.map((x, i) => {
                    if (x.checked) {
                        count++;
                    }
                    const st =
                        x.checked && x.minLevel <= nodeLevel ? {} : { display: "none" };
                    return (
                        <Grid
                            item
                            xs={x.id === 8 || x.id === 9 ? 12 : 6}
                            style={st}
                            key={i}
                        >
                            {components[x.id]}
                        </Grid>
                    );
                });
            })()}
        </Grid>
    );
}
