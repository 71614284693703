import { useTheme } from "@emotion/react";
import { PlaylistAdd, } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useReducer, useState } from "react";
import { FLEET_APIS } from "../../../common/apis";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import useMasterData from "../../../hooks/useMasterData";
import { ClearIcon, SearchIcon } from "../../../icons/ButtonIcons";
import AppDialog from "../../../components/AppDialog";
import AddUnit from "./AddUnit";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function AddUnitSearch({
  TemplateId,
  rowSelected,
  setDialog,
  refreshModels,
}) {
  const curTheme = useTheme();
  const { getMasterData } = useMasterData();
  const [fluidsData, setFluidsData] = useState([]);
  const [openAddUnit, setOpenAddUnit] = useState(false);
  const [unitSearchRowSelected, setUnitSearchRowSelected] = useState(false);
  const { get, post } = useFetch();
  const [data, dispatch] = useReducer(SearchFluidsFilterReducer, {});
  const tblOptions = {
    columns: GetColumns(selectClick, editClick),
    dataSource: (fluidsData || []).map((x) => ({
      ...x,
      keyProp: x.UnitMakeId + "" + (x.UnitModelFormatted || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    rowsSelected: function (rows) {
      rowSelected && rowSelected(rows[0]);
    },
  };

  const formActions = {
    clear: function () {
      dispatch({ type: FormActions.RESET_FORM });
    },
    addNew: function () {
      setUnitSearchRowSelected(null);
      setOpenAddUnit(true);
    },
    search: async function () {
      const tempFluidsData = await post(FLEET_APIS.SEARCH_UNIT_MODELS, {
        ...data,
        MakeOption: 1,
        ModelOption: 1,
      });
      setFluidsData(tempFluidsData);
    },
  };
  const [masterData, setMasterData] = useState({});
  useEffect(function () {
    (async function () {
      const resp = await getMasterData("FluidTypes");
      setMasterData(resp);
    })();
  }, []);

  async function selectClick(row) {
    await get(
      `${FLEET_APIS.ADD_DELETE_TEMPLATE_MODEL}?TemplateId=${TemplateId}&UnitMakeId=${row.UnitMakeId}&IsDelete=false&Screen=Machine Templates`
    );
    setDialog((prevState) => ({
      ...prevState,
      open: false,
    }));
    refreshModels(TemplateId);
  }
  function editClick(row) {
    setUnitSearchRowSelected(row);
    setOpenAddUnit(true);
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(data, masterData)}
            data={data}
            dispatch={dispatch}
            key={data._key}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(data, formActions)}
            hideDivider
            gmt={0}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: curTheme.spacing(2) }}>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
      <AppDialog
        open={openAddUnit}
        title="New Unit Model"
        maxWidth="lg"
        handleClose={() => setOpenAddUnit(false)}
      >
        <AddUnit
          unitSearchRowSelected={unitSearchRowSelected && unitSearchRowSelected}
          TemplateId={TemplateId}
        />
      </AppDialog>
    </>
  );
}
const GetFormButtons = (data, { addNew, clear, search }) => [
  {
    variant: "contained",
    label: "Clear",
    onClick: clear,
    startIcon: <ClearIcon />,
  },
  {
    variant: "contained",
    label: "New Model",
    onClick: addNew,
    startIcon: <PlaylistAdd />,
  },

  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <SearchIcon />,
  },
];

function GetColumns(selectClick, editClick) {
  return [
    {
      headerName: "Select",
      field: "Select",
      sortable: false,
      minWidth: 100,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={selectClick} label="Select" />
          </>
        );
      },
    },
    {
      headerName: "Make",
      field: "UnitMake",
      minWidth: 170,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Model",
      field: "UnitModel",
      minWidth: 170,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Formatted Model",
      field: "UnitModelFormatted",
      minWidth: 200,
      sortable: false,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Verified",
      field: "Verified",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Komatsu",
      field: "IsKomatsu",
      minWidth: 100,
      sortable: false,
    },
    {
      headerName: "Komatsu Group",
      field: "IsKomatsuGroup",
      minWidth: 150,
      sortable: false,
    },
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
  ];
}

function GetFormSections(_, { FluidTypes }) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 6,
          name: "Make",
          label: "Make",
        },
        {
          xsCol: 6,
          name: "Model",
          label: "Model",
        },
      ],
    },
  ];
}

function SearchFluidsFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { _key: Date.now() };
  }
  return state;
}
