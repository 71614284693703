import { Check, ControlPointOutlined, Delete } from "@mui/icons-material";
import { useEffect, useReducer, useState } from "react";
import Form, { FormActions } from "../../components/Form";
import FormFooter from "../../components/FormFooter";
import useFetch from "../../hooks/useFetch";
import { DETAILS_APIS } from "../../common/apis";
import { useRoot, useUser } from "../../rootContext";
import moment from "moment";
import ConfirmModal from "../../components/ConfirmModal";
import AppDialog from "../../components/AppDialog";

export default function EditEventForm({
  setOpenEditEvent,
  fetchData,
  formData,
  eventTypes,
  conditions,
  eventsRowData,
  eventFilter,
  cancel,
}) {
  const { CanDoDataMaintenance } = useRoot()
  const defaultEventsData = {
    EventTypeId: 3,
    EventDate: new moment(),
  };
  const reducerData = eventsRowData?.addNew === true ? defaultEventsData : eventsRowData
  const user = useUser();

  const [data, dispatch] = useReducer(EventReducer, reducerData);
  const { get, post } = useFetch();
  const [openAlert, setOpenAlert] = useState(false);

  const handleDeleteEvent = async () => {
    setOpenAlert(false);
    await get(`${DETAILS_APIS.DELETE_EVENT_LOG}?EventLogId=${data?.EventLogId}`, {});
    setOpenEditEvent && setOpenEditEvent(false);
    fetchData && fetchData(data?.SampleNumber);
    cancel && cancel();
  }

  const actions = {
    clear: function (e) {
      dispatch({
        type: EventActions.RESET_FORM,
        payload: { ...defaultEventsData },
      });
    },
    newFluid: async function (e) {
      await post(DETAILS_APIS.ADD_EVENT_LOG, {
        EventLogId: data?.EventLogId,
        EventTypeId: data?.EventTypeId,
        MeterReading: data?.MeterReading,
        EventDate: data?.EventDate,
        SiteId: (conditions.siteId || conditions.SiteId) ? (conditions.siteId || conditions.SiteId) : null,
        UnitId: (conditions.unitId || conditions.UnitId) ? (conditions.unitId || conditions.UnitId) : null,
        ComponentId: (conditions.componenetId || conditions.ComponenetId) ? (conditions.componenetId || conditions.ComponenetId) : null,
        Note: data?.Event,
        Name: user?.Username,
        SampleNumber: data?.SampleNumber,
      });
      setOpenEditEvent && setOpenEditEvent(false);
      fetchData(data?.SampleNumber);
    },
    deleteFluid: function (e) {
      setOpenAlert(true)
    },
    save: async function (e) {
      if ((eventsRowData?.addNew === true || data?.EventTypeId === null)) {
        await post(DETAILS_APIS.ADD_EVENT_LOG, {
          EventLogId: null,
          EventTypeId: data?.EventTypeId,
          MeterReading: data?.MeterReading,
          EventDate: data?.EventDate,
          SiteId: (eventFilter.componentId || eventFilter.ComponentId) ? null : (eventFilter.siteId || eventFilter.SiteId) ? (eventFilter.siteId || eventFilter.SiteId) : null,
          UnitId: (eventFilter.componentId || eventFilter.ComponentId) ? null : (eventFilter.unitId || eventFilter.UnitId) ? (eventFilter.unitId || eventFilter.UnitId) : null,
          ComponentId: (eventFilter.componentId || eventFilter.ComponentId) ? (eventFilter.componentId || eventFilter.ComponentId) : null,
          Note: data?.Event,
          Name: user?.Username,
          SampleNumber: data?.SampleNumber,
        });
        setOpenEditEvent && setOpenEditEvent(false);
        fetchData && fetchData(data?.SampleNumber);
        cancel && cancel();
        return;
      }
      if (eventsRowData?.addNew !== true || data?.EventTypeId !== null) {

        await post(DETAILS_APIS.SAVE_EVENT_LOG, {
          EventLogId: data?.EventLogId,
          EventTypeId: data?.EventTypeId,
          MeterReading: data?.MeterReading,
          EventDate: data?.EventDate,
          Note: data?.Event,
          Name: user?.Username || data?.AddedBy,
          SampleNumber: data?.SampleNumber,
        });
        setOpenEditEvent && setOpenEditEvent(false);
        fetchData && fetchData(data?.SampleNumber);
        cancel && cancel();
      }
    },
  };

  useEffect(
    function () {
      (async function () {
        if (formData?.SampleNumber || formData?.EventLogId) {
          dispatch({
            type: EventActions.LOAD_DATA,
            payload: { ...formData, },
          });
        }
      })();
    },
    [formData]
  );
  return (
    <>
      <Form
        sections={FormSections(data, eventTypes)}
        data={data}
        spacing={2}
        dispatch={dispatch}
        key={data?.EventLogId}
      />
      <FormFooter
        buttons={GetFormButtons(data, actions, CanDoDataMaintenance)}
        footerText={`ProductId: ${eventFilter?.productId || eventFilter?.ProductId || ""}, CustomerId: ${eventFilter?.customerId || eventFilter?.CustomerId || ""}, SiteId: ${eventFilter?.siteId || eventFilter?.SiteId || ""}, UnidId: ${eventFilter?.unitId || eventFilter?.UnitId || ""} , ComponentId: ${eventFilter?.componentId || eventFilter?.ComponentId || ""} , EventLogId: ${eventFilter?.eventLogId || eventFilter?.EventLogId || ""} `}
      />
      <AppDialog
        open={openAlert}
        title={"Delete Event"}
        maxWidth="md"
        handleClose={() => { setOpenAlert(false); }}
      >
        <ConfirmModal
          handleClose={() => {
            setOpenAlert(false);
          }}
          alertAction={handleDeleteEvent}
          message={`Are you sure you wish to Delete the event?`}
          buttonText={"Delete"}
          buttonText2={"Cancel"} />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (data, { deleteFluid, newFluid, save, clear }, CanDoDataMaintenance) => [
  {
    hidden: !CanDoDataMaintenance,
    variant: "outlined",
    label: "New",
    onClick: clear,
    startIcon: <ControlPointOutlined />,
  },
  {
    hidden: !CanDoDataMaintenance,
    variant: "outlined",
    label: "Delete",
    onClick: deleteFluid,
    startIcon: <Delete />,
    disabled: !data?.EventLogId,
  },

  {
    hidden: !CanDoDataMaintenance,
    variant: "contained",
    label: "Save",
    onClick: save,
    disabled: !(
      data?.MeterReading &&
      data?.Event &&
      data?.EventDate &&
      data?.EventTypeId
    ),
    startIcon: <Check />,
  },
];

function FormSections(data, eventTypes) {
  return [
    {
      fields: [
        {
          name: "EventTypeId",
          label: "Event Type",
          required: true,
          type: "dropdown",
          xsCol: 3,
          selectOptions: (eventTypes || []).map((x) => ({
            value: x.EventTypeId,
            text: x.EventType,
          })),
        },
        {
          name: "SampleNumber",
          label: "Sample Number",
          xsCol: 2,
        },
        { name: "EventDate", label: "Occurred", xsCol: 3, type: "datetime" },
        {
          name: "MeterReading",
          label: "Meter Reading",
          xsCol: 2,
          type: "number",
        },
        { name: "UnitHours", label: "Unit Hours", xsCol: 2, disabled: "true" },
        {
          name: "Event",
          label: "Description",
          type: "textarea",
          xsCol: 8,
        },
      ],
    },
  ];
}
const EventActions = {
  LOAD_DATA: "load-data",
};
function EventReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return action.payload;
  }
  if (action.type === EventActions.RESET_FORM) {
    return { ...action.payload, _ts_key: Date.now() };
  }

  return state;
}
