import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_de from "./app-resources/en/Resources.json";
import common_en from "./app-resources/en/Resources.json";
import { RootProvider } from "./rootContext";
import { customTheme } from "./customTheme";
import { ThemeProvider } from "@mui/material";
import store from './store/store'
import { Provider } from 'react-redux'

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const pca = new PublicClientApplication(msalConfig);

pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
    //localStorage.setItem()
  }
});

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    de: {
      common: common_de,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <I18nextProvider i18n={i18next}>
        <ThemeProvider theme={customTheme}>
          <Provider store={store}>
            <RootProvider>
              <App />
            </RootProvider>
          </Provider>
        </ThemeProvider>
      </I18nextProvider>
    </MsalProvider>
  </React.StrictMode>
);
