import * as React from "react";
import { Grid } from "@mui/material";
import { useReducer, } from "react";
import { DETAILS_APIS } from "../../../common/apis";
import useMasterData from "../../../hooks/useMasterData";
import Form, { FormActions } from "../../../components/Form";
import FormFooter from "../../../components/FormFooter";
import useFetch from "../../../hooks/useFetch";
import { Typography } from "@mui/material";
import { SaveCommentsIcon, } from "../../../icons/ButtonIcons";
import { useRoot, useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";

export default function NewFluid({
  setNewFluidOpen,
  setDialogOpen,
  fetchFluidsDetails,
  rowManageSelected,
  getFluidControlsData,
  isEdit,
  hanldeClose,
}) {
  //useFetch
  const [data, dispatch] = useReducer(FluidReducer, rowManageSelected || defaultFilter);
  const [masterData, setMasterData] = React.useState({});
  const { post } = useFetch();
  const { showToast, showInfoToast, showErrorToast } = useToast();
  const { CanDoDataMaintenance } = useRoot()
  const actions = {
    save: async function () {
      if (data?.FluidLevel1 === "" || data?.FluidLevel1 === undefined) {
        return showInfoToast(appResources.NAME_ATLEAST_LEVEL1_MSG);
      }
      if (data?.FluidId) {
        const response = await post(DETAILS_APIS.UPDATE_FLUID, {
          ...data,
          Screen: "Fluids",
        });
        if (response > 0) {
          showInfoToast(appResources.FLUID_UPDATED_SUCCESSFULLY_MSG);
          getFluidControlsData && getFluidControlsData();
        } else {
          showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
        }
        setNewFluidOpen ? setNewFluidOpen(false) : setDialogOpen(false);
      } else {
        const response = await post(DETAILS_APIS.ADD_FLUID, {
          ...data,
          Screen: "Fluids",
        });
        if (response > 0) {
          showToast(appResources.NEW_FLUID_ADDED_SUCCESSFULLY_MSG);
          fetchFluidsDetails && fetchFluidsDetails(0);
        } else {
          showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
        }
        setNewFluidOpen ? setNewFluidOpen(false) : setDialogOpen(false);
      }
    },
  };

  const { getMasterData } = useMasterData();

  async function fetchFluidType() {
    const temp = await getMasterData("FluidTypes", ``);

    // if (temp && temp.FluidTypes) {
    //   temp.FluidTypes = temp.FluidTypes.filter((x) => !x.IsNonFluid);
    // }
    setMasterData(temp || {});

    return temp;
  }

  React.useEffect(function () {
    (async function () {
      await fetchFluidType();
    })();
    //func();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Form
            sections={GetFormSections(masterData, isEdit)}
            data={data}
            dispatch={dispatch}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFooter
            buttons={GetFormButtons(data, actions, CanDoDataMaintenance)}
          />
        </Grid>
      </Grid>
    </>
  );
}

const GetFormButtons = (data, { save }, CanDoDataMaintenance) => [
  CanDoDataMaintenance && {
    variant: "contained",
    label: "Save",
    type: "submit",
    onClick: save,
    startIcon: <SaveCommentsIcon />,
  },
];

function GetFormSections(data, isEdit) {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          hidden: isEdit && isEdit,
          component: () => (
            <Typography>Please add the details for the new fluid</Typography>
          ),
        },
        {
          xsCol: 12,
          component: () => (
            <Typography sx={{ color: "rgb(145,17,146)" }}>
              Use KOWA naming standards as manufacturer names are inconsistent
              <br />
              When a new fluid is to be created check with another person first,
              as a safety measure
            </Typography>
          ),
        },
        {
          xsCol: 1,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Type
            </Typography>
          ),
        },
        {
          xsCol: 3,
          name: "FluidTypeId",
          // label: "Type",
          type: "dropdown",
          selectOptions: (data.FluidTypes || []).map((x) => ({
            value: x.FluidTypeId,
            text: x.FluidType,
          })),
          defaultValue: 0,
        },
        {
          xsCol: 8,
          component: () => <Typography></Typography>,
        },
        {
          xsCol: 1,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Name
            </Typography>
          ),
        },
        {
          xsCol: 6,
          name: "FluidLevel1",
          // label: "Level 1 (e.g. make)",
        },
        {
          xsCol: 5,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Level 1 (e.g. make)
            </Typography>
          ),
        },
        {
          xsCol: 1,
          component: () => <Typography></Typography>,
        },
        {
          xsCol: 6,
          name: "FluidLevel2",
          // label: "Level 2 (e.g. type)",
        },
        {
          xsCol: 5,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Level 2 (e.g. type)
            </Typography>
          ),
        },
        {
          xsCol: 1,
          component: () => <Typography></Typography>,
        },
        {
          xsCol: 11,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              For multigrades, use a DASH -, e.g. 15W-40; 85W-140
              <br />
              For single grades, use a W, e.g. 10W
              <br />
              For ISO grades, use the grade only, e.g. 68
            </Typography>
          ),
        },

        {
          xsCol: 1,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            ></Typography>
          ),
        },
        {
          xsCol: 6,
          name: "FluidLevel3",
          // label: "Level 3 (e.g. grade)",
        },
        {
          xsCol: 5,
          component: () => (
            <Typography
              sx={{
                paddingTop: "3px",
              }}
            >
              Level 3 (e.g. grade)
            </Typography>
          ),
        },
      ],
    },
  ];
}

const EventActions = {
  LOAD_DATA: "load-data",
};

function FluidReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  }
  return state;
}
const defaultFilter = {
  FluidTypeId: 1,
};
