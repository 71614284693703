import { useTheme } from "@emotion/react";
import { InsertDriveFile, ViewAgenda, Visibility } from "@mui/icons-material";
import { Button, Card, Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useReducer, useState } from "react";
import moment from "moment";
import DataTable from "../../../components/DataTable";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { toLocalDateTime, toLocalDateTime2 } from "../../../utils";
import { DASHBOARD_APIS } from "../../../common/apis";
import appResources from "../../../app-resources/en/Resources.json";
import {
  ExcelIcon,
  ExportHistoryIcon,
  ExportMismatchIcon,
  ViewIcon,
} from "../../../icons/ButtonIcons";
import { useToast } from "../../../rootContext";
import renderCellExpand from "../../../components/DatatableCellExpand";
export default function IkowaFeedback({}) {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );

  const { post, postFile } = useFetch();

  const [feedbackData, setFeedbackData] = useState([]);
  const [infoMessage, setInfoMessage] = useState();
  // const [contactId, setContactId] = useState();
  // const [propId, setPropId] = useState();
  useEffect(() => {
    document.title = "iKOWA Feedback";
  }, []);
  //call api for search audit data
  function view() {
    (async function () {
      if (
        data?.DateFrom === "Invalid date" ||
        data?.DateTo === "Invalid date"
      ) {
        return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
      } else {
        let resData = await post(DASHBOARD_APIS.FEEDBACK, {
          ...data,
          RankDifference: data?.RankDifference === "" ? 1 : data.RankDifference,
        });

        if (resData?.length > 0) {
          setFeedbackData(resData);
          setInfoMessage(`There are ${resData?.length} mismatched samples for iKOWA
          feedback to Japan`);
        } else {
          setFeedbackData();
          setInfoMessage();
          setInfoMessage(appResources.NO_IKOWA_DATA_FOUND_MSG);
        }
      }
    })();
  }
  const tblOptions = {
    columns: GetColumns(data),
    dataSource: (feedbackData || []).map((x) => ({
      ...x,
      keyProp: x.SampleNumber + "" + (x.UnitHours || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: { height: 790 },
  };
  //Export excel
  function mismatchesToExcelClick() {
    postFile(
      DASHBOARD_APIS.EXPORT_MISMATCH,
      {
        ...data,
      },
      "iKOWA Mismatches Feedback"
    );
  }
  function historyToExcelClick() {
    postFile(
      DASHBOARD_APIS.EXPORT_HISTORY,
      {
        ...data,
      },
      "KOWA History"
    );
  }
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                view,
                mismatchesToExcelClick,
                historyToExcelClick,
                infoMessage,
                feedbackData
              )}
              data={data}
              dispatch={dispatch}
            />
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {feedbackData?.length}</b>
          </Typography>
          <DataTable {...tblOptions} />
        </Grid>
      </Grid>
    </>
  );
}

function GetColumns(data) {
  return [
    {
      headerName: "SampleNumber",
      field: "SampleNumber",
      width: 125,
    },
    {
      headerName: "iKowaDate",
      field: "iKowaDate",
      valueFormatter: ({ value }) => toLocalDateTime2(value),
      width: 155,
    },
    {
      headerName: "Laboratory",
      field: "Laboratory",
      width: 125,
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      width: 105,
      renderCell: renderCellExpand,
    },
    {
      headerName: "ExternalFactors",
      field: "ExternalFactors",
      hide: !data.IncludeExternalFactors,
      valueFormatter: ({ value }) => (value === "Yes" ? "Yes" : ""),
      width: 125,
    },
    {
      headerName: "AlertRank",
      field: "AlertRank",
      width: 85,
    },
    {
      headerName: "iKowaRank",
      field: "iKowaRank",
      width: 85,
    },
    {
      headerName: "Difference",
      field: "RankDifference",

      width: 85,
    },
    {
      headerName: "ComponentTypeJP",
      field: "ComponentTypeJP",
      width: 155,
      renderCell: renderCellExpand,
    },
    {
      headerName: "ComponentTypeAU",
      field: "ComponentTypeAU",
      width: 155,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}
function GetFormSections(
  view,
  mismatchesToExcelClick,
  historyToExcelClick,
  infoMessage,
  feedbackData
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "DateFrom",
          label: "From",
          type: "date",
          alignSelf: "end",
        },
        {
          name: "DateTo",
          label: "To",
          type: "date",
          alignSelf: "end",
        },
        {
          name: "RankDifference",
          label: "Difference",
          type: "number",
          xsCol: 1,
          min: 1,
          max: 4,
          alignSelf: "end",
        },
        {
          name: "IncludeExternalFactors",
          label: "External Factors",
          type: "switch",
        },
        {
          name: "UnblockedOnly",
          label: "Quality",
          type: "switch",
        },
        {
          name: "KomatsuOnly",
          label: "Komatsu Only",
          type: "switch",
        },

        {
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={view}
                sx={{
                  mt: "23px",
                  "& .MuiButton-startIcon": { marginTop: "5px" },
                }}
                startIcon={<ViewIcon />}
              >
                View
              </Button>
            );
          },
        },
        {
          component: function () {
            return (
              feedbackData?.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={mismatchesToExcelClick}
                  sx={{ mt: "23px" }}
                  startIcon={<ExportMismatchIcon />}
                >
                  Export Mismatches
                </Button>
              )
            );
          },
        },
        {
          component: function () {
            return (
              feedbackData?.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={historyToExcelClick}
                  sx={{ mt: "23px" }}
                  startIcon={<ExportHistoryIcon />}
                >
                  Export History
                </Button>
              )
            );
          },
        },
        {
          flex: 2,
          alignSelf: "center",
          component: function () {
            return (
              <Typography sx={{ mt: 3, fontWeight: 700, fontSize: 11 }}>
                {infoMessage}
              </Typography>
            );
          },
        },
      ],
    },
  ];
}

function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}
const SearchFilterDefaults = {
  DateFrom: moment().toISOString(),
  DateTo: moment().toISOString(),
  RankDifference: 1,
  UnblockedOnly: true,
  KomatsuOnly: true,
};
