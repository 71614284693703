import DataTable from "../../../components/DataTable";
import renderCellExpand from "./../../../components/DatatableCellExpand";

export default function QuickMergeFluidTable({ data, rowsSelected }) {
  const fromFluidOptions = {
    columns: fromFluidColumns,
    dataSource: data || [],
    checkBoxSelect: true,
    rowIdProperty: "FluidId",
    isDisableColumnFilter: false,
    rowsSelected: rowsSelected,
    sx: { height: 760 },
  };
  return <DataTable {...fromFluidOptions} />;
}

const fromFluidColumns = [
  {
    headerName: "From Fluid Type",
    field: "FluidType",
    minWidth: 200,
  },
  {
    headerName: "From Fluid",
    field: "FluidFormatted",
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
