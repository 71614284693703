import { Button, Grid, Typography } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import SwitchInput from "../../components/SwitchInput";
import useFetch from "../../hooks/useFetch";
import { DETAILS_APIS, } from "../../common/apis";
import ViewEventsTable from "./ViewEventsTable";
import DropDown from "../../components/DropDown";
import { useScreenPopups } from "../../components/useScreenPopups";
import GroupDialog from "../../components/GroupDialog";

export default function ViewEvents({ dataObj }) {
    const { get } = useFetch();

    const [tableData, setTableData] = useState([])
    const [hideEvents, setHideEvents] = useState(true);
    const [ddList, setDDList] = useState([]);
    const [dialogType, setDialogType] = useState({ open: false });
    const [selectedId, setSelectedId] = useState(0);
    const [unitData, setUnitData] = useState({})

    const getUnitData = async (id) => {
        const temp = await get(`${DETAILS_APIS.EVENT_LOGS_ALL}?UnitId=${id || 0}`);
        setTableData(temp)
    }
    const getComponentData = async (id) => {
        const temp = await get(`${DETAILS_APIS.EVENT_LOGS_ALL}?ComponentId=${id || 0}`);
        setTableData(temp)
    }

    // const filteredTableData = useMemo(
    //     function () {
    //         return hideEvents
    //             ? tableData.filter((x) => x.EventTypeId !== 3)
    //             : tableData;
    //     },
    //     [hideEvents, tableData]
    // );

    const handelFilterChange = async (e) => {
        const unitCheck = e.target.value.includes("Unit:")
        const idVal = Number(e.target.value.split(";")[1])
        setSelectedId(e.target.value);

        if (unitCheck !== true) { return getComponentData(idVal) }
        if (unitCheck === true) { return getUnitData(idVal) }
    }

    const refreshData = async () => {
        const treeData = await get(`${DETAILS_APIS.SEARCH_TREE}?types=unit&uid=${dataObj?.UnitId}`, { skipBlocker: true });
        const compList = treeData?.componentsWithCount.map(item => { return ({ Id: item?.ComponentId, Name: item?.Component }) })
        const unitData = treeData?.sampleDetails?.Unit;
        setUnitData(unitData)
        setDDList([{ Id: unitData?.UnitId, Name: "Unit:" + unitData?.Unit }, ...compList,])

        if (dataObj?.ComponentId > 0) { setSelectedId(dataObj?.Component + ";" + dataObj?.ComponentId); return getComponentData(dataObj?.ComponentId) }
        if (dataObj?.UnitId > 0) { setSelectedId("Unit:" + unitData?.Unit + ";" + dataObj?.UnitId); return getUnitData(dataObj?.UnitId) }
    }

    useEffect(() => {
        refreshData()
    }, [])

    const closeDialog = () => {
        setDialogType({ ...dialogType, open: false });
    }

    const addNew = (actionName, row) => {
        setDialogType({ type: actionName, open: true, data: row });
    }

    const handleEditClick = (rowData) => {
        const eventData = {
            product: unitData?.product || unitData?.Product,
            productId: unitData?.ProductId,
            customer: unitData?.customer || unitData?.Customer,
            customerId: dataObj?.customerId || dataObj?.CustomerId,
            site: dataObj?.site || unitData?.Site,
            siteId: dataObj?.siteId || dataObj?.SiteId,
            unit: dataObj?.unit || unitData?.Unit,
            unitId: dataObj?.unitId || dataObj?.UnitId,
            componentId: dataObj?.componentId || dataObj?.ComponentId,
            sampleNumber: dataObj?.sampleNumber || rowData?.SampleNumber,
            ...rowData
        }
        console.log(rowData, dataObj)
        addNew("edit-event", eventData)
    }

    const dialogOptions = useScreenPopups({
        dialogType: dialogType.type,
        data: dialogType.data || {},
        cancel: closeDialog,
        fetchData: refreshData
    });

    const filteredTableData = useMemo(
        function () {
            return hideEvents
                ? tableData.filter((x) => x.EventTypeId !== 3)
                : tableData;
        },
        [hideEvents, tableData]
    );

    return (
        <>
            <Grid container sx={{ minWidth: "90vw" }} spacing={1}>
                <Grid container sx={{ my: 1 }}>
                    <Grid item xs={2}>
                        <DropDown
                            name="scope"
                            label="Scope"
                            selectOptions={(ddList || [])?.map((x) => ({
                                value: x.Name + ";" + x.Id,
                                text: x.Name,
                            }))}
                            value={selectedId}
                            onChange={handelFilterChange}
                            required />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                        <SwitchInput
                            label="Show All Scopes"
                        />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                        <SwitchInput
                            label="Hide Fluid Change Events"
                            value={hideEvents}
                            onChange={(e) => setHideEvents(e.target.checked)}
                        />
                    </Grid>
                    <Grid item alignSelf="flex-end">
                        <Button
                            variant="outlined"
                            onClick={() => addNew("edit-event", { ...dataObj, addNew: true })}
                        >New</Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle2">
                        <b>{tableData?.length || 0}</b> event(s) were found for Unit/Component
                    </Typography>
                    <ViewEventsTable data={filteredTableData} addNew={handleEditClick} />
                </Grid>
            </Grid>
            <GroupDialog
                {...dialogOptions}
                open={dialogType.open || false}
                close={closeDialog}
            />
        </>
    );
}
