import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function TestSetsTable({
  data,
  actions,
  rowSelected,
  selectedRow,
  height = 750,
}) {
  const options = {
    columns: GetColumns(actions),
    dataSource: data || [],
    rowIdProperty: "TestSetId",
    // dataSource: (data || []).map((x) => ({
    //   ...x,
    //   keyProp: x.TestSetId + "" + x.FluidTypeId + "" + (x.TestSet || ""),
    // })),
    // rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "productCommonCellColour",
    sx: { height },

    rowsSelected: function (r) {
      r.length && rowSelected && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
  };
  return <DataTable {...options} />;
}
function GetColumns() {
  return [
    {
      headerName: "Test Set",
      field: "TestSet",
      sortable: false,
      minWidth: 260,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Fluid",
      field: "FluidType",
      sortable: false,
      width: 100,
    },
    {
      headerName: "Specific",
      field: "IsCustomerSpecific",
      sortable: false,
      type: "boolean",
      flex: 1,
    },
    {
      headerName: "Product",
      field: "Product",
      sortable: false,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}
