import DataTable from "../../../components/DataTable";
import { GetCreateTraysColumns } from "./GetCreateTraysColumns";

export default function CreateTrayTable(props) {
  const options = {
    ...props,
    columns: GetCreateTraysColumns(props),
    dataSource: props.data,
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    minHeight: 70,
    heightValue: 100,
  };
  return <DataTable {...options}></DataTable>;
}
