import { Button, Card, Grid, Box, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import DropDown from "../../../components/DropDown";
import DropDownAdvance from "../../../components/DropDownAdvance";
import AppDialog from "../../../components/AppDialog";
import LinkFluidWatch from "./LinkFluidWatch";
import { List } from "@mui/material";
import { DETAILS_APIS } from "../../../common/apis";
import { useRoot, useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import { SaveCommentsIcon, DeleteIcon, LinkIcon, } from "../../../icons/ButtonIcons";
import AlertDialog from "../../../components/AlertDialog";
import useQuery from "../../../hooks/useQuery";

export default function FluidWatch({
  fluidId,
  isShowTitle,
  fluidRow,
  setFluidWatchOpen,
}) {
  const { post, get } = useFetch();
  const { showToast, showErrorToast } = useToast();
  const useRootFLuidTypes = useRoot();
  const { CanDoSpecialReports, CanEvaluateNotTrainee } = useRoot();

  const [fluids, setFluids] = useState({});
  const [fluidIdFilter, setFluidIdFilter] = useState();
  const [fluidIds, setFluidId] = useState(fluidId);
  const [masterData, setMasterData] = useState({});
  const [selectedFluid, setSelectedFluid] = useState({});
  const [selectedFluidType, setSelectedFluidType] = useState();
  // const [selectedFluidId, setSelectedFluidId] = useState();
  const [validationError, setValidationError] = useState("");
  const [addLinkDialogueOpen, setAddLinkDialogueOpen] = useState(false);
  const [linksList, setLinksList] = useState([]);
  const [alertActionName, setAlertActionName] = useState(null);
  const [alertTitle, setAlertTitle] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);

  const [alertWidth, setAlertWidth] = useState("");
  const { urlfluidId } = useQuery();

  // get fluid types and fluid data
  useEffect(
    function () {
      if (isShowTitle) {
        document.title = "Fluid Watch";
      }
      (async function () {
        const masterDataTemp = useRootFLuidTypes;
        setMasterData(masterDataTemp);

        if (!fluidIds && !urlfluidId) {
          await refreshFluids(1);
          setSelectedFluidType(1);
          const temp = await getFluidData(
            (fluids[selectedFluidType] || []).find(
              (x) => x.FluidId === selectedFluid.FluidId
            )
          );
          setSelectedFluid(temp);
        }

        if (urlfluidId) {
          const temp = await getFluidData(urlfluidId);
          if (Array.isArray(temp)) {
            const tempArray = temp[0];
            await refreshFluids(tempArray.FluidTypeId);
            setSelectedFluid(tempArray);
            return setSelectedFluidType(tempArray.FluidTypeId);
          } else if (typeof temp === "object") {
            await refreshFluids(temp.FluidTypeId);
            setSelectedFluid(temp);
            return setSelectedFluidType(temp.FluidTypeId);
          }
        }
        if (fluidIds) {
          const temp = await getFluidData(fluidIds);
          if (Array.isArray(temp)) {
            const tempArray = temp[0];
            await refreshFluids(tempArray.FluidTypeId);
            setSelectedFluid(tempArray);
            setSelectedFluidType(tempArray.FluidTypeId);
          } else if (typeof temp === "object") {
            await refreshFluids(temp.FluidTypeId);
            setSelectedFluid(temp);
            setSelectedFluidType(temp.FluidTypeId);
          }
        } else {
        }
      })();
    },
    [useRootFLuidTypes]
  );

  //Call Fluids links data when fluid id change.
  async function getFluidLinkData() {
    if (fluidIds) {
      const fluidLinkData = await get(
        `${DETAILS_APIS.GET_FLUID_WATCH_LINKS}?FluidId=${fluidIds || 0}`,
        { skipBlocker: true }
      );
      setLinksList(fluidLinkData);
    }
  }
  useEffect(() => {
    getFluidLinkData();
  }, [fluidIds]);

  function handleFluidUpdated() {
    getFluidLinkData();
  }

  async function refreshFluids(fType) {
    if (!fType || fluids[fType]) return;
    const temp = await get(`${DETAILS_APIS.WATCH_BY_TYPE}?typeId=${fType}`, {
      skipBlocker: true,
    });
    setFluids({ ...fluids, [fType]: temp });
  }

  function fluidTypeChangeHandler(event) {
    setSelectedFluidType(event.target.value);
    setSelectedFluid({});
    refreshFluids(event.target.value);
  }

  //fluid dropdown change
  async function fluidChangeHandler(e, value) {
    // const fId = (value || {}).FluidId;
    setFluidId(value);
    const fId = value;
    setFluidIdFilter(fId);
    const temp = await getFluidData(fId);
    setSelectedFluid({ ...(temp || {}), FluidId: fId });
    // setLinksList([]);
  }

  //Get Fluid existing controls data
  async function getFluidData(fId) {
    if (!fId) return {};
    return (
      (await get(`${DETAILS_APIS.GET_WATCH_DATA}?fluidId=${fId}`, {
        skipBlocker: true,
      })) || {}
    );
  }

  //Save new fluid
  function SaveClick() {
    if (fluidIds === null) {
      setValidationError("Please select a fluid."); // Set validation error message if fluid is not selected
    } else {
      setValidationError(""); // Clear validation error if fluid is selected
      // Perform the action you want on button click

      (async () => {
        const response = await post(DETAILS_APIS.SAVE_FLUID_WATCH, {
          FluidId: fluidIds,
          FluidWatchId: selectedFluid.FluidWatchId || fluidIdFilter,
          WatchNotes: selectedFluid.WatchNotes,
          WatchDetail1: selectedFluid.WatchDetail1,
          WatchDetail2: selectedFluid.WatchDetail2,
          Screen: "Fluid Watch",
        });
        if (response > 0) {
          showToast(appResources.FLUID_WATCH_ADDED_SUCCESSFULLY_MSG);
          setFluidWatchOpen && setFluidWatchOpen(false);
        } else {
          showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
          setFluidWatchOpen && setFluidWatchOpen(false);
        }
      })();
    }
  }

  //Delete new fluid
  function deleteDialogue() {
    setAlertTitle("Fluid Watch");
    setAlertActionName("DeleteClick");
    setAlertWidth("md");
    setOpenDialog(true);
    setAlertMessage(appResources.DELETE_FLUID_WATCH_CONFIRMATION_MSG);
  }

  function DeleteClick() {
    (async () => {
      const response = await get(
        `${DETAILS_APIS.DELETE_FLUID_WATCH}?FluidId=${selectedFluid.FluidId}&Screen="Fluid Watch"`
      );
      if (response > 0) {
        showToast(appResources.FLUID_WATCH_DELETED_SUCCESSFULLY_MSG);
        setFluidWatchOpen && setFluidWatchOpen(false);
      } else {
        showErrorToast(appResources.SOMETHING_WENT_WRONG_MSG);
        setFluidWatchOpen && setFluidWatchOpen(false);
      }
    })();
    setOpenDialog(false);
  }

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" {...props} key={option.FluidId}>
        {option.FluidFormatted}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options.filter((x, i) => i < 100)
      : options
        .filter((x) =>
          x.FluidFormatted.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
        .filter((x, i) => i < 100);
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.FluidFormatted || "";
  }, []);

  const fluidOptions = useMemo(
    function () {
      if (fluids && fluids[selectedFluidType]) {
        return (
          fluids[selectedFluidType].filter(
            (x) => x.FluidTypeId === selectedFluidType
          ) || []
        );
      }
      return [];
    },
    [fluids, selectedFluidType]
  );

  const fluidType = useMemo(
    function () {
      if (masterData.fluidTypes && selectedFluidType) {
        return (
          masterData.fluidTypes.find(
            (x) => x.FluidTypeId === selectedFluidType
          ) || {}
        );
      }
      return [];
    },
    [selectedFluidType]
  );

  function watchOnchangeHandler(e) {
    setSelectedFluid({ ...selectedFluid, [e.target.name]: e.target.value });
  }

  function handelOpenDialogueClick() {
    setAddLinkDialogueOpen(true);
  }

  return (
    <>
      <Card
        sx={{ p: 1, overflow: "auto", minWidth: "75vw", minHeight: "40vh" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DropDown
              label="Fluid Type"
              // required={true}
              onChange={fluidTypeChangeHandler}
              key={selectedFluid?.FluidTypeId || selectedFluidType}
              selectOptions={(masterData.fluidTypes || []).map((x) => ({
                text: x.FluidType,
                value: x.FluidTypeId,
              }))}
              value={selectedFluid?.FluidTypeId || selectedFluidType}
            />
          </Grid>
          <Grid item xs={6}>
            <DropDownAdvance
              label="Fluid"
              renderOptions={renderOptions}
              filterOptions={filterOptions}
              getOptionLabel={getOptionLabel}
              onChange={(e, fluid) =>
                fluidChangeHandler(e, (fluid || {}).FluidId || null)
              }
              options={fluidOptions || []}
              key={selectedFluidType}
              defaultValue={
                (fluids[selectedFluidType] || []).find(
                  (x) => x.FluidId === selectedFluid.FluidId
                ) || fluidIds
              }
            />
            {validationError && (
              <Typography style={{ color: "red" }}>
                {validationError}
              </Typography>
            )}
          </Grid>
          {fluidType.DetailsLabel1 && (
            <Grid item xs={3}>
              <TextBox
                name="WatchDetail1"
                label={fluidType.DetailsLabel1}
                value={selectedFluid.WatchDetail1 || ""}
                onChange={watchOnchangeHandler}
              />
            </Grid>
          )}
          {fluidType.DetailsLabel2 && (
            <Grid item xs={3}>
              <TextBox
                name="WatchDetail2"
                label={fluidType.DetailsLabel2}
                value={selectedFluid.WatchDetail2 || ""}
                onChange={watchOnchangeHandler}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextBox
              label="Notes"
              name="WatchNotes"
              multiline
              rows={4}
              value={selectedFluid.WatchNotes || ""}
              onChange={watchOnchangeHandler}
            />
          </Grid>
          {(CanEvaluateNotTrainee) && <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              {CanDoSpecialReports && <Grid item>
                <Button
                  onClick={deleteDialogue}
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  disabled={!selectedFluid.FluidWatchId}
                >
                  Delete
                </Button>
              </Grid>}
              <Grid item>
                <Button
                  item
                  variant="contained"
                  startIcon={<SaveCommentsIcon />}
                  onClick={SaveClick}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={handelOpenDialogueClick}
                  startIcon={<LinkIcon />}
                  variant="outlined"
                >
                  Links
                </Button>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
      </Card>

      <List>
        {linksList.map((link) => (
          <>
            <a href={link.Link} target="_blank" rel="noreferrer">
              {link.LinkDescription}
            </a>
            <br></br>
          </>
        ))}
      </List>
      <AppDialog
        open={addLinkDialogueOpen}
        handleClose={() => setAddLinkDialogueOpen(false)}
        title="Links for Fluid Watch"
        maxWidth="sm"
      >
        <LinkFluidWatch
          fluidId={selectedFluid.FluidId}
          onFluidLinkUpdated={handleFluidUpdated}
          setAddLinkDialogueOpen={setAddLinkDialogueOpen}
        // linksList={linksList}
        // setLinksList={setLinksList}
        />
      </AppDialog>
      <AppDialog
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={alertTitle}
        maxWidth={alertWidth}
      >
        {alertTitle === "Fluid Watch" && (
          <AlertDialog
            handleClose={() => {
              setOpenDialog(false);
            }}
            alertAction={DeleteClick}
            message={alertMessage}
          />
        )}
      </AppDialog>
    </>
  );
}
