import { Typography } from "@mui/material";
import DataTable, { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";

export default function CustomerTable({
  data,
  rowSelected,
  actions,
  rowActionHandler,
}) {
  const options = {
    columns: GetColumns(actions, { rowActionHandler }),
    dataSource: data,
    rowIdProperty: "CustomerId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options}></DataTable>;
}
const GetColumns = (actions, { rowActionHandler }) => [
  {
    headerName: "Product",
    field: "Product",
    minWidth: 150,
    renderCell: function ({ row }) {
      return (
        <>
          {(actions || []).map(function (ac, i) {
            return (
              <DataGridAction
                key={i}
                row={row}
                onClick={ac.action}
                label={ac.label}
                startIcon={ac.startIcon}
              />
            );
          })}
          <DropdownMenu
            options={Options}
            onItemSelect={(option) => rowActionHandler(row, option)}
          />
          <Typography paddingTop={0.25}>{row.Product}</Typography>
        </>
      );
    },
  },
  {
    headerName: "Product",
    field: "Product",
    width: 140,
  },
  {
    headerName: "Customer",
    field: "Customer",
    width: 140,
  },
  {
    headerName: "Physical",
    field: "PhysicalCity",
    width: 140,
  },
  {
    headerName: "Postal",
    field: "PostalCity",
    width: 140,
  },
  {
    headerName: "Archived",
    field: "Archived",
    width: 140,
  },
  // {
  //   headerName: "Actions",
  //   field: "Actions",
  //   sortable: false,
  //   //hide: (actions || []).length < 1,
  //   flex: 1,
  //   renderCell: function ({ row }) {
  //     return (
  //       <>
  //         {(actions || []).map(function (ac, i) {
  //           return (
  //             <DataGridAction
  //               key={i}
  //               row={row}
  //               onClick={ac.action}
  //               label={ac.label}
  //               startIcon={ac.startIcon}
  //             />
  //           );
  //         })}

  //         <DropdownMenu
  //           options={Options}
  //           onItemSelect={(option) => rowActionHandler(row, option)}
  //         />
  //       </>
  //     );
  //   },
  // },
];

const Options = [
  { label: "Search Site", name: "search-site" },
  { label: "Search Unit", name: "search-unit" },
  { label: "Search Component", name: "search-component" },
  { label: "Search Sample", name: "search-sample" },
  { label: "Unit No Recent Samples", name: "unit-recent-report" },
  { label: "Components No Recent Samples", name: "comp-recent-report" },
  // To Handle Add New Items in details/quick
  { label: "New Customer", name: "add-new-customer" },
  { label: "New Site", name: "add-new-site" },
  { label: "New Unit", name: "add-new-unit" },
  { label: "New Component", name: "add-new-component" },
];
