import { toLocalDateTime } from "../../../../utils";
import DataTable, {
  DataGridAction,
  DataGridCheckBox,
} from "./../../../../components/DataTable";

export default function ContactAccessSubscriptionsTable({
  data,
  rowSelected,
  ...props
}) {
  const options = {
    ...props,
    columns: GetColumns(deleteClick),
    dataSource: data,
    rowIdProperty: "SubscriptionId",
    isDisableColumnFilter: true,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
  };
  return <DataTable {...options}></DataTable>;
}
async function deleteClick(row) {}

function GetColumns(deleteClick) {
  return [
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 120,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },

    {
      headerName: "Scope",
      field: "LevelText",
      minWidth: 150,
    },
    {
      headerName: "Report",
      field: "Report",
      minWidth: 150,
    },
    {
      headerName: "Monthly",
      field: "IsMonthly",
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
      minWidth: 150,
    },
    {
      headerName: "Added",
      field: "Added",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      minWidth: 200,
    },
    {
      headerName: "LastSent",
      field: "LastSent",
      minWidth: 150,
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 150,
    },
    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 150,
      flex: 1,
    },
  ];
}
