import DataTable from "../../../components/DataTable";
import { RerunSamplesColumns } from "./RerunSamplesColumns";

export default function RerunSampleTable(props) {
  const options = {
    columns: RerunSamplesColumns,
    dataSource: (props.data || []).map((x) => ({
      ...x,
      keyProp: x.SampleNumber + "" + (x.Dummy1 || "") + "" + (x.SentBack || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    sx: { height: 600 },
  };
  return <DataTable {...options}></DataTable>;
}
