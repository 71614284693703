import React from "react";
import { Button, Grid, Typography } from "@mui/material";

const ConfirmModal = ({
  handleClose,
  alertAction,
  message,
  buttonText,
  buttonText2,
}) => {
  const handleClick = () => {
    alertAction();
  };

  const formatMessage = (message) => {
    const lines = message?.split("\n");
    return lines?.map((line, index) => (
      <Typography variant="subtitle1" key={index}>
        {line}
      </Typography>
    ));
  };

  return (
    <>
      <Grid
        sx={{
          p: 0.25,
          minWidth: "28vw",
          maxWidth: "30vw",
          overflow: "auto",
        }}
      >
        <Grid container sx={{ my: 3 }}>
          <Grid item>{formatMessage(message)}</Grid>
        </Grid>

        <Grid conatainer sx={{ display: "flex", justifyContent: "end" }}>
          {buttonText && <Grid item marginRight={0.5}>
            <Button
              sx={{
                backgroundColor: buttonText === "Delete" ? "#E1464a" : "#253FC8",
                "&:hover": {
                  backgroundColor: buttonText === "Delete"
                    ? "#E1464a"
                    : "#253FC8",
                },
              }}
              onClick={handleClick}
            >
              {buttonText || "Yes"}
            </Button>
          </Grid>}

          <Grid item marginLeft={0.5}>
            <Button variant="outlined" onClick={handleClose}>
              {buttonText2 && buttonText2 ? buttonText2 : "No"}
            </Button>
          </Grid>
        </Grid>

      </Grid>
    </>
  );
};

export default ConfirmModal;
