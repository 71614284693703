import { Box, Typography, Button } from "@mui/material";
import moment from "moment";
import { memo, useCallback, useEffect, useReducer } from "react";
import Form, { FormActions } from "../../../components/Form";
import {
  SearchIcon,
  TickGreenIcon,
} from "../../../icons/ButtonIcons";

export default memo(function SearchFilter({
  onView,
  infoMessage,
  successMessage,
  isInfoIcon,
  isSuccessIcon,
  isWarningTrue,
}) {
  const [data, dispatch] = useReducer(
    SearchFilterReducer,
    SearchFilterDefaults
  );
  const actions = {
    view: () => {
      onView(data); localStorage.setItem("local06", JSON.stringify({ ...data, CreationDate: moment.utc().format("YYYYMMDD") }))
    },
  };
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F1") {
        event.preventDefault();
        actions.view();
      }
    },
    [data]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    let trayLocalData = localStorage.getItem("local06");
    if (trayLocalData !== null) {
      let checkDate = moment.utc().format("YYYYMMDD")
      trayLocalData = JSON.parse(trayLocalData);
      if (checkDate === trayLocalData?.CreationDate) {
        dispatch({
          payload: { ...trayLocalData, },
          type: EventActions.LOAD_DATA,
        });
        onView(trayLocalData)
      }
      if (checkDate !== trayLocalData?.CreationDate) {
        onView(data)
      }
    }
    if (trayLocalData === null) {
      onView(data)
    }
  }, []);

  return (
    <Form
      sections={GetFormSections(
        data,
        infoMessage,
        successMessage,
        isInfoIcon,
        isSuccessIcon,
        isWarningTrue,
        actions
      )}
      data={data}
      spacing={2}
      dispatch={dispatch}
    />
  );
});

function GetFormSections(
  data,
  infoMessage,
  successMessage,
  isInfoIcon,
  isSuccessIcon,
  isWarningTrue,
  { view }
) {
  return [
    {
      fluid: true,
      fields: [
        {
          name: "Date",
          type: "date",
          disabled: data.LabAndDateOption === 1 || data.LabAndDateOption === 3,
        },

        {
          name: "LabAndDateOption",
          type: "dropdown",
          xsCol: 3,
          required: true,

          selectOptions: DateOptions,
        },
        {
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={view}
                startIcon={<SearchIcon />}
              >
                View (F1)
              </Button>
            );
          },
        },
        {
          flex: 1,
          alignSelf: "center",
          component: function () {
            return (
              <Typography sx={{ fontSize: 11 }}>
                {isInfoIcon && (
                  <Box
                    component="div"
                    sx={{ color: isWarningTrue ? "red" : "" }}
                  >
                    {infoMessage}
                  </Box>
                )}
                {isSuccessIcon && (
                  <Box
                    component="div"
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  >
                    {<TickGreenIcon />}
                    <Box component="div" sx={{ ml: 1 }}>
                      {successMessage}
                    </Box>
                  </Box>
                )}
              </Typography>
            );
          },
        },
      ],
    },
  ];
}

const EventActions = {
  LOAD_DATA: "load-data",
};
function SearchFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === EventActions.LOAD_DATA) {
    return { ...action.payload };
  } return state;
}

const SearchFilterDefaults = {
  Date: moment().toISOString(),
  LabAndDateOption: 3,
};
const DateOptions = [
  { value: 0, text: "By Date for Laboratory" },
  { value: 1, text: "ALL for Laboratory" },
  { value: 2, text: "By Date for ALL Laboratories" },
  { value: 3, text: "ALL for ALL Laboratories" },
];
