import DataTable from "../../../components/DataTable";
import moment from "moment/moment";
import { TrayDateFormat, UserDateFormat } from "../../../utils";

export default function MissedSampleDisplayTable(props) {
  const options = {
    columns: Columns,
    dataSource: props.data,
    rowIdProperty: "TrayStem",
    isDisableColumnFilter: true,
    cellStylingHandler: "missedSamplesTable",
    sx: { height: 640 },
    columnGroupingModel: Groups,
    experimentalFeatures: { columnGrouping: true },
  };
  return <DataTable {...options}></DataTable>;
}
const Groups = [
  {
    groupId: "Registration",
    children: [{ field: "missed1" }, { field: "hold1" }],
  },
  {
    groupId: "Laboratory",
    children: [{ field: "missed2" }, { field: "hold2" }],
  },
  {
    groupId: "Quality-Check",
    children: [{ field: "missed3" }, { field: "hold3" }],
  },
  {
    groupId: "Auto-Evaluate",
    children: [{ field: "missed4" }, { field: "hold4" }],
  },
  {
    groupId: "Evaluate",
    children: [{ field: "missed5" }, { field: "hold5" }],
  },
  {
    groupId: "Approval",
    children: [{ field: "missed6" }, { field: "hold6" }],
  },
  {
    groupId: "Total",
    children: [
      { field: "missedTotal" },
      { field: "holdTotal" },
      { field: "total" },
    ],
  },
];

export const Columns = [
  {
    headerName: "Laboratory",
    field: "Laboratory",
    sortable: false,
    minWidth: 120,
    flex: 1,
  },
  {
    headerName: "Tray",
    field: "Tray",
    sortable: false,
    width: 80,
  },
  {
    headerName: "Tray Date",
    field: "TrayDate",
    valueFormatter: (params) =>
      moment(params.value, TrayDateFormat).format(UserDateFormat),
    sortable: false,
    width: 120,
  },
  {
    headerName: "Missed",
    field: "missed1",
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: "Hold",
    field: "hold1",
    sortable: false,
    minWidth: 80,
  },
  {
    headerName: "Missed",
    field: "missed2",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Hold",
    field: "hold2",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Missed",
    field: "missed3",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Hold",
    field: "hold3",
    sortable: false,
    minWidth: 90,
  },

  {
    headerName: "Missed",
    field: "missed4",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Hold",
    field: "hold4",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Missed",
    field: "missed5",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Hold",
    field: "hold5",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Missed",
    field: "missed6",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Hold",
    field: "hold6",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Missed",
    field: "missedTotal",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "Hold",
    field: "holdTotal",
    sortable: false,
    minWidth: 90,
  },
  {
    headerName: "All",
    field: "total",
    sortable: false,
    width: 70,
  },
];
