import DataTable from "../../../components/DataTable";

export default function ResultsTable({
  data = [],
  columns = [],
  rowEditHandler,
  rowSelected,
  selectedRow,
}) {

  const options = {
    opacityCheck: true,
    columns: columns,
    dataSource: data,
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    cellStylingHandler: "sampleResult",
    selectionModel: selectedRow,
    minHeight: 30,
    heightValue: 100,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    rowEditHandler: rowEditHandler,
    experimentalFeatures: { newEditingApi: true },
    onProcessRowUpdateError: console.error,
  };
  return <DataTable {...options} />;
}
