import { Button, Card } from "@mui/material";
import Form, { SectionTitle } from "../../../components/Form";
import { Edit, NotificationsNone } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import AppDialog from "../../../components/AppDialog";
import { useEffect, useState } from "react";
import EditCustomer from "../../../forms/EditCustomer";
import useFetch from "../../../hooks/useFetch";
import { DETAILS_APIS } from "../../../common/apis";
import productColors from "../../../common/ProductColors";
import { connect } from 'react-redux';

// function CustomerDetail({ data, customerDetails }) {
function CustomerDetail({ customerDetails }) {
  const { post } = useFetch();
  const [editCustOpen, setEditCustOpen] = useState(false);
  const [isNotify, setIsNotify] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setData(customerDetails)
  }, [customerDetails])

  useEffect(() => {
    if (data?.NotificationUserId !== null) {
      return setIsNotify(true)
    }
    else {
      return setIsNotify(false)
    }
  }, [data?.NotificationUserId])

  const getMachineInfo = async () => {
    let dataList = await post(DETAILS_APIS.UPDATE_MACHINE_NOTIFICATIONS,
      { CustomerId: data?.CustomerId, HasNotification: isNotify })
    if (dataList?.Success === true) {
      return setIsNotify(!isNotify)
    }
  }

  const actions = {
    edit: function (e) {
      setEditCustOpen(true);
    },
    notifyMe: function () {
      getMachineInfo()
    },
  };

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections(data)}
          data={data}
          spacing={2}
          key={data && data.CustomerId + new Date()}
        />
        <FormFooter buttons={GetFormButtons(isNotify, actions)} />
      </Card>
      <AppDialog
        open={editCustOpen}
        title="Edit Customer"
        maxWidth="lg"
        handleClose={() => setEditCustOpen(false)}
      >
        <EditCustomer
          cancel={() => setEditCustOpen(false)}
          custId={data.CustomerId}
        //prodId={data.ProductId}
        />
      </AppDialog>
    </>
  );
}

const mapStateToProps = ({ customer }) => {
  const { customerDetails, } = customer;
  return { customerDetails, };
};

export default connect(mapStateToProps, null)(CustomerDetail);

const GetFormButtons = (isNotify, { notifyMe, edit }) => [
  {
    variant: "outlined",
    label: isNotify === true ? "Unnotify" : "Notify Me",
    onClick: notifyMe,
    startIcon: <NotificationsNone />,
  },
  { variant: "contained", label: "Edit", onClick: edit, startIcon: <Edit /> },
];

const FormSections = (data) => {
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          component: function () {
            return <SectionTitle title="Customer Details" />;
          },
        },
        { name: "Customer", label: "Customer", xsCol: 4, type: "text", readOnly: true },
        { name: "CmsOwnerName", label: "CMS Owner", xsCol: 4 },
        { name: "Product", label: "Product", xsCol: 4, sx: { [`.MuiFilledInput-root`]: productColors(data?.Product) } },
        { name: "AccountNumber", label: "Account", xsCol: 4 },
        { name: "SapCustomer", label: "Acc Name", xsCol: 4 },
        { name: "ExportOption", label: "Exports", xsCol: 4 },
        { name: "Parent", label: "Parent", xsCol: 4 },
        {
          xsCol: 4,
          component: function () {
            return <Button disabled={!data?.Parent} sx={{ mt: 1.5 }}>Search Parent</Button>;
          },
        },
        {
          xs: 12, group: [
            { name: "AlternativeNames", label: "Known As", xsCol: 4, type: "textarea", },
            { name: "CustomerNotes", label: "Comment", xsCol: 8, type: "textarea" },
          ]
        }
      ],
    },
  ]
}
