
import { Button } from "@mui/material";
import { MaterialReactTable } from "material-react-table";

export default function NewDataTable({
    columns,
    dataSource,
    sx,
    minHeight,
    heightValue,
    rowStylingHandler,
    cellStylingHandler,
    ...props
}) {

    return (
        <MaterialReactTable
            muiTableContainerProps={{ sx: sx || { maxHeight: '500px' } }}
            muiTablePaperProps={{
                sx: {
                    border: "1px solid #253FC8",
                    borderRadius: "5px",
                    padding: "2px",
                    [`.MuiTableRow-root:hover td`]: { backgroundColor: "#8238ba", color: "white" }
                }
            }}
            muiTableBodyProps={{
                sx: {
                    padding: 0,
                    //stripe the rows, make odd rows a darker color
                    "& tr:nth-of-type(even)": {
                        backgroundColor: "#e2f2ff",
                    },
                },
            }}
            muiTableHeadCellProps={{
                align: 'left',
                sx: { borderRight: "1px solid #E0E0E0", py: 0.5, }
            }}
            muiTableBodyRowProps={({ row }) => ({
                // onClick: row.getToggleSelectedHandler(),
                sx: {
                    cursor: 'pointer',
                    //  "&:hover": {
                    //     backgroundColor: "blue !important"
                    // }
                },
            })}
            muiTableBodyCellProps={{
                sx: {
                    py: 0.25,
                }
            }}
            initialState={{
                density: "compact",
                // columnPinning: { left: ["Cleanup"] },
            }}
            defaultColumn={{
                maxSize: 500,
                minSize: 10,
                size: 150, //default size is usually 180
            }}
            // enableRowSelection
            getRowId={(row) => row.userId} //give each row a more useful id
            // muiTableBodyRowProps={({ row }) => ({
            //     //add onClick to row to select upon clicking anywhere in the row
            //     onClick: row.getToggleSelectedHandler(),
            //     sx: { cursor: 'pointer' },
            // })}
            onRowSelectionChange={() => { console.log("changes") }} //connect internal row selection state to your own
            hoveredRow={""}
            enableSorting={props?.enableSorting}
            enableColumnActions={props?.enableColumnActions}
            enableFilterMatchHighlighting={true}
            columns={columns}
            data={dataSource}
            enableHiding={false}
            enableColumnFilters={false}
            showColumnFilters={false}
            columnResizeMode="onChange" //default
            enablePagination={false} //footer pagination
            enableGlobalFilter={false} //disable search feature
            enableFullScreenToggle={false} //full screen icon
            enableDensityToggle={false} //resize table
            editingMode="cell" //to edit cell
            enableTopToolbar={false} //hide top toolbar
            enableBottomToolbar={false}
            enableRowVirtualization
            rowVirtualizerProps={{
                overscan: 10, //adjust the number or rows that are rendered above and below the visible area of the table
                estimateSize: () => 100, //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
            }}
            {...props}
        />
    );
}

export function NewDataGridAction({
    label = "",
    startIcon,
    onClick,
    row,
    className,
    color,
    disabled,
    minWidth,
    height, }) {
    return (
        <Button
            variant={className || "outlined"}
            color={color}
            onClick={(e) => onClick && onClick(row, e)}
            startIcon={startIcon}
            sx={{
                height: height || "18px",
                padding: 0.125,
                minWidth: minWidth,
                backgroundColor: "white",
            }}
            disabled={disabled || false}
        >
            {label}
        </Button>
    );
}