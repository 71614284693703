import { useMemo } from "react";
import UnitHourHistory from "../features/quick-details/unit-hour-history/UnitHourHistory";
import QuickDetails from "../features/quick-details/QuickDetails";
import AddCriticalLogs from "../features/reports/critical-logs/add-critical-logs/AddCriticalLogs";
import HoldManagement from "../features/management/HoldManagement";
import ImagePick from "./SelectImage";
import EditSample from "../forms/EditSample";
import AccessPermissionOverview from "../features/reports/critical-logs/access-permission-overview/AccessPermissionOverview";
import SampleReportsOverview from "../features/management/sample-report-overview/SampleReportsOverview";
import EditUnit from "../forms/EditUnit";
import EditSite from "../forms/EditSite";
import EditCustomer from "../forms/EditCustomer";
import SubscriptionReports from "./SubscriptionReports";
import PrimaryContactOverview from "../features/management/primary-contact-overview/PrimaryContactOverview";
import ContactExports from "../features/reports/critical-logs/contact-permission-overview/ContactExports";
import EditComponent from "../forms/EditComponent";
import EditEvent from "../features/events/EditEvent";
import SubscriptionOverview from "../features/quick-details/subscription/SubscriptionOverview";
import ComponentsRecentSamples from "../features/quick-details/recent-samples/ComponentsRecentSamples";
import UnitsRecentSamples from "../features/quick-details/recent-samples/UnitsRecentSamples";
import RecentUnitResults from "../features/quick-details/recent-unit-result/RecentUnitResults";
import ManageCustomer from "../features/quick-details/manage/manage-customer/ManageCustomer";
import ManageSample from "../features/quick-details/manage/manage-sample/ManageSample";
import ManageSite from "../features/quick-details/manage/manage-site/ManageSite";
import ManageUnit from "../features/quick-details/manage/manage-unit/ManageUnit";
import ManageComponent from "../features/quick-details/manage/manage-component/ManageComponent";
import RecentComponentResults from "../features/quick-details/recent-component-result/RecentComponentResults";
import SearchRegistration from "../features/quick-details/registrations/SearchRegistration";
import { CheckCircle } from "@mui/icons-material";
import { EditIcon } from "../icons/ButtonIcons";
import SearchDetails from "../features/quick-details/SearchDetails";
import SearchFluid from "../features/master-data/fluids/SearchFluid";
import SearchGeneral from "../features/details-old/SearchGeneral";
import EditTray from "../forms/EditTray";
import SpecialReport from "../forms/SpecialReport";
import AddEvent from "../features/events/AddEvent";
import UpdateTrayLabel from "../forms/UpdateTrayLabel";
import ManagementReport from "../features/management/ManagementReport";
import EditBillingRates from "../features/management/EditBillingRates";
import GlobalSettings from "../features/management/GlobalSettings";
import ProductNotifications from "../features/management/Notifications/ProductNotifications";
import FluidNotifications from "../features/management/Notifications/FluidNotifications";
import ViewEvents from "../features/events/ViewEvents";

export function useScreenPopups({ dialogType, data, cancel, ...props }) {
  return useMemo(
    function () {
      if (dialogType === DialogTypes.UNIT_HOUR_HISTORY) {
        return {
          title: "Unit Hour History",
          fullWidth: true,
          width: "100vw",
          component: <UnitHourHistory unitId={data?.UnitId} />,
        };
      }

      if (dialogType === DialogTypes.PRIMARY_CONTACTS_OVERVIEW) {
        return {
          title: "Primary Contacts Overview",
          fullWidth: true,
          width: "100vw",
          component: <PrimaryContactOverview formData={data} />,
        };
      }

      if (dialogType === DialogTypes.REPORT_CONTACTS) {
        return {
          title: "Sample Reporting Overview",
          width: "100vw",
          fullWidth: true,
          component: <SampleReportsOverview formData={data} />,
        };
      }

      if (
        dialogType === DialogTypes.WEBSITE_CONTACTS ||
        dialogType === DialogTypes.ACCESS_PERMISSION_OVERVIEW
      ) {
        return {
          title: "Access Permission Overview",
          fullWidth: true,
          width: "100vw",
          component: <AccessPermissionOverview formData={data} />,
        };
      }

      if (dialogType === DialogTypes.CRITIICAL_LOG) {
        return {
          title: `Critical Log ${data?.SampleNumber && data?.SampleNumber ? "for " + data?.SampleNumber : ""}`,
          width: "100vw",
          component: <AddCriticalLogs dataObj={data} />,
        };
      }

      if (dialogType === DialogTypes.HOLD_MANAGEMENT) {
        return {
          title: "Hold Management",
          width: "xl",
          component: (
            <HoldManagement
              SampleNumber={data?.SampleNumber}
              unitId={data?.UnitId}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.VIEW_IN_QUICK_DETAILS) {
        return {
          title: "Quick Details",
          width: "xl",
          component: <QuickDetails />,
        };
      }

      if (dialogType === DialogTypes.ADD_SAMPLE_IMAGES) {
        return {
          title: "Add Image",
          width: "xl",
          component: <ImagePick sampleId={data?.SampleNumber || 0} />,
        };
      }

      if (dialogType === DialogTypes.ADD_NEW_CUSTOMER) {
        return {
          title: "Add Customer",
          component: (
            <EditCustomer
              cancel={cancel}
              handleNewCustomer={props?.newCustomer}
              handleSearchTree={props?.handleSearchTree}
              prodId={data?.ProductId}
              setExpandTreeData={props?.setExpandTreeData}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.EDIT_CUSTOMER) {
        return {
          title: "Edit Customer",
          component: (
            <EditCustomer
              cancel={cancel}
              prodId={data?.ProductId}
              custId={data?.CustomerId}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.ADD_NEW_SITE) {
        return {
          title: "Add Site",
          component: (
            <EditSite
              cancel={cancel}
              productId={data?.ProductId}
              customerId={data?.CustomerId}
              customer={data?.Customer}
              startLetter={data?.StartLetter || props?.startLetter}
              setExpandTreeData={props?.setExpandTreeData}
              handleSearchTree={props?.handleSearchTree}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.EDIT_SITE) {
        return {
          title: "Edit Site",
          component: (
            <EditSite
              cancel={cancel}
              customerId={data?.CustomerId}
              siteId={data?.SiteId}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.ADD_NEW_UNIT) {
        return {
          title: "Add Unit",
          width: "xl",
          component: (
            <EditUnit
              cancel={cancel}
              unitData={{
                ProductId: data?.ProductId,
                Product: data?.Product,
                CustomerId: data?.CustomerId,
                Customer: data?.Customer,
                SiteId: data?.SiteId,
                Site: data?.Site,
              }}
              startLetter={data?.StartLetter || props?.startLetter}
              setExpandTreeData={props?.setExpandTreeData}
              handleSearchTree={props?.handleSearchTree}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.EDIT_UNIT) {
        return {
          title: "Edit Unit",
          width: "xl",
          component: (
            <EditUnit
              cancel={cancel}
              unitData={{
                ProductId: data?.ProductId,
                Product: data?.Product,
                CustomerId: data?.CustomerId,
                Customer: data?.Customer,
                SiteId: data?.SiteId,
                Site: data?.Site,
                UnitId: data?.UnitId,
              }}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.ADD_NEW_COMPONENT) {
        return {
          title: "Add Component",
          component: (
            <EditComponent
              cancel={cancel}
              productId={data?.ProductId}
              customerId={data?.CustomerId}
              siteId={data?.SiteId}
              unitId={data?.UnitId}
              startLetter={data?.StartLetter || props?.startLetter}
              setExpandTreeData={props?.setExpandTreeData}
              handleSearchTree={props?.handleSearchTree}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.EDIT_COMPONENT) {
        return {
          title: "Edit Component",
          component: (
            <EditComponent
              cancel={cancel}
              productId={data?.ProductId}
              customerId={data?.CustomerId}
              siteId={data?.SiteId}
              unitId={data?.UnitId}
              compId={data?.ComponentId}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.EDIT_SAMPLE) {
        return {
          title: `Edit Sample ${data?.SampleNumber}`,
          width: "100vw",
          component: (
            <EditSample sampleNo={data?.SampleNumber} cancel={cancel} />
          ),
        };
      }

      if (dialogType === DialogTypes.REPORTS_BY_DATE) {
        return {
          title: "Product History and Subscription Reports",
          width: "xl",
          component: <SubscriptionReports />,
        };
      }

      if (dialogType === DialogTypes.EXPORT_CONTACTS_OVERVIEW) {
        return {
          title: "Export Contacts Overview",
          width: "100vw",
          component: <ContactExports treeData={data} />,
        };
      }
      if (dialogType === DialogTypes.VIEW_EVENT) {
        return {
          title: "Events",
          width: "100vw",
          component: <ViewEvents dataObj={data} />,
        };
      }
      if (dialogType === DialogTypes.EDIT_EVENT) {
        return {
          title: "Edit Events",
          width: "xl",
          component: <EditEvent
            eventFilter={data}
            eventsRowData={data}
            fetchData={props?.fetchData}
            cancel={cancel}
          />,
        };
      }
      if (dialogType === DialogTypes.ADD_EVENT) {
        return {
          title: "Edit Events",
          width: "xl",
          component: <AddEvent eventFilter={data} eventsRowData={data}
          />,
        };
      }
      if (dialogType === DialogTypes.EDIT_TRAY) {
        return {
          title: "Tray Overview",
          width: "xl",
          component: <EditTray eventFilter={data} />,
        };
      }

      if (dialogType === DialogTypes.SUBSCRIPTION_OVERVIEW) {
        return {
          title: "Subscription Overview",
          width: "100vw",
          component: <SubscriptionOverview formData={data} />,
        };
      }

      if (dialogType === DialogTypes.UNITS_NO_RECENT_SAMPLES) {
        return {
          title: "Units With No Recent Samples",
          width: "100vw",
          component: <UnitsRecentSamples formData={data} />,
        };
      }

      //MANAGE
      if (dialogType === DialogTypes.MANAGE_COMPONENT) {
        return {
          title: `Component Management ${data?.Component}`,
          width: "100vw",
          component: <ManageComponent unit={data} close={cancel} handleSearchTree={props?.handleSearchTree}
          />,
        };
      }

      if (dialogType === DialogTypes.MANAGE_CUSTOMER) {
        return {
          title: `Customer Management ${data?.Customer}`,
          width: "100vw",
          component: <ManageCustomer unit={data} close={cancel} handleSearchTree={props?.handleSearchTree}
          />,
        };
      }
      if (dialogType === DialogTypes.MANAGE_SAMPLE) {
        return {
          title: `Sample Management ${data?.SampleNumber}`,
          width: "100vw",
          component: <ManageSample unit={data} close={cancel} handleSearchTree={props?.handleSearchTree}
          />,
        };
      }
      if (dialogType === DialogTypes.MANAGE_SITE) {
        return {
          title: `Site Management ${data?.Site}`,
          width: "100vw",
          component: <ManageSite unit={data} close={cancel} handleSearchTree={props?.handleSearchTree}
          />,
        };
      }
      if (dialogType === DialogTypes.MANAGE_UNIT) {
        return {
          title: `Unit Management ${data?.Unit}`,
          width: "100vw",
          component: <ManageUnit unit={data} close={cancel} handleSearchTree={props?.handleSearchTree}
          />,
        };
      }
      //MANAGE

      if (dialogType === DialogTypes.COMPONENTS_NO_RECENT_SAMPLES) {
        return {
          title: "Components With No Recent Samples",
          width: "100vw",
          fullWidth: true,
          component: <ComponentsRecentSamples formData={data} />,
        };
      }

      if (dialogType === DialogTypes.RECENT_UNIT_RESULTS) {
        return {
          title: "Recent Unit History",
          width: "100vw",
          component: (
            <RecentUnitResults
              allData={data}
            />
          ),
        };
      }

      if (dialogType === DialogTypes.RECENT_COMPONENT_RESULTS) {
        return {
          title: "Recent Component History",
          width: "100vw",
          component: (
            <RecentComponentResults
              allData={data}
            />
          ),
        };
      }
      if (dialogType === DialogTypes.SPECIAL_REPORT || dialogType === DialogTypes.SPECIAL_COMPONENT_REPORT || dialogType === DialogTypes.SPECIAL_UNIT_REPORT) {
        return {
          title: "Special Report from Quick Details",
          width: "100vw",
          component: (
            <SpecialReport sampleNumber={data?.SampleNumber} allData={data} />
          ),
        };
      }

      //Search
      if (dialogType === DialogTypes.SEARCH_REGISTRATION) {
        return {
          title: "Search Registration",
          component: (
            <SearchRegistration
              screen={"useScreenPopups"}
              getRegistration={props.getRegistration}
              searchRegClickHandler={props.searchRegClickHandler}
            />
          ),
          width: "100vw",
          fullWidth: true,
        };
      }

      if (dialogType === DialogTypes.SEARCH_FLUID) {
        return {
          title: "Search Fluid",
          component: (
            <SearchFluid
              cancel={cancel}
              actions={[
                {
                  action: props.selectFluid,
                  label: "Select",
                  startIcon: <CheckCircle />,
                },
                {
                  action: props.editFluid,
                  label: "Edit",
                  startIcon: <EditIcon />,
                },
              ]}
              registrationSearchFluid={data}
            />
          ),
        };
      }
      if (dialogType === DialogTypes.SEARCH_COMPONENT) {
        return {
          title: "Select Component",
          width: "xl",
          component: (
            <SearchDetails
              activeOnly="component"
              activeTab="component"
              cancel={cancel}
              compActions={[
                {
                  action: props.selectComponent,
                  label: "Select",
                  startIcon: <CheckCircle />,
                },
              ]}
            />
          ),
        };
      }
      if (dialogType === DialogTypes.UPDATE_TRAY_LABEL) {
        return {
          title: "Tray Details",
          width: "md",
          fullWidth: true,
          component: (
            < UpdateTrayLabel
              rowData={data}
              cancel={cancel}
              onView={props?.onView}
            />
          ),
        };
      }
      if (dialogType === DialogTypes.SEARCH_GENERAL) {
        return {
          title: "Search from Quick Details",
          width: "100vw",
          fullWidth: true,
          component: (
            <SearchGeneral handleSearchTree={props?.handleSearchTree} />
          ),
        };
      }
      if (dialogType === DialogTypes.MANAGEMENT_REPORT_TD) {
        return {
          title: "Management Report",
          width: "md",
          component: (
            <ManagementReport cancel={cancel} />
          ),
        };
      }
      if (dialogType === DialogTypes.EDIT_BILLING_RATES_TD) {
        return {
          title: "Edit Billing Rates",
          width: "md",
          component: (
            <EditBillingRates cancel={cancel} />
          ),
        };
      }
      if (dialogType === DialogTypes.GLOBAL_SETTINGS_TD) {
        return {
          title: "Global Settings",
          width: "md",
          component: (
            <GlobalSettings cancel={cancel} />
          ),
        };
      }
      if (dialogType === DialogTypes.FLUIDTYPE_NOTIFICATIONS) {
        return {
          title: "Fuid Type Notifications",
          width: "lg",
          component: (
            <FluidNotifications cancel={cancel} />
          ),
        };
      }
      if (dialogType === DialogTypes.PRODUCT_NOTIFICATIONS) {
        return {
          title: "Product Notifications",
          width: "lg",
          component: (
            <ProductNotifications cancel={cancel} />
          ),
        };
      }
      else return;
    },
    [dialogType, data, props, cancel]
  );
}

export const DialogTypes = {
  UPDATE_TRAY_LABEL: "update-tray-label",
  UNIT_HOUR_HISTORY: "unit-hour-history",
  SUBSCRIPTION_OVERVIEW: "subscription-overview",
  ACCESS_PERMISSION_OVERVIEW: "access-permission-overview",
  PRIMARY_CONTACTS_OVERVIEW: "primary-contacts-overview",
  EXPORT_CONTACTS_OVERVIEW: "export-contacts-overview",
  REPORT_CONTACTS: "report-contacts",
  WEBSITE_CONTACTS: "website-contacts",
  CRITIICAL_LOG: "critical-log",
  HOLD_MANAGEMENT: "hold-management",
  VIEW_IN_QUICK_DETAILS: "view-in-quick-details",
  REPORTS_BY_DATE: "reports-by-date",
  UNITS_NO_RECENT_SAMPLES: "units-no-recent-samples",
  COMPONENTS_NO_RECENT_SAMPLES: "components-no-recent-samples",
  RECENT_UNIT_RESULTS: "recent-unit-results",
  RECENT_COMPONENT_RESULTS: "recent-component-results",
  SPECIAL_REPORT: "special-report",
  SPECIAL_UNIT_REPORT: "special-unit-report",
  SPECIAL_COMPONENT_REPORT: "special-component-report",
  VIEW_EVENT: "view-event",

  EDIT_SAMPLE: "edit-sample",
  EDIT_COMPONENT: "edit-component",
  EDIT_UNIT: "edit-unit",
  EDIT_SITE: "edit-site",
  EDIT_CUSTOMER: "edit-customer",
  EDIT_EVENT: "edit-event",
  EDIT_TRAY: "edit-tray",

  ADD_NEW_CUSTOMER: "add-new-customer",
  ADD_NEW_COMPONENT: "add-new-component",
  ADD_NEW_UNIT: "add-new-unit",
  ADD_NEW_SITE: "add-new-site",
  ADD_SAMPLE_IMAGES: "add-sample-images",
  ADD_EVENT: "add-event",

  MANAGE_COMPONENT: "manage-component",
  MANAGE_CUSTOMER: "manage-customer",
  MANAGE_SAMPLE: "manage-sample",
  MANAGE_SITE: "manage-site",
  MANAGE_UNIT: "manage-unit",

  SEARCH_REGISTRATION: "search-registration",
  SEARCH_COMPONENT: "search-component",
  SEARCH_FLUID: "search-fluid",
  SEARCH_GENERAL: "search-general",

  MANAGEMENT_REPORT_TD: "management-report",
  EDIT_BILLING_RATES_TD: "edit-billing-rates",
  GLOBAL_SETTINGS_TD: "global-settings",
  FLUIDTYPE_NOTIFICATIONS: "fluid-type-notifications",
  PRODUCT_NOTIFICATIONS: "product-notifications",
};
