import DataTable, { DataGridAction } from "../../../components/DataTable";
import { toLocalDateTime } from "../../../utils";
import { EditIcon, ViewIcon } from "../../../icons/ButtonIcons";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function ActionedTable({ data, sx, editClick, viewClick }) {
  const options = {
    columns: GetColumns(editClick, viewClick),
    dataSource: data,
    rowIdProperty: "CriticalLogId",
    cellStylingHandler: "criticalLogs",
    isDisableColumnFilter: true,
    sx: {
      height: 650,
    },
  };
  return <DataTable {...options} />;
}

function GetColumns(editClick, viewClick) {
  return [
    {
      headerName: "Actions",
      field: "Actions",
      sortable: false,
      flex: 1,
      minWidth: 150,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction
              row={row}
              onClick={editClick}
              label="Edit"
              startIcon={<EditIcon />}
            />
            <DataGridAction
              row={row}
              onClick={viewClick}
              label="View"
              startIcon={<ViewIcon />}
            />
          </>
        );
      },
    },
    {
      headerName: "Evaluator",
      field: "Evaluator",
      width: 140,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Created By",
      field: "CreatedBy",
      width: 140,
      renderCell: renderCellExpand,
    },
    {
      headerName: "SampleNumber",
      field: "SampleNumber",
      width: 120,
    },

    {
      headerName: "Status",
      field: "AlertStatus",
      width: 100,
    },
    {
      headerName: "Contact",
      field: "Contact",
      width: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Email",
      field: "Email",
      width: 200,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Telephone",
      field: "Telephone",
      width: 200,
    },
    {
      headerName: "Created",
      field: "Created",
      valueFormatter: ({ value }) => toLocalDateTime(value),
      width: 160,
    },
    {
      headerName: "Win",
      field: "IsWin",
      valueFormatter: ({ value }) => (value ? "Yes" : ""),
      width: 80,
    },
    {
      headerName: "Comments",
      field: "Comments",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Corrective Action",
      field: "CorrectiveAction",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Customer",
      field: "Customer",
      width: 180,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      width: 100,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Unit",
      field: "Unit",
      width: 100,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Model",
      field: "FullModel",
      width: 200,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Serial",
      field: "Serial",
      width: 180,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Component",
      field: "Component",
      width: 160,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Product",
      field: "Product",
      flex: 1,
      minWidth: 125,
    },
  ];
}
