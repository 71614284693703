import * as yup from "yup";
import { GetErrorObject } from "../../validation/Utils";

const Schemas = {
  sample: yup.object().shape({
    SampleNumber: yup
      .string()
      .transform((value) => value || "")
      .required("Sample Number is required."),
  }),
  job: yup.object().shape({
    JobNumber: yup
      .string()
      .transform((value) => value || "")
      .required("Job Number is required."),
  }),
  "adhoc-report": yup.object().shape({
    ReportNumber: yup
      .string()
      .transform((value) => value || "")
      .required("Report Number is required."),
  }),
  "event-log": yup.object().shape({
    EventLogId: yup
      .string()
      .transform((value) => value || "")
      .required("Event Log Id is required."),
  }),
  town: yup.object().shape({
    Town: yup
      .string()
      .transform((value) => value || "")
      .required("Town is required."),
  }),
};

export function ValidateSearchFilter(data, type) {
  return new Promise(async function (resolve, reject) {
    try {
      if (Schemas[type]) {
        Schemas[type]
          .validate(data, { abortEarly: false })
          .then(function () {
            resolve({ data });
          })
          .catch(function (err) {
            const errors = GetErrorObject(err);
            resolve({ errors });
          });
        return;
      }
      resolve({ data });
    } catch (e) {
      reject(e);
    }
  });
}
