import { Card } from "@mui/material";
import Button from "@mui/material/Button";
import Form, { SectionTitle } from "../../../components/Form";
import { Edit } from "@mui/icons-material";
import FormFooter from "../../../components/FormFooter";
import { ViewPhysicalIcon } from "../../../icons/ButtonIcons";
import AppDialog from "../../../components/AppDialog";
import EditCustomer from "../../../forms/EditCustomer";
import { useState } from "react";

export default function CustomerAddress({ data }) {
  const [editCustOpen, setEditCustOpen] = useState(false);

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          readOnly
          sections={FormSections}
          data={data}
          key={data && data.CustomerId}
        />
        <FormFooter buttons={GetFormButtons(setEditCustOpen)} />
      </Card>

      <AppDialog
        open={editCustOpen}
        title="Edit Customer"
        maxWidth="lg"
        handleClose={() => setEditCustOpen(false)}
      >
        <EditCustomer
          cancel={() => setEditCustOpen(false)}
          custId={data.CustomerId}
        />
      </AppDialog>
    </>
  );
}

const GetFormButtons = (setEditCustOpen) => [
  { variant: "contained", label: "Edit", startIcon: <Edit />, onClick: () => setEditCustOpen(true) },
];

const FormSections = [
  {
    xsCol: 12,
    fields: [
      {
        xsCol: 12,
        component: function () {
          return <SectionTitle title="Customer Address" />;
        },
      },
    ],
  },
  {
    xsCol: 6,
    fields: [
      { name: "PhysicalStreet1", label: "Physical Address 1", xsCol: 12 },
      { name: "PhysicalStreet2", label: "Address 2", xsCol: 12 },
      { name: "PhysicalCity", label: "Town", xsCol: 12 },
      { name: "PhysicalPostCode", label: "Postcode", xsCol: 6 },
      { name: "PhysicalState", label: "State", xsCol: 6 },
      {
        name: "PhysicalCountry",
        label: "Country",
      },
      {
        alignSelf: "flex-end",
        component: (props) => {
          return (
            <Button variant="outlined" startIcon={<ViewPhysicalIcon />}>
              View Physical
            </Button>
          );
        },
      },
    ],
  },
  {
    xsCol: 6,
    fields: [
      { name: "PostalStreet1", label: "Postal Address 1", xsCol: 12 },
      { name: "PostalStreet2", label: "Address 2", xsCol: 12 },
      { name: "PostalCity", label: "Town", xsCol: 12 },
      { name: "PostalPostCode", label: "Postcode", xsCol: 6 },
      { name: "PostalState", label: "State", xsCol: 6 },
      {
        name: "PostalCountry",
        label: "Country",
        xsCol: 12,
      },
    ],
  },
];
