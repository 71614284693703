import { memo } from "react";
import DataTable from "../../../components/DataTable";
export default memo(function HistoryTable({ columns, data }) {
  const options = {
    columns: columns || [],
    dataSource: data || [],
    rowIdProperty: "SampleNumber",
    isDisableColumnFilter: true,
    rowStylingHandler: "sample",
    cellStylingHandler: "sampleBackGroundWhite",
    minHeight: 30,
    heightValue: 100,
  };
  return <DataTable {...options} />;
});
