import { useCallback } from "react";
import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function TestItemsTable({
  data,
  rowSelected,
  selectedRow,
  setDirtyTestItems,
  height = 766,
}) {
  const rowEditHandler = useCallback(function (newRow, oldRow) {
    return new Promise(async function (resolve, reject) {
      setDirtyTestItems((testItems) => {
        return { ...testItems, [newRow.TestItemId]: newRow };
      });
      resolve({ ...newRow });
    });
  }, []);

  const options = {
    columns: GetColumns(),
    dataSource: data || [],
    rowIdProperty: "TestItemId",
    isDisableColumnFilter: true,
    rowStylingHandler: "testItems",
    rowsSelected: function (r) {
      r.length && rowSelected && rowSelected(r[0]);
    },
    selectionModel: selectedRow,
    sx: { height: height },
    rowEditHandler: rowEditHandler,
    experimentalFeatures: { newEditingApi: true },
  };
  return <DataTable {...options} />;
}
function GetColumns() {
  return [
    {
      headerName: "Test Item",
      field: "TestItem",
      sortable: false,
      minWidth: 180,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Code",
      field: "TestItemCode",
      sortable: false,
    },
    {
      headerName: "Type",
      field: "TestItemType",
      sortable: false,
      minWidth: 180,
      flex: 1,
      renderCell: renderCellExpand,
    },
  ];
}
