import { LinkOff, Delete } from "@mui/icons-material";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import DropDownAdvance from "../../../../components/DropDownAdvance";
import { FormActions, SectionTitle } from "../../../../components/Form";
import { UpdateOnlyIcon } from "../../../../icons/ButtonIcons";
import EditUnit from "../../../../forms/EditUnit";
import AppDialog from "../../../../components/AppDialog";
import EditComponent from "../../../../forms/EditComponent";
import SearchFluid from "../../../master-data/fluids/SearchFluid";
import InsiteMachineSearch from "../../../master-data/unit-models/InsiteMachineSearch";

export const RegistrationActions = {
  LOAD_DATA: "load-data",
  SHOW_FLUID_POPUP: "show-fluid-popup",
  HIDE_FLUID_POPUP: "hide-fluid-popup",
  SELECT_FLUID: "select-fluid",
  RESET_FIELDS: "reset-reading",
  UPDATE_UNIT_MAKE_MODEL: "update-unit-make-model",
};

export function RegistrationFormReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === RegistrationActions.LOAD_DATA) {
    return action.payload;
  }
  if (action.type === RegistrationActions.SHOW_FLUID_POPUP) {
    return { ...state, fluidPopupOpen: true };
  }
  if (action.type === RegistrationActions.HIDE_FLUID_POPUP) {
    return { ...state, fluidPopupOpen: false };
  }
  if (action.type === RegistrationActions.SELECT_FLUID) {
    return {
      ...state,
      fluidPopupOpen: false,
      FluidName: action.payload.FluidFormatted,
      FluidId: action.payload.FluidId,
      FluidTypeId: action.payload.FluidTypeId || 1,
    };
  }
  if (action.type === RegistrationActions.RESET_FIELDS) {
    return { ...state, ...action.payload, _key: Date.now() };
  }
  // if (action.type === RegistrationActions.UPDATE_UNIT_MAKE_MODEL) {
  //   const {
  //     UnitMakeOriginal: UnitMakeNew = null,
  //     UnitModelOriginal: UnitModelNew = null,
  //   } = state;
  //   return { ...state, UnitMakeNew, UnitModelNew, _key: Date.now() };
  // }
  return state;
}

function SelectCustomer({ id, data, masterData, setValue, onCustomerChanged, CanRegister }) {
  const [selectedCustomer] = useState(data.CustomerId);

  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" {...props} key={option.CustomerId}>
        {option.CustomerName}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options.filter((x, i) => i < 100)
      : options
        .filter((x) =>
          x.CustomerName.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
        .filter((x, i) => i < 100);
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.CustomerName || "";
  }, []);

  const options = useMemo(
    () =>
      (masterData.Customers || []).filter(
        (x) => x.ProductId === data.ProductId
      ),
    [masterData.Customers, data.ProductId]
  );

  return (
    <DropDownAdvance
      id={id}
      readOnly={!CanRegister}
      label="Customer"
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, customer) => {
        onCustomerChanged("CustomerId", (customer || {}).CustomerId || null);
        setValue("CustomerId", (customer || {}).CustomerId || null);
      }}
      options={options}
      defaultValue={
        options.find((x) => x.CustomerId === selectedCustomer) || null
      }
    />
  );
}

export const useFormSections = ({ onCustomerChanged, data, dispatch, componentData, CanRegister }) => {
  const [openUnit, setOpenUnit] = useState(false);
  const [openComponent, setOpenComponent] = useState(false);
  const [openFluid, setOpenFluid] = useState(false);
  const [openInsite, setOpenInsite] = useState(false);

  function sampleRowSelectedHandler(rowData) {
    dispatch({
      type: RegistrationActions.LOAD_DATA,
      payload: {
        ...data,
        FluidId: rowData.FluidId,
        FluidName: rowData.FluidFormatted,
      },
    });
    setOpenFluid(false);
  }
  return useMemo(
    () => [
      {
        fields: [
          {
            xsCol: 12,
            hidden: !data.RequiresAttention,
            sx: { paddingTop: 0 },
            component: function () {
              return (
                <Typography
                  sx={{ color: "red" }}
                >{`Registration ${data.RegistrationId} has not been registered and REQUIRES ATTENTION. Please FIX ON THIS SCREEN`}</Typography>
              );
            },
          },
          {
            xsCol: 12,
            sx: { paddingTop: 0 },
            component: function () {
              return (
                <Typography
                  sx={{ color: "purple" }}
                >{`If anything is UNKNOWN. e.g. Meter Reading, then make the value EMPTY and NOT ZERO; zero means that the value is known and brand new`}</Typography>
              );
            },
          },
          {
            xsCol: 12,
            component: function () {
              return <SectionTitle title="Sample Information" />;
            },
          },
          {
            name: "SIF",
            label: "Registration",
            xsCol: 3,
            readOnly: true,
          },
          {
            name: "Sampled",
            label: "Date Sampled",
            xsCol: 3,
            type: "datetime",
            // data.Added
          },
          {
            name: "Product",
            label: "Product",
            sx: {
              "& .MuiInputBase-input": {
                backgroundColor: data?.ProductColour,
                color: "white",
              },
            },
            xsCol: 3,
            readOnly: true,
          },
          {
            xsCol: 12,
            component: function () {
              return <SectionTitle title="Customer Information" />;
            },
          },
          {
            xsCol: 3,
            component: (props) => (
              <SelectCustomer
                {...props}
                CanRegister={CanRegister}
                onCustomerChanged={onCustomerChanged}
              />
            ),
          },
          {
            name: "SiteId",
            label: "Site",
            xsCol: 3,
            type: "dropdown",
            required: true,
            optionConfigs: {
              name: "Sites",
              textProp: "Site",
              valProp: "SiteId",
            },
          },
          {
            name: "UnitId",
            label:
              data.UnitId === null
                ? "Unit needs to be created - click the UNIT button add or select"
                : "Unit",
            xsCol: 3,
            type: "dropdown",
            required: true,
            optionConfigs: {
              name: "Units",
              textProp: "Unit",
              valProp: "UnitId",
            },
          },

          {
            xsCol: 3,
            group: [
              {
                component: function ({ data }) {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => setOpenUnit(true)}
                        sx={{ mt: "15px" }}
                      >
                        Search Unit
                      </Button>
                      <AppDialog
                        open={openUnit}
                        title={`${data?.UnitId > 0 ? "Edit" : "New"
                          } Unit from Registration`}
                        maxWidth="xl"
                        handleClose={() => setOpenUnit(false)}
                      >
                        <EditUnit
                          cancel={() => setOpenUnit(false)}
                          unitData={{
                            ProductId: data?.ProductId,
                            Product: data?.Product,
                            CustomerId: data?.CustomerId,
                            Customer: data?.Customer,
                            SiteId: data?.SiteId,
                            Site: data?.Site,
                            UnitId: data?.UnitId,
                          }}
                        />
                      </AppDialog>
                    </>
                  );
                },
              },
              {
                component: function ({ data }) {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        onClick={() => setOpenInsite(true)}
                        sx={{ mt: "15px" }}
                      >
                        Search Insite
                      </Button>
                      <AppDialog
                        open={openInsite}
                        title="Insite Machine Search"
                        maxWidth="lg"
                        handleClose={() => setOpenInsite(false)}
                      >
                        <InsiteMachineSearch userData={data} />
                      </AppDialog>
                    </>
                  );
                },
              },
            ],
          },

          {
            xsCol: 12,
            group: [
              {
                xsCol: 12,
                component: function () {
                  return <SectionTitle title="Machine Information" />;
                },
              },
              {
                name: "UnitMakeNew",
                label: "Make",
                xsCol: 3,
              },
              {
                xsCol: 3,
                group: [
                  {
                    name: "UnitModelNew",
                    label: "Model",
                    flex: 1,
                  },
                ],
              },
              {
                xsCol: 3,
                group: [
                  {
                    name: "SerialNew",
                    label: "Serial",
                    flex: 1,
                  },
                ],
              },
            ],
          },

          {
            xsCol: 12,
            group: [
              {
                label:
                  data.ComponentId === null
                    ? "Component needs to be created - click the COMPONENT button add or select"
                    : "Component",
                name: "ComponentId",
                xsCol: 3,
                type: "dropdown",
                required: true,
                optionConfigs: {
                  name: "Components",
                  textProp: "Component",
                  valProp: "ComponentId",
                },
              },
              {
                name: "ComponentLocationNew",
                label: "Location",
                xsCol: 3,
                type: "dropdown",
                optionConfigs: {
                  name: "ComponentLocations",
                  textProp: "Location",
                  valProp: "Location",
                },
              },
              {
                xs: 3,
                alignSelf: "flex-end",
                component: function ({ data }) {
                  return (
                    <>
                      <Button
                        variant="outlined"
                        disabled={!(data?.UnitId > 0)}
                        onClick={() => {
                          setOpenComponent(true);
                        }}
                      >
                        Component
                      </Button>
                      <AppDialog
                        open={openComponent}
                        title="Edit Component from Registration"
                        maxWidth="lg"
                        handleClose={() => setOpenComponent(false)}
                      >
                        <EditComponent
                          cancel={() => setOpenComponent(false)}
                          datadata={data}
                          unitId={data?.UnitId}
                          compId={data.ComponentId}
                          fluidTypeId={data.FluidTypeId || 1}
                        />
                      </AppDialog>
                    </>
                  );
                },
              },
            ],
          },
          {
            xsCol: 12,
            group: [
              {
                name: "ComponentMakeNew",
                label: "Comp Make",
                xsCol: 3,
              },
              {
                name: "ComponentModelNew",
                label: "Comp Model",
                flex: 1,
                xsCol: 3,
              },
              {
                name: "ComponentSerialNew",
                label: "Comp Serial",
                flex: 1,
                xsCol: 3,
              },
            ],
          },
          {
            name: "FluidTypeId",
            label: "Fluid Type",
            type: "dropdown",
            value: data.FluidName,
            xsCol: 3,
            disabled: true,
            optionConfigs: {
              name: "FluidTypes",
              textProp: "FluidType1",
              valProp: "FluidTypeId",
            },
          },
          {
            name: "FluidName",
            label: "Fluid",
            flex: 1,
            xsCol: 3,
            readOnly: true,
            sx: {
              [`.MuiInputBase-root`]: {
                backgroundColor: data?.FluidId === null && "red",
                color: data?.FluidId === null && "white",
              },
            },
          },
          {
            xs: 4,
            alignSelf: "flex-end",
            component: function ({ data }) {
              return (
                <>
                  <Button variant="outlined" onClick={() => setOpenFluid(true)}>
                    Search Fluid
                  </Button>
                  <AppDialog
                    open={openFluid}
                    title="Fluid Search"
                    maxWidth="lg"
                    handleClose={() => setOpenFluid(false)}
                  >
                    <SearchFluid
                      rowSelected={sampleRowSelectedHandler}
                      registrationSearchFluid={data}
                    />
                  </AppDialog>
                </>
              );
            },
          },
          {
            xs: 3,
            alignSelf: "flex-end",
            component: function ({ data, dispatch }) {
              return (
                <Tooltip
                  title={`Use '${data.ComponentMakeOriginal || ""}' and '${data.ComponentModelOriginal || ""
                    }' as Make and Model respectively.`}
                >
                  <Button
                    variant="outlined"
                    onClick={() =>
                      dispatch({
                        type: RegistrationActions.RESET_FIELDS,
                        payload: {
                          ComponentMakeNew: data.ComponentMakeOriginal,
                          ComponentModelNew: data.ComponentModelOriginal,
                        },
                      })
                    }
                  >
                    Use Default
                  </Button>
                </Tooltip>
              );
            },
          },
          {
            xs: 3,
            alignSelf: "flex-end",
            component: function ({ data }) {
              return (
                data?.FluidId === null && <Typography color="error">Fluid needs to be created - click Search to add or select</Typography>
              );
            },
          },
          {
            xsCol: 12,
            component: function () {
              return <SectionTitle title="Other Information" />;
            },
          },
          {
            xsCol: 12,
            group: [
              {
                name: "UnitMeter",
                label: "Meter Reading",
                label2: componentData?.LastMeterReading || "",
                xsCol: 3,
                type: "number",
              },
              {
                name: "ComponentMeter",
                label: "Comp. Reading",
                label2: componentData?.LastComponentHours || "",
                xsCol: 3,
                type: "number",
              },
              {
                name: "FluidMeter",
                label: "Fluid Hours",
                label2: componentData?.LastFluidHours || "",
                xsCol: 3,
                type: "number",
                max: 999999999,
              },
              {
                xsCol: 3,
                group: [
                  {
                    name: "Topup",
                    label: "Topup",
                    xsCol: 6,
                    type: "number",
                    max: 999999999,
                  },
                  {
                    name: "FilterChanged",
                    label: "Filter Changed",
                    xsCol: 6,
                    type: "switch",
                  },
                ],
              },

            ],
          },
          {
            xsCol: 12,
            group: [
              {
                name: "JobNumber",
                label: "Job Number",
                xsCol: 3,
              },
              {
                name: "ServiceTypeId",
                label: "Service",
                xsCol: 3,
                type: "dropdown",
                optionConfigs: {
                  name: "ServiceTypes",
                  textProp: "ServiceType1",
                  valProp: "ServiceTypeId",
                },
              },
              {
                name: "MeasureId",
                label: "Measure",
                xsCol: 3,
                type: "dropdown",
                disabled: true,
                optionConfigs: {
                  name: "Measures",
                  textProp: "MeasureLong",
                  valProp: "MeasureId",
                },
              },
              {
                name: "CapacityNew",
                label: "Capacity",
                flex: 1,
                type: "number",
              },
            ],
          },

          {
            xsCol: 6,
            group: [
              {
                name: "FluidChanged",
                label: "Fluid Changed",
                type: "switch",
                xsCol: 3,
              },
              {
                name: "FluidChangedAt",
                label: "Changed At",
                type: "number",
                disabled: (data) => !data.FluidChanged,
                xsCol: 3,
              },
              {
                alignSelf: "flex-end",
                component: function ({ data, dispatch }) {
                  return (
                    <Button
                      variant="outlined"
                      disabled={!data.FluidChanged}
                      onClick={() =>
                        dispatch({
                          type: RegistrationActions.RESET_FIELDS,
                          payload: {
                            FluidChangedAt: data.UnitMeter,
                          },
                        })
                      }
                    >
                      Set SMR
                    </Button>
                  );
                },
              },
              {
                name: "FluidChangedAtDate",
                type: "datetime",
                disabled: (data) => !data.FluidChanged,
                xsCol: 3,
                sx: { marginTop: "15px" },
              },
            ],
          },

          {
            xsCol: 6,
            group: [
              {
                name: "UnitMeterChanged",
                label: "Meter Changed",
                type: "switch",
                xsCol: 3,
              },
              {
                name: "UnitMeterChangedAt",
                label: "Changed At",
                type: "number",
                disabled: (data) => !data.UnitMeterChanged,
                xsCol: 3,
              },
              {
                alignSelf: "flex-end",
                component: function ({ data, dispatch }) {
                  return (
                    <Button
                      variant="outlined"
                      disabled={!data.UnitMeterChanged}
                      onClick={() =>
                        dispatch({
                          type: RegistrationActions.RESET_FIELDS,
                          payload: {
                            UnitMeterChangedAt: data.UnitMeter,
                          },
                        })
                      }
                    >
                      Set SMR
                    </Button>
                  );
                },
              },
              {
                name: "UnitMeterChangedAtDate",
                type: "datetime",
                disabled: (data) => !data.UnitMeterChanged,
                xsCol: 3,
                sx: { marginTop: "15px" },
              },
            ],
          },
          {
            xsCol: 6,
            group: [
              {
                name: "ComponentChanged",
                label: "Comp. Changed",
                type: "switch",
                xsCol: 3,
              },
              {
                name: "ComponentChangedAt",
                label: "Changed At",
                type: "number",
                disabled: (data) => !data.ComponentChanged,
                xsCol: 3,
              },
              {
                alignSelf: "flex-end",
                component: function ({ data, dispatch }) {
                  return (
                    <Button
                      variant="outlined"
                      disabled={!data.ComponentChanged}
                      onClick={() =>
                        dispatch({
                          type: RegistrationActions.RESET_FIELDS,
                          payload: {
                            ComponentChangedAt: data.UnitMeter,
                          },
                        })
                      }
                    >
                      Set SMR
                    </Button>
                  );
                },
              },
              {
                name: "ComponentChangedAtDate",
                type: "datetime",
                disabled: (data) => !data.ComponentChanged,
                xsCol: 3,
                sx: { marginTop: "15px" },
              },
            ],
          },
          {
            xsCol: 6,
            group: [
              {
                name: "ComponentRepaired",
                label: "Comp. Repaired",
                type: "switch",
                xsCol: 3,
              },
              {
                name: "ComponentRepairedAt",
                label: "Repaired At",
                type: "number",
                disabled: (data) => !data.ComponentRepaired,
                xsCol: 3,
              },
              {
                alignSelf: "flex-end",
                component: function ({ data, dispatch }) {
                  return (
                    <Button
                      variant="outlined"
                      disabled={!data.ComponentRepaired}
                      onClick={() =>
                        dispatch({
                          type: RegistrationActions.RESET_FIELDS,
                          payload: {
                            ComponentRepairedAt: data.UnitMeter,
                          },
                        })
                      }
                    >
                      Set SMR
                    </Button>
                  );
                },
              },
              {
                name: "ComponentRepairedAtDate",
                type: "datetime",
                disabled: (data) => !data.ComponentRepaired,
                xsCol: 3,
                sx: { marginTop: "15px" },
              },
            ],
          },
          {
            name: "Notes",
            label: "Notes",
            type: "textarea",
            xsCol: 12,
            rows: 4,
          },
        ],
      },
    ],
    [
      onCustomerChanged,
      openUnit,
      openUnit,
      openComponent,
      openFluid,
      openInsite,
      data,
    ]
  );
};

export const useFormButtons = ({
  CanRegister,
  location,
  editCheck,
  submitHandler,
  handleDeleteRegistration,
  handleRegistrationClick,
  handleRegistrationCard,
  decoupleHandler,
  sampleNo,
}) =>

  useMemo(
    () => [
      {
        hidden: !CanRegister,
        label: "Delete",
        variant: "outlined",
        onClick: handleDeleteRegistration,
        startIcon: <Delete />,
      },
      {
        hidden: !CanRegister,
        label: "View Card",
        variant: "outlined",
        onClick: handleRegistrationCard,
        startIcon: <LinkOff />,
      },
      {
        hidden: !CanRegister,
        label: "Update Only",
        onClick: submitHandler,
        startIcon: <UpdateOnlyIcon />,
      },
      location === "/trays/registration" && {
        hidden: !CanRegister,
        label: "Registration",
        onClick: handleRegistrationClick,
        startIcon: <UpdateOnlyIcon />,
      },
    ],
    [location,
      submitHandler,
      handleDeleteRegistration,
      handleRegistrationClick,
      handleRegistrationCard,
      decoupleHandler,
      sampleNo,
      editCheck,
    ]
  );
