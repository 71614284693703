import renderCellExpand from "../../../../components/DatatableCellExpand";
import DataTable, { DataGridAction } from "./../../../../components/DataTable";

export default function ContactReportsPermissionTable({
  data,
  editReportMappingClicked,
  ...props
}) {
  const options = {
    ...props,
    columns: GetColumns(editClick, deleteClick),
    dataSource: (data || []).map((x) => ({
      ...x,
      keyProp: x.ContactId + "" + (x.ContactLinkageId || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: true,
    cellStylingHandler: "contactReportsPermission",
  };
  function editClick(row) {
    editReportMappingClicked(row);
  }
  return <DataTable {...options}></DataTable>;
}

function deleteClick(row) {}
function GetColumns(editClick, deleteClick) {
  return [
    {
      headerName: "Edit",
      field: "Edit",
      sortable: false,
      minWidth: 50,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={editClick} label="Edit" />
          </>
        );
      },
    },
    {
      headerName: "Delete",
      field: "Delete",
      sortable: false,
      minWidth: 50,
      renderCell: function ({ row }) {
        return (
          <>
            <DataGridAction row={row} onClick={deleteClick} label="Delete" />
          </>
        );
      },
    },
    {
      headerName: "Email",
      field: "EmailStatus",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Sample",
      field: "SampleReportStatus",
      minWidth: 150,
    },
    {
      headerName: "Excel",
      field: "ExcelStatus",
      minWidth: 150,
    },
    {
      headerName: "Machine",
      field: "MachineReportStatus",
      minWidth: 150,
    },
    {
      headerName: "SMS",
      field: "SmsStatus",
      minWidth: 150,
    },
    {
      headerName: "XML",
      field: "XmlStatus",
      minWidth: 150,
    },
    {
      headerName: "Mail",
      field: "MailStatus",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Scope",
      field: "LevelText",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Product",
      field: "Product",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Customer",
      field: "Customer",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site",
      field: "Site",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
    {
      headerName: "Site Set",
      field: "SiteSet",
      minWidth: 150,
      renderCell: renderCellExpand,
    },
  ];
}
