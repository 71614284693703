import DataTable from "../../../components/DataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function QuickMergeToFluidTable({ data, rowSelected }) {
  const toFluidOptions = {
    columns: toFluidColumns,
    dataSource: data || [],
    rowIdProperty: "FluidId",
    isDisableColumnFilter: false,
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    sx: { height: 760 },
  };
  return <DataTable {...toFluidOptions} />;
}
const toFluidColumns = [
  {
    headerName: "To Fluid Type",
    field: "FluidType",
    minWidth: 200,
  },
  {
    headerName: "To Fluid",
    field: "FluidFormatted",
    minWidth: 170,
    flex: 1,
    renderCell: renderCellExpand,
  },
];
