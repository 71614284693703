import React, { useCallback, useEffect, useState } from "react";
import ApprovalToReleaseTable from "./ApprovalToRelease.table";
import useFetch from "../../../hooks/useFetch";
import { TRAY_APIS } from "../../../common/apis";
import { Card, Grid, Typography } from "@mui/material";
import SearchFilter from "./SearchFilter";
import moment from "moment";
import { DateFormat } from "../../../utils";
import { useRoot, } from "../../../rootContext";
import { Button } from "@mui/material";
import AppDialog from "../../../components/AppDialog";
import AlertDialog from "../../../components/AlertDialog";
import appResources from "../../../app-resources/en/Resources.json";
const ScreenName = "Release Approval";

export default function ApprovalToRelease() {
  document.body.style.backgroundColor = "#dddddd";

  const { post } = useFetch();
  const { user, CanEvaluateNotTrainee } = useRoot();

  const [samplesData, setSamplesData] = useState([]);
  const [records, setRecords] = useState(0);
  const [actionData, setActionData] = useState({});
  const [reqData, setReqData] = useState();
  const [filterData, setFilterData] = useState();
  const [infoMessage, setInfoMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [allData, setAllData] = useState([]);
  const [expandState, setExpandState] = useState("");
  // const [noneSelected, setNoneSelected] = useState(0);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    isRecords: false,
  });
  // Setting the background color to gray


  const onViewHandler = useCallback(async function (filter) {
    if (filter.Date === "Invalid date") {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    setIsInfoIcon(false);
    setFilterData(filter);
    const resp = await post(TRAY_APIS.SEARCH_APPROVALS, {
      Date: filter.LabAndDateOption === 1 || filter.LabAndDateOption === 3 ? null : moment(filter.Date).format(DateFormat),
      LabId: filter.LabAndDateOption === 2 || filter.LabAndDateOption === 3 ? null : user.DefaultLaboratoryId,
    });

    if (resp) {
      if (resp?.length < 1) {
        setIsWarningTrue(true);
        setIsInfoIcon(true);
        setInfoMessage(appResources.NO_SAMPLES_WERE_FOUND_MSG);
      } else {
        setIsWarningTrue(false);
        setIsInfoIcon(false);
        setInfoMessage();
      }
      setAllData(resp)
      const groups = resp.reduce(function (ac, item) {
        if (!ac[item.Product]) {
          ac[item.Product] = {
            ProductId: item.ProductId,
            Product: item.Product,
            samples: {},
          };
        }
        if (!ac[item.Product].samples[item.SampleNumber]) {
          ac[item.Product].samples[item.SampleNumber] = {
            ...item,
            contacts: [],
          };
        }

        ac[item.Product].samples[item.SampleNumber].contacts.push(item);
        return ac;
      }, {});

      const tempData = Object.keys(groups).map((pName) => {
        const p = groups[pName];
        return {
          ...p,
          samples: Object.keys(p.samples).map((s) => p.samples[s]),
        };
      });
      setSamplesData(tempData);
      let sampleNumberCount = 0;
      tempData.forEach((item) => {
        item.samples.forEach((sample) => {
          sampleNumberCount++;
        });
      });
      setRecords(sampleNumberCount);
    }
  }, []);

  function handleExpandClick() {
    setExpandState(expandState === "expanded" ? "collapsed" : "expanded");
  }

  function handleSetAllExportClick() {
    const temp = samplesData
      .reduce(function (samples, product) {
        samples = [...samples, ...product?.samples];
        return samples;
      }, [])
      .reduce(function (actions, sample) {
        if (
          actionData[sample.SampleNumber]?.action &&
          actionData[sample.SampleNumber]?.action !== "export"
        ) {
          actions[sample.SampleNumber] = actionData[sample.SampleNumber];
          return actions;
        }
        actions[sample.SampleNumber] = { action: "export" };
        return actions;
      }, {});
    setActionData(temp);
  }

  function handleClearExportClick() {
    const temp = samplesData
      .reduce(function (samples, product) {
        samples = [...samples, ...product?.samples];
        return samples;
      }, [])
      .reduce(function (actions, sample) {
        if (
          actionData[sample.SampleNumber]?.action &&
          actionData[sample.SampleNumber].action === "export"
        ) {
          actions[sample.SampleNumber] = { action: "" };
          return actions;
        }
        actions[sample.SampleNumber] = actionData[sample.SampleNumber];
        return actions;
      }, {});
    setActionData(temp);
  }

  function SetExportPoroperties(sampleNumber) {
    var found = null;
    samplesData.forEach(function (r) {
      r.samples.forEach(function (k) {
        if (k.SampleNumber == sampleNumber) {
          found = k;
        }
      });
    });

    return found;
  }

  const removeNonActionItems = (Datalist) =>
    Datalist.filter((Datalist) => Datalist.Action !== "");

  function handleActionSamplesClick() {
    const req = Object.keys(actionData).map((x) => ({
      SampleNumber: Number(x),
      Action: actionData[x]?.action,
      Export: actionData[x]?.action === "export",
      Hold: actionData[x]?.action === "hold",
      ReturnEval: actionData[x]?.action === "eval",
      ReturnLab: actionData[x]?.action === "lab",
      Quiet: actionData[x]?.action === "quiet",
      Screen: ScreenName,
      Contacts: SetExportPoroperties(x)?.contacts,
      ContactId:
        SetExportPoroperties(x)?.Username != null
          ? SetExportPoroperties(x)?.ContactId
          : "",
      Username:
        SetExportPoroperties(x).Username != null
          ? SetExportPoroperties(x).Username
          : "",
      Contact:
        SetExportPoroperties(x).DisplayName != null
          ? SetExportPoroperties(x).DisplayName
          : "",
      Email: SetExportPoroperties(x).EmailFrom != null ? true : false,
      Report: SetExportPoroperties(x).SampleReportFrom != null ? true : false,
      Excel: SetExportPoroperties(x).ExcelFrom != null ? true : false,
      Machine: SetExportPoroperties(x).MachineReportFrom != null ? true : false,
      SMS: SetExportPoroperties(x).SmsFrom != null ? true : false,
      XML: SetExportPoroperties(x).XmlFrom != null ? true : false,
      Mail: SetExportPoroperties(x).MailFrom != null ? true : false,
    }));
    setReqData(removeNonActionItems(req));
    const samplesCount = {
      exportCount: 0,
      holdCount: 0,
      returnCount: 0,
      quietCount: 0,
    };

    const countSum = req.reduce((sum, currentValue) => {
      currentValue.Export === true && (sum.exportCount += 1);
      currentValue.Hold === true && (sum.holdCount += 1);
      (currentValue.ReturnEval === true || currentValue.ReturnLab === true) &&
        (sum.returnCount += 1);
      currentValue.Quiet === true && (sum.quietCount += 1);
      return sum;
    }, samplesCount);

    let reqLength = 0;
    req.forEach((item) => {
      (item.Action !== "" || undefined) && reqLength++;
    });
    const noneSelected = records - reqLength;
    const msg =
      countSum.exportCount > 0 ||
        countSum.holdCount > 0 ||
        countSum.returnCount > 0 ||
        countSum.quietCount > 0
        ? `Do you wish to action:
     ${countSum.exportCount > 0
          ? `EXPORT ${countSum.exportCount} ${countSum.exportCount > 1 ? "samples" : "sample"}`
          : ""
        }
     ${countSum.holdCount > 0
          ? `HOLD ${countSum.holdCount} ${countSum.holdCount > 1 ? "samples" : "sample"}`
          : ""
        }
     ${countSum.returnCount > 0
          ? `RETURN ${countSum.returnCount} ${countSum.returnCount > 1 ? "samples" : "sample"}`
          : ""
        }
     ${countSum.quietCount > 0
          ? `QUIET EXPORT ${countSum.quietCount} ${countSum.quietCount > 1 ? "samples" : "sample"}`
          : ""
        }
     ${noneSelected > 0
          ? `The remaining ${noneSelected} ${noneSelected > 1 ? `samples` : `sample`} will not be actioned`
          : ""
        }
     `
        : appResources.NO_SAMPLES_ARE_SELECTED_MSG;

    setDialog({
      open: true,
      message: msg,
      isRecords:
        msg === appResources.NO_SAMPLES_ARE_SELECTED_MSG ? false : true,
    });
  }

  async function handleActionSamplesClickAlert() {
    const response = await post(TRAY_APIS.APPROVE_SAMPLES, reqData);
    if (response >= 0) {
      onViewHandler(filterData);
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      // setIsSuccessIcon(true);
      // setSuccessMessage(appResources.SUCCESSFULLY_COMPLETED_MSG);
    } else {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      // setIsSuccessIcon(false);
      setInfoMessage(appResources.SOMETHING_WENT_WRONG_MSG);
    }
    setDialog((prevState) => ({ ...prevState, open: false }));
  }

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F3" && samplesData?.length > 0) {
        event.preventDefault();
        handleExpandClick();
      } else if (event.key === "F4") {
        event.preventDefault();
        if (samplesData?.length > 0) {
          handleSetAllExportClick();
        }
      } else if (event.key === "F5") {
        event.preventDefault();
        if (samplesData.length > 0) {
          handleClearExportClick();
        }
      } else if (event.key === "F12") {
        event.preventDefault();
        if (samplesData.length > 0) {
          handleActionSamplesClick();
        }
      }
    },
    [samplesData, expandState, actionData]
  );
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    document.title = "Approval";
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="flex-end"
              marginBottom="10px"
            >
              <Grid container padding={2} display={"inline"}>
                <Grid item xs={8}>
                  <SearchFilter
                    onView={onViewHandler}
                    infoMessage={infoMessage}
                    successMessage={successMessage}
                    isInfoIcon={isInfoIcon}
                    isSuccessIcon={isSuccessIcon}
                    isWarningTrue={isWarningTrue}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} marginLeft={1}>
                <Grid item>
                  <Button
                    disabled={samplesData.length === 0}
                    onClick={handleExpandClick}
                    variant="outlined"
                  >
                    {expandState === "expanded"
                      ? "Collapse (F3)"
                      : "Expand (F3)"}
                  </Button>
                </Grid>
                {CanEvaluateNotTrainee &&
                  <>
                    <Grid item>
                      <Button
                        disabled={samplesData.length === 0}
                        onClick={handleSetAllExportClick}
                        variant="outlined"
                      >
                        Set All Export (F4)
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={samplesData.length === 0}
                        onClick={handleClearExportClick}
                        variant="outlined"
                      >
                        Clear Export (F5)
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={samplesData.length === 0}
                        onClick={handleActionSamplesClick}
                      >
                        Action Samples (F12)
                      </Button>
                    </Grid>
                  </>
                }
              </Grid>

            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              Total Records: <b>{records}</b>
            </Typography>
          </Grid>
          <ApprovalToReleaseTable
            // data={samplesData}
            data={allData}
            {...{
              actionData,
              setActionData,
              expandState,
              setSamplesData,
              setRecords,
            }}
          />
        </Grid>
        <AppDialog
          open={dialog.open}
          handleClose={() =>
            setDialog((prevState) => ({
              ...prevState,
              open: false,
              isRecords: false,
            }))
          }
          title="Action Samples"
          maxWidth="md"
        >
          <AlertDialog
            handleClose={() => {
              setDialog((prevState) => ({
                ...prevState,
                open: false,
                isRecords: false,
              }));
            }}
            alertAction={handleActionSamplesClickAlert}
            message={dialog.message}
            isRecords={dialog.isRecords}
          />
        </AppDialog>
      </Grid>
    </>
  );
}
