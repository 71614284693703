import { useEffect, useState } from "react";
import { MASTER_DATA_APIS } from "../common/apis";
import useFetch from "./useFetch";

export default function useMasterData(type) {
  const { get } = useFetch();
  const [masterData, setMasterData] = useState({});
  useEffect(function () {
    (async function () {
      if (type) {
        const temp = await get(
          `${MASTER_DATA_APIS.GET_BY_TYPES}?types=${type}`
        );
        setMasterData(temp || {});
      }
    })();
  }, []);
  return {
    masterData,
    setMasterData,
    getMasterData: async function (types, params = "") {
      return await get(
        `${MASTER_DATA_APIS.GET_BY_TYPES}?types=${types}&${params}`
      );
    },
    getPresetComments: async function (
      includeShared = false,
      includePersonal = true,
      allPersonal = false
    ) {
      return await get(`/MasterData/preset-comments?includeShared=${includeShared}&includePersonal=${includePersonal}&allPersonal=${allPersonal}&`, { skipBlocker: true });
    },
  };
}
