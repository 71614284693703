import * as yup from "yup";
import { GetErrorObject } from "../Utils";

export const CommonSchema = yup.object().shape({
  ComponentName: yup
    .string()
    .transform((value) => value || "")
    .required("Component Name is required."),
  Template: yup
    .string()
    .transform((value) => value || "")
    .required("Template is required."),
  NewTemplate: yup
    .string()
    .transform((value) => value || "")
    .required("NewTemplate is required."),
});

export function ValidateSchema(input) {
  return new Promise(async function (resolve, reject) {
    try {
      CommonSchema.validate(input, { abortEarly: false })
        .then(function () {
          const data = {
            ComponentName: {
              ...input,
            },
            Template: {
              ...input,
            },
            NewTemplate: {
              ...input,
            },
          };
          resolve({ data });
        })
        .catch(function (err) {
          const errors = GetErrorObject(err);
          resolve({ errors });
        });
    } catch (e) {
      reject(e);
    }
  });
}
