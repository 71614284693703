import { Button, Card, Grid, Typography } from "@mui/material";
import DataTable from "../../../components/DataTable";
import { useEffect, useReducer, useState } from "react";
import { TRAY_APIS } from "../../../common/apis";
import useFetch from "../../../hooks/useFetch";
import Form, { FormActions } from "../../../components/Form";
import { ExcelIcon } from "../../../icons/ButtonIcons";
import renderCellExpand from "../../../components/DatatableCellExpand";
import { toLocalDate2 } from "../../../utils";

const ComponentsRecentSamples = ({ formData }) => {
  const { post, postFile } = useFetch();
  const [componentRecentList, setComponentRecentList] = useState([]);
  const [filterData, dispatch] = useReducer(
    RegistrationFilterReducer,
    RegistrationFilterDefaults
  );

  const handleView = async () => {
    const dataList = await post(`${TRAY_APIS.COMPONENT_SAMPLE_REPORT}`, {
      ProductId: formData?.ProductId,
      CustomerId: formData?.CustomerId,
      ParentId: formData?.ParentId,
      SiteId: formData?.SiteId,
      UnitId: formData?.UnitId,
      Date1: filterData?.date,
    });
    setComponentRecentList(dataList);
  };

  const getExcelFile = async () => {
    await postFile(
      TRAY_APIS.EXPORT_COMPONENT_SAMPLE_REPORT,
      {
        ProductId: formData?.ProductId,
        CustomerId: formData?.CustomerId,
        ParentId: formData?.ParentId,
        SiteId: formData?.SiteId,
        UnitId: formData?.UnitId,
        Date1: filterData?.date,
      },
      "Components With No Recent Samples"
    );
  };

  useEffect(() => {
    handleView();
  }, []);

  const rowSelected = () => {};

  const options = {
    dataSource: (componentRecentList || []).map((x) => ({
      ...x,
      keyProp:
        x.CustomerId +
        "" +
        (x.ComponentId || "") +
        (x.UnitModelFormatted || ""),
    })),
    rowIdProperty: "keyProp",
    isDisableColumnFilter: false,
    cellStylingHandler: "componentRecentSamples",
    rowsSelected: function (r) {
      r.length && rowSelected(r[0]);
    },
    sx: { height: 500 },
  };

  return (
    <>
      <Grid container spacing={2} minWidth={"90vw"} mb={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Form
              sections={GetFormSections(
                componentRecentList,
                handleView,
                getExcelFile
              )}
              data={filterData}
              spacing={2}
              dispatch={dispatch}
              key={filterData._ts_key || ""}
            />
          </Card>
        </Grid>
      </Grid>

      <Typography>
        {componentRecentList?.length} components(s) were found with no approved
        samples or last approved sample before the selected date
      </Typography>
      <DataTable {...options} columns={GetColumns()} />
    </>
  );
};

export default ComponentsRecentSamples;

function GetFormSections(componentRecentList, handleView, getExcelFile) {
  return [
    {
      fields: [
        {
          name: "date",
          label: "Last Sampled Before",
          type: "date",
          xsCol: 3,
          disableFuture: "true",
        },
        {
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                variant="contained"
                onClick={handleView}
                //   startIcon={<SearchIcon />}
              >
                View
              </Button>
            );
          },
        },
        {
          alignSelf: "flex-end",
          component: function () {
            return (
              <Button
                variant="outlined"
                onClick={getExcelFile}
                startIcon={<ExcelIcon />}
                disabled={componentRecentList?.length < 1}
              >
                Excel
              </Button>
            );
          },
        },
      ],
    },
  ];
}

const GetColumns = () => [
  {
    headerName: "Product",
    field: "Product",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Customer",
    field: "Customer",
    width: 80,
    flex: 1,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Site",
    field: "Site",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Unit",
    field: "Unit",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Component",
    field: "Component",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Sampled",
    field: "LastSampled",
    width: 100,
    valueFormatter: ({ value }) => toLocalDate2(value),
  },
  {
    headerName: "Received",
    field: "LastReceived",
    width: 100,
    valueFormatter: ({ value }) => toLocalDate2(value),
  },
  {
    headerName: "Alert Status",
    field: "LastAlertStatus",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Source",
    field: "Source",
    width: 100,
    renderCell: renderCellExpand,
  },
  {
    headerName: "Count",
    field: "SampleCount",
    valueFormatter: ({ value }) => (value > 0 ? value : 0),
    width: 100,
  },
  {
    headerName: "Created",
    field: "ComponentCreated",
    valueFormatter: ({ value }) => toLocalDate2(value),
    width: 100,
  },
];

function RegistrationFilterReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  return state;
}

const RegistrationFilterDefaults = {
  date: new Date(),
};
