import Constants from "../../../common/Constants";
import GetStepNames from "../../../common/GetStepNames";

export const DashboardColumns = ({ labName }) => [
  {
    headerName: labName,
    field: "Tray",
    width: 100,

    valueFormatter: ({ value }) => "Tray " + value,
    cellClassName: (params) => {
      if (!params.value) {
        return "";
      }
      return "cell-txt-bold";
    },
  },
  {
    headerName: "Label",
    field: "Label",
    width: 200,
  },
  {
    headerName: "Samples",
    field: "SampleCount",
    width: 100,
  },
  {
    headerName: "Minimum Step",
    field: "MinimumStep",
    width: 120,
    valueFormatter: ({ value }) => GetStepNames(value).mid,
  },
  {
    headerName: "Maximum Step",
    field: "MaximumStep",
    width: 120,
    valueFormatter: ({ value }) => GetStepNames(value).mid,
  },
  {
    headerName: "Reruns",
    field: "Reruns",
    width: 100,
  },
  {
    headerName: "Hold",
    field: "HoldNotReruns",
    width: 100,
  },
  {
    headerName: "Missing Results",
    field: "MissingResults",
    width: 120,
  },
  {
    headerName: "Critical Logs",
    field: "CriticalLogs",
    width: 100,
    valueFormatter: ({ value }) => value || "",
  },
  {
    headerName: "Auto Evaluated",
    field: "AutoEval",
    width: 120,
  },
];
