import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { Card, Typography } from "@mui/material";
import AutoEvaluationOverviewTable from "./AutoEvaluationOverviewTable";
import AutoEvaluationDetailsTable from "./AutoEvaluationDetailsTable";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import AppDialog from "../../../components/AppDialog";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import { DASHBOARD_APIS } from "../../../common/apis";
import RerunSamples from "../../reports/rerun-samples/RerunSamples";
import MissedSamples from "../../reports/missed-samples/MissedSamples";
import {
  ExcelIcon,
  ExportIcon,
  ExportOverviewIcon,
  SearchIcon,
} from "../../../icons/ButtonIcons";
import { useToast } from "../../../rootContext";
import appResources from "../../../app-resources/en/Resources.json";
import { toLocalDayDate2 } from "../../../utils";
const AutoEvaluationDashboard = () => {
  const { showWarningToast } = useToast();

  const [dataSourceOverview, setDataSourceOverview] = useState([]);
  const [dataSourceDetails, setDataSourceDetails] = useState([]);
  const { post, getFile } = useFetch();
  // Imran Zulfiqar
  const [rerunSamplesOpen, setRerunSamplesOpen] = useState(false);
  function handleRerunClick() {
    setRerunSamplesOpen(true);
  }
  const [missedSamplesOpen, setMissedSamplesOpen] = useState(false);
  function handleMissedClick() {
    setMissedSamplesOpen(true);
  }

  //date picker
  const [fromDate, setFromDate] = useState(new moment());
  const [toDate, setToDate] = useState(new moment());

  const handleChangeFromDate = (DateParams) => {
    setFromDate(DateParams);
  };
  const handleChangeToDate = (DateParam) => {
    setToDate(DateParam);
  };
  useEffect(() => {
    document.title = "Auto-Evaluation Dashboard";
  }, []);
  async function autoEvalClick() {
    const formattedFromDate = fromDate.format("YYYYMMDD");
    const formattedToDate = toDate.format("YYYYMMDD");

    if (
      formattedFromDate === "Invalid date" ||
      formattedToDate === "Invalid date"
    ) {
      return showWarningToast(appResources.INVALID_DATE_FORMAT_MSG);
    }
    let data = await post(DASHBOARD_APIS.AUTO_EVALUATION, {
      DateTo: formattedToDate,
      DateFrom: formattedFromDate,
    });
    if (data?.Detail.length > 0) {
      setDataSourceOverview(data.Overview.filter((x) => x.AutoEvalTotal));
      setDataSourceDetails(data.Detail);
    } else {
      setDataSourceOverview();
      setDataSourceDetails();
      showWarningToast(
        appResources.NO_AUTO_EVALUATION_DASHBOARD_DATA_FOUND_MSG +
          " for " +
          toLocalDayDate2(formattedFromDate)
      );
    }
  }
  // function overviewExportClick() {
  //   const DateFrom = fromDate.format("YYYYMMDD");
  //   const DateTo = toDate.format("YYYYMMDD");
  //   getFile(
  //     `/dashboard/export-overview?TrayDate=${DateFrom}&TrayDate2=${DateTo}`
  //   );
  // }
  function overviewExportClick() {
    const DateFrom = fromDate.format("YYYYMMDD");
    const DateTo = toDate.format("YYYYMMDD");
    getFile(
      `/dashboard/export-overview?TrayDate=${DateFrom}&TrayDate2=${DateTo}`,
      "Auto Evaluation Overview"
    );
  }
  function detailsExportClick() {
    const DateFrom = fromDate.format("YYYYMMDD");
    const DateTo = toDate.format("YYYYMMDD");
    getFile(
      `/dashboard/export-details?TrayDate=${DateFrom}&TrayDate2=${DateTo}`,
      "Auto Evaluation Details"
    );
  }
  //jsx return html to export method
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <FormLabel component="legend">From</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={fromDate}
                        onChange={handleChangeFromDate}
                        renderInput={(params) => <TextBox {...params} />}
                        componentsProps={{
                          actionBar: {
                            actions: ["today"],
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item>
                    <FormLabel component="legend">To</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        inputFormat="DD/MM/YYYY"
                        value={toDate}
                        onChange={handleChangeToDate}
                        renderInput={(params) => <TextBox {...params} />}
                        componentsProps={{
                          actionBar: {
                            actions: ["today"],
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={autoEvalClick}
                      sx={{ marginRight: 1 }}
                      startIcon={<SearchIcon />}
                    >
                      View
                    </Button>
                  </Grid>
                  {dataSourceOverview?.length > 0 && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={overviewExportClick}
                        sx={{ marginTop: "23px" }}
                        startIcon={<ExportOverviewIcon />}
                      >
                        Export Overview
                      </Button>
                    </Grid>
                  )}
                  {dataSourceDetails?.length > 0 && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        onClick={detailsExportClick}
                        sx={{ marginTop: "23px" }}
                        startIcon={<ExportIcon />}
                      >
                        Export Details
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {/* <Grid item>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid item>
                    <Button
                      startIcon={<RerunSampleIcon />}
                      sx={{ marginTop: "25px" }}
                      variant="contained"
                      onClick={handleRerunClick}
                    >
                      Rerun Samples
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<MissedSamplesIcon />}
                      sx={{ marginTop: "25px" }}
                      variant="contained"
                      onClick={handleMissedClick}
                    >
                      Missed Samples
                    </Button>
                  </Grid>
                </Grid>
              </Grid> */}
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {dataSourceOverview?.length}</b>
          </Typography>
          <AutoEvaluationOverviewTable data={dataSourceOverview} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records: <b> {dataSourceDetails?.length}</b>
          </Typography>
          <AutoEvaluationDetailsTable data={dataSourceDetails} />
        </Grid>
      </Grid>
      <AppDialog
        open={rerunSamplesOpen}
        handleClose={() => setRerunSamplesOpen(false)}
        title="Rerun Samples"
        maxWidth="xl"
      >
        <RerunSamples />
      </AppDialog>
      <AppDialog
        open={missedSamplesOpen}
        handleClose={() => setMissedSamplesOpen(false)}
        title="Missed and Hold Samples"
        maxWidth="xl"
      >
        <MissedSamples screenName={"AutoEvaluationDashboard"} />
      </AppDialog>
    </>
  );
};

export default AutoEvaluationDashboard;
