import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SearchFluid from "./SearchFluid";
import useFetch from "../../../hooks/useFetch";
import { FLEET_APIS } from "../../../common/apis";

const steps = ["Select action", "Select replacement fluid", "Action summary"];

export default function ManageFluids({
  SelectedFluid: fluid,
  fetchFluidsDetails,
  setManageFluidsOpen,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedFluid, setSelectedFluid] = React.useState({});
  const [skipped, setSkipped] = React.useState(new Set());
  const [processResponse, setProcessResponse] = React.useState(
    "Process completed successfully."
  );

  const isStepOptional = (step) => { };

  const [selectedOption, setSelectedOption] = React.useState("deleteOriginal");
  const { post } = useFetch();
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep === 2) {
      PerformOperation();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };
  async function PerformOperation() {
    if (selectedOption == "deleteOriginal") {
      const data = {
        OriginalUnitMakeId: fluid.FluidId,
        ReplacementMakeId: selectedFluid.FluidId,
        Screen: "Unit Model Management",
      };
      const result = await post(FLEET_APIS.MANAGE_UNIT, data);
      if (result.length > 0) {
        setProcessResponse(result);
      }
    } else {
      const data = {
        OriginalUnitMakeId: fluid.FluidId,
        ReplacementFluidId: null,
        Screen: "Unit Model Management",
      };
      const result = await post(FLEET_APIS.MANAGE_UNIT, data);
      if (result.length > 0) {
        setProcessResponse(result);
      }
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedFluid({});
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleReset = () => {
    setManageFluidsOpen();
  };
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="deleteOriginal"
              name="manageAction"
              //value={selectedOption}
              onChange={handleRadioGroupChange}
            >
              <FormControlLabel
                value="deleteOriginal"
                control={<Radio />}
                label="Replace all instances of the fluid with a different one, and DELETE the original fluid."
              />
              <FormControlLabel
                value="keepOriginal"
                control={<Radio />}
                label="Replace all instances of the fluid with a different one, but KEEP the original fluid."
              />
            </RadioGroup>
          </FormControl>
        );
      case 1:
        return (
          <SearchFluid
            rowSelected={(row) => setSelectedFluid(row)}
            rowManageSelected={fluid}
            fetchFluidsDetails={fetchFluidsDetails}
          />
        );
      case 2:
        return (
          // <Typography variant="subtitle2" sx={{ mt: 4, mb: 1 }}>
          //   <p>Source Type: {fluid.FluidType}</p>
          //   <p>Source Fluid: {fluid.FluidFormatted}</p>
          //   <p>Source Type: {fluid.FluidId}</p>
          //   <p>------------------------------</p>
          //   <p>Replacement Type: {selectedFluid.FluidType}</p>
          //   <p>Replacement Fluid: {selectedFluid.FluidFormatted}</p>
          //   <p>Replacement Type: {selectedFluid.FluidId}</p>
          // </Typography>
          <>
            <TableContainer component={Paper} sx={{ mt: 6, mb: 4 }}>
              <Table
                sx={{ minWidth: 400 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightblue", color: "blue" }}
                    >
                      Source
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightgreen", color: "green" }}
                    >
                      Replacement
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Type
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightblue", color: "blue" }}
                    >
                      {fluid.FluidType}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightgreen", color: "green" }}
                    >
                      {selectedFluid.FluidType}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Fluid
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightblue", color: "blue" }}
                    >
                      {fluid.FluidFormatted}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightgreen", color: "green" }}
                    >
                      {selectedFluid.FluidFormatted}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      FluidId
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightblue", color: "blue" }}
                    >
                      {fluid.FluidId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ background: "lightgreen", color: "green" }}
                    >
                      {selectedFluid.FluidId}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      default:
        return "Unknown step";
    }
  }

  return (
    <Box sx={{ width: "100%", padding: "10px" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 4, mb: 1 }}>{processResponse}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Close</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography className={ClassNames.instructions} sx={{ mt: 4, mb: 1 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button
              onClick={handleNext}
              disabled={activeStep === 1 && !selectedFluid.FluidId}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
