import { Button, Card, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import React, { useCallback, useEffect, useState } from "react";
import { ResultColWidths } from "../../../common/Constants";
import DropDown from "../../../components/DropDown";
import TextBox from "../../../components/TextBox";
import useFetch from "../../../hooks/useFetch";
import { DateFormat, copyTextToClipboard, toLocalDate } from "../../../utils";
import HistoryTable from "./HistoryTable";
import ResultsTable from "./ResultsTable";
import SamplesTable from "./SamplesTable";
import SearchFilter from "./SearchFilter";
import RerunToLaboratory from "./RerunToLaboratory";
import AppDialog from "./../../../components/AppDialog";
import ImagePick from "../../../components/SelectImage";
import ResizePanel from "react-resize-panel";
import appResources from "../../../app-resources/en/Resources.json";
import { TRAY_APIS } from "../../../common/apis";
import MissedSamples from "../../reports/missed-samples/MissedSamples";
import {
  ChartsIcon,
  ImagesIcon,
  ReleaseSampleIcon,
  RerunSampleIcon,
  SaveIcon,
} from "../../../icons/ButtonIcons";
import { useRoot, useUser } from "../../../rootContext";
import { useScreenPopups } from "../../../components/useScreenPopups";
import GroupDialog from "../../../components/GroupDialog";
import { DataGridAction } from "../../../components/DataTable";
import DropdownMenu from "../../../components/DropdownMenu";
import { BadgeButton } from "../../../components/FormFooter";
import renderCellExpand from "../../../components/DatatableCellExpand";

export default function QualityCheck({ qualityCheckActions }) {
  const { post, get, getFile } = useFetch();
  const user = useUser();
  const { CanQualityCheckTrays } = useRoot();

  const [samplesData, setSamplesData] = useState([]);
  const [results, setResults] = useState({});
  const [imagePickerOpen, setImagePickerOpen] = useState(false);
  const [missedSamplesOpen, setMissedSamplesOpen] = useState(false);

  const [selectedSampleNo, setSelectedSampleNo] = useState("");
  const [selectedRow, setSelectedRow] = useState("");
  const [filterState, setFilterState] = useState("");
  const [lstSampleNumber, setReleaseTraySamples] = useState([]);
  const [infoMessage, setInfoMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [isInfoIcon, setIsInfoIcon] = useState(false);
  const [isSuccessIcon, setIsSuccessIcon] = useState(false);
  const [isWarningTrue, setIsWarningTrue] = useState(false);
  const [dialogType, setDialogType] = useState({ open: false });
  const [selectedValue, setSelectedValue] = useState("1");
  const [eqpMachineId, setEqpMachineId] = useState(null);
  const [history, setHistory] = useState({});

  useEffect(() => {
    document.title = "Quality Check";
  }, []);

  const [setText, setEnteredText] = useState("");
  const [imagesCount, setImagesCount] = useState(0);

  function sampleRowSelectedHandler(sample) {
    setIsWarningTrue(false);
    //  setIsSuccessIcon(false);
    setIsInfoIcon(false);
    //  setSuccessMessage();
    if (typeof sample.SampleNumber === "string") {
      setSelectedSampleNo(parseInt(sample.SampleNumber, 10));
      setSelectedRow(sample);
      return;
    } else {
      setSelectedRow(sample);
      setSelectedSampleNo(sample.SampleNumber);
    }
  }

  async function onViewHandler(filter) {
    if (filter.Date === "Invalid date") {
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      //  setIsSuccessIcon(false);
      return setInfoMessage(appResources.INVALID_DATE_FORMAT_MSG);
    }
    // const isEqual = JSON.stringify(filter) === JSON.stringify(filterState);
    // if (!isEqual) {
    //   setIsSuccessIcon(false);
    // }
    setFilterState(filter);
    const sampleData = await post(TRAY_APIS.QUALITY_CHECK, {
      Date:
        filter.LabandDateOption === 2 || filter.LabandDateOption === 4
          ? null
          : moment(filter.Date).format(DateFormat),
      LabId:
        filter.LabandDateOption === 3 || filter.LabandDateOption === 4
          ? null
          : user.DefaultLaboratoryId,
      TrayNumber: filter.LabandDateOption === 0 ? filter.Tray : null,
    });
    if (sampleData?.QualityCheckResult?.length < 1) {
      //  setIsSuccessIcon(false);
      setIsWarningTrue(true);
      setIsInfoIcon(true);
      setSamplesData([]);
      setInfoMessage(appResources.NO_SAMPLES_WERE_FOUND_MSG);
    } else {
      setIsWarningTrue(false);
      setIsInfoIcon(false);
      setInfoMessage();
      setSamplesData(sampleData.QualityCheckResult);
    }

    const { data, columns } = getDataAndColumns(
      sampleData.SampleResultsAlls,
      sampleData.TestSetItems,
      sampleData.QualityCheckResult
    );

    setResults({ data, columns });
    if (sampleData.QualityCheckResult.length > 0) {
      setSelectedSampleNo(sampleData.QualityCheckResult[0].SampleNumber);
      setSelectedRow(sampleData.QualityCheckResult[0]);
    } else {
      setSelectedSampleNo(0);
      setEnteredText("");
      setHistory([]);
      setResults([]);
    }
  }

  const [viewType, setViewType] = useState(0);
  function handelViewTypeChange(e) {
    setViewType(e.target.value);
  }

  useEffect(
    function () {
      (async function () {
        if (!selectedSampleNo) return;

        const sampleData = await get(
          `${TRAY_APIS.HISTORY}?sampleNo=${selectedSampleNo}&includeLaterSamples=true&resultView=0`
        );

        if (sampleData.PastSamples.length > 0) {
          setEqpMachineId(sampleData.PastSamples[0]?.EqpMachineId);

          localStorage.removeItem("selectedSampleNo");
          window.localStorage.setItem(
            "selectedSampleNo",
            sampleData.PastSamples[0]?.SampleNumber
          );
          localStorage.setItem(
            "EqpMachineId",
            sampleData.PastSamples[0]?.EqpMachineId
          );

          if (sampleData.SampleNotesAndImageCount?.SampleNotes !== null) {
            setEnteredText(sampleData.SampleNotesAndImageCount.SampleNotes);
          }
          if (sampleData.SampleNotesAndImageCount?.SampleImageCount !== null) {
            setImagesCount(
              sampleData.SampleNotesAndImageCount.SampleImageCount
            );
          }
        }
        const { columns, data } =
          viewType === 1
            ? getDataAndColumnsPastSamplesHistory(
                sampleData,
                qualityCheckActions,
                rowActionHandler
              )
            : viewType === 2
            ? getDataAndColumnsPastSamplesSimple(sampleData)
            : {};
        setHistory({ columns, data });
      })();
    },
    [selectedSampleNo, viewType]
  );

  //Update  Note
  async function saveNotes() {
    await post(TRAY_APIS.UPDATE_SAMPLE_NOTE, {
      SampleNumber: selectedSampleNo,
      Note: setText ? setText : null,
      Screen: "Laboratory",
    });
  }

  const [holdOpen, setHoldOpen] = useState(false);
  function imageOpenClick() {
    setImagePickerOpen(true);
  }

  const [isRecheck, setIsRecheck] = useState(true);
  samplesData.map((item) => {
    if (item.QualityCheckResult === false) {
      setIsRecheck(false);
    }
  });

  samplesData.map((item) => {
    if (item.HoldType !== null) {
      setReleaseTraySamples.add(item.SampleNumber);
    }
  });

  function handleRerunSampleClick() {
    setHoldOpen(true);
    setSelectedValue("1");
  }

  async function handleReleaseSampleClick() {
    const response = await get(
      `${TRAY_APIS.QUALITY_CHECK_RELEASE_SAMPLE}?SampleNumber=${selectedSampleNo}`
    );
    if (response > 0) {
      onViewHandler(filterState);
      setIsWarningTrue(false);
      // setIsSuccessIcon(true);
      setIsInfoIcon(false);
      //  setSuccessMessage(`${selectedSampleNo} has been released successfully`);
    }
  }

  async function handleQualityCheckRerunTray() {
    setHoldOpen(true);
    setSelectedValue("2");
  }

  async function handleReleaseTrayClick() {
    const response = await get(
      `${TRAY_APIS.QUALITY_CHECK_RELEASE_TRAY}?SampleNumber=${selectedSampleNo}`
    );
    if (response > 0) {
      onViewHandler(filterState);
      setIsWarningTrue(false);
      //  setIsSuccessIcon(true);
      setIsInfoIcon(false);
      //   setSuccessMessage(appResources.TRAY_RELEASED);
    }
  }

  function handleChartClick() {
    localStorage.removeItem("isCloseWindow");
    localStorage.setItem("isCloseWindow", false);
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    var url = `/Chart?EqpMachineId=${
      selectedRow?.EqpMachineId || eqpMachineId
    }&sampleId=${selectedSampleNo}&hideProp=2`;
    var windowFeatures =
      "width=" +
      windowWidth +
      ",height=" +
      windowHeight +
      ",top=0,left=0,resizable,scrollbars=yes";

    window.open(url, "_blank", windowFeatures);
  }

  async function getSampleReport(row) {
    try {
      let fileName = `${row?.Customer} ${row?.Site} ${row?.Unit} ${row?.Component} ${row?.SampleNumber} `;
      const response = await getFile(
        `${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`,
        fileName
      );
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return window.open(url, "_blank");
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  function getSampleGraph(row) {
    var windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    var windowHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
    var url = `/Chart?EqpMachineId=${
      selectedRow?.EqpMachineId || eqpMachineId
    }&sampleId=${row?.SampleNumber}&hideProp=2`;
    var windowFeatures =
      "width=" +
      windowWidth +
      ",height=" +
      windowHeight +
      ",top=0,left=0,resizable,scrollbars=yes";

    window.open(url, "_blank", windowFeatures);
  }

  function rowActionHandler(row, actionName) {
    if (["add-sample-images", "edit-sample"].indexOf(actionName) > -1) {
      openDialog(row, actionName);
      return;
    } else if (actionName === "view-graphs") {
      return getSampleGraph(row);
    } else if (actionName === "view-sample-report") {
      return getSampleReport(row);
    } else if (actionName === "copy-sample-number") {
      return copyTextToClipboard(row?.SampleNumber);
    }
  }

  const dialogOptions = useScreenPopups({
    data: dialogType.data || {},
    cancel: closeDialog,
    dialogType: dialogType.type,
  });

  function openDialog(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "F3" && samplesData?.length > 0) {
        event.preventDefault();
        handleChartClick();
      } else if (event.key === "F5") {
        event.preventDefault();
        if (CanQualityCheckTrays && samplesData?.length > 0) {
          setHoldOpen(true);
        }
      } else if (event.key === "F2") {
        event.preventDefault();
        imageOpenClick();
      } else if (event.key === "F11") {
        event.preventDefault();
        if (samplesData.length > 0 && CanQualityCheckTrays) {
          handleReleaseTrayClick();
        }
      } else if (event.key === "F12") {
        event.preventDefault();
        if (samplesData.length > 0 && CanQualityCheckTrays) {
          handleReleaseSampleClick();
        }
      } else if (event.key === "F9") {
        event.preventDefault();
        if (
          samplesData.length > 0 &&
          CanQualityCheckTrays &&
          selectedRow?.ReQualityCheck === false &&
          viewType === 0
        ) {
          handleQualityCheckRerunTray();
        }
      }
    },
    [samplesData, CanQualityCheckTrays, viewType, selectedRow, selectedSampleNo]
  );

  const handleRerun = () => {
    setHoldOpen(false);
    onViewHandler(filterState);
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={8}>
                <SearchFilter
                  onView={onViewHandler}
                  infoMessage={infoMessage}
                  successMessage={successMessage}
                  isInfoIcon={isInfoIcon}
                  isSuccessIcon={isSuccessIcon}
                  isWarningTrue={isWarningTrue}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} mb="10px">
          <Typography variant="subtitle2">
            Total Records: <b> {samplesData.length || 0}</b>
          </Typography>
          <ResizePanel direction="s">
            <SamplesTable
              data={samplesData}
              rowSelected={sampleRowSelectedHandler}
              selectedRow={[selectedSampleNo]}
              actions={qualityCheckActions}
              rowActionHandler={rowActionHandler}
            />
          </ResizePanel>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ p: 1 }}>
            <Grid container spacing={2}>
              {samplesData.length > 0 && (
                <Grid item xs={6}>
                  <TextBox
                    label={"Notes"}
                    key={selectedSampleNo}
                    multiline
                    rows={3}
                    value={setText}
                    readOnly={!CanQualityCheckTrays}
                    onChange={(v) => setEnteredText(v.target.value)}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {samplesData.length > 0 && CanQualityCheckTrays && (
                    <Grid item>
                      <Button
                        item
                        variant="contained"
                        onClick={saveNotes}
                        startIcon={<SaveIcon />}
                      >
                        Save
                      </Button>
                    </Grid>
                  )}

                  {samplesData.length > 0 && (
                    <Grid item xs={2}>
                      <DropDown
                        value={viewType}
                        name="viewType"
                        onChange={handelViewTypeChange}
                        selectOptions={[
                          { text: "Result", value: 0 },
                          { text: "History", value: 1 },
                          { text: "History Simple", value: 2 },
                        ]}
                      />
                    </Grid>
                  )}

                  <Grid item hidden={samplesData?.length <= 0}>
                    <Button
                      variant="outlined"
                      onClick={handleChartClick}
                      startIcon={<ChartsIcon />}
                    >
                      Charts (F3)
                    </Button>
                  </Grid>

                  <Grid item hidden={samplesData?.length <= 0}>
                    <BadgeButton
                      variant="outlined"
                      startIcon={<ImagesIcon />}
                      badge={{ badgeContent: imagesCount || 0, color: "error" }}
                      label="Images (F2)"
                      onClick={imageOpenClick}
                    />
                  </Grid>

                  {samplesData.length > 0 &&
                    CanQualityCheckTrays &&
                    selectedRow?.ReQualityCheck === false && (
                      <Grid item>
                        <Button
                          item
                          variant="outlined"
                          color="error"
                          onClick={handleRerunSampleClick}
                          startIcon={
                            <RerunSampleIcon sx={{ marginTop: 0.5 }} />
                          }
                          // sx={{ backgroundColor: "red", color: "white" }}
                        >
                          Rerun Sample (F5)
                        </Button>
                      </Grid>
                    )}

                  {samplesData.length > 0 &&
                    CanQualityCheckTrays &&
                    selectedRow?.ReQualityCheck === false &&
                    viewType === 0 && (
                      <Grid item>
                        <Button
                          item
                          variant="outlined"
                          color="error"
                          startIcon={
                            <RerunSampleIcon sx={{ marginTop: 0.5 }} />
                          }
                          onClick={handleQualityCheckRerunTray}
                        >
                          {filterState.LabandDateOption > 0
                            ? "Return All (F9)"
                            : "Return Tray (F9)"}
                        </Button>
                      </Grid>
                    )}

                  {samplesData.length > 0 && CanQualityCheckTrays && (
                    <Grid item>
                      <Button
                        item
                        startIcon={<ReleaseSampleIcon />}
                        onClick={handleReleaseSampleClick}
                      >
                        Release Sample (F12)
                      </Button>
                    </Grid>
                  )}

                  {samplesData.length > 0 && CanQualityCheckTrays && (
                    <Grid item>
                      <Button
                        item
                        startIcon={<ReleaseSampleIcon />}
                        onClick={handleReleaseTrayClick}
                      >
                        {filterState.LabandDateOption > 0
                          ? "Release All (F11)"
                          : "Release Tray (F11)"}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Total Records:
            <b>
              {viewType === 0
                ? results.data
                  ? results.data.length
                  : 0
                : history.data
                ? history.data.length
                : 0}
            </b>
          </Typography>
          {viewType === 1 || viewType === 2 ? (
            <ResizePanel direction="s">
              <HistoryTable {...history} key={viewType} />
            </ResizePanel>
          ) : (
            <ResizePanel direction="s">
              <ResultsTable
                {...results}
                rowSelected={sampleRowSelectedHandler}
                selectedRow={[selectedSampleNo]}
              />
            </ResizePanel>
          )}
        </Grid>
      </Grid>

      <AppDialog
        open={holdOpen}
        handleClose={() => setHoldOpen(false)}
        title="Return to Laboratory as Rerun"
        maxWidth="xl"
      >
        <RerunToLaboratory
          sampleId={selectedSampleNo}
          sampleDate={moment(filterState?.Date).format(DateFormat)}
          sampleData={selectedRow}
          screenName={"Quality Check"}
          handleClose={handleRerun}
          option={selectedValue}
        />
      </AppDialog>
      <AppDialog
        open={imagePickerOpen}
        handleClose={() => setImagePickerOpen(false)}
        title="Add Image"
        maxWidth="xl"
      >
        <ImagePick sampleId={selectedSampleNo ? selectedSampleNo : 0} />
      </AppDialog>
      <AppDialog
        open={missedSamplesOpen}
        handleClose={() => setMissedSamplesOpen(false)}
        title="Missed and Hold Samples"
        maxWidth="xl"
      >
        <MissedSamples screenName={"QualityCheck"} />
      </AppDialog>

      <GroupDialog
        {...dialogOptions}
        open={dialogType.open || false}
        close={closeDialog}
      />
    </>
  );
}
//columns for REsults
function getDataAndColumns(results, testSetItems, qcResults) {
  const testItems = new Set();
  const samplesMap = {};

  qcResults.forEach(function (s) {
    samplesMap[s.SampleNumber] = s;
  });
  results.forEach(function (r) {
    if (!r.length) return;
    if (!samplesMap[r[0].SampleNumber]) {
      samplesMap[r[0].SampleNumber] = { ...samplesMap[r[0].SampleNumber] };
    }
    r.forEach(function (item) {
      testItems.add(item.TestItemCode + ": " + item.TestItem);
      var sample = samplesMap[r[0].SampleNumber];
      sample[item.TestItemCode] = item.FormattedText;
      sample[item.TestItemCode + "_id"] = item.TestItemId;
      // sample[item.TestSetId] = item.TestSetId;
      samplesMap[r[0].SampleNumber][item.TestItemCode.split(":")[0]] =
        item.FormattedText;
    });
  });

  const data = [];

  Object.keys(samplesMap).forEach(function (key) {
    samplesMap[key]["SampleNumber"] = key;
    var sample = samplesMap[key];
    (testSetItems[sample.TestSetId] || []).forEach(function (item) {
      if (!sample[item.TestItemCode]) {
        sample[item.TestItemCode + "_valReq"] = true;
        return;
      }
      sample[item.TestItemCode + "_hasVal"] = true;
    });
    data.push(samplesMap[key]);
  });

  let columns = [];

  let columns1 = [...testItems].map((x) => ({
    headerName: x.split(":")[0],
    field: x.split(":")[0],
    testId: x.TestItemId,
    minWidth: ResultColWidths[x] || 50,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
    description: `${x.TestItemCode}: ${x.TestItem}`,
  }));
  let columns2 = Object.values(testSetItems)[0].map((x) => ({
    headerName: x.TestItemCode,
    field: x.TestItemCode,
    testId: x.TestItemId,
    minWidth: ResultColWidths[x] || 50,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
    description: `${x.TestItemCode}: ${x.TestItem}`,
  }));
  columns = [...columns1, ...columns2];

  columns = columns.reduce((acc, curr) => {
    const matchingNode = acc.find(
      (node) => node.headerName === curr.headerName
    );
    if (!matchingNode) {
      acc.push(curr);
    }
    return acc;
  }, []);

  // if (Object.values(testSetItems)[0]?.length <= 0) {
  // columns = [...testItems].map((x) => ({
  //   headerName: x.split(":")[0],
  //   field: x.split(":")[0],
  //   testId: x.TestItemId,
  //   minWidth: ResultColWidths[x] || 50,
  //   flex: 1,
  //   sortable: false,
  //   description: `${x.TestItemCode}: ${x.TestItem}`,
  // }));
  // } else {
  //   columns = Object.values(testSetItems)[0].map((x) => ({
  //     headerName: x.TestItemCode,
  //     field: x.TestItemCode,
  //     testId: x.TestItemId,
  //     minWidth: ResultColWidths[x] || 50,
  //     flex: 1,
  //     sortable: false,
  //     description: `${x.TestItemCode}: ${x.TestItem}`,
  //   }));
  // }

  //let it be commented

  // const columns = [...testItems].map((x) => ({
  //   headerName: x.split(":")[0],
  //   field: x.split(":")[0],
  //   minWidth: ResultColWidths[x] || 80,
  //   flex: 1,
  //   sortable: false,
  //   description: x,
  // }));

  columns.unshift({
    headerName: "Sample Number",
    field: "SampleNumber",
    minWidth: ResultColWidths["SampleNumber"] || 80,
    flex: 1,
    sortable: false,
    renderCell: renderCellExpand,
  });
  return { columns, data };
}

//columns for History
function getDataAndColumnsPastSamplesHistory(
  { PastSamplesElementData: data, PastSamples: sampleData },
  qualityCheckActions,
  rowActionHandler
) {
  const dataObject = {};
  const colMap = {};

  const samplesMap = {};
  let hasSif = false;
  sampleData.forEach(function (s) {
    if (s.SIF) {
      hasSif = true;
    }
    samplesMap[s.SampleNumber] = s;
  });

  data.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
    }
    if (x.TestItemCode === "ISO3") {
      dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    } else {
      dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedNumeric;
    }
    if (x.FormattedNumeric === 0 || x.FormattedNumeric < 0) {
      dataObject[x.SampleNumber][x.TestItemCode] = "<1";
    }
    if (colMap[x.TestItemCode]) {
      return;
    }
    colMap[x.TestItemCode] = x;
  });

  const columnsSimple = Object.keys(colMap)
    .map((x) => colMap[x])
    .sort(sortFunction)
    .map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      minWidth: ResultColWidths[x.TestItemCode] || 50,
      flex: 1,
      renderCell: renderCellExpand,
    }));

  const menuOptions = [
    { label: "View Sample Report", name: "view-sample-report" },
  ];

  const extraColumns1 = [
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      minWidth: 128,
      maxWidth: 128,
      renderCell: function ({ row }) {
        return (
          <>
            {(qualityCheckActions || []).map(function (ac, i) {
              return (
                <DataGridAction
                  key={i}
                  row={row}
                  onClick={ac.action}
                  label={ac.label}
                  startIcon={ac.startIcon}
                />
              );
            })}
            <DropdownMenu
              options={menuOptions}
              onItemSelect={(option) => rowActionHandler(row, option)}
            />
            <Typography paddingTop={0.25}>{row.SampleNumber}</Typography>
          </>
        );
      },
    },
    {
      headerName: "UH",
      field: "UnitHours",
      description: "Unit Hours",
      minWidth: 49,
      flex: 1,
    },
    {
      headerName: "CH",
      field: "ComponentHours",
      description: "Component Hours",
      minWidth: 49,
      flex: 1,
    },
    {
      headerName: "CR",
      field: "ComponentRepaired",
      description: "Component Repaired",
      minWidth: 45,
      flex: 1,
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      headerName: "Fluid",
      field: "FluidFormatted",
      description: "Fluid Formatted",
      minWidth: 150,
    },
    {
      headerName: "FH",
      field: "FluidHours",
      description: "Fluid Hours",
      minWidth: 50,
      flex: 1,
    },
    {
      headerName: "FC",
      field: "FluidChanged",
      description: "Fluid Changed",
      minWidth: 45,
      flex: 1,
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    },
    {
      headerName: "Topup",
      field: "Topup",
      description: "Topup",
      minWidth: 54,
      flex: 1,
    },
    {
      headerName: "Filter",
      field: "FilterChanged",
      description: "Filter Changed",
      minWidth: 55,
      flex: 1,
      valueFormatter: ({ value }) => (value ? "Yes" : "No"),
    },
  ];

  const extraColumns2 = [
    {
      headerName: "Sampled",
      field: "Sampled",
      valueFormatter: ({ value }) => toLocalDate(value),
      minWidth: 85,
      maxWidth: 85,
    },
    {
      headerName: "SIF",
      field: "SIF",
      hide: !hasSif,
      minWidth: 70,
      maxWidth: 70,
    },
    {
      headerName: "Technician",
      field: "Evaluator",
      minWidth: 80,
      maxWidth: 80,
      renderCell: renderCellExpand,
    },

    {
      headerName: "Status",
      field: "AlertStatus",
      description: "Alert Status",
      minWidth: 90,
      maxWidth: 90,
    },
  ];
  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }
  return {
    // columns: [...extraColumns1, ...extraColumns2],
    columns: [...extraColumns1, ...columnsSimple, ...extraColumns2],
    data: dataSrc,
  };
}

//columns for History Simple
function getDataAndColumnsPastSamplesSimple({
  PastSamplesElementData: data,
  PastSamples: sampleData,
}) {
  const dataObject = {};
  const colMap = {};

  const samplesMap = {};
  let hasSif = false;
  sampleData.forEach(function (s) {
    if (s.SIF) {
      hasSif = true;
    }
    samplesMap[s.SampleNumber] = s;
  });

  data.forEach((x) => {
    if (!dataObject[x.SampleNumber]) {
      dataObject[x.SampleNumber] = { ...samplesMap[x.SampleNumber] };
    }
    if (x.TestItemCode === "ISO3") {
      dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedText;
    } else {
      dataObject[x.SampleNumber][x.TestItemCode] = x.FormattedNumeric;
    }
    if (x.FormattedNumeric === 0 || x.FormattedNumeric < 0) {
      dataObject[x.SampleNumber][x.TestItemCode] = "<1";
    }
    if (colMap[x.TestItemCode]) {
      return;
    }
    colMap[x.TestItemCode] = x;
  });

  const columnsSimple = Object.keys(colMap)
    .map((x) => colMap[x])
    .sort(sortFunction)
    .map((x) => ({
      headerName: x.TestItemCode,
      field: x.TestItemCode,
      minWidth: ResultColWidths[x.TestItemCode] || 50,
      flex: 1,
      renderCell: renderCellExpand,
    }));

  const extraColumns1 = [
    {
      headerName: "Sample Number",
      field: "SampleNumber",
      minWidth: ResultColWidths["SampleNumber"] || 50,
    },
  ];
  const dataSrc = [];
  for (var key in dataObject) {
    dataObject[key].SampleNumber = key;
    dataSrc.push(dataObject[key]);
  }
  return {
    // columns: [...extraColumns1, ...extraColumns2],
    columns: [...extraColumns1, ...columnsSimple],
    data: dataSrc,
  };
}

function sortFunction(a, b) {
  return (
    a.TechnicianRank - b.TechnicianRank ||
    a.TestItemCode.localeCompare(b.TestItemCode)
  );
}
