import { useEffect, useMemo, useReducer, useState } from "react";
import { Box, Card, Grid, Tab, Tabs, Typography } from "@mui/material";
import Form, { FormActions } from "../../components/Form";
import { Article, Home, Search, Signpost } from "@mui/icons-material";
import FormFooter from "../../components/FormFooter";
import useFetch from "../../hooks/useFetch";
import CustomerTable from "./tables/CustomerTable";
import SampleTable from "./tables/SampleTable";
import JobTable from "./tables/JobTable";
import ReportTable from "./tables/ReportTable";
import ComponentTable from "./tables/ComponentTable";
import SiteTable from "./tables/SiteTable";
import EventLogTable from "./tables/EventLogTable";
import CustomersTable from "./tables/CustomersTable";
import UnitTable from "./tables/UnitTable";
import TownTable from "./tables/TownTable";
import { ValidateSearchFilter } from "./SearchDetailsValidator";
import {
  AddIcon,
  ClearIcon,
  ComponentIcon,
  CustomerIcon,
  EventLog,
  JobIcon,
  SampleIcon,
  UnitIcon,
} from "../../icons/ButtonIcons";
import GroupDialog from "../../components/GroupDialog";
import { DialogTypes, useScreenPopups } from "../../components/useScreenPopups";
import { TRAY_APIS } from "../../common/apis";
import { useRoot } from "../../rootContext";

export default function SearchDetails({
  activeTab = "customer",
  activeOnly = "*",
  compActions,
  custActions,
  siteActions,
  unitActions,
  rowSelected,
  propValue,
}) {
  const { post, getFile } = useFetch();
  // const { getMasterData } = useMasterData();
  const { products } = useRoot();

  const [filter, dispatch] = useReducer(
    SearchQuickDetailsReducer,
    SearchQuickDetailsDefaults
  );

  const [dataSource, setDataSource] = useState({});
  const [errors, setErrors] = useState({});
  // const [masterData, setMasterData] = useState({});
  const [tabVal, setTabVal] = useState(activeTab);
  // const [searchId, setSearchId] = useState("");
  const [dialogType, setDialogType] = useState({ open: false });
  const [sampleNumber, setSampleNumber] = useState(null);

  const formActions = {
    search: function (e) {
      refreshData(tabVal, filter, true, true);
    },
    clear: function (e) {
      dispatch({ type: FormActions.RESET_FORM });
    },
    newModals: function (e) {
      addNew(filter, e);
    },
  };

  const handleNewCustomer = (customerName, productid) => {
    dispatch({ type: FormActions.RESET_FORM });
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name: "Customer", value: customerName },
    });
    refreshData(
      "customer",
      {
        ProductId: productid,
        archivedOption: 0,
        SearchTerm: 0,
        Customer: customerName,
      },
      true,
      true
    );
  };

  function loadTabData(tabName) {
    refreshData(tabName, filter, true);
  }

  async function refreshData(tabName, data, resetData, searchButton) {
    let apiUrl = "";
    let postData = {
      ...data,
      UnarchivedOnly:
        data.archivedOption === 0 ? null : data.archivedOption === 1,
      UseStartsWith: data.SearchTerm === 1,
    };
    let dataSetName = tabName;
    const temp = DataSetMap[tabName];
    apiUrl = temp.dataApi2;
    if (tabName === "customer") {
      apiUrl = temp.dataApi2;
      dataSetName = "customers";
      postData = {
        ProductId: data.ProductId,
        UnarchivedOnly:
          data.archivedOption === 0 ? null : data.archivedOption === 1,
        UseStartsWith: data.SearchTerm === 1,
      };
    } else if (tabName === "site") {
      apiUrl = temp.dataApi2;
      dataSetName = "sites";
      postData = {
        ProductId: data.ProductId,
        Customer: data.Customer === "" ? null : data.Customer,
        UnarchivedOnly:
          data.archivedOption === 0 ? null : data.archivedOption === 1,
        UseStartsWith: data.SearchTerm === 1,
      };
    } else if (tabName === "unit") {
      if (data.UnitId) {
        apiUrl = temp.dataApi2;
      } else if (data.EqpMachineId) {
        apiUrl = temp.dataApi3;
      }
      dataSetName = "units";
      postData = {
        ProductId: data.ProductId,
        Customer: data.Customer === "" ? null : data.Customer,
        Site: data.Site === "" ? null : data.Site,
        SiteId: data.SiteId === "" ? null : data.SiteId,
        UnarchivedOnly:
          data.archivedOption === 0 ? null : data.archivedOption === 1,
        UseStartsWith: data.SearchTerm === 1,
      };
    } else if (tabName === "component" && !data.ComponentId) {
      apiUrl = temp.dataApi2;
      dataSetName = "components";
      postData = {
        ProductId: data.ProductId,
        Customer: data.Customer === "" ? null : data.Customer,
        Site: data.Site === "" ? null : data.Site,
        SiteId: data.SiteId === "" ? null : data.SiteId,
        Unit: data.Unit === "" ? null : data.Unit,
        UnitId: data.UnitId === "" ? null : data.UnitId,
        UnarchivedOnly:
          data.archivedOption === 0 ? null : data.archivedOption === 1,
        UseStartsWith: data.SearchTerm === 1,
      };
    }

    const dataCache = resetData ? {} : dataSource;

    if (dataCache[dataSetName] && !resetData) {
      setDataSource({
        ...dataCache,
        type: dataSetName,
        nodeLevel: temp.nodeLevel,
      });
      return;
    }
    setErrors({});
    ValidateSearchFilter(data, tabName).then(async function ({
      errors: tempErrors,
    }) {
      if (tempErrors) {
        setErrors(tempErrors);
        return;
      }
      // const tempData = await post(apiUrl, {
      //   ...data,
      //   UnarchivedOnly: data.archivedOption === 0 ? null : data.archivedOption === 1,
      //   UseStartsWith: data.SearchTerm === 1,
      // });
      // const tempData = await post(apiUrl, postData);

      //following code is added to search all fields through seach button
      let postModal =
        searchButton === true
          ? {
            ...data,
            CustomerId: data?.CustomerId === "" ? null : data?.CustomerId,
            SiteId: data?.SiteId === "" ? null : data?.SiteId,
            UnitId: data?.UnitId === "" ? null : data?.UnitId,
            ComponentId: data?.ComponentId === "" ? null : data?.ComponentId,
            EqpMachineId:
              data?.EqpMachineId === "" ? null : data?.EqpMachineId,
            UnarchivedOnly:
              data.archivedOption === 0 ? null : data.archivedOption === 1,
            UseStartsWith: data.SearchTerm === 1,
          }
          : postData;
      const tempData = await post(apiUrl, postModal);
      if (tempData?.length > 1) {
        if (tabName === "customer" && !data?.CustomerId) {
          rowSelected && rowSelected(tempData[0], 2);
          dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "Customer", value: tempData[0]["Customer"] },
          });
          dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "CustomerId", value: tempData[0]["CustomerId"] },
          });
        } else if (tabName === "site" && !data?.SiteId) {
          rowSelected && rowSelected(tempData[0], 3);
          dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "Site", value: tempData[0]["Site"] },
          });
          dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "SiteId", value: tempData[0]["SiteId"] },
          });
        } else if (tabName === "unit" && !data?.UnitId) {
          rowSelected && rowSelected(tempData[0], 4);
          dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "Unit", value: tempData[0]["Unit"] },
          });
          dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "UnitId", value: tempData[0]["UnitId"] },
          });
        } else if (tabName === "component" && !data?.ComponentId) {
          rowSelected && rowSelected(tempData[0], 5);
          dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "Component", value: tempData[0]["Component"] },
          });
          dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "ComponentId", value: tempData[0]["ComponentId"] },
          });
        }
      }

      setDataSource({
        ...dataCache,
        type: dataSetName,
        [dataSetName]: tempData,
        nodeLevel: temp.nodeLevel,
      });
    });
  }

  let FormFields = GetFormFields(products, openddModal, filter);

  const fields = useMemo(() => {
    return {
      fields: FormFields.filter((x) =>
        (TabFields[tabVal] || []).includes(x.name)
      ),
    };
  }, [tabVal, FormFields]);

  function rowSelectedHandler(row) {
    // dataSource[dataSource.type][0]["CustomerId"]
    if (dataSource.nodeLevel === 2) {
      // if (filter.Customer === row.Customer) return;
      rowSelected && rowSelected(row, dataSource.nodeLevel);
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: "Customer", value: row.Customer },
      });
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: "CustomerId", value: row.CustomerId },
      });
    }

    if (dataSource.nodeLevel === 3) {
      if (filter.Site === row.Site) return;
      rowSelected && rowSelected(row, dataSource.nodeLevel);
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: "Site", value: row.Site },
      });
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: "SiteId", value: row.SiteId },
      });
    }

    if (dataSource.nodeLevel === 4) {
      if (filter.Unit === row.Unit) return;
      rowSelected && rowSelected(row, dataSource.nodeLevel);
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: "Unit", value: row.Unit },
      });
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: "UnitId", value: row.UnitId },
      });
    }

    if (dataSource.nodeLevel === 5) {
      if (filter.Component === row.Component) return;
      rowSelected && rowSelected(row, dataSource.nodeLevel);
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: "Component", value: row.Component },
      });
      dispatch({
        type: FormActions.INPUT_CHANGED,
        payload: { name: "ComponentId", value: row.ComponentId },
      });
    }
    if (dataSource.nodeLevel === 6) {
      if (sampleNumber === row.SampleNumber) return;
      rowSelected && rowSelected(row, dataSource.nodeLevel);
      setSampleNumber(row.SampleNumber);
    }
    rowSelected && rowSelected(row, dataSource.nodeLevel);
  }

  useEffect(function () {
    refreshData(
      "customer",
      { ProductId: 2, archivedOption: 0, SearchTerm: 0 },
      true
    );
  }, []);

  async function getSampleReport(row) {
    try {
      await getFile(
        `${TRAY_APIS.REPORT_SAMPLE}/?sampleNo=${row?.SampleNumber}`
      );
    } catch (error) {
      console.error("Error fetching or opening PDF:", error);
    }
  }

  const viewMachineReport = async (data) => {
    let fileName = `${data?.Customer || ""} ${data?.Site || ""} ${data?.Unit || ""} `
    await getFile(`${TRAY_APIS.MACHINE_REPORT}/?Machine_UnitId=${data.UnitId}`, fileName);
  };

  //For the Search Functionalities
  function openddModal(e) {
    let actionName = e.target.value;
    if (actionName === "view-sample-report") {
      return getSampleReport(filter);
    }
    if (actionName === "view-mhr-report") {
      return viewMachineReport(filter);
    }

    if (
      [
        DialogTypes.COMPONENTS_NO_RECENT_SAMPLES,
        DialogTypes.UNITS_NO_RECENT_SAMPLES,
        DialogTypes.SUBSCRIPTION_OVERVIEW,
        DialogTypes.ADD_NEW_CUSTOMER,
        DialogTypes.EDIT_CUSTOMER,
        DialogTypes.ACCESS_PERMISSION_OVERVIEW,
        DialogTypes.REPORT_CONTACTS,
        DialogTypes.REPORTS_BY_DATE,
        "search-customer",
        "search-site",
        "search-unit",
        "search-component",
        "search-sample",
      ].indexOf(actionName) > -1
    ) {
      addNew(filter, actionName);
      return;
    }
  }

  function performSearch(row, actionName) {
    const { Customer, Site, Unit, Component } = row;
    const tabName = actionName.split("-")[1]; // e.g. "search-customer" -> customer

    const tempFilter = { ...filter, Customer, Site, Unit, Component };
    if (tabName === "sample") {
      tempFilter.SampleNumber = "2";
    }
    setTabVal(tabName);
    refreshData(tabName, tempFilter, true);
  }

  function rowActionHandler(row, actionName) {
    if (actionName === "view-sample-report") {
      return getSampleReport(row);
    }
    if (actionName === "view-mhr-report") {
      return viewMachineReport(row);
    }

    if (
      [
        "search-customer",
        "search-site",
        "search-unit",
        "search-component",
        "search-sample",
      ].indexOf(actionName) > -1
    ) {
      performSearch(row, actionName);
      return;
    }

    if (
      [
        DialogTypes.RECENT_COMPONENT_RESULTS,
        DialogTypes.COMPONENTS_NO_RECENT_SAMPLES,
        DialogTypes.UNITS_NO_RECENT_SAMPLES,
        DialogTypes.SUBSCRIPTION_OVERVIEW,
        DialogTypes.EDIT_EVENT,
        DialogTypes.EDIT_CUSTOMER,
        DialogTypes.ACCESS_PERMISSION_OVERVIEW,
        DialogTypes.REPORT_CONTACTS,
        DialogTypes.REPORTS_BY_DATE,
        DialogTypes.PRIMARY_CONTACTS_OVERVIEW,
        DialogTypes.EXPORT_CONTACTS_OVERVIEW,
        DialogTypes.ADD_NEW_COMPONENT,

        // Below fields are for addition purposes, and open a modal for collecting data afterwards, same modal as in trays/registration
        DialogTypes.ADD_NEW_COMPONENT,
        DialogTypes.ADD_NEW_CUSTOMER,
        DialogTypes.ADD_NEW_SITE,
        DialogTypes.ADD_NEW_UNIT,
        DialogTypes.UNIT_HOUR_HISTORY,

        //MANAGE
        DialogTypes.MANAGE_COMPONENT,
        DialogTypes.MANAGE_CUSTOMER,
        DialogTypes.MANAGE_SAMPLE,
        DialogTypes.MANAGE_SITE,
        DialogTypes.MANAGE_UNIT,
      ].indexOf(actionName) > -1
    ) {
      addNew(row, actionName);
      return;
    }
  }

  function closeDialog() {
    setDialogType({ ...dialogType, open: false });
  }

  //A new Component created just for the Add Button Functionalities
  const dialogOptions = useScreenPopups({
    dialogType: dialogType.type,
    data: dialogType.data || {},
    cancel: closeDialog,
    newCustomer: handleNewCustomer,
  });

  // Handles the Add New Buttons Functionalities
  function addNew(row, actionName) {
    setDialogType({ type: actionName, open: true, data: row });
  }

  // sampleTableActions
  //For the Search and Display Functionalities

  return (
    <>
      <Grid minHeight={420}>
        <Card sx={{ p: 1, pb: 1.5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tabs
                value={tabVal}
                onChange={(event, newValue, x) => {
                  setTabVal(newValue);
                  //alert(rowVal.Product + " " + rowVal.CustomerId);
                  dataSource.type && loadTabData(newValue);
                }}
              >
                {TabItems.map(({ fields, ...x }) => (
                  <Tab
                    {...x}
                    iconPosition="start"
                    key={x.value}
                    disabled={
                      activeOnly !== "*" && !activeOnly.includes(x.value)
                    }
                  />
                ))}
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <Form
                sections={[fields]}
                data={filter}
                masterData={products}
                errors={errors}
                dispatch={dispatch}
                key={(tabVal || "") + (filter._ts_key || "")}
              />
            </Grid>
          </Grid>

          <FormFooter buttons={GetFormButtons(filter, tabVal, formActions)} />

          {dataSource.type && (
            <Typography variant="subtitle2" style={{ marginTop: "-25px" }}>
              Total Records: <b> {dataSource[dataSource.type]?.length}</b>
            </Typography>
          )}
        </Card>

        <Box sx={{ mt: 1 }}>
          {dataSource.type === "customer" ? (
            <CustomerTable
              data={dataSource[dataSource.type]}
              actions={custActions}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
            />
          ) : dataSource.type === "customers" ? (
            <CustomersTable
              selectedRow={filter?.CustomerId}
              data={dataSource[dataSource.type]}
              actions={custActions}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
              propValue={propValue}
            />
          ) : dataSource.type === "job" ? (
            <JobTable
              data={dataSource[dataSource.type]}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
            />
          ) : dataSource.type === "sample" ? (
            <SampleTable
              data={dataSource[dataSource.type]}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
            />
          ) : dataSource.type === "adhoc-report" ? (
            <ReportTable
              data={dataSource[dataSource.type]}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
            />
          ) : dataSource.type === "components" ||
            dataSource.type === "component" ? (
            <ComponentTable
              data={dataSource[dataSource.type]}
              actions={compActions}
              selectedRow={filter?.ComponentId}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
              propValue={propValue}
            />
          ) : dataSource.type === "unit" || dataSource.type === "units" ? (
            <UnitTable
              data={dataSource[dataSource.type]}
              selectedRow={filter?.UnitId}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
              actions={unitActions}
              propValue={propValue}
            />
          ) : dataSource.type === "sites" || dataSource.type === "site" ? (
            <SiteTable
              data={dataSource[dataSource.type]}
              selectedRow={filter?.SiteId}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
              actions={siteActions}
              propValue={propValue}
            />
          ) : dataSource.type === "event-log" ? (
            <EventLogTable
              data={dataSource[dataSource.type]}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
            />
          ) : dataSource.type === "town" ? (
            <TownTable
              data={dataSource[dataSource.type]}
              rowSelected={rowSelectedHandler}
              rowActionHandler={rowActionHandler}
            />
          ) : (
            <Grid item alignItems={"center"}>
              <Typography
                textAlign={"center"}
                paddingY={13}
                border={"1px solid #253fc8"}
                minHeight={30}
                borderRadius={1}
                backgroundColor={"#fff"}
              >
                No rows
              </Typography>
            </Grid>
          )}
        </Box>
        <GroupDialog
          {...dialogOptions}
          open={dialogType.open || false}
          close={closeDialog}
        />
      </Grid>
    </>
  );
}

const DataSetMap = {
  customer: {
    dataApi: "/details/SearchCustomer",
    dataApi2: "/details/SearchCustomers",
    nodeLevel: 2,
    tab: "customer",
  },
  site: {
    dataApi: "/details/SearchSite",
    dataApi2: "/details/SearchSites",
    nodeLevel: 3,
    tab: "site",
  },
  unit: {
    dataApi: "/details/SearchUnit",
    dataApi2: "/details/SearchUnits",
    dataApi3: "/details/SearchUnitByEqpMachineId",
    nodeLevel: 4,
    tab: "unit",
  },
  component: {
    dataApi: "/details/SearchComponent",
    dataApi2: "/details/search-components",
    nodeLevel: 5,
    tab: "component",
  },
  sample: {
    dataApi2: "/details/SearchSamples",
    nodeLevel: 6,
    tab: "sample",
  },
  job: {
    dataApi2: "/details/SearchJobNumbers",
    nodeLevel: 0,
    tab: "job",
  },
  "adhoc-report": {
    dataApi2: "/details/SearchAdHocReports",
    nodeLevel: 0,
    tab: "adhoc-report",
  },
  "event-log": {
    dataApi2: "/details/SearchEventLog",
    nodeLevel: 0,
    tab: "event-log",
  },
  town: {
    dataApi2: "/details/SearchTowns",
    nodeLevel: 0,
    tab: "town",
  },
};

// const NodeLevelMap = {
//   "/details/SearchCustomers": 2,
//   "/details/SearchCustomer": 2,
//   "/details/SearchSites": 3,
//   "/details/SearchSite": 3,
//   "/details/SearchUnits": 4,
//   "/details/SearchUnit": 4,
//   "/details/SearchComponents": 5,
//   "/details/SearchComponent": 5,
//   "/details/SearchSamples": 6,
//   "/details/SearchJobNumbers": 7,
//   "/details/SearchEventLog": 8,
//   "/details/SearchTowns": 9,
// };
const TabItems = [
  {
    value: "customer",
    label: "Customer",
    icon: <CustomerIcon />,
  },
  {
    value: "site",
    label: "Site",
    icon: <Home />,
  },
  {
    value: "unit",
    label: "Unit",
    icon: <UnitIcon />,
  },
  {
    value: "component",
    label: "Component",
    icon: <ComponentIcon />,
  },
  {
    value: "sample",
    label: "Sample",
    icon: <SampleIcon />,
  },
  {
    value: "job",
    label: "Job",
    icon: <JobIcon />,
  },
  {
    value: "adhoc-report",
    label: "AdHoc Report",
    icon: <Article />,
  },
  {
    value: "event-log",
    label: "Event Log",
    icon: <EventLog />,
  },
  {
    value: "town",
    label: "Town",
    icon: <Signpost />,
  },
];

const TabFields = {
  customer: [
    "ProductId",
    "Customer",
    "Town",
    "Parent",
    "AdditionalReports",
    "Contacts",
    "SearchTerm",
    "archivedOption",
  ],

  site: [
    "ProductId",
    "Customer",
    "Site",
    "Town",
    "Parent",

    "SiteId",

    "SearchTerm",
    "archivedOption",
  ],
  unit: [
    "ProductId",
    "Customer",
    "Site",
    "Town",
    "Unit",
    "Serial",
    "Make",
    "Model",

    "SearchTerm",
    "archivedOption",

    "UnitId",
    "EqpMachineId",
  ],
  component: [
    "ProductId",
    "Customer",
    "Site",
    "Town",
    "Unit",
    "Serial",
    "Make",
    "Model",
    "Component",
    "ComponentSerial",

    "ComponentId",

    "SearchTerm",
    "archivedOption",
  ],
  sample: [
    "ProductId",
    "SampleNumber",
    "Sif",
    "JobNumber",
    "Customer",
    "Site",
    "Town",
    "Unit",
    "Serial",
    "Make",
    "Model",
    "Component",
    "ComponentSerial",
    "Parent",
    "SearchTerm",
    "archivedOption",
  ],
  job: [
    "ProductId",
    "JobNumber",
    "Customer",
    "Site",
    "Town",
    "Unit",
    "Serial",
    "Make",
    "Model",
    "Component",
    "ComponentSerial",

    "SearchTerm",
    "archivedOption",
  ],
  "adhoc-report": [
    "ProductId",
    "ReportNumber",
    "Customer",
    "Site",
    "Town",
    "Unit",
    "Serial",
    "Make",
    "Model",
    "Component",
    "ComponentSerial",

    "SearchTerm",
    "archivedOption",
  ],
  "event-log": ["EventLogId"],
  town: ["ProductId", "Town", "SearchTerm", "archivedOption"],
};

const GetFormButtons = (data, tabVal, { search, clear, newModals }) => [
  // {
  //   variant: "outlined",
  //   label: "Component",
  //   onClick: () => newModals("add-new-component"),
  //   hidden: tabVal !== "component",
  //   startIcon: <AddIcon />,
  // },
  // {
  //   variant: "outlined",
  //   label: "Unit",
  //   onClick: () => newModals("add-new-unit"),
  //   hidden: tabVal !== "unit",
  //   startIcon: <AddIcon />,
  // },
  // {
  //   variant: "outlined",
  //   label: "Site",
  //   onClick: () => newModals("add-new-site"),
  //   hidden: tabVal !== "site",
  //   startIcon: <AddIcon />,
  // },
  {
    variant: "outlined",
    label: "Customer",
    onClick: () => newModals("add-new-customer"),
    hidden: tabVal !== "customer",
    startIcon: <AddIcon />,
  },
  {
    variant: "outlined",
    label: "Clear",
    onClick: clear,
    startIcon: <ClearIcon />,
  },
  {
    variant: "contained",
    label: "Search",
    onClick: search,
    startIcon: <Search />,
  },
];

const GetFormFields = ({ Products }, openddModal, filter) => [
  {
    name: "ProductId",
    label: "Product",
    type: "dropdown",
    required: true,
    optionConfigs: {
      name: "Products",
      textProp: "Product",
      valProp: "ProductId",
    },
    sx: {
      [`.MuiSelect-select`]: {
        backgroundColor:
          Products?.filter((x) => x.ProductId === filter.ProductId)[0][
          "Colour"
          ] || "",
        color: "white",
        [`.MuiSvgIcon-root-MuiSelect-icon`]: { color: "white" },
      },
      [`.MuiSelect-icon`]: { color: "#fff" },
    },
    xsCol: 2,
  },
  { name: "SampleNumber", label: "Sample Number", xsCol: 2 },
  { name: "Sif", label: "Registration", xsCol: 2 },
  { name: "JobNumber", label: "Job Number", xsCol: 2 },
  { name: "ReportNumber", label: "Report Number", xsCol: 2 },
  { name: "Customer", label: "Customer", xsCol: 2 },
  { name: "Make", label: "Make", xsCol: 2 },
  { name: "Town", label: "Town", xsCol: 2 },
  { name: "Site", label: "Site", xsCol: 2 },
  { name: "Model", label: "Model", xsCol: 2 },
  { name: "Unit", label: "Unit", xsCol: 2 },
  { name: "Component", label: "Component", xsCol: 2 },
  { name: "Serial", label: "Serial", xsCol: 2 },
  { name: "ComponentSerial", label: "Comp. Serial", xsCol: 2 },
  { name: "Parent", label: "Parent", xsCol: 2 },
  { name: "CustomerId", label: "Customer Id", xsCol: 2, type: "number" },
  { name: "SiteId", label: "Site Id", xsCol: 2, type: "number" },
  { name: "UnitId", label: "Unit Id", xsCol: 2, type: "number" },
  { name: "ComponentId", label: "Component Id", xsCol: 2, type: "number" },
  { name: "EqpMachineId", label: "Eqp Machine Id", xsCol: 2, type: "number" },
  { name: "EventLogId", label: "EventLog Id", xsCol: 2, type: "number" },
  {
    name: "archivedOption",
    label: "Archived Options",
    type: "dropdown",
    selectOptions: [
      { value: 0, text: "All" },
      { value: 1, text: "Unarchived Only" },
      { value: 2, text: "Archived Only" },
    ],
    xsCol: 2,
  },
  {
    name: "SearchTerm",
    label: "Search Term",
    type: "dropdown",
    selectOptions: [
      { value: 0, text: "Mid-word Search:%term%" },
      { value: 1, text: "Starts-with Search:term%" },
    ],
    xsCol: 2,
  },
  {
    name: "AdditionalReports",
    label: "Additional Reports",
    type: "dropdown",
    onChange: openddModal,
    selectOptions: [
      { value: "reports-by-date", text: "Reports By Date" },
      {
        value: "components-no-recent-samples",
        text: "Components No Recent Samples",
      },
      { value: "units-no-recent-samples", text: "Units No Recent Samples" },
      { value: 4, text: "Reference XSD" },
    ],
    xsCol: 2,
  },
  {
    name: "Contacts",
    label: "Contacts",
    type: "dropdown",
    onChange: openddModal,
    selectOptions: [
      { value: "access-permission-overview", text: "Access Permissions" },
      { value: "report-contacts", text: "Sample Reporting" },
      { value: "subscription-overview", text: "Subscriptions" },
    ],
    xsCol: 2,
  },
];

function SearchQuickDetailsReducer(state, action) {
  if (action.type === FormActions.INPUT_CHANGED) {
    return { ...state, [action.payload.name]: action.payload.value };
  }
  if (action.type === FormActions.RESET_FORM) {
    return { ...SearchQuickDetailsDefaults, _ts_key: Date.now() };
  }
  return state;
}

const SearchQuickDetailsDefaults = {
  SearchTerm: 0,
  ProductId: 2,
  archivedOption: 0,
};
